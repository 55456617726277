import { Directive, Input, ElementRef, Renderer2, OnInit, Optional } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appDisabledClass]'
})
export class DisabledClassDirective implements OnInit {
  @Input('appDisabledClass') disabledClass: string = 'disabled-category-label'; // Default class
  @Input() controlName!: string; // Get control name from the template

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() private controlContainer: ControlContainer // Inject the parent form group
  ) {}

  ngOnInit(): void {
    const control = this.getControl();
    if (control) {
      // Listen for changes in the control's status
      control.statusChanges?.subscribe(() => {
        this.updateClass(control);
      });

      // Apply the class initially based on the control's status
      this.updateClass(control);
    }
  }

  private getControl(): AbstractControl | null {
    return this.controlContainer?.control?.get(this.controlName) || null;
  }

  private updateClass(control: AbstractControl): void {
    if (control.disabled) {
      this.renderer.addClass(this.el.nativeElement, this.disabledClass);
    } else {
      this.renderer.removeClass(this.el.nativeElement, this.disabledClass);
    }
  }
}
