<div class="timer-main">
	<div class="btn-group d-flex" dropdown>
		<button
			*ngIf="!activeTimer && showButton"
			placement="bottom"
			[tooltip]="'Click here to start the timer'"
			class="btn btn-block btn-primary start-timer-button d-flex justify-content-center align-items-center clickable"
			id="dropdownMenuLink"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			(click)="startTimerOverlay()">
			<i class="far fa-clock fa-2x"></i>
			<span class="pl-2 d-flex align-items-center">Start Timer</span>
		</button>
		<div
			[ngStyle]="{ display: showDropdown === true ? 'block' : 'none' }"
			class="dropdown-menu dropdown-menu-left"
			aria-labelledby="dropdownMenuLink">
			<div class="p-3">
				<div class="d-flex justify-content-end">
					<em
						class="text-muted fas fa-times clickable"
						(click)="closeTimerDropdown()"></em>
				</div>

				<form
					class="form-group d-flex align-items-end justify-content-between">
					<div class="description-field">
						<label class="text-dark">Description</label>
						<input
							class="form-control"
							type="text"
							placeholder="Please add the project URL or describe the task you're working on..."
							[formControl]="autoTimerForm"
							(input)="startTimerGetProject()" />
					</div>
					<button
						type="button"
						[disabled]="!autoTimerForm.valid || isProjectLoading"
						class="btn btn-primary start-timer-popup ml-2"
						(click)="startAutoTimer()">
						Start Timer
					</button>
				</form>
				<span *ngIf="project && isAutotimer">
					Project: {{ project?.title }}
				</span>
				<p class="or-button pt-4 pb-4 text-light">OR:</p>
				<div class="more-text">
					<span class="text-dark">More:</span>
					<p class="text-light">
						Manually add time that you might have missed and a
						description.
					</p>
				</div>
				<form [formGroup]="manualTimerForm">
					<div class="d-flex justify-content-between">
						<div class="w-50 mr-2">
							<input
								type="number"
								class="form-control mr-2"
								formControlName="hours"
								placeholder="HH"
								min="0"
								(input)="changeInHour($event)"
								(keypress)="
									_commonService.onlyNumeric($event)
								" />
						</div>

						<div class="d-flex flex-column w-50">
							<input
								type="number"
								class="form-control"
								formControlName="minutes"
								placeholder="MM"
								min="0"
								(input)="changeInMinute($event)"
								(keypress)="
									_commonService.onlyNumeric($event)
								" />
							<div
								*ngIf="
									manualTimerForm.controls[
										'minutes'
									].hasError('min') &&
									(manualTimerForm.controls['minutes']
										.dirty ||
										manualTimerForm.controls['minutes']
											.touched)
								"
								class="text-danger">
								Minutes should be greater than 0.
							</div>
						</div>
					</div>

					<div
						class="form-group d-flex align-items-end justify-content-between mt-4">
						<div class="description-field">
							<input
								type="text"
								class="form-control"
								formControlName="description"
								(input)="manualTimerGetProject()"
								placeholder="Please add the project URL or describe the task you're working on..." />
						</div>
						<button
							type="button"
							[disabled]="
								!manualTimerForm.valid && !isProjectLoading
							"
							class="btn btn-primary start-timer-popup ml-2"
							(click)="startManualTimer()">
							Add Time
						</button>
					</div>
				</form>
				<span *ngIf="project && isManualtimer">
					Project: {{ project?.title }}
				</span>
				<span class="border-bottom"></span>
			</div>
		</div>

		<div
			[ngStyle]="{ display: showSwitchTimer === true ? 'block' : 'none' }"
			class="dropdown-menu switch-dropdown dropdown-menu-left"
			aria-labelledby="dropdownMenuLink">
			<div class="p-3">
				<div class="d-flex justify-content-end pb-2">
					<em
						class="text-muted fas fa-times clickable"
						(click)="closeSwitchTimerDropdown()"></em>
				</div>

				<form
					class="form-group d-flex align-items-end justify-content-between">
					<div class="description-field">
						<label class="text-dark">Description</label>
						<input
							class="form-control"
							type="text"
							placeholder="Please add the project URL or describe the task you're working on..."
							[formControl]="switchTimerForm"
							(input)="switchTimerGetProject()" />
					</div>

					<div
						class="d-flex flex-column justify-content-between button-section">
						<h4 class="text-primary timer-preview">
							<i class="far fa-hourglass text-primary"></i>
							{{ switchTimerValue }}
						</h4>
						<button
							type="button"
							[disabled]="
								!switchTimerForm.valid ||
								isProjectLoading ||
								isSwitchTimerBtnDisabled
							"
							class="btn btn-primary start-timer-popup ml-2"
							(click)="switchTimer()">
							Switch
						</button>
					</div>
				</form>
				<span *ngIf="project && isSwitchtimer">
					Project: {{ project?.title }}
				</span>
			</div>
		</div>
	</div>

	<div
		*ngIf="activeTimer && desktop_view"
		class="d-flex active-timer-block clickable">
		<div
			class="active-timer-button d-flex justify-content-center"
			placement="bottom"
			[tooltip]="currentDiscription">
			<i class="fas fa-hourglass-end fa-2x text-white"></i>
			<span class="pl-2 d-flex align-items-center timer-text text-white">
				<flocksy-timer
					units="Hours|Minutes|Seconds"
					#switchTimerReff
					[hours]="hours"
					[isEarningTimer]="true"
					[minutes]="minutes"
					[seconds]="seconds"
					[timerDirection]="'up'"
					[text]="{
						Hours: ':',
						Minutes: ':',
						Seconds: ''
					}"
					[positiveText]="true"
					[hourly]="true"
					[showNegativeTime]="false"></flocksy-timer>
			</span>
		</div>
		<div
			class="d-flex align-items-center switch-block"
			placement="bottom"
			[tooltip]="'Switch to another project'">
			<div>
				<span
					class="d-flex justify-content-center align-items-center"
					(click)="switchTimerPopup()">
					<span
						class="d-flex justify-content-center align-items-center">
						<i class="fas fa-toggle-on fa-2x text-white"></i>
					</span>
				</span>
			</div>
		</div>
		<div
			[ngStyle]="{
				display: showSwitchDropdown === true ? 'block' : 'none'
			}"
			class="dropdown-menu mobile-view-dropdown dropdown-menu-center"
			aria-labelledby="dropdownMenuLink">
			<div class="p-3">
				<div
					class="form-group d-flex align-items-end justify-content-between">
					<div class="description-field">
						<label class="text-dark">Description</label>
						<input
							class="form-control"
							placeholder="Please add the project URL or describe the task you're working on..." />
					</div>
					<button
						type="submit"
						class="btn btn-primary start-timer-popup ml-2"
						(click)="switchTimer(); dropdown.hide()">
						Switch
					</button>
				</div>
			</div>
		</div>
		<div
			class="d-flex align-items-center stop-block"
			(click)="isStopTimerBtnDisabled ? '' : stopTimerButton()"
			placement="bottom"
			[tooltip]="'Stop Timer'">
			<div class="height-100 stop-button">
				<span class="d-flex justify-content-center align-items-center">
					<i class="fas fa-stop text-white fa-1x"></i>
				</span>
			</div>
		</div>
	</div>
</div>

<ng-template #stopTimerModal>
	<div class="modal-title p-4 bb">
		<h3 class="text-primary">
			Your timer has been running for more than {{ stopHours }} hours
		</h3>
	</div>
	<hr />
	<div class="col-md pl-4">
		<div class="text-bold">
			This timer has been running for
			<span class="text-bold">{{ stopHours }} hours.</span>
			Are you sure you want to keep it running?
		</div>
	</div>
	<div class="d-flex justify-content-end m-2">
		<button
			type="button"
			class="btn btn-primary start-timer-popup ml-2 popup-button"
			(click)="stopTimerRef.hide(); continueTimer()">
			Continue
		</button>
		<button
			type="button"
			class="btn text-white start-timer-popup ml-2 popup-button stop-button"
			(click)="stopTimerPopup(); stopTimerRef.hide()">
			Stop
		</button>
	</div>
</ng-template>

<div *ngIf="!desktop_view">
	<div *ngIf="activeTimer" class="d-flex active-timer-block clickable w-100">
		<div
			class="active-timer-button d-flex justify-content-center w-60"
			placement="bottom"
			[tooltip]="'Your timer is running'">
			<i class="fas fa-hourglass-end fa-2x text-white"></i>
			<span class="pl-2 d-flex align-items-center timer-text text-white">
				<flocksy-timer 
					units="Hours|Minutes|Seconds"
					#switchTimerReff
					[hours]="hours"
					[isEarningTimer]="true"
					[minutes]="minutes"
					seconds="0"
					[timerDirection]="'up'"
					[text]="{
						Hours: ':',
						Minutes: ':',
						Seconds: ''
					}"
					[positiveText]="true"
					[hourly]="true"
					[showNegativeTime]="false"></flocksy-timer>
			</span>
		</div>
		<div
			class="d-flex align-items-center justify-content-center switch-block w-20"
			placement="bottom"
			[tooltip]="'Switch to another project'">
			<div>
				<span
					class="d-flex justify-content-center align-items-center"
					(click)="switchTimerPopup()">
					<i class="fa fa-toggle-on fa-2x text-white"></i>
				</span>
			</div>
		</div>
		<div
			[ngStyle]="{
				display: showSwitchDropdown === true ? 'block' : 'none'
			}"
			class="dropdown-menu mobile-view-dropdown dropdown-menu-center"
			aria-labelledby="dropdownMenuLink">
			<div class="p-3">
				<div
					class="form-group d-flex align-items-end justify-content-between">
					<div class="description-field">
						<label class="text-dark">Description</label>
						<input
							class="form-control"
							placeholder="Please add the project URL or describe the task you're working on..." />
					</div>
					<button
						type="submit"
						class="btn btn-primary start-timer-popup ml-2"
						(click)="switchTimer(); dropdown.hide()">
						Switch
					</button>
				</div>
			</div>
		</div>
		<div
			class="d-flex align-items-center justify-content-center stop-block w-20"
			placement="bottom"
			[tooltip]="'Stop Timer'">
			<div class="height-100 stop-button" (click)="stopTimerButton()">
				<span class="d-flex justify-content-center align-items-center">
					<i class="fas fa-stop text-white fa-1x"></i>
				</span>
			</div>
		</div>
	</div>
</div>
