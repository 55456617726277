<app-create-project-overlay-trigger *ngIf="loaded"></app-create-project-overlay-trigger>

<router-outlet></router-outlet>
<ngx-loading-bar></ngx-loading-bar>
<toaster-container [toasterconfig]="_toasterConfig"></toaster-container>
<app-modal-popup></app-modal-popup>


<div id="plugincontainer"></div>


