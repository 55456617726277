import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable({
	providedIn: 'root'
  })
  
export class BucketAssetsService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, page = 0): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.BUCKETS_URL + '/' + id + '/assets?page=' + page
		);
	}

	store(oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.BUCKET_ASSET_URL,
			oData
		);
	}

	update(bucketId, assetId, oData): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.BUCKETS_URL + '/' + bucketId + '/assets/' + assetId,
			oData
		);
	}

	destroy(bucketId, assetId): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.BUCKETS_URL + '/' + bucketId + '/assets/' + assetId
		);
	}
}
