<div class="wrapper">
	<app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
	<div class="main_wrapper">
		<div class="card-main">
			<div class="card-body">
				<h1 class="login-title-mobile-wrapper">Login</h1>
				<accordion [isAnimated]="true" *ngIf="isMobileScreen">
					<accordion-group
						#packageDetailedInfo
						panelClass="mt-2 mb-1 border accordion">
						<div accordion-heading>
							<span><strong class="mobileview-package-wrapper"> See your selected package</strong> 
								<em
									class="float-right mt-1"
									[ngClass]="
									packageDetailedInfo.isOpen
											? 'fa fa-chevron-up'
											: 'fa fa-chevron-down'
								"></em>
							</span>
						</div>
						<div class="row">
							<div
								class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 px-2"
								*ngIf="packageId">
								<app-details-info
									[packageInfo]="packageInfo"></app-details-info>
							</div>
						</div>
					</accordion-group>
				</accordion>
				<div class="col-md-12">
					<div class="row">
						<div
							class="col-12 col-lg-3 col-md-6 col-sm-12 col-xs-12 mt-3 p-2"
							*ngIf="packageId && !isMobileScreen">
							<app-details-info
								[packageInfo]="packageInfo"></app-details-info>
						</div>
						<div
							[ngClass]="
								packageId
									? 'col-lg-9 col-md-6'
									: 'col-lg-12 col-md-12'
							"
							class="col-12 col-sm-12 col-xs-12 mt-3 p-2">
							<div class="login-content-wrapper pt-3">
								<h1 class="login_title">Login</h1>
								<div class="d-flex">
									<div class="d-flex">
										<span class="pr-2">
											<i class="fas fa-user-tie"></i>
										</span>
										<p class="text-size">
											Don't have an account?
										</p>
									</div>
									<div>
										<a
											(click)="
												this._analyticsService.setRouteData(
													2,
													7
												)
											"
											class="underline link-text pl-1"
											[queryParams]="{
												packageId: packageId
											}"
											[routerLink]="
												'/marketplace-signup'
											">
											Signup
										</a>
									</div>
								</div>
							</div>
							<form
								(submit)="submitForm($event, valForm.value)"
								[formGroup]="valForm"
								class="form-validate form-main"
								name="loginForm"
								novalidate=""
								role="form">
								<div class="form-group">
									<label class="label-required" for="email">
										Email
									</label>
									<span class="text-danger pl-1">*</span>
									<div class="input-group">
										<input
											autocomplete="off"
											class="form-control border-right-0"
											formControlName="email"
											name="email"
											required=""
											type="email" />
										<div
											class="input-group-append border-left">
											<span
												class="input-group-text text-muted bg-white">
												<em
													class="fa fa-envelope label-icons text-primary"></em>
											</span>
										</div>
									</div>
									<div
										*ngIf="
											valForm.controls['email'].hasError(
												'required'
											) &&
											(valForm.controls['email'].dirty ||
												valForm.controls['email']
													.touched)
										"
										class="text-danger">
										This field is required
									</div>
									<div
										*ngIf="
											valForm.controls['email'].hasError(
												'email'
											) &&
											(valForm.controls['email'].dirty ||
												valForm.controls['email']
													.touched)
										"
										class="text-danger">
										This field must be a valid email address
									</div>
								</div>

								<div class="form-group">
									<label
										class="label-required"
										for="password">
										Password
									</label>
									<span class="text-danger pl-1">*</span>
									<div class="input-group with-focus">
										<input
											class="form-control border-right-0"
											formControlName="password"
											name="password"
											required=""
											type="password" />
										<div
											class="input-group-append border-left">
											<span
												class="input-group-text text-muted bg-white">
												<em
													class="fa fa-key label-icons text-primary"></em>
											</span>
										</div>
									</div>
									<div
										*ngIf="
											valForm.controls[
												'password'
											].hasError('required') &&
											(valForm.controls['password']
												.dirty ||
												valForm.controls['password']
													.touched)
										"
										class="text-danger">
										This field is required
									</div>
								</div>
								<div class="login-form-action-wrapper pt-3">
									<div>
										<a
											(click)="
												this._analyticsService.setRouteData(
													7,
													13
												)
											"
											class="underline"
											[routerLink]="'/password/reset'">
											Forgot your password?
										</a>
									</div>
									<div class="btn-login-wrapper">
										<button
											class="btn btn-labeled btn-account d-flex justify-content-between align-items-center"
											(click)="
												this._analyticsService.setRouteData(
													79,
													106
												)
											"
											[disabled]="!valForm.valid"
											type="submit">
											<span class="pl-3">
												<i
													class="far fa-circle icon-size"></i>
											</span>
											<p class="mb-0 pr-3 pr-3 pl-2">
												Login
											</p>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
