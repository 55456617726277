import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes/notes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../../../shared/shared.module';
import { StatsComponent } from '../stats/stats/stats.component';
import { PopoverModule } from 'ngx-bootstrap';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		// SharedModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		PopoverModule,
	],
	exports: [],
})
export class NotesModule {}
