<div>
	<div class="modal-header border-0 pt-4 px-4 pb-2" *ngIf="designer">
		<p class="notes-heading-text">
			View, add or edit your notes about {{ designer?.name }}
		</p>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="emitCloseModal.emit()">
			<img src="../../../../../assets/img/team-close-btn.svg" alt="">
		</button>
	</div>
	<div class="modal-body px-4 pb-5 pt-0" *ngIf="showNotes && !isSkeletonLoaded">
		<hr class="mt-0">
		<span *ngIf="creativeNotes.length <= 0" class="font-13">
			You don't have any notes added for {{ designer?.name }}
		</span>

		<div>
			<div
				class="notes pb-3 pt-2"
				*ngFor="let note of creativeNotes; let i = index">
				<div class="d-flex flex-row align-items-start">
					<div class="mr-2 mt-2">
						<img src="../../../../../../../assets/img/chat-dots-filled-teams.svg" alt="">
					</div>
					<div class="w-100 d-flex flex-column note-margin-right">
						<div class="p-2 note-block mb-2">
							<div>
								{{ note?.note }}
							</div>
						</div>
						<div class="date-text date-flex-mob">
							<div>
								{{
									_localStorageService.formatDate(
										note?.created_at,
										'MMM DD, YYYY h:mm A'
									)
								}}
							</div>
							<div class="flex-row icons-mob">
								<div class="mr-3">
									<img src="../../../../../../../assets/img/pencil-teams.svg" alt=""
									(click)="editCreativeNote(note)"
									class="clickable">
								</div>
								<div >
									<img src="../../../../../../../assets/img/trash-grey-large-teams.svg" alt=""
									(click)="
											initDeleteNote(note, deleteNoteModal)
										"
										class="clickable">
								</div>
							</div>
						</div>
					</div>
					<div class="d-flex flex-row mt-2 icons-normal">
						<div class="mr-3">
							<img src="../../../../../../../assets/img/pencil-teams.svg" alt=""
							(click)="editCreativeNote(note)"
							class="clickable">
						</div>
						<div >
							<img src="../../../../../../../assets/img/trash-grey-large-teams.svg" alt=""
							(click)="
									initDeleteNote(note, deleteNoteModal)
								"
								class="clickable">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="clearfix"
			*ngIf="
				creativeNotesPagination['totalRecords'] > creativeNotes.length
			">
			<div class="float-right teams-pagination">
				<pagination
					[totalItems]="creativeNotesPagination['totalRecords']"
					[itemsPerPage]="creativeNotesPagination['itemsPerPage']"
					(pageChanged)="onCreativeNotesPageChanged($event)"
					[maxSize]="7"
					[boundaryLinks]="false"
					[rotate]="true"
					previousText="&lsaquo;" nextText="&rsaquo;"></pagination>
			</div>
		</div>

		<hr />

		<p class="mt-4 add-note-text">
			Add note
		</p>

		<p class="notes-desc mt-3 mb-3">These notes are only seen by you and won't be visible to other users in the account.</p>


		<div>
			<form
				(submit)="submit($event)"
				[formGroup]="creativeNotesForm"
				class="form-validate mb-3"
				name="requirementNotesForm"
				novalidate=""
				role="form"
				#notesForm>
				<div class="input-group mb-4" #scrollBottom>
					<textarea
						rows="3"
						class="form-control"
						placeholder="Add note"
						formControlName="note"></textarea>
				</div>
				<div
					*ngIf="
						creativeNotesForm.controls['note'].hasError(
							'required'
						) &&
						(creativeNotesForm.controls['note'].dirty ||
							creativeNotesForm.controls['note'].touched)
					"
					class="text-danger mb-3">
					Required
				</div>

				<div class="d-flex justify-content-end requirement-notes">
					<div class="btn-group">
						<button
							type="button"
							class="btn btn-secondary float-right"
							*ngIf="isEditNote"
							(click)="clearEditNote()">
							<span>Cancel</span>
						</button>
						<button
							type="submit"
							class="btn add-note-btn ml-2"
							[disabled]="!creativeNotesForm.valid">
							<span *ngIf="!isEditNote">Add Note</span>
							<span *ngIf="isEditNote">Update Note</span>
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>


	<div class="modal-body px-4 pb-5 pt-0" *ngIf="isSkeletonLoaded">
		<hr class="mt-0">

		<div>
			<div
				class="notes pb-3 pt-2"
				*ngFor="let note of [].constructor(3); let i = index">
				<div class="d-flex flex-row align-items-start">
					<div class="mr-2 mt-2">
						<app-skeleton-loader
							[height]="'15px'"
							[width]="'16px'">
						</app-skeleton-loader>
					</div>
					<div class="w-100 d-flex flex-column note-margin-right">
						<div class="p-2 note-block mb-2">
							<div>
								<app-skeleton-loader
									[height]="'21.4px'"
									[width]="'200px'">
								</app-skeleton-loader>
							</div>
						</div>
						<div class="date-text date-flex-mob">
							<div>
								<app-skeleton-loader
									[height]="'14.5px'"
									[width]="'200px'">
								</app-skeleton-loader>
							</div>
							<div class="flex-row icons-mob">
								<div class="mr-3">
									<app-skeleton-loader
										[height]="'16px'"
										[width]="'16px'">
									</app-skeleton-loader>
								</div>
								<div >
									<app-skeleton-loader
										[height]="'18px'"
										[width]="'21px'">
									</app-skeleton-loader>
								</div>
							</div>
						</div>
					</div>
					<div class="d-flex flex-row mt-2 icons-normal">
						<div class="mr-3">
							<app-skeleton-loader
								[height]="'16px'"
								[width]="'16px'">
							</app-skeleton-loader>
						</div>
						<div >
							<app-skeleton-loader
								[height]="'18px'"
								[width]="'21px'">
							</app-skeleton-loader>
						</div>
					</div>
				</div>
			</div>
		</div>

		<hr />

		<p class="mt-4 add-note-text">
			<app-skeleton-loader
				[height]="'21.5px'"
				[width]="'150px'">
			</app-skeleton-loader>
		</p>

		<p class="notes-desc mt-3 mb-3">
			<app-skeleton-loader
				[height]="'14.5px'"
				[width]="'200px'">
			</app-skeleton-loader>
		</p>


		<div>
			<form
				class="form-validate mb-3">
				<div class="mb-4 w-100" #scrollBottom>
					<app-skeleton-loader
						[height]="'78px'"
						[width]="'100%'">
					</app-skeleton-loader>
				</div>

				<div class="d-flex justify-content-end requirement-notes">
					<div class="btn-group">
						<app-skeleton-loader
							[height]="'43px'"
							[width]="'122px'">
						</app-skeleton-loader>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>

<ng-template #deleteNoteModal>
	<div class="modal-header">
		<h4 class="modal-title text-primary">
			Are you sure you want to delete this note?
		</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="deleteNotesRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="note-details">
			<p class="mb-1">
				<em class="fas fa-comment-alt text-primary mr-2"></em>
				<span class="text-muted">
					{{ selectedNote?.note }}
				</span>
			</p>
			<p>
				<span class="font-11">Note added on</span>
				<span class="font-11">
					{{
						_localStorageService.formatDate(
							selectedNote?.created_at,
							'MMM DD, YYYY h:mm A'
						)
					}}
				</span>
			</p>
		</div>
	</div>
	<div class="modal-footer">
		<div class="text-right">
			<button
				type="button"
				class="btn btn-danger ml-2"
				data-dismiss="modal"
				(click)="deleteCreativeNote(selectedNote?.id)">
				Delete Note
			</button>
		</div>
	</div>
</ng-template>
