import {
	Component,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LocalStorageService } from '../../../../services/localstorage.service';
import { ProjectAssignedUserService } from '../../../../services/project/project-assigned-user.service';
import { ProjectService } from '../../../../services/project/project.service';
import { UserService } from '../../../../services/user/user.service';
import { ToasterService } from 'angular2-toaster';
import { FlocksyUtil } from '../../../../common/FlocksyUtil';
import { UserInviteService } from '../../../../services/user/user-invite.service';
import { forkJoin } from 'rxjs';
import { ClientSubClientService } from '../../../../services/client/client-subclient.service';

@Component({
	selector: 'app-sub-user-info',
	templateUrl: './sub-user-info.component.html',
	styleUrls: ['./sub-user-info.component.scss'],
})
export class SubUserInfoComponent {
	@Input() itemsPerSlide = 5;
	selectedSubClientList = [];
	@Output() subUserDetail: any = new EventEmitter();
	subClientUserList = [];
	modalNewInviteUserModal: BsModalRef;
	closeIcon = FlocksyUtil.closeIcon;
	valForm: FormGroup;
	addSubUserImg = FlocksyUtil.addSubUserImg;
	invitedUserList = [];
	permissions = [
		{
			label: 'Manage All Projects',
			id: 'can_manage_all_projects',
		},
		{
			label: 'View All Projects',
			id: 'can_view_projects',
		},
		{
			label: 'Manage Their Projects',
			id: 'can_manage_own_projects',
		},
		{
			label: 'View All & Manage Their Projects',
			id: 'can_view_manage_own_projects',
		},
	];
	permissionsData = {
		permissions: {
			can_view_projects: false,
			can_manage_all_projects: false,
			can_manage_own_projects: false,
			can_view_manage_own_projects: false,
		},
	};
	isInviteValidationShow: boolean;
	isSkeletonLoading = true;
	originalUsersList = [];
	@Input() assignedUsers = [];
	@Input() projectIdFromParams;

	constructor(
		private _projectService: ProjectService,
		private _userService: UserService,
		private _localStorageService: LocalStorageService,
		private _formBuilder: FormBuilder,
		private _toasterService: ToasterService,
		public _userInviteService: UserInviteService,
		private _bsModalService: BsModalService,
		private __clientSubClientService: ClientSubClientService
	) {
		this.initForm();
		this.retrievExistingSubClients();
	}

	initForm() {
		this.valForm = this._formBuilder.group({
			name: [null, Validators.required],
			email: [null, [Validators.required, Validators.email]], // Using built-in Validators.email for simplicity
			permissions: ['', Validators.required],
		});
	}

	onPermissionsSelected(permissionName) {
		if (this.permissionsData.permissions.hasOwnProperty(permissionName)) {
			Object.keys(this.permissionsData.permissions).forEach((key) => {
				this.permissionsData.permissions[key] = false;
			});
			this.permissionsData.permissions[permissionName] = true;
		}
	}

	retrievExistingSubClients() {
		this.__clientSubClientService.index(this._localStorageService.getUserId()).subscribe(
			(data) => {
				this.originalUsersList = data.data;
				this.subClientUserList = [];
				this.selectedSubClientList = [];
				this.retrieveSubClientUser();
			}
		);
	}	
	
	retrieveSubClientUser() {
		this._userInviteService.index(
			this._localStorageService.getUserId(), 
			null, 
			this.projectIdFromParams ? 'projects_assigned' : null
		).subscribe(
			(inviteList) => {
				this.isSkeletonLoading = true;
				this.subClientUserList = [];
				this.selectedSubClientList = [];
	
				this.originalUsersList.forEach((subClient) => {
					if (subClient?.permissions && subClient?.status === 1) {
						let subClientWithGlobalPermissions = {
							...subClient,
							globalPermissions: { ...subClient.permissions },
							permissionUpdated: false
						};
		
						this.subClientUserList.push(subClientWithGlobalPermissions);
						this.selectedSubClientList.push(subClientWithGlobalPermissions);
					}
				});
	
				if (this.assignedUsers && this.assignedUsers.length > 0) {
					this.selectedSubClientList.forEach((subClient) => {
						subClient.has_permission = this.assignedUsers.includes(subClient.id) ? 1 : 0;
					});
				} else {
					this.selectedSubClientList.forEach((subClient) => {
						subClient.has_permission = 1;
					});
				}
	
				let originalListWithProperties = [...this.subClientUserList];
				let selectedListWithProperties = [...this.selectedSubClientList];
	
				inviteList.data.forEach((invitee) => {
					if (invitee?.permissions) {
						let inviteeWithGlobalPermissions = {
							...invitee,
							globalPermissions: { ...invitee.permissions },
							permissionUpdated: false,
							has_permission_enabled: invitee.has_permission_enabled || 1,
							has_permission: invitee.has_permission || 1,
							invited: true
						};
	
						if (invitee.projects_assigned) {
							let matchingProject = invitee.projects_assigned.find(
								project => project.project_id === this.projectIdFromParams
							);
	
							if (matchingProject) {
								inviteeWithGlobalPermissions.has_permission_enabled = matchingProject.has_permission_enabled === 1 ? 1 : 0;
								inviteeWithGlobalPermissions.has_permission = matchingProject.has_permission_enabled === 1 ? 1 : 0;
							} else {
								inviteeWithGlobalPermissions.has_permission_enabled = 0;
								inviteeWithGlobalPermissions.has_permission = 0;
							}
						}
	
						let existingUserIndex = this.subClientUserList.findIndex(user => user.id === inviteeWithGlobalPermissions.id);
						if (existingUserIndex !== -1) {
							originalListWithProperties[existingUserIndex] = {
								...originalListWithProperties[existingUserIndex],
								...inviteeWithGlobalPermissions
							};
	
							selectedListWithProperties[existingUserIndex] = {
								...selectedListWithProperties[existingUserIndex],
								...inviteeWithGlobalPermissions
							};
						} else {
							originalListWithProperties.push(inviteeWithGlobalPermissions);
							selectedListWithProperties.push(inviteeWithGlobalPermissions);
						}
					}
				});
	
				this.subClientUserList = originalListWithProperties;
				this.selectedSubClientList = selectedListWithProperties;
	
				const addUserCard = { isAddUserCard: true };
				const updatedList = [];
	
				for (let i = 0; i < this.subClientUserList.length; i++) {
					updatedList.push(this.subClientUserList[i]);
					if ((i + 1) % 4 === 0) {
						updatedList.push(addUserCard);
					}
				}
	
				if (updatedList[updatedList.length - 1] !== addUserCard) {
					updatedList.push(addUserCard);
				}
	
				this.subClientUserList = updatedList;
	
				this.subUserDetail.emit(this.selectedSubClientList);
	
				this.isSkeletonLoading = false;
			}
		);
	}	
	
	submitForm($ev, value: any) {
		this.isSkeletonLoading = true;
		this.isInviteValidationShow = true;
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}
		if (!this.valForm.valid) {
			return false;
		}
		
		let copiedPermissions = JSON.parse(JSON.stringify(this.permissionsData.permissions));
	
		let oParam = {
			name: this.valForm.value.name,
			email: this.valForm.value.email,
			permissions: copiedPermissions,
		};
	
		this._userService
			.storeUsersInvite(this._localStorageService.getUserId(), oParam)
			.subscribe((data) => {
				this.valForm.reset();
				this.modalNewInviteUserModal.hide();
				this._toasterService.pop('success', 'Invite sent!', 'Go team!');
				this.retrieveSubClientUser();
				this.isInviteValidationShow = false;
			});
	}	

	initNewInviteUserModal(template: TemplateRef<any>) {
		this.isInviteValidationShow = false;
		this.modalNewInviteUserModal = this._bsModalService.show(
			template,
			Object.assign({}, {}, {})
		);
	}

	selectSubClient(id) {
		
		// Initialize variables to track the indices
		let selectedSubClientIndex = -1;
		let subClientUserIndex = -1;
	
		// Manually loop through subClientUserList to find the object with the matching id
		for (let i = 0; i < this.subClientUserList.length; i++) {
			if (this.subClientUserList[i].id === id) {
				subClientUserIndex = i;
				break;
			}
		}
	
		// Manually loop through selectedSubClientList to find the object with the matching id
		for (let j = 0; j < this.selectedSubClientList.length; j++) {
			if (this.selectedSubClientList[j].id === id) {
				selectedSubClientIndex = j;
				break;
			}
		}
	
		// Check if both objects with the matching id are found
		if (selectedSubClientIndex !== -1 && subClientUserIndex !== -1) {
			this.subClientUserList[subClientUserIndex].has_permission = 1;
			this.selectedSubClientList[selectedSubClientIndex].has_permission = 1;
			this.subUserDetail.emit(this.selectedSubClientList);
		}
	}
	

	unSelectSubClient(id) {
		
		// Initialize variables to track the indices
		let selectedSubClientIndex = -1;
		let subClientUserIndex = -1;
	
		// Manually loop through subClientUserList to find the object with the matching id
		for (let i = 0; i < this.subClientUserList.length; i++) {
			if (this.subClientUserList[i].id === id) {
				subClientUserIndex = i;
				break;
			}
		}
	
		// Manually loop through selectedSubClientList to find the object with the matching id
		for (let j = 0; j < this.selectedSubClientList.length; j++) {
			if (this.selectedSubClientList[j].id === id) {
				selectedSubClientIndex = j;
				break;
			}
		}
	
		// Check if both objects with the matching id are found
		if (selectedSubClientIndex !== -1 && subClientUserIndex !== -1) {
			// Reset has_permission to 0
			this.subClientUserList[subClientUserIndex].has_permission = 0;
			this.selectedSubClientList[selectedSubClientIndex].has_permission = 0;
	
			// Reset permissions to globalPermissions
			this.subClientUserList[subClientUserIndex].permissions = { 
				...this.subClientUserList[subClientUserIndex].globalPermissions 
			};
			this.selectedSubClientList[selectedSubClientIndex].permissions = { 
				...this.selectedSubClientList[selectedSubClientIndex].globalPermissions 
			};
	
			// Set permissionUpdated to false
			this.subClientUserList[subClientUserIndex].permissionUpdated = false;
			this.selectedSubClientList[selectedSubClientIndex].permissionUpdated = false;
	
			// Emit the updated selectedSubClientList
			this.subUserDetail.emit(this.selectedSubClientList);
		}
	}	
	
	changePermission(user, type) {
		const updatePermissions = (user, type) => {
			// Reset all permissions to false
			user.permissions.can_manage_all_projects = false;
			user.permissions.can_manage_own_projects = false;
			user.permissions.can_view_manage_own_projects = false;
			user.permissions.can_view_projects = false;
	
			// Set the relevant permission based on type
			if (type === 'manage') {
				user.permissions.can_manage_all_projects = true;
			} else if (type === 'view') {
				user.permissions.can_view_projects = true;
			}
	
			// Mark the permission as updated
			user.permissionUpdated = true;
		};
	
		// Update permissions in both selectedSubClientList and subClientUserList
		[this.selectedSubClientList, this.subClientUserList].forEach(list => {
			const index = list.findIndex(item => item.id === user.id);
			if (index !== -1) {
				updatePermissions(list[index], type);
			}
		});
	
		// Emit the updated lists
		this.subUserDetail.emit(this.selectedSubClientList);
	}	
	
}
