import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-gear-icon',
	templateUrl: './gear-icon.component.html',
	styleUrls: ['./gear-icon.component.scss'],
})
export class GearIconComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
