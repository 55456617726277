import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TimerSharedService } from '../../../services/time-entries/timer-shared.service';
import { LocalStorageService } from '../../../services/localstorage.service';
import { TimeEntriesService } from '../../../services/time-entries/time-entries.service';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { FlocksyUtil } from '../../../common/FlocksyUtil';
import { FlocksyTimerComponent } from '../flocksy-timer/flocksy-timer.component';
import { ProjectService } from '../../../services/project/project.service';
import { CommonService } from '../../../services/common/common.service';
@Component({
	selector: 'app-earning-timer',
	templateUrl: './earning-timer.component.html',
	styleUrls: ['./earning-timer.component.scss'],
})
export class EarningTimerComponent implements OnInit {
	@Input() desktop_view = true;
	activeTimer = false;
	showDropdown = false;
	showSwitchTimer = false;
	flocksyTimer = false;
	isDetailScreen;
	modalStopTimer: BsModalRef;
	triggerId;
	currentTime = new Date();
	totalTime: number;
	projectId;
	projectName;
	atimerDiffrence;
	currentEntryId;
	hours = 0;
	minutes = 0;
	seconds = 0;
	showButton = false;
	switchTimerValue;
	timerTriggers = FlocksyUtil.timerTriggers();
	routerUrl = this.router.url.substring(this.router.url.indexOf('/') + 1);
	timerEntry = {};
	manualTimerForm: FormGroup;
	currentDiscription;
	stopTimerRef: BsModalRef;
	@ViewChild('stopTimerModal', { static: true })
	stopTimerModal: ElementRef<HTMLElement>;
	projectParams;
	getTimer: Subscription;
	autoTimerForm: FormControl;
	entriesById = new BehaviorSubject([]);
	switchEntryId;
	switchTimerForm: FormControl;
	isPlatinumLevel = this._localStorageService.isPlatinumLevel();
	projectUrl;
	limitValue = this._timerSharedService.stopHours;
	stopHours = this._timerSharedService.stopHours;
	entriesArray = [];
	isModelActive: boolean = false;
	currentUser = this._localStorageService.getCurrentUser();
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	@ViewChild('switchTimerReff') switchTimerReff: any;
	model: boolean = false;
	@ViewChild(FlocksyTimerComponent)
	private timerComponent: FlocksyTimerComponent;
	project;
	isAutotimer: boolean = false;
	isManualtimer: boolean = false;
	isSwitchtimer: boolean = false;
	isProjectLoading: boolean = false;
	isStopTimerBtnDisabled: boolean;
	isSwitchTimerBtnDisabled = false;

	constructor(
		private _timerEntriesService: TimeEntriesService,
		private router: Router,
		public _localStorageService: LocalStorageService,
		public _bsModalService: BsModalService,
		public _formBuilder: FormBuilder,
		public _toasterService: ToasterService,
		public _timerSharedService: TimerSharedService,
		public _analyticsService: AnalyticsService,
		public _projectService: ProjectService,
		public _commonService: CommonService
	) {
		router.events.subscribe((val) => {
			this.closeTimerDropdown();
		});
	}

	ngOnInit() {
		this.index();
		this.checkHoursGreaterThanLimit();
		this.initManualTimer();
		this.initForms();

		this._timerSharedService.showTimerLimit
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe((res) => {
				if (res == true && this.isModelActive == false) {
					this.initStopTimerModal();
					this.isModelActive = true;
					this._timerSharedService.showTimerLimit.next(false);
				}
			});
	}

	getProjectUrl() {
		this.router.events
			.pipe(filter((e) => e instanceof NavigationEnd))
			.subscribe((navEnd: NavigationEnd) => {
				this.projectUrl = navEnd.urlAfterRedirects;
			});
	}

	initForms() {
		this.autoTimerForm = new FormControl('', Validators.required);
		this.switchTimerForm = new FormControl('', Validators.required);
	}

	initManualTimer() {
		this.manualTimerForm = this._formBuilder.group({
			hours: ['', [Validators.required]],
			minutes: ['', [Validators.required]],
			description: ['', [Validators.required]],
		});
	}

	index() {
		return new Promise((resolve, reject) => {
			this._timerEntriesService
				.index(this.currentUser['id'], { 'filter[method]': 'current' })
				.pipe(
					takeUntil(this.destroyed$),
					catchError((err) => {
						return throwError(err);
					})
				)
				.subscribe(
					(res) => {
						if (res.data.data.length > 0) {
							this.activeTimer = true;
							this.convertHMS(res.data.data[0].elapsed_seconds);
							this.currentDiscription =
								res.data.data[0].description;
							this.currentEntryId = res.data.data[0].id;
							resolve('Done');
						} else {
							resolve('Done');
						}

						this.showButton = true;
					},
					(err) => {
						this._toasterService.pop('error', err);
						reject();
					}
				);
		});
	}

	oldcontinueTimer() {
		this.isModelActive = false;
		this.limitValue = this.limitValue + this.stopHours;
		this._timerSharedService.timerLimit.next(this.limitValue);
	}

	async continueTimer() {
		this.index()
			.then((data) => {
				this.setNewTimerOverlayLimit();
			})
			.catch((error) => console.log(error));
	}

	setNewTimerOverlayLimit() {
		new Promise((resolve, reject) => {
			this.isModelActive = false;
			this.limitValue = this.hours + this.stopHours;
			this._timerSharedService.timerLimit.next(this.limitValue);
			resolve('1');
		});
	}

	checkHoursGreaterThanLimit() {
		if (this.hours >= this.limitValue) {
			this.initStopTimerModal();
			this.isModelActive = true;
			this.limitValue = this.hours;
		}
	}

	initStopTimerModal() {
		this.isModelActive = false;
		this.stopTimerRef = this._bsModalService.show(
			this.stopTimerModal,
			Object.assign({
				modalClass: 'modal-dialog',
				class: 'modal-xl modal-full',
			})
		);
	}

	convertHMS(value, switchValue?) {
		const sec = parseInt(value, 10);
		let hours = Math.floor(sec / 3600); // get hours
		let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
		let seconds = sec - hours * 3600 - minutes * 60;

		this.hours = hours;
		this.minutes = minutes;
		this.seconds = seconds;
	}

	startAutoTimer() {
		this.isModelActive = false;
		this.getProjectId();
		this.timerEntry['description'] = this.autoTimerForm.value;
		this.timerEntry['trigger_id'] = +this.triggerId;
		this._timerEntriesService
			.store(this.projectParams, this.timerEntry)
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe(
				(res) => {
					this.newTimer();
					this.activeTimer = true;
					this.seconds = 0;
					this.showDropdown = false;
					this.currentEntryId = res.data.id;
					this.currentDiscription = this.autoTimerForm.value;

					if (this.projectId && this.projectName) {
						this._toasterService.pop(
							'success',
							`You've started a timer for ${this.projectName}`
						);
					} else {
						this._toasterService.pop(
							'success',
							`Your timer has been started!`
						);
					}
					this.projectParams = {};
					this.autoTimerForm.reset();
					this.projectId = '';
					this.projectName = '';
				},
				(err) => {}
			);
	}

	startManualTimer() {
		this.getProjectId();
		this.showDropdown = false;
		this.timerEntry['description'] =
			this.manualTimerForm.get('description').value;
		this.timerEntry['hours'] = +this.manualTimerForm.get('hours').value;
		this.timerEntry['minutes'] = +this.manualTimerForm.get('minutes').value;
		this.timerEntry['trigger_id'] = this.timerTriggers['manual-timer'];
		let params = {
			method: 'manual',
		};
		if (this.projectId) {
			params['project_id'] = this.projectId;
			this.isProjectLoading = true;
		}

		this._timerEntriesService
			.store(params, this.timerEntry)
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe(
				(res) => {
					this.currentEntryId = res.data.id;
					this.isProjectLoading = false;
					if (this.projectId && this.projectName) {
						this._toasterService.pop(
							'success',
							`You've started a timer for ${this.projectName}`
						);
					} else {
						this._toasterService.pop('success', res.message);
					}

					this.showDropdown = false;
					this.projectParams = {};
					this.projectId = '';
					this.project = null;
					this.manualTimerForm.reset();
				},
				(err) => {}
			);
	}

	newTimer() {
		this.hours = 0;
		this.minutes = 0;
	}

	switchTimerPopup() {
		let intervalTimer = interval(1000);
		this.getTimer = intervalTimer.subscribe(
			(res) =>
				(this.switchTimerValue = this.switchTimerReff.displayString)
		);
		this.showSwitchTimer = true;
		this.getProjectId();
	}

	closeSwitchTimerDropdown() {
		this.showSwitchTimer = false;
		this.switchTimerForm.reset();
	}

	unsubscribeTimer() {
		this.getTimer.unsubscribe();
	}

	switchTimer() {
		this.showButton = false;
		this.isSwitchTimerBtnDisabled = true;
		this.getProjectId();
		let switchEntryData = {
			description: this.switchTimerForm.value,
			trigger_id: this.timerTriggers['switch-tasks'],
		};
		if (this.projectId) {
			switchEntryData['project_id'] = this.projectId;
		}
		let entryId = this.switchEntryId
			? this.switchEntryId
			: this.currentEntryId;
		this._timerEntriesService
			.update(entryId, switchEntryData, {
				action: 'switch',
			})
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					this.isSwitchTimerBtnDisabled = false;
					return throwError(err);
				})
			)
			.subscribe((res) => {
				this.activeTimer = false;
				this.isSwitchTimerBtnDisabled = false;
				this.index();
				this.closeSwitchTimerDropdown();
				this.timerComponent.clearInterval();
				(this.currentDiscription = this.switchTimerForm.value),
					this.switchTimerForm.reset();
				if (this.projectId && this.projectName) {
					this._toasterService.pop(
						'success',
						`You've started a timer for ${this.projectName}`
					);
				} else {
					this._toasterService.pop('success', res.message);
				}
			});
	}

	getProjectId() {
		this.isDetailScreen = this._timerSharedService.isDetailScreen;
		if (this.isDetailScreen) {
			this.projectId = this.projectId;
			this.projectName = this._timerSharedService.projectName;
			this.triggerId = this.timerTriggers['auto-timer'];
			this.timerEntry['project_id'] = this.projectId;
			this.projectParams = { project_id: this.projectId };
		} else {
			this.projectId = this.projectId;
			this.projectName = '';
			this.projectParams = { project_id: this.projectId };
			this.triggerId = this.timerTriggers['other-tasks'];
		}
	}

	closeTimerDropdown() {
		this.showDropdown = false;
		this.autoTimerForm.reset();
		this.manualTimerForm.reset();
	}

	stopTimerPopup() {
		this.model = false;
		this._analyticsService.setOverlayTrackData(16, 89);
		this.stopTimer();
		this.limitValue = this._timerSharedService.stopHours;
		this._timerSharedService.timerLimit.next(this.limitValue);
	}

	stopTimerButton() {
		this._analyticsService.setOverlayTrackData(16, 88);
		this.stopTimer();
	}

	stopTimer() {
		this.timerComponent.clearInterval();
		this.closeSwitchTimerDropdown();
		this.cleaupEntry();
		this.timerEntry['trigger_id'] = this.timerTriggers['stop-timer'];
		this.isStopTimerBtnDisabled = true;
		this._timerEntriesService
			.update(this.currentEntryId, this.timerEntry)
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe(
				(res) => {
					this.activeTimer = false;
					this.isStopTimerBtnDisabled = false;
					this.limitValue = this._timerSharedService.stopHours;
					this._timerSharedService.timerLimit.next(
						this._timerSharedService.stopHours
					);

					this._toasterService.pop(
						'success',
						'Timer has been stopped successfully'
					);
				},
				(err) => {
					this.activeTimer = false;
					this.isStopTimerBtnDisabled = false;
					this._toasterService.pop('error', err.errors.text);
				}
			);
	}

	cleaupEntry() {
		this.timerEntry = {};
	}

	startTimerOverlay() {
		this.showDropdown = true;
	}

	getProject(projectString) {
		this.project = null;
		this.projectId = '';
		let isSlashExist = projectString.includes('/');
		let projectId;
		if (isSlashExist) {
			let projectStringSplit = projectString.split('/');
			projectId = projectStringSplit[4];
		} else {
			projectId = projectString;
		}

		if (projectId && Number(projectId) && projectId.length > 4) {
			this.isProjectLoading = true;
			this._projectService
				.get(projectId, {})
				.pipe(
					takeUntil(this.destroyed$),
					catchError((err) => {
						return throwError(err);
					})
				)
				.subscribe(
					(data) => {
						this.isProjectLoading = false;
						this.projectId = data.data.project_id;
						this.project = data.data;
					},
					(err) => {
						if (err.status == 422) {
							this._toasterService.pop(
								'error',
								'Project not found'
							);
						}
					}
				);
		} else {
			this.project = null;
			this.projectId = '';
		}
	}

	startTimerGetProject() {
		this.getProject(this.autoTimerForm.value);
		this.isAutotimer = true;
		this.isManualtimer = false;
		this.isSwitchtimer = false;
	}

	switchTimerGetProject() {
		this.getProject(this.switchTimerForm.value);
		this.isAutotimer = false;
		this.isManualtimer = false;
		this.isSwitchtimer = true;
	}

	manualTimerGetProject() {
		this.getProject(this.manualTimerForm.get('description').value);
		this.isAutotimer = false;
		this.isManualtimer = true;
		this.isSwitchtimer = false;
	}

	changeInHour(event) {
		if (
			+event.target.value &&
			!+this.manualTimerForm.get('minutes').value
		) {
			this.manualTimerForm.get('minutes').setValue(0);
			this.manualTimerForm.get('minutes').clearValidators();
			this.manualTimerForm.get('minutes').updateValueAndValidity();
		} else if (
			!+event.target.value &&
			+this.manualTimerForm.get('minutes').value
		) {
			this.manualTimerForm.get('hours').setValue(0);
			this.manualTimerForm.get('hours').updateValueAndValidity();
		} else if (
			!+event.target.value &&
			!+this.manualTimerForm.get('minutes').value
		) {
			this.manualTimerForm.get('minutes').setValue(0);
			this.manualTimerForm
				.get('minutes')
				.setValidators([Validators.min(1)]);
			this.manualTimerForm.get('minutes').updateValueAndValidity();
		}
	}

	changeInMinute(event) {
		if (+event.target.value && !+this.manualTimerForm.get('hours').value) {
			this.manualTimerForm.get('hours').setValue(0);
			this.manualTimerForm.get('hours').updateValueAndValidity();
		} else if (
			!+event.target.value &&
			+this.manualTimerForm.get('hours').value
		) {
			this.manualTimerForm.get('minutes').setValue(0);
			this.manualTimerForm.get('minutes').updateValueAndValidity();
		} else if (
			!+event.target.value &&
			!+this.manualTimerForm.get('hours').value
		) {
			this.manualTimerForm.get('minutes').setValue(0);
			this.manualTimerForm
				.get('minutes')
				.setValidators([Validators.min(1)]);
			this.manualTimerForm.get('minutes').updateValueAndValidity();
		}
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
