<div class="toggle-wrapper">
	<label class="toggle-container" [class.disabled]="isDisabled">
		<input
			type="checkbox"
			id="{{ id }}"
			[checked]="checked"
			[disabled]="isDisabled"
			(change)="onCheckboxChange($event)" />
		<span class="toggle-slider round"></span>
	</label>
</div>
