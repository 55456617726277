import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-rating-icon',
	templateUrl: './rating-icon.component.html',
	styleUrls: ['./rating-icon.component.scss'],
})
export class RatingIconComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
