import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class CouponService {
	constructor(private _customHttpService: CustomHttpService) {}

	exists(code, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.COUPONS_URL +
				'/' +
				code +
				(params.toString() ? '?' + params.toString() : '')
		);
	}
}
