import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
	@Input() bShowProgressBar = false;
	@Input() showLabel = true;
	@Input() uploadedFile = [];
	@Input() copyBriefFile = [];
	@Input() aiImageList = [];
	uploadFileIcon = FlocksyUtil.uploadFileIcon;
	oUploader = {};
	oBaseDropZoneOver = {};
	@Output() unprocessedFiles = new EventEmitter<File[]>();
	@Output() fileDetail = new EventEmitter();
	@Output() aiImageDetail = new EventEmitter();
	@Output() copyBriefFileDetail = new EventEmitter();
	@Input() showUploadedFiles: boolean = true;
	imageMimeTypes = FlocksyUtil.getImageMimeTypes();
	defaultFileImg = FlocksyUtil.defaultFileImg;
	deleteIcon = FlocksyUtil.delete;
	fileLoaderShown: boolean;
	deletedFile = [];

	newUploadedFile: File[] = [];

	@ViewChild('fileInput') fileInput: ElementRef;
	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private sanitizer: DomSanitizer,
	) {}

	fileOverBase(e: any): void {
		this.oUploader = new FileUploader({ url: 'your-upload-url' });
		this.oBaseDropZoneOver = e;
	}

	onFileDropped(event: any) {
		let promise = new Promise<boolean>((res, rej) => {
			this.fileLoaderShown = true;
			if (event.length) {
				let filesProcessed = 0;
				for(let i = 0;i< event.length; i++) {
					let fileData: any = this.uploadedFile.findIndex((res)=> {
						return res.name == event[i].name
					})
					if(fileData == -1) {
						this.fileLoaderShown = true;
						var reader = new FileReader();
						reader.onload = (events: any) => {
							this.fileLoaderShown = true;
							filesProcessed++;
							this.uploadedFile.push({
								name: event[i].name,
								content: events.target.result,
								file: event[i],
							});
							this.fileLoaderShown = false;
							if((event.length) == filesProcessed) {
								setTimeout(()=>{
									this.fileInput.nativeElement.value = '';
								})
								res(true);
							}
						};
						reader.readAsDataURL(event[i]);
					}
				}
				this._changeDetectorRef.detectChanges();
			}
		})
		promise.then(() => {
			this.fileDetail.emit(this.uploadedFile);
			this.fileLoaderShown = false
		})
	}

	deleteUploadedImg(index: number) {
		let aiImgIndex = this.aiImageList.findIndex(
			(res) => res?.id == this.uploadedFile[index]?.id
		);
		if (aiImgIndex > -1) {
			delete this.aiImageList[aiImgIndex].isSelected;
		}
		this.uploadedFile.splice(index, 1);
		this.fileDetail.emit(this.uploadedFile);
		this.aiImageDetail.emit(this.aiImageList);
	}

	unsetCopyDoc(index) {
		if (this.copyBriefFile) {
			if (index !== -1) {
				this.deletedFile.push(this.copyBriefFile[index].id);
				this.copyBriefFile.splice(index, 1);
				let excludeDocumentFile = [];
				this.copyBriefFile.forEach((res) => {
					excludeDocumentFile.push(res.id);
				});
				this.copyBriefFileDetail.emit({
					excludeDocumentFile: excludeDocumentFile,
					deletedFile: this.deletedFile,
				});
			}
		}
	}

	preventDoubleClick(event: MouseEvent): void {
		event.preventDefault();
	}

	cleanURL(oldURL ): SafeUrl {
		return   this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
	}
}
