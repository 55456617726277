import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class UserSubClientService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/sub-clients'
		);
	}
}
