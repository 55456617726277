import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ProjectExcludeDesignersService {
	constructor(private _customHttpService: CustomHttpService) {}

	store(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/exclude-designers',
			oData
		);
	}

	excludeTeam(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/designers/excludes/team',
			oData
		);
	}
}
