<div class="px-2">
	<app-modal-header [data]="modalHeaderData" [config]="{showClose: false}"></app-modal-header>
	<div class="modal-body py-4">
		<p class="mb-4 legacy-text">
			<strong>You are currently benefiting from legacy pricing.</strong>
			This means that while our platform has evolved, and we've grown and enhanced our creative services and the Flocksy platform,
			<strong>your grandfathered Flocksy plan remains at the original price - a recurring savings of up to 30%</strong> - as a gesture of appreciation for your continued trust and loyalty.
		</p>
		<h5>Thank you for using Flocksy!</h5>
	</div>
	<app-modal-footer [data]="modalFooterData"></app-modal-footer>
</div>