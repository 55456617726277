import { Pipe, PipeTransform } from '@angular/core';
import linkifyHtml from 'linkifyjs/html';

/*
 * Converts links from HTML strings
 */
@Pipe({ name: 'linkifyHtml' })
export class LinkifyHtmlPipe implements PipeTransform {
	transform(value: string, args?: string[]): any {
		if(value) {
			value = linkifyHtml(value);
		}
		return value;
	}
}
