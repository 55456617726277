import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '@common/constant/ApiConstants';
import { CustomHttpService } from '../custom.http.service';
import { GenericHttpResponse } from '@services/interface';
import { EmojiMasterData, EmojiReactionList } from '@shared/module/emoji-reaction/interface';

@Injectable({
	providedIn: 'root'
})
export class EmojiReactionService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(): Observable<GenericHttpResponse<EmojiMasterData[]>> {
		return this._customHttpService.get<GenericHttpResponse<EmojiMasterData[]>>(
			API_CONSTANTS.API_ENDPOINT_URL + '/emoji-reactions'
		);
	}

	// addProjectChatReaction(projectId: number, chatId: number, emojiId: number) {
	// 	const apiUrl = `${API_CONSTANTS.PROJECTS_URL}/${projectId}/chats/${chatId}/emoji-reactions`;
	// 	return this._customHttpService.post<any>(apiUrl, {emoji_reaction_id: emojiId})
	// }

	getEmojiReactions(qParams): Observable<GenericHttpResponse<EmojiReactionList[]>> {
		let params = new URLSearchParams();
		for (const key of Object.keys(qParams)) {
			if (qParams[key]) {
				if (qParams[key] instanceof Array) {
					qParams[key].forEach((item) => {
						params.append(key, item);
					});
				} else {
					params.set(key, qParams[key]);
				}
			}
		}
		const url = API_CONSTANTS.API_ENDPOINT_URL+'/project-chats1/emoji-reactions'+(params ? '?'+params.toString():'')
		return this._customHttpService.get<GenericHttpResponse<EmojiReactionList[]>>(url);
	}
}
