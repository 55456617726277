import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { CustomHttpService } from './../custom.http.service';
import { environment } from '@env/environment';
import { API_CONSTANTS } from '@common/constant/ApiConstants';
import * as uuid from 'uuid';
import { FlocksyUtil } from '@common/FlocksyUtil';

@Injectable({
	providedIn: 'root',
})
export class S3DocumentService {
	constructor(public _customHttpService: CustomHttpService) {}

	CONFIG = {
		accessKeyId: environment.S3accessKeyId,
		secretAccessKey: environment.S3secretAccessKey,
		region: environment.S3region,
		useAccelerateEndpoint: true,
		httpOptions: {
			timeout: FlocksyUtil.getS3fileUploadTimeout(),
		},
		correctClockSkew: true,
	};
	S3BucketsMap = {
		1: 'profile_pic',
		2: 'project_assets',
		3: 'bucket_assets',
		4: 'email_assets',
		5: 'template_library_assets',
		6: 'onboarding_assets',
		7: 'cover_pic',
		8: 'project_feedback_assets',
		9: 'broadcast_banner_assets',
		10: 'message_assets',
		12: 'package_assets',
		13: 'custom_domain_assets'
	};
	S3BucketsMapIds = {
		project_assets: 2,
	};
	s3 = new S3(this.CONFIG);

	uploadFile(files, document_category_id) {
		let file_hash = '';

		if (files.length != 1) {
			return;
		} else {
			file_hash = files[0].file_hash;
		}

		const params = {
			Bucket: environment.S3Bucket,
			Key: this.S3BucketsMap[document_category_id] + '/' + file_hash,
			Body: files[0],
			ACL: 'public-read',
			ContentType: files[0].type,
		};

		return {
			bucket: this.s3,
			params: params,
		};
	}

	uploadFiles(files, document_category_id, callback) {
		let promises = [];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			promises.push(this.upload(file, document_category_id, callback));
		}
		return promises;
	}

	async upload(file, document_category_id, callback) {
		return new Promise(async (resolve, reject) => {
			const params = {
				Bucket: environment.S3Bucket,
				Key:
					this.S3BucketsMap[document_category_id] +
					'/' +
					file.file_hash,
				Body: file,
				ACL: 'public-read',
				ContentType: file.type,
			};

			try {
				await this.s3
					.upload(params)
					.on('httpUploadProgress', callback)
					.send(async (err, data) => {
						if (err) {
							if (err.code === 'RequestTimeTooSkewed') {
								const currentTime = await this.getCorrectTime();
								const localTime = new Date();
								this.s3.config.systemClockOffset =
									currentTime.getTime() - localTime.getTime();
								resolve(
									this.upload(
										file,
										document_category_id,
										callback
									)
								);
							} else {
								reject(err);
								throw err;
							}
						} else {
							resolve(data);
						}
					});
			} catch (err) {
				reject(err);
			}
		});
	}

	getCorrectTime() {
		return fetch(API_CONSTANTS.WORLDTIME_API)
			.then((response) => response.json())
			.then((data) => new Date(data.utc_datetime));
	}

	uploadBase64Files(files, document_category_id, callback) {
		let promises = [];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			promises.push(
				this.uploadBase64(file, document_category_id, callback)
			);
		}
		return promises;
	}

	async uploadBase64(file, document_category_id, callback) {
		return new Promise(async (resolve, reject) => {
			const params = {
				Bucket: environment.S3Bucket,
				Key:
					this.S3BucketsMap[document_category_id] +
					'/' +
					file.file_hash,
				Body: file.file,
				ContentEncoding: 'base64',
				ACL: 'public-read',
				ContentType: file.type,
			};

			try {
				await this.s3
					.upload(params)
					.on('httpUploadProgress', callback)
					.send(async (err, data) => {
						if (err) {
							if (err.code === 'RequestTimeTooSkewed') {
								const currentTime = await this.getCorrectTime();
								const localTime = new Date();
								this.s3.config.systemClockOffset =
									currentTime.getTime() - localTime.getTime();
								resolve(
									this.uploadBase64(
										file,
										document_category_id,
										callback
									)
								);
							} else {
								throw err;
							}
						} else {
							resolve(data);
						}
					});
			} catch (err) {
				reject(err);
			}
		});
	}

	generateFileHash(files) {
		files.forEach((file) => {
			// if (file?.file_hash) {
			// 	let fileExt = file.name.split('.').pop();
			// 	file.file_hash = file.file_hash + '.' + fileExt;
			// } else {
			let fileExt = file.name.split('.').pop();
			file.file_hash = this.getRandomString(64) + '.' + fileExt;
			// }
		});
		return files;
	}

	getRandomString(length) {
		var randomChars =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var result = '';
		for (var i = 0; i < length; i++) {
			result += randomChars.charAt(
				Math.floor(Math.random() * randomChars.length)
			);
		}
		const ts = new Date().valueOf();
		const unique_id = uuid.v4();
		return result + '_' + ts + unique_id;
	}

	storeDocumentFiles(oData) {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/documents/files',
			oData
		);
	}

	updateDocumentFiles(id, oData) {
		return this._customHttpService.put(
			API_CONSTANTS.API_ENDPOINT_URL + '/documents/' + id + '/files/edit',
			oData
		);
	}
}
