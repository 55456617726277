<div *ngIf="userBlockIsVisible() && false" class="item user-block cursor-auto">
	<!-- User picture-->
	<div class="user-block-picture">
		<div class="user-block-status">
			<img
				[src]="profileImageUrl"
				alt="Avatar"
				class="img-thumbnail thumb60 rounded-circle"
				onError="this.src='../../../../assets/img/user-placeholder.png';" />
			<div class="circle bg-primary circle-lg"></div>
		</div>
	</div>
	<!-- Name and Job-->
	<div class="user-block-info">
		<span class="user-block-name">
			{{ _localStorageService.getUserFullName() }}
		</span>
	</div>
	<div
		(click)="initCreativeLevelModal(creativeLevelModal)"
		*ngIf="creative_level_id"
		class="text-center clickable">
		<span
			*ngIf="creative_level_id == creativeLevelId['basic']"
			class="badge badge-success d-inline-block">
			Entry Level
		</span>
		<span
			*ngIf="creative_level_id == creativeLevelId['standard']"
			class="badge badge-success d-inline-block">
			Standard Level
		</span>
		<span
			*ngIf="creative_level_id == creativeLevelId['premium']"
			class="badge badge-success d-inline-block">
			Premium Level
		</span>
		<span
			*ngIf="creative_level_id == creativeLevelId['platinum']"
			class="badge badge-success d-inline-block">
			Platinum Level
		</span>
		<span
			*ngIf="creative_level_id == creativeLevelId['platinum-lead']"
			class="badge badge-success d-inline-block">
			Platinum Lead
		</span>
	</div>
</div>

<div
	*ngIf="userBlockIsVisible() && !isClientUser && !isSubClientUser"
	class="card card-flat user-block"
	style="background-color: inherit">
	<div class="card-body">
		<div class="row align-items-center no-gutters">
			<div class="col-5 text-center">
				<div class="user-block-picture">
					<div class="user-block-status">
						<img
							[src]="profileImageUrl"
							alt="Avatar"
							class="img-thumbnail thumb60 rounded-circle"
							onError="this.src='../../../../assets/img/user-placeholder.png';" />
						<div class="circle bg-primary circle-lg"></div>
					</div>
				</div>
			</div>
			<div class="col-7">
				<div class="d-flex">
					<div class="text-left">
						<div class="p mb-0">Welcome</div>
						<span class="user-block-name text-bold text-primary">
							{{ _localStorageService.getUserFullName() }}
						</span>
						<span
							(click)="initCreativeLevelModal(creativeLevelModal)"
							*ngIf="creative_level_id"
							class="text-center clickable">
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['basic']
								"
								class="badge badge-success d-inline-block">
								Entry Level
							</span>
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['standard']
								"
								class="badge badge-success d-inline-block">
								Standard Level
							</span>
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['premium']
								"
								class="badge badge-success d-inline-block">
								Premium Level
							</span>
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['platinum']
								"
								class="badge badge-success d-inline-block">
								Platinum Level
							</span>
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['platinum-lead']
								"
								class="badge badge-success d-inline-block">
								Platinum Lead
							</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div
	*ngIf="userBlockIsVisible() && (isClientUser || isSubClientUser)"
	class="card card-flat user-block p-0 user-profile"
	style="background-color: inherit">
	<div class="card-body pt-0">
		<div id="profileicon">
			<div class="text-center">
				<div class="user-block-picture">
					<div class="user-block-status">
						<img
							[src]="profileImageUrl"
							alt="Avatar"
							class="img-thumbnail thumb60 rounded-circle p-0 mb-3"
							onError="this.src='../../../../assets/img/user-placeholder.png';" />
						<!-- <div class="circle bg-primary circle-lg"></div> -->
					</div>
				</div>
			</div>
			<div class="text-center">
				<div class="">
					<div class="text-center">
						<div class="">
							<div
								class="dropdown-toggle dropdown dropdown-list text-primary mb-2"
								dropdown
								dropdownToggle>
								<span class="user-block-name mr-2 clickable">
									{{ _localStorageService.getUserFullName() }}
								</span>
								<img
									class="clickable"
									src="../../../../assets/img/dashboard/angle-down-solid.svg"
									alt="down" />
								<div
									*dropdownMenu
									class="dropdown-menu dropdown-menu-right shadow dropdown-menu-wrapper">
									<div class="dropdown-item">
										<!-- START list group-->
										<div class="list-group">
											<div
												class="list-group-item list-group-item-action">
												<a
													(click)="
														_analyticsService.setRouteData(
															28,
															79
														)
													"
													[routerLink]="'/profile'"
													style="color: inherit">
													<span
														class="d-flex align-items-center"
														[ngClass]="{
															activeTab:
																currentUrl ==
																'/profile'
														}">
														Settings
													</span>
												</a>
											</div>
											<div
												*ngIf="isDesignerUser"
												class="list-group-item list-group-item-action">
												<a
													(click)="
														_analyticsService.setRouteData(
															37,
															102
														)
													"
													[routerLink]="
														'/profile/share'
													"
													style="color: inherit">
													<span
														[ngClass]="{
															activeTab:
																currentUrl ==
																'/profile/share'
														}"
														class="d-flex align-items-center">
														Share My Profile
													</span>
												</a>
											</div>
											<div
												*ngIf="isDesignerUser"
												class="list-group-item list-group-item-action">
												<a
													[routerLink]="
														'/projects/preferences'
													"
													style="color: inherit">
													<span
														(click)="
															_analyticsService.setRouteData(
																34,
																95
															)
														"
														[ngClass]="{
															activeTab:
																currentUrl ==
																'/projects/preferences'
														}"
														class="d-flex align-items-center">
														Project Filters
													</span>
												</a>
											</div>
											<div
												class="list-group-item list-group-item-action">
												<a
													(click)="
														_analyticsService.setRouteData(
															29,
															82
														)
													"
													[routerLink]="
														'/change-password'
													"
													style="color: inherit">
													<span
														[ngClass]="{
															activeTab:
																currentUrl ==
																'/change-password'
														}"
														class="d-flex align-items-center">
														Change Password
													</span>
												</a>
											</div>
											<div
												*ngIf="
													isAdminUser ||
													isProjectManagerUser
												"
												class="list-group-item list-group-item-action">
												<a
													(click)="
														_analyticsService.setRouteData(
															41,
															111
														)
													"
													[routerLink]="
														'/emails/send'
													"
													style="color: inherit">
													<span
														[ngClass]="{
															activeTab:
																currentUrl ==
																'/emails/send'
														}"
														class="d-flex align-items-center">
														Send Email
													</span>
												</a>
											</div>
											<!-- <div
											*ngIf="isClientUser"
											class="list-group-item list-group-item-action">
											<a
												(click)="_analyticsService.setRouteData(30, 85)"
												[routerLink]="'/billing-info'"
												style="color: inherit">
												<span
													class="d-flex align-items-center"
													style="color: inherit">
													Billing Info
												</span>
											</a>
										</div> -->
											<div
												*ngIf="
													isDesignerUser ||
													isProjectManagerUser ||
													isProjectManagerAsAM
												"
												class="list-group-item list-group-item-action">
												<a
													(click)="
														_analyticsService.setRouteData(
															31,
															88
														)
													"
													[routerLink]="'/finance'"
													style="color: inherit">
													<!-- <span
														[ngClass]="{
															activeTab:
																currentUrl ==
																'/finance'
														}"
														*ngIf="isClientUser"
														class="d-flex align-items-center">
														Receipts
													</span> -->
													<span
														*ngIf="
															isDesignerUser ||
															isProjectManagerUser ||
															isProjectManagerAsAM
														"
														class="d-flex align-items-center">
														Earnings
													</span>
												</a>
											</div>

											<div
												*ngIf="
													!isOperationalClientUser &&
													(isPlatinumLead ||
														isPlatinumLevel ||
														isProjectManagerAsAM ||
														isProjectManagerUser ||
														isAdminUser)
												"
												class="list-group-item list-group-item-action">
												<a
													[routerLink]="
														'/payment/time-tracker'
													"
													class="color-black">
													<span
														[ngClass]="{
															activeTab:
																currentUrl ==
																'/payment/time-tracker'
														}"
														class="d-flex align-items-center">
														Timer Tracker
													</span>
												</a>
											</div>
											<div
												*ngIf="
													_localStorageService.isClientUser()
												"
												class="list-group-item list-group-item-action">
												<a
													(click)="
														_analyticsService.setRouteData(
															31,
															88
														)
													"
													[routerLink]="
														'/finance-and-billing'
													"
													style="color: inherit">
													<span
														[ngClass]="{
															activeTab:
																currentUrl ==
																'/finance-and-billing'
														}"
														*ngIf="isClientUser"
														class="d-flex align-items-center">
														Upgrading & Billing
													</span>
												</a>
											</div>
											<div
												*ngIf="isDesignerUser"
												class="list-group-item list-group-item-action">
												<a
													href="{{ paypalUrlPrefix }}"
													target="_blank"
													class=" "
													style="color: inherit">
													<span
														class="d-flex align-items-center">
														Paypal Email
													</span>
												</a>
											</div>
											<!-- last list item-->
											<div
												class="list-group-item list-group-item-action">
												<a
													[routerLink]="'/logout'"
													style="color: inherit">
													<span
														class="d-flex align-items-center">
														Logout
													</span>
												</a>
											</div>
										</div>
										<!-- END list group-->
									</div>
								</div>
							</div>
							<div
								class="d-flex align-items-center justify-content-center my-2"
								*ngIf="!isSubscriptionInfoShown && isClientUser">
								<img
									src="assets/img/plans/fire.svg"
									alt="Special Rate" />
								<span class="text-disabled mx-2">
									Special Rate
								</span>
								<em *ngIf="!isWhiteLabelledUser" (click)="showLegacyInformation()" class="fa fa-info-circle text-primary clickable"></em>

							</div>
						</div>
						<span
							(click)="initCreativeLevelModal(creativeLevelModal)"
							*ngIf="creative_level_id"
							class="text-center clickable">
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['basic']
								"
								class="badge badge-success d-inline-block">
								Entry Level
							</span>
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['standard']
								"
								class="badge badge-success d-inline-block">
								Standard Level
							</span>
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['premium']
								"
								class="badge badge-success d-inline-block">
								Premium Level
							</span>
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['platinum']
								"
								class="badge badge-success d-inline-block">
								Platinum Level
							</span>
							<span
								*ngIf="
									creative_level_id ==
									creativeLevelId['platinum-lead']
								"
								class="badge badge-success d-inline-block">
								Platinum Lead
							</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div
	class="col mt-3 mb-3 pr-1 pl-4"
	*ngIf="_localStorageService.isDesignerUser()">
	<em class="fa mr-2 fas text-primary fa-user-clock pl-2"></em>
	<span class="text mb-3">Your availability</span>
	<em
		class="fa text-primary fa-info-circle clickable pl-2"
		tooltip="
            When you're offline, clients won't be able to invite you to 
    projects and you won't be notified about new projects. 
    Custom statuses are treated the same as offline statuses. 
        "
		triggers="click"></em>
	<ul class="navbar-nav flex-row border bg-white rounded w-100">
		<li
			class="nav-item dropdown dropdown-list w-100"
			dropdown
			#dropdown="bs-dropdown"
			[autoClose]="false">
			<a
				class="nav-link text-dark w-100 d-flex justify-content-between align-items-center pt-1 pr-2 pl-2 pb-1"
				dropdownToggle
				href="javascript:">
				<div class="d-flex align-items-center">
					<em
						class="fa user-svg mr-2 fas fa-circle text-danger align-items-center"
						*ngIf="message_id == 0"></em>
					<em
						class="fa user-svg mr-2 fas fa-circle text-primary align-items-center"
						*ngIf="message_id == 1"></em>
					<em
						class="fa user-svg mr-2 fas fa-circle text-danger align-items-center"
						*ngIf="message_id > 1"></em>
					<small
						class="d-none d-lg-inline text-bold p-2 text-truncate"
						style="display: block !important"
						title="{{ availibilityText }} {{
							message_id > 1 ? '(Offline)' : ''
						}}"
						[(ngModel)]="availibilityText"
						name="fieldName"
						ngDefaultControl>
						{{ availibilityText }}
						{{ message_id > 1 ? '(Offline)' : '' }}
					</small>
				</div>
				<i class="fas fa-angle-down pl-2"></i>
			</a>
			<!-- START Dropdown menu-->

			<div
				*dropdownMenu
				class="dropdown-menu dropdown-menu-right shadow m-0"
				style="min-width: 171px">
				<div
					class="dropdown-item d-flex justify-content-between flex-column">
					<div class="d-flex flex-column">
						<div class="list-group" *ngFor="let status of aStatus">
							<div
								class="list-group-item list-group-item-action"
								style="padding-left: 3px !important">
								<div
									class="d-flex clickable justify-content-between"
									(click)="
										changeAvailibility(status.id);
										dropdown.hide()
									">
									<div class="d-flex">
										<div class="p-1" *ngIf="status.id == 1">
											<em
												class="fa mr-2 fas fa-circle text-primary"></em>
										</div>
										<div
											class="p-1 mb-0"
											*ngIf="status.id == 0">
											<em
												class="fa mr-2 fas fa-circle text-danger"></em>
										</div>
										<div
											class="p-1 user-svg"
											*ngIf="status.id > 1">
											<em
												class="fa mr-2 fas fa-circle text-danger"></em>
										</div>
										<div
											class="p-1 text-truncate status-list"
											title="{{ status.value }} {{
												status.id > 1 ? '(Offline)' : ''
											}}">
											{{ status.value }}
											{{
												status.id > 1 ? '(Offline)' : ''
											}}
										</div>
									</div>
									<div class="p-1">
										<em
											class="fa fas fa-check text-primary"
											*ngIf="
												status.id == message_id
											"></em>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="list-group group_data mb-2">
						<div class="list-group-item list-group-item-action">
							<div>
								<span>
									<input
										class="form-control"
										placeholder="Add custom status"
										type="text"
										#message
										form
										(click)="$event.stopPropagation()"
										[formControl]="custom"
										(keyup.enter)="
											createCustomMessage(message.value);
											dropdown.hide()
										" />
								</span>
							</div>
						</div>
					</div>
					<!-- END list group-->
				</div>
			</div>
			<!-- END Dropdown menu-->
		</li>
	</ul>
</div>

<ng-template #creativeLevelModal>
	<div class="modal-header">
		<h4 class="modal-title">Creative Levels</h4>
		<button
			(click)="modalCreativeLevelRef.hide()"
			aria-label="Close"
			class="close"
			type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="container container-xl">
			<div class="row">
				<div
					*ngFor="let level of aLevels"
					[ngClass]="
						_localStorageService.isPlatinumLevel()
							? 'col-lg-3'
							: 'col-lg-4'
					">
					<div class="card b">
						<div
							[ngClass]="{
								'bg-green': level.id == creative_level_id
							}"
							class="card-body text-center bb">
							<h4 class="mb-0">{{ level.name }} Level</h4>
						</div>
						<div class="p-2 bg-gray text-center">
							<h5 class="mb-0 text-bold">Criteria</h5>
						</div>
						<div class="card-body">
							<div
								*ngFor="let criteria of level.criteria"
								class="mb-1">
								<div>
									<em
										*ngIf="criteria.passed"
										class="fa fa-check text-primary mr-2"></em>
									<em
										*ngIf="!criteria.passed"
										class="fa fa-times text-danger mr-2"></em>
									{{ criteria.title }}
								</div>
								<div
									*ngIf="level.id == 1"
									style="margin-bottom: 12rem"></div>
								<div
									*ngIf="
										level.id == 2 &&
										criteria.identifier == 'been-with-us'
									"
									style="margin-bottom: 4.5rem"></div>
							</div>
						</div>
						<div class="p-2 bg-gray text-center">
							<h5 class="mb-0 text-bold">Benefits</h5>
						</div>
						<div class="card-body">
							<p *ngFor="let benefit of level.benefits">
								<em
									*ngIf="benefit.is_checkmark"
									class="fa fa-check text-primary mr-2"></em>
								<em
									*ngIf="!benefit.is_checkmark"
									class="fa fa-times text-danger mr-2"></em>
								{{ benefit.title }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
