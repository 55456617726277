import { Inject, Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '@common/constant/ApiConstants';
import { ToasterService } from 'angular2-toaster';
import { S3DocumentService } from './s3-document.service';

@Injectable()
export class DocumentService {
	S3BucketsMapIds = this._S3documentService.S3BucketsMapIds;
	constructor(
		private _customHttpService: CustomHttpService,
		public _toasterService: ToasterService,
		private _S3documentService: S3DocumentService
	) {}

	store(oData): Observable<any> {
		return this._customHttpService.postFormData(
			API_CONSTANTS.API_ENDPOINT_URL + '/documents',
			oData
		);
	}

	storeDocumentFiles(id, oData, oFiles) {
		return this._customHttpService.postFormData(
			API_CONSTANTS.API_ENDPOINT_URL + '/documents/' + id + '/files',
			oData,
			oFiles
		);
	}

	deleteDocumentFiles(id: any, oData: any) {
		const params = new URLSearchParams();
		for (const data of oData) {
			params.set('ids[' + data + ']', data);
		}
		return this._customHttpService.delete(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/documents/' +
				id +
				'/files?' +
				params.toString()
		);
	}

	downloadFile(
		id: string,
		related_module?: number,
		related_id?: number
	): any {
		return this.generateToken(id, 0, related_module, related_id).subscribe(
			(data) => {
				if (data.data) {
					const url =
						API_CONSTANTS.DOCUMENTS_URL +
						'/' +
						id +
						'/download?token=' +
						data.data['token'];
					window.location.href = url;
					return false;
				}
			},
			(err) => {}
		);
	}

	downloadFileCopyToClipboard(id: string) {
		return this.generateToken(id, 1).subscribe(
			(data) => {
				if (data.data) {
					const url =
						API_CONSTANTS.DOCUMENTS_URL +
						'/' +
						id +
						'/download?token=' +
						data.data['token'];
					this.copyToClipboard(url);
					this._toasterService.pop(
						'success',
						'URL has been copy to clipboard successfully'
					);
					return false;
				}
			},
			(err) => {}
		);
	}

	copyToClipboard(string) {
		let textarea;
		let result;

		try {
			textarea = document.createElement('textarea');
			textarea.setAttribute('readonly', true);
			textarea.setAttribute('contenteditable', true);
			textarea.style.position = 'fixed';
			textarea.value = string;

			document.body.appendChild(textarea);

			textarea.focus();
			textarea.select();

			const range = document.createRange();
			range.selectNodeContents(textarea);

			const sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(range);

			textarea.setSelectionRange(0, textarea.value.length);
			result = document.execCommand('copy');
		} catch (err) {
			console.error(err);
			result = null;
		} finally {
			document.body.removeChild(textarea);
		}

		/* manual copy fallback using prompt */
		if (!result) {
			const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
			const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
			result = prompt(`Press ${copyHotkey}`, string);
			if (!result) {
				return false;
			}
		}
		return true;
	}

	generateToken(
		id,
		operation?: number,
		related_module?: number,
		related_id?: number
	) {
		let oPost = {};
		if (operation) {
			oPost['operation'] = operation;
		}
		oPost['related_module'] = related_module ? related_module : 0;
		oPost['related_id'] = related_id ? related_id : 0;

		return this._customHttpService.post(
			API_CONSTANTS.DOCUMENTS_URL + '/' + id + '/token',
			oPost
		);
	}

	downloadMassBucketFiles(project_id: any): any {
		return this.generateTokenForBucketMassDownload(project_id).subscribe(
			(data) => {
				if (data) {
					const url =
						API_CONSTANTS.PROJECTS_URL +
						'/' +
						project_id +
						'/buckets/mass-download?token=' +
						data.token;
					window.location.href = url;
					return false;
				}
			},
			(err) => {}
		);
	}

	generateTokenForBucketMassDownload(project_id: string) {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				project_id +
				'/buckets/mass-download',
			''
		);
	}

	updateFilePayload(filesInfo) {
		let fileData = [];
		let postFileData = [];
		postFileData.push({
			filename: filesInfo.name,
			file_hash: filesInfo.file_hash,
		});
		fileData.push({
			name: filesInfo.name,
			description: filesInfo.name || filesInfo.text,
			document_category_id: this.S3BucketsMapIds['project_assets'],
			files: postFileData,
		});
		return { fileData, postFileData };
	}

	uploadFiles(files, documentId, callBack) {
		return this._S3documentService.uploadFiles(files, documentId, callBack);
	}

	postCopyBriefFile(id, data) {
		return this._customHttpService.post(
			`${API_CONSTANTS.API_ENDPOINT_URL}/documents/${id}/files/copy`,
			data
		);
	}
}
