import {
	Component,
	Input,
	OnInit,
	OnDestroy,
	TemplateRef,
	Output,
	EventEmitter,
} from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../../../../../../services/localstorage.service';
import { ReplaySubject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { StatService } from '../../../../../../services/client/creative/stat/stat.service';
import { FlocksyUtil } from '../../../../../../common/FlocksyUtil';


@Component({
	selector: 'app-stats',
	templateUrl: './stats.component.html',
	styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit, OnDestroy {
	@Input() designer;
	clientId = this._localStorageService.getUserId();
	modalStatsRef: BsModalRef;
	stats;
	statsShow = false;
	types = [1,2,3,4,5];
	teamsProjectTypes = FlocksyUtil.teamsProjectTypes;

	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	deleteNotesRef: BsModalRef;
	@Output() emitCloseStatsModal = new EventEmitter();
	isSkeletonLoading = true;

	constructor(
		public _formBuilder: FormBuilder,
		public _bsModalService: BsModalService,
		private _statService: StatService,
		public _localStorageService: LocalStorageService
	) {}

	ngOnInit(): void {
		this.retrieveCreativeStats();
	}

	retrieveCreativeStats() {
		const data = {};
		data['include'] = [
			'project_types_worked',
			'projects_worked',
			'projects_completed',
			'average_rating',
		];
		return this._statService
			.index(this.clientId, this.designer.id, data)
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe((data) => {
				this.stats = data.data;
				console.log(this.stats);
				this.statsShow = true;
				this.isSkeletonLoading = false;
			});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	getStarsArray(rating: number): any[] {
		return new Array(rating);
	}

	getEmptyStarsArray(rating: number): any[] {
		let remainingStars = 5 - rating;
		return new Array(remainingStars);
	}

	getFirstNameApostrophe(fullName: string): string {
		const firstName = fullName.split(' ')[0];
		return firstName.endsWith('s') ? `${firstName}'` : `${firstName}'s`;
	}
}
