import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs-compat';
import { API_CONSTANTS } from '../../../common/constant/ApiConstants';
import { CustomHttpService } from '../../../services/custom.http.service';

@Injectable()
export class UserblockService {
	public userBlockVisible: boolean;
	updatedStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
	updatedStatus$ = this.updatedStatus.asObservable();

	constructor(private _customHttpService: CustomHttpService) {
		// initially visible
		this.userBlockVisible = true;
	}

	getVisibility() {
		return this.userBlockVisible;
	}

	setVisibility(stat = true) {
		this.userBlockVisible = stat;
	}

	toggleVisibility() {
		this.userBlockVisible = !this.userBlockVisible;
	}

	getSidebarData(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/sidebar'
		);
	}
}
