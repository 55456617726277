import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChildren,
} from '@angular/core';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ProjectService } from '@services/project/project.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, forkJoin } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ProjectInterface } from '../../interface';
@Component({
	selector: 'app-ai-assist',
	templateUrl: './ai-assist.component.html',
	styleUrls: ['./ai-assist.component.scss'],
})
export class AiAssistComponent implements OnDestroy, OnChanges, OnInit {
	private aiTextDestroyed$: Subject<boolean> = new Subject<boolean>();
	private aiImgDestroyed$: Subject<boolean> = new Subject<boolean>();
	@ViewChildren('dynamicDiv') divElements: QueryList<ElementRef>;
	divHeights: any = [];
	@ViewChildren('resizeTextarea') directionTextInfo: QueryList<ElementRef>;
	@ViewChildren('textarea') textarea: any;
	@Input() directionList:ProjectInterface.Direction[] = [];
	@Input() copyBriefFile = [];
	@Input() aiImageList = [];
	@Input() isValidationShown = false;
	@Input() isSaveProject = false;
	@Input() isMarketplaceValidationShown = false;
	@Input() isMarketplaceTab = false;
	@Input() aiDirectionTextList: ProjectInterface.Direction[] = [];
	@Input() aiDetails: any;
	@Input() aiConfig: any = {};
	@Input() selectedPackage: any;
	@Output() updatedDirectionList = new EventEmitter();
	@Output() UpdatedFileList = new EventEmitter();
	@Output() aiImgDetails = new EventEmitter();
	@Output() aiDetail = new EventEmitter();
	aiInfo = {};
	bulbIcon = FlocksyUtil.bulbIcon;
	grayBulbIcon = FlocksyUtil.grayBulbIcon;
	editIcon = FlocksyUtil.editIcon;
	deleteIcon = FlocksyUtil.deleteIcon;
	trueIcon = FlocksyUtil.trueIcon;
	MoveIcon = FlocksyUtil.MoveIcon;
	plusIcon = FlocksyUtil.plusIcon;
	closeIcon = FlocksyUtil.closeIcon;
	whitePlusIcon = FlocksyUtil.whitePlusIcon;
	whiteDeleteIcon = FlocksyUtil.whiteDeleteIcon;
	searchIcon = FlocksyUtil.searchIcon;
	aiTextModalRef: BsModalRef;
	aiImgModalRef: BsModalRef;
	aiImgSliderModalRef: BsModalRef;
	updateDirection = '';
	directionText = '';
	aiImgGeneratText = '';
	bShowProgressBar = false;
	textProgressBar = false;
	isSpinnerShown = false;
	validationMessageShown = false;
	btnDisabled = {};
	progressBarPercentage: any;
	directionProgressBarPercentage: any;
	customDirective: string;
	pendingProjects: any;
	addAiBtnEnable: boolean;
	isTooltipShown: boolean;
	editAiBtnEnable: boolean;
	regenerateMessageShown: boolean;
	activeSlide: number = 0;

	constructor(
		private _bsModalService: BsModalService,
		private _projectService: ProjectService,
		private sanitizer: DomSanitizer
	) {}
	ngOnInit(): void {
		this._projectService.isSaveProject.subscribe((res) => {
			if (res) {
				this.saveDirectionInfo(
					this.textarea?._results[0]?.nativeElement
				);
			this.directionList.forEach((res, index)=>{
					if(res.isUpdate){
						this.directionList[index]['text'] = res?.updateDirection.trim();
						delete this.directionList[index]['isUpdate'];
						delete this.directionList[index]['updateDirection'];
						this.updatedDirectionList.emit(this.directionList);
					}
				})
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['isSaveProject'] && !!this.isSaveProject) {
			this.saveDirectionInfo();
		}
		if (changes['aiDetails']) {
			this.aiImageList = this.aiDetails?.aiImageList || [];
			this.aiDirectionTextList =
				this.aiDetails?.aiDirectionTextList || [];
			this.aiImgGeneratText = this.aiDetails?.aiImgGeneratText || '';
			this.directionText = this.aiDetails?.directionText || '';
		}
	}

	ngAfterViewInit() {
		this.directionTextInfo.changes.subscribe(() => {
				setTimeout(() => {
					this.directionTextarea();
				})
			}
		);
		this.setCustomHeight();
	}

	saveDirectionInfo(textarea?: HTMLTextAreaElement) {
		if (textarea?.value.trim()) {
			const urlPattern =
				/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
			let textWithLinks = textarea.value.replace('www.', 'https://www.');
			textWithLinks = textWithLinks.replace(urlPattern, (url) => {
				url = url.replace('https://www.', 'www.');
				const fullUrl = url.startsWith('www.') ? `http://${url}` : url;
				return `<a href="${fullUrl}" class="direction-link" target="_blank">${url}</a>`;
			});
			let directionText: ProjectInterface.Direction = {
				textHtml: textWithLinks.replace(/\n/g, '<br>'),
				has_from_openai: false,
				has_from_create: true,
				has_from_update: false,
				isUpdate: false,
				text: textarea.value.trim(),
			};
			if (!this.directionList?.length) {
				this.directionList = [];
			}
			this.directionList.push(directionText);
			this.customDirective = '';
			textarea.style.height = 'auto';
			textarea.rows = 2;
			this.updatedDirectionList.emit(this.directionList);
			setTimeout(() => {
				this.directionTextarea();
			});
			this.setCustomHeight();
		}
	}

	editAiDirectionText(index) {
		this.aiDirectionTextList[index]['isEdit'] = true;
		this.aiDirectionTextList[index]['updatedText'] =
			this.aiDirectionTextList[index]?.text;
	}
	saveUpdatedDirectionText(index) {
		this.aiDirectionTextList[index]['isEdit'] = false;
		if(this.aiDirectionTextList[index] && this.aiDirectionTextList[index].text && this.aiDirectionTextList[index].updatedText){
			if(this.aiDirectionTextList[index].text.trim() !== this.aiDirectionTextList[index].updatedText.trim()) {
				this.aiDirectionTextList[index].has_openai_edited = true;
			}
			this.aiDirectionTextList[index]['text'] = this.aiDirectionTextList[index]?.updatedText.trim();
			const urlPattern =
				/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
			let textWithLinks = this.aiDirectionTextList[index].text.replace('www.', 'https://www.');
			textWithLinks = textWithLinks.replace(urlPattern, (url) => {
				url = url.replace('https://www.', 'www.');
				const fullUrl = url.startsWith('www.') ? `http://${url}` : url;
				return `<a href="${fullUrl}" class="direction-link" target="_blank">${url}</a>`;
			});
			this.aiDirectionTextList[index].textHtml = textWithLinks.replace(/\n/g, '<br>');
			for(let i=0; i<this.directionList.length; i++) {

				if(this.aiDirectionTextList[index]['isSelected'] && this.aiDirectionTextList[index].id == this.directionList[i].id) {
					this.directionList[i] = { ...this.aiDirectionTextList[index] };
				}
			}
			this.updatedDirectionList.emit(this.directionList);
		}
	}

	updateDirectionInfo(index, textarea?) {
		if (!!textarea.value.trim()) {
			const urlPattern =
				/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
			let textWithLinks = textarea.value.replace('www.', 'https://www.');
			textWithLinks = textWithLinks.replace(urlPattern, (url) => {
				url = url.replace('https://www.', 'www.');
				const fullUrl = url.startsWith('www.') ? `http://${url}` : url;
				return `<a href="${fullUrl}" class="direction-link" target="_blank">${url}</a>`;
			});
			this.directionList[index].textHtml = textWithLinks.replace(
				/\n/g,
				'<br>'
			);
			if(this.checkAiEdited(this.directionList[index], textarea.value.trim())) {
				this.directionList[index]["has_openai_edited"] = true;
			}
			this.directionList[index]['text'] = textarea.value.trim();
			delete this.directionList[index]['isUpdate'];
			delete this.directionList[index]['updateDirection'];
			textarea.style.height = 'auto';
			textarea.rows = 2;
			this.updatedDirectionList.emit(this.directionList);
			setTimeout(() => {
				this.directionTextarea();
			},);
		}
		this.setCustomHeight();
	}

	checkAiEdited(direction:ProjectInterface.Direction, updatedDirection:string): boolean {
		if(direction?.has_from_openai && !direction?.has_openai_edited) {
			return updatedDirection !== direction.text;
		}
		return false;
	}

	deleteDirection(index: number) {
		let directionIndex = this.aiDirectionTextList.findIndex(
			(res) => res?.id == this.directionList[index]?.id
		);
		if (directionIndex > -1) {
			delete this.aiDirectionTextList[directionIndex]['isSelected'];
		}
		this.directionList.splice(index, 1);
		this.updatedDirectionList.emit(this.directionList);
	}

	toggleEditMode(index: number) {
		this.directionList[index]['isUpdate'] = true;
		this.directionList[index]['updateDirection'] =
			this.directionList[index].text;
	}

	onKeyDown(event: KeyboardEvent, textarea, index?): void {
		if(index > -1){
			this.directionList[index]['updateDirection'] = textarea.value;
		}
		if (event.ctrlKey && event.key === 'Enter') {
			if (index > -1) {
				this.updateDirectionInfo(index, textarea);
			} else {
				this.saveDirectionInfo(textarea);
			}
		}
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(
			this.directionList,
			event.previousIndex,
			event.currentIndex
		);
		this.updatedDirectionList.emit(this.directionList);
		this.setCustomHeight();
	}

	adjustTextarea(event: any, index?): void {
		if (index > -1) {
			this.directionList[index]['updateDirection'] = event.target.value;
		}
		const textarea: HTMLTextAreaElement =
			event.target as HTMLTextAreaElement;
		const currentLines = textarea.value.split('\n').length;

		if (currentLines < 20) {
			textarea.rows = 2;
			textarea.style.height = 'auto';
			textarea.style.height = `${textarea.scrollHeight + 5}px`;
		}
		else {
			textarea.style.height = 'auto';
			textarea.rows = 20;
		}
	}

	directionTextarea(): void {
		this.directionTextInfo.forEach((textareaRef) => {
			const textarea: HTMLTextAreaElement = textareaRef.nativeElement;
			const currentLines = textarea.value.split('\n').length;
			if (currentLines <= 20) {
				textarea.rows = 2;
				textarea.style.height = 'auto';
				textarea.style.height = `${textarea.scrollHeight + 5}px`;
			} else {
				const lastChar = textarea.value.substr(-1);
				if (lastChar === '\n') {
					textarea.value = textarea.value.substring(
						0,
						textarea.value.length - 1
					);
				}
			}
		});
	}

	deleteAiDirection(index: number) {
		let directionIndex = this.directionList.findIndex((res) => {
			return res.id == this.aiDirectionTextList[index]['id'];
		});
		if (directionIndex > -1) {
			this.unSelectDirection(index);
		}
		this.aiDirectionTextList.splice(index, 1);
	}

	openAITextModal(template: any) {
		this.btnDisabled['textHistory'] = false;
		this.validationMessageShown = false;
		this.aiTextModalRef = this._bsModalService.show(
			template,
			Object.assign({
				keyboard: false,
				backdrop: 'static',
				modalClass: 'modal-dialog',
				class: 'modal-lg modal-full ai-img-modal',
			})
		);
	}

	directionTextDetail(index, event) {
		this.directionList[index].value = event.target.value;
	}

	openAIImgModal(template: any) {
		if (this.aiImgSliderModalRef) {
			this.aiImgSliderModalRef.hide();
		}
		this.btnDisabled['imgHistory'] = false;
		this.aiImgModalRef = this._bsModalService.show(
			template,
			Object.assign({
				keyboard: false,
				backdrop: 'static',
				modalClass: 'modal-dialog',
				class: 'modal-lg modal-full ai-img-modal',
			})
		);
	}

	hideModalDetail(data?) {
		if (data) {
			this.aiTextModalRef.hide();
			this.btnDisabled['textHistory'] = true;
		} else {
			this.aiImgModalRef.hide();
			this.btnDisabled['imgHistory'] = true;
		}
	}

	openAIImgSliderModal(template: any, index: number) {
		this.activeSlide = index;
		this.aiImgModalRef.hide();
		this.btnDisabled['imgHistory'] = false;
		this.aiImgSliderModalRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{
					modalClass: 'modal-dialog',
					class: 'modal-lg modal-full ai-img-modal',
				}
			)
		);
	}

	unSelectDirection(index) {
		this.aiDirectionTextList[index]['isEdit'] = false;
		this.aiDirectionTextList[index]['isSelected'] = false;
		let directionIndex = this.directionList.findIndex((res) => {
			return res.id == this.aiDirectionTextList[index]['id'];
		});
		this.customDirective = '';
		this.directionList.splice(directionIndex, 1);
		this.updatedDirectionList.emit(this.directionList);
	}

	unSelectImg(index) {
		this.aiImageList[index]['isSelected'] = false;
		let directionIndex = this.selectedPackage['uploadedFile'].findIndex(
			(res) => {
				return res.img_Id == this.aiImageList[index]['img_Id'];
			}
		);
		this.customDirective = '';
		this.selectedPackage['uploadedFile'].splice(directionIndex, 1);
		this.UpdatedFileList.emit(this.selectedPackage['uploadedFile']);
	}

	postAIText() {
		if (!this.aiDirectionTextList.length) {
			this.directionProgressBarPercentage = '1';
			this.textProgressBar = true;
			let data = {
				project_title_id:
					this.selectedPackage['selectedTitleId'] ||
					this.selectedPackage['title'],
				user_prompt: this.directionText,
			};

			this.isSpinnerShown = true;
			this.btnDisabled['aiTextGenerationBtn'] = true;
			this._projectService
				.postAIText(this.selectedPackage['type'], data)
				.pipe(takeUntil(this.aiTextDestroyed$))
				.subscribe(
					(res) => {
						this.aiDirectionTextList = [];
						this.btnDisabled['aiTextGenerationBtn'] = false;
						res.data.forEach((resData, index) => {
							this.aiDirectionTextList.push({
								text: resData,
								id: index + 1,
								has_from_openai: true,
								has_openai_edited: false,
								has_from_create: true,
								has_from_update: false,
								textHtml: resData
							});
							this.directionProgressBarPercentage = Math.floor(
								(this.aiDirectionTextList.length * 100) /
									res.data.length
							);
						});
						this.textProgressBar = false;
						this.isSpinnerShown = false;
					},
					() => {
						this.isSpinnerShown = false;
						this.textProgressBar = false;
						this.btnDisabled['aiTextGenerationBtn'] = false;
					}
				);
		} else {
			this.regenerateMessageShown = true;
		}
	}

	imgGeneration() {
		if (this.aiImgGeneratText && this.selectedPackage['title']) {
			let data: any = {
				project_title_id:
					this.selectedPackage['selectedTitleId'] ||
					this.selectedPackage['title'],
				user_prompt: this.aiImgGeneratText,
			};
			this.aiImageList = [];
			let imageList = [];
			this.progressBarPercentage = 1;
			this.bShowProgressBar = true;
			this.btnDisabled['aiImgGenerationBtn'] = true;
			const apiCalls = Array.from(
				{ length: this.aiConfig['openai_image_suggestions'] },
				() =>
					this._projectService.postAIImgText(
						this.selectedPackage['type'],
						data
					)
			);
			forkJoin(apiCalls)
				.pipe(
					catchError(() => {
						this.deleteAiInfo();
						return [];
					})
				)
				.subscribe(
					(items) => {
						items.forEach((res, index) => {
							const byteCharacters = atob(res?.data?.b64_json);
							const byteNumbers = new Array(
								byteCharacters.length
							);
							for (let i = 0; i < byteCharacters.length; i++) {
								byteNumbers[i] = byteCharacters.charCodeAt(i);
							}
							const byteArray = new Uint8Array(byteNumbers);
							let url: any = new Blob([byteArray], {
								type: 'image/jpeg	',
							});
							imageList.push({
								img_Id: index + 1,
								file_hash: res?.data?.b64_json,
								fullPath: this.sanitizer.bypassSecurityTrustUrl(
									URL.createObjectURL(url)
								),
							});
							if (
								index ==
								this.aiConfig['openai_image_suggestions'] - 1
							) {
								this.btnDisabled['aiImgGenerationBtn'] = false;
								this.aiImageList = imageList;
								this.bShowProgressBar = false;
								this.aiImgDetails.emit(this.aiImageList);
							}
							this.progressBarPercentage = Math.floor(
								(imageList.length * 100) /
									this.aiConfig['openai_image_suggestions']
							);
						});
					},
					() => {
						this.btnDisabled['aiImgGenerationBtn'] = false;
						this.deleteAiInfo();
						this.bShowProgressBar = false;
					}
				);
		}
	}

	aiDirectionInfo(index) {
		this.aiDirectionTextList[index]['isEdit'] = false;
		let directionText: ProjectInterface.Direction = {
			id: this.aiDirectionTextList[index]['id'],
			text: this.aiDirectionTextList[index]['text'],
			has_from_openai: this.aiDirectionTextList[index].has_from_openai,
			has_from_create: this.aiDirectionTextList[index].has_from_create,
			has_from_update: this.aiDirectionTextList[index].has_from_update,
			has_openai_edited: this.aiDirectionTextList[index].has_openai_edited,
			textHtml: this.aiDirectionTextList[index].text,
			isUpdate: this.aiDirectionTextList[index].isUpdate,
		};
		if (!this.directionList?.length) {
			this.directionList = [];
		}
		this.directionList.push(directionText);
		this.updatedDirectionList.emit(this.directionList);
		setTimeout(() => {
			this.directionTextarea();
		});
	}

	onDeleteAiImg(index: number) {
		let selectedImgIndex = this.selectedPackage['uploadedFile'].findIndex(
			(res) => {
				return res.img_Id == this.aiImageList[index]['img_Id'];
			}
		);
		if (selectedImgIndex > -1) {
			this.selectedPackage['uploadedFile'].splice(selectedImgIndex, 1);
			this.UpdatedFileList.emit(this.selectedPackage['uploadedFile']);
			this.aiImageList.splice(index, 1);
			this.aiImgDetails.emit(this.aiImageList);
		} else {
			this.aiImageList.splice(index, 1);
			this.aiImgDetails.emit(this.aiImageList);
		}
	}

	onSelectFile(index: any): void {
		let files: any = this.base64ToArrayBuffer(
			this.aiImageList[index].file_hash
		);
		files['description'] = 'has_from_openai=1';
		this.aiImageList[index].isSelected = true;
		this.selectedPackage['uploadedFile'].push({
			img_Id: this.aiImageList[index].img_Id,
			name: files.name,
			content: this.aiImageList[index].fullPath,
			file: files,
			description: 'has_from_openai=1',
		});
		this.UpdatedFileList.emit(this.selectedPackage['uploadedFile']);
	}

	deleteAiInfo(value?) {
		if (value) {
			this.aiDirectionTextList = [];
			this.directionText = '';
			this.aiTextDestroyed$.next();
			this.aiTextDestroyed$.complete();
			this.isSpinnerShown = false;
			this.textProgressBar = false;
			this.btnDisabled['textHistory'] = false;
			this.btnDisabled['aiTextGenerationBtn'] = false;
		} else {
			this.btnDisabled['aiImgGenerationBtn'] = false;
			this.btnDisabled['imgHistory'] = false;
			this.bShowProgressBar = false;
			this.isSpinnerShown = false;
			this.aiImageList = [];
			this.aiImgGeneratText = '';
			this.aiImgDestroyed$.next();
			this.aiImgDestroyed$.complete();
		}
	}

	base64ToArrayBuffer(base64: string) {
		const binaryString = window.atob(base64);
		const length = binaryString.length;
		const bytes = new Uint8Array(length);
		for (let i = 0; i < length; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		const byteArray = new Uint8Array(bytes);
		return new File([byteArray], moment(new Date()) + 'aiImg.png', {
			type: 'image/png',
		});
	}

	handleCheckboxChange(checkbox, index) {
		if (checkbox.target.checked) {
			this.aiDirectionInfo(index);
			this.aiDirectionTextList[index]['isSelected'] = true;
		} else {
			this.aiDirectionTextList[index]['isSelected'] = false;
			this.unSelectDirection(index);
		}
		this.addAiBtnEnable = this.aiDirectionTextList.some(
			(resData) => resData?.isSelected
		);
	}

	addAiDirection() {
		this.validationMessageShown = true;
		this.editAiBtnEnable = this.aiDirectionTextList.some(
			(resData) => resData?.isEdit
		);
		this.addAiBtnEnable = this.aiDirectionTextList.some(
			(resData) => resData?.isSelected
		);
		if (this.addAiBtnEnable) {
			this.validationMessageShown = false;
			this.hideModalDetail(true);
		}
	}

	setCustomHeight(id?) {
		setTimeout(() => {
			this.directionList.forEach((items, index) => {
				const height = document.getElementById(
					'ai-' + index
				)?.offsetHeight;
				this.divHeights[index] = height > 420;
			});
		}, 100);
	}

	regenerateAiText() {
		this.aiDirectionTextList = [];
		this.directionList.forEach((direction) =>{
			delete direction?.id;
		});
		this.updatedDirectionList.emit(this.directionList);
		this.regenerateMessageShown = false;
		this.postAIText();
	}

	ngOnDestroy() {
		this.aiInfo['aiImageList'] = this.aiImageList;
		this.aiInfo['aiDirectionTextList'] = this.aiDirectionTextList;
		this.aiInfo['aiImgGeneratText'] = this.aiImgGeneratText;
		this.aiInfo['directionText'] = this.directionText;
		this.aiDetail.emit(this.aiInfo);
	}
}
