import {
	Component,
	OnInit,
	OnDestroy,
	EventEmitter,
	Output,
	Input,
	OnChanges,
	SimpleChanges,
	ChangeDetectorRef,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { CardService } from '@services/cards/card.service';
import { CommonService } from '@services/common/common.service';
import { LocalStorageService } from '@services/localstorage.service';
@Component({
	selector: 'app-cards-list',
	templateUrl: './cards.component.html',
	styleUrls: ['./cards.component.scss'],
})
export class CardsListComponent implements OnInit, OnChanges, OnDestroy {
	@Input() cards = [];
	@Input() isCardsLoaded;
	@Input() showCardsList;
	userId = this._localStorageService.getUserId();
	@Input() isViaSignUpPage: any;
	@Output() selectedCard = new EventEmitter<number>();
	@Output() addNewCard = new EventEmitter<any>();
	cardBrands = FlocksyUtil.cardBrands();
	shieldFillUncheck = FlocksyUtil.shieldFillUncheck;
	shieldFillCheck = FlocksyUtil.shieldFillCheck;
	addSubUserImg = FlocksyUtil.addSubUserImg;
	cardBrandNames = [];
	defaultCardId: any;
	customerId: string;
	selectedCardId: string;
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	defaultCard: any;
	constructor(
		private _cardService: CardService,
		private _localStorageService: LocalStorageService,
		public _commonService: CommonService,
		private cdr: ChangeDetectorRef,
		public _analyticsService: AnalyticsService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['cards']?.currentValue) {
			this.cardDetail();
		}
		this.cdr.detectChanges();
	}

	ngOnInit(): void {
		this._commonService.addedNewStripeCard
			.pipe(takeUntil(this.destroyed$))
			.subscribe((data) => {
				this.retrieveCards();
			});
	}

	retrieveCards() {
		this._cardService.index(this.userId).subscribe((data) => {
			if (data.data.length) {
				this.cards = data.data;
				this.cardDetail();
			} else {
				this.selectedCardId = this.defaultCard?.cc_id;
				this.selectedCard.emit(this.defaultCard);
			}
		});
	}

	cardDetail() {
		if (this.cards.length) {
			this.defaultCard = this.cards?.find((val) => !!val.default_source);
			this.defaultCardId = this.defaultCard?.cc_id;
			this.selectedCardId = this.defaultCardId;
			this.selectedCard.emit(this.defaultCard);
			this.customerId = this.cards[0]['stripe_customer_id'];
			this.cardBrandNames = Object.keys(this.cardBrands);
		} else {
			this.selectedCardId = this.defaultCard?.cc_id;
			this.selectedCard.emit(this.defaultCard);
		}
		this.cdr.detectChanges();
	}

	onCardChange(cardId) {
		let selectedCard = this.cards?.find((val) => val.cc_id == cardId);
		this.selectedCardId = selectedCard?.cc_id;
		this.selectedCard.emit(selectedCard);
	}
	onAddCard() {
		this.addNewCard.emit(true);
	}
	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
