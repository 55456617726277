import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';
@Injectable()
export class MessageService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.MESSAGES_URL}/${id}${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	getMessages(id) {
		return this._customHttpService.get(
			API_CONSTANTS.MESSAGES_URL + '/' + +id
		);
	}

	store(oData, includeReplies?: boolean): Observable<any> {
		let url = API_CONSTANTS.MESSAGES_URL;
		if (includeReplies) {
			url += '?include[replies]=1';
		}
		return this._customHttpService.post(url, oData);
	}	

	destroy(id): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.MESSAGES_URL + '/' + id
		);
	}

	unreadCount(id, project_id?: number): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.MESSAGES_URL +
				'/unread/' +
				id +
				'/count?filter[project_id]=' +
				project_id
		);
	}

	unreadTotalCount(oFilter?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.MESSAGES_URL +
				'/unread/count' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}
	unreadCommentsCount(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.COMMENTS_URL +
				'/projects/unread?has_unread_projects=1'
		);
	}

	getClients(id?: number, oFilter?: object, page = 1): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		params.set('page', page.toString());
		return this._customHttpService.get(
			API_CONSTANTS.MESSAGES_URL +
				'/clients?pm_user_id=' +
				(id ? id : 0) +
				'&' +
				params.toString()
		);
	}

	getCancelledClients(
		id?: number,
		oFilter?: object,
		page = 1
	): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		params.set('page', page.toString());
		return this._customHttpService.get(
			API_CONSTANTS.MESSAGES_URL +
				'/clients/cancelled?pm_user_id=' +
				(id ? id : 0) +
				'&' +
				params.toString()
		);
	}

	getDesigners(id?: number, oFilter?: object, page = 1): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		params.set('page', page.toString());
		return this._customHttpService.get(
			API_CONSTANTS.MESSAGES_URL +
				'/designers?pm_user_id=' +
				(id ? id : 0) +
				'&' +
				params.toString()
		);
	}

	getPM(oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.MESSAGES_URL}/users${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	getPMGeneralNotes(id, oParams): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/pms/' +
				id +
				'/notes?' +
				params.toString()
		);
	}

	getProjectsForFilter(id, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.MESSAGES_URL +
				'/' +
				id +
				'/projects' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	markAsRead(oData): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.MESSAGES_URL + '/mark-as-read',
			oData
		);
	}

	storeMessageEmojiReactions(messageId, body): Observable<any> {
		return this._customHttpService.post(
			`${API_CONSTANTS.MESSAGES_URL}/${messageId}/reactions`,
			body
		);
	}

	deleteMessageEmojiReaction(messageId, reactionId): Observable<any> {
		return this._customHttpService.delete(
			`${API_CONSTANTS.MESSAGES_URL}/${messageId}/reactions/${reactionId}`
		);
	}

	retrieveMessageInfo(messageId) {
		return this._customHttpService.get(
			`${API_CONSTANTS.MESSAGES_URL}/${messageId}/details`
		);
	}

	messageCenterFilesGenerateToken(
		documentId: number,
		parent_id: number,
		parent_module: number
	) {
		let oPost = {};

		oPost['parent_module'] = parent_module;
		oPost['parent_id'] = parent_id;

		return this._customHttpService.post(
			API_CONSTANTS.DOCUMENTS_URL + '/' + documentId + '/token',
			oPost
		);
	}

	messageCenterFileDownload(
		documentId: number,
		parent_id: number,
		parent_module: number = 5
	): any {
		return this.messageCenterFilesGenerateToken(documentId, parent_id, parent_module).subscribe(
			(data) => {
				if (data.data) {
					const url =
						API_CONSTANTS.MESSAGES_URL +
						'/' +
						parent_id +
						'/files/download?token=' +
						data.data['token'];
					window.location.href = url;
					return false;
				}
			},
			(err) => {}
		);
	}
}
