import { NgModule } from '@angular/core';
import { FlocksyTimerComponent } from './flocksy-timer.component';
import { CommonModule } from '@angular/common';
import { FlocksyTimerIconComponent } from './flocksy-timer-icon/flocksy-timer-icon.component';

@NgModule({
	imports: [CommonModule],
	exports: [FlocksyTimerComponent, FlocksyTimerIconComponent],
	declarations: [FlocksyTimerComponent, FlocksyTimerIconComponent],
	providers: [],
})
export class FlocksyTimerModule {}
