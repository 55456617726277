import {
	Component,
	HostListener,
	Injector,
	Input, OnDestroy,
	OnInit,
	TemplateRef,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as introJs from 'intro.js/intro.js';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { SettingsService } from '@core/settings/settings.service';
import { LocalStorageService } from '@services/localstorage.service';
import { TimerSharedService } from '@services/time-entries/timer-shared.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { MenuService } from '@core/menu/menu.service';
import { ClientSubClientService } from '@services/client/client-subclient.service';
import { MessageService } from '@services/message/message.service';
import { Subject, Subscription } from 'rxjs';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { CommonService } from '@services/common/common.service';
import { ProjectPreferenceService } from '@services/project/project-preference.service';
import { SocketIoService } from '@services/socket.io.service';
import { NotificationService } from '@services/notification/notification.service';
import { environment } from '@env/environment';
import { ToasterService } from 'angular2-toaster';
import { WhiteLabelledUser } from '@services/interface';
import { WhiteLabelService } from '@services/white-label/white-label.service';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
	selector: 'app-client-sidebar',
	templateUrl: './client-sidebar.component.html',
	styleUrls: ['./client-sidebar.component.scss'],
})
export class ClientSidebarComponent implements OnInit, OnDestroy {
	router: Router;
	unreadNotificationCount: number;
	userId = this._localStorageService.getUserId();
	notificationTypes = FlocksyUtil.notificationTypes();
	planPeriodType = FlocksyUtil.planPeriodType;
	modalSeeAllNotificationsRef: BsModalRef;
	page = 1;
	notifications = [];
	notificationLoaded = false;
	sbclickEvent = 'click.sidebar-toggle';
	$doc: any = null;
	aMenuItems: Array<any> = [];
	@Input() totalUnreadCount: number;
	totalCount: number;
	isRecentlyAddedTitle = false;
	unassigned_chats;
	introJS;
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();
	messageUnreadCountSubscription: Subscription;
	isClientUser = this._localStorageService.isClientUser();
	socketEstablished;
	actionMessageTriggeredSubscription;
	isEligibleRealTime = false;
	currentUrl;
	previousUrl;
	introJSSteps = [];
	getRecentLinkData = [];
	modalNewSystemRef: BsModalRef;
	tutorialIntro = introJs();
	mDevice = false;
	routerLinks = FlocksyUtil.routerLink;
	isNotificationModalShown = false;
	@HostListener('document:click', ['$event'])
	onDocumentClick(event: any) {
		if (typeof event?.target?.className == 'string') {
			if (event.target.className?.split(' ')?.includes('fas')) {
				this.isNotificationModalShown = true;
			} else {
				this.isNotificationModalShown = false;
			}
		} else {
			this.isNotificationModalShown = false;
		}
	}
	selectedActiveId;

	isNotificationsCountEnabled = FlocksyUtil.isNotificationsCountEnabled;
	notificationCountSubscription: Subscription;
	routerNotificationSubscription;
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();
	whiteLabelledUser:WhiteLabelledUser;
	destroy$: Subject<void> = new Subject<void>();
	isNavigatingToActivityFeed = false;

	constructor(
		public _menuService: MenuService,
		public settings: SettingsService,
		public injector: Injector,
		public _localStorageService: LocalStorageService,
		private _clientSubClientService: ClientSubClientService,
		public _projectPreferenceService: ProjectPreferenceService,
		private _messageService: MessageService,
		public _analyticsService: AnalyticsService,
		public _timerSharedService: TimerSharedService,
		private _commonService: CommonService,
		public _socketIoService: SocketIoService,
		public _settings: SettingsService,
		public _notificationService: NotificationService,
		public _toasterService: ToasterService,
		public _router: Router,
		public _bsModalService: BsModalService,
		private _whiteLabelService: WhiteLabelService,
	) {
		this.aMenuItems = this._menuService.getMenu();

		if (this._localStorageService.isSubClientUser()) {
			this.retrievePermissions();
		}

		this._router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				if (event.url === '/account-activity') {
					this.isNavigatingToActivityFeed = true;
				}
			} else if (event instanceof NavigationEnd) {
				this.isNavigatingToActivityFeed = false;

				if (
					event.urlAfterRedirects !== '/login' &&
					event.urlAfterRedirects !== '/logout' &&
					event.urlAfterRedirects !== '/signup'
				) {
					this.retrieveTotalUnreadCount();
					this.currentUrl = event.urlAfterRedirects;
					let data = {
						project_id: null,
						title: this.routerLinks[event.urlAfterRedirects],
						link: event.urlAfterRedirects,
						project_title: null,
					};
					let projectInfo = event.urlAfterRedirects.split('/');
					if (!isNaN(+projectInfo[2]) && projectInfo[1] === 'projects') {
						data.project_id = +projectInfo[2];
						data.title = '';
					}
					this._localStorageService.saveRoute(data);
				}
			}
		});
	}

	ngOnInit() {
		if(this.isWhiteLabelledUser) {
			this._whiteLabelService.getWhiteLabelledUser$()
				.pipe(takeUntil(this.destroy$))
				.subscribe((res) => {
					this.whiteLabelledUser = res;
				})
		}
		this.router = this.injector.get(Router);

		if (this._localStorageService.isUserLoggedIn()) {
			this.retrieveUnreadNotificationCount();
			this.retrieveProjectUnreadCount();
		}
		this.currentUrl = this.router.url;
		this.router.events.subscribe((val) => {
			this.getRecentLink();
			// close any submenu opened when route changes
			this.removeFloatingNav();
			// scroll view to top
			window.scrollTo(0, 0);

			if (val instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = val.url;

				if (
					this.isEligibleRealTime &&
					this.previousUrl == '/messages'
				) {
					const excludeUrls = ['/messages'];
					if (!excludeUrls.includes(val.url)) {
						this.retrieveTotalUnreadCount();
					}
				}
			}
			// close sidebar on route change
			this._timerSharedService.isIntroJs$.subscribe((res) => {
				this.introJS = res;
				if (this.introJS == false) {
					this.settings.setLayoutSetting('asideToggled', false);
				}
			});
			this._localStorageService.isRecentLink.subscribe((res) => {
				if (res) {
					this.getRecentLink();
				}
			});
		});

		if (this.isNotificationsCountEnabled) {
			this.routerNotificationSubscription = this._router.events.subscribe(
				(event) => {
					if (event instanceof NavigationEnd) {
						const excludeUrls = ['/logout', '/login'];
						if (!excludeUrls.includes(event.url)) {
							this.retrieveUnreadNotificationCount();
						}
					}
				}
			);
		}

		// enable sidebar autoclose from extenal clicks
		this.anyClickClose();
		this.initSubscribe();
		this.isEligibleRealTime = this.isEligibleRealTimeNotification();
		this.initRealTimeNotification();
		this.getRecentLink();
	}

	initSubscribe() {
		this._commonService.actionTriggered.subscribe({
			next: (data: any) => {
				if ('action' in data) {
					if (data['action'] == 'unread_chats') {
						this.retrieveTotalUnreadCount();
					} else if (data['action'] == 'unread_project_count') {
						this.retrieveProjectUnreadCount();
					}
				}
			},
		});

		this._commonService.unassignedChatsUpdated.subscribe((data) => {
			this.unassigned_chats = data['unassigned_chats'];
			this.setUnreadCount();
		});

		this.messageUnreadCountSubscription =
			this._commonService.messageChatsUpdated.subscribe((data) => {
				this.retrieveTotalUnreadCount();
			});
	}

	retrievePermissions() {
		this._clientSubClientService
			.getPermissions(
				this._localStorageService.getParentId(),
				this._localStorageService.getUserId()
			)
			.subscribe((data) => {
				let canManageOwnProjects =
					this._clientSubClientService.canManageOwnProjects(
						data.data.permissions
					);
				let canManageAllProjects =
					this._clientSubClientService.canManageAllProjects(
						data.data.permissions
					);
				let canViewManageOwnProjects =
					this._clientSubClientService.canViewManageOwnProjects(
						data.data.permissions
					);

				if (
					!canManageOwnProjects &&
					!canManageAllProjects &&
					!canViewManageOwnProjects
				) {
					let i = 0;
					for (let oMenu of this.aMenuItems) {
						if ('data' in oMenu) {
							if ((oMenu['data']['id'] = 'project_post')) {
								this.aMenuItems.splice(i, 1);
							}
						}
						i++;
					}
				}
			});

		this._messageService.unreadCommentsCount().subscribe((data) => {
			this.totalCount = data.data.count;
			if (this.totalCount > 0) {
				this.setUnreadCommentsCount();
			}
		});
	}

	adButtonClick() {
		this._analyticsService.setOverlayTrackData(16, 98, null, null, {
			extra_details: window.location.href,
		});
	}

	retrieveTotalUnreadCount() {
		this._messageService.unreadTotalCount().subscribe((data) => {
			this.totalUnreadCount = data.data.count;
			this.setUnreadCount();
		});
	}

	clearunreadCount(event, text) {
		if (text == 'My projects') {
			for (let oMenu of this.aMenuItems) {
				if ('data' in oMenu && oMenu.text === 'My projects') {
					//oMenu["data"]["unread_comments"] = 0;
					this.retrieveProjectUnreadCount();
				}
			}
		}
	}

	checkIsRecentlyAddedTitle() {
		this._projectPreferenceService.checkTitleAdded().subscribe((data) => {
			if (data.count > 0) {
				this.isRecentlyAddedTitle = true;
				this.setUnreadCount();
			}
		});
	}

	setUnreadCommentsCount() {
		for (let oMenu of this.aMenuItems) {
			if ('data' in oMenu) {
				if (oMenu['data']['id'] == 'comment') {
					oMenu['data']['unread_comments'] = this.totalCount;
				}
			}
		}
	}

	setUnreadCount() {
		for (let oMenu of this.aMenuItems) {
			if ('data' in oMenu) {
				if (oMenu['data']['id'] == 'messages') {
					oMenu['data']['unread_count'] = this.totalUnreadCount;
				} else if (
					oMenu['data']['id'] == 'work_categories' &&
					this.isRecentlyAddedTitle
				) {
					if (!this._localStorageService.isDesignerUser()) {
						oMenu['data']['notify_text'] = 'New';
					}
				} else if (oMenu['data']['id'] == 'available_projects') {
					oMenu['data']['unread_count'] = this.unassigned_chats;
				}
			}
		}
	}

	anyClickClose() {
		// this.$doc = $(document).on(this.sbclickEvent, (e) => {
		// 	if (!$(e.target).parents('.aside-container').length) {
		// 		if (this.introJS == false) {
		// 			this.settings.setLayoutSetting('asideToggled', false);
		// 		}
		// 	}
		// });
	}

	ngOnDestroy() {
		if (this.$doc) {
			this.$doc.off(this.sbclickEvent);
		}

		if (this.messageUnreadCountSubscription) {
			this.messageUnreadCountSubscription.unsubscribe();
		}
		if (this.actionMessageTriggeredSubscription) {
			this.actionMessageTriggeredSubscription.unsubscribe();
		}

		if (this.routerNotificationSubscription) {
			this.routerNotificationSubscription.unsubscribe();
		}

		this.destroy$.next();
		this.destroy$.complete();
	}

	toggleSubmenuClick(event) {
		event.preventDefault();

		if (
			!this.isSidebarCollapsed() &&
			!this.isSidebarCollapsedText() &&
			!this.isEnabledHover()
		) {
			let ul = $(event.currentTarget.nextElementSibling);

			// hide other submenus
			let parentNav = ul.parents('.sidebar-subnav');
			$('.sidebar-subnav').each((idx, el) => {
				let $el = $(el);
				// if element is not a parent or self ul
				if (el !== parentNav[0] && el !== ul[0]) {
					this.closeMenu($el);
				}
			});

			// abort if not UL to process
			if (!ul.length) {
				return;
			}

			// any child menu should start closed
			ul.find('.sidebar-subnav').each((idx, el) => {
				this.closeMenu($(el));
			});

			// toggle UL height
			const ulHeight = ul.css('height');
			if (ulHeight === 'auto' || parseInt(ulHeight, 10)) {
				this.closeMenu(ul);
			} else {
				// expand menu
				ul.on('transitionend', () => {
					ul.css('height', 'auto').off('transitionend');
				}).css('height', ul[0].scrollHeight);
				// add class to manage animation
				ul.addClass('opening');
			}
		}
	}

	// Close menu collapsing height
	closeMenu(elem) {
		elem.css('height', elem[0].scrollHeight); // set height
		elem.css('height', 0); // and move to zero to collapse
		elem.removeClass('opening');
	}

	toggleSubmenuHover(event) {
		let self = this;
		if (
			this.isSidebarCollapsed() ||
			this.isSidebarCollapsedText() ||
			this.isEnabledHover()
		) {
			event.preventDefault();

			this.removeFloatingNav();

			let ul = $(event.currentTarget.nextElementSibling);
			let anchor = $(event.currentTarget);

			if (!ul.length) {
				return; // if not submenu return
			}

			let $aside = $('.aside-container');
			let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
			let $sidebar = $asideInner.children('.sidebar');
			let mar =
				parseInt($asideInner.css('padding-top'), 0) +
				parseInt($aside.css('padding-top'), 0);
			let itemTop =
				anchor.parent().position().top + mar - $sidebar.scrollTop();

			let floatingNav = ul.clone().appendTo($aside);
			let vwHeight = document.body.clientHeight;

			// let itemTop = anchor.position().top || anchor.offset().top;

			floatingNav.addClass('nav-floating');

			// each item has ~40px height
			// multiply to force space for at least N items
			var safeOffsetValue = 40 * 5;
			var navHeight = floatingNav.height() + 2; // 2px border
			var safeOffset =
				navHeight < safeOffsetValue ? navHeight : safeOffsetValue;

			var displacement = 25;

			// if not enough space to show N items, use then calculated 'safeOffset'
			var menuTop =
				vwHeight - itemTop > safeOffset
					? itemTop
					: vwHeight - safeOffset - displacement;

			floatingNav
				.removeClass('opening') // necesary for demo if switched between normal//collapsed mode
				// .addClass('nav-floating')
				.css({
					position: this.settings.getLayoutSetting('isFixed')
						? 'fixed'
						: 'absolute',
					top: menuTop,
					bottom:
						floatingNav.outerHeight(true) + menuTop > vwHeight
							? displacement + 'px'
							: 'auto',
				});

			floatingNav
				.on('mouseleave', () => {
					floatingNav.remove();
				})
				.find('a')
				.on('click', function (e) {
					e.preventDefault(); // prevents page reload on click
					// get the exact route path to navigate
					let routeTo = $(this).attr('route');
					if (routeTo) {
						self.router.navigate([routeTo]);
					}
				});

			this.listenForExternalClicks();
		}
	}

	listenForExternalClicks() {
		let $doc = $(document).on('click.sidebar', (e) => {
			if (!$(e.target).parents('.aside-container').length) {
				this.removeFloatingNav();
				$doc.off('click.sidebar');
			}
		});
	}

	removeFloatingNav() {
		$('.nav-floating').remove();
	}

	isSidebarCollapsed() {
		return this.settings.getLayoutSetting('isCollapsed');
	}

	isSidebarCollapsedText() {
		return this.settings.getLayoutSetting('isCollapsedText');
	}

	isEnabledHover() {
		return this.settings.getLayoutSetting('asideHover');
	}

	retrieveProjectUnreadCount() {
		if (
			this._localStorageService.isClientUser() ||
			this._localStorageService.isSubClientUser()
		) {
			this._messageService.unreadCommentsCount().subscribe((data) => {
				this.totalCount = data.data.count;
				this.setUnreadCommentsCount();
			});
		}
	}

	initRealTimeNotification() {
		if (this.isEligibleRealTime) {
			this.initSocket();
		}
	}

	isEligibleRealTimeNotification() {
		if (
			this._localStorageService.isProjectManagerUser() ||
			this._localStorageService.isOperationalClientUser()
		) {
			return true;
		}

		return false;
	}

	getRoomName() {
		return 'user.' + this._localStorageService.getUserId();
	}

	initSocket() {
		this._socketIoService.connect();
		this._socketIoService.joinRoom(this.getRoomName());
		this.socketEstablished = true;

		this._socketIoService.subscribeToUser((type, data) => {
			this.retrieveTotalUnreadCount();
			this.retrieveUnreadNotificationCount();
		});
	}

	startIntroJs(): any {
		this._settings.setLayoutSetting('asideToggled', true);

		let interval = setInterval(() => {
			this.callIntrojs('');
			clearInterval(interval);
		}, 700);
	}

	remindMeLater() {
		this.modalNewSystemRef.hide();
	}

	callIntrojs(id) {
		if (id == 1) {
			this.remindMeLater();
		}

		this._timerSharedService.isIntroJs.next(true);

		this.introJSSteps = [
			{
				element: document.querySelector('#A1'),
				intro: `Here's where you can see all your current projects and team members at a glance, as well as recent activity and ${this.isWhiteLabelledUser ? this.whiteLabelledUser?.business_name:'Flocksy'} updates!`,
				position: 'bottom',
			},
			{
				element: document.querySelector('#A2'),
				intro: 'Here’s where you can create a project and start getting stuff done!',
				position: 'bottom',
			},
			{
				element: document.querySelector('#A3'),
				intro: "Here's where you can see all your active, pending, and completed projects!",
				position: 'bottom',
			},
			{
				element: document.querySelector('#A4'),
				intro: "Here's where you can upload your stylebooks, logos, copy guides, and anything else that would help your team do your projects. You'll be able to organize your files into convenient Brand Buckets that can easily be attached to projects.",
				position: 'bottom',
			},
			{
				element: document.querySelector('#A5'),
				menu: 'team',
				intro:
					"Here's where you can see who's part of your team and also customize it. As you run projects, team members that work with you will be added to your team page." +
					'<br><br>' +
					'You can also add other users to your account to help you manage your account and projects. These can be your own team members, clients, or anyone you want.',
				position: 'bottom',
			},
			{
				element: document.querySelector('#pm'),
				intro: "Here's where you can read and respond to messages from your project manager and team members.",
				position: 'bottom',
			},
			{
				element: document.querySelector('#profileicon'),
				intro:
					'My Profile' +
					'<br/>' +
					'Here is where you can manage your account settings, edit profile information, set up email notifications, and change your profile picture.' +
					'<br/>' +
					'<br/>' +
					'Change Password' +
					'<br/>' +
					'This is where you can update or make changes to your password.' +
					'<br/>' +
					'<br/>' +
					(this._localStorageService.isSubClientUser()
						? ''
						: 'Upgrading' +
						  '<br/>' +
						  'Here you can upgrade or downgrade your plan.' +
						  '<br/>' +
						  '<br/>' +
						  'Billing' +
						  '<br/>' +
						  'Here you can manage your cards, access billing information, and view payment history.' +
						  '<br/>' +
						  '<br/>') +
					'Feedback' +
					'<br/>' +
					'This is where you can review feedback left on past projects.' +
					'<br/>' +
					'<br/>' +
					'Logout' +
					'<br/>' +
					'Here is where you log out of the account.',
				position: 'bottom',
			},
			{
				element: document.querySelector('#my_team'),
				intro: 'This is where you will see the team members you work with the most.',
				position: 'bottom',
			},
			{
				element: document.querySelector('#project_activity'),
				intro: "Here's where you will see recent updates, comments, questions, etc. for current projects.",
				position: 'bottom',
			},
		];

		if (!this.isClientUser) {
			const stepIndex = this.introJSSteps.findIndex(
				(step) => step['menu'] === 'team'
			);
			this.introJSSteps.splice(stepIndex, 2, {
				element: document.querySelector('#pm'),
				intro: "Here's where you can read and respond to messages from your project manager.",
				position: 'top',
			});
		}

		if (this.currentUrl != '/dashboard') {
			const stepIndex = this.introJSSteps.findIndex(
				(step) => step['menu'] === 'my_team'
			);
			this.introJSSteps.splice(stepIndex - 1, 2);
		}

		if (this._settings.getLayoutSetting('asideToggled') == true) {
			this.tutorialIntro
				.setOptions({
					steps: this.introJSSteps,
					showBullets: !this.mDevice,
					tooltipClass: 'customTooltip',
				})
				.start();
		}

		document.querySelectorAll('.introjs-tooltip').forEach((element) => {
			// Type assertion to HTMLElement
			let htmlElement = element as HTMLElement;
			htmlElement.style.margin = '0 0px';
		});

		let initialElement = document.querySelector('#A1') as HTMLElement;
		if (initialElement) {
			initialElement.classList.add('highlighted-targeted-element');
		}

		this.aMenuItems.forEach((item) => {
			const menuItem = document.getElementById(item.id);
			if (menuItem && menuItem.classList.contains('active')) {
				this.selectedActiveId = menuItem;
				menuItem.classList.remove('active');
			}
		});

		let previousElement = initialElement;
		this.tutorialIntro.onchange((targetElement) => {
			targetElement = targetElement as HTMLElement;
			if (
				targetElement.id === 'my_team' ||
				targetElement.id === 'project_activity'
			) {
				targetElement.classList.add(
					'highlighted-element-border-shadow-none'
				);
				previousElement.classList.remove(
					'highlighted-element-border-shadow-none'
				);
				this._settings.setLayoutSetting('asideToggled', false);
				setTimeout(() => {
					this.tutorialIntro.refresh();
				}, 250);
			} else {
				this._settings.setLayoutSetting('asideToggled', true);
				setTimeout(() => {
					this.tutorialIntro.refresh();
				}, 250);
			}
			if (previousElement && previousElement !== targetElement) {
				previousElement.classList.remove(
					'highlighted-targeted-element'
				);
			}
			targetElement.classList.add('highlighted-targeted-element');
			previousElement = targetElement;

			if (window.innerWidth <= 1920) {
				let tooltipText = document.querySelector(
					'.introjs-tooltiptext'
				) as HTMLElement;
				if (tooltipText) {
					if (targetElement.id === 'profileicon') {
						tooltipText.style.height = '250px';
						tooltipText.style.overflow = 'scroll';
					} else {
						if (window.innerWidth <= 540) {
							if (
								targetElement.id === 'A4' ||
								targetElement.id === 'A5'
							) {
								tooltipText.style.height = '120px';
								tooltipText.style.overflow = 'scroll';
							} else {
								tooltipText.style.height = '';
								tooltipText.style.overflow = '';
							}
						} else {
							tooltipText.style.height = '';
							tooltipText.style.overflow = '';
						}
					}
				}
			}
		});

		this.tutorialIntro.onexit(() => {
			this._timerSharedService.isIntroJs.next(false);
			previousElement.classList.remove('highlighted-targeted-element');
			previousElement.classList.remove(
				'highlighted-element-border-shadow-none'
			);
			this.selectedActiveId.classList.add('active');
		});
	}

	public getRecentLink() {
		this.getRecentLinkData = this._localStorageService.getItem(
			'routerHistory',
			''
		);
	}

	seenAllNotifications() {
		if (this.unreadNotificationCount > 0) {
			this._notificationService
				.seenAll(this.userId, {})
				.subscribe((data) => {
					this.unreadNotificationCount = 0;
				});
		}
		this.retrieveNotifications();
	}

	retrieveNotifications() {
		this._notificationService
			.index(this.userId, this.page, {})
			.subscribe((data) => {
				this.notifications = data.data.data;
				this.notificationLoaded = true;
			});
	}

	clearAllNotifications() {
		this._notificationService.readAll(this.userId, {}).subscribe((data) => {
			this.unreadNotificationCount = 0;
			this.notifications = [];
			this._toasterService.pop('success', data.message);
		});
		this.retrieveNotifications();
	}

	markAsRead(notificationId, notification, event: any, isClose = false) {
		this.markedAsReadNotification(
			notificationId,
			notification,
			event,
			isClose
		);
	}

	markedAsReadNotification(
		notificationId,
		notification,
		event,
		isClose = false,
		fromHeader = true
	) {
		this._notificationService.read(notificationId, {}).subscribe((data) => {
			this._toasterService.pop('success', data.message);
			this.retrieveNotifications();
			this.retrieveUnreadNotificationCount();
			if (isClose) {
				return false;
			}
			if (
				notification.notification_type_id == 1 ||
				notification.notification_type_id == 2 ||
				notification.notification_type_id == 4
			) {
				if (event.ctrlKey || event.metaKey) {
					window.open(
						`${environment.baseURL}/messages?focusedMessageId=${notification?.data?.id}`,
						'_blank'
					);
				} else {
					this._router.navigate(['/messages'], {
						queryParams: {
							focusedMessageId: notification?.data?.id,
						},
					});
				}
			} else {
				if (event.ctrlKey || event.metaKey) {
					window.open(
						environment.baseURL +
							'/projects/' +
							notification.data.project_id,
						'_blank'
					);
				} else {
					window.open(
						environment.baseURL +
							'/projects/' +
							notification.data.project_id,
						'_self'
					);
				}
			}
			const navElement: HTMLElement = document.querySelector(
				'.navbar'
			) as HTMLElement;
			navElement.click();
		});
	}

	retrieveUnreadNotificationCount() {
		this._notificationService.unreadCount().subscribe((data) => {
			this.unreadNotificationCount = data.data.count;
		});
	}

	initSeeAllNotifications(template: TemplateRef<any>) {
		this.modalSeeAllNotificationsRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-lg modal-full' }
			)
		);
	}
}
