import { Component, Input, OnInit } from '@angular/core';
import { FlocksyModalHeaderConfig, FlocksyModalHeaderData } from '../interface';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {
  @Input() data: FlocksyModalHeaderData;
  @Input() config: FlocksyModalHeaderConfig = {
    showClose: true
  };

  constructor(
      public _bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

}
