import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	OnInit,
	Input,
	OnDestroy,
	EventEmitter,
	Output,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { loadStripe } from '@stripe/stripe-js';
import { ToasterService } from 'angular2-toaster';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { CardService } from '@services/cards/card.service';
import { CommonService } from '@services/common/common.service';
import { LocalStorageService } from '@services/localstorage.service';
import { environment } from '@env/environment';
import { ApiService } from '@services/common/api.service';
declare var Stripe;
declare var Rewardful;

@Component({
	selector: 'app-add-new-card',
	templateUrl: './create.component.html',
	styleUrls: ['./create.component.scss'],
})
export class AddNewCardComponent
	implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
	userId = this._localStorageService.getUserId();
	cardBrands = FlocksyUtil.cardBrands();
	cardTriggers = FlocksyUtil.cardTriggers();

	stripe;
	stripeError;
	stripeCardElement: any;
	stripeToken;
	addCardForm: FormGroup;
	disableAddCardButton: boolean = false;
	@Input('customerId') customerId;
	@Input('isViaSignUpPage') isViaSignUpPage;
	@Output() emitCloseModal = new EventEmitter();
	@Output() isFormValidated = new EventEmitter();
	@Output() selectedStateId = new EventEmitter<any>();
	isShowCard: boolean;
	countries = [];
	states: object = {};
	usCountryId = FlocksyUtil.usCountryId;
	alabamaStateId = FlocksyUtil.alabamaStateId;
	shieldFillUncheck = FlocksyUtil.shieldFillUncheck;
	@Input('saveCardInfo') saveCardInfo;
	@Output() cardError = new EventEmitter();
	@Input() btnClicked = false;
	@Output() stripeErr = new EventEmitter();

	constructor(
		private _cardService: CardService,
		private _localStorageService: LocalStorageService,
		private _changeDetectorRef: ChangeDetectorRef,
		public _toasterService: ToasterService,
		public _formBuilder: FormBuilder,
		public _commonService: CommonService,
		public _apiService: ApiService
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['saveCardInfo'].currentValue) {
			this.onAddnewCardSubmit();
		}
	}

	ngOnInit(): void {
		this.initForm();
		this.addCardForm.valueChanges.subscribe((formGroup) => {
			if (this.addCardForm.valid) {
				this.isFormValidated.emit({
					isValid: true,
					stateId: this.addCardForm.value.state,
				});
			} else {
				this.isFormValidated.emit({
					isValid: false,
					stateId: this.addCardForm.value.state,
				});
			}
		});
	}

	isRewardfulLoaded() {
		return typeof Rewardful === 'function';
	}

	ngAfterViewInit() {
		this.initLoadStripe();
	}

	ngOnDestroy() {
		this.destroyStripeCardElement();
	}

	destroyStripeCardElement() {
		if (this.stripeCardElement) {
			this.stripeCardElement.unmount();
			this.stripeCardElement.clear();
			this.stripeCardElement.destroy();
		}
	}

	initLoadStripe() {
		loadStripe(environment.stripePublishableKey).then((r) => {
			this.stripe = r;
			this.initStripe();
		});
	}

	mountStripeCard() {
		this.stripeCardElement.mount('#stripe-card-element');
		
		this.stripeCardElement.on('change', (event) => {
			if (event.complete) {
				// enable payment button
				this.stripeError = null;
				this.stripeErr.emit({
					isValid: false
				});
				this.addCardForm
					.get('stripe_event_completed')
					.setValue(true);
				this.addCardForm.updateValueAndValidity();
				this._changeDetectorRef.detectChanges();
			} else if (event.error) {
				// show validation to customer
				this.stripeError = event.error;
				this.stripeErr.emit({
					isValid: true
				});
				this.addCardForm
					.get('stripe_event_completed')
					.setValue(null);
				this.addCardForm.updateValueAndValidity();
				this._toasterService.pop('error', event.error.message);
				this._changeDetectorRef.detectChanges();
			}
		});
	}

	initStripe() {
		const elements = this.stripe.elements();
		elements.create('card', { hidePostalCode: true });
		this.stripeCardElement = elements.getElement('card');

		const thisObject = this;
		const checkCardElementExist = setInterval(function () {
			if (document.getElementById('stripe-card-element')) {
				clearInterval(checkCardElementExist);
				thisObject.mountStripeCard();
			}
		}, 100);
	}

	initForm() {
		this.addCardForm = this._formBuilder.group({
			country: ['', [Validators.required]],
			state: [''],
			name: ['', [Validators.required]],
			stripe_event_completed: [null, Validators.required],
		});
		if (this.countries.length == 0) {
			this.getCountryList();
			this.getStateList(this.usCountryId);
		}
		this.addCardForm.get('country').setValue(this.usCountryId);
		this.addCardForm.get('state').setValue(this.alabamaStateId);
		this.selectedStateId.emit(this.addCardForm.value.state);
	}

	onAddnewCardSubmit(event?) {
		this.btnClicked = true;
		this.stripeError = null;
		if (!this.addCardForm.valid) {
			let controlName: string;
			for (controlName in this.addCardForm.controls) {
				this.addCardForm.controls[controlName].markAsDirty();
				this.addCardForm.controls[controlName].updateValueAndValidity();
			}
			return false;
		}

		this.disableAddCardButton = true;
		const thisObject = this;
		this.createStripeToken().then((result) => {
			// Handle result.error or result.token
			if (result.token) {
				thisObject.stripeToken = result.token;
				thisObject.storeCard(result);
				thisObject._changeDetectorRef.detectChanges();
			} else if (result.error) {
				thisObject.stripeError = result.error;
				this.cardError.emit();
				thisObject.disableAddCardButton = false;
				thisObject._changeDetectorRef.detectChanges();
			}
		});
	}

	createStripeToken() {
		const name = this.addCardForm.get('name').value;
		return this.stripe.createToken(this.stripeCardElement, { name });
	}

	storeCard(tokenResponse) {
		const data = {};
		data['token_response'] = tokenResponse;
		data['token'] = tokenResponse['token']['id'];
		data['stripe_customer_id'] = this.customerId;
		data['country_id'] = this.addCardForm.get('country').value;
		data['state_id'] = this.addCardForm.get('state').value
			? this.addCardForm.get('state').value
			: null;
		data['trigger_id '] = this.cardTriggers['add-new-card-marketplace'];
		if (this.isRewardfulLoaded()) {
			data['rewardful_referral'] = Rewardful?.referral || '';
			data['rewardful_coupon'] = Rewardful?.coupon?.id || '';
		}
		this._cardService.store(this.userId, data).subscribe(
			(data) => {
				if (!this.isViaSignUpPage) {
					//success message for add card will not be shown on to the user who are new to flocksy
					this._toasterService.pop('success', data.message);
				}
				this._commonService.addedNewStripeCard.emit();
				this.disableAddCardButton = false;
				// card detail not will not be shown to the user who is registering to flocksy
				this.emitCloseModal.emit(true);
			},
			(error) => {
				this.disableAddCardButton = false;
				this.cardError.emit();
			}
		);
	}

	getCountryList() {
		this._apiService.getCountryList().subscribe((data) => {
			this.countries = data?.data;
		});
	}

	getStateList(country_id) {
		if (!country_id) {
			return;
		}

		this._apiService.getStateList(country_id).subscribe((data) => {
			this.states[country_id] = data?.data;
		});
	}

	onChangeCountry(event) {
		if (this.addCardForm.value.country == this.usCountryId) {
			this.addCardForm.get('state').setValidators(Validators.required);
			this.addCardForm.get('state').updateValueAndValidity();
		} else {
			this.addCardForm.get('state').setValidators(Validators.required);
			this.addCardForm.get('state').updateValueAndValidity();
			this.addCardForm.get('state').setValue('');
		}
		this.getStateList(event);
	}

	onChangeState(event) {
		this.selectedStateId.emit(this.addCardForm.value.state);
		if (this.addCardForm.valid) {
			this.isFormValidated.emit({
				isValid: true,
				stateId: this.addCardForm.value.state,
			});
		} else {
			this.isFormValidated.emit({
				isValid: false,
				stateId: this.addCardForm.value.state,
			});
		}
	}

	onCloseCardForm() {
		this.emitCloseModal.emit(false);
	}
}
