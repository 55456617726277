import { Injectable } from '@angular/core';

import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable({
	providedIn: 'root',
})
export class CardService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, page?, oFilter?: any): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set(key, oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/cards'
		);
	}

	destroy(userId, cardId): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.USERS_URL + '/' + userId + '/cards/' + cardId
		);
	}

	store(userId, data): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/' + userId + '/cards',
			data
		);
	}

	makeBackup(userId, cardId, data?): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL +
				'/' +
				userId +
				'/cards/' +
				cardId +
				'/mark-as-backup',
			{}
		);
	}

	makeDefault(userId, cardId, data?): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL +
				'/' +
				userId +
				'/cards/' +
				cardId +
				'/mark-as-default',
			data
		);
	}
}
