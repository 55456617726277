import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';
import { environment } from '../../../environments/environment';

@Injectable()
export class ProjectBidService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, oFilter?: any): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set(key, oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				id +
				'/bids' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getDeclined(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/decline-designers'
		);
	}

	isEligibleForAutoAssigned(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/project_bid/auto_assign/is_eligible?project_id=' +
				id
		);
	}

	isEligibleForAutoAssign(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				id +
				'/bids/auto-assign/is-eligible'
		);
	}

	storeBid(id, oData) {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/bids',
			oData
		);
	}

	storeBidQuestions(id, oData) {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/bids/questions',
			oData
		);
	}

	declineProject(id, oData) {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/decline-designers',
			oData
		);
	}

	getInvited(id, oFilter): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set(key, oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				id +
				'/invite/designers' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getInvitedLastActive(projectId, oFilter): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		projectId = 32;
		return this._customHttpService.get(
			environment.analyticsEndPoint +
				'/users/page-visits/' +
				projectId +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getInvitedViewedProject(projectId, oFilter): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		return this._customHttpService.get(
			environment.analyticsEndPoint +
				'/projects/' +
				projectId +
				'/detail-view/seen' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getExcluded(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/excluded/designers'
		);
	}

	getBidChats(id, bid_id, user_id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/project-bid-chat/' +
				bid_id +
				'/' +
				user_id
		);
	}

	storeBidChat(id, bid_id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/project-bid-chat',
			oData
		);
	}

	getExceptBidDesigners(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/designers/except_bid?project_id=' +
				id
		);
	}

	storeInviteBidDesigner(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/project/invite/designer',
			oData
		);
	}

	getQualifiedCreatives(id, oFilter?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set(key, oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				id +
				'/creatives/stats' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getProjectSeenStats(id): Observable<any> {
		return this._customHttpService.get(
			environment.analyticsEndPoint +
				'/projects/' +
				id +
				'/briefs/creatives/seen'
		);
	}

	getOffer(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/offer'
		);
	}
}
