<div class="wrapper">
	<div class="block-center mt-4 wd-xl">
		<!-- START card-->
		<div class="card card-flat">
			<div class="card-header text-center bg-success">
				<a href="#">
					<h3 class="app_name_header">Flocksy</h3>
					<!--<img class="block-center width-100" src="assets/img/flocksy-logo.png" alt="Image" />-->
				</a>
			</div>
			<div class="card-body">
				<p
					(click)="removeVisibilityGoogleRecaptcha()"
					class="text-center py-2">
					SIGNUP TO GET INSTANT ACCESS.
				</p>
				<h4 class="text-green">Sign up as a Creative on Flocksy</h4>
				<div *ngIf="oError">
					<alert
						*ngIf="oError?.status"
						dismissible="true"
						type="danger">
						{{ oError.error.message }}
					</alert>
				</div>
				<form
					(submit)="submitForm($event, valForm.value)"
					[formGroup]="valForm"
					class="form-validate mb-3"
					name="registerForm"
					novalidate=""
					role="form">
					<div class="form-group">
						<label class="text-muted mb-1">
							Name
							<span class="text-danger">*</span>
						</label>
						<div class="input-group with-focus">
							<input
								autocomplete="off"
								class="form-control border-right-0"
								formControlName="name"
								name="name"
								placeholder="Name"
								required=""
								type="text" />
							<div class="input-group-append">
								<span
									class="input-group-text text-muted bg-transparent border-left-0">
									<em class="fa fa-user"></em>
								</span>
							</div>
						</div>
						<div
							*ngIf="
								valForm.controls['name'].hasError('required') &&
								(valForm.controls['name'].dirty ||
									valForm.controls['name'].touched)
							"
							class="text-danger">
							This field is required
						</div>
						<div
							*ngIf="
								(valForm.controls['name'].hasError('url') ||
									valForm.controls['name'].hasError(
										'email'
									)) &&
								(valForm.controls['name'].dirty ||
									valForm.controls['name'].touched)
							"
							class="text-danger">
							Please enter valid name
						</div>
					</div>
					<div class="form-group">
						<label class="text-muted mb-1">Phone</label>
						<div class="input-group with-focus">
							<input
								autocomplete="off"
								class="form-control border-right-0"
								formControlName="phone"
								name="phone"
								placeholder="Phone"
								type="text" />
							<div class="input-group-append">
								<span
									class="input-group-text text-muted bg-transparent border-left-0">
									<em class="fa fa-phone"></em>
								</span>
							</div>
						</div>
						<div
							*ngIf="
								valForm.controls['phone'].hasError(
									'required'
								) &&
								(valForm.controls['phone'].dirty ||
									valForm.controls['phone'].touched)
							"
							class="text-danger">
							This field is required
						</div>
					</div>
					<div class="form-group">
						<label class="text-muted mb-1">
							PayPal Email Address
							<span class="text-danger">*</span>
						</label>
						<div class="input-group with-focus">
							<input
								autocomplete="off"
								class="form-control border-right-0"
								formControlName="payment_email"
								name="payment_email"
								placeholder="PayPal Email Address"
								required=""
								type="email" />
							<div class="input-group-append">
								<span
									class="input-group-text text-muted bg-transparent border-left-0">
									<em class="fa fa-envelope"></em>
								</span>
							</div>
						</div>
						<div
							*ngIf="
								valForm.controls['payment_email'].hasError(
									'required'
								) &&
								(valForm.controls['payment_email'].dirty ||
									valForm.controls['payment_email'].touched)
							"
							class="text-danger">
							This field is required
						</div>
						<div
							*ngIf="
								valForm.controls['payment_email'].hasError(
									'email'
								) &&
								(valForm.controls['payment_email'].dirty ||
									valForm.controls['payment_email'].touched)
							"
							class="text-danger">
							This field must be a valid email address
						</div>
					</div>
					<div class="form-group">
						<label class="text-muted mb-1">
							Profile Picture
							<span class="text-danger">*</span>
						</label>
						<div class="input-group with-focus">
							<div class="text-muted">
								Please upload a high quality profile picture
							</div>
							<label
								class="file-upload file-upload mt-1"
								for="image-input">
								<button class="btn btn-secondary btn-block">
									Upload File
								</button>
								<input
									#profilePicFile
									(change)="onSelectFile($event)"
									id="image-input"
									type="file" />
							</label>
						</div>
						<div
							*ngIf="
								valForm.controls['file1'].hasError(
									'required'
								) &&
								(valForm.controls['file1'].dirty ||
									valForm.controls['file1'].touched)
							"
							class="text-danger">
							This field is required
						</div>
						<div *ngIf="valForm.value.file1" class="row">
							<div class="col-md-12">
								<button
									class="mb-2 btn btn-labeled btn-secondary">
									<span class="btn-label">
										<em class="fa fa-file"></em>
									</span>
									{{ valForm.value.file1.name }}
								</button>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="text-muted mb-1">
							Country
							<span class="text-danger">*</span>
						</label>
						<ngx-select
							(select)="onChangeCountry($event)"
							[items]="aCountries"
							formControlName="country"
							optionValueField="id"
							placeholder="Select Country"></ngx-select>
						<div
							*ngIf="
								valForm.controls['country'].hasError(
									'required'
								) &&
								(valForm.controls['country'].dirty ||
									valForm.controls['country'].touched)
							"
							class="text-danger">
							Required
						</div>
					</div>
					<div class="form-group">
						<label class="text-muted mb-1">
							State
							<span class="text-danger">*</span>
						</label>
						<ngx-select
							[items]="aStates"
							formControlName="state"
							optionValueField="id"
							placeholder="Select State"></ngx-select>
						<div
							*ngIf="
								valForm.controls['state'].hasError(
									'required'
								) &&
								(valForm.controls['state'].dirty ||
									valForm.controls['state'].touched)
							"
							class="text-danger">
							Required
						</div>
					</div>
					<div class="form-group">
						<label class="text-muted mb-1">
							How did you hear of us?
							<span class="text-danger">*</span>
						</label>
						<select
							class="form-control"
							formControlName="lead_source"
							(change)="onLeadSourceChange()">
							<option
								*ngFor="let source of leadSourceNames"
								[value]="source">
								{{ source }}
							</option>
						</select>
						<div class="mt-2" *ngIf="isOtherSelected">
							<input
								autocomplete="off"
								class="form-control"
								[(ngModel)]="otherLeadSource"
								[ngModelOptions]="{ standalone: true }"
								name="lead_source"
								placeholder="How did you hear of us?"
								type="text" />
						</div>
						<div
							*ngIf="
								(valForm.controls['lead_source'].hasError(
									'required'
								) &&
									(valForm.controls['lead_source'].dirty ||
										valForm.controls['lead_source']
											.touched)) ||
								(isOtherSelected && otherLeadSource == '')
							"
							class="text-danger">
							Required
						</div>
					</div>
					<div class="form-group">
						<label class="text-muted mb-1">
							Tell Us About Yourself
						</label>
						<div class="input-group with-focus">
							<textarea
								class="form-control border-right-0"
								formControlName="about_yourself"
								name="about_yourself"
								placeholder="Tell Us About Yourself"></textarea>
							<div class="input-group-append">
								<span
									class="input-group-text text-muted bg-transparent border-left-0">
									<em class="fa fa-user"></em>
								</span>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="text-muted mb-1">Headline</label>
						<div class="input-group with-focus">
							<textarea
								class="form-control border-right-0"
								formControlName="headline"
								name="headline"
								placeholder="Headline"></textarea>
							<div class="input-group-append">
								<span
									class="input-group-text text-muted bg-transparent border-left-0">
									<em class="fa fa-user"></em>
								</span>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="text-muted mb-1">
							Email
							<span class="text-danger">*</span>
						</label>
						<div class="input-group with-focus">
							<input
								autocomplete="off"
								class="form-control border-right-0"
								formControlName="email"
								name="email"
								placeholder="Email"
								required=""
								type="email" />
							<div class="input-group-append">
								<span
									class="input-group-text text-muted bg-transparent border-left-0">
									<em class="fa fa-envelope"></em>
								</span>
							</div>
						</div>
						<div
							*ngIf="
								valForm.controls['email'].hasError(
									'required'
								) &&
								(valForm.controls['email'].dirty ||
									valForm.controls['email'].touched)
							"
							class="text-danger">
							This field is required
						</div>
						<div
							*ngIf="
								valForm.controls['email'].hasError('email') &&
								(valForm.controls['email'].dirty ||
									valForm.controls['email'].touched)
							"
							class="text-danger">
							This field must be a valid email address
						</div>
					</div>

					<div formGroupName="passwordGroup">
						<div class="form-group">
							<label class="text-muted mb-1">
								Password
								<span class="text-danger">*</span>
							</label>
							<div class="input-group with-focus">
								<input
									[formControl]="
										valForm.get('passwordGroup.password')
									"
									class="form-control border-right-0"
									formControlName="password"
									name="password"
									placeholder="Password"
									type="password" />
								<div class="input-group-append">
									<span
										class="input-group-text text-muted bg-transparent border-left-0">
										<em class="fa fa-lock"></em>
									</span>
								</div>
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.password')
										.hasError('required') &&
									(valForm.get('passwordGroup.password')
										.dirty ||
										valForm.get('passwordGroup.password')
											.touched)
								"
								class="text-danger">
								This field is required
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.password')
										.hasError('pattern') &&
									(valForm.get('passwordGroup.password')
										.dirty ||
										valForm.get('passwordGroup.password')
											.touched)
								"
								class="text-danger">
								Please enter min 8 characters.
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.password')
										.hasError('maxlength') &&
									(valForm.get('passwordGroup.password')
										.dirty ||
										valForm.get('passwordGroup.password')
											.touched)
								"
								class="text-danger">
								Password can't be greater than 16 characters.
							</div>
						</div>
						<div class="form-group">
							<label class="text-muted mb-1">
								Password confirmation
								<span class="text-danger">*</span>
							</label>
							<div class="input-group with-focus">
								<input
									[formControl]="
										valForm.get(
											'passwordGroup.confirm_password'
										)
									"
									class="form-control border-right-0"
									formControlName="confirm_password"
									name="confirm_password"
									placeholder="Password confirmation"
									type="password" />
								<div class="input-group-append">
									<span
										class="input-group-text text-muted bg-transparent border-left-0">
										<em class="fa fa-lock"></em>
									</span>
								</div>
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.confirm_password')
										.hasError('required') &&
									(valForm.get(
										'passwordGroup.confirm_password'
									).dirty ||
										valForm.get(
											'passwordGroup.confirm_password'
										).touched)
								"
								class="text-danger">
								This field is required
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.confirm_password')
										.hasError('equalTo')
								"
								class="text-danger">
								Your passwords do not match
							</div>
						</div>
					</div>
					<div class="clearfix">
						<div class="checkbox c-checkbox float-left mt0">
							<label>
								<input
									formControlName="agree_terms"
									name="agree_terms"
									required=""
									type="checkbox" />
								<span class="fa fa-check"></span>
								By checking the box to the left and signing up,
								you agreeing to our
								<a
									href="https://flocksy.com/terms-of-service"
									target="_blank">
									Terms and Conditions
								</a>
								and confirm that you have read our Data Policy,
								including our Cookie Use Policy.
							</label>
						</div>
					</div>
					<span
						*ngIf="
							valForm.controls['agree_terms'].hasError(
								'required'
							) &&
							(valForm.controls['agree_terms'].dirty ||
								valForm.controls['agree_terms'].touched)
						"
						class="text-danger">
						You must agree the terms
					</span>
					<div *ngIf="bShowProgressBar" class="mb-2">
						<progressbar
							[animate]="false"
							[value]="progressBarPercentage"
							type="success">
							<b>{{ progressBarPercentage }}%</b>
						</progressbar>
					</div>
					<button
						[disabled]="!valForm.valid || bDisabledButton"
						class="btn btn-block btn-primary mt-3"
						type="submit">
						Create account
					</button>
				</form>
				<!-- <div class="alert alert-danger text-center"></div> -->
				<p class="pt-3 text-center">Have an account?</p>
				<a
					(click)="this._analyticsService.setRouteData(1, 4)"
					[routerLink]="'/login'"
					class="btn btn-block btn-secondary">
					Login
				</a>
			</div>
		</div>
		<!-- END card-->
		<div class="p-3 text-center">
			<span>&copy;</span>
			<span>{{ _settingsService.getAppSetting('year') }}</span>
			<span class="mx-2">-</span>
			<span>{{ _settingsService.getAppSetting('name') }}</span>
			<br />
			<span>{{ _settingsService.getAppSetting('description') }}</span>
		</div>
	</div>
</div>
