import { FlocksyUtil } from '../FlocksyUtil';

export const API_CONSTANTS = {
	API_ENDPOINT_URL: FlocksyUtil.getApiEndPoint(),
	OAUTH_TOKEN_URL: FlocksyUtil.getApiEndPointPath() + '/oauth/token',
	OAUTH_URL: FlocksyUtil.getApiEndPoint() + '/oauth',
	AUTH_USER: FlocksyUtil.getApiEndPoint() + '/auth/users',
	AUTH_SEAMLESS: FlocksyUtil.getApiEndPoint() + '/auth/seamless',
	LOGOUT: FlocksyUtil.getApiEndPoint() + '/logout',
	USERS_URL: FlocksyUtil.getApiEndPoint() + '/users',
	ADMIN_USERS_URL: FlocksyUtil.getApiEndPoint() + '/admin/users',
	PROJECTS_URL: FlocksyUtil.getApiEndPoint() + '/projects',
	CLIENT_URL: FlocksyUtil.getApiEndPoint() + '/client',
	CLIENTS_URL: FlocksyUtil.getApiEndPoint() + '/clients',
	BUCKET_URL: FlocksyUtil.getApiEndPoint() + '/bucket',
	BUCKETS_URL: FlocksyUtil.getApiEndPoint() + '/buckets',
	ADMIN_URL: FlocksyUtil.getApiEndPoint() + '/admin',
	BUCKET_ASSET_URL: FlocksyUtil.getApiEndPoint() + '/bucket_asset',
	BIDS_URL: FlocksyUtil.getApiEndPoint() + '/bids',
	TIMER_ENTRIES_URL: FlocksyUtil.getApiEndPoint() + '/time-entries',
	PLAN_URL: FlocksyUtil.getApiEndPoint() + '/plan',
	PLANS_URL: FlocksyUtil.getApiEndPoint() + '/plans',
	TRANSACTIONS_URL: FlocksyUtil.getApiEndPoint() + '/transactions',
	TRANSACTION_URL: FlocksyUtil.getApiEndPoint() + '/transaction',
	TRANSACION_URL: FlocksyUtil.getApiEndPoint() + '/transacions',
	REPORTS_URL: FlocksyUtil.getApiEndPoint() + '/reports',
	TEAM_URL: FlocksyUtil.getApiEndPoint() + '/client/pm_and_designers',
	ENTRY_POINT_URL: FlocksyUtil.getApiEndPoint() + '/entry_point',
	PROJECT_TYPE_URL: FlocksyUtil.getApiEndPoint() + '/project_type',
	PROJECT_TYPES_URL: FlocksyUtil.getApiEndPoint() + '/project-types',
	PROJECT_TITLE_URL: FlocksyUtil.getApiEndPoint() + '/project_title',
	PROJECT_TITLES_URL: FlocksyUtil.getApiEndPoint() + '/project-titles',
	PROJECT_QUESTION_URL: FlocksyUtil.getApiEndPoint() + '/project_question',
	DOCUMENTS_URL: FlocksyUtil.getApiEndPoint() + '/documents',
	EMAIL_URL: FlocksyUtil.getApiEndPoint() + '/email',
	COUPON_URL: FlocksyUtil.getApiEndPoint() + '/coupon',
	COUPONS_URL: FlocksyUtil.getApiEndPoint() + '/coupons',
	PROJECT_CHATS_URL: FlocksyUtil.getApiEndPoint() + '/project-chats',
	IMAGE_TAGS_URL: FlocksyUtil.getApiEndPoint() + '/image-tags',
	MESSAGES_URL: FlocksyUtil.getApiEndPoint() + '/messages',
	TEMPLATE_LIBRARIES_URL:
		FlocksyUtil.getApiEndPoint() + '/template-libraries',
	SHOWCASES_URL: FlocksyUtil.getApiEndPoint() + '/showcases',
	ONBOARDING_URL: FlocksyUtil.getApiEndPoint() + '/onboarding',
	SHARES_URL: FlocksyUtil.getApiEndPoint() + '/shares',
	ANALYTICS_URL: FlocksyUtil.getApiAnalyticsURL() + '/track',
	CREATIVES_URL: FlocksyUtil.getApiEndPoint() + '/creatives',
	TRACKERS_URL: FlocksyUtil.getApiEndPoint() + '/trackers',
	BROADCAST_BANNERS_URL: FlocksyUtil.getApiEndPoint() + '/broadcast-banners',
	COMMENTS_URL: FlocksyUtil.getApiEndPoint() + '/notifications',
	NOTIFICATION_URL: FlocksyUtil.getApiEndPoint() + '/notifications',
	CREATIVE_CLIENT_NOTES_URL:
		FlocksyUtil.getApiEndPoint() + '/creative-client-notes',
	PACKAGES_URL: FlocksyUtil.getApiEndPoint() + '/packages',
	SUPPORT_EMAIL_URL: FlocksyUtil.getApiEndPoint() + '/support/emails',
	WORLDTIME_API: 'https://worldtimeapi.org/api/timezone/Etc/UTC',
	OAUTH_PASSWORD_CODE_URL: FlocksyUtil.getApiEndPoint() + '/oauth/password-codes/validate',
	CUSTOM_DOMAIN: FlocksyUtil.getApiEndPoint() + '/custom-domains',
	OAUTH_USER_CHECK_EMAIL: FlocksyUtil.getApiEndPoint() + '/oauth/users/check-email',
	OAUTH_USER_VERIFICATION_CODE: FlocksyUtil.getApiEndPoint() + '/oauth/users/send-verification-code',
};
