import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { BsModalRef } from 'ngx-bootstrap';
import { FlocksyUtil } from '../../../../common/FlocksyUtil';
import { LocalStorageService } from '../../../../services/localstorage.service';
import { NotificationService } from '../../../../services/notification/notification.service';
import { CommonService } from '../../../../services/common/common.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
@Component({
	selector: 'app-notifications-detailview',
	templateUrl: './notifications-detailview.component.html',
	styleUrls: ['./notifications-detailview.component.scss'],
})
export class NotificationsDetailviewComponent implements OnInit {
	constructor(
		public _notificationService: NotificationService,
		public _toasterService: ToasterService,
		public _localStorageService: LocalStorageService,
		public _commonService: CommonService,
		public _analyticsService: AnalyticsService,
		public _router: Router
	) {}

	unreadNotificationCount: number;
	userId = this._localStorageService.getUserId();
	notifications = [];
	unreadNotifications = [];
	notificationTypes = FlocksyUtil.notificationTypes();
	modalSeeAllNotificationsRef: BsModalRef;
	@Output() emitCloseModal = new EventEmitter();

	notificationsPagination = [];
	page = 1;
	defaultImg = '../../../../assets/img/user-placeholder.png';
	isNotifications = false;

	ngOnInit(): void {
		this.retrieveUnreadNotifications();
		this.getReadNotifications();
		this.isNotifications = this._router.url.indexOf('/notifications') > -1;
	}

	clearAllNotifications() {
		this._notificationService.readAll(this.userId, {}).subscribe((data) => {
			this.unreadNotificationCount = 0;
			this.unreadNotifications = [];
			this._toasterService.pop('success', data.message);
			this._commonService.notificationCountUpdated.emit({});
			this.retrieveNotifications();
		});
	}

	markedAsReadNotification(notification, isClosed) {
		this._notificationService
			.read(notification.id, {})
			.subscribe((data) => {
				if (
					(notification.notification_type_id == 3 ||
						notification.notification_type_id == 5) &&
					isClosed
				) {
					window.open(
						environment.baseURL +
							'/projects/' +
							notification.data.project_id,
						'_self'
					);
				}
				this._toasterService.pop('success', data.message);
				this.retrieveUnreadNotifications();
				this.retrieveNotifications();
			});
	}

	retrieveNotifications(page = 1) {
		this._notificationService
			.index(this.userId, page, {})
			.subscribe((data) => {
				let aNotifications = this.notifications;

				this.notifications = _.orderBy(
					_.uniqBy(aNotifications.concat(data.data.data), 'id'),
					['id'],
					['desc']
				);

				this.notificationsPagination = data.data;
			});
	}

	retrieveUnreadNotifications() {
		this._notificationService
			.getUnreadNotifications(this.userId, this.page, {})
			.subscribe((data) => {
				this.unreadNotifications = data.data;
			});
	}

	pastNotificationAnalytics() {
		this._analyticsService.setOverlayTrackData(16, 99);
	}

	retrieveUnreadNotificationCount() {
		this._notificationService.unreadCount().subscribe((data) => {
			this.unreadNotificationCount = data.data.count;
		});
	}

	readNotifications() {
		this._notificationService.read(this.userId, {}).subscribe((data) => {
			this.unreadNotificationCount = 0;
			this._toasterService.pop('success', data.message);
			this.retrieveUnreadNotificationCount();
		});
	}

	getReadNotifications() {
		this._notificationService.getReadNotifications().subscribe((data) => {
			this.notifications = data?.data?.data;
			this.notificationsPagination = data?.data;
		});
	}

	markAsRead(notification, isClosed = false, isRead = true) {
		this._commonService.notificationCountUpdated.emit(notification.id);
		if (isClosed) {
			this.emitCloseModal.emit();
		}
		if (isRead) {
			this.markedAsReadNotification(notification, isClosed);
		} else if (
			(notification.notification_type_id == 3 ||
				notification.notification_type_id == 5) &&
			isClosed
		) {
			window.open(
				environment.baseURL +
					'/projects/' +
					notification.data.project_id,
				'_self'
			);
		}
	}
}
