import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientCreativeFeedbacksComponent } from './client-creative-feedbacks.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSelectModule } from 'ngx-select-ex';
import { RatingModule } from 'ngx-bootstrap/rating';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [ClientCreativeFeedbacksComponent],
	imports: [
		CommonModule,
		NgxDatatableModule,
		NgxSelectModule,
		RatingModule,
		FormsModule,
		ReactiveFormsModule,
		PaginationModule.forRoot(),
		RouterModule,
	],
	exports: [ClientCreativeFeedbacksComponent, RouterModule],
})
export class ClientCreativeFeedbacksModule {}
