<div class="wrapper white-bg">
	<app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
	<div class="main_wrapper">
		<!-- START card-->
		<div class="login_title login_title_top font-36">Login</div>
		<div class="card-main margin-top-4-rem">
			<div class="card-body border rounded">
				<div *ngIf="oError">
					<alert
						*ngIf="oError?.status"
						dismissible="true"
						type="danger">
						{{ oError.error.message }}
					</alert>
				</div>
				<ng-container *ngIf="step === 'EMAIL'; else codeForm">
					<form (submit)="submitForm($event, emailValForm.value)" [formGroup]="emailValForm"
						  class="form-validate form-main mb-3" name="loginForm" novalidate="" role="form">
						<!-- Email field -->
						<div class="form-group green-input">
							<label class="label-required" for="email">Email</label>
							<span class="text-danger">*</span>
							<div class="input-group with-focus"
								 [class.valid-border]="emailValForm.get('email').valid && emailValForm.get('email').dirty">
								<input id="email" class="form-control border-right-0" formControlName="email" name="email"
									   required="" type="email" placeholder="Enter your email address" />
								<div class="input-group-append">
								<span class="input-group-text text-muted bg-white">
									<img src="assets/img/envelope-fill.svg" alt="envelope">
								</span>
								</div>
							</div>
							<!-- Modified validation message conditions -->
							<div *ngIf="submitted && emailValForm.controls['email'].hasError('required')"
								 class="text-danger">
								This field is required
							</div>
							<div
								*ngIf="submitted && emailValForm.controls['email'].hasError('email') && emailValForm.controls['email'].dirty"
								class="text-danger">
								This field must be a valid email address
							</div>
						</div>
						<div
							class="d-flex justify-content-between align-items-center pt-3">
							<div>
								<a
									(click)="
									this._analyticsService.setRouteData(7, 13)
								"
									class="text-no-decoration text-purple font-12"
									[routerLink]="'/password/reset'">
									Forgot your password?
								</a>
							</div>
							<button
								class="btn purple-btn text-white py-2"
								(click)="
								this._analyticsService.setRouteData(16, 31)
							"
								type="submit"
								id="loginBtn">
								<p class="mb-0 px-2">Next</p>
							</button>
						</div>
						<!--<div class="d-flex flex-column align-items-center mt-4" *ngIf="!isWhiteLabelledUser">
							<div class="continue-btns mt-3 d-flex py-2 pl-4 align-items-center clickable"
								 (click)="loginWithSocials('google')">
								<ng-container *ngIf="!isGoogleLoading; else googleLoading">
									<div class="mr-4">
										<img src="assets/img/google-icon.svg" alt="">
									</div>
									<div class="continue-text">
										Continue with Google
									</div>
								</ng-container>
								<ng-template #googleLoading>
									<div class="w-100 d-flex pr-4 justify-content-center align-items-center">
										<div class="spinner-border text-primary" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								</ng-template>
							</div>
							<div class="mt-3 continue-btns d-flex py-2 pl-4 align-items-center clickable"
								 (click)="loginWithSocials('microsoft')">
								<ng-container *ngIf="!isMicrosoftLoading; else loadingMicrosoft">
									<div class="mr-4">
										<img src="assets/img/microsoft-icon.svg" alt="">
									</div>
									<div class="continue-text">
										Continue with Microsoft
									</div>
								</ng-container>
								<ng-template #loadingMicrosoft>
									<div class="w-100 d-flex pr-4 justify-content-center align-items-center">
										<div class="spinner-border text-primary" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								</ng-template>
							</div>
						</div>-->
					</form>
				</ng-container>
				<ng-template #codeForm>
					<img (click)="showEmailField()" src="assets/img/project-files-modal/arrow-left-circle-fill.svg" alt="" class="clickable mb-3"/>
					<div class="mb-3">
						<h4>{{codeFormText.title}}</h4>
						<p>{{codeFormText.text}}</p>
					</div>
					<form (ngSubmit)="onCodeSubmit($event)" [formGroup]="codeFormGroup">
						<ng-container *ngIf="step === 'PASSWORD'; else codeInput">
							<div class="form-group green-input">
								<label class="label-required" for="password">Password</label>
								<span class="text-danger">*</span>
								<div class="input-group with-focus"
									 [class.valid-border]="codeFormGroup.get('password').valid && codeFormGroup.get('password').dirty">
									<input id="password" class="form-control border-right-0" formControlName="password"
										   name="password" required type="password" placeholder="********" />
									<div class="input-group-append">
															<span class="input-group-text text-muted bg-white">
																<img src="assets/img/key.svg" alt="key">
															</span>
									</div>
								</div>
								<div *ngIf="codeFormGroup.controls['password'].touched && codeFormGroup.controls['password'].hasError('required')"
									 class="text-danger">
									This field is required
								</div>
							</div>
						</ng-container>
						<ng-template #codeInput>
							<div class="form-group green-input">
								<label class="label-required" for="verification_code">Verification Code</label>
								<span class="text-danger">*</span>
								<div class="input-group with-focus"
									 [class.valid-border]="codeFormGroup.get('verification_code').valid && codeFormGroup.get('verification_code').dirty">
									<input id="verification_code" class="form-control border-right-0" formControlName="verification_code"
										   name="verification_code" required type="text" placeholder="Enter code" />
									<div class="input-group-append">
															<span class="input-group-text text-muted bg-white">
																<img src="assets/img/key.svg" alt="key">
															</span>
									</div>
								</div>
								<div *ngIf="codeFormGroup.controls['verification_code'].touched && codeFormGroup.controls['verification_code'].hasError('required')"
									 class="text-danger">
									This field is required
								</div>
								<div *ngIf="codeFormGroup.controls['verification_code'].touched && codeFormGroup.controls['verification_code'].hasError('pattern')"
									 class="text-danger">
									Verification code must be numeric
								</div>
							</div>
						</ng-template>
						<div class="d-flex justify-content-between">
							<span>
								<a (click)="loginViaCode()" *ngIf="step === 'CODE'" class="signup-link clickable">Resend code</a>
								<a *ngIf="step === 'PASSWORD'"
									(click)="
									this._analyticsService.setRouteData(7, 13)
								"
									class="text-no-decoration text-purple font-12"
									[routerLink]="'/password/reset'">
									Forgot your password?
								</a>
							</span>
							<button type="submit" class="btn btn-purple px-3">Login</button>
						</div>
					</form>
				</ng-template>
				<ng-container *ngIf="!isWhiteLabelledUser">
					<hr>
					<div class="row mx-0 justify-content-center">
						<div class="d-flex justify-content-center mr-2 text-muted">
							<p class="font-12">Don't have an account?</p>
						</div>
						<div class="d-flex justify-content-center">
							<a
								(click)="this._analyticsService.setRouteData(2, 7)"
								class="signup-link"
								*ngIf="!referralSource"
								[routerLink]="'/signup'">
								Sign Up
							</a>
							<a
								(click)="this._analyticsService.setRouteData(2, 7)"
								class="signup-link"
								*ngIf="referralSource"
								[queryParams]="{ r: referralSource }"
								[routerLink]="'/signup'">
								Sign Up
							</a>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!isWhiteLabelledUser" class="p-3 footer white-bg">
	<span>&copy;</span>
	<span>{{ _settingsService.getAppSetting('year') }}</span>
	<span class="mx-2">-</span>
	<span>{{ _settingsService.getAppSetting('name') }}</span>
</div>
