import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LocalStorageService } from '../../../../services/localstorage.service';
import { CommonService } from '../../../../services/common/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-demo-signup-overlay',
	templateUrl: './demo-signup.overlay.component.html',
})
export class DemoSignupOverlayComponent implements OnInit {
	modalDemoSignupPlanRef: BsModalRef;
	@ViewChild('demoSignupPlanModal', { static: true })
	demoSignupPlanModal: TemplateRef<any>;

	constructor(
		public _commonService: CommonService,
		public _localStorageService: LocalStorageService,
		public _bsModalService: BsModalService,
		private _router: Router
	) {}

	ngOnInit() {
		this.initSubscribe();
	}

	init() {
		this.initDemoSignupPlanModal(this.demoSignupPlanModal);
	}

	initSubscribe() {
		this._commonService.actionTriggered.subscribe((data) => {
			if ('action' in data) {
				if (data['action'] == 'demo_signup_overlay') this.init();
			}
		});
	}

	initDemoSignupPlanModal(template) {
		this.modalDemoSignupPlanRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-lg modal-full' }
			)
		);
	}

	goToPlanPage() {
		this.modalDemoSignupPlanRef.hide();
		this._router.navigate(['/plans']);
	}
}
