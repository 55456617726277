import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	Renderer2,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { MenuService } from '@core/menu/menu.service';
import { LocalStorageService } from '@services/localstorage.service';
import { CommonService } from '@services/common/common.service';
import { Subject, Subscription, combineLatest} from 'rxjs';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { environment } from '@env/environment';
import { DocumentService } from '@services/document/document.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { ApiService } from '@services/common/api.service';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationService } from '@services/notification/notification.service';
import { SocketIoService } from '@services/socket.io.service';
import { UserService } from '@services/user/user.service';
import { WhiteLabelledUser } from '@services/interface';
import { WhiteLabelService } from '@services/white-label/white-label.service';
import { takeUntil } from 'rxjs/operators';
import { ReferralService } from './services/referral.service';

declare var window;
declare var profitwell;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	host: { id: 'app-header' },
})
export class HeaderComponent implements OnInit, OnDestroy {
	navCollapsed = true; // for horizontal layout
	profileImageUrl: string;
	profilePictureChanged: Subscription;
	loginBeanNameChanged: Subscription;
	urlShare;
	paypalUrlPrefix: string;
	modalFreeMonthReferralRef: BsModalRef;
	@Input() onlyLogo = false;
	mobile = false;
	isClientUser = this._localStorageService.isClientUser();
	isSubClientUser = this._localStorageService.isSubClientUser();
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();
	isAdminUser = this._localStorageService.isAdminUser();
	isProjectManagerUser = this._localStorageService.isProjectManagerUser();
	isDesignerUser = this._localStorageService.isDesignerUser();
	isPlatinumLead = this._localStorageService.isPlatinumLead();
	isPlatinumLevel = this._localStorageService.isPlatinumLevel();
	isProjectManagerAsAM = this._localStorageService.isProjectManagerAsAM();
	isFeedbackeryEnabled = FlocksyUtil.isFeedbackeryEnabled;
	isFeedbackeryInitialized = FlocksyUtil.isFeedbackeryInitialized;
	modalReportConcernRef: BsModalRef;
	unreadNotificationCount: number;
	loggedInUser = this._localStorageService.getCurrentUser();
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();
	whiteLabelledUser:WhiteLabelledUser;
	defaultLogo: string = 'assets/img/custom-domain/custom_domain_default_logo.png';
	destroyed$ = new Subject<void>();
	isEmailSent: boolean;

	@ViewChild('feedbackBtnDesktop') set fbDesktop(content: ElementRef) {
		if (content && !this.isFeedbackeryInitialized) {
			const feedbackId = this.isOperationalClientUser
				? environment.feedbackeryIds.dashboardClient
				: environment.feedbackeryIds.dashboardDesigner;
			this._renderer2.setAttribute(
				content.nativeElement,
				'data-feedbackery',
				feedbackId
			);
			if (this._router.url != '/dashboard') {
				window.feedbackery.init();
				this.isFeedbackeryInitialized = true;
			}
		}
	}

	userId = this._localStorageService.getUserId();
	notifications = [];
	allNotifications = [];
	notificationTypes = FlocksyUtil.notificationTypes();
	modalSeeAllNotificationsRef: BsModalRef;

	notificationsPagination = {
		totalRecords: 0,
		currentPage: 1,
		itemsPerPage: 0,
	};
	page = 1;
	defaultImg = '../../../../assets/img/user-placeholder.png';
	isNotificationsCountEnabled = FlocksyUtil.isNotificationsCountEnabled;
	notificationCountSubscription: Subscription;
	notificationLoaded = false;
	isLoginPage = this._router.url.indexOf('/login') > -1;
	notificationSubscription: Subscription;
	routerNotificationSubscription;
	isEligibleRealTime = false;
	socket;
	roomName;
	socketEstablished = false;
	newSocketEstablished = false;
	subscribeMessageListener;
	private unsubscribe$ = new Subject<void>();
	shareMethod: string;

	constructor(
		public menu: MenuService,
		public settings: SettingsService,
		public _localStorageService: LocalStorageService,
		public _analyticsService: AnalyticsService,
		public _bsModalService: BsModalService,
		public _documentService: DocumentService,
		public _toasterService: ToasterService,
		public _renderer2: Renderer2,
		public _apiService: ApiService,
		public _router: Router,
		public _commonService: CommonService,
		public _socketIoService: SocketIoService,
		public _notificationService: NotificationService,
		private _whiteLabelService: WhiteLabelService,
		private _userService: UserService,
		private _referralService: ReferralService
	) {
		this.profileImageUrl = this._localStorageService.getProfileImageURL();
		this.urlShare =
			environment.landingSiteURL +
			'/?r=' +
			this._localStorageService.getUserId();
		this.paypalUrlPrefix =
			environment.landingSiteURL + '/update-paypal-email';
	}

	ngOnInit() {
		if(this.isWhiteLabelledUser) {
			this._whiteLabelService.getWhiteLabelledUser$()
				.pipe(takeUntil(this.destroyed$))
				.subscribe((value) => {
				this.whiteLabelledUser = value;
			})
		}
		if (window.screen.width < 767) {
			// 768px portrait
			this.mobile = true;
		} else {
			this.mobile = false;
		}

		this.initSubscription();

		if (this._localStorageService.isUserLoggedIn()) {
			this.getAuthUser();
			if (!this.isOperationalClientUser) {
				this.retrieveUnreadNotificationCount();
			}
			

			if (!this.isOperationalClientUser && this.isNotificationsCountEnabled) {
				this.routerNotificationSubscription =
					this._router.events.subscribe((event) => {
						if (event instanceof NavigationEnd) {
							const excludeUrls = ['/logout', '/login'];
							if (!excludeUrls.includes(event.url)) {
								this.retrieveUnreadNotificationCount();
							}
						}
					});
			}

			this.isEligibleRealTime = this.isEligibleRealTimeNotification();
			this.initRealTimeNotification();
		}

		combineLatest([
			this._referralService.shareMethod$,
			this._referralService.isEmailSent$
		])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(([method, isEmailSent]) => {
				this.shareMethod = method;
				this.isEmailSent = isEmailSent;
			});
	}

	ngOnDestroy() {
		if (this.routerNotificationSubscription) {
			this.routerNotificationSubscription.unsubscribe();
		}
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	initSubscription() {
		this.profilePictureChanged =
			this._commonService.profilePictureChanged.subscribe((data) => {
				this.profileImageUrl =
					this._localStorageService.getProfileImageURL();
			});

		this.loginBeanNameChanged =
			this._commonService.loginBeanNameChanged.subscribe((data) => {});
	}

	toggleOffsidebar() {
		this.settings.toggleLayoutSetting('offsidebarOpen');
	}

	toggleCollapsedSideabar() {
		this.settings.toggleLayoutSetting('isCollapsed');
	}

	isCollapsedText() {
		return this.settings.getLayoutSetting('isCollapsedText');
	}

	initFreeMonthReferralModal(template: TemplateRef<any>) {
		this.modalFreeMonthReferralRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{
					modalClass: 'modal-dialog',
					class: 'modal-flocksy-lg modal-flocksy-md modal-flocksy-sm',
				}
			)
		);
		this._analyticsService.setOverlayTrackData(16, 42);
	}

	initReportConcernModal(template: TemplateRef<any>) {
		this.modalReportConcernRef = this._bsModalService.show(
			template,
			Object.assign({
				modalClass: 'modal-dialog',
				class: 'modal-lg modal-full',
			})
		);
		// @todo: analytics
	}

	copyToClipboard() {
		this._documentService.copyToClipboard(this.urlShare);
		this._toasterService.pop('success', 'Referral URL has been copied');
	}

	getAuthUser() {
		this._apiService.getAuthUser().subscribe((data) => {
			this._localStorageService.setCurrentUser(data.data);
			this._userService.setUserData(data.data);
			this.setFeedbackeryData(data.data);
			this.fillProfitwell();
		});
	}

	fillProfitwell() {
		if (typeof profitwell === 'function') {
			profitwell('start', {
				user_email: this._localStorageService.getUserEmail(),
			});
		}
	}

	setFeedbackeryData(userData) {
		const data = {
			Id: userData.id,
			Name: userData.name,
			'Date Joined': this._localStorageService.formatDate(
				userData['created_at'],
				'MMM DD, YYYY h:mm A'
			),
		};
		data['Type'] = this._localStorageService.getUserType();
		if (this.isOperationalClientUser) {
			if (this.isClientUser) {
				data['Plan Id'] = userData.plan_id;
				data['Plan'] = userData.plan_title;
				data['Money Back'] = userData.is_money_back ? 'Yes' : 'No';
			} else if (this.isSubClientUser) {
				data['Plan Id'] = userData.parent_plan_id;
				data['Plan'] = userData.parent_plan_title;
				data['Money Back'] = userData.parent_is_money_back
					? 'Yes'
					: 'No';
			}
		}
		if (this.isDesignerUser) {
			data['Level'] = this._localStorageService.getCreativeLevelName(
				userData.creative_level_id
			);
			data['Country'] = userData.country_name;
			data['State'] = userData.state_name;
		}
		window.feedbackery.setUserDetails(data);
	}

	retrieveNotifications() {
		this._notificationService
			.index(this.userId, this.page, {})
			.subscribe((data) => {
				this.notifications = data.data.data;
				this.notificationLoaded = true;
			});
	}

	retrieveUnreadNotifications() {
		this._analyticsService.setOverlayTrackData(16, 80);
		this._notificationService
			.getUnreadNotifications(this.userId, this.page, {})
			.subscribe((data) => {
				this.notifications = data.data;
				this.notificationLoaded = true;
				this.unreadNotificationCount = this.notifications.length;
			});
	}

	retrieveUnreadNotificationCount() {
		this._notificationService.unreadCount().subscribe((data) => {
			this.unreadNotificationCount = data.data.count;
		});
	}

	readNotifications() {
		this._notificationService.read(this.userId, {}).subscribe((data) => {
			this.unreadNotificationCount = 0;
			this._toasterService.pop('success', data.message);
			this.retrieveUnreadNotificationCount();
		});
	}

	markAsRead(notificationId, notification, event: any, isClose = false) {
		this.markedAsReadNotification(
			notificationId,
			notification,
			event,
			isClose
		);
	}

	seenAllNotifications() {
		if (this.unreadNotificationCount > 0) {
			this._notificationService
				.seenAll(this.userId, {})
				.subscribe((data) => {
					this.unreadNotificationCount = 0;
				});
		}
		this.retrieveNotifications();
	}

	clearAllNotifications() {
		this._notificationService.readAll(this.userId, {}).subscribe((data) => {
			this.unreadNotificationCount = 0;
			this.notifications = [];
			this._toasterService.pop('success', data.message);
		});
		this.retrieveNotifications();
	}

	initSeeAllNotifications(template: TemplateRef<any>) {
		this.modalSeeAllNotificationsRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-lg modal-full' }
			)
		);
	}

	onPageChanged(event: any): void {
		this.page = event.page;
		this.retrieveNotifications();
	}

	markedAsReadNotification(
		notificationId,
		notification,
		event,
		isClose = false,
		fromHeader = true
	) {
		this._notificationService.read(notificationId, {}).subscribe((data) => {
			this._toasterService.pop('success', data.message);
			this.retrieveNotifications();
			this.retrieveUnreadNotificationCount();
			if (isClose) {
				return false;
			}
			if (
				notification.notification_type_id == 1 ||
				notification.notification_type_id == 2 ||
				notification.notification_type_id == 4
			) {
				if (event.ctrlKey || event.metaKey) {
					window.open(
						`${environment.baseURL}/messages?focusedMessageId=${notification?.data?.id}`,
						'_blank'
					);
				} else {
					this._router.navigate(['/messages'], {
						queryParams: {
							focusedMessageId: notification?.data?.id,
						},
					});
				}
			} else {
				if (event.ctrlKey || event.metaKey) {
					window.open(
						environment.baseURL +
							'/projects/' +
							notification.data.project_id,
						'_blank'
					);
				} else {
					window.open(
						environment.baseURL +
							'/projects/' +
							notification.data.project_id,
						'_self'
					);
				}
			}
			const navElement: HTMLElement = document.querySelector(
				'.navbar'
			) as HTMLElement;
			navElement.click();
		});
	}

	initRealTimeNotification() {
		if (this.isEligibleRealTime) {
			this.initSocket();
		}
	}

	isEligibleRealTimeNotification() {
		if (
			this._localStorageService.isProjectManagerUser() &&
			this._localStorageService.isProjectManagerAsAM()
		) {
			return false;
		}

		return true;
	}

	getRoomName() {
		return (
			'user.' +
			this._localStorageService.getUserId() +
			'.header.notifications'
		);
	}

	initSocket() {
		if (!this._socketIoService.isConnected()) {
			this._socketIoService.connect();
			this.newSocketEstablished = true;
		}
		if (!this._socketIoService.isConnected()) {
			return false;
		}

		this.roomName = this.getRoomName();
		this._socketIoService.joinRoom(this.roomName);
		this.socketEstablished = true;

		this.subscribeMessageListener =
			this._socketIoService.subscribeToHeaderNotifications(
				(type, data) => {
					this.retrieveUnreadNotificationCount();
				}
			);
	}

	leaveRoom(roomName) {
		this._socketIoService.leaveRoom(roomName);
	}

	leaveSocket() {
		if (this.socketEstablished) {
			this.leaveRoom(this.roomName);
			this._socketIoService.offEvent(
				'project.chats',
				this.subscribeMessageListener
			);
			if (this.newSocketEstablished) {
				this._socketIoService.disconnect();
			}
		}
	}
	redirectUser() {
		if (this._localStorageService.isUserLoggedIn()) {
			this._router.navigate(['/dashboard']);
		} else {
			this._router.navigate(['/login']);
		}
	}
}
