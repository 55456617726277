<div class="modal-header">
	<h4 class="modal-title">Report</h4>
	<button
		(click)="emitCloseModal.emit()"
		aria-label="Close"
		class="close"
		type="button">
		<em class="fa fa-times" style="font-size: 16px"></em>
	</button>
</div>
<div class="modal-body">
	<div class="text-muted">
		<div class="p mb-4">
			Please submit a report if you have any issues or concerns regarding
			your account. Reports are
			<span class="text-dark">not seen</span>
			by Project Managers or Account Managers and are sent directly to
			upper management.
		</div>
		<div class="p mb-4">
			We look into every report and you should hear back within
			<span class="text-dark">24 hours during business days.</span>
		</div>
		<div class="p mb-4">
			For normal questions about your account, projects or clients, please
			ask a project manager, use our
			<span class="text-dark">live chat</span>
			or email
			<a href="mailto:support@flocksy.com">support@flocksy.com</a>
		</div>
		<form
			(submit)="submitReportConcernForm($event)"
			*ngIf="reportConcernForm"
			[formGroup]="reportConcernForm"
			class="form-horizontal">
			<div class="form-group">
				<div>
					<textarea
						class="form-control"
						formControlName="message"
						name="message"
						rows="5"
						placeholder="Be as detailed as possible and include any relevent links..."></textarea>
					<div
						*ngIf="
							reportConcernForm.controls['message'].hasError(
								'required'
							) &&
							(reportConcernForm.controls['message'].dirty ||
								reportConcernForm.controls['message'].touched)
						"
						class="text-danger">
						Required
					</div>
				</div>
			</div>
			<div class="form-group mb-0 text-right">
				<button
					class="btn btn-primary"
					type="submit"
					[disabled]="!reportConcernForm.valid || aDisabledBtn">
					Submit Report
				</button>
			</div>
		</form>
	</div>
</div>
