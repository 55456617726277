import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { ReportConcernComponent } from './report-concern.component';

@NgModule({
	imports: [SharedModule],
	exports: [ReportConcernComponent],
	declarations: [ReportConcernComponent],
	providers: [],
})
export class ReportConcernModule {}
