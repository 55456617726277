import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ProjectPreferenceService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, oParams?: object): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		if (oParams) {
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL +
					'/' +
					id +
					'/projects/preferences?' +
					params.toString()
			);
		}
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/projects/preferences'
		);
	}

	store(id, data: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/' + id + '/projects/preferences',
			data
		);
	}

	checkTitleAdded(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/types/titles/is-recently-added'
		);
	}

	getOnboardingTestSubType(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/' +
				id +
				'/projects/preferences/onboarding/tests/sub-types'
		);
	}

	getTypePreferences(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/projects/types/preferences'
		);
	}
}
