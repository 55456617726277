import {
	Component,
	OnInit,
	Renderer2,
	RendererStyleFlags2,
	TemplateRef,
	OnDestroy,
	ViewChild,
	ChangeDetectorRef,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FileUploader } from 'ng2-file-upload';

import { LocalStorageService } from '../../../../services/localstorage.service';
import { CommonService } from '../../../../services/common/common.service';
import { ProjectService } from '../../../../services/project/project.service';
import { TeamService } from '../../../../services/team/team.service';
import { BucketService } from '../../../../services/bucket/bucket.service';
import { DocumentService } from '../../../../services/document/document.service';
import { ProjectBucketService } from '../../../../services/project/project-bucket.service';
import { ProjectDetailService } from '../../../../services/project/project-detail.service';
import { ProjectBidService } from '../../../../services/project/project-bid.service';
import { ProjectExcludeDesignersService } from '../../../../services/project/project-exclude-designers.service';
import { ProjectAssignedUserService } from '../../../../services/project/project-assigned-user.service';
import { UserSubClientService } from '../../../../services/user/user-subclient.service';
import { ClientSubClientService } from '../../../../services/client/client-subclient.service';
import { ToasterService } from 'angular2-toaster';
import { FlocksyValidator } from '../../../../common/validation/flocksy-validator';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { ProjectInviteDesignersService } from '../../../../services/project/project-invite-designers.service';
import { FlocksyUtil } from '../../../../common/FlocksyUtil';
import { DashboardService } from '../../../../services/dashboard/dashboard.service';
import { UserService } from '../../../../services/user/user.service';
import { environment } from '../../../../../environments/environment';
import { S3DocumentService } from '../../../../services/document/s3-document.service';
import { ProjectFinalizeService } from '../../../../services/project/project-finalize.service';
import { ProjectUserService } from '../../../../services/project/project-user.service';
import { UserClientService } from '../../../../services/user/user-client.service';
declare var window: any;
import * as moment from 'moment';
import { ReplaySubject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ProjectTypeService } from '../../../../services/project/project-type.service';
@Component({
	selector: 'app-old-editview',
	templateUrl: './old-editview.component.html',
	styleUrls: ['./old-editview.component.scss'],
})
export class OldEditviewComponent implements OnInit, OnDestroy {
	userType: string;
	aBuckets = [];
	aTeamMembers = [];
	aSuggestedTeamMembers = [];
	aExcludedTeamMembers = [];
	initBucketData = [];
	aAppListString = [];
	oBucketText = {
		defaultTitle: 'Select Bucket',
	};
	category: object = {
		type: '',
		title: '',
		questionForms: {
			activeStep: 1,
			totalStep: 0,
			disabledStep: {
				1: false,
			},
		},
	};
	marketplaceCategory: object = {
		type: '',
		title: '',
		questionForms: {
			activeStep: 1,
			totalStep: 0,
			disabledStep: {
				1: false,
			},
		},
	};
	assignedUsers: FormGroup;
	oDefs: object = {};
	oDefsQuestion: object = {};
	closeResult: string;
	orderForm: FormGroup;
	items: any[] = [];
	myForm: FormGroup;
	oQuestionTypes: {};
	aProjectTitle = [];
	validateProjectTitle = true;
	isMobile;
	modalDesignerProjectRef: BsModalRef;
	modalHireDesignerRef: BsModalRef;
	oDesignerUser: object = {};
	aDesignerProjects = [];
	oTmPagination: any = {
		totalRecords: 0,
		currentPage: 1,
		itemsPerPage: 0,
	};
	loadMorePagination: any = {
		currentPage: 1,
	};
	oSuggestedTmPagination: any = {
		totalRecords: 0,
		currentPage: 1,
		itemsPerPage: 0,
	};
	bDisableType = false;
	bDisableTitle = false;
	oSelectedType: object;
	oSelectedTitle: object;
	oQueryString: object = {};
	oProjectQuestion: object = {};
	oDesignerForClient: object = {};
	oAccordion = 1;
	aSubClients = [];
	oProjectsData: any;
	oBaseDropZoneOver = {};
	oUploader = {};
	bDisableBtn = false;
	bShowProgressBar = false;
	progressBarPercentage: any;
	bCopyBrief = false;
	statusForAdmin = 'all';
	showWhichStatus = false;
	oCopyBriefFiles: object = {};
	oPostCopyDocs: object = {};
	teamInviteStatus;
	oFileProgress = {};
	oFileAnalyticsId = {};
	oFileAnalyticsInterval = {};
	utils = FlocksyUtil;
	pendingProjectsCountArr;
	sort_order;
	isEligibleActive = false;
	pendingProjectsCount: number;
	MAX_ANALYTICS_ATTEMPT = FlocksyUtil.getMaxFileAnalyticsAttempt();
	FILE_ANALYTICS_INTERVAL = FlocksyUtil.getFileAnalyticsInterval();
	config = this._localStorageService.getEditorConfig();
	apiFileUploadCount = 0;
	apiFileUploading = false;
	selectedProjectType = '';
	isDemoPlan = this._localStorageService.isDemoPlan();
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();
	isProjectManagerUser = this._localStorageService.isProjectManagerUser();
	isAdminUser = this._localStorageService.isAdminUser();
	isClientUser = this._localStorageService.isClientUser();
	isSubClientUser = this._localStorageService.isSubClientUser();
	isEligibleForHire;
	oProject;
	draftParams: number;
	aDisabledBtn = false;
	collapsed = false;
	accordion = {
		step1: {
			collapsed: false,
		},
		step2: {
			collapsed: false,
		},
		step3: {
			collapsed: false,
		},
		step4: {
			collapsed: false,
		},
		step5: {
			collapsed: false,
		},
		step6: {
			collapsed: false,
		},
	};
	selectedPackage;
	action;
	modalArchiveProjectRef: BsModalRef;
	guid;
	projectAuditTriggers = FlocksyUtil.projectAuditTriggers();
	userId = this._localStorageService.getUserId();
	projectTypeId: number;
	projectTitleId: number;
	projectTitle: string;

	modalNotesRef: BsModalRef;
	modalStatsRef: BsModalRef;

	aProjectUsers = [];
	aFeedbackQuestions = [];
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	startedProject = [];
	designersWorked = [];
	projectConcepts = FlocksyUtil.projectConcepts();
	projectStatus = FlocksyUtil.projectStatus();
	creativeLevels = FlocksyUtil.creativeLevels();
	draftParam = FlocksyUtil.draftParam;
	isUserOnPausePlan: boolean;
	isUpdatedBilling: boolean;
	platinumInvitedDesigners = [];
	marketPlaceCategories: any;
	marketPlaceCategoryTypes = false;
	currentUser: any;
	selectedProjectTitle: any;
	marketplacePackages = [];
	marketplaceQuestions = [];
	isPaymentModalOpen: boolean;
	currentProject: any;
	openPaymentModel: boolean;
	hasMarketplaceAccess: boolean;
	authSeamLessData: any;
	packageId: any;
	isPaymentSucceeded: boolean;
	isPackageNotFound: boolean;
	lastLoadedPackages: any;
	marketPlaceTooltip = `Pay per project with Marketplace projects.
	It doesn't affect your queue if you have a subscription.`;
	activeModalType;
	createProjectPackageListLimit = FlocksyUtil.createProjectPackageListLimit;
	packageInfo;
	newLabelBackgroundImage = '../../../../../assets/img/new-label-bg.svg';
	@ViewChild('packageDetailedInfo') packageDetailedInfoTemp: TemplateRef<any>;
	sectionType = {
		'project-category-types': 1,
		'invite-team-members': 2,
		bucket: 3,
		'project-brief': 4,
		'project-details': 5,
		'project-subcategory-types': 6,
	};
	packageBGImage = FlocksyUtil.packageBGImage;
	discountCouponBgImage = FlocksyUtil.discountBgImage;
	satisfactionGuaranteedImg = FlocksyUtil.satisfactionGuaranteedImg;
	satisfactionGuaranteedTooltip = FlocksyUtil.satisfactionGuaranteedTooltip;
	projectId;
	constructor(
		private _formBuilder: FormBuilder,
		public _localStorageService: LocalStorageService,
		public _commonService: CommonService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _projectService: ProjectService,
		private _projectTypeService: ProjectTypeService,
		private _teamService: TeamService,
		public _bucketService: BucketService,
		public _toasterService: ToasterService,
		public _documentService: DocumentService,
		public _bsModalService: BsModalService,
		public _projectBucketService: ProjectBucketService,
		public _projectDetailService: ProjectDetailService,
		public _projectBidService: ProjectBidService,
		public _projectAssignedUserService: ProjectAssignedUserService,
		public _userSubClientService: UserSubClientService,
		public _projectInviteDesignersService: ProjectInviteDesignersService,
		public _projectExcludeDesignersService: ProjectExcludeDesignersService,
		public _analyticsService: AnalyticsService,
		public _dashboardService: DashboardService,
		public _userService: UserService,
		public _s3DocumentService: S3DocumentService,
		public _renderer2: Renderer2,
		private _clientSubClientService: ClientSubClientService,
		public _projectFinalizeService: ProjectFinalizeService,
		public _projectUserService: ProjectUserService,
		public _userClientService: UserClientService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		this.authSeamLessData = this._localStorageService.getUserAuthSeamLess();
		this.isUserOnPausePlan = this._localStorageService.isUserOnPausePlan();
		this.isUpdatedBilling = this._localStorageService.isUpdatedBilling();
		this.oQuestionTypes =
			this._localStorageService.getProjectQuestionType();
		this.userType = this._localStorageService.getUserType();
		this.currentUser = this._localStorageService.getCurrentUser();
		this.hasMarketplaceAccess =
			this._localStorageService.hasMarketplaceAccess();
		if (this.hasMarketplaceAccess) {
			this.getMarketplaceCategory();
		}
		if (
			this.isAdminUser ||
			this._localStorageService.isProjectManagerUser()
		) {
			this.showWhichStatus = true;
		}

		this._activatedRoute.queryParams.subscribe((params) => {
			this.action = params.action;
			this.packageId = params.package_id;
			this.projectTypeId = params.project_type_id;
			this.projectTitleId = params.project_title_id;
			this.projectTitle = params.project_title;

			if (this.action == 3) {
				this.bCopyBrief = true;
			}
		});

		/*this.assignedUsers = this._formBuilder.group({
            'ids': [[]],
        });*/
	}

	ngOnInit() {
		if (
			!this.isAdminUser &&
			!this._localStorageService.isProjectManagerUser()
		) {
			this.getPendingCount();
			this.getEligibleActiveProject(
				this._localStorageService.getUserId()
			);
		}
		if (
			this.isClientUser ||
			this.isAdminUser ||
			this.isProjectManagerUser
		) {
			this.retrieveDefs();
		} else if (this.isSubClientUser) {
			this.retrievePermissions(
				this._localStorageService.getParentId(),
				this._localStorageService.getUserId()
			);
		}
	}

	ngAfterViewInit() {
		let container = document.getElementsByClassName('section-container');
		if (container && container[0]) {
			this._renderer2.setStyle(
				container[0],
				'margin-bottom',
				'0',
				RendererStyleFlags2.Important + RendererStyleFlags2.DashCase
			);
		}
	}

	retrievePermissions(parentId, userId) {
		this._clientSubClientService
			.getPermissions(parentId, userId)
			.subscribe((data) => {
				if (data.data.allow_managing_my_projects) {
					this.retrieveDefs();
				} else {
					this._toasterService.pop(
						'error',
						"Sorry, you're not able to create projects."
					);
					this._router.navigate(['/projects/active']);
				}
			});
	}

	getProjectTitleForSearchable() {
		const aData = [];
		if (!this.packageId) {
			for (const oTitle of this.oDefs['titles'][this.category['type']]) {
				aData.push({
					id: oTitle['project_title_id'],
					text: oTitle['title'],
				});
			}
		} else {
			const index = this.marketPlaceCategories.findIndex((res) => {
				return res.project_type_id == this.category['type'];
			});

			aData.push({
				id: this.marketPlaceCategories[index]['project_type_id'],
				text: this.marketPlaceCategories[index]['title'],
			});
		}
		return aData;
	}

	setValueViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('type' in params) {
				this.category['type'] = params['type'];
				this.setSelectedType();
				this.bDisableType = true;
			}
			if ('title' in params) {
				this.category['title'] = params['title'];
				this.setSelectedTitle();
				this.initQuestionDefs();
				this.bDisableTitle = true;
			}
			if ('action' in params) {
				this.oQueryString['parent_action'] = params['action'];
				if (
					this.oQueryString['parent_action'] == 2 ||
					this.oQueryString['parent_action'] == 3
				) {
					this.bCopyBrief = true;
				}
			}
			if ('project_id' in params) {
				this.projectId = params['project_id'];
				this.oQueryString['parent_id'] = params['project_id'];
				this.retrieveQuestionAnswers(params['project_id']);
				this.getExcludedDesigners();
			}
		});
	}

	setSelectedType() {
		for (const oType of this.oDefs['types']) {
			if (this.category['type'] == oType['project_type_id']) {
				this.oSelectedType = oType;
				return;
			}
		}
	}

	setMarketplaceSelectedType() {
		this.oSelectedType = {
			project_type_id: this.oProject?.project_type_id,
			title: this.oProject?.project_type_title,
		};
	}

	setMarketplaceSelectedTitle() {
		if (this.oProject && this.marketplaceCategory['type']) {
			this.oSelectedTitle = {
				project_title_id: this.oProject?.project_title_id,
				project_type_id: this.oProject?.project_type_id,
				title: this.oProject?.project_title_title,
			};
		}
	}

	setSelectedTitle() {
		if (this.oDefs && this.category['type']) {
			if (this.oDefs['titles'][this.category['type']]) {
				for (const oTitle of this.oDefs['titles'][
					this.category['type']
				]) {
					if (this.category['title'] == oTitle['project_title_id']) {
						this.oSelectedTitle = oTitle;
						return;
					}
				}
			}
		}
	}

	getProjectCategoryTitle(id) {
		for (const oType of this.oDefs['types']) {
			if (id == oType['project_type_id']) {
				return oType['title'];
			}
		}
	}

	onNextTitle(type: object) {
		this.category['type'] = type['project_type_id'];
		this.category['title'] = '';
		this.marketplaceCategory['type'] = '';
		this.projectTypeId = null;
		this.marketplaceQuestions = [];
		this.selectedPackage = null;
		this.aProjectTitle = this.getProjectTitleForSearchable();
		this.selectedProjectType = type['title'];
		if (this.isAdminUser || this.isProjectManagerUser) {
			return false;
		}
		this.aTeamMembers = [];
		this.oTmPagination['totalRecords'] = 0;
		this.aSuggestedTeamMembers = [];
		this.oSuggestedTmPagination['totalRecords'] = 0;
		/* this.category['title'] = 263;
        this.category['title'] = 299;
        this.onSelectProjectTitle(null); */
	}

	getMarketplaceCategory() {
		this._projectTypeService
			.getMarketPlaceProjectType()
			.subscribe((res) => {
				this.marketPlaceCategories = res.data;
			});
	}

	redirectForUpgradePlan() {
		if (!this.isUpdatedBilling) {
			this._router.navigate(['/plans/billing-info']);
		} else {
			this._router.navigate(['/plans']);
		}
	}

	onMarketplaceNextTitle(type: any) {
		if (
			this.currentProject?.concept_id == this.projectConcepts?.marketplace
		)
			return;
		this.marketplaceCategory['type'] = type?.project_type_id
			? type?.project_type_id
			: this.projectTypeId;
		this.category['type'] = '';
		this.category['title'] = '';
		this.marketplacePackages = [];
		this.marketplaceQuestions = [];
		this.lastLoadedPackages = [];
		this.isPackageNotFound = false;
		this.projectTypeId = type?.project_type_id;
		this.getMarketPlaceProjectTitle();
	}

	getMarketPlaceProjectTitle() {
		let param = {
			concept_id: [FlocksyUtil.projectConcepts()['marketplace']],
		};
		this._projectTypeService
			.getMarketPlaceProjectTitle(this.projectTypeId, param)
			.subscribe((res) => {
				res['data'] = [
					{
						concept_id:
							FlocksyUtil.projectConcepts()['marketplace'],
						description: null,
						project_title_id: 0,
						title: 'All',
					},
					...res.data,
				];
				this.marketPlaceCategoryTypes = res?.data;
				this.changeDetectorRef.detectChanges();
				this._activatedRoute.queryParams.subscribe((params) => {
					if (
						!params['project_title_id'] &&
						!params['project_type_id']
					) {
						this.marketplaceCategory['title'] = [0];
						this.onSelectMarketplaceProjectTitle(
							this.marketPlaceCategoryTypes[0].project_title_id
						);
					}
				});
			});
	}
	onSelectProjectTitle(event) {
		if (!isNaN(event)) {
			this.category['title'] = event;
			this.getSuggestedTeamMembers(true);
			this.initQuestionDefs();
			this.getTeamMembers(true);
		}
	}

	onSelectMarketplaceProjectTitle(event) {
		if (
			this.currentProject?.concept_id == this.projectConcepts?.marketplace
		)
			return;
		this.projectTitleId = event != undefined ? event : this.projectTitleId;
		this.marketplaceCategory['title'] =
			event != undefined
				? event == 0
					? [event]
					: event
				: this.projectTitle;
		this.marketplaceQuestions = [];
		this.marketplacePackages = [];
		this.loadMorePagination['currentPage'] = 1;
		this.isPackageNotFound = false;
		this.selectedPackage = null;
		this.getMarketplacePackages();
	}

	getMarketplacePackages() {
		let param;
		if (this.packageId) {
			param = {
				package_id: this.packageId,
				sort: 'package_id',
			};
		}
		param = {
			...param,
			page: this.loadMorePagination['currentPage'],
		};

		if (this.projectTitleId && this.marketplaceCategory['title'][0] !== 0) {
			param = {
				...param,
				'filter[project_title_id]': this.projectTitleId,
			};
		}
		this._projectTypeService
			.getMarketplacePackages(this.projectTypeId, param)
			.subscribe((val) => {
				this.marketplacePackages = [
					...this.marketplacePackages,
					...val?.data?.data,
				];
				this.lastLoadedPackages = val?.data;
				this.isPackageNotFound =
					this.marketplacePackages.length == 0 ? true : false;
				this.loadMorePagination['currentPage'] =
					val?.data?.current_page;
				if (this.packageId) {
					let isSelectPackage = this.marketplacePackages.find(
						(val) => val?.id == this.packageId
					);
					this.onSelectPackage(isSelectPackage);
				}
			});
	}

	onClickLoadMorePackage() {
		this.loadMorePagination['currentPage'] += 1;
		this.getMarketplacePackages();
	}

	onSelectPackage(selectedPackage) {
		this.modalDesignerProjectRef?.hide();
		if (
			this.currentProject?.concept_id == this.projectConcepts?.marketplace
		)
			return;
		this.selectedPackage = selectedPackage;
		this.projectTitleId = selectedPackage.project_title_id;
		this.getBuckets();
		this._projectTypeService
			.getMarketplaceQuestions(this.projectTypeId, this.projectTitleId)
			.subscribe((val) => {
				this.marketplaceQuestions =
					val.data.questions[this.projectTitleId];
				this.marketplaceCategory['questionForms']['totalStep'] =
					val.data.questions[this.projectTitleId].length;
				this.marketplaceCategory['questionForms']['activeStep'] = 1;
				this.marketplaceCategory['questionForms']['steps'] =
					this._formBuilder.group({});
				this.marketplaceCategory['questionForms']['steps'].reset();
				for (
					let i = 0;
					i < val.data.questions[this.projectTitleId].length;
					i++
				) {
					const step = i + 1;
					let stepControl: FormGroup;
					let field_required = false;
					if (
						val.data.questions[this.projectTitleId][i][
							'field_required'
						]
					) {
						field_required = true;
					}
					if (
						val.data.questions[this.projectTitleId][i][
							'project_question_type_id'
						] == this.oQuestionTypes['checkbox']
					) {
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									this.initFormArray(
										val.data.questions[this.projectTitleId][
											i
										]['field_options']
									),
									Validators.compose([
										Validators.required,
										FlocksyValidator.minLengthCheckbox(1),
									])
								),
							});
						} else {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									this.initFormArray(
										val.data.questions[this.projectTitleId][
											i
										]['field_options']
									)
								),
							});
						}
					} else if (
						val.data.questions[this.projectTitleId][i][
							'project_question_type_id'
						] == this.oQuestionTypes['multienum']
					) {
						this.initAppListStringWithIdName(
							val.data.questions[this.projectTitleId][i][
								'field_options'
							],
							val.data.questions[this.projectTitleId][i][
								'field_options'
							]
						);
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: [
									[],
									Validators.compose([
										FlocksyValidator.required,
										FlocksyValidator.minLengthArray(1),
									]),
								],
							});
						} else {
							stepControl = this._formBuilder.group({
								title: [[]],
							});
						}
					} else if (
						val.data.questions[this.projectTitleId][i][
							'project_question_type_id'
						] == this.oQuestionTypes['file']
					) {
						this.oUploader['step' + step] = new FileUploader({
							url: '',
						});
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									[],
									Validators.required
								),
							});
						} else {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array([]),
							});
						}
					} else {
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: ['', FlocksyValidator.required],
							});
						} else {
							stepControl = this._formBuilder.group({
								title: ['', false],
							});
						}
					}

					this.marketplaceCategory['questionForms'][
						'steps'
					].addControl('step' + step, stepControl);
					if (i !== 0) {
						this.marketplaceCategory['questionForms'][
							'disabledStep'
						][i + 1] = true;
					}
					if (
						val.data.questions[this.projectTitleId].length - 1 ===
						i
					) {
						setTimeout(() => {
							document
								.getElementById('questionContainerGroup')
								.scrollIntoView({
									behavior: 'smooth',
									block: 'start',
									inline: 'nearest',
								});
						});
					}
				}
			});
	}

	onChangeAccordion(event: boolean, active: number) {
		if (event) {
			this.oAccordion = active;
		}
		this.accordion['step' + active].collapsed =
			!this.accordion['step' + active].collapsed;
	}

	retrieveDefs() {
		let status = '';
		if (
			(this.isAdminUser || this.isProjectManagerUser) &&
			(this.statusForAdmin == 'all' || this.statusForAdmin == 'inactive')
		) {
			status = this.statusForAdmin;
		}

		let user_id = '';
		if (
			this.isProjectManagerUser &&
			this._activatedRoute.snapshot.queryParams['user_id']
		) {
			user_id = this._activatedRoute.snapshot.queryParams['user_id'];
			this.bCopyBrief = true;
			status = '';
		}

		const data = {};
		if (this.bCopyBrief) {
			data['has_show_other_category'] = 1;
		}

		this._projectService.getDefs(status, user_id, data).subscribe(
			(data) => {
				this.oDefs = data.data;
				this.setValueViaQueryString();
				this.showWhichStatusChanges();
				if (
					this.hasMarketplaceAccess &&
					!this.currentUser['plan_id'] &&
					!this.packageId
				) {
					if (this.projectTypeId && this.projectTitle) {
						this.onMarketplaceNextTitle({
							project_type_id: this.projectTypeId,
							title: this.projectTitle,
						});
					}
					if (this.projectTitleId) {
						this.onSelectMarketplaceProjectTitle(
							this.projectTitleId
						);
						this.marketplaceCategory['title'] = Number(
							this.projectTitleId
						);
					}
				} else if (
					this.hasMarketplaceAccess &&
					this.currentUser['plan_id'] &&
					this.packageId
				) {
					if (this.projectTypeId && this.projectTitle) {
						this.onMarketplaceNextTitle({
							project_type_id: this.projectTypeId,
							title: this.projectTitle,
						});
					}
					if (this.projectTitleId) {
						this.onSelectMarketplaceProjectTitle(
							this.projectTitleId
						);
						this.marketplaceCategory['title'] = Number(
							this.projectTitleId
						);
					}
				} else if (
					this.hasMarketplaceAccess &&
					!this.currentUser['plan_id'] &&
					this.packageId
				) {
					if (this.projectTypeId && this.projectTitle) {
						this.onMarketplaceNextTitle({
							project_type_id: this.projectTypeId,
							title: this.projectTitle,
						});
					}
					if (this.projectTitleId) {
						this.onSelectMarketplaceProjectTitle(
							this.projectTitleId
						);
						this.marketplaceCategory['title'] = Number(
							this.projectTitleId
						);
					}
				} else {
					if (this.projectTypeId && this.projectTitle) {
						this.onNextTitle({
							project_type_id: this.projectTypeId,
							title: this.projectTitle,
						});
					}

					if (this.projectTitleId) {
						this.onSelectProjectTitle(this.projectTitleId);
						this.category['title'] = Number(this.projectTitleId);
					}
				}
				/* let proj;
                proj = {
                    project_type_id: 1,
                    title: 'Graphic Design'
                }
                proj = {
                    project_type_id: 5,
                    title: 'Video Editing & Creation'
                }
                this.onNextTitle(proj) */
			},
			(err) => {}
		);
	}

	showWhichStatusChanges() {
		if (
			(this.isAdminUser || this.isProjectManagerUser) &&
			this.showWhichStatus
		) {
			if (this.oDefs) {
				for (const key in this.oDefs['titles']) {
					for (const oTitle of this.oDefs['titles'][key]) {
						oTitle['title'] =
							oTitle['title'] +
							' - ' +
							(oTitle['status'] == '1' ? 'Active' : 'Inactive');
					}
				}
			}
		}
	}

	retrieveDefsQuestion(project_type_id, project_title_id) {
		const oFilter = {};
		if (
			(this.isAdminUser ||
				(this.isProjectManagerUser && !this.bCopyBrief)) &&
			this.statusForAdmin == 'all'
		) {
			oFilter['status'] = this.statusForAdmin;
		}

		let userId = '';
		if (this.isProjectManagerUser && this.bCopyBrief) {
			userId = this.oProject.created_by;
		}

		if (this.bCopyBrief) {
			oFilter['has_show_other_category'] = 1;
		}

		if (this.action == 'start-project') {
			oFilter['start-project'] = 1;
		}

		let project_id = this.projectId;

		return this._projectService.getDefsQuestion(
			project_type_id,
			project_title_id,
			userId,
			project_id,
			oFilter
		);
	}

	initAppListStringWithIdName(
		dropdown_name: string,
		oAppStrings: object
	): void {
		this.aAppListString[dropdown_name] = [];
		for (const appListString in oAppStrings) {
			this.aAppListString[dropdown_name].push({
				id: appListString,
				name: oAppStrings[appListString],
			});
		}
	}

	initQuestionDefs(bFill?: boolean) {
		this.validateProjectTitle = true;

		if (this.category['title']) {
			this.oDefs['questions'] = {};
			this.oDefs['app_list_strings'] = {};

			this.retrieveDefsQuestion(
				this.category['type'],
				this.category['title']
			).subscribe((data) => {
				this.oDefs['questions'] = data.data['questions'];
				this.oDefs['app_list_strings'] = data.data['app_list_strings'];
				this.initQuestions(bFill);
			});
		} else {
			this.validateProjectTitle = false;
		}
	}

	initMarketplaceQuestion(bFill?: boolean) {
		this.validateProjectTitle = true;

		if (this.marketplaceCategory['title']) {
			this._projectTypeService
				.getMarketplaceQuestions(
					this.marketplaceCategory['type'],
					this.marketplaceCategory['title']
				)
				.subscribe((data) => {
					this.oDefs['questions'] = data.data['questions'];
					this.marketplaceQuestions =
						data.data['questions'][
							this.marketplaceCategory['title']
						];
					this.initMarketplaceQuestions(bFill);
				});
		} else {
			this.validateProjectTitle = false;
		}
	}

	initMarketplaceQuestions(bFill?: boolean) {
		if (this.marketplaceQuestions) {
			if (this.marketplaceQuestions.length > 0) {
				this.getBuckets();
				this.marketplaceCategory['questionForms']['totalStep'] =
					this.marketplaceQuestions.length;
				this.marketplaceCategory['questionForms']['activeStep'] = 1;
				this.marketplaceCategory['questionForms']['steps'] =
					this._formBuilder.group({});
				this.marketplaceCategory['questionForms']['steps'].reset();
				for (let i = 0; i < this.marketplaceQuestions.length; i++) {
					const step = i + 1;
					let stepControl: FormGroup;
					let field_required = false;
					if (this.marketplaceQuestions[i]['field_required']) {
						field_required = true;
					}

					if (
						this.marketplaceQuestions[i][
							'project_question_type_id'
						] == this.oQuestionTypes['file']
					) {
						this.oUploader['step' + step] = new FileUploader({
							url: '',
						});
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									[],
									Validators.required
								),
							});
						} else {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array([]),
							});
						}
					} else {
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: ['', FlocksyValidator.required],
							});
						} else {
							stepControl = this._formBuilder.group({
								title: ['', false],
							});
						}
					}
					this.marketplaceCategory['questionForms'][
						'steps'
					].addControl('step' + step, stepControl);
					if (i !== 0) {
						this.marketplaceCategory['questionForms'][
							'disabledStep'
						][i + 1] = true;
					}
				}
				this.marketplaceCategory['questionForms']['disabledStep'][
					this.marketplaceQuestions.length + 1
				] = true;
			}
		}

		if (bFill) {
			this.fillMarketplaceQuestionAnswers();
			this.retrieveSharedBuckets(this.oQueryString['parent_id']);
		}
	}
	initQuestions(bFill?: boolean) {
		if (this.oDefs['questions'][this.category['title']]) {
			if (this.oDefs['questions'][this.category['title']].length > 0) {
				this.getBuckets();
				this.category['questionForms']['totalStep'] =
					this.oDefs['questions'][this.category['title']].length;
				this.category['questionForms']['activeStep'] = 1;
				this.category['questionForms']['steps'] =
					this._formBuilder.group({});
				this.category['questionForms']['steps'].reset();
				for (
					let i = 0;
					i < this.oDefs['questions'][this.category['title']].length;
					i++
				) {
					const step = i + 1;
					let stepControl: FormGroup;
					let field_required = false;
					if (
						this.oDefs['questions'][this.category['title']][i][
							'field_required'
						]
					) {
						field_required = true;
					}

					if (
						this.oDefs['questions'][this.category['title']][i][
							'project_question_type_id'
						] == this.oQuestionTypes['checkbox']
					) {
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									this.initFormArray(
										this.oDefs['app_list_strings'][
											this.oDefs['questions'][
												this.category['title']
											][i]['field_options']
										]
									),
									Validators.compose([
										Validators.required,
										FlocksyValidator.minLengthCheckbox(1),
									])
								),
							});
						} else {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									this.initFormArray(
										this.oDefs['app_list_strings'][
											this.oDefs['questions'][
												this.category['title']
											][i]['field_options']
										]
									)
								),
							});
						}
					} else if (
						this.oDefs['questions'][this.category['title']][i][
							'project_question_type_id'
						] == this.oQuestionTypes['multienum']
					) {
						this.initAppListStringWithIdName(
							this.oDefs['questions'][this.category['title']][i][
								'field_options'
							],
							this.oDefs['app_list_strings'][
								this.oDefs['questions'][this.category['title']][
									i
								]['field_options']
							]
						);

						if (field_required) {
							stepControl = this._formBuilder.group({
								title: [
									[],
									Validators.compose([
										FlocksyValidator.required,
										FlocksyValidator.minLengthArray(1),
									]),
								],
							});
						} else {
							stepControl = this._formBuilder.group({
								title: [[]],
							});
						}
					} else if (
						this.oDefs['questions'][this.category['title']][i][
							'project_question_type_id'
						] == this.oQuestionTypes['file']
					) {
						this.oUploader['step' + step] = new FileUploader({
							url: '',
						});
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array(
									[],
									Validators.required
								),
							});
						} else {
							stepControl = this._formBuilder.group({
								title: this._formBuilder.array([]),
							});
						}
					} else {
						if (field_required) {
							stepControl = this._formBuilder.group({
								title: ['', FlocksyValidator.required],
							});
						} else {
							stepControl = this._formBuilder.group({
								title: ['', false],
							});
						}
					}

					this.category['questionForms']['steps'].addControl(
						'step' + step,
						stepControl
					);
					if (i !== 0) {
						this.category['questionForms']['disabledStep'][i + 1] =
							true;
					}
				}
				this.category['questionForms']['disabledStep'][
					this.oDefs['questions'][this.category['title']].length + 1
				] = true;
			}
		}

		if (bFill) {
			this.fillQuestionAnswers();
			this.retrieveSharedBuckets(this.oQueryString['parent_id']);
			this.getTeamMembers(true);
			if (this.action == 'start-project') {
				this.retrieveInvitedDesigners(this.oQueryString['parent_id']);
			}
			/*this.retrieveInvitedDesigners(this.oQueryString['parent_id']);*/
		}
	}

	initFormArray(oFormAppStrings: object) {
		const aAssignForm = [];
		let i = 0;
		for (const appFormListString in oFormAppStrings) {
			aAssignForm[i] = this._formBuilder.group({
				name: appFormListString,
				value: false,
				text: oFormAppStrings[appFormListString],
			});
			i++;
		}
		return aAssignForm;
	}

	onSingleQueFileSelected(event, name) {
		let control;
		if (
			(this.hasMarketplaceAccess || this.selectedPackage) &&
			this.marketplaceCategory['questionForms']['steps']
		) {
			control = <FormArray>(
				this.marketplaceCategory['questionForms']['steps'].controls[
					name
				].controls['title']
			);
		} else {
			control = <FormArray>(
				this.category['questionForms']['steps'].controls[name].controls[
					'title'
				]
			);
		}
		for (const oFile of event.target.files) {
			control.push(
				this._formBuilder.group({
					file: [oFile, ''],
				})
			);
		}
	}

	initFileRow() {
		return this._formBuilder.group({
			file: ['', ''],
		});
	}

	fileOverBase(name, e: any): void {
		this.oBaseDropZoneOver[name] = e;
	}

	onFileDropped(name, event: any): void {
		const control = <FormArray>(
			this.category['questionForms']['steps'].controls[name].controls[
				'title'
			]
		);
		for (const oFile of event) {
			control.push(
				this._formBuilder.group({
					file: [oFile, ''],
				})
			);
		}
	}

	unsetQueFile(name, index) {
		let control;
		if (
			(this.hasMarketplaceAccess || this.selectedPackage) &&
			this.marketplaceCategory['questionForms']['steps']
		) {
			control = <FormArray>(
				this.marketplaceCategory['questionForms']['steps']?.controls[
					name
				]?.controls['title']
			);
		} else {
			control = <FormArray>(
				this.category['questionForms']['steps']?.controls[name]
					?.controls['title']
			);
		}
		control.removeAt(index);
		document.getElementById('que_file_' + name)['value'] = '';
	}

	onSingleQueFileRemove(name) {
		document.getElementById('que_file_' + name)['value'] = '';
		this.category['questionForms']['steps'].controls[name].controls[
			'title'
		].setValue('');
	}

	unsetCopyDoc(name, index) {
		if (this.oCopyBriefFiles[name]) {
			if (index !== -1) {
				this.oCopyBriefFiles[name].splice(index, 1);
			}
		}
	}
	saveAsDraft($event) {
		$event.preventDefault();
		this.draftParams = 5;
		if (
			(this.selectedPackage || this.marketplaceCategory['type']) &&
			this.hasMarketplaceAccess
		) {
			this.onMarketplaceQuestionsSubmit(this.draftParams);
		} else {
			this.onQuestionSubmit(this.draftParams);
		}
	}

	onQuestionSubmit(oParams) {
		if (this.category['questionForms']['steps'].valid || oParams) {
			this.bDisableBtn = true;
			let project_title = '';
			const question_answers: Array<object> = [];
			const oDocumentApis: object = {};
			let aPayloadBucket = [];
			let aPayloadInviteDesigner = [];

			if (this.category['title']) {
				if (this.oDefs['questions'][this.category['title']]) {
					if (
						this.oDefs['questions'][this.category['title']].length >
						0
					) {
						for (
							let k = 0;
							k <
							this.oDefs['questions'][this.category['title']]
								.length;
							k++
						) {
							if (
								'custom_field' in
								this.oDefs['questions'][this.category['title']][
									k
								]
							) {
								if (
									this.oDefs['questions'][
										this.category['title']
									][k]['custom_field'] == 'title'
								) {
									project_title =
										this.category['questionForms']['steps']
											.value['step' + (k + 1)]['title'];
									continue;
								} else if (
									this.oDefs['questions'][
										this.category['title']
									][k]['custom_field'] == 'bucket'
								) {
									aPayloadBucket =
										this.category['questionForms']['steps']
											.value['step' + (k + 1)]['title'];
									continue;
								} else if (
									this.oDefs['questions'][
										this.category['title']
									][k]['custom_field'] == 'invite_designer'
								) {
									aPayloadInviteDesigner =
										this.category['questionForms']['steps']
											.value['step' + (k + 1)]['title'];
									continue;
								}
							}

							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_type_id'] ==
								this.oQuestionTypes['textarea']
							) {
								const answer =
									this.category['questionForms']['steps']
										.value['step' + (k + 1)]['title'];
								if (answer.match(/<img/)) {
									this._toasterService.pop(
										'error',
										'Oops, looks like an image got into the text box! Please remove it.'
									);
									this.bDisableBtn = false;
									return;
								}
							}

							const answers = {
								project_question_id:
									this.oDefs['questions'][
										this.category['title']
									][k]['project_question_id'],
								answer: this.category['questionForms']['steps']
									.value['step' + (k + 1)]['title'],
								project_question_type_id:
									this.oDefs['questions'][
										this.category['title']
									][k]['project_question_type_id'],
								field_options:
									this.oDefs['questions'][
										this.category['title']
									][k]['field_options'],
								order: this.oDefs['questions'][
									this.category['title']
								][k]['order'],
							};
							question_answers.push(answers);

							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_type_id'] == 7
							) {
								if (
									this.category['questionForms']['steps']
										.value['step' + (k + 1)]['title']
								) {
									oDocumentApis[question_answers.length - 1] =
										this.category['questionForms'][
											'steps'
										].value['step' + (k + 1)]['title'];
								}
								if (
									this.oCopyBriefFiles['step' + (k + 1)] &&
									this.oCopyBriefFiles['step' + (k + 1)]
										.length > 0
								) {
									this.oPostCopyDocs[
										this.oDefs['questions'][
											this.category['title']
										][k]['project_question_id']
									] = this.oCopyBriefFiles['step' + (k + 1)];
								}
							}
						}
					}
				}
			}

			const restData = {
				created_by: this._localStorageService.getUserId(),
				project_title_id: this.category['title'],
				question_answers: question_answers,
				title: project_title,
				invite_designers: aPayloadInviteDesigner,
				exclude_designers: this.aExcludedTeamMembers,
				query_string: this.oQueryString,
				copy_brief_docs: this.oPostCopyDocs,
				team_invite_status: this.teamInviteStatus,
				platinum_invited_designers: this.platinumInvitedDesigners,
			};

			if (!this.isEligibleActive) {
				restData['sort_order'] = this.sort_order;
			}

			this.apiFileUploadCount = 0;
			if (Object.keys(oDocumentApis).length > 0) {
				let k = 0;
				for (const oDocumentApi in oDocumentApis) {
					const oDocData = {};
					oDocData['name'] = 'File';
					oDocData['document_category_id'] = 2;
					oDocData['description'] = '';
					oDocData['description1'] = '';
					let n = 0;
					for (const oDoc of oDocumentApis[oDocumentApi]) {
						oDocData['file' + (n + 1)] = oDoc['file'];
						n++;
					}
					if (!environment.useAWSS3) {
						this.storeDocumentFile(oDocData).subscribe(
							(data) => {
								if (data) {
									this.bShowProgressBar = true;
									this.progressBarPercentage = data.progress;
									if (data['data']) {
										this.bShowProgressBar = false;
										if (data['data']['document_id']) {
											const document_id =
												data['data']['document_id'];
											restData['question_answers'][
												oDocumentApi
											]['answer'] = '';
											restData['question_answers'][
												oDocumentApi
											]['document_id'] = document_id;

											if (
												k + 1 ==
												Object.keys(oDocumentApis)
													.length
											) {
												this.postProject(
													restData,
													aPayloadBucket
												);
											}
											k++;
										}
									}
								}
							},
							(err) => {
								this.bDisableBtn = false;
								return false;
							}
						);
					} else {
						if (oDocumentApis[oDocumentApi].length != 0) {
							this.storeDocumentFileS3(
								oDocumentApis,
								oDocumentApi,
								restData,
								aPayloadBucket
							);
						} else {
							this.apiFileUploadCount++;
							if (
								this.apiFileUploadCount ==
									Object.keys(oDocumentApis).length &&
								!this.apiFileUploading
							) {
								this.postProject(restData, aPayloadBucket);
							}
						}
						k++;
					}
				}
			} else {
				this.postProject(restData, aPayloadBucket);
			}
		}
	}

	onMarketplaceQuestionsSubmit(oParams) {
		if (
			this.marketplaceCategory['questionForms']['steps'].valid ||
			oParams
		) {
			this.bDisableBtn = true;
			let project_title = '';
			const question_answers: Array<object> = [];
			const oDocumentApis: object = {};
			let aPayloadBucket = [];
			let aPayloadInviteDesigner = [];

			if (this.marketplaceCategory['title']) {
				if (this.marketplaceQuestions) {
					if (this.marketplaceQuestions.length > 0) {
						for (
							let k = 0;
							k < this.marketplaceQuestions.length;
							k++
						) {
							if (
								'custom_field' in this.marketplaceQuestions[k]
							) {
								if (
									this.marketplaceQuestions[k][
										'custom_field'
									] == 'title'
								) {
									project_title =
										this.marketplaceCategory[
											'questionForms'
										]['steps'].value['step' + (k + 1)][
											'title'
										];
									continue;
								} else if (
									this.marketplaceQuestions[k][
										'custom_field'
									] == 'bucket'
								) {
									aPayloadBucket =
										this.marketplaceCategory[
											'questionForms'
										]['steps'].value['step' + (k + 1)][
											'title'
										];
									continue;
								} else if (
									this.marketplaceQuestions[k][
										'custom_field'
									] == 'invite_designer'
								) {
									aPayloadInviteDesigner =
										this.marketplaceCategory[
											'questionForms'
										]['steps'].value['step' + (k + 1)][
											'title'
										];
									continue;
								}
							}

							if (
								this.marketplaceQuestions[k][
									'project_question_type_id'
								] == this.oQuestionTypes['textarea']
							) {
								const answer =
									this.marketplaceCategory['questionForms'][
										'steps'
									].value['step' + (k + 1)]['title'];
								if (answer.match(/<img/)) {
									this._toasterService.pop(
										'error',
										'Oops, looks like an image got into the text box! Please remove it.'
									);
									this.bDisableBtn = false;
									return;
								}
							}

							const answers = {
								project_question_id:
									this.marketplaceQuestions[k][
										'project_question_id'
									],
								answer: this.marketplaceCategory[
									'questionForms'
								]['steps'].value['step' + (k + 1)]['title'],
								project_question_type_id:
									this.marketplaceQuestions[k][
										'project_question_type_id'
									],
								field_options:
									this.marketplaceQuestions[k][
										'field_options'
									],
								order: this.marketplaceQuestions[k]['order'],
							};
							question_answers.push(answers);

							if (
								this.marketplaceQuestions[k][
									'project_question_type_id'
								] == 7
							) {
								if (
									this.marketplaceCategory['questionForms'][
										'steps'
									].value['step' + (k + 1)]['title']
								) {
									oDocumentApis[question_answers.length - 1] =
										this.marketplaceCategory[
											'questionForms'
										]['steps'].value['step' + (k + 1)][
											'title'
										];
								}
								if (
									this.oCopyBriefFiles['step' + (k + 1)] &&
									this.oCopyBriefFiles['step' + (k + 1)]
										.length > 0
								) {
									this.oPostCopyDocs[
										this.marketplaceQuestions[k][
											'project_question_id'
										]
									] = this.oCopyBriefFiles['step' + (k + 1)];
								}
							}
						}
					}
				}
			}

			const restData = {
				created_by: this._localStorageService.getUserId(),
				project_title_id: this.projectTitleId,
				question_answers: question_answers,
				title: project_title,
				invite_designers: aPayloadInviteDesigner,
				exclude_designers: this.aExcludedTeamMembers,
				query_string: this.oQueryString,
				copy_brief_docs: this.oPostCopyDocs,
				team_invite_status: this.teamInviteStatus,
				platinum_invited_designers: this.platinumInvitedDesigners,
			};

			if (!this.isEligibleActive) {
				restData['sort_order'] = this.sort_order;
			}

			this.apiFileUploadCount = 0;
			if (Object.keys(oDocumentApis).length > 0) {
				let k = 0;
				for (const oDocumentApi in oDocumentApis) {
					const oDocData = {};
					oDocData['name'] = 'File';
					oDocData['document_category_id'] = 2;
					oDocData['description'] = '';
					oDocData['description1'] = '';
					let n = 0;
					for (const oDoc of oDocumentApis[oDocumentApi]) {
						oDocData['file' + (n + 1)] = oDoc['file'];
						n++;
					}
					if (!environment.useAWSS3) {
						this.storeDocumentFile(oDocData).subscribe(
							(data) => {
								if (data) {
									this.bShowProgressBar = true;
									this.progressBarPercentage = data.progress;
									if (data['data']) {
										this.bShowProgressBar = false;
										if (data['data']['document_id']) {
											const document_id =
												data['data']['document_id'];
											restData['question_answers'][
												oDocumentApi
											]['answer'] = '';
											restData['question_answers'][
												oDocumentApi
											]['document_id'] = document_id;

											if (
												k + 1 ==
												Object.keys(oDocumentApis)
													.length
											) {
												this.postProject(
													restData,
													aPayloadBucket
												);
											}
											k++;
										}
									}
								}
							},
							(err) => {
								this.bDisableBtn = false;
								return false;
							}
						);
					} else {
						if (oDocumentApis[oDocumentApi].length != 0) {
							this.storeDocumentFileS3(
								oDocumentApis,
								oDocumentApi,
								restData,
								aPayloadBucket
							);
						} else {
							this.apiFileUploadCount++;
							if (
								this.apiFileUploadCount ==
									Object.keys(oDocumentApis).length &&
								!this.apiFileUploading
							) {
								this.postProject(restData, aPayloadBucket);
							}
						}
						k++;
					}
				}
			} else {
				this.postProject(restData, aPayloadBucket);
			}
		}
	}

	postProject(restData, aPayloadBucket?) {
		let params = {};
		if (this.isProjectManagerUser && this.bCopyBrief) {
			params['user_id'] = this.oProject.created_by;
		}
		if (
			(this.isOperationalClientUser &&
				this.draftParams == this.draftParam) ||
			((this.selectedPackage || this.marketplaceCategory['type']) &&
				this.hasMarketplaceAccess)
		) {
			params['project_status_id'] = 5;
		}
		if (this.hasMarketplaceAccess && this.selectedPackage) {
			restData = {
				...restData,
				concept_id: this.projectConcepts.marketplace,
				package_id: this.selectedPackage?.id,
			};
		}

		let projectStarted;
		if (this.openPaymentModel && this.draftParams != this.draftParam) {
			this.isPaymentModalOpen = true;
			return;
		}
		if (
			this.action == 'start-project' &&
			this.draftParams != this.draftParam
		) {
			if (
				this.hasMarketplaceAccess &&
				(this.selectedPackage || this.marketplaceCategory['type'])
			) {
				restData = {
					...restData,
					concept_id: this.projectConcepts.marketplace,
				};
			} else {
				restData['action'] = 'start-project';
			}
			projectStarted = this.storeProjectFromDraft(
				this.oQueryString['parent_id'],
				restData,
				params,
				aPayloadBucket
			);
		} else if (this.action == 'start-project' && this.draftParams == 5) {
			restData['action'] = 'update-draft';
			projectStarted = this.storeProjectFromDraft(
				this.oQueryString['parent_id'],
				restData,
				params,
				aPayloadBucket
			);
		} else {
			projectStarted = this.storeProject(
				restData,
				params,
				aPayloadBucket
			);
		}

		projectStarted.then((data) => {
			if (this.action == 'redo-project') {
				this.retrieveUsers();
				this.startedProject = data;
			} else {
				this.currentProject = data;
				if (
					this.hasMarketplaceAccess &&
					!this.draftParams &&
					(this.selectedPackage || this.marketplaceCategory['type'])
				) {
					this.isPaymentModalOpen = true;
				} else {
					this.redirectProject(data['project_status_id'], data);
				}
			}
			this.bDisableBtn = false;
		});
	}

	storeProject(restData, params, aPayloadBucket?) {
		if (this.action == 'redo-project') {
			params['action'] = 'redo-project';
		}
		return new Promise((resolve, reject) => {
			this._projectService.post(restData, params).subscribe(
				(data) => {
					let toastMessage;
					if (
						!(
							this.selectedPackage ||
							this.marketplaceCategory['type']
						)
					) {
						toastMessage =
							params['project_status_id'] !=
							this.projectStatus['draft']
								? "Your project has been posted! Let's get things done..."
								: 'Your project has been saved to drafts...';
					} else {
						toastMessage =
							'Your project has been saved to drafts...';
					}
					this._toasterService.pop('success', toastMessage);
					data = data.data;
					const project_status_id = data['project_status_id'];
					this.oQueryString['parent_id'] = data['project_id'];
					if (aPayloadBucket) {
						this.shareBuckets(data.project_id, aPayloadBucket);
					}
					/*if (this.aExcludedTeamMembers && this.aExcludedTeamMembers.length > 0) {
                    this.assignExcludeDesigners(data.project_id, {ids: this.aExcludedTeamMembers});
                }*/
					if (this.teamInviteStatus) {
						this.assignInviteExcludeTeam(data.project_id);
					}
					/*if(this.assignedUsers.value && this.assignedUsers.value['ids'])
                {
                    if(this.assignedUsers.value['ids'].length > 0)
                    {
                        this.storeAssignedUser(data.project_id, this.assignedUsers.value);
                    }
                }*/
					this.bDisableBtn = false;
					resolve(data);
				},
				(err) => {
					this.bDisableBtn = false;
					reject();
				}
			);
		});
	}

	storeDocumentFile(oDoc) {
		return this._documentService.store(oDoc);
	}

	storeDocumentFileS3(oDocumentApis, oDocumentApi, restData, aPayloadBucket) {
		this.apiFileUploading = true;

		let oFiles = oDocumentApis[oDocumentApi];
		oFiles = oFiles.map((file) => file.file);
		oFiles = this._s3DocumentService.generateFileHash(oFiles);

		// analytics
		let startTime = moment();
		this._analyticsService
			.storeDocumentTrackData(2, 17, 0, oFiles)
			.subscribe((res) => {
				this.oFileAnalyticsId[oDocumentApi] = res.data.request_id;
			});

		this.oFileProgress = {};
		let uploadFilePromises = this._s3DocumentService.uploadFiles(
			oFiles,
			2,
			function (evt) {
				let percent = Math.round((evt.loaded * 100) / evt.total);
				this.oFileProgress[evt.key] = percent;
				let progressPercentage = 0;
				for (const key in this.oFileProgress) {
					progressPercentage =
						progressPercentage + this.oFileProgress[key];
				}
				this.bShowProgressBar = true;
				this.progressBarPercentage = Math.floor(
					progressPercentage /
						(oFiles.length * Object.keys(oDocumentApis).length)
				);
			}.bind(this)
		);

		Promise.all(uploadFilePromises).then(
			async (res) => {
				// analytics
				this.updateFileAnalytics(startTime, oDocumentApi, 2);

				// document files
				let postFiles = [];
				for (let i = 0; i < oFiles.length; i++) {
					const file = oFiles[i];
					postFiles.push({
						filename: file.name,
						file_hash: file.file_hash,
					});
				}
				let storeFilesData = {
					document_category_id: 2,
					files: postFiles,
				};
				this._s3DocumentService
					.storeDocumentFiles(storeFilesData)
					.subscribe((res) => {
						this.bShowProgressBar = false;
						const document_id = res.data[0];
						restData['question_answers'][oDocumentApi]['answer'] =
							'';
						restData['question_answers'][oDocumentApi][
							'document_id'
						] = document_id;
						this.apiFileUploadCount++;
						if (
							this.apiFileUploadCount ==
							Object.keys(oDocumentApis).length
						) {
							this.postProject(restData, aPayloadBucket);
						}
					});
			},
			(err) => {
				this.bDisableBtn = false;
				this.bShowProgressBar = false;
				this._toasterService.pop(
					'error',
					'There was an error uploading your files'
				);
				this.updateFileAnalytics(startTime, oDocumentApi, 3, err);
			}
		);
	}

	updateFileAnalytics(startTime, oDocumentApi, status, err?) {
		const duration = moment().diff(startTime, 'seconds');
		let i = 0;
		this.oFileAnalyticsInterval[oDocumentApi] = setInterval(() => {
			if (this.oFileAnalyticsId[oDocumentApi]) {
				this._analyticsService
					.updateDocumentsTrackData(
						this.oFileAnalyticsId[oDocumentApi],
						{
							time_duration: duration,
							status: status,
							failed_reason: err,
						}
					)
					.subscribe();
				clearInterval(this.oFileAnalyticsInterval[oDocumentApi]);
			}
			++i;
			if (i == this.MAX_ANALYTICS_ATTEMPT) {
				clearInterval(this.oFileAnalyticsInterval[oDocumentApi]);
			}
		}, this.FILE_ANALYTICS_INTERVAL);
	}

	getBuckets() {
		if (
			this.isAdminUser ||
			(this.isProjectManagerUser && !this.bCopyBrief)
		) {
			return true;
		}

		this.retrieveBuckets().subscribe(
			(data) => {
				this.aBuckets = this.filterBuckets(data.data);
			},
			(err) => {}
		);
	}

	openSelectBucket() {
		if (
			this.isAdminUser ||
			this._localStorageService.isProjectManagerUser()
		) {
			return true;
		}

		this.retrieveBuckets().subscribe(
			(data) => {
				if (data.data.length > this.aBuckets.length) {
					this.aBuckets = this.filterBuckets(data.data);
				}
			},
			(err) => {}
		);
	}

	retrieveBuckets() {
		const params = {};
		if (this.isProjectManagerUser && this.bCopyBrief) {
			params['user_id'] = this.oProject.created_by;
		}
		params['action'] = 'for_dropdown';
		return this._bucketService.get(params);
	}

	filterBuckets(aData) {
		const aBuckets = [];
		for (const oData of aData) {
			aBuckets.push({
				id: oData.id,
				name: oData.name,
			});
		}
		return aBuckets;
	}

	formatBucketsForSelect2(aBucket) {
		const aData = [];
		for (const oData of aBucket) {
			aData.push({ id: oData.id, text: oData.name });
		}
		return aData;
	}

	getTeamMembers(reset?: boolean) {
		if (
			this.isAdminUser ||
			(this.isProjectManagerUser && !this.bCopyBrief)
		) {
			return false;
		}

		this.retrieveTeamMembers().subscribe(
			(data) => {
				if (reset) {
					this.aTeamMembers = data.data;
				} else {
					this.aTeamMembers = this.aTeamMembers.concat(data.data);
				}

				if (this.designersWorked && this.designersWorked.length > 0) {
					this.addDesignersToExcluded();
				}

				this.invitePlatinumTeamMembers();

				this.oTmPagination['totalRecords'] = data.total_records;
				this.oTmPagination['itemsPerPage'] = data.items_per_page;
			},
			(err) => {}
		);
	}

	retrieveTeamMembers() {
		let userId = this._localStorageService.getUserId();
		if (this.isProjectManagerUser && this.bCopyBrief) {
			userId = this.oProject.created_by;
		}
		return this._teamService.getDesignersByClient(
			userId,
			this.prepareTmPagination()
		);
	}

	prepareTmPagination() {
		let oParam = {
			project_type_id: this.category['type'],
			client_designers_block: {
				project_title_id: this.category['title'],
			},
			pageId: 'post-project',
		};
		if (this.bCopyBrief) {
			if (this.oQueryString && 'parent_id' in this.oQueryString) {
				oParam['exclude_project_id'] = this.oQueryString['parent_id'];
			}
		}
		return {
			page: this.oTmPagination['currentPage'],
			filters: JSON.stringify(oParam),
		};
	}

	onClickLoadMoreTm() {
		this.oTmPagination['currentPage'] =
			this.oTmPagination['currentPage'] + 1;
		this.getTeamMembers();
	}

	onClickAddTeamMember(
		name: string,
		user_id: number,
		creativeLevelId: number
	) {
		const aTeams =
			this.category['questionForms']['steps'].controls[name].controls[
				'title'
			].value;
		if (this.aExcludedTeamMembers.indexOf(user_id) > -1) {
			this.onClickRemoveExcludeTeamMember(user_id);
		}

		if (aTeams.indexOf(user_id) == -1) {
			aTeams.push(user_id);
			if (
				creativeLevelId == this.creativeLevels['platinum'] ||
				creativeLevelId == this.creativeLevels['platinum-lead']
			) {
				this.platinumInvitedDesigners.push(user_id);
			}
		}

		if (this.modalDesignerProjectRef) {
			this.modalDesignerProjectRef.hide();
		}
	}

	onClickRemoveTeamMember(name: string, user_id: number) {
		const aTeams =
			this.category['questionForms']['steps'].controls[name].controls[
				'title'
			].value;

		const index = aTeams.indexOf(user_id);
		const platinumIndex = this.platinumInvitedDesigners.indexOf(user_id);
		if (index > -1) {
			aTeams.splice(index, 1);
		}

		if (platinumIndex > -1) {
			this.platinumInvitedDesigners.splice(index, 1);
		}

		if (this.modalDesignerProjectRef) {
			this.modalDesignerProjectRef.hide();
		}
	}

	onClickAddExcludeTeamMember(user_id: number) {
		const aTeams =
			this.category['questionForms']['steps'].controls['step1'].controls[
				'title'
			].value;
		if (aTeams.indexOf(user_id) > -1) {
			this.onClickRemoveTeamMember('step1', user_id);
		}

		if (this.aExcludedTeamMembers.indexOf(user_id) == -1) {
			this.aExcludedTeamMembers.push(user_id);
		}
	}

	onClickRemoveExcludeTeamMember(user_id: number) {
		const index = this.aExcludedTeamMembers.indexOf(user_id);
		if (index > -1) {
			this.aExcludedTeamMembers.splice(index, 1);
		}
	}

	getSuggestedTeamMembers(reset?: boolean) {
		if (
			this.isAdminUser ||
			(this.isProjectManagerUser && !this.bCopyBrief)
		) {
			return false;
		}
		/** https://flocksy.atlassian.net/browse/FT-704 */
		return false;
		if (reset) {
			this.resetSuggestedTmPagination();
		}

		let userId = this._localStorageService.getUserId();
		if (this.isProjectManagerUser && this.bCopyBrief) {
			userId = this.oProject.created_by;
		}

		this.retrieveSuggestedTeamMembers(userId).subscribe((data) => {
			if (reset) {
				this.aSuggestedTeamMembers = data.data;
			} else {
				this.aSuggestedTeamMembers = this.aSuggestedTeamMembers.concat(
					data.data
				);
			}
			this.aSuggestedTeamMembers.forEach((member) => {
				member.is_suggested = true;
			});
			this.oSuggestedTmPagination['totalRecords'] = data.total_records;
			this.oSuggestedTmPagination['itemsPerPage'] = data.items_per_page;
		});
	}

	retrieveSuggestedTeamMembers(userId) {
		return this._teamService.getSuggestedDesignersByClient(
			userId,
			this.prepareSuggestedTmPagination()
		);
	}

	prepareSuggestedTmPagination() {
		let filters = {
			project_type_id: this.category['type'],
			client_designers_block: {
				project_title_id: this.category['title'],
			},
		};
		if (this.bCopyBrief) {
			filters['parent_project_id'] = this.oQueryString['parent_id'];
		}
		return {
			page: this.oSuggestedTmPagination['currentPage'],
			filters: JSON.stringify(filters),
		};
	}

	onClickLoadMoreSuggestedTm() {
		this.oSuggestedTmPagination['currentPage'] =
			this.oSuggestedTmPagination['currentPage'] + 1;
		this.getSuggestedTeamMembers();
	}

	resetSuggestedTmPagination() {
		this.oSuggestedTmPagination = {
			totalRecords: 0,
			currentPage: 1,
			itemsPerPage: 0,
		};
	}

	initDesignerProjectModal(
		oDesignerUser,
		template: TemplateRef<any>,
		eventId: any,
		modalType
	) {
		this.isEligibleForHire = oDesignerUser.is_eligible_hire_team_member;
		this.modalDesignerProjectRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-xl modal-full' }
			)
		);
		this.oDesignerUser = oDesignerUser;
		this._analyticsService.setOverlayTrackData(
			17,
			eventId,
			this.oDesignerUser['id'],
			1
		);
		this.aDesignerProjects = [];
		this.activeModalType = modalType;
	}

	formatTeamMemberForSelect2(aUsers) {
		const aData = [];
		for (const oData of aUsers) {
			aData.push({ id: oData.id, text: oData.name });
		}
		return aData;
	}

	shareBuckets(project_id, aData) {
		const data = {
			bucket_ids: aData != '' ? aData : [],
			is_post_project: 1,
		};
		if (project_id) {
			this._projectBucketService
				.storeSharedBuckets(project_id, data)
				.subscribe(
					(data) => {},
					(err) => {}
				);
		}
	}

	assignExcludeDesigners(project_id, oData) {
		this._projectExcludeDesignersService.store(project_id, oData).subscribe(
			(data) => {},
			(err) => {}
		);
	}

	assignInviteExcludeTeam(project_id) {
		let teamRequest;
		if (this.teamInviteStatus == 1) {
			teamRequest = this._projectInviteDesignersService.inviteTeam(
				project_id,
				{}
			);
		} else if (this.teamInviteStatus == 2) {
			teamRequest = this._projectExcludeDesignersService.excludeTeam(
				project_id,
				{}
			);
		}

		if (teamRequest) {
			teamRequest.subscribe(
				(data) => {},
				(err) => {}
			);
		}
	}

	retrieveQuestionAnswers(guid) {
		let data = {};
		if (this.action == 'redo-project') {
			data['action'] = 'redo-project';
		}

		this._projectDetailService.get(guid, data).subscribe(
			(data) => {
				const oProjectsData = data.data;
				this.oProject = oProjectsData['project'];
				this.oProjectsData = oProjectsData;
				this.designersWorked = oProjectsData['project']['designer'];
				this.selectedPackage = oProjectsData['project_package'];

				if (
					this.isProjectManagerUser &&
					this.oProject.created_by_parent_id
				) {
					this._clientSubClientService
						.getPermissions(
							this.oProject.operational_id,
							this.oProject.created_by
						)
						.subscribe((data) => {
							if (!data.data.allow_managing_my_projects) {
								this._toasterService.pop(
									'error',
									"Sorry, you're not able to create projects."
								);
								this._router.navigate(['/projects/active']);
							}
						});
				}

				if (
					oProjectsData.project.concept_id ==
					this.projectConcepts?.marketplace
				) {
					if ('parent_action' in this.oQueryString) {
						if ('project_type_id' in oProjectsData['project']) {
							this.marketplaceCategory['type'] =
								oProjectsData['project']['project_type_id'];
							this.setMarketplaceSelectedType();
							this.bDisableType = true;
						}
						if ('project_title_id' in oProjectsData['project']) {
							this.marketplaceCategory['title'] =
								oProjectsData['project']['project_title_id'];
							this.setMarketplaceSelectedTitle();
							this.initMarketplaceQuestion(true);
							this.bDisableTitle = true;
						}
					}
				} else {
					if ('parent_action' in this.oQueryString) {
						if ('project_type_id' in oProjectsData['project']) {
							this.category['type'] =
								oProjectsData['project']['project_type_id'];
							this.setSelectedType();
							this.bDisableType = true;
						}
						if ('project_title_id' in oProjectsData['project']) {
							this.category['title'] =
								oProjectsData['project']['project_title_id'];
							this.setSelectedTitle();
							this.initQuestionDefs(true);
							this.bDisableTitle = true;
						}
					}
					this.getSuggestedTeamMembers(true);
					if (this.isProjectManagerUser) {
						this.getPendingCount();
						this.getEligibleActiveProject(this.oProject.created_by);
					}
				}
			},
			(err) => {}
		);
	}

	fillProjectQuestions(aData) {
		for (const oData of aData) {
			if (oData['project_question_id']) {
				this.oProjectQuestion[oData['project_question_id']] = oData;
			}
		}
	}

	fillQuestionAnswers() {
		this.fillProjectQuestions(
			this.oProjectsData['project_question_answers']
		);
		if (this.category['title']) {
			if (this.oDefs['questions'][this.category['title']]) {
				if (
					this.oDefs['questions'][this.category['title']].length > 0
				) {
					for (
						let k = 0;
						k <
						this.oDefs['questions'][this.category['title']].length;
						k++
					) {
						if (
							'custom_field' in
							this.oDefs['questions'][this.category['title']][k]
						) {
							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['custom_field'] == 'title'
							) {
								this.category['questionForms'][
									'steps'
								].controls['step' + (k + 1)].controls[
									'title'
								].setValue(
									this.oProjectsData['project']['title']
								);
								continue;
							} else if (
								this.oDefs['questions'][this.category['title']][
									k
								]['custom_field'] == 'bucket'
							) {
								continue;
							} else if (
								this.oDefs['questions'][this.category['title']][
									k
								]['custom_field'] == 'invite_designer'
							) {
								continue;
							}
						}
						if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 1 ||
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 6 ||
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 2 ||
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 3
						) {
							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_id'] in
								this.oProjectQuestion
							) {
								this.category['questionForms'][
									'steps'
								].controls['step' + (k + 1)].controls[
									'title'
								].setValue(
									this.oProjectQuestion[
										this.oDefs['questions'][
											this.category['title']
										][k]['project_question_id']
									]['answer']
								);
							}
						} else if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 4
						) {
							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_id'] in
								this.oProjectQuestion
							) {
								if (
									this.oProjectQuestion[
										this.oDefs['questions'][
											this.category['title']
										][k]['project_question_id']
									]['answer']
								) {
									const aCheckbox =
										this.oProjectQuestion[
											this.oDefs['questions'][
												this.category['title']
											][k]['project_question_id']
										]['answer'].split(',');
									this.category['questionForms'][
										'steps'
									].controls['step' + (k + 1)].controls[
										'title'
									].setValue(aCheckbox);
								}
							}
						} else if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 5
						) {
							if (
								this.oDefs['questions'][this.category['title']][
									k
								]['project_question_id'] in
								this.oProjectQuestion
							) {
								if (
									this.oProjectQuestion[
										this.oDefs['questions'][
											this.category['title']
										][k]['project_question_id']
									]['answer']
								) {
									const aCheckbox =
										this.oProjectQuestion[
											this.oDefs['questions'][
												this.category['title']
											][k]['project_question_id']
										]['answer'].split(',');
									let j = 0;
									for (const oCheckbox of this.category[
										'questionForms'
									]['steps'].controls['step' + (k + 1)].get(
										'title'
									).controls) {
										if (
											aCheckbox.indexOf(
												this.category['questionForms'][
													'steps'
												].controls['step' + (k + 1)]
													.get('title')
													.controls[j].get('name')
													.value
											) != -1
										) {
											this.category['questionForms'][
												'steps'
											].controls['step' + (k + 1)]
												.get('title')
												.controls[j].get('value')
												.setValue(true);
										}
										j++;
									}
								}
							}
						} else if (
							this.oDefs['questions'][this.category['title']][k][
								'project_question_type_id'
							] == 7
						) {
							this.oCopyBriefFiles['step' + (k + 1)] =
								this.oProjectQuestion[
									this.oDefs['questions'][
										this.category['title']
									][k]['project_question_id']
								]['document_files'];
						}
					}
				}
			}
		}
	}

	fillMarketplaceQuestionAnswers() {
		this.fillProjectQuestions(
			this.oProjectsData['project_question_answers']
		);
		if (this.marketplaceCategory['title']) {
			if (this.marketplaceQuestions) {
				if (this.marketplaceQuestions.length > 0) {
					for (let k = 0; k < this.marketplaceQuestions.length; k++) {
						if ('custom_field' in this.marketplaceQuestions[k]) {
							if (
								this.marketplaceQuestions[k]['custom_field'] ==
								'title'
							) {
								this.marketplaceCategory['questionForms'][
									'steps'
								].controls['step' + (k + 1)].controls[
									'title'
								].setValue(
									this.oProjectsData['project']['title']
								);
								continue;
							} else if (
								this.marketplaceQuestions[k]['custom_field'] ==
								'bucket'
							) {
								continue;
							} else if (
								this.marketplaceQuestions[k]['custom_field'] ==
								'invite_designer'
							) {
								continue;
							}
						}
						if (
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 1 ||
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 6 ||
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 2 ||
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 3
						) {
							if (
								this.marketplaceQuestions[k][
									'project_question_id'
								] in this.oProjectQuestion
							) {
								this.marketplaceCategory['questionForms'][
									'steps'
								].controls['step' + (k + 1)].controls[
									'title'
								].setValue(
									this.oProjectQuestion[
										this.marketplaceQuestions[k][
											'project_question_id'
										]
									]['answer']
								);
							}
						} else if (
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 4
						) {
							if (
								this.marketplaceQuestions[k][
									'project_question_id'
								] in this.oProjectQuestion
							) {
								if (
									this.oProjectQuestion[
										this.marketplaceQuestions[k][
											'project_question_id'
										]
									]['answer']
								) {
									const aCheckbox =
										this.oProjectQuestion[
											this.marketplaceQuestions[k][
												'project_question_id'
											]
										]['answer'].split(',');
									this.marketplaceCategory['questionForms'][
										'steps'
									].controls['step' + (k + 1)].controls[
										'title'
									].setValue(aCheckbox);
								}
							}
						} else if (
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 5
						) {
							if (
								this.marketplaceQuestions[k][
									'project_question_id'
								] in this.oProjectQuestion
							) {
								if (
									this.oProjectQuestion[
										this.marketplaceQuestions[k][
											'project_question_id'
										]
									]['answer']
								) {
									const aCheckbox =
										this.oProjectQuestion[
											this.marketplaceQuestions[k][
												'project_question_id'
											]
										]['answer'].split(',');
									let j = 0;
									for (const oCheckbox of this.marketplaceCategory[
										'questionForms'
									]['steps'].controls['step' + (k + 1)].get(
										'title'
									).controls) {
										if (
											aCheckbox.indexOf(
												this.marketplaceCategory[
													'questionForms'
												]['steps'].controls[
													'step' + (k + 1)
												]
													.get('title')
													.controls[j].get('name')
													.value
											) != -1
										) {
											this.marketplaceCategory[
												'questionForms'
											]['steps'].controls[
												'step' + (k + 1)
											]
												.get('title')
												.controls[j].get('value')
												.setValue(true);
										}
										j++;
									}
								}
							}
						} else if (
							this.marketplaceQuestions[k][
								'project_question_type_id'
							] == 7
						) {
							this.oCopyBriefFiles['step' + (k + 1)] =
								this.oProjectQuestion[
									this.marketplaceQuestions[k][
										'project_question_id'
									]
								]['document_files'];
						}
					}
				}
			}
		}
	}

	retrieveSharedBuckets(guid) {
		this._projectBucketService.getSharedBuckets(guid).subscribe((data) => {
			const aFillBucketData = [];
			for (const oData of data.data) {
				if (oData['bucket'])
					aFillBucketData.push(oData['bucket']['id']);
			}
			if (this.category['title'] || this.marketplaceCategory['title']) {
				if (
					this.oDefs['questions'][this.category['title']] ||
					this.oDefs['questions'][this.marketplaceCategory['title']]
				) {
					if (
						this.oDefs['questions'][this.category['title']]
							?.length > 0
					) {
						for (
							let k = 0;
							k <
							this.oDefs['questions'][this.category['title']]
								.length;
							k++
						) {
							if (
								'custom_field' in
								this.oDefs['questions'][this.category['title']][
									k
								]
							) {
								if (
									this.oDefs['questions'][
										this.category['title']
									][k]['custom_field'] == 'bucket'
								) {
									this.category['questionForms'][
										'steps'
									].controls['step' + (k + 1)].controls[
										'title'
									].setValue(aFillBucketData);
									return;
								}
							}
						}
					} else if (
						this.oDefs['questions'][
							this.marketplaceCategory['title']
						]?.length > 0
					) {
						for (
							let k = 0;
							k <
							this.oDefs['questions'][
								this.marketplaceCategory['title']
							].length;
							k++
						) {
							if (
								'custom_field' in
								this.oDefs['questions'][
									this.marketplaceCategory['title']
								][k]
							) {
								if (
									this.oDefs['questions'][
										this.marketplaceCategory['title']
									][k]['custom_field'] == 'bucket'
								) {
									this.marketplaceCategory['questionForms'][
										'steps'
									].controls['step' + (k + 1)]?.controls[
										'title'
									]?.setValue(aFillBucketData);
									return;
								}
							}
						}
					}
				}
			}
		});
	}

	fillValueToSingleArray(aData) {
		const aResult = [];
		for (const oData of aData) {
			aResult.push(oData['id']);
		}
		return aResult;
	}

	retrieveInvitedDesigners(guid) {
		this.retrieveTeamMembers().subscribe((data) => {
			this.aTeamMembers = data.data;
			this.oTmPagination['totalRecords'] = data.total_records;
			this.oTmPagination['itemsPerPage'] = data.items_per_page;

			this._projectBidService.getInvited(guid, {}).subscribe((data) => {
				const aFillInvitedData = this.fillValueToSingleArray(data.data);

				if (this.category['title']) {
					if (this.oDefs['questions'][this.category['title']]) {
						if (
							this.oDefs['questions'][this.category['title']]
								.length > 0
						) {
							for (
								let k = 0;
								k <
								this.oDefs['questions'][this.category['title']]
									.length;
								k++
							) {
								if (
									'custom_field' in
									this.oDefs['questions'][
										this.category['title']
									][k]
								) {
									if (
										this.oDefs['questions'][
											this.category['title']
										][k]['custom_field'] ==
										'invite_designer'
									) {
										this.category['questionForms'][
											'steps'
										].controls['step' + (k + 1)].controls[
											'title'
										].setValue(aFillInvitedData);
										return;
									}
								}
							}
						}
					}
				}
			});
		});
	}

	retrieveSubClients(id) {
		this._userSubClientService.index(id).subscribe((data) => {
			this.aSubClients = data.data;
		});
	}

	storeAssignedUser(project_id, oData) {
		this._projectAssignedUserService.store(project_id, oData).subscribe(
			(data) => {},
			(err) => {}
		);
	}

	initDemoSignupOverlay() {
		this._commonService.actionTriggered.emit({
			action: 'demo_signup_overlay',
		});
	}

	adminFilter(status) {
		this.statusForAdmin = status;
		this.category['type'] = '';
		this.category['title'] = '';
		this.retrieveDefs();
	}

	onClickInviteTeam() {
		if (this.teamInviteStatus == 1) {
			this.teamInviteStatus = null;
		} else {
			this.teamInviteStatus = 1;
		}
	}

	onClickExcludeTeam() {
		if (this.teamInviteStatus == 2) {
			this.teamInviteStatus = null;
		} else {
			this.teamInviteStatus = 2;
		}
	}

	getConfig(question: any) {
		Object.assign(this.config, {
			placeholder: question.question_placeholder,
		});
		return this.config;
	}

	getPendingCount() {
		const params = {};
		if (this.isProjectManagerUser) {
			params['user_id'] = this.oProject.created_by_parent_id
				? this.oProject.operational_id
				: this.oProject.created_by;
		}
		this._dashboardService
			.getPendingProjectsCount(params)
			.subscribe((res) => {
				this.pendingProjectsCount = res.count;
				this.pendingProjectsCountArr = Array(res.count + 1)
					.fill(0)
					.map((x, i) => i);
				this.sort_order = this.pendingProjectsCountArr.length;

				if (this.action == 'redo-project') {
					this.pendingProjectsCountArr.length = 1;
					this.sort_order = 1;
				}
			});
	}

	getEligibleActiveProject(userId) {
		this._userService.getEligibleActiveProject(userId).subscribe((res) => {
			this.isEligibleActive = res.data.is_eligible;
		});
	}

	onChangeSortOrder(event) {
		this.sort_order = event.target.value;
	}

	initHireDesignerModal(oDesignerUser, template: TemplateRef<any>) {
		this.modalHireDesignerRef = this._bsModalService.show(
			template,
			Object.assign({ modalClass: 'modal-dialog', class: '' })
		);
		this.oDesignerUser = oDesignerUser;
		this._analyticsService.setOverlayTrackData(
			17,
			60,
			this.oDesignerUser['id'],
			1
		);
	}

	storeProjectFromDraft(id, restData, params, aPayloadBucket?) {
		return new Promise((resolve, reject) => {
			this._projectService.startFromDraft(id, restData, params).subscribe(
				(data) => {
					let toastMessage;
					if (
						params['project_status_id'] !=
						this.projectStatus['draft']
					) {
						toastMessage =
							"Your project has been posted! Let's get things done...";
					} else {
						toastMessage =
							'Your project has been saved to drafts...';
					}
					this._toasterService.pop('success', toastMessage);
					data = data?.data ? data?.data : data;
					const project_status_id = data['project_status_id'];
					this.shareBuckets(data.project_id, aPayloadBucket);
					/*if (this.aExcludedTeamMembers && this.aExcludedTeamMembers.length > 0) {
                    this.assignExcludeDesigners(data.project_id, {ids: this.aExcludedTeamMembers});
                }*/
					if (this.teamInviteStatus) {
						this.assignInviteExcludeTeam(data.project_id);
					}
					/*if(this.assignedUsers.value && this.assignedUsers.value['ids'])
                {
                    if(this.assignedUsers.value['ids'].length > 0)
                    {
                        this.storeAssignedUser(data.project_id, this.assignedUsers.value);
                    }
                }*/
					resolve(data);
				},
				(err) => {
					this.bDisableBtn = false;
					reject();
				}
			);
		});
	}

	initArchiveProjectModal(template: TemplateRef<any>, project) {
		this.guid = project.project_id;
		this.oProject = project;
		this.modalArchiveProjectRef = this._bsModalService.show(
			template,
			Object.assign({
				modalClass: 'modal-dialog',
				class: 'modal-lg modal-full',
			})
		);
	}

	onSubmitArchiveProject() {
		const data = {};
		data['audit_trigger_id'] =
			this.projectAuditTriggers['archive-projects-plan-changed'];
		this._projectDetailService
			.storeArchive(this.guid, data)
			.subscribe((data) => {
				this._toasterService.pop('success', data.message);
				this.modalArchiveProjectRef.hide();
				this._router.navigate(['/projects']);
			});
	}

	initNotes(designer, template: TemplateRef<any>) {
		this.oDesignerUser = designer;
		this.modalNotesRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-lg modal-full' }
			)
		);
		this._analyticsService.setOverlayTrackData(
			17,
			92,
			this.oDesignerUser['id'],
			1
		);
	}

	initStats(designer, template: TemplateRef<any>) {
		this.oDesignerUser = designer;
		this.modalStatsRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-lg modal-full' }
			)
		);

		this._analyticsService.setOverlayTrackData(
			17,
			94,
			this.oDesignerUser['id'],
			1
		);
	}

	redirectProject(project_status_id, data) {
		if (
			project_status_id == this.projectStatus['assigned'] ||
			project_status_id == this.projectStatus['completed']
		) {
			this._router.navigate(['/projects/' + data.project_id]);
		} else if (
			project_status_id == this.projectStatus['pending'] ||
			this.isPaymentSucceeded
		) {
			this._router.navigate([
				'/projects/' +
					(data?.project_id
						? data.project_id
						: this.oProject?.project_id),
			]);
		} else if (project_status_id == this.projectStatus['draft']) {
			this._router.navigate(['/projects/drafts']);
		} else {
			this._router.navigate(['/projects']);
		}
	}

	retrieveUsers() {
		return new Promise<void>((resolve, reject) => {
			this._projectUserService
				.get(this.oProject.project_id)
				.subscribe((data) => {
					this.aProjectUsers = data.data;
					this.retrieveFeedbackQuestionsDefs();
					resolve();
				});
		});
	}

	retrieveFeedbackQuestionsDefs() {
		let data = {};
		this.getDefs(data).subscribe((data) => {
			this.aFeedbackQuestions = data.data['feedback_questions'];
			this.prepareFinalizeProject();
		});
	}

	getDefs(data?) {
		return this._projectFinalizeService.getDefs(
			this.oProject.project_id,
			data
		);
	}

	prepareDesignerRatings() {
		return this.aProjectUsers
			.filter((designer) => designer['user_type_name'] == 'Designer')
			.map((designer) => {
				return {
					id: designer['id'],
					name: designer['name'],
					rating: 0,
					feedback: '',
					removeFromTeam: true,
					removeFromTeamMainProject: false,
					reason: '',
					reasonMainProject: '',
					project_type_tags: [],
				};
			});
	}

	prepareFinalizeProject() {
		let desingerRatings = this.prepareDesignerRatings();

		let finalizeArray = {
			feedback_stars: [],
			feedback_message: '',
			designer_rating: desingerRatings,
			project_id: this.oProject.project_id,
			action: 'redo-project',
			started_project_id: this.startedProject['project_id'],
		};

		this.storeFinalize(finalizeArray);
		this.removeFromTeam(finalizeArray);
	}

	storeFinalize(oData) {
		this._projectFinalizeService
			.store(this.oProject.project_id, oData)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(
				(data) => {
					data['form'] = 'finalize';
				},
				(err) => {}
			);
	}

	removeFromTeam(oData) {
		if (oData['designer_rating']) {
			for (const oDesigner of oData['designer_rating']) {
				if (oDesigner['removeFromTeam'] == true) {
					const data = {
						project_id: this.oProject['project_id'],
						trigger_id: 5,
						comments: '',
						reason: oDesigner['reason'],
						project_title_id: this.oProject['project_title_id'],
					};

					this.destroyRemoveFromTeam(oDesigner, data);
				}
			}
		}
	}

	destroyRemoveFromTeam(oDesigner, data) {
		this._userClientService
			.destroyCreative(
				this.oProject['operational_id'],
				oDesigner['id'],
				data
			)
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe((data) => {
				this.redirectProject(data['project_status_id'], data);
			});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	addDesignersToExcluded() {
		this.designersWorked.map((designer, key) => {
			let index = this.aTeamMembers.findIndex(
				(obj) => obj.id == designer.user_id
			);
			if (index > -1) {
				this.aTeamMembers.splice(index, 1);
			}
		});
	}

	invitePlatinumTeamMembers() {
		const aTeams =
			this.category['questionForms']['steps']?.controls['step1']
				?.controls['title'].value;

		this.aTeamMembers.forEach((designer) => {
			if (
				(designer.creative_level_id ==
					this.creativeLevels['platinum'] ||
					designer.creative_level_id ==
						this.creativeLevels['platinum-lead']) &&
				designer?.is_default_invite &&
				!aTeams?.includes(designer.id)
			) {
				aTeams.push(designer.id);
				this.platinumInvitedDesigners.push(designer.id);
			}
		});
	}

	onProjectProceedSuccessful() {
		this.isPaymentSucceeded = true;
		this.redirectProject(
			this.currentProject['project_status_id'],
			this.currentProject
		);
	}

	onClosePaymentModel() {
		if (this.currentProject?.project_id || this.currentProject?.message) {
			this.openPaymentModel = true;
			this.isPaymentModalOpen = false;
			this.action = 'start-project';
		}
	}

	getPackageDetailTitle(details) {
		return details?.replace(/<[^>]+>/g, '');
	}

	fetchPackageInfo(packageInfo) {
		this.packageInfo = packageInfo;
		this.openPackageDetailedInfo(this.packageDetailedInfoTemp);
	}

	openPackageDetailedInfo(template) {
		this.modalDesignerProjectRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-xl modal-full' }
			)
		);
	}

	closePackageModal() {
		this.modalDesignerProjectRef.hide();
	}

	getExcludedDesigners() {
		this._projectService
			.getExcludedDesigners(this.oQueryString['parent_id'])
			.subscribe((data) => {
				let resData = data.data;
				this.aExcludedTeamMembers = resData.map((res) => res.id);
			});
	}
}
