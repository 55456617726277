<div class="stats-heading">
	Your Stats
</div>
<div
	class="container-fluid d-flex flex-row justify-content-between align-items-center p-0 flex-wrap">
	<div
		class="col-flocksy-sm-12 col-flocksy-md-custom col-flocksy-lg-custom p-0 mb-4">
		<div class="d-flex flex-column align-items-center border rounded w-100">
			<div class="mt-4 mb-4">
				<img
					src="assets/img/referral-link/tracking/ref-clicks-green.svg"
					alt="" />
			</div>
			<div
				class="stats-digits active-color">
				{{ stats?.visits_count || 00 }}
			</div>
			<div class="mb-4">Link Clicks</div>
		</div>
	</div>

	<div
		class="col-flocksy-sm-12 col-flocksy-md-custom col-flocksy-lg-custom p-0 mb-4">
		<div class="d-flex flex-column align-items-center border rounded w-100">
			<div class="mt-4 mb-4">
				<img
					src="assets/img/referral-link/tracking/ref-sign-ups-green.svg"
					alt="" />
			</div>
			<div
				class="stats-digits active-color">
				{{ stats?.subscriptions_count || 00 }}
			</div>
			<div class="mb-4">Sign Up</div>
		</div>
	</div>

	<div
		class="col-flocksy-sm-12 col-flocksy-md-custom col-flocksy-lg-custom p-0 mb-4">
		<div class="d-flex flex-column align-items-center border rounded w-100">
			<div class="mt-4 mb-4">
				<img
					src="assets/img/referral-link/tracking/ref-active-refs-green.svg"
					alt="" />
			</div>
			<div
				class="stats-digits active-color">
				{{ stats?.active_subscriptions_count || 00 }}
			</div>
			<div class="mb-4">Active Referrals</div>
		</div>
	</div>

	<div
		class="col-flocksy-sm-12 col-flocksy-md-custom col-flocksy-lg-custom p-0 mb-4">
		<div class="d-flex flex-column align-items-center border rounded w-100">
			<div class="mt-4 mb-4">
				<img
					src="assets/img/referral-link/tracking/ref-earnings-green.svg"
					alt="" />
			</div>
			<div
				class="stats-digits active-color">
				{{
					(stats?.total_earning / 100) || 0
						| currency: 'USD':'symbol':'1.0-0'
				}}
			</div>
			<div class="mb-4">Earnings Per Month</div>
		</div>
	</div>
</div>
