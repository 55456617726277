import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuService } from '@core/menu/menu.service';
import { SettingsService } from '@core/settings/settings.service';
import { ClientSubClientService } from '@services/client/client-subclient.service';
import { MessageService } from '@services/message/message.service';
import { ProjectPreferenceService } from '@services/project/project-preference.service';
import { LocalStorageService } from '@services/localstorage.service';
import { CommonService } from '@services/common/common.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { Subscription } from 'rxjs';
import { TimerSharedService } from '@services/time-entries/timer-shared.service';
import { SocketIoService } from '@services/socket.io.service';
import { WhiteLabelService } from '@services/white-label/white-label.service';

declare var $: any;

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
	router: Router;
	sbclickEvent = 'click.sidebar-toggle';
	$doc: any = null;
	aMenuItems: Array<any> = [];
	totalUnreadCount: number;
	totalCount: number;
	isRecentlyAddedTitle = false;
	unassigned_chats;
	introJS;
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();
	messageUnreadCountSubscription: Subscription;
	isPlatinumLead = this._localStorageService.isPlatinumLead();
	isProjectManagerasAM = this._localStorageService.isProjectManagerAsAM();
	isClientUser = this._localStorageService.isClientUser();
	isSubClientUser = this._localStorageService.isSubClientUser();
	socketEstablished;
	actionMessageTriggeredSubscription;
	isEligibleRealTime = false;
	currentUrl;
	previousUrl;
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();

	constructor(
		public _menuService: MenuService,
		public settings: SettingsService,
		public injector: Injector,
		public _localStorageService: LocalStorageService,
		private _clientSubClientService: ClientSubClientService,
		public _projectPreferenceService: ProjectPreferenceService,
		private _messageService: MessageService,
		public _analyticsService: AnalyticsService,
		public _timerSharedService: TimerSharedService,
		private _aRouter: Router,
		private _commonService: CommonService,
		public _socketIoService: SocketIoService,
		public _settings: SettingsService,
		private _whiteLabelService: WhiteLabelService,
	) {
		this.initInfo();
	}

	ngOnInit() {
		this.router = this.injector.get(Router);

		this.currentUrl = this.router.url;
		this.router.events.subscribe((val) => {
			// close any submenu opened when route changes
			this.removeFloatingNav();
			// scroll view to top
			window.scrollTo(0, 0);

			if (val instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = val.url;

				if (
					this.isEligibleRealTime &&
					this.previousUrl == '/messages'
				) {
					const excludeUrls = ['/messages'];
					if (
						!excludeUrls.includes(val.url) &&
						!this.isOperationalClientUser
					) {
						this.retrieveTotalUnreadCount();
					}
				}
			}
			// close sidebar on route change
			this._timerSharedService.isIntroJs$.subscribe((res) => {
				this.introJS = res;
				if (this.introJS == false) {
					this.settings.setLayoutSetting('asideToggled', false);
				}
			});
		});

		// enable sidebar autoclose from extenal clicks
		this.anyClickClose();
		this.initSubscribe();
		this.isEligibleRealTime = this.isEligibleRealTimeNotification();
		this.initRealTimeNotification();
	}

	initInfo() {
		this.aMenuItems = this._menuService.getMenu();

		if (this._localStorageService.isSubClientUser()) {
			this.retrievePermissions();
		}

		if (this._localStorageService.isProjectManagerUser()) {
			this.retrieveTotalUnreadCount();
		}

		if (
			this._localStorageService.isOperationalClientUser() ||
			this._localStorageService.isDesignerUser()
		) {
			this.retrieveTotalUnreadCount();
			this.retrieveProjectUnreadCount();
		}

		if (this._localStorageService.isDesignerUser()) {
			this.checkIsRecentlyAddedTitle();
		}
	}

	initSubscribe() {
		this._commonService.actionTriggered.subscribe({
			next: (data: any) => {
				if ('action' in data) {
					if (data['action'] == 'unread_chats') {
						this.retrieveTotalUnreadCount();
					} else if (data['action'] == 'unread_project_count') {
						this.retrieveProjectUnreadCount();
					}
				}
			},
		});

		this._commonService.unassignedChatsUpdated.subscribe((data) => {
			this.unassigned_chats = data['unassigned_chats'];
			this.setUnreadCount();
		});

		this.messageUnreadCountSubscription =
			this._commonService.messageChatsUpdated.subscribe((data) => {
				this.retrieveTotalUnreadCount();
			});
	}

	retrievePermissions() {
		this._clientSubClientService
			.getPermissions(
				this._localStorageService.getParentId(),
				this._localStorageService.getUserId()
			)
			.subscribe((data) => {
				let canManageOwnProjects =
					this._clientSubClientService.canManageOwnProjects(
						data.data.permissions
					);
				let canManageAllProjects =
					this._clientSubClientService.canManageAllProjects(
						data.data.permissions
					);
				let canViewManageOwnProjects =
					this._clientSubClientService.canViewManageOwnProjects(
						data.data.permissions
					);

				if (
					!canManageOwnProjects &&
					!canManageAllProjects &&
					!canViewManageOwnProjects
				) {
					console.log('new project');
					let i = 0;
					for (let oMenu of this.aMenuItems) {
						if ('data' in oMenu) {
							if ((oMenu['data']['id'] = 'project_post')) {
								this.aMenuItems.splice(i, 1);
							}
						}
						i++;
					}
				}
			});

		this._messageService.unreadCommentsCount().subscribe((data) => {
			this.totalCount = data.data.count;
			if (this.totalCount > 0) {
				this.setUnreadCommentsCount();
			}
		});
	}

	adButtonClick() {
		this._analyticsService.setOverlayTrackData(16, 98, null, null, {
			extra_details: window.location.href,
		});
	}

	retrieveTotalUnreadCount() {
		this._messageService.unreadTotalCount().subscribe((data) => {
			this.totalUnreadCount = data.data.count;
			this.setUnreadCount();
		});
	}

	clearunreadCount(event, text) {
		if (text == 'My projects') {
			for (let oMenu of this.aMenuItems) {
				if ('data' in oMenu && oMenu.text === 'My projects') {
					//oMenu["data"]["unread_comments"] = 0;
					this.retrieveProjectUnreadCount();
				}
			}
		}
	}

	checkIsRecentlyAddedTitle() {
		this._projectPreferenceService.checkTitleAdded().subscribe((data) => {
			if (data.count > 0) {
				this.isRecentlyAddedTitle = true;
				this.setUnreadCount();
			}
		});
	}

	setUnreadCommentsCount() {
		for (let oMenu of this.aMenuItems) {
			if ('data' in oMenu) {
				if (oMenu['data']['id'] == 'comment') {
					oMenu['data']['unread_comments'] = this.totalCount;
				}
			}
		}
	}

	setUnreadCount() {
		for (let oMenu of this.aMenuItems) {
			if ('data' in oMenu) {
				if (oMenu['data']['id'] == 'messages') {
					oMenu['data']['unread_count'] = this.totalUnreadCount;
				} else if (
					oMenu['data']['id'] == 'work_categories' &&
					this.isRecentlyAddedTitle
				) {
					if (!this._localStorageService.isDesignerUser()) {
						oMenu['data']['notify_text'] = 'New';
					}
				} else if (oMenu['data']['id'] == 'available_projects') {
					oMenu['data']['unread_count'] = this.unassigned_chats;
				}
			}
		}
	}

	anyClickClose() {
		this.$doc = $(document).on(this.sbclickEvent, (e) => {
			if (!$(e.target).parents('.aside-container').length) {
				if (this.introJS == false) {
					this.settings.setLayoutSetting('asideToggled', false);
				}
			}
		});
	}

	ngOnDestroy() {
		if (this.$doc) {
			this.$doc.off(this.sbclickEvent);
		}

		if (this.messageUnreadCountSubscription) {
			this.messageUnreadCountSubscription.unsubscribe();
		}
		if (this.actionMessageTriggeredSubscription) {
			this.actionMessageTriggeredSubscription.unsubscribe();
		}
	}

	toggleSubmenuClick(event) {
		event.preventDefault();

		if (
			!this.isSidebarCollapsed() &&
			!this.isSidebarCollapsedText() &&
			!this.isEnabledHover()
		) {
			let ul = $(event.currentTarget.nextElementSibling);

			// hide other submenus
			let parentNav = ul.parents('.sidebar-subnav');
			$('.sidebar-subnav').each((idx, el) => {
				let $el = $(el);
				// if element is not a parent or self ul
				if (el !== parentNav[0] && el !== ul[0]) {
					this.closeMenu($el);
				}
			});

			// abort if not UL to process
			if (!ul.length) {
				return;
			}

			// any child menu should start closed
			ul.find('.sidebar-subnav').each((idx, el) => {
				this.closeMenu($(el));
			});

			// toggle UL height
			const ulHeight = ul.css('height');
			if (ulHeight === 'auto' || parseInt(ulHeight, 10)) {
				this.closeMenu(ul);
			} else {
				// expand menu
				ul.on('transitionend', () => {
					ul.css('height', 'auto').off('transitionend');
				}).css('height', ul[0].scrollHeight);
				// add class to manage animation
				ul.addClass('opening');
			}
		}
	}

	// Close menu collapsing height
	closeMenu(elem) {
		elem.css('height', elem[0].scrollHeight); // set height
		elem.css('height', 0); // and move to zero to collapse
		elem.removeClass('opening');
	}

	toggleSubmenuHover(event) {
		let self = this;
		if (
			this.isSidebarCollapsed() ||
			this.isSidebarCollapsedText() ||
			this.isEnabledHover()
		) {
			event.preventDefault();

			this.removeFloatingNav();

			let ul = $(event.currentTarget.nextElementSibling);
			let anchor = $(event.currentTarget);

			if (!ul.length) {
				return; // if not submenu return
			}

			let $aside = $('.aside-container');
			let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
			let $sidebar = $asideInner.children('.sidebar');
			let mar =
				parseInt($asideInner.css('padding-top'), 0) +
				parseInt($aside.css('padding-top'), 0);
			let itemTop =
				anchor.parent().position().top + mar - $sidebar.scrollTop();

			let floatingNav = ul.clone().appendTo($aside);
			let vwHeight = document.body.clientHeight;

			// let itemTop = anchor.position().top || anchor.offset().top;

			floatingNav.addClass('nav-floating');

			// each item has ~40px height
			// multiply to force space for at least N items
			var safeOffsetValue = 40 * 5;
			var navHeight = floatingNav.height() + 2; // 2px border
			var safeOffset =
				navHeight < safeOffsetValue ? navHeight : safeOffsetValue;

			var displacement = 25;

			// if not enough space to show N items, use then calculated 'safeOffset'
			var menuTop =
				vwHeight - itemTop > safeOffset
					? itemTop
					: vwHeight - safeOffset - displacement;

			floatingNav
				.removeClass('opening') // necesary for demo if switched between normal//collapsed mode
				// .addClass('nav-floating')
				.css({
					position: this.settings.getLayoutSetting('isFixed')
						? 'fixed'
						: 'absolute',
					top: menuTop,
					bottom:
						floatingNav.outerHeight(true) + menuTop > vwHeight
							? displacement + 'px'
							: 'auto',
				});

			floatingNav
				.on('mouseleave', () => {
					floatingNav.remove();
				})
				.find('a')
				.on('click', function (e) {
					e.preventDefault(); // prevents page reload on click
					// get the exact route path to navigate
					let routeTo = $(this).attr('route');
					if (routeTo) {
						self.router.navigate([routeTo]);
					}
				});

			this.listenForExternalClicks();
		}
	}

	listenForExternalClicks() {
		let $doc = $(document).on('click.sidebar', (e) => {
			if (!$(e.target).parents('.aside-container').length) {
				this.removeFloatingNav();
				$doc.off('click.sidebar');
			}
		});
	}

	removeFloatingNav() {
		$('.nav-floating').remove();
	}

	isSidebarCollapsed() {
		return this.settings.getLayoutSetting('isCollapsed');
	}

	isSidebarCollapsedText() {
		return this.settings.getLayoutSetting('isCollapsedText');
	}

	isEnabledHover() {
		return this.settings.getLayoutSetting('asideHover');
	}

	retrieveProjectUnreadCount() {
		if (
			this._localStorageService.isClientUser() ||
			this._localStorageService.isSubClientUser()
		) {
			this._messageService.unreadCommentsCount().subscribe((data) => {
				this.totalCount = data.data.count;
				this.setUnreadCommentsCount();
			});
		}
	}

	initRealTimeNotification() {
		if (this.isEligibleRealTime) {
			this.initSocket();
		}
	}

	isEligibleRealTimeNotification() {
		if (
			this._localStorageService.isProjectManagerUser() ||
			this._localStorageService.isOperationalClientUser()
		) {
			return true;
		}

		return false;
	}

	getRoomName() {
		return 'user.' + this._localStorageService.getUserId();
	}

	initSocket() {
		this._socketIoService.connect();
		this._socketIoService.joinRoom(this.getRoomName());
		this.socketEstablished = true;

		this._socketIoService.subscribeToUser((type, data) => {
			this.retrieveTotalUnreadCount();
		});
	}
}
