<!-- START Top Navbar-->
<nav
	class="navbar topnavbar"
	role="navigation"
	*ngIf="!isClientUser && !isSubClientUser">

	<!-- START navbar header-->
	<div
		class="navbar-header d-flex align-items-center"
		[ngStyle]="{ 'padding-left': onlyLogo === true ? '5px' : '2rem' }">
		<a
			(click)="_analyticsService.setRouteData(16, 34); redirectUser()"
			class="navbar-brand text-white">
			<div class="logo-class">
				<img
					class="block-center icon-size"
					style="width: 100px"
					[src]="isWhiteLabelledUser ? (whiteLabelledUser?.logo_document?.full_path ? whiteLabelledUser?.logo_document?.full_path: defaultLogo) : 'assets/img/new-flocksy-header-icon.svg'"
					alt="Logo" />
			</div>
			<div class="brand-logo-collapsed logo-class">
				<img
					class="block-center icon-size"
					style="width: 100px"
					[src]="isWhiteLabelledUser ? (whiteLabelledUser?.logo_document?.full_path ? whiteLabelledUser?.logo_document?.full_path: defaultLogo) : 'assets/img/new-flocksy-header-icon.svg'"
					alt="Logo" />
			</div>
		</a>
		<div
			class="desktop-view"
			*ngIf="
				(isPlatinumLevel ||
					isProjectManagerUser ||
					isProjectManagerAsAM) &&
				!mobile &&
				!isLoginPage
			">
			<app-earning-timer [desktop_view]="true"></app-earning-timer>
		</div>
	</div>
	<!-- END navbar header-->
	<!-- START Left navbar-->
	<ul class="navbar-nav mr-auto flex-row">
		<li class="nav-item">
			<!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
			<a
				(click)="
					settings.toggleLayoutSetting('asideToggled');
					$event.stopPropagation()
				"
				[ngStyle]="{ display: onlyLogo === true ? 'none' : 'block' }"
				class="nav-link sidebar-toggle d-md-none text-dark">
				<em class="fas fa-bars"></em>
			</a>
		</li>
	</ul>
	<!-- END Left navbar-->
	<!-- START Right Navbar-->
	<ul
		class="navbar-nav flex-row"
		[ngStyle]="{ visibility: onlyLogo === true ? 'hidden' : 'show' }">
		<!-- START Alert menu-->
		<li
			*ngIf="isClientUser || isSubClientUser"
			class="nav-item d-flex align-items-center">
			<a
				href="javascript:void(0);"
				class="btn btn-labeled btn-primary d-none d-sm-block"
				type="button"
				(click)="initFreeMonthReferralModal(freeMonthReferralModal)">
				<!-- *ngIf="isClientUser" -->
				<span class="btn-label">
					<i class="fa fa-gift"></i>
				</span>
				Earn a FREE Month
			</a>
			<!-- <a (click)="initFreeMonthReferralModal(freeMonthReferralModal)"
               class="nav-link text-dark d-block d-sm-none px-0" href="javascript:">
               <em class="fa fa-gift mr-2"></em> Earn a Free Month!
            </a> -->
		</li>
		<li class="nav-item d-flex align-items-center">
			<a
				href="javascript:void(0);"
				class="refer-button"
				type="button"
				(click)="initFreeMonthReferralModal(freeMonthReferralModal)">
				<img src="assets/img/referral-link/refer-button.svg" alt="refer">
			</a>
		</li>
		<li
			class="nav-item feedback d-flex align-items-center"
			*ngIf="isFeedbackeryEnabled">
			<a
				#feedbackBtnDesktop
				id="header-feedback-btn"
				class="nav-link text-dark text-lg"
				href="javascript:void(0)">
				<span class="d-none d-sm-block">
					<em class="fas fa-bullhorn mr-1"></em>
					Give Feedback
				</span>
				<span class="d-block d-sm-none">
					<em class="fas fa-bullhorn mr-1"></em>
				</span>
			</a>
		</li>
		<li
			class="nav-item feedback d-flex align-items-center"
			*ngIf="isDesignerUser">
			<a
				class="nav-link text-primary text-lg"
				href="javascript:"
				(click)="initReportConcernModal(reportConcernModal)">
				<span tooltip="Report a concern">
					<em class="fa-eye far"></em>
				</span>
			</a>
		</li>
		<li
			class="nav-item dropdown notification-dropdown dropdown-list"
			dropdown
			#dropdown="bs-dropdown"
			[insideClick]="true">
			<a
				class="nav-link dropdown-toggle dropdown-toggle-nocaret clickable"
				dropdownToggle
				(click)="seenAllNotifications()">
				<em class="fas fa-bell text-dark"></em>
				<span
					class="badge badge-danger"
					*ngIf="
						unreadNotificationCount > 0 &&
						unreadNotificationCount <= 9
					">
					{{ unreadNotificationCount }}
				</span>
				<span
					class="badge badge-danger"
					*ngIf="unreadNotificationCount > 9">
					9+
				</span>
			</a>
			<div *dropdownMenu class="dropdown-menu dropdown-menu-right">
				<div class="dropdown-item">
					<div class="list-group">
						<div
							class="list-group-item b0 border-0 pt-3"
							*ngIf="notificationLoaded">
							<span
								class="d-flex align-items-center float-right"
								*ngIf="notifications?.length">
								<u>
									<a
										href="javascript:void(0);"
										(click)="clearAllNotifications()">
										Clear all notifications
									</a>
								</u>
							</span>
						</div>
						<div
							class="overflow-auto"
							[ngClass]="
								!notificationLoaded
									? 'notification-height'
									: 'notificaion-max-height'
							">
							<div
								class="list-group-item border-0"
								*ngIf="notifications?.length">
								<div
									class="media pb-2 pt-1 list-group-item-action"
									*ngFor="let item of notifications">
									<div>
										<img
											class="align-self-start mx-2 circle thumb32"
											[src]="
												item?.created_by_user
													?.profile_picture?.full_path
											"
											onError="this.src='../../../../assets/img/user-placeholder.png';"
											alt="Image" />
									</div>
									<div class="media-body">
										<span
											class="mb-1 text-sm text-wrap"
											[ngSwitch]="
												item?.notification_type_id
											">
											<span
												*ngSwitchCase="1"
												class="clickable">
												<em
													class="d-inline-flex text-primary align-middle  {{
														notificationTypes[
															item
																?.notification_type_id
														]['icon']
													}} mr-2"></em>
												<a
													class="text-dark"
													(click)="
														markAsRead(
															item?.id,
															item,
															$event
														)
													"
													(click)="
														dropdown.isOpen =
															!dropdown.isOpen
													">
													<span>
														{{
															item?.plain_content
														}}
													</span>
												</a>
											</span>
											<span
												*ngSwitchCase="2"
												class="clickable">
												<em
													class="d-inline-flex text-primary align-middle  {{
														notificationTypes[
															item
																?.notification_type_id
														]['icon']
													}} mr-2"></em>
												<a
													class="text-dark"
													(click)="
														markAsRead(
															item?.id,
															item,
															$event
														)
													">
													<span>
														{{
															item?.plain_content
														}}
													</span>
												</a>
											</span>
											<span
												*ngSwitchCase="3"
												class="clickable">
												<em
													class="d-inline-flex text-primary align-middle  {{
														notificationTypes[
															item
																?.notification_type_id
														]['icon']
													}} mr-2"></em>
												<a
													class="text-dark"
													(click)="
														markAsRead(
															item?.id,
															item,
															$event
														)
													"
													(click)="
														dropdown.isOpen =
															!dropdown.isOpen
													">
													<span>
														{{
															item?.plain_content
														}}
													</span>
												</a>
											</span>

											<span
												*ngSwitchCase="4"
												class="clickable">
												<em
													class="d-inline-flex text-primary align-middle  {{
														notificationTypes[
															item
																?.notification_type_id
														]['icon']
													}} mr-2"></em>
												<a
													class="text-dark"
													(click)="
														markAsRead(
															item?.id,
															item,
															$event
														)
													"
													(click)="
														dropdown.isOpen =
															!dropdown.isOpen
													">
													<span>
														{{
															item?.plain_content
														}}
													</span>
												</a>
											</span>
											<span
												*ngSwitchCase="5"
												class="clickable">
												<em
													class="d-inline-flex text-primary align-middle  {{
														notificationTypes[
															item
																?.notification_type_id
														]['icon']
													}} mr-2"></em>
												<a
													class="text-dark"
													(click)="
														markAsRead(
															item?.id,
															item,
															$event
														)
													"
													(click)="
														dropdown.isOpen =
															!dropdown.isOpen
													">
													<span>
														{{
															item?.plain_content
														}}
													</span>
												</a>
											</span>
											<p>
												<small class="text-muted">
													{{
														_localStorageService.formatDate(
															item?.created_at,
															'MMM DD, YYYY h:mm A'
														)
													}}
												</small>
											</p>
											<p
												class="notificaion-comment"
												*ngIf="
													item.data.message ||
													item.data.text
												">
												<span
													*ngIf="item?.data?.message"
													[innerHTML]="
														item?.data?.message
															| newline
															| innerhtml
															| linkifyHtml
													"></span>
												<span
													*ngIf="item?.data?.text"
													[innerHTML]="
														item?.data?.text
															| newline
															| innerhtml
															| linkifyHtml
													"></span>
											</p>
										</span>
									</div>
									<div class="mr-2 ml-4 align-self-center">
										<em
											class="text-muted fas fa-times clickable"
											(click)="
												markAsRead(
													item?.id,
													item,
													$event,
													true
												)
											"></em>
									</div>
								</div>
							</div>
							<div class="preloader" *ngIf="!notificationLoaded">
								<div class="preloader-progress">
									<div class="preloader-progress-bar"></div>
								</div>
							</div>
						</div>
						<div
							class="list-group-item text-center border-0"
							*ngIf="
								notifications?.length <= 0 && notificationLoaded
							">
							<div class="media pb-1 pt-2 pl-5 pr-5">
								<div class="media-body">
									<em
										class="fas fa-check fa-3x text-primary pb-3"></em>
									<p class="text-sm">
										You've read all your notifications
									</p>
								</div>
							</div>
						</div>
						<div
							class="list-group-item b0"
							*ngIf="notificationLoaded">
							<span class="d-flex align-items-center float-right">
								<u>
									<a
										href="javascript:void(0);"
										(click)="
											initSeeAllNotifications(
												seeAllNotificationsModal
											)
										">
										See all notifications
									</a>
								</u>
							</span>
						</div>
					</div>
				</div>
			</div>
		</li>
		<li class="nav-item dropdown dropdown-list" dropdown id="profileicon">
			<a
				class="nav-link dropdown-toggle text-dark"
				dropdownToggle
				href="javascript:">
				<img
					[src]="profileImageUrl"
					class="img-fluid header-image rounded-circle thumb24 mr-2"
					onError="this.src='../../../../assets/img/user-placeholder.png';" />
				<span class="d-none d-lg-inline text-bold">
					{{ _localStorageService.getUserFullName() }}
				</span>
			</a>
			<!-- START Dropdown menu-->
			<div *dropdownMenu class="dropdown-menu dropdown-menu-right shadow">
				<div class="dropdown-item">
					<!-- START list group-->
					<div class="list-group">
						<div class="list-group-item list-group-item-action">
							<a
								(click)="_analyticsService.setRouteData(28, 79)"
								[routerLink]="'/profile'"
								style="color: inherit">
								<span class="d-flex align-items-center">
									Settings
								</span>
							</a>
						</div>
						<div
							*ngIf="isDesignerUser"
							class="list-group-item list-group-item-action">
							<a
								(click)="
									_analyticsService.setRouteData(37, 102)
								"
								[routerLink]="'/profile/share'"
								style="color: inherit">
								<span class="d-flex align-items-center">
									Share My Profile
								</span>
							</a>
						</div>
						<div
							*ngIf="isDesignerUser"
							class="list-group-item list-group-item-action">
							<a
								[routerLink]="'/projects/preferences'"
								style="color: inherit">
								<span
									(click)="
										_analyticsService.setRouteData(34, 95)
									"
									class="d-flex align-items-center">
									Project Filters
								</span>
							</a>
						</div>
						<div class="list-group-item list-group-item-action">
							<a
								(click)="_analyticsService.setRouteData(29, 82)"
								[routerLink]="'/change-password'"
								style="color: inherit">
								<span class="d-flex align-items-center">
									Change Password
								</span>
							</a>
						</div>
						<div
							*ngIf="isAdminUser || isProjectManagerUser"
							class="list-group-item list-group-item-action">
							<a
								(click)="
									_analyticsService.setRouteData(41, 111)
								"
								[routerLink]="'/emails/send'"
								style="color: inherit">
								<span class="d-flex align-items-center">
									Send Email
								</span>
							</a>
						</div>
						<div
							*ngIf="
								isClientUser ||
								isDesignerUser ||
								isProjectManagerUser ||
								isProjectManagerAsAM
							"
							class="list-group-item list-group-item-action">
							<a
								*ngIf="isClientUser"
								(click)="_analyticsService.setRouteData(31, 88)"
								[routerLink]="'/billing'"
								style="color: inherit">
								<span
									class="d-flex align-items-center">
									Receipts
								</span>
							</a>
							<a
								*ngIf="
									isDesignerUser ||
									isProjectManagerUser ||
									isProjectManagerAsAM
								"
								(click)="_analyticsService.setRouteData(31, 88)"
								[routerLink]="'/finance'"
								style="color: inherit">
								<span
									class="d-flex align-items-center">
									Earnings
								</span>
							</a>
						</div>

						<div
							*ngIf="
								!isOperationalClientUser &&
								(isPlatinumLead ||
									isPlatinumLevel ||
									isProjectManagerAsAM ||
									isProjectManagerUser ||
									isAdminUser)
							"
							class="list-group-item list-group-item-action">
							<a
								[routerLink]="'/payment/time-tracker'"
								class="color-black">
								<span class="d-flex align-items-center">
									Timer Tracker
								</span>
							</a>
						</div>
						<div
							*ngIf="_localStorageService.isClientUser()"
							class="list-group-item list-group-item-action">
							<a
								(click)="
									_analyticsService.setRouteData(78, 196)
								"
								[routerLink]="'/finance-and-billing'"
								style="color: inherit">
								<span
									*ngIf="isClientUser"
									class="d-flex align-items-center">
									Upgrading & Billing
								</span>
							</a>
						</div>
						<div
							*ngIf="isDesignerUser"
							class="list-group-item list-group-item-action">
							<a
								href="{{ paypalUrlPrefix }}"
								target="_blank"
								class=" "
								style="color: inherit">
								<span class="d-flex align-items-center">
									Paypal Email
								</span>
							</a>
						</div>
						<!-- last list item-->
						<div class="list-group-item list-group-item-action">
							<a [routerLink]="'/logout'" style="color: inherit">
								<span class="d-flex align-items-center">
									Logout
								</span>
							</a>
						</div>
					</div>
					<!-- END list group-->
				</div>
			</div>
			<!-- END Dropdown menu-->
		</li>
		<!-- END Alert menu-->
	</ul>
	<!-- END Right Navbar-->
	<div
		class="mobile-view w-100"
		*ngIf="
			(isPlatinumLevel || isProjectManagerUser || isProjectManagerAsAM) &&
			mobile &&
			!isLoginPage
		">
		<app-earning-timer [desktop_view]="false"></app-earning-timer>
	</div>
</nav>

<ng-container *ngIf="isClientUser || isSubClientUser">
	<app-client-header [onlyLogo]="onlyLogo"></app-client-header>
</ng-container>

<!-- END Top Navbar-->
<!-- <ng-template #freeMonthReferralModal>
	<div class="modal-header border-0 px-4 py-0">
		<div
			class="row mx-0 justify-content-between w-100 align-items-center padding-top-2">
			<div class="font-24 line-height-27">Earn a Free Month</div>
			<div class="header-desc-text line-height-16 header-text-margin-top">
				Have a bunch of people to refer? Join our
				<a href="https://flocksy.com/affiliates/" class="link-text">
					Affiliate Program
				</a>
				and earn an average of $2,730/month
			</div>
		</div>
		<div class="pt-4 ml-2">
			<button
				(click)="modalFreeMonthReferralRef.hide()"
				aria-label="Close"
				class="close"
				type="button">
				<img src="../../../assets/img/close-light.svg" alt="" />
			</button>
		</div>
	</div>
	<div class="modal-body px-4 padding-bottom-4 pt-0">
		<div
			class="font-14 line-height-16 header-text-margin-top text-muted header-text-mob">
			Have a bunch of people to refer? Join our
			<a href="https://flocksy.com/affiliates/" class="link-text">
				Affiliate Program
			</a>
			and earn an average of $2,730/month
		</div>
		<hr class="mt-4 margin-bottom-2" />
		<div class="">
			<div>
				<div class="font-14 mb-4 line-height-16">
					Share the link below and earn a free month for each
					new user that signs up. Each user that signs up with your
					link will receive $100 off the first month of any plan.
				</div>
			</div>
			<div>
				<div class="input-group mb-4">
					<input
						[value]="urlShare"
						class="form-control text-muted line-height-16 font-14"
						type="text" />
					<div
						(click)="copyToClipboard()"
						class="input-group-append clickable"
						tooltip="Copy to Clipboard">
						<span class="input-group-text">
							<img
								src="../../../assets/img/copy-icon.svg"
								alt="" />
						</span>
					</div>
				</div>
			</div>
			<div class="font-12 text-muted line-height-16">
				Credit is only given for new users who sign up and pay. Credit
				not applied if the user received a refund or does a chargeback.
				If the new user's plan is priced lower than your plan, you'll be
				credited up to the user's plan price.
			</div>
		</div>
	</div>
</ng-template> -->

<ng-template #reportConcernModal>
	<app-report-concern
		(emitCloseModal)="modalReportConcernRef.hide()"></app-report-concern>
</ng-template>

<ng-template #seeAllNotificationsModal>
	<app-notifications-detailview
		(emitCloseModal)="
			modalSeeAllNotificationsRef.hide()
		"></app-notifications-detailview>
</ng-template>

<ng-template #freeMonthReferralModal>
	<div class="modal-header border-0 px-4 py-0">
		<div class="row mx-0 justify-content-between w-100 align-items-center padding-top-2">
			<div *ngIf="!isEmailSent" class="font-24 line-height-27">
				Tell people about Flocksy and earn cash!
			</div>
			<div *ngIf="isEmailSent" class="font-24 line-height-27">
				Success!
			</div>
		</div>
		<div class="pt-4 ml-2">
			<button
				(click)="modalFreeMonthReferralRef.hide()"
				aria-label="Close"
				class="close"
				type="button">
				<img src="assets/img/close-light.svg" alt="">
			</button>
		</div>
	</div>
	<div class="modal-body px-4 padding-bottom-4 pt-0">
		<hr class="mt-4 mb-4">
		<div *ngIf="!isEmailSent">
			<div class="mb-5">
				<app-paypal-email></app-paypal-email>
			</div>
			<div>
				<app-share-tabs></app-share-tabs>
			</div>
			<hr class="mb-4 mt-0">
			<div>
				<app-stats-tracking></app-stats-tracking>
			</div>
		</div>
		<div *ngIf="isEmailSent">
			<app-email-success></app-email-success>
		</div>
	</div>
</ng-template>
