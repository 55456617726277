import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { LocalStorageService } from '@services/localstorage.service';
import { SettingsService } from '@core/settings/settings.service';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@services/common/api.service';
import { SignupService } from '@services/signup/signup.service';
import { LoginService } from '@services/login.service';
import { environment } from '@env/environment';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { ExternalScriptService } from '@services/common/external-script.service';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { WhiteLabelService } from '@services/white-label/white-label.service';

declare var grecaptcha: any;

@Component({
	selector: 'app-app-signup',
	templateUrl: './app-signup.component.html',
	styleUrls: ['./app-signup.component.scss'],
})
export class AppSignupComponent implements OnInit, OnDestroy {
	valForm: FormGroup;
	passwordForm: FormGroup;
	oError: object = {};
	aCountries = [];
	aStates = [];
	bDisabledButton = false;
	@ViewChild('profilePicFile', { static: true }) profilePicFile: ElementRef;
	bShowProgressBar = false;
	progressBarPercentage: any;
	formSubmitted = false;
	leadSourceNames = FlocksyUtil.getLeadSourceNames();
	isOtherSelected = false;
	otherLeadSource = '';
	referralCode = '';
	utmSource = '';

	constructor(
		public _settingsService: SettingsService,
		public _formBuilder: FormBuilder,
		public _toasterService: ToasterService,
		public _localStorageService: LocalStorageService,
		private _router: Router,
		public _apiService: ApiService,
		public _signupService: SignupService,
		public _loginService: LoginService,
		public _analyticsService: AnalyticsService,
		public _externalScriptService: ExternalScriptService,
		private _activatedRoute: ActivatedRoute,
		public _whiteLabelService: WhiteLabelService,
	) {
		if(_whiteLabelService.isWhiteLabelledUser()){} {
			this._router.navigate(['/login']);
		}
		this.initForm();
	}

	ngOnInit() {
		this.checkUserLoggedIn();
		this.getCountryList();
		this.reloadGoogleRecaptchaScript();
		this.setReferralCodeViaQueryString();
		this.setUtmSourceViaQueryString();
	}

	ngOnDestroy() {
		this.destroyGoogleRecaptcha();
	}

	reloadGoogleRecaptchaScript() {
		this._externalScriptService
			.loadScript(
				'https://www.google.com/recaptcha/api.js?render=' +
					environment.reCaptchaSiteKey,
				'flocksy_recaptcha'
			)
			.then((data) => {})
			.catch((error) => console.log(error));
	}

	assignRecaptchaToken(token) {
		this.valForm.patchValue({ recaptcha_token: token });
	}

	removeVisibilityGoogleRecaptcha() {
		const elements = document.getElementsByClassName('grecaptcha-badge');
		while (elements.length > 0) {
			elements[0].parentNode.removeChild(elements[0]);
		}
	}

	destroyGoogleRecaptcha() {
		this.removeVisibilityGoogleRecaptcha();
		this._externalScriptService.removeScript('flocksy_recaptcha');
	}

	initForm() {
		let password = new FormControl(
			'',
			Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(16),
			])
		);
		let certainPassword = new FormControl('', [Validators.required]);

		this.passwordForm = this._formBuilder.group(
			{
				password: password,
				confirm_password: certainPassword,
			},
			{
				validator: FlocksyValidator.equalTo(
					'password',
					'confirm_password'
				),
			}
		);

		this.valForm = this._formBuilder.group({
			name: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.notUrl,
					FlocksyValidator.notEmail,
				]),
			],
			phone: [null],
			country: [null, Validators.required],
			state: [null, Validators.required],
			lead_source: [null, Validators.required],
			about_yourself: [null],
			headline: [null],
			payment_email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			file1: [null, Validators.required],
			passwordGroup: this.passwordForm,
			agree_terms: [null, Validators.required],
			recaptcha_token: [null],
		});
	}

	onLeadSourceChange() {
		if (this.valForm.value.lead_source == 'Other') {
			this.isOtherSelected = true;
		} else {
			this.isOtherSelected = false;
		}
	}

	checkUserLoggedIn() {
		if (this._localStorageService.isUserLoggedIn()) {
			this.redirectUser();
		}
	}

	getCountryList() {
		this._apiService.getCountryList().subscribe(
			(data) => {
				this.aCountries = data.data;
			},
			(err) => {}
		);
	}

	redirectUser() {
		if (this._localStorageService.isClientUser()) {
			if (this._localStorageService.getClientPlanID() != 0) {
				this._router.navigate(['/dashboard']);
			} else {
				this._router.navigate(['/plans']);
			}
		} else if (this._localStorageService.isDesignerUser()) {
			if (this.formSubmitted) {
				this._router.navigate(['/onboarding/overview']);
			} else {
				this._router.navigate(['/dashboard']);
			}
		} else if (this._localStorageService.isProjectManagerUser()) {
			this._router.navigate(['/projects/active']);
		} else if (this._localStorageService.isAdminUser()) {
			this._router.navigate(['/projects/active']);
		} else {
			this._router.navigate(['/login']);
		}
	}

	setReferralCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('r' in params) {
				this.referralCode = params['r'];
				this._localStorageService.setReferralCode(btoa(params['r']));
				this._localStorageService.setReferralAppliedDate(new Date());
			}
		});
	}

	setUtmSourceViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('utm_source' in params) {
				this.utmSource = params['utm_source'];
				this._localStorageService.setUtmSource(btoa(params['utm_source']));
			}
		});
	}

	submitForm($ev, value: any) {
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}
		for (let c in this.passwordForm.controls) {
			this.passwordForm.controls[c].markAsTouched();
		}

		if (
			!this.valForm.valid ||
			(this.isOtherSelected && this.otherLeadSource == '')
		) {
			return false;
		}
		this.bDisabledButton = true;
		this.generateTokenGoogleRecaptcha();
	}

	storeSubmit() {
		Object.assign(this.valForm.value, this.valForm.value.passwordGroup);
		this.valForm.value['user_type_id'] = 2;

		if (this.valForm.value.lead_source == 'Other') {
			this.valForm.value.lead_source = 'Other: ' + this.otherLeadSource;
		}
		Object.assign(this.valForm.value, {
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: null,
		});

		this._signupService.registerAsDesigner(this.valForm.value).subscribe(
			(data) => {
				if (data) {
					this.bShowProgressBar = true;
					this.progressBarPercentage = data.progress;
					if (data.data) {
						this.bShowProgressBar = false;
						this.formSubmitted = true;
						this._toasterService.pop('success', data.message);
						const oParam = this.valForm.value;
						this.autoLoginAfterSignup(
							oParam['email'],
							oParam['password']
						);
						this.resetprofilePicFileValue();
						this.valForm.reset();
					}
				}
			},
			(err) => {
				this.bDisabledButton = false;
				this.oError = err;
			}
		);
	}

	generateTokenGoogleRecaptcha() {
		const thisObject = this;
		grecaptcha.ready(function () {
			grecaptcha
				.execute(environment.reCaptchaSiteKey, {
					action: 'appSignupPage',
				})
				.then(function (token) {
					thisObject.assignRecaptchaToken(token);
					thisObject.storeSubmit();
				});
		});
	}

	autoLoginAfterSignup(email, password) {
		var auth_data = {
			grant_type: 'password',
			username: email,
			password: password,
			client_id: environment.ApiClientId,
			client_secret: environment.ApiClientSecret,
			designer_signup: 1,
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: null,
		};

		this._loginService.oAuthToken(JSON.stringify(auth_data)).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	getAuthUser() {
		this._apiService.getAuthUser({ tracker_flagged: 1 }).subscribe(
			(data) => {
				this._localStorageService.setCurrentUser(data.data);
				this.redirectUser();
			},
			(err) => {}
		);
	}

	onNavigateAfterSignup() {
		window.location.href =
			'https://flocksy.com/team-member-applications-designer';
	}

	onSelectFile($event) {
		if (this.isImageFile($event.target.files[0])) {
			this.valForm.controls['file1'].setValue($event.target.files[0]);
		} else {
			this._toasterService.pop(
				'error',
				"Oops, that doesn't look like an image. Please check what you selected and try again."
			);
			this.resetprofilePicFileValue();
		}
	}

	resetprofilePicFileValue() {
		this.profilePicFile.nativeElement.value = '';
		this.valForm.controls['file1'].setValue('');
	}

	isImageFile(file) {
		if (file) {
			if (file.type) {
				var mimeType = file.type;
				if (mimeType.match(/image\/*/) == null) return false;

				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	onChangeCountry(event) {
		if (this.valForm.value['country']) {
			this.getStateList(this.valForm.value['country']);
		} else {
			this.aStates = [];
		}
	}

	getStateList(country_id) {
		if (!country_id) {
			return;
		}

		this._apiService.getStateList(country_id).subscribe(
			(data) => {
				this.aStates = data.data;
			},
			(err) => {}
		);
	}
}
