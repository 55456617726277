import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReferralService } from '../../services/referral.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-share-tabs',
  templateUrl: './share-tabs.component.html',
  styleUrls: ['./share-tabs.component.scss']
})
export class ShareTabsComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  shareMethod: string;

  constructor(private _referralService: ReferralService) { }

  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;

  ngOnInit(): void {

  }

  setShareMethod(method: string) {
    this._referralService.setMethod(method);
  }

  ngAfterViewInit() {
    this._referralService.shareMethod$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        method => {
          this.shareMethod = method;
          this.onSelectTab(this.shareMethod);
        })
  }
  onSelectTab(method: string): void {
    if (method === 'social-media') {

    } else {
      if (this.staticTabs?.tabs[1]) {
        this.staticTabs.tabs[1].active = true; // Open the second tab
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
