import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-project-loader',
	templateUrl: './project-loader.component.html',
	styleUrls: ['./project-loader.component.scss'],
})
export class ProjectLoaderComponent implements OnInit {
	@Input() isSkeletonShown = false;

	constructor() {}

	ngOnInit(): void {}
}
