import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../../common/constant/ApiConstants';
import { CustomHttpService } from '../../custom.http.service';
@Injectable()
export class CreativeNotesService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(clientId, creativeId, oParams): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${
				API_CONSTANTS.CREATIVES_URL
			}/${creativeId}/clients/${clientId}/notes${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	update(noteId, oData): Observable<any> {
		return this._customHttpService.put(
			`${API_CONSTANTS.CREATIVE_CLIENT_NOTES_URL}/${noteId}`,
			oData
		);
	}

	store(creativeId, clientId, oData) {
		return this._customHttpService.post(
			`${API_CONSTANTS.CREATIVES_URL}/${creativeId}/clients/${clientId}/notes`,
			oData
		);
	}

	delete(noteId: string, oParams?) {
		const params = new URLSearchParams();
		for (const key in oParams) {
			if (oParams[key]) {
				params.set(key, oParams[key]);
			}
		}

		return this._customHttpService.delete(
			`${API_CONSTANTS.CREATIVE_CLIENT_NOTES_URL}/${noteId}${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}
}
