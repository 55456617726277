<div *ngIf="oDesignerForClient">
	<div class="row mb-2 border-bottom pb-4">
		<div class="col-12 p-0">
			Hire this team member to directly work with them in a dedicated
			Slack channel. This team member will commit up to 2 hours per day
			for the work requested.
		</div>
	</div>

	<!-- Cover and Profile Image -->
	<div class="" *ngIf="showCoverImage">
		<div class="">
			<div class="card">
				<div class="half-float ie-fix-flex">
					<img class="img-fluid" src="assets/img/bg3.jpg" alt="" />
					<div class="half-float-bottom">
						<img
							class="img-thumbnail circle thumb128"
							[src]="profilePath"
							onError="this.src='../../../../assets/img/user-placeholder.png';" />
					</div>
				</div>
				<div
					class="card-body text-center"
					*ngIf="oDesignerForClient['profile']">
					<h3 class="m-0">
						{{ oDesignerForClient['profile']['name'] }}
						<span class="badge badge-success">
							{{
								_localStorageService.getCreativeLevelName(
									oDesignerForClient['profile']
								)
							}}
						</span>
					</h3>
				</div>
			</div>
		</div>
	</div>

	<!-- Features -->
	<div class="row mb-3 mt-4">
		<div class="col pl-0">
			<div class="h4 text-bold">Features</div>
			<div class="mb-3">
				<div>
					<div *ngFor="let feature of features" class="mb-2">
						<em
							class="fas fa-chevron-circle-right text-primary mr-1"
							style="font-size: 16px"></em>
						<span [innerHtml]="feature"></span>
					</div>
					<div class="mb-2">
						<em
							class="fas fa-chevron-circle-right text-primary mr-1"
							style="font-size: 16px"></em>
						<span>We offer the following plans:</span>
					</div>
					<div *ngFor="let plan of plans" class="mb-2 ml-5">
						<em
							class="fas fa-chevron-circle-right text-primary mr-1"
							style="font-size: 16px"></em>
						<span [innerHtml]="plan"></span>
					</div>
				</div>
			</div>
			<div class="mt-3">
				<span class="text-bold mr-1">Have questions?</span>
				<a
					href="javascript:"
					(click)="onContactSupport()"
					class="text-underline">
					Chat with us.
				</a>
			</div>
		</div>
	</div>

	<!-- Actions -->
	<div class="row" *ngIf="oDesignerForClient['profile']">
		<div class="col text-center">
			<!-- <div *ngIf="isAvailableForHire">

                <button class="btn btn-primary mb-3" *ngIf="!isHired && !isAvailableForWaitlist" (click)="hireOrJoinWaitlist(1)" [disabled]="aDisabledBtn">
                    Hire {{ oDesignerForClient['profile']['name'] }}
                </button>

                <button class="btn btn-primary mb-3 hire-disabled-btn" *ngIf="isHired" disabled>
                    <span class="clickable" [tooltip]="hiredTooltip">
                        Hire {{ oDesignerForClient['profile']['name'] }}
                    </span>
                </button>

                <ng-template #hiredTooltip>
                    <span style="opacity: 1;">
                        You've already requested to hire this team member.
                    </span>
                </ng-template>
            </div>

            <div *ngIf="!isHired && isAvailableForWaitlist" class="d-flex justify-content-center">
                <button class="btn btn-primary mb-3" [disabled]="isDesignerWaitListJoined || aDisabledBtn" (click)="hireOrJoinWaitlist(2)">
                    Join Waitlist
                </button>
                <em class="ml-1 fas fa-info-circle text-primary clickable"
                    style="font-size: 16px"
                    [tooltip]="isDesignerWaitListJoined ? 'You\'ve already requested to be added to the waitlist for this team member.' : 'This team member\'s queue is currently full but join the waitlist to be notified when they\'re available again.'">
                </em>
            </div>

            <div class="p text-muted" *ngIf="isHired || !isAvailableForWaitlist">
                Your card won't be charged yet.
                <em class="ml-1 fas fa-info-circle text-primary clickable" tooltip="We'll email you back with the email you have on file and confirm the purchase. Once everything has been confirmed, we'll charge the payment method on file and add you to Slack."
                    style="font-size: 16px">
                </em>
            </div> -->

			<button
				class="btn btn-primary mb-3"
				(click)="hireOrJoinWaitlist(3)"
				[disabled]="aDisabledBtn">
				Request more info
			</button>

			<em
				class="fa fa-info-circle clickable align-top text-primary ml-1"
				[tooltip]="aDisabledBtn ? aDisabledBtnTooltip : hireMeTooltip"
				style="font-size: 14px"></em>
			<ng-template #hireMeTooltip>
				<div>
					These's no commitment to learn more about this.
					<br />
					We'll be in touch as soon as possible to answer all your
					questions.
				</div>
			</ng-template>
			<ng-template #aDisabledBtnTooltip>
				<div>
					You've already requested more info about hiring this team
					member.
				</div>
			</ng-template>
		</div>
	</div>
</div>
