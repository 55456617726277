import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { WhiteLabelService } from '@services/white-label/white-label.service';

@Component({
	selector: 'app-error500',
	templateUrl: './error500.component.html',
	styleUrls: ['./error500.component.scss'],
})
export class Error500Component implements OnInit {
	isWhiteLabelUser = this._whiteLabelService.isWhiteLabelledUser();

	constructor(
		public settings: SettingsService,
		public _whiteLabelService: WhiteLabelService
	) {}

	ngOnInit() {}
}
