import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ProjectDetailService {
	constructor(private _customHttpService: CustomHttpService) {}

	get(id, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/projects/' +
				id +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getStats(id, page, limit, sort = 1): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				id +
				'/stats/clients?page=' +
				page +
				'&limit=' +
				limit +
				'&sort=' +
				sort
		);
	}

	getTimerHistory(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/turnaround-times/history'
		);
	}

	updateProjectStatus(id, oData: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/update/projects/' +
				id +
				'/status',
			oData
		);
	}

	storeArchive(id, data?: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/archive',
			data
		);
	}

	moveProjectToPending(id, data): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/pending',
			data
		);
	}

	updatePmGeneralNote(oData: object): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/user_information',
			oData
		);
	}

	genralNotePmNote(id, oData: object): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/pms/' + id + '/notes',
			oData
		);
	}

	updateCreativeGeneralNote(
		oData: object,
		creativeId,
		clientId
	): Observable<any> {
		return this._customHttpService.put(
			`${API_CONSTANTS.CREATIVES_URL}/${creativeId}/clients/${clientId}/notes`,
			oData
		);
	}

	getCreativeGeneralNote(creativeId, clientId, page): Observable<any> {
		return this._customHttpService.get(
			`${API_CONSTANTS.CREATIVES_URL}/${creativeId}/clients/${clientId}/notes?page=${page}`
		);
	}

	getPmGenralNote(id, oParams) {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/pms/' +
				id +
				'/notes' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	getPmProjectNote(id, oParams) {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.PROJECTS_URL}/${id}/audits?filter[pm_note]=pm_note`
		);
	}

	getCreativeInfo(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/users/' +
				id +
				'/stats?include[average_rating]=1&include[completed_project_count]=1&include[worked_with_client_count]=1'
		);
	}

	getCopied(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/copied'
		);
	}
}
