import { io } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './localstorage.service';

@Injectable({
	providedIn: 'root',
})
export class SocketIoService {
	socket;

	constructor(public _localStorageService: LocalStorageService) {}

	connect() {
		if (!environment.socket.connection) {
			return false;
		}
		this.socket = io(environment.chatEndPoint);
		this.connected();
	}

	isConnected() {
		if (this.socket) {
			return true;
		}

		return false;
	}

	getSocket() {
		return this.socket;
	}

	connected() {
		if (this.socket) {
			this.socket.emit('connected', {
				id: this._localStorageService.getUserId(),
			});
		}
	}

	disconnect() {
		if (this.socket) {
			this.socket.disconnect();
		}
	}

	/**
	 * @param roomName
	 */
	joinRoom(roomName) {
		if (this.socket) {
			this.socket.emit('join', roomName);
		}
	}

	/**
	 * @param roomName
	 */
	leaveRoom(roomName) {
		if (this.socket) {
			this.socket.emit('leave', roomName);
		}
	}

	/**
	 * @param eventName
	 * @param listener
	 */
	offEvent(eventName, listener) {
		if (listener) {
			listener.off(eventName);
		}
	}

	/**
	 * @param cb
	 */
	subscribeToMessages = (cb) => {
		if (!this.socket) {
			return true;
		}

		return this.socket.on('message', (message) => {
			return cb('message', message);
		});
	};

	/**
	 * @param cb
	 */
	subscribeToUser = (cb) => {
		if (!this.socket) {
			return true;
		}

		return this.socket.on('user', (message) => {
			return cb('user', message);
		});
	};

	/**
	 * @param cb
	 */
	subscribeToMessageListview = (cb) => {
		if (!this.socket) {
			return true;
		}

		return this.socket.on('user.pages.messages', (message) => {
			return cb('user.pages.messages', message);
		});
	};

	/**
	 * @param cb
	 */
	subscribeToProjectChats = (cb) => {
		if (!this.socket) {
			return true;
		}

		return this.socket.on('project.chats', (message) => {
			return cb('project.chats', message);
		});
	};

	/**
	 * @param cb
	 */
	subscribeToHeaderNotifications = (cb) => {
		if (!this.socket) {
			return true;
		}

		return this.socket.on('user.header.notifications', (message) => {
			return cb('user.header.notifications', message);
		});
	};

	/**
	 * @param message
	 * @param roomName
	 * @param cb
	 */
	sendMessage = ({ message, roomName }, cb) => {
		if (this.socket) {
			this.socket.emit('message', { message, roomName }, cb);
			if ('received_by' in message) {
				const userRoomName = 'user.' + message['received_by'];
				this.socket.emit('user', { roomName: userRoomName }, cb);
				const userListviewRoomName =
					'user.messages.' + message['received_by'] + '.listview';
				this.socket.emit(
					'user.pages.messages',
					{ message: message, roomName: userListviewRoomName },
					cb
				);
			}
		}
	};

	/**
	 * @param chat
	 * @param roomName
	 * @param cb
	 */
	sendProjectChat = ({ chat, roomName }, cb) => {
		if (this.socket) {
			this.socket.emit('project.chats', { chat, roomName }, cb);
			if ('notification_recipient_ids' in chat) {
				for (const id of chat.notification_recipient_ids) {
					let notifyRoomName = 'user.' + id + '.header.notifications';
					this.socket.emit(
						'user.header.notifications',
						{ roomName: notifyRoomName },
						cb
					);
				}
			}
		}
	};

	handleAutomatedBotMessage(roomName, message) {
		this.connect();
		this.sendMessage({ message, roomName: roomName }, (cb) => {
			this.disconnect();
		});
	}
}
