<div
	class="package-detail-wrapper"
	[ngClass]="
		activeModuleType == 'projectBrief' ? 'project-brief-wrapper' : ''
	">
	<div class="modal-header mx-3 px-0 d-flex justify-content-between">
		<h4
			class="modal-title text-primary ml-1"
			*ngIf="!isOperationalClientUser">
			{{ packageInfo?.title }}
		</h4>
		<div class="modal-title ml-1" *ngIf="isOperationalClientUser">
			{{ packageInfo?.title }}
		</div>
		<button
			*ngIf="activeModuleType != 'projectBrief'"
			type="button"
			class="close clickable"
			aria-label="Close"
			(click)="closePackageModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="mt-4 mx-3">
		<div class="row">
			<div
				class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 package-image-container">
				<div class="package-image-content-wrapper">
					<div
						class="package-image-wrapper"
						[ngStyle]="{
							'background-image':
								'url(' +
								packageInfo?.document_file?.full_path +
								')'
						}">
						<img
							class="package-image-content w-100"
							src="../../../{{ packageBGImage }}"
							alt="" />
					</div>
				</div>
			</div>
			<div
				class="col-xl-8 col-lg-6 col-md-6 col-sm-6 col-xs-12 rating-wrapper">
				<div
					class="row package-rating-wrapper mt-4 mt-lg-0 mt-md-4 mt-sm-4">
					<div class="col-lg-7 col-md-6 col-sm-12 col-xs-12 mb-2">
						<div
							class="d-flex align-items-center package-star-rating font-weight-bold mr-2 start-icon-wrapper">
							<app-rating-icon></app-rating-icon>
							<span class="profile-rating mt-1 mx-1">
								{{
									packageInfo?.created_by_user?.average_rating
										| number: '1.1-1'
								}}
							</span>
						</div>
						<div>
							<span>
								{{ packageInfo?.project_type_name }} -
								{{ packageInfo?.project_title_name }}
							</span>
						</div>
					</div>
					<div class="col-lg-5 col-md-6 col-sm-12 col-xs-12 d-flex">
						<div class="avtar-wrapper mr-2">
							<img
								class="img-fluid header-image rounded-circle thumb32 mr-2"
								src="{{
									packageInfo?.created_by_user
										?.profile_picture?.full_path
								}}"
								alt="" />
						</div>
						<div class="">
							<div>
								<span class="text-primary">
									{{ packageInfo?.created_by_user?.name }}
								</span>
							</div>
							<div
								class="d-flex align-items-center package-star-rating font-weight-bold mr-1"
								*ngIf="activeModuleType != 'projectBrief'">
								<app-rating-icon></app-rating-icon>
								<span class="profile-rating mt-1 mx-1">
									{{
										packageInfo?.created_by_user
											.average_rating | number: '1.1-1'
									}}
								</span>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div
					class="d-flex justify-content-between align-items-baseline flex-column">
					<div class="d-flex align-items-baseline">
						<h3
							[ngClass]="
								packageInfo?.discounted_total != null &&
								packageInfo?.discounted_total !== 0 &&
								!isDesignerUser
									? 'package-original-price-wrapper'
									: ''
							">
							${{
								(isDesignerUser
									? +packageInfo['price']
									: +packageInfo['total'] || 0
								) | number: '1.0-2'
							}}
						</h3>
						<h3
							*ngIf="
								packageInfo?.discounted_total != null &&
								packageInfo?.discounted_total !== 0 &&
								!isDesignerUser
							"
							class="discounted-price-wrapper text-primary">
							${{
								packageInfo['discounted_total'] || 0
									| number: '1.0-2'
							}}
						</h3>
					</div>
					<div
						class="discount-code-wrapper"
						*ngIf="
							packageInfo?.discounted_total != null &&
							packageInfo?.discounted_total !== 0 &&
							!isDesignerUser
						">
						<div class="discount-content-container">
							<img
								src="../../../../{{ discountCouponBgImage }}"
								alt="" />
							<div class="discount-content-text-wrapper font-11">
								<span>
									{{ packageInfo?.discount | number: '1.0' }}%
									DISCOUNT
								</span>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div>
					<span [innerHTML]="packageInfo?.details"></span>
				</div>
				<div
					class="select-btn-wrapper d-flex justify-content-end"
					*ngIf="activeModuleType != 'projectBrief'">
					<button
						class="btn btn-lg my-4 px-5 select-btn"
						(click)="selectPackage(packageInfo)"
						[ngClass]="
							selectedPackage == packageInfo
								? 'btn-warning'
								: 'btn-primary'
						">
						{{
							selectedPackage == packageInfo
								? 'Selected'
								: 'Select'
						}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
