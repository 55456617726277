import { Injectable } from '@angular/core';
import { CustomHttpService } from '../custom.http.service';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TimeEntriesService {
	constructor(private _customHttpService: CustomHttpService) {}

	store(oData?, oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.post(
			API_CONSTANTS.TIMER_ENTRIES_URL +
				(params.toString() ? '?' + params.toString() : ''),
			oData
		);
	}

	update(entryId, oData, oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.put(
			API_CONSTANTS.TIMER_ENTRIES_URL +
				'/' +
				entryId +
				(params.toString() ? '?' + params.toString() : ''),
			oData
		);
	}

	index(userId, oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/' +
				userId +
				'/time-entries' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	timerEnteriesUsers(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.TIMER_ENTRIES_URL + '/users'
		);
	}
}
