import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '@common/constant/ApiConstants';
import { LocalStorageService } from '../localstorage.service';
import {
	ANALYTICS_PAGES,
	IS_ANALYTICS,
} from '@common/constant/AnalyticsPageIds';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import { environment } from '@env/environment';

@Injectable()
export class AnalyticsService {
	public routeDataObj: any;
	guid;
	public trackRelatedId: any;
	public trackPreviousEventId: any;

	constructor(
		private _customHttpService: CustomHttpService,
		private _localStorageService: LocalStorageService,
		private _route: ActivatedRoute,
		private _router: Router
	) {}

	initAnalytics() {
		if (!IS_ANALYTICS) {
			return false;
		}

		this._router.events
			.filter((event) => event instanceof NavigationEnd)
			.map(() => {
				let child = this._route.firstChild;
				while (child) {
					if (child.firstChild) {
						child = child.firstChild;
					} else if (child.snapshot.data && child.snapshot.data) {
						return {
							data: child.snapshot.data,
							params: child.snapshot.params,
						};
					} else {
						return null;
					}
				}
				return null;
			})
			.subscribe((eventData: any) => {
				const routeData = eventData.data;
				if (routeData.page_placement_id) {
					if (!routeData.page_placement_id.isTrack) {
						return false;
					}
				}
				const paramsData = eventData.params;
				const routeStateData = this.getRouteData();
				if (routeStateData) {
					const queryParams: any = this._route.queryParams;
					if (routeData.related_module_id) {
						routeStateData.related_module_id =
							routeData.related_module_id;
					}
					this.store(routeStateData, queryParams, paramsData);
				} else {
					if (
						Object.keys(routeData).length != 0 &&
						routeData.page_placement_id
					) {
						const queryParams: any = this._route.queryParams;
						this.store(routeData, queryParams, paramsData);
					}
				}
			});
	}

	store(routeData, queryParams, paramsData) {
		const data = this.prepareParams(routeData, queryParams, paramsData);
		this.trackRelatedId = null;
		this.postTrackData(this.getPostParams(data)).subscribe(
			(res) => {
				this.setRouteData(null, null);
				if (res.data) {
					this.trackRelatedId = res.data.id;
				}
				this.trackPreviousEventId = null;
			},
			(err) => {
				console.log('AnalyticsService :: store :: err :: ', err);
				this.setRouteData(null, null);
			}
		);
	}

	prepareParams(routeData, queryParams, paramsData) {
		let param = {};
		const prevPage = this._localStorageService.getItem('prevPageId');
		this._localStorageService.setItem(
			'prevPageId',
			routeData.page_placement_id ? routeData.page_placement_id.id : ''
		);
		if (
			routeData.page_placement_id &&
			routeData.page_placement_id.isTrack &&
			IS_ANALYTICS
		) {
			param = {
				routeData: routeData,
				prevPageId:
					prevPage === routeData.page_placement_id.id
						? undefined
						: prevPage,
			};

			if (queryParams) {
				param['queryParams'] = queryParams._value;
			}

			if (paramsData) {
				param['paramsData'] = paramsData;
			}
		}
		return param;
	}

	// params logic prepare post object
	getPostParams(val) {
		const prevPageId = val.prevPageId;
		const queryParams = val.queryParams;
		const ret = {
			ta_state_changed: true,
		};
		if (val.routeData && val.routeData.page_placement_id) {
			ret['ta_page_placement_id'] = val.routeData.page_placement_id.id;
			if (val.routeData.related_module_id && val.paramsData) {
				ret['related_module'] = val.routeData.related_module_id;
				ret['related_id'] = +val.paramsData.id;
			}
		}

		if (prevPageId) {
			ret['ta_previous_page_placement_id'] = prevPageId;
		}

		if (queryParams && queryParams['r']) {
			ret['ta_referral_code'] = queryParams['r'];
		}

		if (queryParams && queryParams['discount_code']) {
			ret['ta_coupon_code'] = queryParams['discount_code'];
		}
		if (queryParams && queryParams['via-inbox'] == '') {
			ret['source'] = 1;
		}

		if (this._localStorageService.getUserId()) {
			ret['user_id'] = this._localStorageService.getUserId();
		}

		if (this._localStorageService.getUserFullName()) {
			ret['name'] = this._localStorageService.getUserFullName();
		}

		if (this._localStorageService.getUserEmail()) {
			ret['email'] = this._localStorageService.getUserEmail();
		}

		if (this._localStorageService.getUserType()) {
			const userTypeId = this._localStorageService.getUserTypeID();
			ret['user_type_id'] = userTypeId;
			if (userTypeId === 1 || userTypeId === 5) {
				ret['plan_id'] = this._localStorageService.getClientPlanID();
			}
		}
		return ret;
	}

	// api call
	postTrackData(oData): Observable<any> {
		return this._customHttpService.post(API_CONSTANTS.ANALYTICS_URL, oData);
	}

	// api call
	postOverlayTrackData(oData, id): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.ANALYTICS_URL + '/' + id,
			oData
		);
	}

	postDocumentsTrackData(oData): Observable<any> {
		return this._customHttpService.post(
			environment.analyticsEndPoint + '/tracks/documents',
			oData
		);
	}

	updateDocumentsTrackData(id, oData): Observable<any> {
		return this._customHttpService.put(
			environment.analyticsEndPoint + '/tracks/documents/' + id,
			oData
		);
	}

	// get on click data of tracking.
	getRouteData() {
		return this.routeDataObj;
	}

	setRouteData(pageId, placementId) {
		if (
			pageId &&
			placementId &&
			ANALYTICS_PAGES[pageId].placements[placementId]
		) {
			this.routeDataObj = {
				page_placement_id:
					ANALYTICS_PAGES[pageId].placements[placementId],
			};
		} else if (!pageId && !placementId) {
			this.routeDataObj = null;
		}
	}

	public setOverlayTrackData(
		pageId,
		eventId,
		relatedId: any = null,
		relatedModuleId: any = null,
		otherDetails: any = null
	) {
		if (!IS_ANALYTICS) {
			return false;
		}

		let overlayTrackData = null;
		if (pageId && eventId && ANALYTICS_PAGES[pageId].events) {
			if (
				ANALYTICS_PAGES[pageId]?.events[eventId].isTrack &&
				this.trackRelatedId
			) {
				overlayTrackData = {
					ta_event_id: ANALYTICS_PAGES[pageId].events[eventId].id,
					ta_previous_event_id: this.trackPreviousEventId,
				};
				this.trackPreviousEventId =
					ANALYTICS_PAGES[pageId].events[eventId].id;
				if (relatedModuleId) {
					overlayTrackData['related_module'] = relatedModuleId;
				}

				if (relatedId) {
					overlayTrackData['related_id'] = relatedId;
				}
				if (otherDetails) {
					if ('event_name' in otherDetails) {
						overlayTrackData['event_name'] =
							otherDetails['event_name'];
					}
					if ('extra_details' in otherDetails) {
						overlayTrackData['extra_details'] =
							otherDetails['extra_details'];
					}
				}
				this.storeOverlayTrackData(overlayTrackData);
			}
		}
	}

	storeOverlayTrackData(paramsData) {
		this.postOverlayTrackData(paramsData, this.trackRelatedId).subscribe(
			(res) => {},
			(err) => {
				console.log(
					'AnalyticsService :: storeOverlayTrackData :: err :: ',
					err
				);
			}
		);
	}

	storeDocumentTrackData(
		document_category_id,
		ta_page_placement_id,
		time_duration,
		files
	) {
		let filesAnalytics = [];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			filesAnalytics.push({
				filename: file.name,
				file_mime_type: file.type,
				file_size: file.size,
				file_hash: file.file_hash,
			});
		}

		const data = {
			user_id: this._localStorageService.getUserId(),
			plan_id: this._localStorageService.getClientPlanID(),
			user_type_id: this._localStorageService.getUserTypeID(),
			name: this._localStorageService.getUserFullName(),
			email: this._localStorageService.getUserEmail(),
			files: filesAnalytics,
			document_category_id: document_category_id,
			ta_page_placement_id: ta_page_placement_id,
			time_duration: time_duration,
		};

		return this.postDocumentsTrackData(data);
	}
}
