import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex';
import { FileUploadModule } from 'ng2-file-upload';

import { SharedModule } from '@shared/shared.module';
import { DesignerProfileModule } from '@shared/module/designer-profile/designer-profile.module';

import { TeamService } from '@services/team/team.service';
import { DocumentService } from '@services/document/document.service';
import { ProjectBucketService } from '@services/project/project-bucket.service';
import { ProjectDetailService } from '@services/project/project-detail.service';
import { ProjectBidService } from '@services/project/project-bid.service';
import { ProjectAssignedUserService } from '@services/project/project-assigned-user.service';
import { ProjectExcludeDesignersService } from '@services/project/project-exclude-designers.service';
import { UserSubClientService } from '@services/user/user-subclient.service';
import { ClientSubClientService } from '@services/client/client-subclient.service';
import { ObjectToIterablePipeModule } from '@shared/pipes/object-to-iterable/object-to-iterable.pipe.module';
import { ANALYTICS_PAGES } from '@common/constant/AnalyticsPageIds';
import { ProjectInviteDesignersService } from '@services/project/project-invite-designers.service';
import { NgxSummernoteModule } from 'ngx-summernote';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { S3DocumentService } from '@services/document/s3-document.service';
import { HireDesignerModule } from '@shared/module/hire-designer/hire-designer.module';
import { NotesModule } from '@shared/module/clients/creatives/notes/notes.module';
import { ProjectUserService } from '@services/project/project-user.service';
import { ProjectFinalizeService } from '@services/project/project-finalize.service';
import { UserClientService } from '@services/user/user-client.service';
import { PaymentsModule } from '../payments/payments.module';
import { MarketplaceModule } from '../../marketplace/marketplace.module';
import { OldEditviewComponent } from './old-editview/old-editview.component';
import { AiAssistComponent } from './ai-assist/ai-assist.component';
import { BucketDetailComponent } from './bucket-detail/bucket-detail.component';
import { SubUserInfoComponent } from './sub-user-info/sub-user-info.component';
import { ProjectLoaderComponent } from './project-loader/project-loader.component';
import { ProjectsEditviewComponent } from './editview/projects-editview.component';
import { UnsavedChangesGuard } from '../../../guards/deactivate.guard';
import { UserInviteService } from '@services/user/user-invite.service';
import { FileUploaderComponent } from './file-uploder/file-uploader.component';
import { CustomCarouselComponent } from './custom-carousel/custom-carousel.component';

const routes: Routes = [
	{
		path: 'old',
		component: OldEditviewComponent,
		data: {
			title: 'Create New Project',
			page_placement_id: ANALYTICS_PAGES[17].placements[35],
		},
	},
	{
		path: '',
		component: ProjectsEditviewComponent,
		canDeactivate: [UnsavedChangesGuard],
		data: {
			title: 'Create New Project',
			page_placement_id: ANALYTICS_PAGES[17].placements[35],
		},
	},
];

@NgModule({
	imports: [
		SharedModule,
		RouterModule.forChild(routes),
		NgxSelectModule,
		ObjectToIterablePipeModule,
		DesignerProfileModule,
		FileUploadModule,
		NgxSummernoteModule,
		HireDesignerModule,
		NotesModule,
		PaymentsModule,
		MarketplaceModule,
	],
	declarations: [
		ProjectsEditviewComponent,
		OldEditviewComponent,
		ProjectsEditviewComponent,
		AiAssistComponent,
		BucketDetailComponent,
		SubUserInfoComponent,
		ProjectLoaderComponent,
		CustomCarouselComponent
	],
	exports: [RouterModule, ProjectsEditviewComponent, FileUploaderComponent, ProjectLoaderComponent],
	providers: [
		TeamService,
		DocumentService,
		ProjectBucketService,
		ProjectDetailService,
		ProjectBidService,
		ProjectAssignedUserService,
		UserSubClientService,
		ClientSubClientService,
		ProjectInviteDesignersService,
		ProjectExcludeDesignersService,
		DashboardService,
		S3DocumentService,
		ProjectUserService,
		ProjectFinalizeService,
		UserClientService,
		UnsavedChangesGuard,
		UserInviteService,
	],
})
export class ProjectsEditviewModule {}
