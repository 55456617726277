import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../services/localstorage.service';

@Injectable()
export class MenuService {
	aMenuItems: Array<any>;

	constructor(public _localStorageService: LocalStorageService) {
		this.aMenuItems = [];
	}

	getMenu() {
		if (this._localStorageService.isClientUser()) {
			this.getClientMenu();
		} else if (this._localStorageService.isSubClientUser()) {
			this.getSubClientMenu();
		} else if (
			this._localStorageService.isDesignerUser() &&
			!this._localStorageService.isPlatinumLead()
		) {
			this.getDesignerMenu();
		} else if (
			this._localStorageService.isDesignerUser() &&
			this._localStorageService.isPlatinumLead()
		) {
			this.getPlatinumLeadMenu();
		} else if (
			this._localStorageService.isProjectManagerUser() &&
			!this._localStorageService.isProjectManagerAsAM()
		) {
			this.getProjectManagerMenu();
		} else if (this._localStorageService.isProjectManagerAsAM()) {
			this.getProjectManagerAsAMMenu();
		} else if (this._localStorageService.isAdminUser()) {
			this.getAdminMenu();
		}
		return this.aMenuItems;
	}

	getClientMenu() {
		this.aMenuItems = [
			{
				url: 'account-activity',
				text: 'Account Activity',
				icon: 'assets/img/activity-feed-page/account-activity-logo.svg',
				pagePlacementId: [82, 198],
				id: 'account-activity',
			},
			{
				url: 'dashboard',
				text: 'Dashboard',
				icon: 'far fa-paper-plane',
				pagePlacementId: [16, 32],
				id: 'A1',
			},
			{
				url: 'projects/create',
				text: 'New Project',
				icon: 'far fa-edit',
				pagePlacementId: [17, 36],
				id: 'A2',
			},
			{
				url: 'projects/queue',
				text: 'Projects',
				icon: 'far fa-file',
				pagePlacementId: [17, 36],
				id: 'A3',
				data: {
					id: 'comment',
					unread_comments: 0,
				},
				// @todo: analytics for new page
			},
			{
				url: 'buckets',
				text: 'Account Files',
				icon: 'far fa-folder-open',
				pagePlacementId: [22, 65],
				extraActiveUrls: ['/project-files'],
				id: 'A4',
			},
			{
				url: 'teams',
				text: 'Team',
				icon: 'far fa-user',
				pagePlacementId: [24, 70],
				extraActiveUrls: ['/users/invite'],
				id: 'A5',
			},
			/*{
                url: 'users/invite',
                text: 'Users',
                icon: 'far fa-user',
                pagePlacementId: [25, 73]
            },*/
			{
				url: 'messages',
				text: 'Messages',
				icon: 'far fa-comment',
				pagePlacementId: [26, 75],
				id: 'pm',
				data: {
					id: 'messages',
					unread_count: 0,
				},
			},
			// {
			// 	url: 'template-library',
			// 	text: 'Template Library',
			// 	icon: 'far fa-file-image',
			// 	pagePlacementId: [27, 77],
			// 	id: 'tl',
			// },
			// {
			// 	url: 'feedback',
			// 	text: 'Feedback',
			// 	icon: 'far fa-comments',
			// 	pagePlacementId: [69, 172],
			// 	id: 'fb',
			// },
		];
	}

	getSubClientMenu() {
		this.aMenuItems = [
			{
				url: 'account-activity',
				text: 'Account Activity',
				icon: 'assets/img/activity-feed-page/account-activity-logo.svg',
				pagePlacementId: [82, 198],
				id: 'account-activity',
			},
			{
				url: 'dashboard',
				text: 'Dashboard',
				icon: 'far fa-paper-plane',
				pagePlacementId: [16, 32],
				id: 'A1',
			},
			{
				url: 'projects/create',
				text: 'New Project',
				icon: 'far fa-edit',
				data: {
					id: 'project_post',
				},
				pagePlacementId: [17, 36],
				id: 'A2',
			},
			{
				url: 'projects/queue',
				text: 'Projects',
				icon: 'far fa-file',
				pagePlacementId: [17, 36],
				data: {
					id: 'comment',
					unread_comments: 0,
				},
				id: 'A3',
				// @todo: analytics for new page
			},
			{
				url: 'buckets',
				text: 'Account Files',
				icon: 'far fa-folder-open',
				pagePlacementId: [22, 65],
				id: 'A4',
			},
			{
				url: 'messages',
				text: 'Messages',
				icon: 'far fa-comment',
				pagePlacementId: [26, 75],
				id: 'pm',
				data: {
					id: 'messages',
					unread_count: 0,
				},
			},
			// {
			// 	url: 'template-library',
			// 	text: 'Template Library',
			// 	icon: 'far fa-file-image',
			// 	pagePlacementId: [27, 77],
			// 	id: 'tl',
			// },
			// {
			// 	url: 'feedback',
			// 	text: 'Feedback',
			// 	icon: 'far fa-comments',
			// 	pagePlacementId: [69, 172],
			// 	id: 'fb',
			// },
		];
	}

	getPlatinumLeadMenu() {
		this.aMenuItems = [
			{
				url: 'dashboard',
				text: 'Dashboard',
				icon: 'far fa-paper-plane',
				pagePlacementId: [16, 32],
			},
			{
				url: 'projects/unassigned',
				text: 'Projects',
				icon: 'far fa-file',
				pagePlacementId: [32, 90],
				extraActiveUrls: [
					'/projects/active',
					'/bids',
					'/projects/completed',
				],
				data: {
					id: 'available_projects',
					unread_count: 0,
				},
			},
			{
				url: 'packages',
				text: 'Packages',
				icon: 'far fa-file',
			},
			{
				url: 'projects/daily-queue',
				text: 'Daily Queue',
				icon: 'far fa-file',
				pagePlacementId: [],
				data: {
					id: 'daily_queue',
					unread_count: 0,
				},
			},
			{
				url: 'messages',
				text: 'Message Center',
				icon: 'far fa-comment',
				pagePlacementId: [26, 75],
				data: {
					id: 'messages',
					unread_count: 0,
				},
			},
			{
				url: 'admin/users',
				text: 'Users',
				icon: 'far fa-file',
				pagePlacementId: [46, 121],
				creativeLevelId: [5],
			},
			{
				url: 'showcases',
				text: 'My Portfolio',
				icon: 'far fa-images',
				pagePlacementId: [35, 98],
			},
			{
				url: 'teams',
				text: 'My Team',
				icon: 'far fa-user',
				pagePlacementId: [24, 70],
			},
			{
				url: 'resources/approved-resources',
				text: 'Resources',
				icon: 'far fa-folder',
				extraActiveUrls: [
					'/users/rules',
					'/resources/faq',
					'/creatives/onboarding/tests',
					'/feedback',
				],
			},
		];
	}
	getDesignerMenu() {
		this.aMenuItems = [
			{
				url: 'dashboard',
				text: 'Dashboard',
				icon: 'far fa-paper-plane',
				pagePlacementId: [16, 32],
			},
			{
				url: 'projects/unassigned',
				text: 'Projects',
				icon: 'far fa-file',
				pagePlacementId: [32, 90],
				extraActiveUrls: [
					'/projects/active',
					'/bids',
					'/projects/completed',
				],
				data: {
					id: 'available_projects',
					unread_count: 0,
				},
			},
			{
				url: 'packages',
				text: 'Packages',
				icon: 'far fa-file',
			},
			{
				url: 'messages',
				text: 'Message Center',
				icon: 'far fa-comment',
				pagePlacementId: [26, 75],
				data: {
					id: 'messages',
					unread_count: 0,
				},
			},
			{
				url: 'admin/users',
				text: 'Users',
				icon: 'far fa-file',
				pagePlacementId: [46, 121],
				creativeLevelId: [5],
			},
			{
				url: 'showcases',
				text: 'My Portfolio',
				icon: 'far fa-images',
				pagePlacementId: [35, 98],
			},
			{
				url: 'teams',
				text: 'My Team',
				icon: 'far fa-user',
				pagePlacementId: [24, 70],
			},
			{
				url: 'resources/approved-resources',
				text: 'Resources',
				icon: 'far fa-folder',
				extraActiveUrls: [
					'/users/rules',
					'/resources/faq',
					'/creatives/onboarding/tests',
					'/feedback',
				],
			},
		];
	}

	getProjectManagerMenu() {
		this.aMenuItems = [
			{
				url: 'projects/create',
				text: 'Create New Project',
				icon: 'far fa-edit',
				pagePlacementId: [17, 36],
			},
			{
				url: 'projects/unassigned',
				text: 'Unassigned Projects',
				icon: 'far fa-file',
				pagePlacementId: [32, 90],
			},
			{
				url: 'projects/active',
				text: 'Active Projects',
				icon: 'far fa-file',
				pagePlacementId: [19, 45],
			},
			{
				url: 'projects/pending',
				text: 'Pending Projects',
				icon: 'far fa-file',
				pagePlacementId: [18, 42],
			},
			{
				url: 'projects/completed',
				text: 'Completed Projects',
				icon: 'far fa-file',
				pagePlacementId: [20, 49],
			},
			{
				url: 'projects/archived',
				text: 'Archived Projects',
				icon: 'far fa-file',
				pagePlacementId: [42, 113],
			},
			{
				url: 'messages',
				text: 'Message Center',
				icon: 'far fa-comment',
				pagePlacementId: [26, 75],
				data: {
					id: 'messages',
					unread_count: 0,
				},
			},
			{
				url: 'admin/users',
				text: 'Users',
				icon: 'far fa-file',
				pagePlacementId: [46, 121],
			},
		];
	}

	getProjectManagerAsAMMenu() {
		this.aMenuItems = [
			{
				url: 'projects/create',
				text: 'Create New Project',
				icon: 'far fa-edit',
				pagePlacementId: [17, 36],
			},
			{
				url: 'projects/unassigned',
				text: 'Unassigned Projects',
				icon: 'far fa-file',
				pagePlacementId: [32, 90],
			},
			{
				url: 'projects/active',
				text: 'Active Projects',
				icon: 'far fa-file',
				pagePlacementId: [19, 45],
			},
			{
				url: 'projects/pending',
				text: 'Pending Projects',
				icon: 'far fa-file',
				pagePlacementId: [18, 42],
			},
			{
				url: 'projects/completed',
				text: 'Completed Projects',
				icon: 'far fa-file',
				pagePlacementId: [20, 49],
			},
			{
				url: 'projects/archived',
				text: 'Archived Projects',
				icon: 'far fa-file',
				pagePlacementId: [42, 113],
			},
			{
				url: 'messages',
				text: 'Message Center',
				icon: 'far fa-comment',
				pagePlacementId: [26, 75],
			},
			{
				url: 'admin/users',
				text: 'Users',
				icon: 'far fa-file',
				pagePlacementId: [46, 121],
			},
			{
				url: 'admin/team-members',
				text: 'Team Members',
				icon: 'far fa-user',
				pagePlacementId: [],
			},
			{
				url: 'admin/transactions/additional-payments',
				text: 'Additional Payments',
				icon: 'far fa-file',
				pagePlacementId: [74, 184],
			},
			{
				url: 'admin/block-designers',
				text: 'Block Designer',
				icon: 'far fa-file',
				pagePlacementId: [38, 105],
			},
			{
				url: 'admin/projects/dispute-ratings',
				text: 'Dispute Ratings',
				icon: 'far fa-file',
				pagePlacementId: [75, 186],
			},
			{
				url: 'admin/banners',
				text: 'Banners',
				icon: 'far fa-file',
				pagePlacementId: [48, 125],
			},
		];
	}

	getAdminMenu() {
		this.aMenuItems = [
			{
				url: 'dashboard',
				text: 'Dashboard',
				icon: 'far fa-paper-plane',
				pagePlacementId: [16, 32],
			},
			{
				url: 'projects/active',
				text: 'Active Projects',
				icon: 'far fa-file',
				pagePlacementId: [19, 45],
			},
			{
				url: 'packages',
				text: 'Packages',
				icon: 'far fa-file',
			},
			{
				url: 'projects/unassigned',
				text: 'Active Unassigned Projects',
				icon: 'far fa-file',
				pagePlacementId: [32, 90],
			},
			{
				url: 'projects/pending',
				text: 'Pending Projects',
				icon: 'far fa-file',
				pagePlacementId: [18, 42],
			},
			{
				url: 'projects/completed',
				text: 'Completed Projects',
				icon: 'far fa-file',
				pagePlacementId: [20, 49],
			},
			{
				url: 'projects/archived',
				text: 'Archived Projects',
				icon: 'far fa-file',
				pagePlacementId: [42, 113],
			},
			{
				url: 'projects/create',
				text: 'Create New Project',
				icon: 'far fa-edit',
				pagePlacementId: [17, 36],
			},
			{
				url: 'admin/plans',
				text: 'Plans',
				icon: 'far fa-file',
				pagePlacementId: [43, 115],
			},
			{
				url: 'admin/users',
				text: 'Users',
				icon: 'far fa-file',
				pagePlacementId: [46, 121],
			},
			{
				url: 'admin/team-members',
				text: 'Team Members',
				icon: 'far fa-user',
				// todo:analytics
				pagePlacementId: [],
			},
			{
				url: 'admin/bids',
				text: 'Bids',
				icon: 'far fa-file',
				pagePlacementId: [47, 123],
			},
			{
				url: 'admin/block-designers',
				text: 'Block Designer',
				icon: 'far fa-file',
				pagePlacementId: [38, 105],
			},
			{
				url: 'messages',
				text: 'Message Center',
				icon: 'far fa-comment',
				pagePlacementId: [26, 75],
			},
			{
				url: 'admin/template-libraries',
				text: 'Template Library',
				icon: 'far fa-file-image',
				pagePlacementId: [48, 125],
			},
			{
				url: 'admin/onboarding/designs',
				text: 'Onboarding > Upload Design',
				icon: 'far fa-file',
				pagePlacementId: [62, 156],
			},
			{
				url: 'admin/onboarding/tests',
				text: 'Onboarding > Upload Test',
				icon: 'far fa-file',
				pagePlacementId: [65, 162],
			},
			{
				url: 'admin/onboarding/tests/reviews',
				text: 'Onboarding > Test Reviews',
				icon: 'far fa-file',
				pagePlacementId: [],
			},
			{
				url: 'admin/showcases',
				text: 'Designer Showcase',
				icon: 'far fa-file',
				pagePlacementId: [51, 131],
			},
			{
				url: 'admin/project-settings',
				text: 'Project Settings',
				icon: 'far fa-file',
				pagePlacementId: [52, 133],
			},
			{
				url: 'admin/project-questions',
				text: 'Project Questions',
				icon: 'far fa-file',
				pagePlacementId: [53, 135],
			},
			{
				url: 'admin/transactions/outgoing',
				text: 'Outgoing Tx',
				icon: 'far fa-file',
				pagePlacementId: [56, 141],
			},
			{
				url: 'admin/transactions/incoming',
				text: 'Incoming Tx',
				icon: 'far fa-file',
				pagePlacementId: [57, 143],
			},
			{
				url: 'admin/transactions/additional-payments',
				text: 'Additional Payments',
				icon: 'far fa-file',
				pagePlacementId: [74, 184],
			},
			{
				url: 'admin/reports/rating',
				text: 'Reports > Rating',
				icon: 'far fa-file',
				pagePlacementId: [58, 145],
			},
			{
				url: 'admin/pm-signup',
				text: 'PM Setting',
				icon: 'far fa-file',
				pagePlacementId: [59, 147],
			},
			{
				url: 'admin/projects/dispute-ratings',
				text: 'Dispute Ratings',
				icon: 'far fa-file',
				pagePlacementId: [75, 186],
			},
			{
				url: 'admin/banners',
				text: 'Banners',
				icon: 'far fa-file',
				// @todo-analytics
				pagePlacementId: [48, 125],
			},
		];
	}
}
