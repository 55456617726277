import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { ToasterService } from 'angular2-toaster';
import { environment } from '@env/environment';
import { SettingsService } from '@core/settings/settings.service';
import { ApiService } from '@services/common/api.service';
import { CommonService } from '@services/common/common.service';
import { CouponService } from '@services/coupon/coupon.service';
import { LocalStorageService } from '@services/localstorage.service';
import { LoginService } from '@services/login.service';
import { PlanService } from '@services/plan/plan.service';
import { SignupService } from '@services/signup/signup.service';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { PLAN_COUPONS } from '@common/PlanCoupons';
import { TrackingAnalyticsService } from '@services/tracking.analytics.service';
import { WhiteLabelService } from '@services/white-label/white-label.service';

declare var Stripe;

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, AfterViewInit, OnDestroy {
	valForm: FormGroup;
	passwordForm: FormGroup;
	oError: object = {
		status: '',
	};
	oSelectedPlan: object = {};
	isFeatureFlag = true;
	serverStatusCode: number;
	serverMessage: string;
	oCoupon: object = {
		applied: false,
		statusCode: '',
		message: '',
	};
	aCountries = [];
	aStates = [];
	oPaymentData: object = {
		months: [],
		years: [],
	};
	aPlans = [];
	bDisableButton = false;
	oAccordion: string;
	stripe;
	stripeError;
	stripeCardElement: any;
	stripeToken;
	isMonthFree = false;
	isBillingPeriodNotMonthly = false;
	planQuantity;
	coupon_code;
	renewal_date;
	PLAN_COUPONS = PLAN_COUPONS;

	constructor(
		public _localStorageService: LocalStorageService,
		public _apiService: ApiService,
		public _loginService: LoginService,
		public _signupService: SignupService,
		public _couponService: CouponService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		public _settingsService: SettingsService,
		public _planService: PlanService,
		public _formBuilder: FormBuilder,
		public _toasterService: ToasterService,
		public _commonService: CommonService,
		private _changeDetectorRef: ChangeDetectorRef,
		public _trackingAnalyticsService: TrackingAnalyticsService,
		public _whiteLabelService: WhiteLabelService,
	) {
	}

	ngOnInit() {
		if(this._whiteLabelService.isWhiteLabelledUser()){} {
			this._router.navigate(['/login']);
		}
		this.checkUserLoggedIn();
		this.initForm();
		this.setCouponCodeViaQueryString();
		this.getCountryList();
		this.userSignupformControlValueChanged();
	}

	checkUserLoggedIn() {
		if (this._localStorageService.isUserLoggedIn()) {
			this._commonService.redirectUser();
		}
	}

	initForm() {
		const password = new FormControl(
			'',
			Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(16),
			])
		);
		const certainPassword = new FormControl('', [Validators.required]);

		this.passwordForm = this._formBuilder.group(
			{
				password: password,
				confirm_password: certainPassword,
			},
			{
				validator: FlocksyValidator.equalTo(
					'password',
					'confirm_password'
				),
			}
		);

		this.valForm = this._formBuilder.group({
			name: [null, Validators.required],
			email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			phone: [null],
			lead_source: [''],
			passwordGroup: this.passwordForm,
			agree_terms: [null, Validators.required],
			coupon_code: [null],
			country: ['', [Validators.required]],
			state: ['', false],
			plan_id: ['', Validators.required],
			stripe_event_completed: [null, Validators.required],
			is_feature_flag: [this.isFeatureFlag],
		});
	}

	setCouponCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('discount_code' in params) {
				this._localStorageService.setCouponCode(
					btoa(params['discount_code'])
				);
				this.valForm.patchValue({
					coupon_code: atob(
						this._localStorageService.getCouponCode()
					),
				});
			} else {
				if (this._localStorageService.getCouponAppliedDate()) {
					if (
						this._localStorageService.getDateDiffHours(
							new Date(),
							new Date(
								this._localStorageService.getCouponAppliedDate()
							)
						) <= 24
					) {
						if (this._localStorageService.getCouponCode()) {
							this.valForm.patchValue({
								coupon_code: atob(
									this._localStorageService.getCouponCode()
								),
							});
						}
					}
				}
			}

			this.getActivePlans();

			if (this.valForm.value['coupon_code']) {
				this.onClickValidateCouponCode();
			}
		});
	}

	getCountryList() {
		this._apiService.getCountryList().subscribe(
			(data) => {
				this.aCountries = data.data;
			},
			(err) => {}
		);
	}

	getStateList(country_id) {
		if (!country_id) {
			return;
		}

		this._apiService.getStateList(country_id).subscribe(
			(data) => {
				this.aStates = data.data;
			},
			(err) => {}
		);
	}

	ngAfterViewInit() {
		this.initLoadStripe();
	}

	ngOnDestroy() {
		this.destroyStripeCardElement();
	}

	destroyStripeCardElement() {
		if (this.stripeCardElement) {
			this.stripeCardElement.unmount();
			this.stripeCardElement.clear();
			this.stripeCardElement.destroy();
		}
	}

	initLoadStripe() {
		loadStripe(environment.stripePublishableKey).then((r) => {
			this.stripe = r;
			this.initStripe();
		});
	}

	mountStripeCard() {
		this.stripeCardElement.mount('#stripe-card-element');
		const thisObject = this;

		this.stripeCardElement.on('change', function (event) {
			if (event.complete) {
				// enable payment button
				thisObject.stripeError = null;
				thisObject.valForm.get('stripe_event_completed').setValue(true);
				thisObject.valForm.updateValueAndValidity();
				thisObject._changeDetectorRef.detectChanges();
			} else if (event.error) {
				// show validation to customer
				thisObject.stripeError = event.error;
				thisObject.valForm.get('stripe_event_completed').setValue(null);
				thisObject.valForm.updateValueAndValidity();
				thisObject._toasterService.pop('error', event.error.message);
				thisObject._changeDetectorRef.detectChanges();
			}
		});
	}

	initStripe() {
		const elements = this.stripe.elements();
		elements.create('card', { hidePostalCode: true });
		this.stripeCardElement = elements.getElement('card');

		const thisObject = this;
		const checkCardElementExist = setInterval(function () {
			if (document.getElementById('stripe-card-element')) {
				clearInterval(checkCardElementExist);
				thisObject.mountStripeCard();
			}
		}, 100);
	}

	getPlanCost() {
		if (
			this.isMonthFree &&
			this.oSelectedPlan['coupon'] &&
			this.oSelectedPlan['coupon']['quantity']
		) {
			return (
				this.oSelectedPlan['plan_cost'] *
				this.oSelectedPlan['coupon']['quantity']
			);
		}
		return parseFloat(this.oSelectedPlan['plan_cost']);
	}

	getPayableAmount() {
		if (this.oSelectedPlan) {
			if ('plan_cost' in this.oSelectedPlan) {
				let total = 0;
				total = this.getPlanCost();

				if (this.oCoupon && this.oCoupon['applied'] == true) {
					if ('type' in this.oSelectedPlan) {
						if (this.oSelectedPlan['type'] == 1) {
							total =
								total -
								parseFloat(this.oSelectedPlan['discount']);
						}
						if (this.oSelectedPlan['type'] == 2) {
							if (this.oSelectedPlan['discount'] != 100) {
								total =
									total -
									(total *
										parseFloat(
											this.oSelectedPlan['discount']
										)) /
										100;
							}
						}
					}
				}
				if (
					this.valForm.value.state &&
					this.valForm.value.state == '3927'
				) {
					total = total + (total * 6.5) / 100;
				}
				return total;
			}
		}
		return 0;
	}

	getStateTaxAmount() {
		const total = 0;
		if (this.oSelectedPlan) {
			if ('plan_cost' in this.oSelectedPlan) {
				let total = 0;
				if (
					this.valForm.value.state &&
					this.valForm.value.state == '3927'
				) {
					total =
						((this.getPlanCost() - this.getCouponAmount()) * 6.5) /
						100;
				}
				return total;
			}
		}
		return total;
	}

	getCouponAmount() {
		let total = 0;
		if (
			this.oCoupon &&
			this.oCoupon['applied'] == true &&
			this.oSelectedPlan
		) {
			if ('type' in this.oSelectedPlan) {
				if (this.oSelectedPlan['type'] == 1) {
					total = parseFloat(this.oSelectedPlan['discount']);
				}
				if (this.oSelectedPlan['type'] == 2) {
					if (this.oSelectedPlan['discount'] != 100) {
						total =
							(this.getPlanCost() *
								parseFloat(this.oSelectedPlan['discount'])) /
							100;
					}
				}
			}
		}
		return total;
	}

	calculateTotalAmountHtml() {
		let html = '';
		if (
			this.valForm.value.state &&
			this.valForm.value.state != '3927' &&
			this.oCoupon &&
			!('type' in this.oSelectedPlan)
		) {
		} else {
			html += '<p>Total Amount: ' + this.getPlanCost() + '</p>';
		}
	}

	userSignupformControlValueChanged() {
		this.valForm.get('country').valueChanges.subscribe((mode: any) => {
			if (mode) {
				if (mode && mode == 231) {
					if (this.aStates.length == 0) {
						this.getStateList(mode);
						this.valForm
							.get('state')
							.setValidators([Validators.required]);
					}
				} else {
					this.aStates = [];
					this.valForm.patchValue({ state: '' });
					this.valForm.get('state').clearValidators();
					this.valForm.get('state').updateValueAndValidity();
				}
			}
		});
	}

	onChangeAccordion(event, oPlan) {
		this.oSelectedPlan = oPlan;
		this.valForm.controls['plan_id'].setValue(
			this.oSelectedPlan['plan_id']
		);
	}

	createStripeToken() {
		return this.stripe.createToken(this.stripeCardElement);
	}

	submitForm($ev, value: any) {
		$ev.preventDefault();
		for (const c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}
		for (const c in this.passwordForm.controls) {
			this.passwordForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			return false;
		}

		this.bDisableButton = true;

		const thisObject = this;
		this.createStripeToken().then((result) => {
			// Handle result.error or result.token
			if (result.token) {
				thisObject.stripeToken = result.token;
				thisObject.onCheckoutSubmit();
				thisObject._changeDetectorRef.detectChanges();
			} else if (result.error) {
				thisObject.stripeError = result.error;
				thisObject.bDisableButton = false;
				thisObject._changeDetectorRef.detectChanges();
			}
		});
	}

	onCheckoutSubmit() {
		if (!this.stripeToken) {
			this._toasterService.pop('error', 'Please try again');
		}

		Object.assign(this.valForm.value, this.valForm.value.passwordGroup);

		const oPost = {
			coupon_code: this._localStorageService.getCouponCode()
				? atob(this._localStorageService.getCouponCode())
				: '',
			coupon_applied_date:
				this._localStorageService.getCouponAppliedDate()
					? this._localStorageService.getCouponAppliedDate()
					: '',
			country_id: this.valForm.value.country,
			state_id: this.valForm.value.state,
			token_response: this.stripeToken,
			temp_token_id: this.stripeToken['id'],
			last4: this.stripeToken['card'].last4,
		};
		Object.assign(this.valForm.value, oPost);

		this.valForm.value['user_type_id'] = 1;

		this._signupService.signupPaymentUser(this.valForm.value).subscribe(
			(data) => {
				this.serverStatusCode = data.status;
				this.serverMessage = data.message;
				this._toasterService.pop('success', data.message);
				this._trackingAnalyticsService.trackGoogleConversion();
				this.autoLoginAfterSignup(
					this.valForm.value.email,
					this.valForm.value.passwordGroup['password']
				);
				this.valForm.reset();
			},
			(err) => {
				this.bDisableButton = false;
				this.serverStatusCode = 422;
				this.serverMessage = err.error.message;
				return false;
			}
		);
	}

	autoLoginAfterSignup(email, password) {
		const auth_data = {
			grant_type: 'password',
			username: email,
			password: password,
			client_id: environment.ApiClientId,
			client_secret: environment.ApiClientSecret,
			client_signup: 1,
		};

		this._loginService.oAuthToken(JSON.stringify(auth_data)).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.serverStatusCode = data.status;
				this.serverMessage = '';
				this.getAuthUser();
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	getAuthUser() {
		this._apiService.getAuthUser().subscribe(
			(data) => {
				this._localStorageService.setCurrentUser(data.data);
				this._commonService.redirectUser();
			},
			(err) => {}
		);
	}

	resetCouponData() {
		this.oCoupon = {
			applied: true,
			statusCode: '',
			message: '',
		};
	}

	getActivePlans() {
		const oFilter = {};
		if (this.valForm.value['coupon_code']) {
			oFilter['discount_code'] = this.valForm.value['coupon_code'];
		}
		this._planService.retrieveActivePlans(oFilter).subscribe((data) => {
			this.aPlans = data.data;
			this.assignDefaultPlan();

			// Check if Billing Period is not monthly
			for (let i = 0; i < this.aPlans.length; i++) {
				const plan = this.aPlans[i];
				if (plan.coupon && plan.coupon.quantity) {
					this.planQuantity = plan.coupon.quantity;
				}
				if (plan.billing_period != 1) {
					this.isBillingPeriodNotMonthly = true;
					break;
				}
			}
		});
	}

	assignDefaultPlan() {
		if (this.valForm.value['coupon_code']) {
			for (const plan of this.aPlans) {
				if (plan['coupon']) {
					plan['plan_coupon_id'] = plan['coupon']['id'];
					plan['type'] = plan['coupon']['type'];
					plan['discount'] = plan['coupon']['discount'];
					if (!this.oSelectedPlan) {
						this.setDefaultPlan(plan);
					}
				}
			}
		} else {
			if (this.aPlans.length > 0) {
				this.setDefaultPlan(this.aPlans[0]);
			}
		}
	}

	setDefaultPlan(plan) {
		this.oSelectedPlan = plan;
		this.valForm.controls['plan_id'].setValue(
			this.oSelectedPlan['plan_id']
		);
		this.oAccordion = this.oSelectedPlan['plan_id'];
	}

	onClickValidateCouponCode() {
		const coupon_code = this.valForm.value['coupon_code'];
		this.resetCouponData();
		if (coupon_code) {
			const oParams = {
				include: 'coupon',
			};
			this._couponService.exists(coupon_code, oParams).subscribe(
				(data) => {
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = data.message;
					this.oCoupon['statusCode'] = data.status;
					this.oCoupon['data'] = data.data;

					this._localStorageService.setCouponCode(btoa(coupon_code));
					this._localStorageService.setCouponAppliedDate(new Date());

					this._toasterService.pop('success', data.message);

					// Check if the coupon is for 1 Month free
					for (let i = 0; i < this.oCoupon['data'].length; i++) {
						const coupon = this.oCoupon['data'][i];
						if (
							coupon.type == 2 &&
							coupon.coupon &&
							coupon.coupon.percent_off == 100
						) {
							this.isMonthFree = true;
							break;
						}
					}

					this.coupon_code = coupon_code;
					const date = new Date();
					if (this.coupon_code == this.PLAN_COUPONS.Buy1Get1) {
						this.renewal_date =
							this._localStorageService.formatDate(
								date.setMonth(date.getMonth() + 2),
								'MMM DD, YYYY'
							);
					} else if (this.coupon_code == this.PLAN_COUPONS.Buy2Get1) {
						this.renewal_date =
							this._localStorageService.formatDate(
								date.setMonth(date.getMonth() + 3),
								'MMM DD, YYYY'
							);
					}
				},
				(err) => {
					this._localStorageService.setCouponCode('');
					this._localStorageService.setCouponAppliedDate('');
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = err.message;
					this.oCoupon['statusCode'] = err.status;
					this.oCoupon['data'] = {};
					this.getActivePlans();
				}
			);
		} else {
			this._toasterService.pop('error', 'Please Enter Coupon Code');
		}
	}
}
