import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ProjectInviteDesignersService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/invite-designers'
		);
	}

	store(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/invite-designers',
			oData
		);
	}

	destroy(project_id, id): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				project_id +
				'/invite-designers/' +
				id
		);
	}

	inviteTeam(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/designers/invites/team',
			oData
		);
	}
}
