<svg
	width="16"
	height="16"
	viewBox="0 0 16 16"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		id="Vector"
		d="M2.11538 16H0V13.8846L11.9285 1.92654L14.0735 4.07192L2.11538 16ZM14.5277 3.61539L12.3846 1.47231L13.6023 0.29308C13.7904 0.103849 14.0788 3.40122e-06 14.3461 3.40122e-06C14.4777 -0.000340387 14.6081 0.0253812 14.7297 0.0756836C14.8513 0.125986 14.9617 0.199873 15.0546 0.29308L15.7085 0.946926C15.8013 1.03971 15.8748 1.14994 15.9248 1.27126C15.9749 1.39259 16.0004 1.52261 16 1.65385C16 1.92308 15.8958 2.20962 15.7069 2.39846L14.5277 3.61539Z"
		fill="#C1C1C1" />
</svg>
