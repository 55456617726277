<svg
	width="17"
	height="13"
	viewBox="0 0 17 13"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		*ngIf="type == iconType.normal"
		id="Vector Vector1"
		d="M13.7241 0.412243C14.0049 0.144905 14.3786 -0.0028907 14.7663 4.28491e-05C15.154 0.0029764 15.5254 0.15641 15.8021 0.427967C16.0788 0.699524 16.2392 1.06796 16.2495 1.45554C16.2597 1.84312 16.119 2.21953 15.857 2.50533L7.9033 12.4525C7.76653 12.5998 7.60146 12.718 7.41797 12.8C7.23447 12.8821 7.03631 12.9263 6.83533 12.93C6.63435 12.9338 6.43469 12.8969 6.24828 12.8217C6.06187 12.7465 5.89254 12.6345 5.75041 12.4923L0.475844 7.21775C0.328956 7.08088 0.211141 6.91583 0.129427 6.73243C0.0477133 6.54904 0.00377453 6.35107 0.000232671 6.15033C-0.00330919 5.94958 0.0336185 5.75019 0.108812 5.56402C0.184006 5.37786 0.295925 5.20875 0.437893 5.06679C0.579862 4.92482 0.74897 4.8129 0.935131 4.7377C1.12129 4.66251 1.32069 4.62558 1.52143 4.62912C1.72218 4.63267 1.92015 4.67661 2.10354 4.75832C2.28693 4.84003 2.45199 4.95785 2.58886 5.10474L6.76307 9.27695L13.6862 0.456099C13.6987 0.440752 13.712 0.42611 13.7261 0.412243H13.7241Z"
		fill="#C1C1C1" />
	<path
		*ngIf="type == iconType.hovered"
		id="Vector Vector2"
		d="M13.7241 0.412243C14.0049 0.144905 14.3786 -0.0028907 14.7663 4.28491e-05C15.154 0.0029764 15.5254 0.15641 15.8021 0.427967C16.0788 0.699524 16.2392 1.06796 16.2495 1.45554C16.2597 1.84312 16.119 2.21953 15.857 2.50533L7.9033 12.4525C7.76653 12.5998 7.60146 12.718 7.41797 12.8C7.23447 12.8821 7.03631 12.9263 6.83533 12.93C6.63435 12.9338 6.43469 12.8969 6.24828 12.8217C6.06187 12.7465 5.89254 12.6345 5.75041 12.4923L0.475844 7.21775C0.328956 7.08088 0.211141 6.91583 0.129427 6.73243C0.0477133 6.54904 0.00377453 6.35107 0.000232671 6.15033C-0.00330919 5.94958 0.0336185 5.75019 0.108812 5.56402C0.184006 5.37786 0.295925 5.20875 0.437893 5.06679C0.579862 4.92482 0.74897 4.8129 0.935131 4.7377C1.12129 4.66251 1.32069 4.62558 1.52143 4.62912C1.72218 4.63267 1.92015 4.67661 2.10354 4.75832C2.28693 4.84003 2.45199 4.95785 2.58886 5.10474L6.76307 9.27695L13.6862 0.456099C13.6987 0.440752 13.712 0.42611 13.7261 0.412243H13.7241Z"
		fill="#949695" />
	<path
		*ngIf="type == iconType.selected"
		id="Vector Vector3"
		d="M13.7241 0.412243C14.0049 0.144905 14.3786 -0.0028907 14.7663 4.28491e-05C15.154 0.0029764 15.5254 0.15641 15.8021 0.427967C16.0788 0.699524 16.2392 1.06796 16.2495 1.45554C16.2597 1.84312 16.119 2.21953 15.857 2.50533L7.9033 12.4525C7.76653 12.5998 7.60146 12.718 7.41797 12.8C7.23447 12.8821 7.03631 12.9263 6.83533 12.93C6.63435 12.9338 6.43469 12.8969 6.24828 12.8217C6.06187 12.7465 5.89254 12.6345 5.75041 12.4923L0.475844 7.21775C0.328956 7.08088 0.211141 6.91583 0.129427 6.73243C0.0477133 6.54904 0.00377453 6.35107 0.000232671 6.15033C-0.00330919 5.94958 0.0336185 5.75019 0.108812 5.56402C0.184006 5.37786 0.295925 5.20875 0.437893 5.06679C0.579862 4.92482 0.74897 4.8129 0.935131 4.7377C1.12129 4.66251 1.32069 4.62558 1.52143 4.62912C1.72218 4.63267 1.92015 4.67661 2.10354 4.75832C2.28693 4.84003 2.45199 4.95785 2.58886 5.10474L6.76307 9.27695L13.6862 0.456099C13.6987 0.440752 13.712 0.42611 13.7261 0.412243H13.7241Z"
		fill="#16CC8F" />
	<path
		*ngIf="type == iconType.disabled"
		id="Vector Vector4"
		d="M13.7241 0.412243C14.0049 0.144905 14.3786 -0.0028907 14.7663 4.28491e-05C15.154 0.0029764 15.5254 0.15641 15.8021 0.427967C16.0788 0.699524 16.2392 1.06796 16.2495 1.45554C16.2597 1.84312 16.119 2.21953 15.857 2.50533L7.9033 12.4525C7.76653 12.5998 7.60146 12.718 7.41797 12.8C7.23447 12.8821 7.03631 12.9263 6.83533 12.93C6.63435 12.9338 6.43469 12.8969 6.24828 12.8217C6.06187 12.7465 5.89254 12.6345 5.75041 12.4923L0.475844 7.21775C0.328956 7.08088 0.211141 6.91583 0.129427 6.73243C0.0477133 6.54904 0.00377453 6.35107 0.000232671 6.15033C-0.00330919 5.94958 0.0336185 5.75019 0.108812 5.56402C0.184006 5.37786 0.295925 5.20875 0.437893 5.06679C0.579862 4.92482 0.74897 4.8129 0.935131 4.7377C1.12129 4.66251 1.32069 4.62558 1.52143 4.62912C1.72218 4.63267 1.92015 4.67661 2.10354 4.75832C2.28693 4.84003 2.45199 4.95785 2.58886 5.10474L6.76307 9.27695L13.6862 0.456099C13.6987 0.440752 13.712 0.42611 13.7261 0.412243H13.7241Z"
		fill="#C1C1C1" />
</svg>
