import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '@services/common/api.service';
import { LocalStorageService } from '@services/localstorage.service';
import { CommonService } from '@services/common/common.service';
import { HotjarService } from '@services/hotjar/hotjar.service';
import { ThonestService } from '@services/thonest.service';
import { Router, NavigationEnd } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { WhiteLabelService } from '@services/white-label/white-label.service';
@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
	bShowDemoSignup = false;
	whiteBackground: any = FlocksyUtil.whiteBackground;
	showBanner = true;
	currentUrl: any;
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();
	BANNER_HIDE_DATE = 6;
	isPlatinumLevel = this._localStorageService.isPlatinumLevel();
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	holidayDate = new Date(2024, 11 - 1, 28); //yyyy,M,d  //use currentMonth - 1
	showHolidayBanner = this._commonService.isShowHolidayBanner(
		this.holidayDate
	);
	activityFeedPadding = window.innerWidth > 1079 ? true : false;
	isWhiteLabelledUser: boolean = this._whiteLabelService.isWhiteLabelledUser();
	isDesignerUser: boolean = this._localStorageService.isDesignerUser();

	get canShowHolidayBanner():boolean {
		if(this.showHolidayBanner && this._localStorageService.isOperationalClientUser()) {
			if(this.isWhiteLabelledUser) {
				return this._localStorageService.isClientUser();
			}
			else {
				return true;
			}
		}
		return false;
	}

	constructor(
		public _commonService: CommonService,
		public _localStorageService: LocalStorageService,
		public _hotjarService: HotjarService,
		public _apiService: ApiService,
		public _thonestService: ThonestService,
		public _router: Router,
		public _whiteLabelService: WhiteLabelService,
	) {
	}

	ngOnInit() {
		const body = document.getElementsByTagName('body');
		if (body && this.isOperationalClientUser) {
			body[0].style.backgroundColor = 'white ';
		}
		this.init();
		this.initStorage();
		this.currentUrl = this._router.url;
		this.currentUrl = this.currentUrl.split('?')[0];
		if (this.currentUrl.startsWith('/projects/')) {
			this.currentUrl = '/' + this.currentUrl.split('/')[1];
		}
		this._router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.currentUrl = val.url;
				this.currentUrl = this.currentUrl.split('?')[0];
				if (this.currentUrl.startsWith('/projects/')) {
					this.currentUrl = '/' + this.currentUrl.split('/')[1];
				}
				this.currentUrl = this.currentUrl.split('?')[0];
			}
		});
	}

	init() {
		if (this._localStorageService.isOperationalClientUser()) {
			this.retrievePlanSeamless();
		}

		if (this._localStorageService.isProjectManagerUser()) {
			this.initHotjar();
		}

		this.initThonest();
	}

	initThonest() {
		this._thonestService.init();

		this._router.events
			.pipe(takeUntil(this.destroyed$))
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					this._thonestService.remove();
					const excludeUrls = ['/logout', '/login'];
					if (!excludeUrls.includes(event.url)) {
						this._thonestService.init();
					}
				}
			});
	}

	initHotjar() {
		this._hotjarService.init();
	}

	initStorage() {
		this._localStorageService.setDemoPlan(this.bShowDemoSignup);
	}

	retrievePlanSeamless() {
		this._apiService.getPlanSeamless().subscribe((data) => {
			if ('client' in data.data) {
				if ('plan_activated' in data.data['client']) {
					if (data.data['client']['demo_plan']) {
						this.bShowDemoSignup = true;
					}
					this._localStorageService.setDemoPlan(this.bShowDemoSignup);
				}
			} else if ('subclient' in data.data) {
				if ('plan_activated' in data.data['subclient']['client']) {
					if (data.data['subclient']['client']['demo_plan']) {
						this.bShowDemoSignup = true;
					}
					this._localStorageService.setDemoPlan(this.bShowDemoSignup);
				}
			}
		});
	}

	initDemoSignupOverlay() {
		this._commonService.actionTriggered.emit({
			action: 'demo_signup_overlay',
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
