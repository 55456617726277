import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideojsComponent } from './videojs.component';

@NgModule({
	imports: [CommonModule],
	exports: [VideojsComponent],
	declarations: [VideojsComponent],
	providers: [],
})
export class VideojsModule {
	static forRoot():
		| any[]
		| import('@angular/core').Type<any>
		| import('@angular/core').ModuleWithProviders<{}> {
		throw new Error('Method not implemented.');
	}
}
