<div>
	<tabset #staticTabs (selectTab)="onSelectTab($event)">
		<tab
			heading="Referral Link"
			id="social_tab">
			<div class="mt-4">
				<app-share-social-media></app-share-social-media>
			</div>
		</tab>
		<tab
			heading="Send Via Email"
			id="email_tab">
			<div class="mt-4">
				<app-share-email></app-share-email>
			</div>
		</tab>
	</tabset>
</div>
