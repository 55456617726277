import { NgModule } from '@angular/core';
import { AppModalPopupComponent } from './app-modal-popup.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';

@NgModule({
	imports: [CommonModule, FormsModule, NgxSelectModule, ReactiveFormsModule],
	exports: [AppModalPopupComponent],
	declarations: [AppModalPopupComponent],
	providers: [],
})
export class AppModalPopupModule {}
