import { Injectable } from '@angular/core';

import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class NotificationService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, page?, oFilter?: any): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set(key, oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.NOTIFICATION_URL +
				'?' +
				'page=' +
				page +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	getUnreadNotifications(id, page?, oFilter?: any): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set(key, oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.NOTIFICATION_URL +
				'/unread' +
				'?' +
				'page=' +
				page +
				(params.toString() ? '&' + params.toString() : '')
		);
	}

	unreadCount(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.NOTIFICATION_URL + '/count/unread'
		);
	}

	read(notificationId, data: any): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.NOTIFICATION_URL + '/' + notificationId + '/clear',
			data
		);
	}

	getReadNotifications(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.NOTIFICATION_URL + '/read'
		);
	}

	readAll(userId, data: any): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.NOTIFICATION_URL + '/' + userId + '/read-all',
			data
		);
	}

	seenAll(userId, data: any): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.NOTIFICATION_URL + '/' + userId + '/seen-all',
			data
		);
	}

	destroy(notificationId): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.NOTIFICATION_URL + '/' + notificationId + '/clear/'
		);
	}

	destroyAll(userId): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.NOTIFICATION_URL + '/' + userId + '/delete/'
		);
	}

	activityMarkAllAsResolved(
		userId: string, 
		data: any, 
		searchQuery = '', 
		showUnresolved = 0, 
		directMessage = 0, 
		accountActions = 0, 
		projectMessages = 0, 
		projectMessageId = '', 
		sortType = ''
	): Observable<any> {
		let queryParams = [];
	
		if (directMessage || accountActions || projectMessages || projectMessageId) {
			queryParams.push(`filter[messages]=1`);
		}
	
		if (searchQuery) queryParams.push(`filter[q]=${searchQuery}`);
		if (showUnresolved) queryParams.push(`filter[show_unresolved]=${showUnresolved}`);
		if (directMessage) queryParams.push(`filter[direct_messages]=${directMessage}`);
		if (accountActions) queryParams.push(`filter[account_actions]=${accountActions}`);
		if (projectMessages) queryParams.push(`filter[project_messages]=${projectMessages}`);
		if (projectMessageId) queryParams.push(`filter[project_id]=${projectMessageId}`);
		if (sortType) queryParams.push(`sort=${sortType}`);
	
		const queryString = queryParams.join('&');
	
		return this._customHttpService.put(
			`${API_CONSTANTS.NOTIFICATION_URL}/${userId}/resolve-all?${queryString}`,
			data
		);
	}	

	activityMarkAsResolved(notificationId, data: any): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.NOTIFICATION_URL + '/' + notificationId + '/resolve',
			data
		);
	}
	
	activityMarkAsUnresolved(notificationId, data: any): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.NOTIFICATION_URL + '/' + notificationId + '/unresolve',
			data
		);
	}

}
