import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimerSharedService } from '../../../services/time-entries/timer-shared.service';
import * as workerTimers from 'worker-timers';

/**
 * USAGE:
 * <flocksy-timer units="Days|Hours|Minutes|Seconds|Milliseconds" end="July 22, 2018"></app-flocksy-timer>
 */

@Component({
	selector: 'flocksy-timer',
	templateUrl: './flocksy-timer.component.html',
	styleUrls: ['./flocksy-timer.component.css'],
})
export class FlocksyTimerComponent implements OnInit {
	_to: any;

	@Input()
	units: any;

	@Input()
	hours: any;

	@Input()
	minutes: any;

	@Input()
	isEarningTimer = false;

	@Input()
	seconds: any;

	@Input()
	text: any;
	@Input()
	hourly = false;
	@Input()
	positiveText = false;

	@Input() hours_red = false;

	@Input() showNegativeTime = true;

	@Input() showFullStop = false;

	@Input() timerDirection = 'down';

	@Input() shortTime: boolean = false;

	@Output()
	endCount = new EventEmitter();

	givenDate: any;
	displayString: string;
	stopCounter = false;
	isNegative = false;
	activeRedClass = false;
	timerLimit = 1;

	currentDate: any;
	timerIntervalRef;

	constructor(private _timerSharedService: TimerSharedService) {}

	ngOnInit(): void {
		if (typeof this.units === 'string') {
			this.units = this.units.toLowerCase().split('|');
		}

		this.givenDate = new Date();
		if (parseInt(this.hours, 10) > 0) {
			this.givenDate.setHours(
				this.givenDate.getHours() + parseInt(this.hours, 10)
			);
		}

		if (parseInt(this.minutes, 10) > 0) {
			this.givenDate.setMinutes(
				this.givenDate.getMinutes() + parseInt(this.minutes, 10)
			);
		}

		if (parseInt(this.seconds, 10)) {
			this.givenDate.setSeconds(
				this.givenDate.getSeconds() + parseInt(this.seconds, 10)
			);
		}

		if (!this.text) {
			this.text = {
				Weeks: 'W',
				Days: 'D',
				Hours: 'H',
				Minutes: 'M',
				Seconds: 'S',
				MilliSeconds: 'MS',
			};
		}

		if (this.isEarningTimer) {
			this._timerSharedService.timerLimit$.subscribe((res) => {
				this.timerLimit = res;
			});
		}

		this._to = workerTimers.setInterval(
			() => this._displayString(),
			this.units['milliseconds'] ? 1 : 1000
		);
	}

	splitTime(numberOfHours) {
		const days = Math.floor(numberOfHours / 24);
		const remainder = numberOfHours % 24;
		const hours = Math.floor(remainder);
		const minutes = Math.floor(60 * (remainder - hours));
		return { days: days, hours: hours, minutes: minutes };
	}

	_displayString() {
		if (this.timerDirection == 'up' && !this.currentDate) {
			this.currentDate = new Date();
		}

		const now: any = new Date(),
			lastUnit = this.units[this.units.length - 1],
			unitsLeft = [],
			unitConstantMillis = {
				weeks: 6048e5, // 7 days
				days: 864e5, // 24 hours
				hours: 36e5, // 60 mins
				minutes: 6e4,
				seconds: 1e3,
				milliseconds: 1,
			};

		let msLeft: any;
		if (this.timerDirection == 'up') {
			this.givenDate.setSeconds(this.givenDate.getSeconds() + 1);
			msLeft = this.givenDate - this.currentDate;
		}
		else {
			msLeft = this.givenDate - now;
		}
		let returnString = '';

		if (msLeft <= 0) {
			this.stopCounter = true;
			this.endCount.emit();
			workerTimers.clearInterval(this._to);
		}

		if (this.hours_red && msLeft < 43200000) {
			this.activeRedClass = true;
		}
		let shortTimeCount = 0;
		this.units.forEach((unit: string) => {
			if (!unitConstantMillis[unit]) {
				// $interval.cancel(countDownInterval);
				throw new Error('Cannot repeat unit: ' + unit);
			}

			if (!unitConstantMillis.hasOwnProperty(unit)) {
				throw new Error(
					'Unit: ' +
						unit +
						' is not supported. ' +
						' Please use following units: ' +
						' + weeks, days, hours, minutes, seconds, milliseconds'
				);
			}

			unitsLeft[unit] = msLeft / unitConstantMillis[unit];

			unitsLeft[unit] =
				lastUnit === unit
					? Math.ceil(unitsLeft[unit])
					: Math.floor(unitsLeft[unit]);

			msLeft -= unitsLeft[unit] * unitConstantMillis[unit];

			unitConstantMillis[unit] = false;

			let units = unit.toUpperCase().slice(0, 1);

			if (unitsLeft[unit] >= 0) {
				if(this.shortTime) {
					if(unitsLeft[unit] > 0 && shortTimeCount < 2) {
						shortTimeCount++;
						returnString +=
							' ' +
							(unitsLeft[unit] < 10
								? '0' + unitsLeft[unit]
								: unitsLeft[unit]) + units;
					}
				}
				else {
					returnString +=
						' ' +
						(unitsLeft[unit] < 10
							? '0' + unitsLeft[unit]
							: unitsLeft[unit]) +
						' ' +
						units;
				}

				let actualHours = unitsLeft['hours'];

				if (returnString.length >= 15 && this.isEarningTimer) {
					if (+actualHours >= this.timerLimit) {
						this._timerSharedService.showTimerLimit.next(true);
						this.timerLimit = this._timerSharedService.stopHours;
					}
				}
			}
		});

		if (this.stopCounter) {
			if (this.showNegativeTime) {
				this._displayNegativeString();
			} else if (this.hourly) {
				this._hourlyTimer();
			} else {
				this.displayString = '0 H';
				if (this.positiveText) {
					this.displayString = '0 H'.replace('H', this.text.Hours);
				}
			}
		}
		else {
			this.displayString = returnString
				.replace('Weeks', this.text.Weeks)
				.replace('Days', this.text.Days)
				.replace('Hours', this.text.Hours)
				.replace('Minutes', this.text.Minutes)
				.replace('Seconds', this.text.Seconds)
				.replace('Milliseconds', this.text.MilliSeconds)
				.replace('W', this.text.Weeks)
				.replace('D', this.text.Days)
				.replace('H', this.text.Hours)
				.replace('M', this.text.Minutes)
				.replace('S', this.text.Seconds)
				.replace('Ms', this.text.MilliSeconds);

			if (this.positiveText) {
				this.displayString = returnString
					.replace('W', this.text.Weeks)
					.replace('D', this.text.Days)
					.replace('H', this.text.Hours)
					.replace('M', this.text.Minutes)
					.replace('S', this.text.Seconds)
					.replace('Ms', this.text.MilliSeconds);
			}
		}
	}

	_displayNegativeString() {
		this.isNegative = true;
		const hoursData = this.splitTime(Math.abs(this.hours));
		const totalMinutes = Math.abs(this.minutes) + hoursData.minutes;

		const inp = {
			year: 0,
			month: 0,
			day: hoursData.days,
			hours: hoursData.hours,
			minutes: totalMinutes,
			seconds: Math.abs(this.seconds),
		};
		let returnString = '';
		let oDate = new Date();
		oDate.setUTCDate(oDate.getUTCDate() - inp.day);
		oDate.setUTCHours(inp.hours);
		oDate.setUTCMinutes(inp.minutes);
		oDate.setUTCSeconds(inp.seconds);

		const interval = 1;

		workerTimers.setInterval(() => {
			var now = new Date().getTime();
			oDate = new Date(oDate.getTime() + interval * 1000);
			var timeDiff = Math.abs(new Date().getTime() - oDate.getTime());
			var diffDays = Math.abs(timeDiff / (1000 * 3600 * 24));

			if(this.shortTime) {
				if(parseInt(diffDays.toString()) != 0) {
					returnString =
						parseInt(diffDays.toString()) +
						'Days ' +
						oDate.getUTCHours() +
						'Hours '
				}
				else if(oDate.getUTCHours() != 0) {
					returnString = oDate.getUTCHours() +
						'Hours ' +
						oDate.getUTCMinutes() +
						'Minutes '
				}
				else {
					returnString = oDate.getUTCMinutes() +
						'Minutes ' +
						oDate.getUTCSeconds() +
						'Seconds '
				}
			}
			else {
				returnString =
					parseInt(diffDays.toString()) +
					' Days ' +
					oDate.getUTCHours() +
					' Hours ' +
					oDate.getUTCMinutes() +
					' Minutes ' +
					oDate.getUTCSeconds() +
					' Seconds ';
			}

			this.displayString =
				' - ' +
				returnString
					.replace('Days', this.text.Days)
					.replace('Hours', this.text.Hours)
					.replace('Minutes', this.text.Minutes)
					.replace('Seconds', this.text.Seconds)
					.replace('Milliseconds', this.text.MilliSeconds);
		}, Math.abs(interval) * 1000);
	}

	_hourlyTimer() {
		this.isNegative = false;
		const hoursData = this.splitTime(Math.abs(this.hours));
		const totalMinutes = Math.abs(this.minutes) + hoursData.minutes;

		const inp = {
			hours: hoursData.hours,
			minutes: totalMinutes,
			seconds: Math.abs(this.seconds),
		};
		let returnString = '';
		let oDate = new Date();
		oDate.setUTCHours(inp.hours);
		oDate.setUTCMinutes(inp.minutes);
		oDate.setUTCSeconds(inp.seconds);

		const interval = 1;

		this.timerIntervalRef = workerTimers.setInterval(() => {
			var now = new Date().getTime();
			oDate = new Date(oDate.getTime() + interval * 1000);
			var timeDiff = Math.abs(new Date().getTime() - oDate.getTime());
			var diffDays = Math.abs(timeDiff / (1000 * 3600 * 24));

			returnString =
				(oDate.getUTCHours() < 10
					? '0' + oDate.getUTCHours()
					: oDate.getUTCHours()) +
				' Hours ' +
				(oDate.getUTCMinutes() < 10
					? '0' + oDate.getUTCMinutes()
					: oDate.getUTCMinutes()) +
				' Minutes ' +
				(oDate.getUTCSeconds() < 10
					? '0' + oDate.getUTCSeconds()
					: oDate.getUTCSeconds()) +
				' Seconds ';

			this.displayString = returnString
				.replace('Hours', this.text.Hours)
				.replace('Minutes', this.text.Minutes)
				.replace('Seconds', this.text.Seconds);

			if (oDate.getUTCHours() >= this.timerLimit && this.isEarningTimer) {
				this._timerSharedService.showTimerLimit.next(true);
				this.timerLimit = this._timerSharedService.stopHours;
			}
		}, Math.abs(interval) * 1000);
	}

	clearInterval() {
		if (this._to) {
			try {
				workerTimers.clearInterval(this._to);
			} catch (e) {}
		}
		if (this.timerIntervalRef) {
			try {
				workerTimers.clearInterval(this.timerIntervalRef);
			} catch (e) {}
		}
	}
}
