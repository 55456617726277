<div class="card package-info-card-wrapper">
	<div class="card-body">
		<div
			class="package-list-image-wrapper"
			[ngStyle]="{
				'background-image':
					'url(' + packageInfo?.document_file?.full_path + ')'
			}">
			<img
				class="w-100 package-image-content"
				src="../../../{{ packageBGImage }}"
				alt="" />
		</div>
		<div class="package-rating-wrapper">
			<div
				class="package-list-image-wrapper profile-picture-wrapper ml-2"
				[ngStyle]="{
					'background-image': packageInfo?.created_by_user
						?.profile_picture?.full_path
						? 'url(' +
						  packageInfo?.created_by_user?.profile_picture
								?.full_path +
						  ')'
						: 'url(' +
						  '../../../../assets/img/user-placeholder.png' +
						  ')'
				}">
				<img [src]="'../../../assets/img/profile-bg-img.png'" alt="" />
			</div>
			<div class="profile-title text-primary">
				{{ packageInfo?.created_by_user?.name }}
			</div>
			<div class="d-flex align-items-center package-star-rating mr-2">
				<app-rating-icon></app-rating-icon>
				<span class="profile-rating mt-1">
					{{
						packageInfo?.created_by_user?.average_rating
							| number: '1.1-1'
					}}
				</span>
			</div>
		</div>
		<div class="mx-3">
			<div
				class="package-list-title-wrapper mt-4 text-primary"
				[popover]="packageTitle"
				placement="auto"
				triggers="mouseenter:mouseleave click">
				{{ packageInfo?.title }}
			</div>
			<ng-template #packageTitle>
				<div [innerHtml]="packageInfo?.title"></div>
			</ng-template>
			<div class="package-list-content-wrapper mt-4">
				{{ packageInfo?.project_type_name }}
				-
				{{ packageInfo?.project_title_name }}
			</div>
			<div class="package-list-price-wrapper">
				<div
					class="d-flex justify-content-between align-items-baseline flex-wrap">
					<div class="d-flex align-items-baseline">
						<h3
							[ngClass]="
								packageInfo?.discounted_total != null &&
								packageInfo?.discounted_total !== 0
									? 'package-original-price-wrapper'
									: ''
							">
							${{ +packageInfo?.total || 0 | number: '1.0-2' }}
						</h3>
						<h3
							*ngIf="
								packageInfo?.discounted_total != null &&
								packageInfo?.discounted_total !== 0
							"
							class="discounted-price-wrapper text-primary">
							${{
								packageInfo['discounted_total'] || 0
									| number: '1.0-2'
							}}
						</h3>
					</div>
					<div
						class="discount-code-wrapper"
						*ngIf="
							packageInfo?.discounted_total != null &&
							packageInfo?.discounted_total !== 0
						">
						<div class="discount-content-container">
							<img
								src="../../../../{{ discountCouponBgImage }}"
								alt="" />
							<div class="discount-content-text-wrapper font-11">
								<span>
									{{ packageInfo?.discount | number: '1.0' }}%
									DISCOUNT
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="package-list-details-wrapper mt-3 mr-3"
				[innerHTML]="packageInfo?.details"
				[popover]="packageDetail"
				placement="auto"
				triggers="mouseenter:mouseleave click"></div>

			<ng-template #packageDetail>
				<div [innerHtml]="packageInfo?.details"></div>
			</ng-template>
			<div
				class="login-message-wrapper package-list-details-wrapper mt-3 text-primary mb-3 mr-3">
				Login or Sign Up to continue with this package
			</div>
			<span
				class="d-flex align-items-center justify-content-center mb-2 clickable"
				triggers="mouseenter:mouseleave click"
				placement="right"
				[popover]="satisfactionGuaranteedTooltip"
				(click)="$event.preventDefault(); $event.stopPropagation()">
				<img
					class="mr-2"
					src="../../../../{{ satisfactionGuaranteedImg }}"
					alt="" />
				<p class="satisfaction-content-wrapper mb-0">
					Satisfaction Guaranteed!
				</p>
			</span>
		</div>
	</div>
</div>
