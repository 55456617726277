<div class="wrapper">
	<app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
	<div class="main_wrapper">
		<div>
			<div>
				<div class="main-content-wrapper">
					<div class="card-main">
						<div class="card-body pt-2" *ngIf="valForm">
							<h1 class="signup-title-mobile-wrapper">Sign Up</h1>
							<accordion
								[isAnimated]="true"
								*ngIf="isMobileScreen">
								<accordion-group
									#packageDetailedInfo
									panelClass="mt-2 mb-1 border accordion signup-wrapper">
									<div accordion-heading>
										<span>
											<strong class="mobileview-package-wrapper">
												See your selected package
											</strong>
											<em
												class="float-right mt-1"
												[ngClass]="
													packageDetailedInfo.isOpen
														? 'fa fa-chevron-up'
														: 'fa fa-chevron-down'
												"></em>
										</span>
									</div>
									<div class="row">
										<div
											class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 px-2"
											*ngIf="packageId">
											<app-details-info
												[packageInfo]="
													packageInfo
												"></app-details-info>
										</div>
									</div>
								</accordion-group>
							</accordion>
							<div class="col-md-12">
								<div class="row">
									<div
										class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-3 p-2"
										*ngIf="packageId && !isMobileScreen">
										<app-details-info
											[packageInfo]="
												packageInfo
											"></app-details-info>
									</div>
									<div
										[ngClass]="
											packageId
												? 'col-lg-9 col-md-6'
												: 'col-lg-12 col-md-12'
										"
										class="col-12 col-lg-9 col-md-6 col-sm-12 col-xs-12 mt-3 p-2">
										<div
											class="pt-3 signup-content-wrapper">
											<h1 class="signup_title">
												Sign Up
											</h1>
											<div class="d-flex">
												<div class="d-flex">
													<span class="pr-2">
														<i
															class="fas fa-user-tie"></i>
													</span>
													<p class="text-start">
														Already have an account?
													</p>
												</div>
												<div>
													<a
														(click)="
															_analyticsService.setRouteData(
																1,
																3
															)
														"
														[queryParams]="{
															packageId: packageId
														}"
														[routerLink]="
															'/marketplace-login'
														"
														class="d-flex justify-content-center underline link-text pl-1">
														Login
													</a>
												</div>
											</div>
										</div>
										<form
											(submit)="
												submitForm(
													$event,
													valForm.value
												)
											"
											[formGroup]="valForm"
											class="form-validat form-main"
											name="registerForm"
											novalidate=""
											role="form">
											<div class="form-group">
												<label class="mb-1">Name</label>
												<span class="text-danger pl-1">
													*
												</span>
												<div
													class="input-group with-focus">
													<input
														autocomplete="off"
														class="form-control border-right-0"
														formControlName="name"
														name="name"
														placeholder="Name"
														required=""
														type="text" />
													<div
														class="input-group-append border-left">
														<span
															class="input-group-text text-muted bg-white">
															<em
																class="fas fa-user-tie label-icons text-primary"></em>
														</span>
													</div>
												</div>
												<div
													*ngIf="
														valForm.controls[
															'name'
														].hasError(
															'required'
														) &&
														(valForm.controls[
															'name'
														].dirty ||
															valForm.controls[
																'name'
															].touched)
													"
													class="text-danger">
													Required
												</div>
												<div
													*ngIf="
														(valForm.controls[
															'name'
														].hasError('url') ||
															valForm.controls[
																'name'
															].hasError(
																'email'
															)) &&
														(valForm.controls[
															'name'
														].dirty ||
															valForm.controls[
																'name'
															].touched)
													"
													class="text-danger">
													Please enter valid name
												</div>
											</div>
											<div class="form-group">
												<label class="mb-1">
													Business Name
												</label>
												<div
													class="input-group with-focus">
													<input
														autocomplete="off"
														class="form-control border-right-0"
														formControlName="business_name"
														name="business_name"
														placeholder="Business Name"
														type="text" />
													<div
														class="input-group-append border-left">
														<span
															class="input-group-text text-muted bg-white">
															<em
																class="fa fa-building label-icons text-primary"></em>
														</span>
													</div>
												</div>
											</div>
											<div class="form-group">
												<label class="mb-1">
													Email
												</label>
												<span class="text-danger pl-1">
													*
												</span>
												<div
													class="input-group with-focus">
													<input
														autocomplete="off"
														class="form-control border-right-0"
														formControlName="email"
														name="email"
														placeholder="Email"
														required=""
														type="email" />
													<div
														class="input-group-append border-left">
														<span
															class="input-group-text text-muted bg-white">
															<em
																class="fa fa-envelope label-icons text-primary"></em>
														</span>
													</div>
												</div>
												<div
													*ngIf="
														valForm.controls[
															'email'
														].hasError(
															'required'
														) &&
														(valForm.controls[
															'email'
														].dirty ||
															valForm.controls[
																'email'
															].touched)
													"
													class="text-danger">
													Required
												</div>
												<div
													*ngIf="
														valForm.controls[
															'email'
														].hasError('email') &&
														(valForm.controls[
															'email'
														].dirty ||
															valForm.controls[
																'email'
															].touched)
													"
													class="text-danger">
													This field must be a valid
													email address
												</div>
											</div>
											<div class="form-group">
												<label class="mb-1">
													Phone
												</label>
												<div
													class="input-group with-focus">
													<input
														autocomplete="off"
														class="form-control border-right-0"
														formControlName="phone"
														name="phone"
														placeholder="Phone"
														type="number" />
													<div
														class="input-group-append border-left">
														<span
															class="input-group-text text-muted bg-white">
															<em
																class="fa fa-phone label-icons text-primary"></em>
														</span>
													</div>
												</div>
												<div
													*ngIf="
														valForm.controls[
															'phone'
														].hasError('pattern') &&
														(valForm.controls[
															'phone'
														].dirty ||
															valForm.controls[
																'phone'
															].touched)
													"
													class="text-danger">
													Please enter a valid phone
													number.
												</div>
											</div>
											<div class="form-group">
												<label class="mb-1">
													How did you hear of us?
												</label>
												<div
													class="input-group with-focus">
													<input
														class="form-control border-right-0"
														formControlName="lead_source"
														name="lead_source"
														placeholder="How did you hear of us?" />
													<div
														class="input-group-append border-left">
														<span
															class="input-group-text text-muted bg-white">
															<em
																class="fas fa-comment-dots label-icons text-primary"></em>
														</span>
													</div>
												</div>
											</div>
											<div formGroupName="passwordGroup">
												<div class="form-group">
													<label class="mb-1">
														Password
													</label>
													<span
														class="text-danger pl-1">
														*
													</span>
													<div
														class="input-group with-focus">
														<input
															[formControl]="
																valForm.get(
																	'passwordGroup.password'
																)
															"
															class="form-control border-right-0"
															formControlName="password"
															name="password"
															placeholder="Password"
															type="password" />
														<div
															class="input-group-append border-left">
															<span
																class="input-group-text text-muted bg-white">
																<em
																	class="fa fa-key label-icons text-primary"></em>
															</span>
														</div>
													</div>
													<div
														*ngIf="
															valForm
																.get(
																	'passwordGroup.password'
																)
																.hasError(
																	'required'
																) &&
															(valForm.get(
																'passwordGroup.password'
															).dirty ||
																valForm.get(
																	'passwordGroup.password'
																).touched)
														"
														class="text-danger">
														Required
													</div>
													<div
														*ngIf="
															valForm
																.get(
																	'passwordGroup.password'
																)
																.hasError(
																	'minlength'
																) &&
															(valForm.get(
																'passwordGroup.password'
															).dirty ||
																valForm.get(
																	'passwordGroup.password'
																).touched)
														"
														class="text-danger">
														Please enter min 8
														characters.
													</div>
													<div
														*ngIf="
															valForm
																.get(
																	'passwordGroup.password'
																)
																.hasError(
																	'maxlength'
																) &&
															(valForm.get(
																'passwordGroup.password'
															).dirty ||
																valForm.get(
																	'passwordGroup.password'
																).touched)
														"
														class="text-danger">
														Password can't be
														greater than 16
														characters.
													</div>
												</div>
												<div class="form-group">
													<label class="mb-1">
														Confirm password
													</label>
													<span
														class="text-danger pl-1">
														*
													</span>
													<div
														class="input-group with-focus">
														<input
															[formControl]="
																valForm.get(
																	'passwordGroup.confirm_password'
																)
															"
															class="form-control border-right-0"
															formControlName="confirm_password"
															name="confirm_password"
															placeholder="Password confirmation"
															type="password" />
														<div
															class="input-group-append border-left">
															<span
																class="input-group-text text-muted bg-white">
																<em
																	class="fa fa-key label-icons text-primary"></em>
															</span>
														</div>
													</div>
													<div
														*ngIf="
															valForm
																.get(
																	'passwordGroup.confirm_password'
																)
																.hasError(
																	'required'
																) &&
															(valForm.get(
																'passwordGroup.confirm_password'
															).dirty ||
																valForm.get(
																	'passwordGroup.confirm_password'
																).touched)
														"
														class="text-danger">
														Required
													</div>
													<div
														*ngIf="
															valForm
																.get(
																	'passwordGroup.confirm_password'
																)
																.hasError(
																	'equalTo'
																)
														"
														class="text-danger">
														Your passwords do not
														match
													</div>
												</div>
											</div>
											<div
												class="signup-action-wrapper pt-3">
												<div>
													<div
														class="checkbox c-checkbox float-left mt0 clearfix d-flex">
														<label
															class="text-dark">
															<input
																formControlName="agree_terms"
																name="agree_terms"
																required=""
																type="checkbox" />
															<span
																class="fa fa-check"></span>
														</label>
														<p>
															I accept the
															<a
																href="https://flocksy.com/terms-of-service"
																class="underline-color"
																target="_blank">
																Terms and
																Conditions
															</a>
														</p>
													</div>
													<p
														*ngIf="
															valForm.controls[
																'agree_terms'
															].hasError(
																'required'
															) &&
															(valForm.controls[
																'agree_terms'
															].dirty ||
																valForm
																	.controls[
																	'agree_terms'
																].touched)
														"
														class="text-danger">
														Please accept terms
													</p>
												</div>
												<div
													class="btn-account-wrapper">
													<button
														[disabled]="
															!valForm.valid
														"
														class="btn btn-labeled btn-account mt-3 mb-2"
														type="submit"
														(click)="
														this._analyticsService.setRouteData(
															80,
															107
														)
													">
														<span class="pl-3">
															<i
																class="far fa-circle icon-size"></i>
														</span>
														<span
															class="btn-text-size pr-3 pl-2">
															Create Account
														</span>
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
								<!-- END card-->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
