<!-- START Sidebar (left)-->
<div
	class="aside-inner sidebar-info-wrapper"
	*ngIf="!isClientUser && !isSubClientUser">
	<nav
		[class.show-scrollbar]="settings.getLayoutSetting('asideScrollbar')"
		class="sidebar pb-0 mr-0 overflow"
		sidebar-anyclick-close="">
		<!-- <a class="nav-link clickable text-right">
			<em class="fas fa-bell text-dark"></em>
		</a>
		<app-userblock></app-userblock> -->
		<!-- START sidebar nav-->
		<ul class="sidebar-nav pb-3">
			<li class="has-user-block">
				<app-userblock></app-userblock>
			</li>
			<li
				class="sidebar-item-wrapper"
				*ngFor="let oMenu of aMenuItems"
				id="{{ oMenu?.id }}"
				[routerLinkActive]="['active']"
				[class.custom_active]="
					oMenu?.extraActiveUrls &&
					oMenu?.extraActiveUrls?.indexOf(router.url) > -1
				">
				<a
					class="my-1"
					(click)="
						oMenu.pagePlacementId
							? _analyticsService.setRouteData(
									oMenu.pagePlacementId[0],
									oMenu.pagePlacementId[1]
							  )
							: ''
					"
					*ngIf="!oMenu.static && !oMenu.creativeLevelId"
					[attr.route]="oMenu.url"
					[routerLinkActive]="['active']"
					[routerLink]="'/' + oMenu.url"
					[class.custom_active]="
						oMenu?.extraActiveUrls &&
						oMenu?.extraActiveUrls?.indexOf(router.url) > -1
					">
					<em class="{{ oMenu?.icon }}"></em>
					<span (click)="clearunreadCount($event, oMenu.text)">
						{{ oMenu.text }}
						<span
							*ngIf="oMenu?.data?.unread_comments > 0"
							class="badge badge-danger sidebar_unread_count">
							{{ oMenu?.data.unread_comments }}
						</span>
						<span
							*ngIf="
								isEligibleRealTime &&
								router.url != '/messages' &&
								oMenu?.data?.unread_count > 0
							"
							class="badge badge-danger sidebar_unread_count">
							{{ oMenu?.data.unread_count }}
						</span>
						<span
							*ngIf="
								!isEligibleRealTime &&
								oMenu?.data?.unread_count > 0
							"
							class="badge badge-danger sidebar_unread_count">
							{{ oMenu?.data.unread_count }}
						</span>
						<span
							*ngIf="oMenu?.data?.notify_text"
							class="badge badge-danger sidebar_unread_count">
							{{ oMenu?.data.notify_text }}
						</span>
					</span>
				</a>

				<a
					(click)="
						oMenu.pagePlacementId
							? _analyticsService.setRouteData(
									oMenu.pagePlacementId[0],
									oMenu.pagePlacementId[1]
							  )
							: ''
					"
					*ngIf="
						!oMenu.static && oMenu.creativeLevelId && isPlatinumLead
					"
					[attr.route]="oMenu.url"
					[routerLinkActive]="['active']"
					[routerLink]="'/' + oMenu.url"
					[class.custom_active]="
						oMenu?.extraActiveUrls &&
						oMenu?.extraActiveUrls?.indexOf(router.url) > -1
					">
					<em class="{{ oMenu?.icon }}"></em>
					<span>{{ oMenu.text }}</span>
				</a>

				<a *ngIf="oMenu.static" [href]="oMenu.url" target="_blank">
					<em class="{{ oMenu?.icon }}"></em>
					<span>{{ oMenu.text }}</span>
				</a>
			</li>
		</ul>

		<!-- <ul class="px-3 mx-3 bt bb">
			<a
				href="javascript:void(0);"
				class="tutorial-btn-wrapper font-18"
				type="button"
				(click)="
					startIntroJs();
					_analyticsService.setOverlayTrackData(16, 61)
				"
				*ngIf="isOperationalClientUser">
				<span class="text-underline">Tutorial</span>
			</a>
		</ul> -->
		<!-- <div class="px-3">
			<a
				href="javascript:void(0);"
				class="btn earn-free-month-btn"
				type="button">
				Earn a FREE Month
			</a>
		</div>
		<div>
			<a
				#feedbackBtnDesktop
				id="header-feedback-btn"
				class="nav-link text-dark text-lg font-18 text-center"
				href="javascript:">
				<span>
					<em class="fas fa-bullhorn mr-1"></em>
					Give Feedback
				</span>
			</a>
		</div> -->

		<a
			class="ad-management-card d-flex align-items-center justify-content-between p-2 add-on"
			*ngIf="isOperationalClientUser && !isWhiteLabelledUser"
			href=" https://flocksy.com/finch-flocksy/"
			target="_blank"
			(click)="adButtonClick()">
			<em class="fas fa-tasks"></em>
			<div>
				<p class="mb-0">Add-on:</p>
				<p class="mb-0">PPC Management</p>
			</div>
		</a>
		<div
			class="card shadow bg-white rounded mt-4 ml-3 mr-3"
			*ngIf="isOperationalClientUser">
			<div class="card-body text-center">
				<div class="card-text font-weight-bold">
					<span class="mr-2">Issues or questions?</span>
					<span
						class="fa fa-phone fa-flip-horizontal"
						style="color: #16cc8e"
						aria-hidden="true"></span>
				</div>
				<div class="card-subtext" style="color: #16cc8e">
					<p class="font-weight-bold">(877) 263-7389</p>
				</div>
			</div>
		</div>

		<!-- END sidebar nav-->
	</nav>

	<footer app-footer class="footer"></footer>
</div>

<div class="aside-inner" *ngIf="isClientUser || isSubClientUser">
	<app-client-sidebar
		[totalUnreadCount]="totalUnreadCount"></app-client-sidebar>
</div>
<!-- END Sidebar (left)-->
