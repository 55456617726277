import { NgModule } from '@angular/core';
import { PersonalMessageDetailviewComponent } from './personal-message.detailview.component';
import { CommonModule } from '@angular/common';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { NewLinePipeModule } from '@shared/pipes/new-line/new-line.pipe.module';
import { MessageService } from '@services/message/message.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { RouterModule } from '@angular/router';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { AngularResizedEventModule } from 'angular-resize-event';
import { InnerHtmlPipeModule } from '@shared/pipes/innerhtml/innerhtml.pipe.module';
import { AccordionModule } from 'ngx-bootstrap';
import { ProjectDetailService } from '@services/project/project-detail.service';
import { LinkifyHtmlModule } from '@shared/pipes/linkify-html/linkify-html.pipe.module';
import { NgxSummernoteModule } from 'ngx-summernote';
import { SharedModule } from '@shared/shared.module';
import { ReportConcernModule } from '../../report-concern/report-concern.module';
import { ProjectBriefModule } from '@shared/module/project-brief/project-brief.module';
import { PdfViewerModuleModule } from '../../pdf-viewer/pdf-viewer.module';
import { VideojsModule } from '../../videojs/videojs.module';
import { NgxSelectModule } from 'ngx-select-ex';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TruncateModule } from '@shared/pipes/truncate/truncate.module';
import { CreativeNotesService } from '@services/creative/client/notes.service';
import { UserNoteService } from '@services/user/notes/notes.service';
import { ProjectNotesService } from '@services/project/notes/notes.service';
import { EmojiReactionModule } from '../../emoji-reaction/emoji-reaction.module';
import { EmojiReactionService } from '@services/emoji-reaction/emoji-reaction.service';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		NgxLinkifyjsModule,
		NewLinePipeModule,
		PdfViewerModuleModule,
		FormsModule,
		NgxSelectModule,
		ReactiveFormsModule,
		NgxAutoScrollModule,
		RouterModule,
		TruncateModule,
		VideojsModule,
		TextareaAutosizeModule,
		AngularResizedEventModule,
		InnerHtmlPipeModule,
		AccordionModule,
		LinkifyHtmlModule,
		NgxSummernoteModule,
		ReportConcernModule,
		ProjectBriefModule,
		PickerModule,
		EmojiReactionModule,
	],
	exports: [PersonalMessageDetailviewComponent],
	declarations: [PersonalMessageDetailviewComponent],
	providers: [
		MessageService,
		ProjectDetailService,
		UserNoteService,
		CreativeNotesService,
		ProjectNotesService,
		EmojiReactionService,
	],
})
export class PersonalMessageDetailviewModule {}
