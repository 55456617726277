import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ValidationResult } from './validation-result';
import { isString } from 'util';

export class FlocksyValidator {
	/* custom validation for the confirm password */
	static passwordConfirming(c: AbstractControl): ValidationResult {
		let validationResult: ValidationResult;
		if (c.get('password') !== null && c.get('confirm_password') !== null) {
			validationResult =
				c.get('password').value === ''
					? { passwordRequired: true }
					: null;
			if (validationResult === null)
				validationResult =
					c.get('password').value !== c.get('confirm_password').value
						? { misMatchConfirmPassword: true }
						: null;
		}
		return validationResult;
	}

	static equalTo(controlName: string, matchingControlName: string) {
		return (formGroup: FormGroup) => {
			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];

			if (matchingControl.errors && !matchingControl.errors.mustMatch) {
				// return if another validator has already found an error on the matchingControl
				return;
			}

			// set error on matchingControl if validation fails
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({ equalTo: true });
			} else {
				matchingControl.setErrors(null);
			}
		};
	}

	static required(control: FormControl): ValidationResult {
		return control.value == null ||
			(isString(control.value) && control.value.trim() == '')
			? { required: true }
			: null;
	}

	static email(control: FormControl): ValidationResult {
		let EMAIL_REGEXP =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

		if (control.value != '' && !EMAIL_REGEXP.test(control.value))
			return { email: true };
		return null;
	}

	static notUrl(control: FormControl): ValidationResult {
		let URL_REGEXP = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i'
		); // fragment locator
		if (
			control.value &&
			control.value != '' &&
			URL_REGEXP.test(control.value.trim())
		)
			return { url: true };
		return null;
	}

	static notEmail(control: FormControl): ValidationResult {
		let EMAIL_REGEXP =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
		if (
			control.value &&
			control.value != '' &&
			EMAIL_REGEXP.test(control.value.trim())
		)
			return { email: true };
		return null;
	}

	static minLengthArray(min: number) {
		return (c: AbstractControl): { [key: string]: any } => {
			if (c.value.length >= min) return null;

			return { minLengthArray: { valid: false } };
		};
	}

	static minLengthCheckbox(min: number) {
		return (c: AbstractControl): { [key: string]: any } => {
			let valid = true;
			let count_valid = 0;
			for (let value of c.value) {
				if (value['value']) {
					count_valid++;
				}
			}
			if (count_valid >= min) {
				return null;
			}
			valid = false;
			return { minLengthCheckbox: { valid: valid } };
		};
	}
}
