export enum CATEGORIES_IDS {
	GRAPHIC_DESIGN = 1,
	WEBSITE_DEVELOPMENT = 2,
	COPYWRITING = 3,
	VIDEO_EDITING = 5,
	CUSTOM_ILLUSTRATIONS = 9,
	VOICE_OVERS = 10,
	MOTION_GRAPHICS = 19,
	VIRTUAL_ASSISTANTS = 21,
}
