import { Component, Input, OnInit } from '@angular/core';
import { FlocksyModalFooterData } from '@shared/module/flocksy-modal/interface';

@Component({
  selector: 'app-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss']
})
export class ModalFooterComponent implements OnInit {
  @Input() data: FlocksyModalFooterData;

  constructor() { }

  ngOnInit(): void {
  }

}
