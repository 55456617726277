import { Injectable } from '@angular/core';
import { CustomHttpService } from '../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class SignupService {
	constructor(private _customHttpService: CustomHttpService) {}

	storePM(oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/register/auth',
			oData
		);
	}

	register(oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/register',
			oData
		);
	}

	registerAsDesigner(oData: object): Observable<any> {
		return this._customHttpService.postFormData(
			API_CONSTANTS.API_ENDPOINT_URL + '/register',
			oData
		);
	}

	signupPaymentUser(oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/register_payment',
			oData
		);
	}

	resetPasswordUser(oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/get/reset/token',
			oData
		);
	}

	checkResetToken(token: string): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/password/reset/' + token
		);
	}

	storeResetToken(oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/reset/password',
			oData
		);
	}

	showToken(token): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/invite/token/' + token
		);
	}

	inviteSignup(data: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/invite/signup',
			data
		);
	}
}
