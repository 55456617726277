<div class="wrapper">
	<app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
	<div class="main-wrapper" *ngIf="oToken">
		<h1
			class="login_title pl-3 pr-2"
			*ngIf="oToken && oToken['data'] && oToken.retrieved">
			You have been invited to signup on {{isWhiteLabelledUser ? whiteLabelledUser?.business_name : 'Flocksy'}} by
			{{ oToken['data']['created_by_name'] }}
		</h1>
		<!-- START card-->
		<!-- <div class="text-center">
            <a href="https://flocksy.com" target="_blank">
                <img alt="Logo" class="block-center mb-3 width-50" src="assets/img/flocksy-logo.png"/>
            </a>
        </div> -->
		<div class="card-main">
			<div *ngIf="oToken" class="card-body">
				<!-- <h5 *ngIf="oToken && oToken['data'] && oToken.retrieved" class="text-center text-primary">
                    You have been invited to signup on flocksy by {{ oToken['data']['created_by_name'] }}.
                </h5> -->
				<div *ngIf="oError">
					<alert
						*ngIf="oError?.status"
						dismissible="true"
						type="danger">
						{{ oError.error.message }}
					</alert>
				</div>
				<form
					(submit)="submitForm($event, valForm.value)"
					*ngIf="valForm"
					[formGroup]="valForm"
					class="form-validate mb-3 form-main"
					name="inviteSignupForm"
					novalidate=""
					role="form">
					<div class="form-group">
						<label class="mb-1">Name</label>
						<span class="text-danger pl-1">*</span>
						<div class="input-group with-focus">
							<input
								autocomplete="off"
								class="form-control border-right-0"
								formControlName="name"
								name="name"
								placeholder="Name"
								required=""
								type="text" />
							<div class="input-group-append border-left">
								<span class="input-group-text bg-white">
									<em
										class="fas fa-user-tie label-icons"></em>
								</span>
							</div>
						</div>
						<div
							*ngIf="
								valForm.controls['name'].hasError('required') &&
								(valForm.controls['name'].dirty ||
									valForm.controls['name'].touched)
							"
							class="text-danger">
							Required
						</div>
					</div>
					<div class="form-group">
						<label class="mb-1">Email</label>
						<span class="text-danger pl-1">*</span>
						<div class="input-group with-focus">
							<input
								autocomplete="off"
								class="form-control border-right-0"
								formControlName="email"
								name="email"
								placeholder="Email"
								required=""
								type="email" />
							<div class="input-group-append border-left">
								<span class="input-group-text bg-white">
									<em class="fa fa-envelope label-icons"></em>
								</span>
							</div>
						</div>
						<div
							*ngIf="
								valForm.controls['email'].hasError(
									'required'
								) &&
								(valForm.controls['email'].dirty ||
									valForm.controls['email'].touched)
							"
							class="text-danger">
							Required
						</div>
						<div
							*ngIf="
								valForm.controls['email'].hasError('email') &&
								(valForm.controls['email'].dirty ||
									valForm.controls['email'].touched)
							"
							class="text-danger">
							This field must be a valid email address
						</div>
					</div>
					<div class="form-group">
						<label class="mb-1">Phone</label>
						<div class="input-group with-focus">
							<input
								autocomplete="off"
								class="form-control border-right-0"
								formControlName="phone"
								name="phone"
								placeholder="Phone"
								type="number" />
							<div class="input-group-append border-left">
								<span class="input-group-text bg-white">
									<em class="fa fa-phone label-icons"></em>
								</span>
							</div>
						</div>
					</div>
					<div formGroupName="passwordGroup">
						<div class="form-group">
							<label class="mb-1">Password</label>
							<span class="text-danger pl-1">*</span>
							<div class="input-group with-focus">
								<input
									[formControl]="
										valForm.get('passwordGroup.password')
									"
									class="form-control border-right-0"
									formControlName="password"
									name="password"
									placeholder="Password"
									type="password" />
								<div class="input-group-append border-left">
									<span class="input-group-text bg-white">
										<em class="fa fa-key label-icons"></em>
									</span>
								</div>
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.password')
										.hasError('required') &&
									(valForm.get('passwordGroup.password')
										.dirty ||
										valForm.get('passwordGroup.password')
											.touched)
								"
								class="text-danger">
								Required
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.password')
										.hasError('minlength') &&
									(valForm.get('passwordGroup.password')
										.dirty ||
										valForm.get('passwordGroup.password')
											.touched)
								"
								class="text-danger">
								Please enter min 8 characters.
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.password')
										.hasError('maxlength') &&
									(valForm.get('passwordGroup.password')
										.dirty ||
										valForm.get('passwordGroup.password')
											.touched)
								"
								class="text-danger">
								Password can't be greater than 16 characters.
							</div>
						</div>
						<div class="form-group">
							<label class="mb-1">Password confirmation</label>
							<span class="text-danger pl-1">*</span>
							<div class="input-group with-focus">
								<input
									[formControl]="
										valForm.get(
											'passwordGroup.confirm_password'
										)
									"
									class="form-control border-right-0"
									formControlName="confirm_password"
									name="confirm_password"
									placeholder="Password confirmation"
									type="password" />
								<div class="input-group-append border-left">
									<span class="input-group-text bg-white">
										<em class="fa fa-key label-icons"></em>
									</span>
								</div>
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.confirm_password')
										.hasError('required') &&
									(valForm.get(
										'passwordGroup.confirm_password'
									).dirty ||
										valForm.get(
											'passwordGroup.confirm_password'
										).touched)
								"
								class="text-danger">
								Required
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.confirm_password')
										.hasError('equalTo')
								"
								class="text-danger">
								Password does Not match
							</div>
						</div>
					</div>
					<div
						class="d-flex justify-content-between align-items-center pt-3 flex-wrap">
						<div *ngIf="!isWhiteLabelledUser" class="mt0 d-flex align-item-center flex-column">
							<div class="checkbox c-checkbox float-left">
								<label class="d-flex text-dark">
									<input
										formControlName="agree_terms"
										name="agree_terms"
										required=""
										type="checkbox" />
									<span class="fa fa-check"></span>
									I accept the
									<a
										href="https://flocksy.com/terms-of-service"
										class="underline pl-1"
										target="_blank">
										Terms and Conditions
									</a>
								</label>
							</div>
							<span
								class="text-danger"
								*ngIf="
									valForm.controls['agree_terms'].hasError(
										'required'
									) &&
									(valForm.controls['agree_terms'].dirty ||
										valForm.controls['agree_terms'].touched)
								">
								Please accept terms
							</span>
						</div>

						<button
							[disabled]="!valForm.valid"
							class="btn btn-labeled btn-primary mb-2 d-flex justify-content-between align-items-center"
							type="submit">
							<span class="btn-label">
								<i class="far fa-check-circle"></i>
							</span>
							Create An Account
						</button>
					</div>
				</form>
				<!-- <div class="alert alert-danger text-center"></div> -->
				<div class="d-flex justify-content-center align-items-center">
					<span class="pr-2"><em class="fas fa-user-tie"></em></span>
					<p class="pt-2 text-center text-account">
						Already have an account?
					</p>
				</div>

				<a
					[routerLink]="'/login'"
					class="d-flex justify-content-center underline link-text">
					Login
				</a>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!isWhiteLabelledUser" class="p-3 footer">
	<span>&copy;</span>
	<span>{{ _settingsService.getAppSetting('year') }}</span>
	<span class="mx-2">-</span>
	<span>{{ _settingsService.getAppSetting('name') }}</span>
</div>
