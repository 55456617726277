<ng-template #updateLocationInfoModal>
	<div class="modal-header">
		<h4 class="modal-title">Update Location Information</h4>
	</div>
	<div class="modal-body">
		<div class="container container-md" *ngIf="valForm">
			<h4>Hi {{ _localStorageService.getUserFullName() }},</h4>
			<p>Please update your country and state information.</p>
			<form
				[formGroup]="valForm"
				class="form-validate mb-3"
				role="form"
				name="locationForm"
				novalidate=""
				(submit)="submitForm($event)">
				<div class="form-group">
					<label class="text-muted mb-1">Country</label>
					<ngx-select
						[items]="aCountries"
						placeholder="Select Country"
						formControlName="country"
						optionValueField="id"
						(select)="onChangeCountry($event)"></ngx-select>
					<div
						class="text-danger"
						*ngIf="
							valForm.controls['country'].hasError('required') &&
							(valForm.controls['country'].dirty ||
								valForm.controls['country'].touched)
						">
						Required
					</div>
				</div>
				<div
					class="form-group"
					*ngIf="_localStorageService.isDesignerUser()">
					<label class="text-muted mb-1">State</label>
					<ngx-select
						[items]="aStates"
						placeholder="Select State"
						formControlName="state"
						optionValueField="id"
						optionTextField="text"></ngx-select>
					<div
						class="text-danger"
						*ngIf="
							valForm.controls['state'].hasError('required') &&
							(valForm.controls['state'].dirty ||
								valForm.controls['state'].touched)
						">
						Required
					</div>
				</div>
				<button
					class="btn btn-primary mt-3"
					type="submit"
					[disabled]="!valForm.valid">
					Update
				</button>
			</form>
		</div>
	</div>
</ng-template>
