import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class UserClientService {
	constructor(private _customHttpService: CustomHttpService) {}

	destroyCreative(id, creative_id, oParams): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}

		return this._customHttpService.delete(
			API_CONSTANTS.USERS_URL +
				'/' +
				id +
				'/creatives/' +
				creative_id +
				'?' +
				params.toString()
		);
	}

	removeClient(user_id, client_id, oParams): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}

		return this._customHttpService.delete(
			API_CONSTANTS.USERS_URL +
				'/creatives/' +
				user_id +
				'/clients/' +
				client_id +
				'?' +
				params.toString()
		);
	}
}
