import { Pipe, PipeTransform } from '@angular/core';

/*
 * Converts newlines into html breaks
 */
@Pipe({ name: 'innerhtml' })
export class InnerHtmlPipe implements PipeTransform {
	transform(value: string, args?: string[]): any {
		var txt = document.createElement('textarea');
		txt.innerHTML = value;
		return txt.value;
	}
}
