<div [ngClass]="data?.wrapperClass" class="modal-header">
	<div class="d-flex align-items-center justify-content-between">
		<div class="d-flex align-items-center">
			<ng-container *ngIf="data?.iconSrc">
				<img class="mr-2" alt="" [src]="data.iconSrc"/>
			</ng-container>
			<h3 [ngClass]="data?.titleClass" class="mb-0 mt-1">{{data?.title ? data?.title : 'Title'}}</h3>
		</div>
		<div>
			<ng-content></ng-content>
		</div>
	</div>
	<ng-container *ngIf="config?.showClose">
		<button (click)="_bsModalRef.hide()" class="btn" aria-label="Close">
			<img width="16" height="16" src="assets/img/close-grey.svg" alt="Close">
		</button>
	</ng-container>
</div>