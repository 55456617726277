<div class="wrapper">
	<div class="block-center mt-4 wd-xl">
		<!-- START card-->
		<div class="text-center">
			<a href="https://flocksy.com" target="_blank">
				<img
					alt="Logo"
					class="block-center mb-3 width-50"
					[src]="isWhiteLabelledUser ? whiteLabelledUser.logo_document.full_path:'assets/img/flocksy-logo.png'" />
			</a>
		</div>
		<div class="card card-flat">
			<div class="card-body">
				<p class="text-center py-2">NEW PASSWORD</p>
				<div *ngIf="oError">
					<alert
						*ngIf="oError?.status"
						dismissible="true"
						type="danger">
						{{ oError.error.message }}
					</alert>
				</div>
				<form
					(submit)="submitForm($event, valForm.value)"
					*ngIf="valForm"
					[formGroup]="valForm"
					class="form-validate mb-3"
					name="recoverPasswordForm"
					novalidate=""
					role="form">
					<div formGroupName="passwordGroup">
						<div class="form-group">
							<label class="text-muted mb-1">New Password</label>
							<div class="input-group with-focus">
								<input
									[formControl]="
										valForm.get('passwordGroup.password')
									"
									class="form-control border-right-0"
									formControlName="password"
									name="password"
									placeholder="Password"
									type="password" />
								<div class="input-group-append">
									<span
										class="input-group-text text-muted bg-transparent border-left-0">
										<em class="fa fa-lock"></em>
									</span>
								</div>
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.password')
										.hasError('required') &&
									(valForm.get('passwordGroup.password')
										.dirty ||
										valForm.get('passwordGroup.password')
											.touched)
								"
								class="text-danger">
								Required
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.password')
										.hasError('minlength') &&
									(valForm.get('passwordGroup.password')
										.dirty ||
										valForm.get('passwordGroup.password')
											.touched)
								"
								class="text-danger">
								Please enter min 8 characters.
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.password')
										.hasError('maxlength') &&
									(valForm.get('passwordGroup.password')
										.dirty ||
										valForm.get('passwordGroup.password')
											.touched)
								"
								class="text-danger">
								Password can't be greater than 16 characters.
							</div>
						</div>
						<div class="form-group">
							<label class="text-muted mb-1">
								New Password confirmation
							</label>
							<div class="input-group with-focus">
								<input
									[formControl]="
										valForm.get(
											'passwordGroup.confirm_password'
										)
									"
									class="form-control border-right-0"
									formControlName="confirm_password"
									name="confirm_password"
									placeholder="Password confirmation"
									type="password" />
								<div class="input-group-append">
									<span
										class="input-group-text text-muted bg-transparent border-left-0">
										<em class="fa fa-lock"></em>
									</span>
								</div>
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.confirm_password')
										.hasError('required') &&
									(valForm.get(
										'passwordGroup.confirm_password'
									).dirty ||
										valForm.get(
											'passwordGroup.confirm_password'
										).touched)
								"
								class="text-danger">
								Required
							</div>
							<div
								*ngIf="
									valForm
										.get('passwordGroup.confirm_password')
										.hasError('equalTo')
								"
								class="text-danger">
								Password does Not match
							</div>
						</div>
					</div>
					<button
						[disabled]="!valForm.valid"
						class="btn btn-block btn-primary mt-3"
						type="submit">
						Change Password
					</button>
				</form>
				<div *ngIf="serverMessage" class="mt-3">
					<div *ngIf="serverStatusCode == 200">
						<div class="alert alert-success">
							{{ serverMessage }}
						</div>
					</div>
					<div *ngIf="serverStatusCode != 200">
						<div class="alert alert-danger">
							{{ serverMessage }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- END card-->
		<div *ngIf="!isWhiteLabelledUser" class="p-3 text-center">
			<span>&copy;</span>
			<span>{{ _settingsService.getAppSetting('year') }}</span>
			<span class="mx-2">-</span>
			<span>{{ _settingsService.getAppSetting('name') }}</span>
			<br />
			<span>{{ _settingsService.getAppSetting('description') }}</span>
		</div>
	</div>
</div>
