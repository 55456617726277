import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable({
	providedIn: 'root'
})
export class ProjectChatService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, page: number = 1): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/chats?page=' + page
		);
	}

	index_old(id, offset: number, limit: number): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/project/chat?project_id=' +
				id +
				'&offset=' +
				offset +
				'&limit=' +
				limit
		);
	}

	store(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/chats',
			oData
		);
		/* return this._customHttpService.post(API_CONSTANTS.API_ENDPOINT_URL + '/project/chat', oData); */
	}

	show(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECT_CHATS_URL + '/' + id
		);
	}

	destroy_old(oData: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/project/chat/delete',
			oData
		);
	}

	destroy(id: any, chat_id): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/chats/' + chat_id
		);
	}

	getCreativeRules(project_type_id: any, oParams?: any): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${
				API_CONSTANTS.PROJECT_TYPES_URL
			}/${project_type_id}/onboarding/checklists${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	sendFeedback(projectId: any, chatId: any, oData: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				projectId +
				'/chats/' +
				chatId +
				'/feedback',
			oData
		);
	}

	markAdditionalWork(id, oData): Observable<any> {
		return this._customHttpService.put(
			`${API_CONSTANTS.PROJECTS_URL}/${id}/mark-additional-work`,
			oData
		);
	}

	getProjectCount(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/completed/count'
		);
	}
	getUnassignedChats(project_id, page, groups?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in groups) {
			params.set('group[' + key + ']', groups[key]);
		}
		if (page) {
			params.set('page', page);
		}

		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				project_id +
				'/chats/unassigned' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	markAsRead(oData): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.PROJECT_CHATS_URL + '/mark-as-read',
			oData
		);
	}

	createEmojiReactionUrl(projectId:any, chatId:any) {
		return `${API_CONSTANTS.PROJECTS_URL}/${projectId}/chats/${chatId}/emoji-reactions`;
	}
}
