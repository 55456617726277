import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { LocalStorageService } from '../../../services/localstorage.service';
import { TeamService } from '../../../services/team/team.service';
declare var window;

@Component({
	selector: 'app-hire-designer',
	templateUrl: './hire-designer.component.html',
	styleUrls: ['./hire-designer.component.scss'],
})
export class HireDesignerComponent implements OnInit {
	@Input() user_id: number;
	@Input() profilePath: string;
	@Input() projectTypeId;
	@Input() projectType;
	@Input() trigger_id;
	@Input() showCoverImage = true;
	@Output() emitCloseModal = new EventEmitter();
	oDesignerForClient: any = {};
	features = [];
	plans = [];
	planCost = 995;
	billingPeriod = 'month';
	isAvailableForHire = false;
	isHired = false;
	isAvailableForWaitlist = false;
	isDesignerWaitListJoined = false;
	aDisabledBtn = false;

	constructor(
		public _teamService: TeamService,
		public _toasterService: ToasterService,
		public _bsModalService: BsModalService,
		public _analyticsService: AnalyticsService,
		public _localStorageService: LocalStorageService
	) {}

	ngOnInit() {
		this.retrieveDesignerProfileForClient();
		this.features = [
			'Work directly with this team member in a dedicated Slack channel.',
			`Up to two hours per day on your ${this.projectType} tasks.`,
			'Fast direct communication.',
			'Project Management support.',
		];
		this.plans = [
			'Video Editing & Creation - <b>$995</b>',
			'Graphic Design - <b>$995</b>',
			'Copywriting - <b>$1,195</b>',
			'Web Development - <b>$1,495</b>',
		];
	}

	init() {}

	ngOnChanges(changes: SimpleChanges) {}

	retrieveDesignerProfileForClient() {
		this.getDesignerProfileForClient().subscribe((data) => {
			this.oDesignerForClient = data.data;
			if (this.oDesignerForClient.hires) {
				this.isHired = this.oDesignerForClient.hires.is_hired;
				this.isAvailableForHire =
					this.oDesignerForClient.hires.is_eligible_hire_team_member;
				this.isAvailableForWaitlist =
					this.oDesignerForClient.hires.is_hire_join_waitlist;
				this.isDesignerWaitListJoined =
					this.oDesignerForClient.hires.is_hired_join_waitlist;
			}
			if (!this.profilePath) {
				if ('profile' in this.oDesignerForClient) {
					this.profilePath =
						this.oDesignerForClient['profile']['profile_path'];
				}
			}
		});
	}

	getDesignerProfileForClient() {
		return this._teamService.getDesignerProfileForClient(
			this._localStorageService.getUserId(),
			this.user_id
		);
	}

	hireOrJoinWaitlist(type) {
		if (!this.trigger_id || !type || !this.projectTypeId) {
			return;
		}
		const data = {
			project_type_id: this.projectTypeId,
			trigger_id: this.trigger_id, // 1. Create Project, 2. Team
			type: type, // 1. Hire Request, 2. Join Request 3. Request Info
		};
		this.aDisabledBtn = true;
		this._teamService
			.hireDesigner(
				this._localStorageService.getUserId(),
				this.user_id,
				data
			)
			.subscribe(
				(res) => {
					if (type == 3) {
						this._toasterService.pop(
							'success',
							'Thanks for your interest in hiring a team member. We’ll be in touch shortly.'
						);
					} else {
						this._toasterService.pop(
							'success',
							'Your request has been submitted.'
						);
					}
					this.emitCloseModal.emit();
					this.aDisabledBtn = false;
				},
				(err) => {
					this._toasterService.pop(
						'success',
						"That didn't work. Please try again."
					);
					this.aDisabledBtn = false;
				}
			);
	}

	onContactSupport() {
		window.fcWidget.open();
	}
}
