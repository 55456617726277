import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { LocalStorageService } from '@services/localstorage.service';
import { ApiService } from '@services/common/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@services/login.service';
import { SignupService } from '@services/signup/signup.service';
import { ToasterService } from 'angular2-toaster';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { ExternalScriptService } from '@services/common/external-script.service';
import { environment } from '@env/environment';
import { WhiteLabelService } from '@services/white-label/white-label.service';
declare var grecaptcha: any;

@Component({
	selector: 'app-demo-signup',
	templateUrl: './demo-signup.component.html',
	styleUrls: ['./demo-signup.component.scss'],
})
export class DemoSignupComponent implements OnInit {
	valForm: FormGroup;
	passwordForm: FormGroup;
	oError: object = {
		status: '',
	};
	serverStatusCode: number;
	serverMessage: string;
	oCoupon: object = {
		applied: false,
		statusCode: '',
		message: '',
		data: {},
	};
	selectedPlan: object = {
		plan_id: 34,
		plan_cost: 420,
	};
	bSignupSuccess = false;
	utmSource = '';

	constructor(
		public _localStorageService: LocalStorageService,
		public _apiService: ApiService,
		public _loginService: LoginService,
		public _signupService: SignupService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		public _settingsService: SettingsService,
		public _formBuilder: FormBuilder,
		public _toasterService: ToasterService,
		public _externalScriptService: ExternalScriptService,
		public _whiteLabelService: WhiteLabelService,
	) {
		if(_whiteLabelService.isWhiteLabelledUser()){} {
			this._router.navigate(['/login']);
		}
		this.initForm();
		this.setCouponCodeViaQueryString();
		this.setReferralCodeViaQueryString();
		this.setUtmSourceViaQueryString();
	}

	ngOnInit() {
		this.checkUserLoggedIn();
		this.reloadGoogleRecaptchaScript();
	}

	initForm() {
		let password = new FormControl(
			'',
			Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(16),
			])
		);
		let certainPassword = new FormControl('', [Validators.required]);

		this.passwordForm = this._formBuilder.group(
			{
				password: password,
				confirm_password: certainPassword,
			},
			{
				validator: FlocksyValidator.equalTo(
					'password',
					'confirm_password'
				),
			}
		);

		this.valForm = this._formBuilder.group({
			name: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.notUrl,
					FlocksyValidator.notEmail,
				]),
			],
			business_name: [null],
			email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			phone: [null],
			lead_source: [''],
			passwordGroup: this.passwordForm,
			agree_terms: [null, Validators.required],
			recaptcha_token: [null],
		});
	}

	setCouponCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('discount_code' in params) {
				this._localStorageService.setCouponCode(
					btoa(params['discount_code'])
				);
				this.onClickValidateCouponCode();
			}
		});
	}

	setReferralCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('r' in params) {
				this._localStorageService.setReferralCode(btoa(params['r']));
				this._localStorageService.setReferralAppliedDate(new Date());
			}
		});
	}

	setUtmSourceViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('utm_source' in params) {
				this.utmSource = params['utm_source'];
				this._localStorageService.setUtmSource(btoa(params['utm_source']));
			}
		});
	}

	checkUserLoggedIn() {
		if (this._localStorageService.isUserLoggedIn()) {
			this.redirectUser();
		}
	}

	submitForm($ev, value: any) {
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}
		for (let c in this.passwordForm.controls) {
			this.passwordForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			return false;
		}
		this.generateTokenGoogleRecaptcha();
	}

	storeSubmit() {
		Object.assign(this.valForm.value, this.valForm.value.passwordGroup);

		const oPost = {
			coupon_code: this._localStorageService.getCouponCode()
				? atob(this._localStorageService.getCouponCode())
				: '',
			coupon_applied_date:
				this._localStorageService.getCouponAppliedDate(),
			plan_id: this.selectedPlan['plan_id'],
			source: 1,
		};
		if (
			this._localStorageService.getReferralAppliedDate() &&
			this._localStorageService.getReferralCode()
		) {
			if (
				this._localStorageService.getDateDiffHours(
					new Date(),
					new Date(this._localStorageService.getReferralAppliedDate())
				) <= 720
			) {
				oPost['referral_source'] = atob(
					this._localStorageService.getReferralCode()
				);
			}
		}

		Object.assign(this.valForm.value, oPost);

		this.valForm.value['user_type_id'] = 1;
		this._signupService.register(this.valForm.value).subscribe(
			(data) => {
				this.serverStatusCode = data.status;
				this.serverMessage = data.message;
				this._toasterService.pop('success', data.message);
				this.valForm.reset();
				this.bSignupSuccess = true;
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	redirectUser() {
		if (this._localStorageService.isClientUser()) {
			if (this._localStorageService.getClientPlanID() != 0) {
				this._router.navigate(['/dashboard']);
			} else {
				this._router.navigate(['/plans']);
			}
		} else if (this._localStorageService.isDesignerUser()) {
			this._router.navigate(['/dashboard']);
		} else if (this._localStorageService.isProjectManagerUser()) {
			this._router.navigate(['/projects/active']);
		} else if (this._localStorageService.isAdminUser()) {
			this._router.navigate(['/projects/active']);
		} else {
			this._router.navigate(['/login']);
		}
	}

	resetCouponData() {
		this.oCoupon = {
			applied: true,
			statusCode: '',
			message: '',
			data: {},
		};
	}

	onClickValidateCouponCode() {
		let coupon_code = this._localStorageService.getCouponCode();
		this.resetCouponData();
		if (coupon_code) {
			this._apiService
				.isValidCoupon(
					atob(coupon_code),
					this.selectedPlan['plan_id'],
					1
				)
				.subscribe(
					(data) => {
						this.oCoupon['applied'] = true;
						this.oCoupon['message'] = data.message;
						this._toasterService.pop('success', data.message);
						this.oCoupon['statusCode'] = data.status;
						this._localStorageService.setCouponCode(coupon_code);
						this._localStorageService.setCouponAppliedDate(
							new Date()
						);
						this.oCoupon['data'] = data.data;
					},
					(err) => {
						this._localStorageService.setCouponCode('');
						this._localStorageService.setCouponAppliedDate('');
						this.oCoupon['applied'] = true;
						this.oCoupon['message'] = err.json().message;
						this.oCoupon['statusCode'] = err.json().status;
						this.oCoupon['data'] = {};
					}
				);
		}
	}

	generateTokenGoogleRecaptcha() {
		const thisObject = this;
		grecaptcha.ready(function () {
			grecaptcha
				.execute(environment.reCaptchaSiteKey, {
					action: 'appSignupPage',
				})
				.then(function (token) {
					thisObject.assignRecaptchaToken(token);
					thisObject.storeSubmit();
				});
		});
	}

	reloadGoogleRecaptchaScript() {
		this._externalScriptService
			.loadScript(
				'https://www.google.com/recaptcha/api.js?render=' +
					environment.reCaptchaSiteKey,
				'flocksy_recaptcha'
			)
			.then((data) => {})
			.catch((error) => console.log(error));
	}

	assignRecaptchaToken(token) {
		this.valForm.patchValue({ recaptcha_token: token });
	}

	removeVisibilityGoogleRecaptcha() {
		const elements = document.getElementsByClassName('grecaptcha-badge');
		while (elements.length > 0) {
			elements[0].parentNode.removeChild(elements[0]);
		}
	}

	destroyGoogleRecaptcha() {
		this.removeVisibilityGoogleRecaptcha();
		this._externalScriptService.removeScript('flocksy_recaptcha');
	}

	ngOnDestroy() {
		this.destroyGoogleRecaptcha();
	}
}
