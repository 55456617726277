import { Component, ViewChild, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-btn-dropdown-select',
  templateUrl: './btn-dropdown-select.component.html',
  styleUrls: ['./btn-dropdown-select.component.scss']
})
export class BtnDropdownSelectComponent {
  @Input() options: any = ['Option 1', 'Option 2', 'Option 3'];
  @Input() selectedOption: any | null = null;
  @Output() performAction = new EventEmitter();

  isDropdownOpen = false;

  @ViewChild('dropdownWrapper') dropdownWrapper: ElementRef;

  constructor(private renderer: Renderer2) {}
  
  // Perform action based on selected option when main button is clicked
  handlePerformAction() {
    if (this.selectedOption) {
      this.performAction.emit(this.selectedOption);
    } else {
      console.log('No option selected, performing default action.');
      // Default action
    }
  }

  // Toggle the dropdown when the arrow button is clicked
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      // Add a click listener to the document when dropdown opens
      this.renderer.listen('document', 'click', (event) => this.onClick(event));
    }
  }

  // Select an option from the dropdown and update the button
  selectOptionAndPerform(option: string) {
    this.selectedOption = option;
    this.isDropdownOpen = false;
    this.handlePerformAction();
  }

  onClick(event: MouseEvent) {
    // Check if the clicked target is outside of the dropdown wrapper
    if (this.dropdownWrapper && !this.dropdownWrapper.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      // Optionally, you can remove the listener here if you want
      this.renderer.setProperty(this.dropdownWrapper.nativeElement, 'onclick', null);
    }
  }
}
