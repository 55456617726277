import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class TeamService {
	constructor(private _customHttpService: CustomHttpService) {}

	get() {
		return this._customHttpService.get(API_CONSTANTS.TEAM_URL);
	}

	getDesignersByClient(client_user_id: any, oParams?: object) {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.CLIENTS_URL +
				'/' +
				client_user_id +
				'/designers' +
				'?' +
				params.toString()
		);
	}

	getClientDesignerFeedback(client_user_id: any, oParams?: object) {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.CLIENTS_URL +
				'/' +
				client_user_id +
				'/feedback/users/designers' +
				'?' +
				params.toString()
		);
	}

	getClientsByDesigner(
		designer_user_id: any,
		project_type_id: any,
		page: number = 1
	) {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL +
				'/creatives/' +
				designer_user_id +
				'/team?filter[project_type_id]=' +
				project_type_id +
				'&page=' +
				page
		);
	}

	getSuggestedDesignersByClient(client_user_id: any, oParams?: object) {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.CLIENTS_URL +
				'/' +
				client_user_id +
				'/designers/suggested' +
				'?' +
				params.toString()
		);
	}

	getDesignerProfileForClient(
		client_user_id,
		user_id,
		oFilter?: object
	): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.CLIENTS_URL +
				'/' +
				client_user_id +
				'/designers/' +
				user_id +
				'/profile?' +
				params.toString()
		);
	}

	getDesignerProjectsForClient(client_user_id: any, user_id: any) {
		return this._customHttpService.get(
			API_CONSTANTS.CLIENT_URL +
				'/' +
				client_user_id +
				'/designer/' +
				user_id +
				'/projects'
		);
	}

	hireDesigner(clientId, creativeId, data) {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL +
				'/' +
				clientId +
				'/creatives/' +
				creativeId +
				'/hires',
			data
		);
	}
}
