import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from '../../../services/notification/notification.service';
import { NotificationsDetailviewComponent } from './notifications-detailview/notifications-detailview.component';
import { RouterModule } from '@angular/router';
import { LinkifyHtmlModule } from '../../../shared/pipes/linkify-html/linkify-html.pipe.module';
import { InnerHtmlPipeModule } from '../../pipes/innerhtml/innerhtml.pipe.module';
import { NewLinePipeModule } from '../../../shared/pipes/new-line/new-line.pipe.module';
@NgModule({
	declarations: [NotificationsDetailviewComponent],
	imports: [
		CommonModule,
		RouterModule,
		LinkifyHtmlModule,
		InnerHtmlPipeModule,
		NewLinePipeModule,
	],
	exports: [NotificationsDetailviewComponent],
	providers: [NotificationService],
})
export class NotificationsDetailviewModule {}
