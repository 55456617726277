import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import { LocalStorageService } from './localstorage.service';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';

@Injectable()
export class CustomHttpService {
	constructor(
		private _httpClient: HttpClient,
		private _localStorageService: LocalStorageService,
		public _router: Router,
		public _toasterService: ToasterService // public _analyticsService: AnalyticsService
	) {}

	get<T extends any>(url: string): Observable<T> {
		return this._httpClient
			.get<T>(url, { headers: this.getHttpHeaders() })
			.catch((err) => {
				return this.catchError(err);
			});
	}

	post<T extends any>(url: string, body: any): Observable<T> {
		return this._httpClient
			.post<T>(url, body, { headers: this.getHttpHeaders() })
			.catch((err) => {
				return this.catchError(err);
			});
	}

	put<T extends any>(url: string, body: any): Observable<T> {
		return this._httpClient
			.put<T>(url, body, { headers: this.getHttpHeaders() })
			.catch((err) => {
				return this.catchError(err);
			});
	}

	delete<T extends any>(url: string): Observable<T> {
		return this._httpClient
			.delete<T>(url, { headers: this.getHttpHeaders() })
			.catch((err) => {
				return this.catchError(err);
			});
	}

	catchError(err) {
		if (err.status == 403) {
			/*this._errorNotificationService.showError('Access Denied', '')*/
			this._toasterService.pop(
				'error',
				'Sorry, you can’t access this page. Please check that you entered the correct login details!'
			);
			this._router.navigate(['/projects/active']);
		} else if (err.status == 404) {
			this._toasterService.pop('error', 'Resource Not Found');
			this._router.navigate(['/projects/active']);
		} else if (err.status == 401) {
			if (this._localStorageService.isUserLoggedIn()) {
				this._localStorageService.resetSessionStorage();
				//this._analyticsService.setRouteData(1,5);
				if (err.error) {
					if ('message' in err.error) {
						this._toasterService.pop('error', err.error.message);
					}
				}
				this._router.navigate(['/logout']);
			}
		} else {
			let message = '';
			if (err) {
				if (err.error) {
					if (err.error.message) {
						message = err.error.message;
					}
					if ('errors' in err.error) {
						message = '';
						for (let field of Object.keys(err.error.errors)) {
							message += err.error.errors[field].join(', ');
						}
					}
				} else if (err.message) {
					message = err.message;
				}
			}
			if (message) {
				this._toasterService.pop('error', message);
			}
		}
		return throwError(err);
	}

	postFormData(url: string, postData: object, oFiles?) {
		let formData: FormData = new FormData();
		for (let key in postData) {
			formData.append(key, postData[key]);
		}
		formData.append('created_by', this._localStorageService.getUserId());

		if (oFiles) {
			for (let i = 0; i < oFiles.length; i++) {
				const file = oFiles[i];
				formData.append('files[]', file);
			}
		}

		return this._httpClient
			.post(url, formData, {
				headers: this.getFormDataHttpHeaders(),
				reportProgress: true,
				observe: 'events',
			})
			.pipe(
				map((event) => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							const progress = Math.round(
								(100 * event.loaded) / event.total
							);
							return { status: 'progress', progress: progress };
						case HttpEventType.Response:
							return event.body;
						default:
							return 'Unhandled event: ${event.type}';
					}
				})
			)
			.catch((err) => {
				return this.catchError(err);
			});
	}

	getHttpHeaders(): HttpHeaders {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('Accept', 'application/json');
		if (this._localStorageService.getAccessToken()) {
			headers = headers.set(
				'Authorization',
				'Bearer ' + this._localStorageService.getAccessToken()
			);
		}
		headers = headers.set('X-Current-Url', document.URL);
		if (this._localStorageService.getSessionToken()) {
			headers = headers.set(
				'X-Auth-Token',
				this._localStorageService.getSessionToken()
			);
		}
		return headers;
	}

	getFormDataHttpHeaders(): HttpHeaders {
		let headers = new HttpHeaders();
		if (this._localStorageService.getAccessToken()) {
			headers = headers.set(
				'Authorization',
				'Bearer ' + this._localStorageService.getAccessToken()
			);
		}
		return headers;
	}

	removeEmpty(obj: any) {
		const newObj: any = {};
		if (obj) {
			Object.keys(obj).forEach(function (k) {
				if (obj[k] && typeof obj[k] === "object") {
					newObj[k] = this.removeEmpty(obj[k]);
				} else if (obj[k] != null && obj[k] != 'undefined') {
					newObj[k] = obj[k];
				}
			});
		}

		if (Object.keys(newObj).length === 0 && newObj.constructor === Object) return false
		return newObj;
	}
}
