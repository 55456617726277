import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { WhiteLabelService } from '@services/white-label/white-label.service';

const SEPARATOR = ' > ';

@Injectable({
	providedIn: 'root',
})
export class PageTitleService {
	APP_TITLE: string;
	pathString: string;
	constructor(
		public _title: Title,
		public _router: Router,
		public _activatedRoute: ActivatedRoute,
		public whiteLabelService: WhiteLabelService,
	) {}

	static ucFirst(text: string) {
		if (!text) {
			return text;
		}
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	init() {
		this.whiteLabelService.getAppTitle().subscribe((title: string) => {
			this.APP_TITLE = title;
			this.setTitle(this.pathString);
		});
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let route = this._activatedRoute;
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route) => route.outlet === 'primary'),
				map((route) => route.snapshot),
				map((snapshot) => {
					if (snapshot.data.title) {
						return snapshot.data.title;
					} else {
						return this._router.url
							.split('/')
							.reduce((acc, frag) => {
								if (acc && frag) {
									acc += SEPARATOR;
								}
								return acc + PageTitleService.ucFirst(frag);
							});
					}
				})
			)
			.subscribe((pathString) => {
					this.pathString = pathString;
					this._title.setTitle(`${pathString} | ${this.APP_TITLE}`);
				}
			);
	}

	setTitle(title: string) {
		if(title)
			this._title.setTitle(title + ` | ${this.APP_TITLE}`);
	}
}
