import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FlocksyUtil } from '../../../../common/FlocksyUtil';
import { LocalStorageService } from '../../../../services/localstorage.service';

@Component({
	selector: 'app-package-detailed-info',
	templateUrl: './package-detailed-info.component.html',
	styleUrls: ['./package-detailed-info.component.scss'],
})
export class PackageDetailedInfoComponent implements OnInit {
	@Output() onClosePackageModal = new EventEmitter<any>();
	@Input() packageInfo;
	@Output() onSelectPackage = new EventEmitter<any>();
	@Input() selectedPackage;
	@Input() activeModuleType;
	packageBGImage = FlocksyUtil.packageBGImage;
	discountCouponBgImage = FlocksyUtil.discountBgImage;
	isDesignerUser = this.localStorageService.isDesignerUser();
	isOperationalClientUser =
		this.localStorageService.isOperationalClientUser();
	constructor(private localStorageService: LocalStorageService) {}

	ngOnInit(): void {}

	closePackageModal() {
		this.onClosePackageModal.emit(true);
	}

	selectPackage(data) {
		this.onSelectPackage.emit(data);
	}
}
