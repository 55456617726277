<carousel
#carousel
	[interval]="interval"
	[itemsPerSlide]="itemsPerSlide"
	[showIndicators]="showIndicators"
	[noWrap]="noWrap">
	<slide *ngFor="let item of aiImageList; let i = index">
		<img [src]="item?.fullPath" alt="" width="855px" height="855px" />
		<div class="action-tab slide-img p-1">
			<img
				(click)="handleOpenAIImgModal(aiImgModal)"
				class="mx-2 clickable"
				[src]="searchIcon"
				alt="search" />
			<img
				*ngIf="!item?.isSelected"
				(click)="handleSelectFile(i)"
				class="mx-2 clickable"
				[src]="whitePlusIcon"
				alt="add" />
			<div
				*ngIf="item?.isSelected"
				class="d-inline-block mx-2 clickable"
				(click)="handleUnSelectImg(i)">
				<app-true-icon [type]="'selected'"></app-true-icon>
			</div>
			<img
				(click)="handleDeleteAiImg(i)"
				class="mx-2 clickable"
				[src]="whiteDeleteIcon"
				alt="delete" />
		</div>
	</slide>
</carousel>
