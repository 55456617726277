import { Component, OnInit, HostListener } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { SettingsService } from '@core/settings/settings.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { ApiService } from '@services/common/api.service';
import { ExternalScriptService } from '@services/common/external-script.service';
import { LocalStorageService } from '@services/localstorage.service';
import { LoginService } from '@services/login.service';
import { SignupService } from '@services/signup/signup.service';
import { environment } from '@env/environment';
import { ConfigService } from '@services/user/config.service';
import { PackagesService } from '@services/packages/packages.service';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { WhiteLabelService } from '@services/white-label/white-label.service';

declare var grecaptcha: any;
declare var Rewardful;

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss', '../login/login.component.scss'],
})
export class SignupComponent implements OnInit {
	valForm: FormGroup;
	passwordForm: FormGroup;
	oError: object = {
		status: '',
	};
	serverStatusCode: number;
	serverMessage: string;
	userId: any;
	packageId: string;
	packageInfo;
	@HostListener('window:resize', ['$event'])
	getScreenSize(event?) {
		this.isMobileScreen = window.innerWidth < 480;
	}
	isMobileScreen = window.innerWidth < 480 ? true : false;
	signupPageTriggerId =
		FlocksyUtil.authenticationTriggers()['marketplace-signup'];
	marketPlaceTriggers = FlocksyUtil.marketPlaceConfigTriggerIds();
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();

	constructor(
		public _localStorageService: LocalStorageService,
		public _apiService: ApiService,
		public _loginService: LoginService,
		public _signupService: SignupService,
		private _router: Router,
		public _settingsService: SettingsService,
		public _formBuilder: FormBuilder,
		public _toasterService: ToasterService,
		public _analyticsService: AnalyticsService,
		public _externalScriptService: ExternalScriptService,
		public _configService: ConfigService,
		private _activatedRoute: ActivatedRoute,
		private _packageService: PackagesService,
		private _whiteLabelService: WhiteLabelService,
	) {
		this.init();
	}
	ngOnInit() {
		if(this.isWhiteLabelledUser) {
			this._router.navigate(['/dashboard']);
			return;
		}
		if (this._activatedRoute.snapshot.queryParamMap.get('packageId')) {
			this.packageId =
				this._activatedRoute.snapshot.queryParamMap.get('packageId');
			this.getPackageDetails();
		}
	}

	init() {
		if (this._localStorageService.isUserLoggedIn()) {
			this.checkUserLoggedIn();
		} else {
			this.reloadGoogleRecaptchaScript();
			this.initForm();
		}
	}

	initForm() {
		const password = new FormControl(
			'',
			Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(16),
			])
		);
		const certainPassword = new FormControl('', [Validators.required]);

		this.passwordForm = this._formBuilder.group(
			{
				password: password,
				confirm_password: certainPassword,
			},
			{
				validator: FlocksyValidator.equalTo(
					'password',
					'confirm_password'
				),
			}
		);

		this.valForm = this._formBuilder.group({
			name: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.notUrl,
					FlocksyValidator.notEmail,
				]),
			],
			business_name: [null],
			email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			phone: [null],
			lead_source: [''],
			passwordGroup: this.passwordForm,
			agree_terms: [null, Validators.required],
			recaptcha_token: [null],
		});
	}

	checkUserLoggedIn() {
		if (this._localStorageService.isUserLoggedIn()) {
			this.redirectUser();
		}
	}

	submitForm($ev, value: any) {
		$ev.preventDefault();
		for (const c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}
		for (const c in this.passwordForm.controls) {
			this.passwordForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			return false;
		}
		this.generateTokenGoogleRecaptcha();
	}

	storeSubmit() {
		Object.assign(this.valForm.value, this.valForm.value.passwordGroup);
		this.valForm.value['user_type_id'] = 1;
		this.valForm.value['trigger_id'] = this.signupPageTriggerId;
		this.valForm.value['package_id'] = this.packageId;
		this._signupService.register(this.valForm.value).subscribe(
			(data) => {
				this.serverStatusCode = data.status;
				this.serverMessage = data.message;
				this.userId = data.user_id;
				this._toasterService.pop('success', data.message);
				this.autoLoginAfterSignup(
					this.valForm.value.email,
					this.valForm.value.passwordGroup['password']
				);
				this.valForm.reset();
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	storeMarketplaceConfig() {
		let reqParam = {};
		reqParam['identifier'] = FlocksyUtil.marketplaceIdentifier;
		reqParam['value'] = 1;
		reqParam['trigger_id'] = this.marketPlaceTriggers['marketplace-signup'];
		this._configService.store(this.userId, reqParam).subscribe((res) => {});
	}

	autoLoginAfterSignup(email, password) {
		const auth_data = {
			grant_type: 'password',
			username: email,
			password: password,
			client_id: environment.ApiClientId,
			client_secret: environment.ApiClientSecret,
			client_signup: 1,
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: null,
		};

		this._loginService.oAuthToken(JSON.stringify(auth_data)).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.serverStatusCode = data.status;
				this.serverMessage = '';
				this.storeMarketplaceConfig();
				this.getAuthUser();
			},
			(err) => {
				this.oError = err;
			}
		);
	}

	getAuthUser() {
		this._apiService.getAuthUser({ tracker_flagged: 1 }).subscribe(
			(data) => {
				this._localStorageService.setCurrentUser(data.data);
				this.redirectUser();
			},
			(err) => {}
		);
	}

	redirectUser() {
		if (this._localStorageService.isClientUser()) {
			let hasMarketplaceAccess =
				this._localStorageService.hasMarketplaceAccess();
			if (
				this._localStorageService.getClientPlanID() != 0 ||
				hasMarketplaceAccess
			) {
				if (this.packageId && hasMarketplaceAccess) {
					this._router.navigate(['/projects/create'], {
						queryParams: {
							package_id: this.packageId,
							project_type_id: this.packageInfo?.project_type_id,
							project_title_id:
								this.packageInfo?.project_title_id,
							project_title: this.packageInfo?.project_title_name,
						},
					});
				} else {
					this._router.navigate(['/dashboard']);
				}
			} else {
				this._router.navigate(['/plans']);
				this._analyticsService.setRouteData(14, 25);
			}
		} else if (this._localStorageService.isDesignerUser()) {
			this._router.navigate(['/dashboard']);
		} else if (this._localStorageService.isProjectManagerUser()) {
			this._router.navigate(['/projects/active']);
		} else if (this._localStorageService.isAdminUser()) {
			this._router.navigate(['/projects/active']);
		} else {
			this._router.navigate(['/marketplace-login']);
		}
	}

	generateTokenGoogleRecaptcha() {
		const thisObject = this;
		grecaptcha.ready(function () {
			grecaptcha
				.execute(environment.reCaptchaSiteKey, {
					action: 'appSignupPage',
				})
				.then(function (token) {
					thisObject.assignRecaptchaToken(token);
					thisObject.storeSubmit();
				});
		});
	}

	reloadGoogleRecaptchaScript() {
		this._externalScriptService
			.loadScript(
				'https://www.google.com/recaptcha/api.js?render=' +
					environment.reCaptchaSiteKey,
				'flocksy_recaptcha'
			)
			.then((data) => {})
			.catch((error) => console.log(error));
	}

	assignRecaptchaToken(token) {
		this.valForm.patchValue({ recaptcha_token: token });
	}

	removeVisibilityGoogleRecaptcha() {
		const elements = document.getElementsByClassName('grecaptcha-badge');
		while (elements.length > 0) {
			elements[0].parentNode.removeChild(elements[0]);
		}
	}

	destroyGoogleRecaptcha() {
		this.removeVisibilityGoogleRecaptcha();
		this._externalScriptService.removeScript('flocksy_recaptcha');
	}

	getPackageDetails() {
		this._packageService.getPackageDetails(this.packageId).subscribe(
			(res) => {
				this.packageInfo = res.data;
			},
			(err) => {
				if (err.status == 422) {
					this._router.navigate(['/marketplace-signup'], {
						queryParams: {},
					});
					this.packageId = null;
				}
			}
		);
	}

	ngOnDestroy() {
		this.destroyGoogleRecaptcha();
	}
}
