<div class="dropdown-wrapper" #dropdownWrapper>
	<!-- Button with action depending on the selected option -->
	<button class="btn action-btn" (click)="handlePerformAction()">
		{{ selectedOption?.label || 'Perform Action' }}
	</button>

	<!-- Chevron button to open/close dropdown -->
	<button class="btn chevron-btn" (click)="toggleDropdown()">
		<span>
			<i
				[ngStyle]="{
					transform: isDropdownOpen
						? 'rotate(180deg)'
						: 'rotate(0deg)'
				}"
				class="fas fa-chevron-down text-white"></i>
		</span>
	</button>

	<!-- Options dropdown (will open upwards) -->
	<div class="dropdown-menu-options" *ngIf="isDropdownOpen">
		<div
			*ngFor="let option of options" 
			class="dropdown-item"
			(click)="selectOptionAndPerform(option);"
			>
			<button 
				class="btn-dropdown"
				[ngClass]="{'selected': selectedOption.label === option.label}"
				>
				{{ option.label }}
			</button>
		</div>
	</div>
</div>
