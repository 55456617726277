import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateProjectOverlayComponent } from './create-project-overlay.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProjectsEditviewModule } from '../../../routes/projects/editview/projects.editview.module';
import { PopoverModule } from 'ngx-bootstrap';
import { CreateProjectOverlayTriggerComponent } from './create-project-overlay-trigger/create-project-overlay-trigger.component';


@NgModule({
	declarations: [CreateProjectOverlayComponent, CreateProjectOverlayTriggerComponent],
	imports: [
		CommonModule,
		ModalModule,
		ProjectsEditviewModule,
		PopoverModule,
	],
	exports: [
		CreateProjectOverlayComponent,
		CreateProjectOverlayTriggerComponent,
	],
})
export class CreateProjectOverlayModule {
}
