<div class="modal-header" *ngIf="unreadNotifications?.length > 0">
	<div class="d-flex justify-content-between align-items-center w-100">
		<h4 class="modal-title text-primary">Unread notifications</h4>
		<div class="d-flex justify-content-end align-items-center">
			<span class="float-right">
				<u>
					<a
						href="javascript:void(0);"
						(click)="clearAllNotifications()">
						Clear all notifications
					</a>
				</u>
			</span>
			<button
				(click)="emitCloseModal.emit()"
				aria-label="Close"
				class="close btn btn-sm ml-0 align-self-start"
				type="button"
				*ngIf="!isNotifications">
				<em class="fa fa-times" style="font-size: 16px"></em>
			</button>
		</div>
	</div>
</div>
<div class="modal-body p-2" *ngIf="unreadNotifications?.length > 0">
	<div class="list-group">
		<div class="list-group-item b0 bb-2 p-0">
			<div
				class="media pb-1 pt-1"
				*ngFor="let item of unreadNotifications">
				<img
					class="align-self-start mx-2 circle thumb32"
					[src]="item?.created_by_user?.profile_picture?.full_path"
					onError="this.src='../../../../assets/img/user-placeholder.png';"
					alt="Image" />
				<div class="media-body">
					<span
						class="mr-1 btn btn-xs btn-oval btn-secondary mb-1 float-right float-right">
						{{
							_localStorageService.formatRelativeOrAbsoluteDate(
								item?.created_at
							)
						}}
					</span>
					<span
						[ngSwitch]="item?.notification_type_id"
						class="align-sub">
						<span *ngSwitchCase="1" class="mb-1 text-sm">
							<a
								href="javascript:void(0);"
								[routerLink]="'/messages'"
								[queryParams]="{
									focusedMessageId: item?.data?.id
								}"
								class="text-dark overflow-wrap-anywhere"
								(click)="markAsRead(item, true)">
								<span>
									<b>{{ item?.plain_content }}</b>
								</span>
							</a>
						</span>
						<span *ngSwitchCase="2" class="mb-1 text-sm">
							<a
								href="javascript:void(0);"
								[routerLink]="'/messages'"
								[queryParams]="{
									focusedMessageId: item?.data?.id
								}"
								class="text-dark overflow-wrap-anywhere"
								(click)="markAsRead(item, true)">
								<span>
									<b>{{ item?.plain_content }}</b>
								</span>
							</a>
						</span>
						<span *ngSwitchCase="3" class="mb-1 text-sm">
							<a
								class="text-dark clickable"
								(click)="markAsRead(item, true)">
								<span>
									<b>{{ item?.plain_content }}</b>
								</span>
							</a>
						</span>
						<span *ngSwitchCase="4" class="mb-1 text-sm">
							<a
								[routerLink]="'/messages'"
								[queryParams]="{
									focusedMessageId: item?.data?.id
								}"
								class="text-dark clickable overflow-wrap-anywhere"
								(click)="markAsRead(item, true)">
								<span>
									<b>{{ item?.plain_content }}</b>
								</span>
							</a>
						</span>
						<span *ngSwitchCase="5" class="mb-1 text-sm">
							<a
								class="text-dark clickable"
								(click)="markAsRead(item, true)">
								<span>
									<b>{{ item?.plain_content }}</b>
								</span>
							</a>
						</span>
						<p
							class="notificaion-comment mt-2"
							*ngIf="item.data.message || item.data.text">
							<span
								[innerHTML]="
									item?.data?.message
										| newline
										| innerhtml
										| linkifyHtml
								"></span>
							<span
								*ngIf="item?.data?.text"
								[innerHTML]="
									item?.data?.text
										| newline
										| innerhtml
										| linkifyHtml
								"></span>
						</p>
					</span>
				</div>
				<div class="mr-2 ml-4 align-self-center">
					<em
						class="text-muted fas fa-times clickable"
						(click)="markAsRead(item, false)"></em>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal-header">
	<!-- <span class="float-right"><u><a  href="javascript:void(0);" (click)="clearAllNotifications()">Clear all notifications-detailview</a></u></span> -->
	<h4 class="modal-title text-primary">
		<span *ngIf="isNotifications">Past notifications</span>
		<u>
			<a
				[routerLink]="'/notifications'"
				(click)="emitCloseModal.emit(); pastNotificationAnalytics()"
				*ngIf="!isNotifications">
				Past notifications
			</a>
		</u>
	</h4>
	<button
		(click)="emitCloseModal.emit()"
		aria-label="Close"
		class="close btn btn-sm ml-0"
		type="button"
		*ngIf="unreadNotifications?.length <= 0 && !isNotifications">
		<em class="fa fa-times" style="font-size: 16px"></em>
	</button>
</div>
<div class="modal-body p-2">
	<div class="list-group">
		<div
			class="list-group-item b0 p-0"
			[class.bb2]="!notificationsPagination['next_page_url']">
			<div class="media pb-1 pt-1" *ngFor="let item of notifications">
				<img
					class="align-self-start mx-2 circle thumb32"
					[src]="item?.created_by_user?.profile_picture?.full_path"
					onError="this.src='../../../../assets/img/user-placeholder.png';"
					alt="Image" />
				<div class="media-body">
					<span
						class="mr-1 btn btn-xs btn-oval btn-secondary mb-1 float-right float-right">
						{{
							_localStorageService.formatDate(
								item?.created_at,
								'MMM DD, YYYY h:mm A'
							)
						}}
					</span>
					<span
						[ngSwitch]="item?.notification_type_id"
						class="align-sub">
						<span *ngSwitchCase="1" class="mb-1 text-sm">
							<a
								[routerLink]="'/messages'"
								[queryParams]="{
									focusedMessageId: item?.data?.id
								}"
								(click)="markAsRead(item, true, false)"
								class="text-dark overflow-wrap-anywhere">
								<span>{{ item?.plain_content }}</span>
							</a>
						</span>
						<span *ngSwitchCase="2" class="mb-1 text-sm">
							<a
								[routerLink]="'/messages'"
								[queryParams]="{
									focusedMessageId: item?.data?.id
								}"
								(click)="markAsRead(item, true, false)"
								class="text-dark overflow-wrap-anywhere">
								<span>{{ item?.plain_content }}</span>
							</a>
						</span>
						<span *ngSwitchCase="3" class="mb-1 text-sm">
							<a
								(click)="markAsRead(item, true, false)"
								class="text-dark clickable">
								<span>{{ item?.plain_content }}</span>
							</a>
						</span>
						<span *ngSwitchCase="4" class="mb-1 text-sm">
							<a
								[routerLink]="'/messages'"
								[queryParams]="{
									focusedMessageId: item?.data?.id
								}"
								class="text-dark clickable overflow-wrap-anywhere"
								(click)="markAsRead(item, true)">
								<span>
									{{ item?.plain_content }}
								</span>
							</a>
						</span>
						<span *ngSwitchCase="5" class="mb-1 text-sm">
							<a
								class="text-dark clickable"
								(click)="markAsRead(item, true)">
								<span>
									{{ item?.plain_content }}
								</span>
							</a>
						</span>

						<p
							class="notificaion-comment mt-2"
							*ngIf="item.data.message || item.data.text">
							<span
								*ngIf="item?.data?.message"
								[innerHTML]="
									item?.data?.message
										| newline
										| innerhtml
										| linkifyHtml
								"></span>
							<span
								*ngIf="item?.data?.text"
								[innerHTML]="
									item?.data?.text
										| newline
										| innerhtml
										| linkifyHtml
								"></span>
						</p>
					</span>
				</div>
			</div>
		</div>
	</div>
	<div
		class="list-group-item b0 list-group-item-action justify-content-center d-flex"
		*ngIf="notificationsPagination['next_page_url']">
		<span>
			<a
				href="javascript:void(0);"
				(click)="
					retrieveNotifications(
						notificationsPagination['current_page'] + 1
					)
				">
				<span>Load More</span>
				<em
					class="ml-2 text-muted fas fa-chevron-down text-primary"></em>
			</a>
		</span>
	</div>
	<div class="text-center" *ngIf="notifications.length <= 0">
		<span>No past notifications</span>
	</div>
</div>
