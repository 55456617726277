import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable({
	providedIn: 'root',
})
export class ProjectTypeService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECT_TYPES_URL + '/trending'
		);
	}

	getProjectTypes(): Observable<any> {
		return this._customHttpService.get(API_CONSTANTS.PROJECT_TYPES_URL);
	}

	getMarketPlaceProjectType(oParams?) {
		const params = new URLSearchParams();
		if (oParams) {
			for (let key in oParams) {
				params.set('filter[' + key + ']', oParams[key]);
			}
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.PROJECT_TYPES_URL}/marketplace${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}
	getMarketPlaceProjectTitle(id, oParams?) {
		const params = new URLSearchParams();
		if (oParams) {
			for (let key in oParams) {
				for (const value of oParams[key]) {
					params.set('filter[' + key + ']', value);
				}
			}
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.PROJECT_TYPES_URL}/${id}/project-titles${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	getMarketplaceCategories(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECT_TYPES_URL + '/marketplace'
		);
	}

	getMarketplaceSubcategories(projectTypeId): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECT_TYPES_URL +
				'/' +
				projectTypeId +
				'/project-titles'
		);
	}

	getMarketplaceQuestions(projectTypeId, projectTitleId) {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECT_TYPES_URL +
				'/' +
				projectTypeId +
				'/project-titles/' +
				projectTitleId +
				'/questions'
		);
	}
	/**
	getMarketplacePackages(
		projectTypeId,
		projectTitleId,
		pageNo,
		oParams?
	): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${
				API_CONSTANTS.PROJECT_TYPES_URL
			}/${projectTypeId}/project-titles/${projectTitleId}/packages?page=${pageNo}${
				params.toString() ? '&' + params.toString() : ''
			}`
		);
	}
	*/

	getMarketplacePackages(typeId, oParams) {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.PROJECT_TYPES_URL}/${typeId}/packages${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}
}
