import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';



@NgModule({
	declarations: [ModalHeaderComponent, ModalFooterComponent],
	exports: [
		ModalHeaderComponent,
		ModalFooterComponent,
	],
	imports: [
		CommonModule,
	],
})
export class FlocksyModalModule { }
