import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	private user: any;
	private app: any;
	private layout: any;

	constructor() {
		// User Settings
		// -----------------------------------
		this.user = {
			name: 'Panseriya',
			job: 'ng-developer',
			picture: 'assets/img/user/02.jpg',
		};

		// App Settings
		// -----------------------------------
		this.app = {
			name: 'Flocksy',
			description: 'The Unlimited Creative Team',
			year: new Date().getFullYear(),
		};

		// Layout Settings
		// -----------------------------------
		this.layout = {
			isFixed: true,
			isCollapsed: false,
			isBoxed: false,
			isRTL: false,
			horizontal: false,
			isFloat: false,
			asideHover: false,
			theme: null,
			asideScrollbar: false,
			isCollapsedText: false,
			useFullLayout: false,
			hiddenFooter: false,
			offsidebarOpen: false,
			asideToggled: false,
			viewAnimation: 'ng-fadeInUp',
		};
	}

	getAppSetting(name) {
		return name ? this.app[name] : this.app;
	}

	getUserSetting(name) {
		return name ? this.user[name] : this.user;
	}

	getLayoutSetting(name) {
		return name ? this.layout[name] : this.layout;
	}

	setAppSetting(name, value) {
		if (typeof this.app[name] !== 'undefined') {
			this.app[name] = value;
		}
	}

	setUserSetting(name, value) {
		if (typeof this.user[name] !== 'undefined') {
			this.user[name] = value;
		}
	}

	setLayoutSetting(name, value) {
		if (typeof this.layout[name] !== 'undefined') {
			return (this.layout[name] = value);
		}
	}

	toggleLayoutSetting(name) {
		return this.setLayoutSetting(name, !this.getLayoutSetting(name));
	}

	asideLayoutSetting(name) {
		return this.setLayoutSetting(name, true);
	}
}
