import { Injectable } from '@angular/core';

declare var document: any;

@Injectable()
export class ExternalScriptService {
	private scripts: any = {};

	constructor() {}

	loadScript(src: string, id: string, attributes?: object) {
		return new Promise((resolve, reject) => {
			// load script
			const script = document.createElement('script');
			script.setAttribute('id', id);
			script.type = 'text/javascript';
			script.src = src;
			script.async = true;
			for (const attribute in attributes) {
				script.setAttribute(attribute, attributes[attribute]);
			}
			if (script.readyState) {
				script.onreadystatechange = () => {
					if (
						script.readyState === 'loaded' ||
						script.readyState === 'complete'
					) {
						script.onreadystatechange = null;
						script.loaded = true;
						resolve({ loaded: true, status: 'Loaded' });
					}
				};
			} else {
				script.onload = () => {
					script.loaded = true;
					resolve({ loaded: true, status: 'Loaded' });
				};
			}
			script.onerror = (error: any) =>
				resolve({ loaded: false, status: 'Loaded' });
			document.getElementsByTagName('head')[0].appendChild(script);
		});
	}

	removeScript(id) {
		if (document.getElementById(id)) {
			document.getElementById(id).remove();
		}
	}
}
