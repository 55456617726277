<div class="modal-header px-0 mx-4 border-0 pt-4 pb-0">
	<p class="enter-payment-method-text mb-0">Enter a payment method to proceed</p>
	<button
		type="button"
		class="close"
		aria-label="Close"
		(click)="onCloseCardForm()">
		<img src="assets/img/close-grey-light.svg" alt="" class="close-icon-dimension">
	</button>
</div>
<div class="container">
	<hr class="mt-3 mb-0" />
	<ng-template #stripeTemplate>
		<div id="cardElement" #stripeCardElement></div>
	</ng-template>
	<div class="row new-card mx-2 my-2">
		<div class="col-md-12 px-0">
			<div class="">
				<form
					[formGroup]="addCardForm"
					class="form-validate"
					role="form"
					name="addCardForm"
					novalidate=""
					*ngIf="addCardForm"
					(submit)="onAddnewCardSubmit(event)">
					<div class="form-row mt-4">
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div class="form-group card-dropdown">
								<label>Country</label>
								<span>*</span>
								<ngx-select
									(select)="onChangeCountry($event)"
									[items]="countries"
									formControlName="country"
									placeholder="Select Country"></ngx-select>
							</div>
						</div>
						<div class="col-sm-6 col-md-6 col-lg-6">
							<div
								*ngIf="
									addCardForm.value.country &&
									states[addCardForm.value.country]
								"
								class="form-group card-dropdown">
								<label>State</label>
								<span class="">*</span>
								<ngx-select
									[items]="states[addCardForm.value.country]"
									formControlName="state"
									(select)="onChangeState($event)"
									placeholder="Select State"></ngx-select>
								<div
									*ngIf="btnClicked && addCardForm.controls[
										'state'
									].hasError('required')"
									class="text-danger">
									Required
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label>
							Name
							<span class="">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							formControlName="name"
							placeholder="Name" />
						<div
							*ngIf="btnClicked && addCardForm.controls[
								'name'
							].hasError('required')"
							class="text-danger">
							Required
						</div>
					</div>
					<div class="form-group">
						<label>
							Credit card number
							<span>*</span>
						</label>
						<div id="stripe-card-element"></div>
						<div
							*ngIf="btnClicked && addCardForm.controls[
								'stripe_event_completed'
							].hasError('required')"
							class="text-danger">
							Required
						</div>
						<div
							*ngIf="btnClicked && stripeError"
							class="alert alert-danger mt-2">
							{{ stripeError.message }}
						</div>
					</div>
					<div
						class="my-4 d-flex justify-content-between align-items-center" *ngIf="!isViaSignUpPage">
						<div class="text-right">
							<button
								class="btn btn-primary new-card-btn"
								type="submit"
								[disabled]="
									disableAddCardButton
								">
								Add Card
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
