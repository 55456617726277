import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { SignupService } from '@services/signup/signup.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { WhiteLabelService } from '@services/white-label/white-label.service';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	valForm: FormGroup;
	oError: object = {
		status: '',
	};
	serverStatusCode: number;
	serverMessage: string;
	btnClicked = false;
	submitted = false;
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();

	constructor(
		public _settingsService: SettingsService,
		public _signupService: SignupService,
		public _formBuilder: FormBuilder,
		public _toasterService: ToasterService,
		public _whiteLabelService: WhiteLabelService,
	) {
		this.initForm();
	}

	ngOnInit() {}

	initForm() {
		this.valForm = this._formBuilder.group({
			email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
		});
	}

	submitForm($ev) {
		$ev.preventDefault();
		this.submitted = true;
		if (this.valForm.valid) {
			this.btnClicked = false;
			for (let c in this.valForm.controls) {
				this.valForm.controls[c].markAsTouched();
			}
	
			if (!this.valForm.valid) {
				return false;
			}
			this.oError = {
				status: '',
			};
			this._signupService.resetPasswordUser(this.valForm.value).subscribe(
				(data) => {
					this.serverStatusCode = data.status;
					this.serverMessage = data.message;
					this.valForm.reset();
					this._toasterService.pop('success', data.message);
					this.btnClicked = true;
				},
				(err) => {
					this.oError = err;
					this.btnClicked = false;
				}
			);
		} else {
			this.submitted = true;
		}
	}
}
