import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './components/signup/signup.component';
import { LoginComponent } from './components/login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { ANALYTICS_PAGES } from '../../common/constant/AnalyticsPageIds';
import { LayoutModule } from '../../../app/layout/layout.module';
import { ExternalScriptService } from '../../../app/services/common/external-script.service';
import { CouponService } from '../../../app/services/coupon/coupon.service';
import { LoginService } from '../../../app/services/login.service';
import { PlanService } from '../../../app/services/plan/plan.service';
import { SignupService } from '../../../app/services/signup/signup.service';
import { SharedModule } from '../../../app/shared/shared.module';
import { NgxSelectModule } from 'ngx-select-ex';
import { PackageDetailsComponent } from './components/package-info/package-details.component';
import { PackageDetailedInfoComponent } from './components/package-detailed-info/package-detailed-info.component';
import { PopoverModule } from 'ngx-bootstrap';

const routes: Routes = [
	{
		path: 'marketplace-signup',
		component: SignupComponent,
		data: {
			title: 'Signup',
		},
	},
	{
		path: 'marketplace-login',
		component: LoginComponent,
		data: {
			title: 'Login',
		},
	},
];

@NgModule({
	declarations: [
		SignupComponent,
		LoginComponent,
		PackageDetailsComponent,
		PackageDetailedInfoComponent,
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		SharedModule,
		NgxSelectModule,
		LayoutModule,
		PopoverModule,
	],
	exports: [RouterModule, PackageDetailedInfoComponent],
	providers: [
		LoginService,
		SignupService,
		CouponService,
		PlanService,
		ExternalScriptService,
	],
})
export class MarketplaceModule {}
