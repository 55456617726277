import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { LocalStorageService } from '@services/localstorage.service';
import { WhiteLabelService } from '@services/white-label/white-label.service';

@Component({
	selector: '[app-footer]',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	isClientUser = this._localStorageService.isClientUser();
	isSubClientUser = this._localStorageService.isSubClientUser();
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();

	constructor(
		public settings: SettingsService,
		private _localStorageService: LocalStorageService,
		public _whiteLabelService: WhiteLabelService
	) {}

	ngOnInit() {}
}
