import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-skeleton-loader',
	templateUrl: './skeleton-loader.component.html',
	styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent implements OnInit {
	@Input() borderRadius;
	@Input() height;
	@Input() count = 1;
	@Input() width;
	@Input() border;
	@Input() appearance = false;
	@Input() class = '';
	theme = {};
	constructor() {}

	ngOnInit(): void {
		this.theme = {
			'border-radius': this.borderRadius,
			height: this.height,
			width: this.width,
			border: this.border,
		};
	}
}
