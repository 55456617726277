import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LocalStorageService } from './localstorage.service';
import { ExternalScriptService } from './common/external-script.service';
import { environment } from '../../environments/environment';

declare var XmlFeedGrabber;

@Injectable()
export class ThonestService {
	private _renderer2: Renderer2;
	thonestDataToken: string = environment.thonestDataToken;
	userId = Number(this._localStorageService.getUserId());
	angularWidgetJsURL =
		'https://app.thonest.com/backend/js/angularWidget.js?v=1';

	constructor(
		public _localStorageService: LocalStorageService,
		public _externalScriptService: ExternalScriptService,
		rendererFactory: RendererFactory2
	) {
		this._renderer2 = rendererFactory.createRenderer(null, null);
	}

	init() {
		return false;

		if (environment.env == 'local') {
			return;
		}

		if (!this.isEnabled()) {
			return;
		}

		if (
			this._localStorageService.isDesignerUser() ||
			this._localStorageService.isProjectManagerUser()
		) {
			this.thonestDataToken = environment.thonestDataToken;
		} else {
			this.thonestDataToken = environment.clientPmAmThonestDataToken;
		}

		this.loadScript();
	}

	isEnabled() {
		return (
			this._localStorageService.isAdminUser() ||
			this._localStorageService.isProjectManagerUser() ||
			this._localStorageService.isDesignerUser() ||
			this._localStorageService.isOperationalClientUser()
		);
	}

	loadScript() {
		this._externalScriptService
			.loadScript(this.angularWidgetJsURL, 'thonestWidget', {
				'data-token': this.thonestDataToken,
				class: 'd-block',
			})

			.then((data) => {
				XmlFeedGrabber.render();
			})
			.catch((error) => console.log(error));
	}

	remove() {
		this.removeScript();
	}

	removeScript() {
		this._externalScriptService.removeScript('thonestWidget');
		this._externalScriptService.removeScript('thonCommentBtn');
	}

	toggleThonest(isShow = true) {
		if (isShow) {
			this.showThonest();
		} else {
			this.hideThonest();
		}
	}

	showThonest() {
		this._renderer2.removeClass(
			document.getElementById('plugincontainer'),
			'd-none'
		);
		this._renderer2.addClass(
			document.getElementById('plugincontainer'),
			'd-block'
		);
	}

	hideThonest() {
		this._renderer2.removeClass(
			document.getElementById('plugincontainer'),
			'd-block'
		);
		this._renderer2.addClass(
			document.getElementById('plugincontainer'),
			'd-none'
		);
	}
}
