import { Injectable } from '@angular/core';
import { CustomHttpService } from '../../custom.http.service';
import { API_CONSTANTS } from '../../../common/constant/ApiConstants';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PlansService {
	constructor(private readonly _customHttpService: CustomHttpService) {}

	index(userId) {
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${userId}/plans/active`
		);
	}

	getUserDetails(id): Observable<any> {
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${id}?include=pm`
		);
	}
}
