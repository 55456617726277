import { CropperSettings } from 'ngx-img-cropper';
import { environment } from '@env/environment';
export class FlocksyUtil {
	static isFeedbackeryEnabled = true;
	static isFeedbackeryInitialized = false;
	static minHoursForBidProject = 7;
	static minHoursOffsetForBidProject = 4;
	static designerRequestAdditionalPayment = 10;
	static isNotificationsCountEnabled = true;
	static cardBrandImagesPath = '../assets/img/cards';
	static showFeedbackeryAfterProjects = 5;
	static imagesPath = '../assets/img/';
	static videoExtensionAllowed = ['video/mp4', 'video/m4v'];
	static getEmojiIntervalCheck = 500;
	static previewImgSize = 50000000;
	static hasAdditionalPayment = 2;
	static packageDetailMaxLength = 1000;
	static packageTitleMaxLength = 100;
	static usCountryId = 231;
	static alabamaStateId = 3919;
	static pausePlanIds = [28, 42];
	static draftParam = 5;
	static bucketListInitialDisplayLimit = 3;
	static boltIconPlanIds = [140, 143, 146, 141, 144, 147, 149, 150, 151];
	static doubleDiamondBadgeId = 5;
	static sixWeekBadgeId = 6;
	static fileUploadLimit = 10;
	static marketplaceTransactionType = 13;
	static createProjectPackageListLimit = 4;
	static subscriptionType = {
		newPlanSubscription: 1,
		planRenewal: 3,
		planUpgrade: 14,
	};

	static UserConfigsMap = {
		HAS_SUB_USER_DISABLED: 'has_sub_user_disabled'
	}

	static trendingProjectTypes = {
		'graphic-design': {
			title: 'graphicDesign',
			path: 'assets/img/dashboard/graphic_Design.svg',
		},
		copywriting: {
			title: 'copyWriting',
			path: 'assets/img/dashboard/copy_Writing.svg',
		},
		'video-editing-creation': {
			title: 'videoEditing',
			path: 'assets/img/dashboard/video_icon.svg',
		},
		video: {
			title: 'videoEditing',
			path: 'assets/img/dashboard/video_icon.svg',
		},
		'custom-illustrations': {
			title: 'customIllustrations',
			path: 'assets/img/dashboard/custom_Illustrations.svg',
		},
		'motion-graphics': {
			title: 'motionGraphics',
			path: 'assets/img/dashboard/motion_Graphics.svg',
		},
		'virtual-assistant': {
			title: 'virtualAssistant',
			path: 'assets/img/dashboard/virtual_Assistants.svg',
		},
		'web-email-design': {
			title: 'webEmailDesign',
			path: 'assets/img/web_email_design.svg',
		},
		'ai-copy-editing': {
			title: 'aiCopywriting',
			path: 'assets/img/dashboard/ai_copywriting.svg',
		},
		'website-development': {
			title: 'websiteDevelopment',
			path: 'assets/img//dashboard/web_dev.svg',
		},
		'voice-overs': {
			title: '"voiceOvers"',
			path: 'assets/img/dashboard/Voice_over.svg',
		},
		'performer-artist-entertainer': {
			title: '"voiceOvers"',
			path: 'assets/img/dashboard/performer_artist.svg',
		},
		'copywriting-seo': {
			title: '"voiceOvers"',
			path: 'assets/img/dashboard/copywriting_seo.svg',
		},
		other: {
			title: '',
			path: 'assets/img/dashboard/other.svg',
		},
	};

	static historyType = {
		registered: 1,
		upgraded: 2,
		cancelled: 3,
		downgraded: 4,
		reSubscribed: 5,
	};

	static planPeriodType = {
		monthlyPlan: 'monthly',
		quarterlyPlan: 'quarterly',
		yearlyPlan: 'yearly',
	};


	static planPeriodTime = {
		monthlyPlan: 1,
		quarterlyPlan: 3,
		yearlyPlan: 12,
	};

	static subscribeAnnualPlanFromUI: boolean = true;
	static marketPlaceBrowse = '/marketplace/browse/';
	static marketplaceIdentifier = 'has_marketplace_access';
	static projectManagerIdentifier = 'has_project_manager';
	static autoFinalizeAccessIdentifier = "has_auto_finalize_access";
	static projectAutoFinalizeIdentifier = "has_project_auto_finalize";
	static packageImageResolution = '620x330';
	static bulbIcon = 'assets/img/create_project/bulb-icon.svg';
	static grayBulbIcon = 'assets/img/create_project/disabled-bulb-icon.svg';
	static editIcon = 'assets/img/create_project/pen-icon.svg';
	static uploadFileIcon = 'assets/img/create_project/upload-file-icon.svg';
	static addSubUserImg = 'assets/img/create_project/add_subuser_img.png';
	static deleteIcon = 'assets/img/create_project/delete-icon.svg';
	static delete = 'assets/img/create_project/delete.svg';
	static MoveIcon = 'assets/img/create_project/move-icon.svg';
	static trueIcon = 'assets/img/create_project/green-true-icon.svg';
	static packageBGImage = 'assets/img/package-bg-img.png';
	static projectsBGImage = 'assets/img/create_project/transparent-image.png';
	static notesIcon = 'assets/img/create_project/notes_normal.svg';
	static statsIcon = 'assets/img/create_project/stats_normal.svg';
	static plusIcon = 'assets/img/create_project/plus_icon.svg';
	static whitePlusIcon = 'assets/img/create_project/white_plus_icon.svg';
	static whiteDeleteIcon = 'assets/img/create_project/white_delete_icon.svg';
	static searchIcon = 'assets/img/create_project/search_icon.svg';
	static defaultFileImg = 'assets/img/create_project/file_img.png';
	static svgDefaultImg = "assets/img/project-files-modal/default-svg-file.svg";
	static closeIcon = 'assets/img/modal_close_icon.svg';
	static shieldFillCheck = 'assets/img/create_project/shield-fill-check.svg';
	static shieldFillUncheck =
		'assets/img/create_project/shield-fill-uncheck.svg';
	static excludeIcon = 'assets/img/create_project/exclude_normal.svg';
	static includeIcon = 'assets/img/create_project/include_normal.svg';
	static selectedExcludeIcon =
		'assets/img/create_project/exclude_pressed.svg';
	static selectedIncludeIcon =
		'assets/img/create_project/include_pressed.svg';
	static leftArrowImage = 'assets/img/plans/left-arrow-vector.png';
	static rightArrowImage = 'assets/img/plans/right-arrow-vector.png';
	static nextBillingDateIcon =
		'assets/img/upgrading-billing/next_date_icon.svg';
	static checkCalenderIcon =
		'assets/img/upgrading-billing/calendar-check-fill.svg';
	static weekCalenderIcon =
		'assets/img/upgrading-billing/calendar-week-fill.svg';
	static settingIcon =
		'assets/img/upgrading-billing/project_setting_icon.svg';
	static registerPlanIcon = 'assets/img/upgrading-billing/purchase_icon.svg';
	static primaryTrue = 'assets/img/upgrading-billing/true-icon.svg';
	static grayCancel = 'assets/img/upgrading-billing/cancel-icon.svg';
	static phoneIcon = '../../../../assets/img/plans-phone.svg';
	static redirectArrow = '../../../../assets/img/redirect-arrow.svg';
	static feedbackISvg = '../../../../assets/img/feedback.svg';
	static discountBgImage = 'assets/img/packages/discount-ribbon.svg';
	static draftsImage = 'assets/img/dashboard/draft_icon.svg';
	static pendingProjectImage = 'assets/img/dashboard/pending_project.svg';
	static currentProjectImage = 'assets/img/dashboard/current_project.svg';
	static piggyIconSvg = 'assets/img/dashboard/piggy-icon.svg';
	static closeIconSvg = 'assets/img/close-light.svg';
	static licenseInfoUrl = 'https://flocksy.com/licenses/';
	static discountImage = 'assets/img/create_project/discount-ribbon.svg';
	static checkGreenFilledSvg = 'assets/img/checked-filled.svg';
	static crossGreyFilledSvg = 'assets/img/cross-filled.svg';
	static searchedIcon = 'assets/img/message-center/search-icon.svg';
	static noMessageSelected =
		'assets/img/message-center/no-messages-selected.svg';
	static pinnedMessage = 'assets/img/message-center/pinned.svg';
	static unreadMessage = 'assets/img/message-center/unread_message_dots.svg';
	static bookConsultation = environment.externalSiteUrl + '/schedule-demo';
	static satisfactionGuaranteedImg =
		'assets/img/packages/satisfaction_guaranteed.svg';
	static billingCycleSubject = 'Client request to update billing cycle';
	static switchBillingTypeMessage = 'Message send to administrator.';
	static satisfactionGuaranteedTooltip =
		'We guarantee you will be 100% happy, or else receive a full refund';
	static zapierMessageSource = 2;
	static getApiEndPointPath(): string {
		return environment.apiEndPoint;
	}
	static acceptablePdfFileType = [
		'application/pdf',
		'application/pdf; charset=UTF-8',
	];
	static previewAbleExtensions = [
		'video/mp4',
		'video/mp4; charset=UTF-8 ',
		'video/x-m4v',
		'application/pdf',
		'application/pdf; charset=UTF-8',
		'video/x-m4v; charset=UTF-8',
	];

	static URL_REGEX_HTTP = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
	static URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
	// preview for the pdf file in project detail page

	static getApiVersionURL(): string {
		return environment.apiVersionURL;
	}

	static getApiAnalyticsURL(): string {
		return environment.analyticsEndPoint;
	}

	static getBaseURL(): string {
		return environment.baseURL;
	}

	static projectStatusById = {
		1: 'Pending',
		2: 'Assigned',
		3: 'Completed',
		4: 'Unassigned',
		5: 'Draft',
	};

	static routerLink = {
		'/dashboard': 'Dashboard',
		'/projects/create': 'New Project',
		'/projects/queue': 'Projects Queue',
		'/buckets': 'Brand Buckets',
		'/teams': 'My Creative Team',
		'/messages': 'Messages',
		'/profile': 'My Profile',
		'/finance-and-billing': 'Upgrading',
		'/billing': 'Billing',
		'/subscriptions/plans': 'Manage Plan',
		'/finance': 'Receipts',
		'/projects/completed': 'Completed Projects',
		'/projects/drafts': 'Draft Projects',
		'/project-files': 'Deliverables',
		'/users/invite': 'Account Users',
		'/project': '',
		'/change-password': 'Change Password',
		'/projects/active': 'Project Active',
		'/feedback': 'Feedback',
		'/template-library': 'Template Library',
	};

	static accountActivityInfoType = {
		send_message: 1,
		send_general_message: 2,
		send_project_comment: 3,
		send_file_message: 4,
		send_file_project: 5,
		project_created: 6,
		project_started: 7,
		project_status_changed: 8,
		project_completed: 9,
		account_updates: 10,
		user_reacted_to_message: 11,
	};

	static getImageMimeTypes() {
		return [
			'image/png',
			'image/bmp',
			'image/cis-cod',
			'image/gif',
			'image/ief',
			'image/jpeg',
			'image/jpeg',
			'image/pipeg',
			'image/tiff',
			'image/jpeg; charset=UTF-8',
			'image/webp',
			'image/png; charset=UTF-8',
		];
	}

	static legacyPlanInfo = {
		title: 'Legacy Plan',
		tooltip: "You're on a legacy plan with <br> savings of 30% or more.",
		description:
			'You currently have <strong>legacy pricing</strong> and are <strong>saving up to 30% on your plan!</strong>',
	};

	static getApiEndPoint(): string {
		return this.getApiEndPointPath() + '/' + this.getApiVersionURL();
	}

	static getProjectChatLimit(): number {
		return 20;
	}

	static getProjectListLimit(): number {
		return 75;
	}

	static getImageFileSizeLimit(): any {
		return 20; /* in MB */
	}

	static getDocumentPath(): string {
		return environment.apiEndPoint + '/storage/uploads/';
	}

	static getShowInviteTeamLimit(): number {
		return 300000;
	}

	static getLeadSourceNames(): string[] {
		return [
			'Quora',
			'Friend',
			'Indeed',
			'LinkedIn',
			'Glassdoor',
			'Online research',
			'Other',
		];
	}

	static getMaxFileAnalyticsAttempt(): number {
		return 5;
	}

	static getFileAnalyticsInterval(): number {
		return 500;
	}

	static getStorageServiceLinks(): string[] {
		return [
			'drive.google.com',
			'dropbox.com',
			'1drv.ms',
			'app.box.com',
			'we.tl',
		];
	}

	static getS3fileUploadTimeout(): number {
		return 0;
		// return 6 * 60 * 1000; // 6 mins in milliseconds
	}

	static getMaxEstimatedTaHours(): number {
		return 72;
	}

	static bannerColorOptions() {
		return [
			{
				label: 'Primary',
				value: '#16cc8e',
			},
			{
				label: 'Success',
				value: '#007acc',
			},
			{
				label: 'Info',
				value: '#23b7e5',
			},
			{
				label: 'Warning',
				value: '#ff902b',
			},
			{
				label: 'Danger',
				value: '#f05050',
			},
		];
	}

	static getHMSFromSeconds(seconds) {
		let data: any = {};

		// calculate (and subtract) whole days
		// data.days = Math.floor(seconds / (3600*24));
		// seconds -= data.days * (3600*24);

		// calculate (and subtract) whole hours
		data.hours = Math.floor(seconds / 3600);
		seconds -= data.hours * 3600;

		// calculate (and subtract) whole minutes
		data.minutes = Math.floor(seconds / 60);
		seconds -= data.minutes * 60;

		// what's left is seconds
		data.seconds = seconds;
		return data;
	}

	static getMaxBidAmountForAssignCreative(): number {
		return 40;
	}

	static convertTZ(date, tzString) {
		return new Date(
			(typeof date === 'string' ? new Date(date) : date).toLocaleString(
				'en-US',
				{ timeZone: tzString }
			)
		);
	}

	static getPlatinumTurnAroundTime(): number {
		return 7200;
	}

	static triggerTypes() {
		return {
			users: 1,
			projects: 2,
			stripe_plan: 3,
			package: 13,
		};
	}

	static stateTaxData() {
		return {
			stateId: '3927',
			stateText: 'Connecticut Sales Tax',
			stateTaxValue: 6.5,
		};
	}

	static creativeRequestRemovalTriggers() {
		return {
			'my-team': 1,
			'available-projects': 2,
		};
	}

	static notificationTypes() {
		return {
			1: {
				id: 1,
				icon: 'fas fa-comment',
				type: 'send_message',
				link: this.getBaseURL() + '/messages',
			},
			2: {
				id: 2,
				icon: 'fas fa-comment',
				type: this.getBaseURL() + '/messages',
			},
			3: {
				id: 3,
				icon: 'fas fa-comment',
				type: 'send_comment',
			},
			4: {
				id: 4,
				icon: 'fas fa-comment',
				type: 'asset_upload_message_center',
			},
			5: {
				id: 5,
				icon: 'fas fa-comment',
				type: 'asset_upload_detail_view',
			},
		};
	}

	static projectAuditTriggers() {
		return {
			'archive-projects-plan-changed': 15,
			'status-changed-when-renewal-upgrade': 19,
			'status-changed-when-pending-to-active': 20,
		};
	}

	static projectStatus() {
		return {
			pending: 1,
			assigned: 2,
			completed: 3,
			unassigned: 4,
			draft: 5,
		};
	}

	static creativeLevels() {
		return {
			basic: 1,
			standard: 2,
			premium: 3,
			platinum: 4,
			'platinum-lead': 5,
		};
	}

	static additionalWork() {
		return {
			no: 0,
			yes: 1,
		};
	}

	static creativeLevelsWithTitle() {
		return [
			{
				id: 1,
				title: 'Basic',
			},
			{
				id: 2,
				title: 'Standard',
			},
			{
				id: 3,
				title: 'Premium',
			},
			{
				id: 4,
				title: 'Platinum',
			},
			{
				id: 5,
				title: 'Platinum Lead',
			},
		];
	}

	static iconType = {
		normal: 'normal',
		hovered: 'hovered',
		selected: 'selected',
		disabled: 'disabled',
	};

	static cardBrands() {
		return {
			Visa: {
				img: this.cardBrandImagesPath + '/' + 'visa.svg',
			},
			'American Express': {
				img: this.cardBrandImagesPath + '/' + 'american-express.svg',
			},
			Discover: {
				img: this.cardBrandImagesPath + '/' + 'discover.svg',
			},
			JCB: {
				img: this.cardBrandImagesPath + '/' + 'jcb.svg',
			},
			MasterCard: {
				img: this.cardBrandImagesPath + '/' + 'mastercard.svg',
			},
			UnionPay: {
				img: this.cardBrandImagesPath + '/' + 'union_pay.svg',
			},
			default: {
				img: this.cardBrandImagesPath + '/' + 'default.svg',
			},
			all: {
				img: this.cardBrandImagesPath + '/' + 'all_cards.svg',
			},
		};
	}

	static whiteBackground = [
		'/dashboard',
		'/projects/queue',
		'/projects/drafts',
		'/projects/completed',
		'/finance-and-billing',
		'/buckets',
		'/project-files',
		'/profile',
		'/change-password',
		'/billing',
		'/feedback',
		'/projects',
		'/projects/create',
		'/subscriptions/plans',
		'/plans',
		'/teams',
		'/users/invite',
		'/messages',
		'/plans/billing-info',
		'/account-activity',
		'/white-label',
		'/projects/daily-queue'
	];

	static clientBadges() {
		return {
			'double-diamond': {
				img: this.imagesPath + '/badges/' + 'double-diamond.svg',
				tooltip: 'Double Diamond',
			},
			diamond: {
				img: this.imagesPath + '/badges/' + 'diamond.svg',
				tooltip: 'Diamond',
			},
			gold: {
				img: this.imagesPath + '/badges/' + 'gold.svg',
				tooltip: 'Gold',
			},
			silver: {
				img: this.imagesPath + '/badges/' + 'silver.svg',
				tooltip: 'Silver',
			},
			bronze: {
				img: this.imagesPath + '/badges/' + 'bronze.svg',
				tooltip: 'Bronze',
			},
			'six-weeks': {
				img: this.imagesPath + '/badges/' + 'six-weeks.svg',
				tooltip: 'Within six weeks',
			},
		};
	}

	static clientBadgeWithIds() {
		return {
			1: {
				name: 'Bronze',
				img: this.imagesPath + '/badges/' + 'bronze.svg',
				tooltip: 'Bronze',
				emoji: '🥉 ',
			},
			2: {
				name: 'Silver',
				img: this.imagesPath + '/badges/' + 'silver.svg',
				tooltip: 'Silver',
				emoji: '🥈 ',
			},
			3: {
				name: 'Gold',
				img: this.imagesPath + '/badges/' + 'gold.svg',
				tooltip: 'Gold',
				emoji: '🥇 ',
			},
			4: {
				name: 'Diamond',
				img: this.imagesPath + '/badges/' + 'diamond.svg',
				tooltip: 'Diamond',
				emoji: '💎 ',
			},
			5: {
				name: 'Double Diamond',
				img: this.imagesPath + '/badges/' + 'double-diamond.svg',
				tooltip: 'Double Diamond',
				emoji: '💎💎 ',
			},
			6: {
				name: 'Within six weeks',
				img: this.imagesPath + '/badges/' + 'six-weeks.svg',
				tooltip: 'Within six weeks',
				emoji: '⏳ ',
			},
		};
	}
	
	static sixWeekClientWithEmoji() {
		return {
			1: {
				name: 'MoneyBack',
				emoji: '💼⭕ ',
			},
			2: {
				name: 'NotMoneyBack',
				emoji: '💼🟢 ',
			}
		}
	}

	static userTypeIds() {
		return {
			client: 1,
			designer: 2,
			'project-manager': 3,
			admin: 4,
			'sub-client': 5,
		};
	}

	static timerTriggers() {
		return {
			'auto-timer': 1,
			'manual-timer': 2,
			'stop-timer': 5,
			'other-tasks': 3,
			'switch-tasks': 4,
		};
	}

	static hasAdditionalWork() {
		return {
			1: {
				id: 1,
				title: 'Not A Revision',
			},
			2: {
				id: 2,
				title: 'Undecided',
			},
		};
	}

	static showLoader() {
		const preLoaderProgress = document.querySelector('.preloader-progress');
		preLoaderProgress?.parentElement?.classList.remove('preloader-hidden');
		preLoaderProgress?.parentElement?.classList.add('preloader');
		
	}

	static removeLoader() {
		const preLoaderProgress = document.querySelector('.preloader-progress');
		preLoaderProgress?.parentElement?.classList.remove('preloader');
		preLoaderProgress?.parentElement?.classList.add('preloader-hidden');
	}

	static cropperSettings(): CropperSettings {
		let cropperSettings = new CropperSettings();
		cropperSettings.cropOnResize = true;
		cropperSettings.noFileInput = true;

		cropperSettings.width = 140;
		cropperSettings.height = 150;

		cropperSettings.croppedWidth = 140;
		cropperSettings.croppedHeight = 150;

		cropperSettings.canvasWidth = 140;
		cropperSettings.canvasHeight = 150;

		cropperSettings.minWidth = 50;
		cropperSettings.minHeight = 40;

		cropperSettings.cropperDrawSettings.strokeColor = '#fff';
		cropperSettings.cropperDrawSettings.strokeWidth = 2;

		cropperSettings.rounded = false;
		cropperSettings.keepAspect = true;
		cropperSettings.touchRadius = 0;
		cropperSettings.centerTouchRadius = 20;
		cropperSettings.fileType = 'file';
		return cropperSettings;
	}

	static noteTriggers() {
		return {
			'13': 'project details page',
			'14': 'message center page',
			'15': 'offers info page',
			'16': 'project details page',
			'17': 'user details page',
			'18': 'project details page',
			'19': 'message center',
			'20': 'user details page',
		};
	}

	static notesAddedViaTrigger() {
		return {
			'project-notes-project-details': 13,
			'project-notes-message-center': 14,
			'project-notes-offers-overlay': 15,
			'user-notes-project-details': 16,
			'user-notes-user-details': 17,
			'creative-client-notes-project-details': 18,
			'creative-client-notes-message-center': 19,
			'project-notes-user-details': 20,
		};
	}

	/**
	 * 13 - Add project notes from project details view
	 * 14 - Add project notes from message center
	 * 15 - Add project notes from offers overlay
	 * 16 - Add client notes from project details page
	 * 17 - Add client notes from clients detail view page
	 * 18 - Add client notes by creative from project detail view page
	 * 19 - Add client notes by creative from message center
	 * 20 - Add project notes from clients detail view page
	 */

	static deleteNotesTriggers() {
		return {
			'delete-project-notes-project-detail-view': 36,
			'delete-project-notes-message-center': 37,
			'delete-project-notes-offer-overlay': 38,
			'delete-user-notes-project-detail-view': 39,
			'delete-user-notes-user-detail-view': 40,
			'delete-user-notes-via-creative-user-detail-page': 41,
		};
	}
	static clientBadgesWithIds() {
		return [
			{
				id: 6,
				title: 'First Six Weeks',
			},
			{
				id: 5,
				title: 'Double Diamond',
			},
			{
				id: 4,
				title: 'Diamond',
			},
			{
				id: 3,
				title: 'Gold',
			},
			{
				id: 2,
				title: 'Silver',
			},
			{
				id: 1,
				title: 'Bronze',
			},
		];
	}

	static portfolioFileTypes() {
		return [
			{
				title: 'All Files',
				id: 2,
			},
			{
				title: 'Added to portfolio',
				id: 1,
			},
			{
				title: 'Not added to portfolio',
				id: 0,
			},
		];
	}

	static packageStatusId() {
		return {
			pending: 1,
			approve: 2,
			deny: 3,
		};
	}

	static packageMode() {
		return {
			active: 1,
			paused: 2,
			delete: 3,
		};
	}

	static projectConcepts() {
		return {
			subscriptions: 1,
			marketplace: 2,
		};
	}

	static packageStatusList() {
		return [
			{
				id: 0,
				title: 'All',
			},
			{
				id: 1,
				title: 'Pending',
			},
			{
				id: 2,
				title: 'Approved',
			},
			{
				id: 3,
				title: 'Denied',
			},
		];
	}

	static packageSortingList() {
		return [
			{
				value: '-created_at',
				title: 'Newest First',
			},
			{
				value: 'created_at',
				title: 'Oldest First',
			},
			{
				value: 'total',
				title: 'Price low to high',
			},
			{
				value: '-total',
				title: 'Price high to low',
			},
		];
	}
	static cardTriggers() {
		return {
			'purchase-plan': 9,
			'update-default-card-billing-info': 10,
			'update-card-billing-info': 11,
			'add-new-card': 12,
			'add-new-card-marketplace': 24,
		};
	}

	static authenticationTriggers() {
		return {
			'traditional-signup': 27,
			'marketplace-signup': 28,
			'traditional-login': 29,
			'marketplace-login': 30,
		};
	}

	static marketPlaceConfigTriggerIds() {
		return {
			'marketplace-login': 31,
			'marketplace-signup': 31,
			'user-details': 32,
			'manage-user-plan': 33,
		};
	}

	static projectManagerConfigTriggerIds() {
		return {
			'has_project_manager': 56,
			'has_auto_finalize_access': 62
		};
	}

	static projectStatuses() {
		return {
			pending: {
				id: 1,
				text: 'Pending',
			},
			active: {
				id: 2,
				text: 'Active',
			},
			completed: {
				id: 3,
				text: 'Completed',
			},
			activeUnassigned: {
				id: 4,
				text: 'Active Unassigned',
			},
		};
	}

	static triggers() {
		return {
			'platinum-team-members-added-user-details': 48,
			'platinum-team-members-removed-user-details': 49,
		};
	}

	static createQuantityArray(): number[] {
		const quantityArray: number[] = [];
		for (let i = 0; i <= 9; i++) {
			quantityArray.push(i);
		}
		return quantityArray;
	}

	static teamsProjectTypes = {
		'graphic-design': {
			title: 'graphicDesign',
			path: 'assets/img/teams/graphic_Design.svg',
		},
		copywriting: {
			title: 'copyWriting',
			path: 'assets/img/teams/copy_Writing.svg',
		},
		'video-editing-creation': {
			title: 'videoEditing',
			path: 'assets/img/teams/video_icon.svg',
		},
		video: {
			title: 'videoEditing',
			path: 'assets/img/teams/video_icon.svg',
		},
		'custom-illustrations': {
			title: 'customIllustrations',
			path: 'assets/img/teams/custom_Illustrations.svg',
		},
		'motion-graphics': {
			title: 'motionGraphics',
			path: 'assets/img/teams/motion_Graphics.svg',
		},
		'virtual-assistant': {
			title: 'virtualAssistant',
			path: 'assets/img/teams/virtual_Assistants.svg',
		},
		'web-email-design': {
			title: 'webEmailDesign',
			path: 'assets/img/teams/web_email_design.svg',
		},
		'ai-copy-editing': {
			title: 'aiCopywriting',
			path: 'assets/img/teams/ai_copywriting.svg',
		},
		'website-development': {
			title: 'websiteDevelopment',
			path: 'assets/img/teams/web_dev.svg',
		},
		'voice-overs': {
			title: '"voiceOvers"',
			path: 'assets/img/teams/Voice_over.svg',
		},
		'performer-artist-entertainer': {
			title: '"voiceOvers"',
			path: 'assets/img/teams/performer_artist.svg',
		},
		'copywriting-seo': {
			title: '"voiceOvers"',
			path: 'assets/img/teams/copywriting_seo.svg',
		},
		other: {
			title: '',
			path: 'assets/img/teams/other.svg',
		},
	};

	static permissions = {
		can_view_projects: {
			title: 'View All Projects',
			value: 'can_view_projects',
		},
		can_manage_all_projects: {
			title: 'Manage All Projects',
			value: 'can_manage_all_projects',
		},
		can_manage_own_projects: {
			title: 'Manage Their Projects',
			value: 'can_manage_own_projects',
		},
		can_view_manage_own_projects: {
			title: 'View & Manage Their Projects',
			value: 'can_view_manage_own_projects',
		},
	};

	static screenSizes = {
		XLARGE: 5,
		LARGE: 4,
		MEDIUM: 3,
		SMALL: 2,
		XSMALL: 1
	}

	static flocksySupportEmail() {
		return 'support@flocksy.com';
	}

	static admminUserTxnTaxAmountType() {
		return 8
	}

	static whiteLabelInfoLink = "https://flocksy.com/resources/how-to-set-up-domain-masking-for-your-website/";

	static getSocialLoginsTriggerIds() {
		return [
			{
				title: 'authenticatedFromApp',
				id: 68,
			},
			{
				title: 'authenticatedFromExternalSite',
				id: 67,
			},
		];
	}

	static earlySubscribeDays = 15;

	static ConnecticutTax = 0.065;

	static first30Days = 30;
}
