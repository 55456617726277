import { Injectable } from '@angular/core';
import { CustomHttpService } from '../custom.http.service';
import { Observable, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpService: HttpClient) {}

  fetchFileAsBase64(url: string): Observable<{ base64: string, size: number }> {
    return this.httpService.get(url, { responseType: 'blob' }).pipe(
      switchMap((response: Blob) => {
        const reader = new FileReader();

        return new Observable<{ base64: string, size: number }>(observer => {
          reader.onloadend = () => {
            observer.next({ base64: reader.result as string, size: response.size });
            observer.complete();
          };
          reader.onerror = err => observer.error(err);

          reader.readAsDataURL(response);
        });
      }),
      catchError(err => {
        // Handle errors here if needed
        throw err;
      })
    );
  }

  transformInputToFileStructure(input: any): Observable<any> {
    return this.fetchFileAsBase64(input.full_path).pipe(
      map(({ base64, size }) => ({
        content: base64, // Base64-encoded file content
        file: new File([base64], input.filename, { type: input.file_mime_type }),
        lastModified: Date.now(), // Use the current timestamp or a specific value
        lastModifiedDate: new Date(), // Current date or a specific date
        name: input.filename, // Use the filename as the name
        size: size, // The size obtained from the Blob
        type: input.file_mime_type,
        webkitRelativePath: ''
      }))
    );
  }
}
