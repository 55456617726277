import {
	Component,
	DoCheck,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TemplateRef,
	ChangeDetectorRef,
	ViewChild,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LocalStorageService } from '@services/localstorage.service';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { PaymentService } from '@services/project/payment.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { SubscriptionsService } from '@services/user/subscriptions/subscriptions.service';
import { CardService } from '@services/cards/card.service';
import { CommonService } from '@services/common/common.service';

declare var Rewardful;
@Component({
	selector: 'app-project-payments',
	templateUrl: './payments.component.html',
	styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, DoCheck {
	@Input() isPaymentModalOpen: any;
	@Input() selectedPackage: any;
	@Input() projectsData: any;
	@Input() totalPlanPayments: any;
	@Input() isViaSignUpPage: any; // will be used for hied show contain for signup page
	@Input() currentProject: any;
	@Input() planPaymentInfo: any; // will be used from the plans page when payment is done
	@Input() planList: any; // will be used from the plans page when payment is done
	@Input() isSubscriptionPlan: any;
	@Output() paymentProceed = new EventEmitter<boolean>();
	@Output() updateSubscribedPlans = new EventEmitter<any>();
	@Output() closePaymentModel = new EventEmitter<any>();
	shieldFillUncheck = FlocksyUtil.shieldFillUncheck;
	shieldFillCheck = FlocksyUtil.shieldFillCheck;
	isShowPaymentModel: boolean;
	cardBrands = FlocksyUtil.cardBrands();
	stateTaxData = FlocksyUtil.stateTaxData();
	userId = this._localStorageService.getUserId();
	cards = [];
	modalPaymentRef: BsModalRef;
	addCardModalRef: BsModalRef;
	selectedCard: any;
	showCardsList: boolean = true;
	@ViewChild('paymentModel', { static: true }) paymentModel: TemplateRef<any>;
	@ViewChild('addUserModal', { static: true }) addUserModal: TemplateRef<any>;
	isCardsLoaded: boolean;
	isDisableBtn: boolean;
	discountCouponBgImage = FlocksyUtil.discountBgImage;
	saveCardInfo: boolean;
	btnClicked = false;
	btnDisabled = false;
	isFormValid = false;
	stripeErrValid = false;
	@Output() stateTaxEmit = new EventEmitter<any>();
	fixedTotalPaymentsValue;
	@Input() taxAmt = 0;
	@Input() totalExcludingTax = 0;
	@Input() totalTaxAmount = 0;
	newCardAdd = false;

	constructor(
		public _bsModalService: BsModalService,
		public _localStorageService: LocalStorageService,
		private _paymentService: PaymentService,
		public _analyticsService: AnalyticsService,
		public _subscriptionsService: SubscriptionsService,
		public _router: Router,
		public _toasterService: ToasterService,
		public _commonService: CommonService,
		private cdr: ChangeDetectorRef,
		private _cardService: CardService
	) {
		this.retrieveCards();
	}

	ngOnInit(): void {}

	ngDoCheck(): void {
		this.cdr.detectChanges();
		if (this.fixedTotalPaymentsValue == undefined) {
			if (this.totalExcludingTax) {
				this.fixedTotalPaymentsValue = this.totalExcludingTax
			} else {
				this.fixedTotalPaymentsValue = this.totalPlanPayments;
			}
		}
	}

	onSelectCard(event) {
		setTimeout(() => {
			if (event) {
				this.selectedCard = event;
				this.emitStateTax(this.selectedCard.state_id);
			}
			if (this.isViaSignUpPage) {
				this.onProceedWithPayment();
				this.modalPaymentRef.hide();
			} else {
				this.isCardsLoaded = true;
			}
		});
	}

	initPaymentModal(template: TemplateRef<any>) {
		this.isShowPaymentModel = true;
		this.modalPaymentRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{
					modalClass: 'modal-dialog',
					class: 'modal-lg modal-full payment-model',
					ignoreBackdropClick: true,
				}
			)
		);
		this._bsModalService.onHide.subscribe((reason: string | any) => {
			if (reason === 'backdrop-click') {
				this.closePaymentModel.emit(true);
			}
		});
	}

	initAddNewCardModal(template) {
		this.addCardModalRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{
					modalClass: 'modal-dialog',
					class: 'modal-lg modal-full payment-model',
				}
			)
		);
		if (this.modalPaymentRef) {
			this.modalPaymentRef.hide();
		}
		this.showCardsList = false;
		this.selectedCard = undefined;
		this.isCardsLoaded = false;
	}

	onCloseModel(event) {
		this.showCardsList = true;
		this._cardService.index(this.userId).subscribe((data) => {
			if (data.data.length) {
				this.cards = data.data;
				if (this.cards?.length == 1 && this.isViaSignUpPage) {
					this.selectedCard = this.cards[0];
					this.onProceedWithPayment();
				} else {
					this.selectedCard = event
						? this.cards[this.cards.length - 1]
						: this.cards[0];
				}
			}
		});
		this.addCardModalRef.hide();
		if (this.isViaSignUpPage) {
			this.closePaymentModel.emit();
		}
		if (this.cards?.length) {
			this.initPaymentModal(this.paymentModel);
		}
	}

	onPlanPaymentProcess(payload) {
		let planInfo = {
			country: payload.country_id,
			country_id: payload.country_id,
			cc_id: payload.cc_id,
			state_id: payload.state_id,
			stripe_event_completed: true,
			coupon_code: this.planPaymentInfo?.coupon_code || '',
			coupon_applied_date:
				this.planPaymentInfo?.coupon_applied_date || '',
			interval_count:
				this.planPaymentInfo?.summaryInfo[0]?.interval_count ||
				this.planList[0]?.subscription_items[0]?.interval_count,
			plans: [],
		};
		if (!this.planPaymentInfo?.isCouponApplied) {
			this.planList.forEach((res) => {
				planInfo.plans.push({
					plan_id: res?.plan_id,
					quantity:
						res?.subscription_items[0]?.quantity >= 0
							? res?.subscription_items[0]?.quantity
							: 0,
					old_quantity: res?.oldIntervalQuantity
						? res?.oldIntervalQuantity
						: res?.oldQuantity || 0,
				});
			});
			if (
				!!this.planPaymentInfo?.summaryInfo[0]?.old_interval_count &&
				this.planPaymentInfo?.summaryInfo[0]?.old_interval_count !==
					this.planPaymentInfo?.summaryInfo[0]?.interval_count
			) {
				planInfo['old_interval_count'] =
					this.planPaymentInfo?.summaryInfo[0]?.old_interval_count;
			} else {
				delete planInfo['old_interval_count'];
			}
			this._subscriptionsService
				.update(this._localStorageService.getUserId(), planInfo)
				.subscribe((res) => {
					this.updateSubscribedPlans.emit({ value: true });
					this.onClosePaymentModel();
					this.planPaymentInfo = {};
					this._toasterService.pop('success', res.message);
					// this._commonService.reloadBillingInfoText();
					this._router.navigate(['/finance-and-billing']);
				});
		} else {
			planInfo.plans = this.planPaymentInfo.summaryInfo.map((res) => ({
				plan_id: res.plan_id,
				quantity: res.subscription_items[0]['quantity'],
				old_quantity: res.oldIntervalQuantity
					? res.oldIntervalQuantity
					: res.previousQuantity || 0,
			}));
			this._subscriptionsService
				.store(this._localStorageService.getUserId(), planInfo)
				.subscribe((res) => {
					this._toasterService.pop('success', res.message);
					this.planPaymentInfo = {};
					this._router.navigate(['/plans/hooray']);
					this.onClosePaymentModel();
				});
		}
	}

	onClosePaymentModel() {
		if (this.modalPaymentRef) {
			this.modalPaymentRef.hide();
			this.closePaymentModel.emit();
		}
	}

	getStateTaxAmount() {
		let total = 0;
		if (this.selectedPackage || this.projectsData) {
			if (
				this.selectedCard?.state_id &&
				this.selectedCard?.state_id == this.stateTaxData?.stateId
			) {
				if (!!this.selectedPackage?.discounted_total) {
					total =
						(this.selectedPackage?.discounted_total *
							this.stateTaxData?.stateTaxValue) /
						100;
				} else {
					total =
						((this.selectedPackage?.total
							? this.selectedPackage?.total
							: this.projectsData?.project_package?.total) *
							this.stateTaxData?.stateTaxValue) /
						100;
				}
			}
			return total;
		} else if (
			this.fixedTotalPaymentsValue &&
			this.selectedCard?.state_id == this.stateTaxData?.stateId
		) {
			total =
				(this.fixedTotalPaymentsValue * this.stateTaxData?.stateTaxValue) /
				100;
		}
		return total;
	}

	onProceedWithPayment() {
		let projectId = this.currentProject?.project_id
			? this.currentProject?.project_id
			: this.projectsData?.project?.project_id;
		let payload = {
			title: this.selectedPackage?.title
				? this.selectedPackage?.title
				: this.projectsData?.project?.title,
			amount: this.selectedPackage?.total
				? this.selectedPackage?.total
				: this.projectsData?.project_package?.total,
			country_id: this.selectedCard?.country_id,
			state_id: this.selectedCard?.state_id,
			cc_id: this.selectedCard?.cc_id,
		};
		if (this.isSubscriptionPlan) {
			this.onPlanPaymentProcess(payload);
		} else {
			this._paymentService.store(projectId, payload).subscribe(
				(res) => {
					this.paymentProceed.emit(true);
					this.modalPaymentRef.hide();
					this.cdr.detectChanges();
				},
				(err) => {
					this.isDisableBtn = false;
					this.cdr.detectChanges();
				}
			);
		}
	}
	isAddNewCardFormValidated(event) {
		// need to enable the process to payment button on the add new card validation is full `filled
		if (event.isValid) {
			this.isFormValid = true;
			this.isDisableBtn = !event.isValid;
			this.selectedCard = {};
			this.selectedCard['state_id'] = event.stateId;
		} else {
			this.isFormValid = false;
			this.isDisableBtn = !event.isValid;
			this.selectedCard = {};
			this.selectedCard['state_id'] = event.stateId;
		}
	}

	onPaymentProcess() {
		this.btnClicked = true;
		if (!this.isViaSignUpPage) {
			this.isDisableBtn = true;
			this.onProceedWithPayment();
		} else {
			// save the card info without redirecting to the card list popup for the signup page
			if (this.isFormValid && !this.stripeErrValid) {
				this.isDisableBtn = true;
				this.saveCardInfo = true;
				this.paymentProceed.emit(true);
			}
		}
	}

	retrieveCards() {
		this._cardService.index(this.userId).subscribe(
			(data) => {
				if (this.isPaymentModalOpen) {
					if (!this.isViaSignUpPage || data.data.length) {
						this.initPaymentModal(this.paymentModel);
					}
					if (data.data.length > 0) {
						this.cards = data.data;
						// if (data.data.length) {
						this.isViaSignUpPage = false;
						this.showCardsList = true;
						// }
					} else {
						// this.isViaSignUpPage = true;
						this.initAddNewCardModal(this.addUserModal);
					}
				}
				if (data.data.length) {
					this.cards = data.data;
					if (data.data.length) {
						this.isViaSignUpPage = false;
						// this.initPaymentModal(this.paymentModel);
						this.showCardsList = true;
					}
				}
			},
			(err) => {
				if (this.isPaymentModalOpen) {
					if (this.isViaSignUpPage) {
						this.initAddNewCardModal(this.addUserModal);
					} else {
						this.initPaymentModal(this.paymentModel);
					}
				}
			}
		);
	}

	taxCalculate(event) {
		if (this.isViaSignUpPage) {
			this.selectedCard = {};
			this.isDisableBtn = !event.isValid;
			this.selectedCard['state_id'] = event.stateId;
		}
		this.emitStateTax(event);
	}

	emitStateTax(event) {
		if (this.fixedTotalPaymentsValue == undefined) {
			this.fixedTotalPaymentsValue = this.totalPlanPayments;
		}
		let stateTaxEmitted;
		if (event == this.stateTaxData?.stateId) {
			stateTaxEmitted = (this.fixedTotalPaymentsValue * this.stateTaxData?.stateTaxValue) / 100
		} else {
			stateTaxEmitted = 0;
		}
		if (!this.isViaSignUpPage) {
			if (this.totalTaxAmount) {
				this.taxAmt = this.totalTaxAmount;
			} else {
				this.taxAmt = stateTaxEmitted;
			}
		}
		if (this.isViaSignUpPage || (!this.isViaSignUpPage && !this.totalTaxAmount)) {
			this.stateTaxEmit.emit(stateTaxEmitted);
		}
	}

	stripeErr(event) {
		if (event.isValid) {
			this.stripeErrValid = true;
		} else {
			this.stripeErrValid = false;
		}
	}

	cardErrProcess() {
		this.isDisableBtn = false;
		this.saveCardInfo = false;
		this.stripeErrValid = false;
		this.paymentProceed.emit(false);
	}
}
