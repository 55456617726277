import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthData, CheckEmailResponse, LoginMethod, LoginService } from '@services/login.service';
import { LocalStorageService } from '@services/localstorage.service';
import { ApiService } from '@services/common/api.service';
import { CommonService } from '@services/common/common.service';
import { CouponService } from '@services/coupon/coupon.service';
import { environment } from '@env/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { ClientSubClientService } from '@services/client/client-subclient.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { WhiteLabelService } from '@services/white-label/white-label.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExternalScriptService } from '@services/common/external-script.service';
import { GenericHttpResponse } from '@services/interface';

declare var profitwell;
declare var grecaptcha: any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	emailValForm: FormGroup;
	oError: object = {
		status: '',
	};
	guid: string;
	oCoupon: object = {
		applied: false,
		statusCode: '',
		message: '',
		data: {},
	};
	selectedPlan: object = {
		plan_id: 34,
		plan_cost: 420,
	};
	referralSource = '';
	referralCode;
	loginPageTriggerId =
		FlocksyUtil.authenticationTriggers()['traditional-login'];
	socialModalRef: BsModalRef;
	socialUrl;
	emailReq = false;
	inavlidEmail = false;
	submitted = false;
	isMicrosoftLoading = false;
	isGoogleLoading = false;
	passwordCode:string;
	recaptchaResponse:string;
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	appTitle: string;
	utmSource = '';
	authData = {} as AuthData;
	step: 'EMAIL' | 'CODE' | 'PASSWORD' = 'EMAIL';
	codeFormText = {
		title: 'Finish logging in',
		text: '',
		passwordText: "Please enter the password for your | email account",
		codeText: "Enter the code sent to | to log in"
	};
	codeFormGroup: FormGroup;

	constructor(
		public _settingsService: SettingsService,
		public _formBuilder: FormBuilder,
		public _commonService: CommonService,
		public _loginService: LoginService,
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		public _localStorageService: LocalStorageService,
		public _apiService: ApiService,
		public _couponService: CouponService,
		public _analyticsService: AnalyticsService,
		public _toasterService: ToasterService,
		private _clientSubClientService: ClientSubClientService,
		public _bsModalService: BsModalService,
		public _whiteLabelService: WhiteLabelService,
		public _externalScriptService: ExternalScriptService,
		private _ref: ChangeDetectorRef,
	) {
		this.initForm();
		this.setReferralCodeViaQueryString();
		this.setUtmSourceViaQueryString();

		if (this._activatedRoute.snapshot.paramMap.get('guid')) {
			this.guid = this._activatedRoute.snapshot.paramMap.get('guid');
			this.checkGuidLogin();
		}
		if(this._activatedRoute.snapshot.queryParamMap.get('verification-code')) {
			this.loginWithVerificationCode(this._activatedRoute.snapshot.queryParamMap.get('verification-code'))
		}

		this.setCouponCodeViaQueryString();
		this.setPasswordCodeViaQueryString();
	}

	ngOnInit() {
		this._whiteLabelService.getAppTitle()
			.pipe(takeUntil(this.destroyed$))
			.subscribe((title: string) => {
				this.appTitle = title;
			});
		if (this._localStorageService.isUserLoggedIn()) {
			this.getAuthUser();
		}
		this.initRecaptcha();
	}

	loginWithVerificationCode(code) {
		this._loginService.oAuthToken({
			client_id: environment.VerificationCodeId,
			client_secret: environment.VerificationCodeSecret,
			grant_type: "verification-code",
			username: "test@test.com",
			code: code
		}).subscribe((res) => {
				this._localStorageService.setAccessToken(res.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
			},
			(error) => {
				if(error.status === 404) {
					this._toasterService.clear();
					this._toasterService.pop('error', "Invalid Code", "Please enter valid verification code.");
				}
				this.codeFormGroup.enable();
			},
		);
	}

	async initRecaptcha() {
		await this._loginService.reloadGoogleRecaptchaScript();
	}

	initForm() {
		this.emailValForm = this._formBuilder.group({
			email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			// password: [null, Validators.required],
		});
	}

	setPasswordCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe(async (params) => {
			if ('code' in params) {
				await this._loginService.reloadGoogleRecaptchaScript();
				this.passwordCode = params['code'];
				this.validateOauthPasswordCode();
			}
		});
	}

	setCouponCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('discount_code' in params) {
				this._localStorageService.setCouponCode(
					btoa(params['discount_code']),
				);
				this.onClickValidateCouponCode();
			}
		});
	}

	setReferralCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('r' in params) {
				this.referralSource = params['r'];
				this.referralCode = params['r'];
				this._localStorageService.setReferralCode(btoa(params['r']));
				this._localStorageService.setReferralAppliedDate(new Date());
				this.existsReferralCode();
			}
		});
	}

	setUtmSourceViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('utm_source' in params) {
				this.utmSource = params['utm_source'];
				this._localStorageService.setUtmSource(btoa(params['utm_source']));
			}
		});
	}

	getAuthUser() {
		this._apiService.getAuthUser({ tracker_flagged: 1 }).subscribe(
			(data) => {
				this._localStorageService.setCurrentUser(data.data);
				if (
					this._localStorageService.isClientUser() ||
					this._localStorageService.isSubClientUser()
				) {
					this.getRecentLink();
				}
				this._toasterService.pop('success', `Welcome back to ${this.appTitle}!`);
				this._commonService.redirectUser();
			},
			(err) => {
			},
		);
	}

	submitForm($ev, value: any) {
		this.authData = {} as AuthData;
		$ev.preventDefault();
		this.submitted = true;

		if (this.emailValForm.valid) {
			for (let c in this.emailValForm.controls) {
				this.emailValForm.controls[c].markAsTouched();
			}

			if (!this.emailValForm.valid) {
				return false;
			}
			this.emailValForm.disable();

			this.emailValForm.value['trigger_id'] = this.loginPageTriggerId;
			this.emailValForm.value['username'] = this.emailValForm.value['email'];
			Object.assign(this.authData, this.emailValForm.value);
			this.checkEmail(this.authData);

        } else {
            this.submitted = true;
        }
    }

	async checkEmail(data: any) {
		this._loginService.generateGoogleRecaptchaToken((token) => {
			data['google-recaptcha-response'] = token;
			this.authData['google-recaptcha-response'] = token;
			this._loginService.checkEmail(data).subscribe((res: GenericHttpResponse<CheckEmailResponse>) => {
				if (res?.data?.exists) {
					this.oError = {
						status: false,
					}
					if (res?.data?.method === LoginMethod.CODE) {
						this.loginViaCode();
					} else {
						this.step = 'PASSWORD';
						this.codeFormText.text = this.codeFormText.passwordText.replace("|", data?.username);
						this.codeFormGroup = new FormGroup({
							password: new FormControl('', Validators.required),
						});
						this._ref.detectChanges();
					}
				} else {
					this.oError = {
						status: true,
						error: {
							message: "The user credentials were incorrect."
						}
					}
					this._toasterService.pop(
						'error',
						'The user credentials were incorrect.',
					);
				}
				this.emailValForm.enable();
			},
				error => {
				this.oError = error
				}
				);
		}, 'appLoginPage');
	}

	async loginViaCode() {
		this._loginService.generateGoogleRecaptchaToken((token) => {
			this.authData['google-recaptcha-response'] = token;
			this.step = 'CODE';
			this.codeFormText.text = this.codeFormText.codeText.replace("|", this.authData?.username);
			this.codeFormGroup = new FormGroup({
				verification_code: new FormControl('', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
			});
			this._ref.detectChanges();
			this._loginService.sendVerificationCode(this.authData).subscribe((res) => {
				this._toasterService.pop("success", "Verification Code", "Sent successfully!");
			})
			//create token for recaptcha

		}, 'appLoginPage');
	}

	checkGuidLogin() {
		if (this._localStorageService.isUserLoggedIn()) {
			this._toasterService.pop(
				'error',
				'You\'re already logged in!',
				'Please refresh or open a new browser.',
			);
			return false;
		}

		let auth_data = {
			grant_type: 'password',
			username: 'test@test.com',
			password: 'test@123',
			client_id: environment.ApiClientId,
			client_secret: environment.ApiClientSecret,
			guid: this.guid,
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: null,
		};
		this.loginViaGuid(auth_data);
	}

	loginViaGuid(authData) {
		this._loginService
			.loginGuid(this.guid, JSON.stringify(authData))
			.subscribe(
				(data) => {
					this._localStorageService.setAccessToken(data.access_token);
					this._localStorageService.setUserLoggedIn(true);
					this.getAuthUser();
				},
				(err) => {
				},
			);
	}

	existsReferralCode() {
		if (!this.referralCode) {
			return false;
		}
		this._apiService.existsReferralCode(this.referralCode).subscribe(
			(data) => {
				if ('coupon_code' in data.data) {
					this._localStorageService.setCouponCode(
						btoa(data.data['coupon_code']),
					);
					this.onClickValidateCouponCode();
				}
			},
			(err) => {
			},
		);
	}

	resetCouponData() {
		this.oCoupon = {
			applied: true,
			statusCode: '',
			message: '',
			data: {},
		};
	}

	onClickValidateCouponCode() {
		let coupon_code = this._localStorageService.getCouponCode();
		this.resetCouponData();
		if (coupon_code) {
			this._couponService.exists(atob(coupon_code)).subscribe(
				(data) => {
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = data.message;
					this._toasterService.pop('success', data.message);
					this.oCoupon['statusCode'] = data.status;
					this._localStorageService.setCouponCode(coupon_code);
					this._localStorageService.setCouponAppliedDate(new Date());
					this.oCoupon['data'] = data.data;
				},
				(err) => {
					this._localStorageService.setCouponCode('');
					this._localStorageService.setCouponAppliedDate('');
					this.oCoupon['applied'] = true;
					this.oCoupon['message'] = err.json().message;
					this.oCoupon['statusCode'] = err.json().status;
					this.oCoupon['data'] = {};
				},
			);
		}
	}

	getRecentLink() {
		let params = {
			identifier: 'recent_links',
		};
		this._clientSubClientService
			.getRecentLink(this._localStorageService.getUserId(), params)
			.subscribe((res) => {
				let routerHistoryDetail = [];
				if (res?.data?.details?.length) {
					res.data.details.forEach((resData) => {
						let data = {
							project_id: resData?.project_id,
							title: resData?.title,
							link: resData?.link,
						};
						routerHistoryDetail.push(data);
					});
					this._localStorageService.setItem(
						'routerHistory',
						routerHistoryDetail,
					);
				}
			});
	}

	setCookie(name: string, value: string): void {
		document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; path=/`;
	}

	getTriggerId() {
		const socialLoginsTriggerIds = FlocksyUtil.getSocialLoginsTriggerIds();
		let triggerId = null;

		if (this.getCookieValue('login_source_from_app')) {
			triggerId = socialLoginsTriggerIds.find(
				(item) => item.title === 'authenticatedFromApp',
			)?.id;
		} else if (this.getCookieValue('social_provider')) {
			triggerId = socialLoginsTriggerIds.find(
				(item) => item.title === 'authenticatedFromExternalSite',
			)?.id;
		}
		return triggerId;
	}

	loginWithSocials(type) {
		const width = 600;
		const height = 600;
		const left = window.innerWidth / 2 - width / 2;
		const top = window.innerHeight / 2 - height / 2;
		const options = `width=${width},height=${height},left=${left},top=${top}`;

		if (type == 'google') {
			this.setCookie('login_source_from_app', 'google');
			this.isGoogleLoading = true;
			this.isMicrosoftLoading = false;
			this._apiService.getGoogleCode().subscribe(
				data => {
					this.isGoogleLoading = false;
					const width = 600;
					const height = 600;
					const left = (screen.width / 2) - (width / 2);
					const top = (screen.height / 2) - (height / 2);
					const redirectUri = environment.baseURL + '/social-logins/google/redirect';

					const authWindow = window.open(data.data.redirect_url, 'Google Login', `width=${width},height=${height},top=${top},left=${left}`);

					// Monitor the popup window for the response
					const pollTimer = window.setInterval(function() {
							if (authWindow.closed) {
								window.clearInterval(pollTimer);
								return;
							}
							try {
								const url = authWindow.location.href;
								if (url.indexOf(redirectUri) !== -1) {
									window.clearInterval(pollTimer);
									authWindow.close();
									this.handleGoogleAuthResponse(url);
								}
							} catch (e) {
								this.isGoogleLoading = false;
								// Ignore cross-origin errors until we get the response
							}
						}.bind(this),
						100);
				},
			);
		} else if (type == 'microsoft') {
			this.setCookie('login_source_from_app', 'microsoft');
			this.isMicrosoftLoading = true;
			this.isGoogleLoading = false;
			this._apiService.getMicrosoftCode().subscribe(
				data => {
					this.isMicrosoftLoading = false;
					const width = 600;
					const height = 600;
					const left = (screen.width / 2) - (width / 2);
					const top = (screen.height / 2) - (height / 2);
					const redirectUri = environment.baseURL + '/social-logins/microsoft/redirect';

					const authWindow = window.open(data.data.redirect_url, 'Microsoft Login', `width=${width},height=${height},top=${top},left=${left}`);

					// Monitor the popup window for the response
					const pollTimer = window.setInterval(function() {
							if (authWindow.closed) {
								window.clearInterval(pollTimer);
								return;
							}
							try {
								const url = authWindow.location.href;
								if (url.indexOf(redirectUri) !== -1) {
									window.clearInterval(pollTimer);
									authWindow.close();
									this.handleMicrosoftAuthResponse(url);
								}
							} catch (e) {
								this.isMicrosoftLoading = false;
								// Ignore cross-origin errors until we get the response
							}
						}.bind(this),
						100);
				},
			);
		}
	}

	handleGoogleAuthResponse(url) {
		const urlObj = new URL(url);
		const code = urlObj.searchParams.get('code');
		this.handleSocialLoginToken('google', environment.oauthClients.google.clientId, environment.oauthClients.google.clientSecret, code, 'google');
	}

	handleMicrosoftAuthResponse(url) {
		const urlObj = new URL(url);
		const code = urlObj.searchParams.get('code');
		this.handleSocialLoginToken('microsoft', environment.oauthClients.microsoft.clientId, environment.oauthClients.microsoft.clientSecret, code, 'microsoft');
	}

	getCookieValue(name: string): string | null {
		const nameEQ = name + '=';
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i].trim();
			if (cookie.indexOf(nameEQ) === 0) {
				return cookie.substring(nameEQ.length);
			}
		}
		return null;
	}

	handleSocialLoginToken(providerName, providerClientId, providerClientSecret, code, type) {
		var oAuthData = {
			grant_type: providerName,
			client_id: providerClientId,
			client_secret: providerClientSecret,
			coupon_code: this._localStorageService.getCouponCode()
				? atob(this._localStorageService.getCouponCode())
				: '',
			coupon_applied_date:
				this._localStorageService.getCouponAppliedDate(),
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: this.getCookieValue('r')
					? this.getCookieValue('r')
					: null,
			utm_source: this._localStorageService.getUtmSource()
				? atob(this._localStorageService.getUtmSource())
				: this.getCookieValue('utm_source')
					? this.getCookieValue('utm_source')
					: null,
			trigger_id: this.getTriggerId(),
		};

		// Dynamically add the correct auth_code property
		if (type === 'microsoft') {
			oAuthData['microsoft_auth_code'] = code;
		} else if (type === 'google') {
			oAuthData['google_auth_code'] = code;
		}

		this._loginService.oAuthToken(JSON.stringify(oAuthData)).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
				this.isGoogleLoading = false;
				this.isMicrosoftLoading = false;
			},
			(err) => {
				this._toasterService.pop('error', err.error.message);
				this._router.navigate(['/login']);
				this.isGoogleLoading = false;
				this.isMicrosoftLoading = false;
			},
		);
	}

	validateOauthPasswordCode() {
		if (typeof grecaptcha !== 'undefined') {
			this.generateTokenGoogleRecaptcha();
		}

		let oAuthData = {
			'code': this.passwordCode,
			'google-recaptcha-response': this.recaptchaResponse,
		};
		// Object.assign(this.valForm.value, oAuthData);
		this._loginService.loginWithPasswordCode(oAuthData).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
			},
			(err) => {
				this.emailValForm.enable();
				this.oError = err;
			},
		);
	}

	async generateTokenGoogleRecaptcha() {
		const thisObject = this;
		await grecaptcha.ready(function() {
			grecaptcha
				.execute(environment.reCaptchaSiteKey, {
					action: 'appSignupPage',
				})
				.then(function(token) {
					// thisObject.assignRecaptchaToken(token);
					thisObject.recaptchaResponse = token;
				});
		});
	}

	onCodeSubmit(ev) {
		this.codeFormGroup.markAllAsTouched();
		if (this.codeFormGroup.valid) {
			this.codeFormGroup.disable();
			if (this.step === 'PASSWORD') {
				this.authData = {
					grant_type: 'password',
					username: this.emailValForm.value.email,
					client_id: environment.ApiClientId,
					client_secret: environment.ApiClientSecret,
					referral_source: this._localStorageService.getReferralCode()
						? atob(this._localStorageService.getReferralCode())
						: null,
				};
			} else {
				this.authData = {
					grant_type: 'verification-code',
					username: this.emailValForm.value.email,
					client_id: environment.VerificationCodeId,
					client_secret: environment.VerificationCodeSecret,
					referral_source: this._localStorageService.getReferralCode()
						? atob(this._localStorageService.getReferralCode())
						: null,
				};
			}
			Object.assign(this.authData, this.codeFormGroup.getRawValue());
			this._loginService.oAuthToken(this.authData).subscribe((res) => {
					this.oError = {
						status: false,
					}
					this._localStorageService.setAccessToken(res.access_token);
					this._localStorageService.setUserLoggedIn(true);
					this.getAuthUser();
				},
				(error) => {
					if(error.status === 404) {
						this._toasterService.clear();
						this.oError = {
							status: true,
							error: {
								message: "The verification code you entered is incorrect"
							}
						}
						this._toasterService.pop('error', "Invalid Code", "The verification code you entered is incorrect");
					}
					else {
						this.oError = error
					}
					this.codeFormGroup.enable();
				},
			);
		}
	}

	// assignRecaptchaToken(token) {
	// 	this.valForm.patchValue({ recaptcha_token: token });
	// }

	removeVisibilityGoogleRecaptcha() {
		const elements = document.getElementsByClassName('grecaptcha-badge');
		while (elements.length > 0) {
			elements[0].parentNode.removeChild(elements[0]);
		}
	}

	showEmailField() {
		this.step = "EMAIL";
		this.codeFormGroup.reset();
	}

	destroyGoogleRecaptcha() {
		this.removeVisibilityGoogleRecaptcha();
		this._externalScriptService.removeScript('flocksy_recaptcha');
	}

	ngOnDestroy() {
		this.destroyGoogleRecaptcha();
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
