<div class="wrapper white-background" [ngClass]="{
		'white-background':
			isOperationalClientUser
	}">
	<!-- top navbar-->
	<app-header class="topnavbar-wrapper bb"></app-header>
	<!-- sidebar-->
	<app-sidebar class="aside-container"></app-sidebar>
	<!-- Main section-->
	<section class="section-container"
		[ngClass]="{
			'section-container-margin-bottom-0' : currentUrl == '/account-activity'
		}">
		<div
			(click)="initDemoSignupOverlay()"
			*ngIf="bShowDemoSignup && !isWhiteLabelledUser"
			class="p-1 text-md bg-success text-center clickable">
			You are on the Starter Plan: Upgrade Now
		</div>
		<div
			*ngIf="canShowHolidayBanner"
			class="p-3 bg-success text-center">
			In observance of the Thanksgiving holiday, we'll have limited customer support on Thursday, November 28th. It's also possible that estimated project delivery times may be moved to the next business day due to the holiday, although in most cases, projects will continue to be worked on. Please email support@flocksy.com with any issues.
		</div>
		<!-- Page content-->
		<div
			class="content-wrapper"
			[ngClass]="{
				'pt-5': isPlatinumLevel,
				'white-background':
					whiteBackground.includes(currentUrl) &&
					(isOperationalClientUser || isDesignerUser),
					'layout-padding-right-0': currentUrl == '/account-activity' && activityFeedPadding
			}">
			<router-outlet></router-outlet>
		</div>
	</section>
</div>
<app-demo-signup-overlay></app-demo-signup-overlay>
