import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReferralService } from '../../services/referral.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { DocumentService } from '../../../../services/document/document.service';
import { UserService } from '../../../../services/user/user.service';

@Component({
	selector: 'app-email-success',
	templateUrl: './email-success.component.html',
	styleUrls: ['./email-success.component.scss'],
})
export class EmailSuccessComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();

	emailsList: any[];
	message: string;
	videoLink: string;
	referralLink: string;

	visibleEmails: string[];
	showAll = false;

	constructor(
		public _referralService: ReferralService,
		public _userService: UserService,
		public _documentService: DocumentService,
		public _toasterService: ToasterService
	) {}

	ngOnInit(): void {
		combineLatest([
			this._userService.emailsList$,
			this._userService.emailMessage$,
			this._userService.emailVideoLink$,
			this._referralService.referralLink$,
		])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(([list, message, video, referralLink]) => {
				this.emailsList = list;
				this.message = message;
				this.videoLink = video;
				this.referralLink = referralLink;
			});
			this.updateVisibleEmails();
	}

	resetForm() {
		this.setDefaultFormValues();
		this._referralService.setMethod('email');
	}
	setDefaultFormValues() {
		this._userService.setEmailsList([]);
		this._userService.setRefLinkIsAttached(false);
		this._userService.setEmailMessage(
			this._userService.formInitValues.message
		);
		this._userService.setEmailSubject(
			this._userService.formInitValues.subject
		);
		this._referralService.setIsEmailSent(false);
	}

	copyToClipboard() {
		this._documentService.copyToClipboard(this.referralLink);
		this._toasterService.pop('success', 'Referral URL has been copied');
	}
  
	toggleView() {
	  this.showAll = !this.showAll;
	  this.updateVisibleEmails();
	}
  
	private updateVisibleEmails() {
	  if (this.showAll) {
		this.visibleEmails = this.emailsList;
	  } else {
		this.visibleEmails = this.emailsList.slice(0, 12);
	  }
	}

	ngOnDestroy(): void {
		this.setDefaultFormValues();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
