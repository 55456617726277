import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgesComponent } from './badges.component';
import { PopoverModule } from 'ngx-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
	declarations: [BadgesComponent],
	imports: [CommonModule, TooltipModule, PopoverModule.forRoot()],
	exports: [BadgesComponent],
})
export class BadgesModule {}
