<svg
	width="18"
	height="21"
	viewBox="0 0 18 21"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<g id="Group 687">
		<path
			id="Vector"
			d="M2.45312 3.91016L3.36222 18.4556C3.4054 19.2961 4.01676 19.9102 4.81676 19.9102H13.1804C13.9836 19.9102 14.5836 19.2961 14.6349 18.4556L15.544 3.91016"
			stroke="#949695"
			stroke-width="1.3125"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<g id="Vector_2">
			<path d="M1 3.90625H17H1Z" fill="#C1C1C1" />
			<path
				d="M1 3.90625H17"
				stroke="#949695"
				stroke-width="1.3125"
				stroke-miterlimit="10"
				stroke-linecap="round" />
		</g>
		<path
			id="Vector_3"
			d="M6.0902 3.9091V2.09091C6.08978 1.94754 6.11771 1.80549 6.17238 1.67294C6.22706 1.5404 6.3074 1.41997 6.40878 1.31859C6.51017 1.2172 6.63059 1.13686 6.76314 1.08219C6.89568 1.02752 7.03773 0.999585 7.18111 1H10.8175C10.9608 0.999585 11.1029 1.02752 11.2354 1.08219C11.368 1.13686 11.4884 1.2172 11.5898 1.31859C11.6912 1.41997 11.7715 1.5404 11.8262 1.67294C11.8809 1.80549 11.9088 1.94754 11.9084 2.09091V3.9091M8.99929 6.81819V17M5.72656 6.81819L6.0902 17M12.272 6.81819L11.9084 17"
			stroke="#949695"
			stroke-width="1.3125"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</g>
</svg>
