<div *ngIf="oDesignerForClient && showOverlayContent && !isSkeletonLoading">
	<div class="d-flex profile-flex">
		<div class="profile-image">
			<img
				alt="Image"
				class="profile-pic-size"
				[src]="profilePath"
				onError="this.src='../../../../assets/img/user-placeholder.png';" />
		</div>
		<div>
			<div class="d-flex flex-column mt-2 date-align">
				<div class="team-member-name-text mb-1">
					{{ oDesignerForClient['profile']['name'] }}
				</div>
				<div class="date-joined-text">
					Joined {{
						_localStorageService.formatDate(
							oDesignerForClient['profile']['created_at'],
							'MMM DD, YYYY'
						)
					}}
				</div>
			</div>
			<hr>
			<div class="d-flex stats-flex align-items-start">
				<div class="d-flex flex-row score-align">
					<div class="d-flex flex-column stats-margin mt-2">
						<div class="stats-headings mb-2">
							<span>Average Score</span>
						</div>
						<div class="stats-data-text">
							{{ oDesignerForClient['average_rating'] }}
						</div>
					</div>
					<div class="vertical-line stats-margin"></div>
					<div class="d-flex flex-column stats-margin mt-2">
						<div class="stats-headings mb-2">
							Clients
						</div>
						<div class="stats-data-text">
							{{
								oDesignerForClient[
									'worked_with_other_clients'
								] > 0
									? _localStorageService.formatNumber(
											oDesignerForClient[
												'worked_with_other_clients'
											]
									  )
									: '-'
							}}
						</div>
					</div>
					<div class="vertical-line stats-margin"></div>
					<div class="d-flex flex-column stats-margin mt-2">
						<div class="stats-headings mb-2">
							Project Invites
						</div>
						<div class="stats-data-text">
							{{
								oDesignerForClient[
									'invited_by_other_clients'
								] > 0
									? _localStorageService.formatNumber(
											oDesignerForClient[
												'invited_by_other_clients'
											]
									  )
									: '-'
							}}
						</div>
					</div>
					<div class="vertical-line mr-4 no-margin-mob"></div>
				</div>
				<div class="d-flex flex-column skills-width mt-2 px-0">
					<div class="stats-headings mb-2">
						Skills
					</div>
					<div class="d-flex flex-row flex-wrap flex-gap">
						<div class="skills-pills skills-data-text" *ngFor="
							let skill of uniqueSkills">
							{{ skill }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="my-account-info mt-4">
		<div class="font-16 mb-4">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disable-text-wrapper p-3 font-14 text-muted text-icons padding-top-1-5-rem"
						(click)="recentHistory = true"
						[ngClass]="{
							'nav-link active font-active': recentHistory == true
						}">
						Shared History
					</button>
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disable-text-wrapper p-3 font-14 text-muted svg-icons-display btn-padding-mobile btn-padding-tab"
						[ngClass]="{ 'nav-link active font-active': recentHistory == true }"
						(click)="recentHistory = true"
						>
						<img src="../../../../assets/img/shared-history-dark.svg" alt=""
						*ngIf="recentHistory == true">
						<img src="../../../../assets/img/shared-history-light.svg" alt=""
						*ngIf="recentHistory == false">
					</button>
				</li>
				<li class="nav-item">
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disable-text-wrapper p-3 font-14 text-muted text-icons padding-top-1-5-rem"
						(click)="recentHistory = false"
						[ngClass]="{
							'nav-link active font-active': recentHistory == false
						}"
						>
						Recent Projects
					</button>
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disable-text-wrapper p-3 font-14 text-muted svg-icons-display btn-padding-mobile btn-padding-tab"
						[ngClass]="{ 'nav-link active font-active': recentHistory == false }"
						(click)="recentHistory = false"
						>
						<img src="../../../../assets/img/recent-projects-dark.svg" alt=""
						*ngIf="recentHistory == false">
						<img src="../../../../assets/img/recent-projects-light.svg" alt=""
						*ngIf="recentHistory == true">
					</button>
				</li>
			</ul>
		</div>
	</div>

	<div class="mt-4" *ngIf="recentHistory == true">
		<div class="team-member-worked-text mb-4">
			Projects {{ oDesignerForClient['profile']['name'] }} has worked on for you
		</div>
		<div>
			<div class="projects-block p-3 d-flex flex-row justify-content-between margin-bottom-75-rem" *ngFor="let project of aDesignerProjects">

				<div class="d-flex flex-row mx-0 justify-content-between padding-top-bottom-main-block tab-flex-column col-11 mobile-flex-unset">
					<div class="d-flex flex-row mx-0 review-project-block tab-flex-column">
						<div class="d-flex flex-column justify-content-center review-width">
							<div class="font-14 grey-text mb-1 overflow-hidden-text clickable word-break-all review-text-height">
								<span
									*ngIf="project?.feedback_by_client"
									[tooltip]="sharedFeedback"
									container="body">
									{{project?.feedback_by_client}}
								</span>
								<span
									*ngIf="!project?.feedback_by_client">
									No feedback for this project
								</span>
							</div>
							<ng-template #sharedFeedback>
								<div>
									{{project?.feedback_by_client}}
								</div>
							</ng-template>
							<div class="row mx-0" *ngIf="project?.feedback_by_client_rating">
								<div *ngFor="let star of getStarsArray(project?.feedback_by_client_rating)" class="mr-1">
									<img src="../../../../assets/img/star-solid-my-team.svg" alt="" class="star-dimensions">
								</div>
								<div *ngFor="let emptyStar of getEmptyStarsArray(project?.feedback_by_client_rating)" class="mr-1">
									<img src="../../../../assets/img/star-hollow-my-team.svg" alt="" class="star-dimensions">
								</div>
							</div>
							<div class="row mx-0" *ngIf="!project?.feedback_by_client_rating">
								<div *ngFor="let i of [].constructor(5); let index = index" class="mr-1">
								  <img src="../../../../assets/img/star-hollow-my-team.svg" alt="" class="star-dimensions">
								</div>
							</div>
						</div>
						<div class="d-flex flex-column justify-content-between project-detail-width">
							<div class="font-18 dark-grey-text project-details-field-margin-bottom mb-1">
								<a [routerLink]="['/projects', project?.project_id]"
									target="_blank"
									class="dark-grey-text no-underline"
									(click)="
										_analyticsService.setRouteData(
											21,
											59
										)
									">
									<span
									class="clickable short-file-name"
									>
									{{ project?.title }}
									<img src="../../../../assets/img/link-redirect.svg" alt="" class="ml-2">
									</span>
									<!-- <app-link-icon class="ml-2" [hovered]="hoverId == review.id ? true : false"></app-link-icon> -->
								</a>
							</div>
							<ng-template #projTitle>
								{{ project?.title }}
							</ng-template>
							<div class="font-14 grey-text project-details-field-margin-bottom mb-1">
								{{project?.project_title_name}}
							</div>
							<div class="font-12 dark-grey-text clickable">
								<span 
								*ngIf="project?.created_at"
								>{{
									_localStorageService.formatDate(
										project?.created_at,
										'MMMM DD, YYYY'
									)
								}}</span>
							</div>
							<ng-template #fullReviewDate>
								{{
									_localStorageService.formatDate(
										project?.created_at,
										'MMMM DD, YYYY'
									)
								}}
							</ng-template>
						</div>
					</div>
					<div class="d-flex flex-row creative-width">
						<div class="mr-2">
							<img [src]="profilePath" alt="userProfilePicture"
							onError="this.src='../../../../assets/img/user-placeholder.png';" 
							class="creative-profile clickable"
							>
						</div>
						<div class="d-flex flex-column">
							<div class="font-14 light-grey-text mb-2">
								{{getFirstNameApostrophe(oDesignerForClient['profile']['name'])}} response:
							</div>
							<div class="font-14 grey-text overflow-hidden-text word-break-all clickable"
								>
								<span
									*ngIf="project?.feedback_comment_by_creative"
									[tooltip]="sharedFeedbackResponse"
									container="body">
									{{ project?.feedback_comment_by_creative }}
								</span>
							</div>
							<ng-template #sharedFeedbackResponse>
								<div>
									{{ project?.feedback_comment_by_creative }}
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="mt-4" *ngIf="recentHistory == false">
		<div class="team-member-worked-text mb-4">
			Recent <span *ngIf="!isWhiteLabelledUser">Flocksy</span> Projects
		</div>
		<div>
			<div class="projects-block p-3 d-flex flex-row justify-content-between margin-bottom-75-rem" *ngFor="let project of oDesignerForClient[
				'recent_projects_by_other_clients'
			]">

				<div class="d-flex flex-row mx-0 justify-content-between padding-top-bottom-main-block tab-flex-column col-11 mobile-flex-unset">
					<div class="d-flex flex-row mx-0 review-project-block tab-flex-column">
						<div class="d-flex flex-column justify-content-center review-width">
							<div class="font-14 grey-text mb-1 overflow-hidden-text clickable word-break-all review-text-height">
								<span
									*ngIf="project?.feedback"
									[tooltip]="recentFeedback"
									container="body">
									{{project?.feedback}}
								</span>
								<span
									*ngIf="!project?.feedback">
									No feedback for this project
								</span>
								<ng-template #recentFeedback>
									<div>
										{{project?.feedback}}
									</div>
								</ng-template>
							</div>
							<div class="row mx-0" *ngIf="project?.rating">
								<div *ngFor="let star of getStarsArray(project?.rating)" class="mr-1">
									<img src="../../../../assets/img/star-solid-my-team.svg" alt="" class="star-dimensions">
								</div>
								<div *ngFor="let emptyStar of getEmptyStarsArray(project?.rating)" class="mr-1">
									<img src="../../../../assets/img/star-hollow-my-team.svg" alt="" class="star-dimensions">
								</div>
							</div>
							<div class="row mx-0" *ngIf="!project?.rating">
								<div *ngFor="let i of [].constructor(5); let index = index" class="mr-1">
								  <img src="../../../../assets/img/star-hollow-my-team.svg" alt="" class="star-dimensions">
								</div>
							</div>
						</div>
						<div class="d-flex flex-column justify-content-between project-detail-width">
							<div class="font-18 dark-grey-text project-details-field-margin-bottom mb-1">
								<a [routerLink]="['/projects', project?.project_id]"
									target="_blank"
									class="dark-grey-text no-underline"
									(click)="
										_analyticsService.setRouteData(
											21,
											59
										)
									">
									<span
									class="clickable short-file-name"
									>
									{{ project?.title }}
									<img src="../../../../assets/img/link-redirect.svg" alt="" class="ml-2">
									</span>
									<!-- <app-link-icon class="ml-2" [hovered]="hoverId == review.id ? true : false"></app-link-icon> -->
								</a>
							</div>
							<ng-template #projTitle>
								{{ project?.title }}
							</ng-template>
							<div class="font-14 grey-text project-details-field-margin-bottom mb-1">
								{{project?.project_title_name}}
							</div>
							<div class="font-12 dark-grey-text clickable">
								<span 
								*ngIf="project?.created_at"
								>{{
									_localStorageService.formatDate(
										project?.created_at,
										'MMMM DD, YYYY'
									)
								}}</span>
							</div>
							<ng-template #fullReviewDate>
								{{
									_localStorageService.formatDate(
										project?.created_at,
										'MMMM DD, YYYY'
									)
								}}
							</ng-template>
						</div>
					</div>
					<div class="d-flex flex-row creative-width">
						<div class="mr-2">
							<img [src]="profilePath" alt="userProfilePicture"
							onError="this.src='../../../../assets/img/user-placeholder.png';" 
							class="creative-profile clickable"
							>
						</div>
						<div class="d-flex flex-column">
							<div class="font-14 light-grey-text mb-2">
								{{getFirstNameApostrophe(oDesignerForClient['profile']['name'])}} response:
							</div>
							<div class="font-14 grey-text overflow-hidden-text word-break-all clickable"
								>
								<span
									*ngIf="project?.feedback_comment"
									[tooltip]="recentFeedbackResponse"
									container="body">
									{{ project?.feedback_comment }}
								</span>
							</div>
							<ng-template #recentFeedbackResponse>
								<div>
									{{ project?.feedback_comment }}
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="isSkeletonLoading">
	<div class="d-flex profile-flex">
		<div class="profile-image">
			<app-skeleton-loader
				[appearance]="'circle'"
				[height]="'155px'"
				[width]="'155px'"></app-skeleton-loader>
		</div>
		<div>
			<div class="d-flex flex-column mt-2 date-align">
				<div class="team-member-name-text mb-1">
					<app-skeleton-loader
						[height]="'21.5px'"
						[width]="'155px'"></app-skeleton-loader>
				</div>
				<div class="date-joined-text">
					<app-skeleton-loader
						[height]="'17px'"
						[width]="'155px'"></app-skeleton-loader>
				</div>
			</div>
			<hr>
			<div class="d-flex stats-flex align-items-start">
				<div class="d-flex flex-row score-align">
					<div class="d-flex flex-column stats-margin mt-2">
						<div class="stats-headings mb-2">
							<span>
								<app-skeleton-loader
								[height]="'14.5px'"
								[width]="'37px'"></app-skeleton-loader>
							</span>
						</div>
						<div class="stats-data-text">
							<app-skeleton-loader
							[height]="'21.5px'"
							[width]="'45px'"></app-skeleton-loader>
						</div>
					</div>
					<div class="vertical-line stats-margin"></div>
					<div class="d-flex flex-column stats-margin mt-2">
						<div class="stats-headings mb-2">
							<span>
								<app-skeleton-loader
								[height]="'14.5px'"
								[width]="'37px'"></app-skeleton-loader>
							</span>
						</div>
						<div class="stats-data-text">
							<app-skeleton-loader
							[height]="'21.5px'"
							[width]="'45px'"></app-skeleton-loader>
						</div>
					</div>
					<div class="vertical-line stats-margin"></div>
					<div class="d-flex flex-column stats-margin mt-2">
						<div class="stats-headings mb-2">
							<span>
								<app-skeleton-loader
								[height]="'14.5px'"
								[width]="'37px'"></app-skeleton-loader>
							</span>
						</div>
						<div class="stats-data-text">
							<app-skeleton-loader
							[height]="'21.5px'"
							[width]="'45px'"></app-skeleton-loader>
						</div>
					</div>
					<div class="vertical-line mr-4 no-margin-mob"></div>
				</div>
				<div class="d-flex flex-column skills-width mt-2 px-0">
					<div class="stats-headings mb-2">
						<app-skeleton-loader
							[height]="'14.5px'"
							[width]="'37px'"></app-skeleton-loader>
					</div>
					<div class="d-flex flex-row flex-wrap flex-gap">
						<div class="skills-data-text" *ngFor="
							let skill of [].constructor(3)">
							<app-skeleton-loader
							[height]="'18px'"
							[width]="'92px'"></app-skeleton-loader>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="my-account-info mt-4">
		<div class="font-16 mb-4">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disable-text-wrapper p-3 font-14 text-muted text-icons 
						padding-top-1-5-rem nav-link active font-active"
						>
						<app-skeleton-loader
							[height]="'39px'"
							[width]="'126px'">
						</app-skeleton-loader>
					</button>
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disable-text-wrapper p-3 font-14 text-muted 
						svg-icons-display btn-padding-mobile btn-padding-tab nav-link active font-active"
						>
						<app-skeleton-loader
							[height]="'39px'"
							[width]="'30px'">
						</app-skeleton-loader>
						>
					</button>
				</li>
				<li class="nav-item">
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disable-text-wrapper p-3 font-14 text-muted text-icons padding-top-1-5-rem"
						>
						<app-skeleton-loader
							[height]="'39px'"
							[width]="'126px'">
						</app-skeleton-loader>
					</button>
					<button
						class="btn btn-lg tab-button draft-btn-wrapper disable-text-wrapper p-3 font-14 text-muted svg-icons-display btn-padding-mobile btn-padding-tab"
						>
						<app-skeleton-loader
							[height]="'39px'"
							[width]="'30px'">
						</app-skeleton-loader>
					</button>
				</li>
			</ul>
		</div>
	</div>

	<div class="mt-4">
		<div class="team-member-worked-text mb-4">
			<app-skeleton-loader
				[height]="'25px'"
				[width]="'200px'"></app-skeleton-loader>
		</div>
		<div>
			<div class="projects-block p-3 d-flex flex-row justify-content-between margin-bottom-75-rem" *ngFor="let project of [].constructor(3)">

				<div class="d-flex flex-row mx-0 justify-content-between padding-top-bottom-main-block tab-flex-column col-11 mobile-flex-unset">
					<div class="d-flex flex-row mx-0 review-project-block tab-flex-column">
						<div class="d-flex flex-column justify-content-center review-width">
							<div class="font-14 grey-text mb-1 overflow-hidden-text clickable word-break-all review-text-height">
								<span>
									<app-skeleton-loader
									[height]="'14px'"
									[width]="'60px'"></app-skeleton-loader>
								</span>
							</div>
							<div class="row mx-0">
								<div class="mr-1">
									<app-skeleton-loader
									[height]="'24px'"
									[width]="'221px'"></app-skeleton-loader>
								</div>
							</div>
						</div>
						<div class="d-flex flex-column justify-content-between project-detail-width">
							<div class="font-18 dark-grey-text project-details-field-margin-bottom mb-1">
								<span
									class="dark-grey-text no-underline"
									>
									<span
									class="clickable short-file-name"
									>
									<app-skeleton-loader
									[height]="'21.5px'"
									[width]="'114px'"></app-skeleton-loader>
									</span>
								</span>
							</div>
							<div class="font-14 grey-text project-details-field-margin-bottom mb-1">
								<app-skeleton-loader
									[height]="'17px'"
									[width]="'50px'"></app-skeleton-loader>
							</div>
							<div class="font-12 dark-grey-text clickable">
								<span>
									<app-skeleton-loader
										[height]="'12px'"
										[width]="'71px'">
									</app-skeleton-loader>
								</span>
							</div>
						</div>
					</div>
					<div class="d-flex flex-row creative-width">
						<div class="mr-2">
							<app-skeleton-loader
								[appearance]="'circle'"
								[height]="'40px'"
								[width]="'40px'"></app-skeleton-loader>
						</div>
						<div class="d-flex flex-column">
							<div class="font-14 light-grey-text mb-2">
								<app-skeleton-loader
									[height]="'17px'"
									[width]="'117px'"></app-skeleton-loader>
							</div>
							<div class="font-14 grey-text overflow-hidden-text word-break-all clickable"
								>
								<span>
									<app-skeleton-loader
									[height]="'17px'"
									[width]="'117px'"></app-skeleton-loader>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

