<!-- START Sidebar (left)-->
<div class="aside-inner client-sidebar-wrapper">
	<nav
		[class.show-scrollbar]="settings.getLayoutSetting('asideScrollbar')"
		class="sidebar pb-0 mr-0 overflow"
		[ngClass]="isNotificationModalShown ? 'overflow-hidden test' : ''"
		sidebar-anyclick-close="">
		<div
			class="dropdown notification-dropdown dropdown-list"
			dropdown
			#dropdown="bs-dropdown"
			[insideClick]="true">
			<div class="d-flex justify-content-end">
				<a
					dropdownToggle
					(click)="
						seenAllNotifications();
						isNotificationModalShown = !isNotificationModalShown
					"
					class="nav-link dropdown-toggle dropdown-toggle-nocaret clickable text-right p-0 mr-3 mt-2 pr-1 pt-1">
					<em
						[ngClass]="!!isNotificationModalShown ? 'fas' : 'far'"
						class="fa-bell disable-text-wrapper font-16"></em>
					<span
						class="badge badge-danger badge-icon-wrapper"
						*ngIf="
							unreadNotificationCount > 0 &&
							unreadNotificationCount <= 9
						">
						{{ unreadNotificationCount }}
					</span>
					<span
						class="badge badge-danger max-number-badge-wrapper"
						*ngIf="unreadNotificationCount > 9">
						9+
					</span>
				</a>
				<div *dropdownMenu class="dropdown-menu dropdown-menu-right">
					<div class="dropdown-item">
						<div class="list-group">
							<div class="list-group-item b0 border-0 pt-3">
								<span
									class="d-flex align-items-center float-right"
									*ngIf="notifications?.length">
									<u class="text-decoration-color-wrapper">
										<a
											href="javascript:void(0);"
											(click)="clearAllNotifications()">
											Clear all notifications
										</a>
									</u>
								</span>
							</div>
							<div
								class="list-group-item border-0 notification-section"
								*ngIf="notifications?.length">
								<ng-container
									*ngFor="let item of notifications">
									<div
										class="notification-info-wrapper d-flex">
										<div>
											<img
												class="align-self-start mr-2 circle thumb32 ml-0"
												[src]="
													item?.created_by_user
														?.profile_picture
														?.full_path
												"
												onError="this.src='../../../../assets/img/user-placeholder.png';"
												alt="Image" />
										</div>
										<div class="w-75">
											<div
												class="media-body d-flex align-items-center">
												<div
													class="mb-1 text-sm text-wrap"
													[ngSwitch]="
														item?.notification_type_id
													">
													<span
														*ngSwitchCase="1"
														class="clickable">
														<a
															class="text-dark"
															(click)="
																markAsRead(
																	item?.id,
																	item,
																	$event
																)
															"
															(click)="
																dropdown.isOpen =
																	!dropdown.isOpen
															">
															<span>
																{{
																	item?.plain_content
																}}
															</span>
														</a>
													</span>
													<span
														*ngSwitchCase="2"
														class="clickable">
														<a
															class="text-dark"
															(click)="
																markAsRead(
																	item?.id,
																	item,
																	$event
																)
															">
															<span>
																{{
																	item?.plain_content
																}}
															</span>
														</a>
													</span>
													<span
														*ngSwitchCase="3"
														class="clickable">
														<a
															class="text-dark"
															(click)="
																markAsRead(
																	item?.id,
																	item,
																	$event
																)
															"
															(click)="
																dropdown.isOpen =
																	!dropdown.isOpen
															">
															<span>
																{{
																	item?.plain_content
																}}
															</span>
														</a>
													</span>
													<span
														*ngSwitchCase="4"
														class="clickable">
														<a
															class="text-dark"
															(click)="
																markAsRead(
																	item?.id,
																	item,
																	$event
																)
															"
															(click)="
																dropdown.isOpen =
																	!dropdown.isOpen
															">
															<span>
																{{
																	item?.plain_content
																}}
															</span>
														</a>
													</span>
													<span
														*ngSwitchCase="5"
														class="clickable">
														<a
															class="text-dark"
															(click)="
																markAsRead(
																	item?.id,
																	item,
																	$event
																)
															"
															(click)="
																dropdown.isOpen =
																	!dropdown.isOpen
															">
															<span>
																{{
																	item?.plain_content
																}}
															</span>
														</a>
													</span>
												</div>
												<span
													(click)="
														markAsRead(
															item?.id,
															item,
															$event,
															true
														)
													"
													class="clickable">
													<img
														src="../../../../assets/img/dashboard/cross-icon.svg"
														alt="" />
												</span>
											</div>
											<p>
												<small class="text-muted">
													{{
														_localStorageService.formatRelativeOrAbsoluteDate(
															item?.created_at
														)
													}}
												</small>
											</p>
										</div>
									</div>
									<div class="team-message-wrapper">
										<p
											class="disable-text-wrapper font-12 notification-comment-wrapper"
											*ngIf="
												item.data.message ||
												item.data.text
											">
											<span
												*ngIf="item?.data?.message"
												[innerHTML]="
													item?.data?.message
														| newline
														| innerhtml
														| linkifyHtml
												"></span>
											<span
												*ngIf="item?.data?.text"
												[innerHTML]="
													item?.data?.text
														| newline
														| innerhtml
														| linkifyHtml
												"></span>
										</p>
									</div>
									<!-- <dx -->
								</ng-container>
							</div>
							<div
								*ngIf="false"
								class="overflow-auto"
								[ngClass]="
									!notificationLoaded
										? 'notification-height'
										: 'notificaion-max-height'
								">
								<div
									class="list-group-item border-0"
									*ngIf="notifications?.length">
									<div
										class="media pb-2 pt-1 list-group-item-action"
										*ngFor="let item of notifications">
										<div>
											<img
												class="align-self-start mx-2 circle thumb32"
												[src]="
													item?.created_by_user
														?.profile_picture
														?.full_path
												"
												onError="this.src='../../../../assets/img/user-placeholder.png';"
												alt="Image" />
										</div>
										<div class="media-body">
											<span
												class="mb-1 text-sm text-wrap"
												[ngSwitch]="
													item?.notification_type_id
												">
												<span
													*ngSwitchCase="1"
													class="clickable">
													<em
														class="d-inline-flex text-primary align-middle  {{
															notificationTypes[
																item
																	?.notification_type_id
															]['icon']
														}} mr-2"></em>
													<a
														class="text-dark"
														(click)="
															markAsRead(
																item?.id,
																item,
																$event
															)
														"
														(click)="
															dropdown.isOpen =
																!dropdown.isOpen
														">
														<span>
															{{
																item?.plain_content
															}}
														</span>
													</a>
												</span>
												<span
													*ngSwitchCase="2"
													class="clickable">
													<em
														class="d-inline-flex text-primary align-middle  {{
															notificationTypes[
																item
																	?.notification_type_id
															]['icon']
														}} mr-2"></em>
													<a
														class="text-dark"
														(click)="
															markAsRead(
																item?.id,
																item,
																$event
															)
														">
														<span>
															{{
																item?.plain_content
															}}
														</span>
													</a>
												</span>
												<span
													*ngSwitchCase="3"
													class="clickable">
													<em
														class="d-inline-flex text-primary align-middle  {{
															notificationTypes[
																item
																	?.notification_type_id
															]['icon']
														}} mr-2"></em>
													<a
														class="text-dark"
														(click)="
															markAsRead(
																item?.id,
																item,
																$event
															)
														"
														(click)="
															dropdown.isOpen =
																!dropdown.isOpen
														">
														<span>
															{{
																item?.plain_content
															}}
														</span>
													</a>
												</span>
												<span
													*ngSwitchCase="4"
													class="clickable">
													<em
														class="d-inline-flex text-primary align-middle  {{
															notificationTypes[
																item
																	?.notification_type_id
															]['icon']
														}} mr-2"></em>
													<a
														class="text-dark"
														(click)="
															markAsRead(
																item?.id,
																item,
																$event
															)
														"
														(click)="
															dropdown.isOpen =
																!dropdown.isOpen
														">
														<span>
															{{
																item?.plain_content
															}}
														</span>
													</a>
												</span>
												<span
													*ngSwitchCase="5"
													class="clickable">
													<em
														class="d-inline-flex text-primary align-middle  {{
															notificationTypes[
																item
																	?.notification_type_id
															]['icon']
														}} mr-2"></em>
													<a
														class="text-dark"
														(click)="
															markAsRead(
																item?.id,
																item,
																$event
															)
														"
														(click)="
															dropdown.isOpen =
																!dropdown.isOpen
														">
														<span>
															{{
																item?.plain_content
															}}
														</span>
													</a>
												</span>
												<p>
													<small class="text-muted">
														{{
															_localStorageService.formatDate(
																item?.created_at,
																'MMM DD, YYYY h:mm A'
															)
														}}
													</small>
												</p>
												<p
													class="notificaion-comment"
													*ngIf="
														item.data.message ||
														item.data.text
													">
													<span
														*ngIf="
															item?.data?.message
														"
														[innerHTML]="
															item?.data?.message
																| newline
																| innerhtml
																| linkifyHtml
														"></span>
													<span
														*ngIf="item?.data?.text"
														[innerHTML]="
															item?.data?.text
																| newline
																| innerhtml
																| linkifyHtml
														"></span>
												</p>
											</span>
										</div>
										<div
											class="mr-2 ml-4 align-self-center">
											<em
												class="text-muted fas fa-times clickable"
												(click)="
													markAsRead(
														item?.id,
														item,
														$event,
														true
													)
												"></em>
										</div>
									</div>
								</div>
								<div
									class="preloader"
									*ngIf="!notificationLoaded">
									<div class="preloader-progress">
										<div
											class="preloader-progress-bar"></div>
									</div>
								</div>
							</div>
							<div
								class="list-group-item text-center border-0"
								*ngIf="
									notifications?.length <= 0 &&
									notificationLoaded
								">
								<div class="media pb-1 pt-2">
									<div class="media-body">
										<em
											class="fas fa-check fa-3x text-primary pb-3"></em>
										<p class="text-sm notifaction-text">
											You've read all your notifications
										</p>
									</div>
								</div>
							</div>
							<div
								class="list-group-item b0"
								*ngIf="notificationLoaded">
								<span
									class="d-flex align-items-center float-right">
									<u class="text-decoration-color-wrapper">
										<a
											href="javascript:void(0);"
											(click)="
												initSeeAllNotifications(
													seeAllNotificationsModal
												)
											">
											See all notifications
										</a>
									</u>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<app-userblock [currentUrl]="currentUrl"></app-userblock>
		<!-- START sidebar nav-->
		<ul class="sidebar-nav pb-3">
			<li
				class="mx-3 sidebar-item-wrapper hover-over"
				*ngFor="let oMenu of aMenuItems"
				id="{{ oMenu?.id }}"
				[routerLinkActive]="['active']"
				[class.active]="
					(oMenu?.extraActiveUrls &&
						oMenu?.extraActiveUrls?.indexOf(router.url) > -1) ||
					oMenu?.url == router.url
				">
				<a
					class="my-1"
					(click)="
						oMenu.pagePlacementId
							? _analyticsService.setRouteData(
									oMenu.pagePlacementId[0],
									oMenu.pagePlacementId[1]
							  )
							: ''
					"
					*ngIf="!oMenu.static && !oMenu.creativeLevelId"
					[attr.route]="oMenu.url"
					[routerLinkActive]="['active']"
					[routerLink]="'/' + oMenu.url"
					[class.custom_active]="
						oMenu?.extraActiveUrls &&
						oMenu?.extraActiveUrls?.indexOf(router.url) > -1
					">
					<em class="{{ oMenu?.icon }} font-16" *ngIf="oMenu.text != 'Account Activity'"></em>
					<img 
						[src]="
							isNavigatingToActivityFeed 
								? 'assets/img/activity-feed-page/account-activity-logo.svg' 
								: (router.url == '/account-activity' 
									? oMenu?.icon 
									: 'assets/img/activity-feed-page/account-activity-unselected.svg')"
						alt="grid-icon" 
						class="font-16 mr-2 hover-image"
						*ngIf="oMenu.text == 'Account Activity'">

					<span (click)="clearunreadCount($event, oMenu.text)">
						{{ oMenu.text }}
						<span
							*ngIf="oMenu?.data?.unread_comments > 0"
							class="badge badge-danger sidebar_unread_count">
							{{ oMenu?.data.unread_comments }}
						</span>
						<span
							*ngIf="
								isEligibleRealTime &&
								router.url != '/messages' &&
								oMenu?.data?.unread_count > 0
							"
							class="badge badge-danger sidebar_unread_count">
							{{ oMenu?.data.unread_count }}
						</span>
						<span
							*ngIf="
								!isEligibleRealTime &&
								oMenu?.data?.unread_count > 0
							"
							class="badge badge-danger sidebar_unread_count">
							{{ oMenu?.data.unread_count }}
						</span>
						<span
							*ngIf="oMenu?.data?.notify_text"
							class="badge badge-danger sidebar_unread_count">
							{{ oMenu?.data.notify_text }}
						</span>
					</span>
				</a>

				<a
					(click)="
						oMenu.pagePlacementId
							? _analyticsService.setRouteData(
									oMenu.pagePlacementId[0],
									oMenu.pagePlacementId[1]
							  )
							: ''
					"
					*ngIf="
						!oMenu.static && oMenu.creativeLevelId && isPlatinumLead
					"
					[attr.route]="oMenu.url"
					[routerLinkActive]="['active']"
					[routerLink]="'/' + oMenu.url"
					[class.custom_active]="
						oMenu?.extraActiveUrls &&
						oMenu?.extraActiveUrls?.indexOf(router.url) > -1
					">
					<em class="{{ oMenu?.icon }}"></em>
					<span>{{ oMenu.text }}</span>
				</a>

				<a *ngIf="oMenu.static" [href]="oMenu.url" target="_blank">
					<em class="{{ oMenu?.icon }}" *ngIf="oMenu.text != 'Account Activity'"></em>
					<span>{{ oMenu.text }}</span>
				</a>
			</li>
		</ul>

		<ul class="sidebar-nav bt pl-3 py-3 mx-3 my-2">
			<li class="my-2">
				<p class="mb-0">
					<img
						src="../../../assets/img/dashboard/link-outline.svg"
						class="mr-2"
						alt="" />
					<span class="">Recent Links</span>
				</p>
			</li>
			<ng-container *ngFor="let router of getRecentLinkData">
				<div
					class="my-2 text-underline clickable"
					*ngIf="router?.project_title || router?.title">
					<a [routerLink]="router.link" class="text-dark">
						<span class="router-link-title">
							{{
								!!router?.project_title
									? router?.project_title
									: router?.title
							}}
						</span>
					</a>
				</div>
			</ng-container>
		</ul>

		<ul class="px-3 mx-3 bt text-center">
			<a
				href="javascript:void(0);"
				class="tutorial-btn-wrapper"
				type="button"
				(click)="
					startIntroJs();
					_analyticsService.setOverlayTrackData(16, 61)
				"
				*ngIf="isOperationalClientUser">
				<span class="text-underline">Tutorial</span>
			</a>
		</ul>

		<!-- END sidebar nav-->
	</nav>

	<footer app-footer class="footer"></footer>
</div>
<!-- END Sidebar (left)-->
<ng-template #seeAllNotificationsModal>
	<app-notifications-detailview
		(emitCloseModal)="
			modalSeeAllNotificationsRef.hide()
		"></app-notifications-detailview>
</ng-template>
