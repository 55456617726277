import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../../../common/constant/ApiConstants';
import { CustomHttpService } from '../../../custom.http.service';

@Injectable({
	providedIn: 'root',
})
export class StatService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(clientId, creativeId, oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (let key in oParams) {
			if (Array.isArray(oParams[key])) {
				let i = 0;
				for (const value of oParams[key]) {
					params.set('include[' + i + ']', value);
					i++;
				}
			} else {
				params.set(key, oParams[key]);
			}
		}

		return this._customHttpService.get(
			`${
				API_CONSTANTS.USERS_URL
			}/clients/${clientId}/creatives/${creativeId}/stats?${
				params.toString() ? '&' + params.toString() : ''
			}`
		);
	}
}
