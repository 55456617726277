import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerComponent } from './pdf-viewer.component';

@NgModule({
	imports: [CommonModule],
	exports: [PdfViewerComponent],
	declarations: [PdfViewerComponent],
	providers: [],
})
export class PdfViewerModuleModule {}
