import { Component, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BehaviorSubject } from 'rxjs';

export enum CreateProjectOverlayCloseAction {
	MINIMISE = 'MINIMISE',
	CLOSE = 'CLOSE'
}

@Component({
	selector: 'app-create-project-overlay',
	templateUrl: './create-project-overlay.component.html',
	styleUrls: ['./create-project-overlay.component.scss'],
})
export class CreateProjectOverlayComponent implements OnInit {
	@ViewChild('saveDraftModal') saveDraftModalTemplate: TemplateRef<any>;
	saveModalRef: BsModalRef;
	saveDraft$ = new BehaviorSubject(false);

	@Output() onClose = new EventEmitter<CreateProjectOverlayCloseAction>();

	@HostListener('scroll', ['$event'])
	onScroll(ev: any) {
		ev.stopPropagation();
	}

	isEdited: boolean = false;

	constructor(
		public modalService: BsModalService,
	) {
	}

	ngOnInit(): void {
	}

	closeSaveDraftModal(save: boolean) {
		this.saveModalRef.hide();
		if (!save) {
			this.isEdited = false;
			this.closeModal(CreateProjectOverlayCloseAction.CLOSE);
			return;
		}
		this.saveDraft$.next(save);
	}

	closeModal(action: CreateProjectOverlayCloseAction) {
		if (action === CreateProjectOverlayCloseAction.CLOSE && this.isEdited) {
			this.saveModalRef = this.modalService.show(this.saveDraftModalTemplate, {
				ignoreBackdropClick: true,
				backdrop: true,
				class: 'modal-lg',
			});
		} else {
			this.onClose.emit(action);
		}
	}

	public readonly CreateProjectOverlayCloseAction = CreateProjectOverlayCloseAction;
}
