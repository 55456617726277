<div class="success-wrapper">
	<div class="d-flex justify-content-start align-items-center">
		<div class="">
			<img
				src="assets/img/referral-link/success.svg"
				alt="success" />
		</div>
		<div class="ml-3">
			<div class="success-title-text">
				You have just shared your referral link with your friends!
			</div>
			<div class="text-14">
				Your stats will show you how much you earn, how many people
				click your link and how many sign ups you have.
			</div>
		</div>
	</div>
	<hr class="mt-4 mb-4">
	<div class="mb-3">Friends you shared with:</div>
	<div class="container p-0">
		<div class="row">
			<div class="col-3" *ngFor="let email of visibleEmails">
				<div class="email-item text-truncate mt-1 mb-1">
					<span>
						<img
							src="assets/img/referral-link/email-green.svg"
							alt="email"
							width="18px"
							height="14px" />
					</span>
					<span class="ml-1">
						{{ email }}
					</span>
				</div>
			</div>
		</div>

		<div
			*ngIf="!showAll && emailsList.length > 12"
			class="text-center mt-3">
			<div
				class="text-decoration-underline text-muted clickable"
				(click)="toggleView()">
				See all
			</div>
		</div>
	</div>
	<hr />
	<div class="container p-0">
		<div class="row">
			<div class="col-6">
				<div class="mb-2">Your message:</div>
				<div class="d-flex">
					<div>
						<span>
							<img
								src="../../../assets/img/referral-link/quote.svg"
								alt="email"
								width="18px"
								height="14px" />
						</span>
					</div>
					<div class="ml-1">
						{{ message }}
					</div>
				</div>
			</div>
			<div class="col-6" *ngIf="videoLink">
				<div class="mb-2">Your recorded video message:</div>
				<iframe
					width="240"
					height="200"
					frameborder="0"
					allowfullscreen=""
					[src]="videoLink"></iframe>
			</div>
		</div>
	</div>
</div>
<hr>
<div class="w-100">
	<div class="d-flex justify-content-between">
	  <div class="text-decoration-underline clickable text-muted" (click)="resetForm()">
		Go back
	  </div>
	  <div class="text-decoration-underline clickable d-flex align-items-center" (click)="copyToClipboard()">
		<img
		  class="copy-image social-media-icon"
		  src="../../../assets/img/referral-link/clipboard2-check-fill.svg"
		  alt="copy" width="16px" height="16px" />
		<div class="d-inline-block ml-1 text-muted">Copy link</div>
	  </div>
	</div>
  </div>
  
