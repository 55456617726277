import { NgModule } from '@angular/core';
import { DemoSignupOverlayComponent } from './demo-signup.overlay.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [CommonModule, RouterModule],
	exports: [DemoSignupOverlayComponent],
	declarations: [DemoSignupOverlayComponent],
	providers: [],
})
export class DemoSignupOverlayModule {}
