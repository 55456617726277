<div
	class="alert alert-success m-2"
	*ngIf="
		oProject?.is_category_plan == false &&
		!hasMarketplaceAccess &&
		action == 'start-project'
	">
	<div class="row">
		<div class="col-auto">
			<em class="fa-exclamation-triangle fas"></em>
		</div>
		<div class="col pl-0">
			<p>
				You have changed your plan on
				<span *ngIf="oProject?.plan?.last_purchased_at">
					{{
						_localStorageService.formatDate(
							oProject?.plan?.last_purchased_at,
							'MMM DD, YYYY h:mm A'
						)
					}}
				</span>
				and your current plan does not include
				{{ oProject.project_type_title }} projects. Please contact us
				for more info.
			</p>
			<span>
				<!-- _analyticsService.setRouteData(17,37); -->
				<button
					class="btn btn-secondary"
					type="button"
					(click)="
						initArchiveProjectModal(archiveProjectModal, oProject);
						_analyticsService.setOverlayTrackData(
							21,
							65,
							oProject.project_id,
							2
						)
					"
					*ngIf="
						oProject &&
						(isClientUser ||
							(isSubClientUser &&
								userId == oProject.created_by)) &&
						(oProject.project_status_id == 1 ||
							oProject.project_status_id == 3 ||
							oProject.project_status_id == 5) &&
						!oProject.archived_by
					">
					<span class="mr-2 ml-2">Archive Project</span>
				</button>
			</span>
		</div>
	</div>
</div>
<div
	class="container-fluid"
	*ngIf="oProject?.is_category_plan != false || hasMarketplaceAccess">
	<div class="page-header mt-0 clearfix h3">Start new project</div>
</div>

<div
	class="row"
	*ngIf="oProject?.is_category_plan != false || hasMarketplaceAccess">
	<div class="col">
		<div
			class="alert alert-info clickable"
			*ngIf="isOperationalClientUser && isDemoPlan"
			(click)="initDemoSignupOverlay()">
			Any projects created will be moved to your pending queue until your
			account is upgraded. Upgrade now to have this project activated and
			worked on
		</div>
		<div
			class="text-center"
			*ngIf="isAdminUser || (isProjectManagerUser && !bCopyBrief)">
			<button
				class="btn btn-lg"
				[ngClass]="{ 'btn-success': statusForAdmin == 'all' }"
				(click)="adminFilter('all')">
				Active & Inactive
			</button>
			<button
				class="btn btn-lg"
				[ngClass]="{ 'btn-success': statusForAdmin == 'active' }"
				(click)="adminFilter('active')">
				Active
			</button>
		</div>

		<div>
			<accordion [closeOthers]="false" class="card-group">
				<accordion-group
					#stepgroup1
					[isOpen]="true"
					panelClass="card shadow mb-4 project-edit-view-wrapper"
					(isOpenChange)="
						onChangeAccordion(
							$event,
							sectionType['project-category-types']
						)
					">
					<div
						accordion-heading
						class="text-primary text-bold mt-2 clickable">
						<span class="h4">
							1. What type of project are you starting?
							<em
								class="fa fa-info-circle clickable ml-1 f-18"
								[popover]="projectTooltip"
								triggers="mouseenter:mouseleave click"
								placement="auto"
								(click)="$event.stopPropagation()"></em>
							<ng-template #projectTooltip>
								Choose the type of work you need done. If your
								project requires more than one of these, please
								create separate project briefs.
							</ng-template>
						</span>
						<span
							*ngIf="!this.accordion?.step1?.collapsed"
							class="fa fa-angle-down text-primary mr-2 float-right"></span>
						<span
							*ngIf="this.accordion?.step1?.collapsed"
							class="fa fa-angle-up text-primary mr-2 float-right"></span>
						<hr class="mb-0" />
					</div>
					<div id="collapse01">
						<div class="row">
							<div class="col-md-12" *ngIf="oDefs">
								<div
									*ngIf="
										oDefs &&
										oDefs.types &&
										oSelectedType &&
										oSelectedTitle &&
										bDisableType &&
										bDisableTitle
									">
									<h3 class="text-success">
										{{ oSelectedType.title }} Project For
										{{ oSelectedTitle.title }}
									</h3>
								</div>
								<div class="clearfix"></div>
								<div
									class="alert alert-info mb-0"
									role="alert"
									*ngIf="
										oQueryString &&
										oQueryString.parent_action &&
										oQueryString.parent_action == 1
									">
									You are creating a new project. Once you
									submit it, your previous project will close
									out and this one will start.
								</div>
								<div
									class="font-14 font-weight-semi-bold mb-4 text-center"
									*ngIf="
										hasMarketplaceAccess &&
										oProjectsData?.project?.concept_id !=
											projectConcepts?.marketplace &&
										!isSubClientUser &&
										oDefs.types &&
										!(
											authSeamLessData?.plan_activated &&
											isUpdatedBilling &&
											!isUserOnPausePlan
										)
									">
									<span
										*ngIf="
											!isUserOnPausePlan &&
											isUpdatedBilling &&
											!authSeamLessData?.is_plan_cancelled
										">
										If you're looking for unlimited
										projects, upgrade your account now to
										gain access to all our incredible
										features.
									</span>
									<span
										*ngIf="
											!authSeamLessData?.plan_activated &&
											isUpdatedBilling &&
											authSeamLessData?.is_plan_cancelled
										">
										If you're looking for unlimited
										projects, renew your subscription now to
										gain access to all our incredible
										features.
									</span>
									<span *ngIf="isUserOnPausePlan">
										If you're looking for unlimited
										projects, reactivate your account now to
										gain access to all our incredible
										features.
									</span>

									<span *ngIf="!isUpdatedBilling">
										If you're looking for unlimited
										projects, update your billing info now
										to gain access to all our incredible
										features.
									</span>
								</div>
								<div
									*ngIf="oDefs && !bDisableType"
									[ngClass]="
										hasMarketplaceAccess &&
										oProjectsData?.project?.concept_id !=
											projectConcepts?.marketplace &&
										!isSubClientUser &&
										!(
											authSeamLessData?.plan_activated &&
											isUpdatedBilling &&
											!isUserOnPausePlan
										)
											? 'disable-category'
											: ''
									">
									<ng-container
										*ngIf="
											oDefs?.types &&
											oDefs?.types?.length > 0
										">
										<span
											class="d-flex f-18 flex-wrap ml-1">
											<strong class="mr-2">
												Subscription categories
											</strong>
											<span
												class="d-flex align-items-baseline">
												<p>
													(Included with your
													subscription)
												</p>
												<i
													class="fa fa-info-circle text-primary ml-3 clickable"
													[popover]="
														'What type of project are you starting (included with your subscription)?'
													"
													triggers="mouseenter:mouseleave click"
													placement="auto"></i>
											</span>
										</span>
										<div class="row row-flush">
											<div
												*ngFor="
													let type of oDefs?.types
												"
												class="col-12 col-lg-3 col-md-6 col-sm-6 w-20 px-1 mb-2">
												<div
													class="row row-flush h-100 clickable"
													(click)="onNextTitle(type)">
													<div
														class="col-4 text-center d-flex align-items-center justify-content-center project-type-icon rounded-left"
														[ngClass]="{
															'project-type-icon-active':
																category?.type ==
																type?.project_type_id
														}">
														<em
															*ngIf="
																type?.icon_class
															"
															class="{{
																type?.icon_class
															}} f-25"></em>
														<em
															*ngIf="
																!type?.icon_class
															"
															class="fas fa-file-alt f-25"></em>
													</div>
													<div
														class="col-8 bg-primary d-flex align-items-center rounded-right"
														[ngClass]="{
															'project-type-active':
																category?.type ==
																type?.project_type_id
														}">
														<div
															class="card-body px-2 py-2 text-center">
															<p
																class="mb-0 text-uppercase">
																{{
																	type?.title
																}}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
								<!-- upgrade btn -->
								<div
									*ngIf="
										oDefs?.types?.length &&
										hasMarketplaceAccess &&
										oProjectsData?.project?.concept_id !=
											projectConcepts?.marketplace &&
										!isSubClientUser &&
										!bDisableType &&
										!(
											authSeamLessData?.plan_activated &&
											isUpdatedBilling &&
											!isUserOnPausePlan
										)
									"
									class="row clickable upgrade-button"
									[ngClass]="{
										'upgrade-button-top':
											oDefs?.types?.length < 6
									}"
									(click)="redirectForUpgradePlan()">
									<div
										class="col-4 text-center d-flex align-items-center justify-content-center upgrade-icon rounded-left">
										<em class="fa fa-user-tie font-26"></em>
									</div>
									<div
										class="col-8 d-flex align-items-center rounded-right">
										<div
											class="card-body px-2 py-2 text-center">
											<p
												class="mb-0 text-uppercase text-white"
												*ngIf="
													!isUserOnPausePlan &&
													isUpdatedBilling &&
													!authSeamLessData?.is_plan_cancelled
												">
												Upgrade
											</p>
											<p
												class="mb-0 text-uppercase text-white"
												*ngIf="isUserOnPausePlan">
												Reactivate account
											</p>
											<p
												class="mb-0 text-uppercase text-white"
												*ngIf="!isUpdatedBilling">
												Update billing info
											</p>
											<p
												class="mb-0 text-uppercase text-white"
												*ngIf="
													!authSeamLessData?.plan_activated &&
													isUpdatedBilling &&
													authSeamLessData?.is_plan_cancelled
												">
												Resubscribe
											</p>
										</div>
									</div>
								</div>
								<div
									*ngIf="
										marketPlaceCategories &&
										!bDisableType &&
										hasMarketplaceAccess &&
										oDefs?.types?.length &&
										!isSubClientUser
									">
									<hr />
									<span
										class="d-flex justify-content-between align-items-center">
										<div
											class="d-flex align-items-center mr-1 my-2 flex-wrap ml-1">
											<img
												class="marketplace-image-wrapper"
												[src]="newLabelBackgroundImage"
												alt="" />
											<span
												class="marketplace-label-wrapper">
												New
											</span>
											<span
												class="d-flex align-items-baseline">
												<h4
													class="marketplace-header ml-3 mr-2">
													Marketplace
												</h4>
											</span>
											<div
												class="d-flex align-items-center f-18">
												<span>
													(Purchase one-off projects)
												</span>
												<i
													class="fa fa-info-circle text-primary ml-3 clickable"
													[popover]="
														marketPlaceTooltip
													"
													triggers="mouseenter:mouseleave click"
													placement="auto"></i>
											</div>
										</div>
										<!-- <div>
												<span
													class="d-flex align-items-baseline">
													<em
														class="fas fa-external-link-alt text-primary"></em>
													<p class="ml-1">
														Learn more about the
														Marketplace
													</p>
													<em
														class="fas fa-chevron-right ml-1 text-primary"></em>
												</span>
											</div> -->
									</span>
									<div class="row row-flush mt-2">
										<div
											*ngFor="
												let type of marketPlaceCategories
											"
											class="col-12 col-lg-3 col-md-6 col-sm-6 px-1 mb-2 w-20">
											<div
												class="row row-flush h-100 clickable"
												[ngClass]="{
													'marketplace-project-type-icon-active':
														projectTypeId ==
														type?.project_type_id
												}"
												(click)="
													onMarketplaceNextTitle(type)
												">
												<div
													[ngClass]="{
														'selected-marketplace-category-icon':
															projectTypeId ==
															type?.project_type_id
													}"
													class="col-4 text-center d-flex align-items-center justify-content-center market-project-type-icon rounded-left">
													<em
														[ngClass]="
															type?.icon_class
																? type.icon_class
																: 'fa-folder-open'
														"
														class="far font-26"></em>
												</div>
												<div
													class="col-8 d-flex align-items-center rounded-right"
													[ngClass]="
														projectTypeId ==
														type?.project_type_id
															? 'selected-marketplace-category'
															: 'marketplace-bg-type'
													">
													<div
														class="card-body px-2 py-2 text-center">
														<p
															class="mb-0 text-uppercase text-white">
															{{ type?.title }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</accordion-group>
				<accordion-group
					#stepgroup8
					[isOpen]="true"
					panelClass="card shadow mb-4"
					(isOpenChange)="
						onChangeAccordion(
							$event,
							sectionType['project-subcategory-types']
						)
					"
					*ngIf="
						(category?.type && oDefs['titles'][category?.type]) ||
						(hasMarketplaceAccess &&
							marketPlaceCategoryTypes &&
							marketplaceCategory['type'])
					">
					<div
						accordion-heading
						class="text-primary text-bold mt-2 clickable">
						<span class="h4">
							2. What best describes your project?*
							<em
								class="fa fa-info-circle clickable ml-1"
								[popover]="projectTypeTooltip"
								triggers="mouseenter:mouseleave click"
								(click)="$event.stopPropagation()"></em>
							<ng-template #projectTypeTooltip>
								Type a keyword to find the appropriate tag for
								your project
							</ng-template>
						</span>
						<span
							*ngIf="!this.accordion?.step6?.collapsed"
							class="fa fa-angle-down text-primary mr-2 float-right"></span>
						<span
							*ngIf="this.accordion?.step6?.collapsed"
							class="fa fa-angle-up text-primary mr-2 float-right"></span>
						<hr class="mb-0" />
					</div>
					<div id="collapse08">
						<div
							class="row mb-2"
							*ngIf="
								category.type && oDefs['titles'][category?.type]
							">
							<div class="col-md-6">
								<ngx-select
									[items]="oDefs['titles'][category?.type]"
									optionValueField="project_title_id"
									optionTextField="title"
									(select)="onSelectProjectTitle($event)"
									[(ngModel)]="category.title"
									[disabled]="bDisableType"
									placeholder="Type here to search"></ngx-select>
							</div>
						</div>
						<div
							class="row"
							*ngIf="
								hasMarketplaceAccess &&
								marketPlaceCategoryTypes &&
								marketplaceCategory['type']
							">
							<div class="col-md-6">
								<ngx-select
									[items]="marketPlaceCategoryTypes"
									optionValueField="project_title_id"
									optionTextField="title"
									[disabled]="bDisableType"
									(select)="
										onSelectMarketplaceProjectTitle($event)
									"
									[(ngModel)]="marketplaceCategory.title"
									placeholder="Type here to search"></ngx-select>
							</div>
						</div>
						<div class="row">
							<ng-container
								*ngIf="
									hasMarketplaceAccess &&
									marketplacePackages?.length > 0 &&
									this.marketplaceCategory['type']
								">
								<ng-container
									*ngFor="let package of marketplacePackages">
									<div
										class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12 my-3 px-3 py-2">
										<div
											class="card package-list-card-wrapper">
											<div>
												<div class="card-body">
													<div
														class="package-list-image-wrapper"
														[ngStyle]="{
															'background-image':
																'url(' +
																package
																	?.document_file
																	?.full_path +
																')'
														}">
														<img
															class="package-image-content w-100"
															src="../../../{{
																packageBGImage
															}}"
															alt="" />
													</div>
													<div
														class="d-flex align-items-center clickable"
														(click)="
															initDesignerProjectModal(
																package?.created_by_user,
																designerProfileModal,
																109,
																'designer_package'
															)
														">
														<img
															class="profile-picture-wrapper ml-3"
															[src]="
																package
																	?.created_by_user
																	?.profile_picture
																	?.full_path
															"
															onError="this.src='../../../../../assets/img/user-placeholder.png';"
															alt="" />
														<div
															class="profile-title text-primary">
															{{
																package
																	?.created_by_user
																	?.name
															}}
														</div>
														<div
															class="d-flex align-items-center package-star-rating">
															<app-rating-icon></app-rating-icon>
															<span
																class="profile-rating">
																{{
																	package
																		?.created_by_user
																		?.average_rating
																		| number
																			: '1.1-1'
																}}
															</span>
														</div>
													</div>
													<div
														class="mx-sm-2 mx-lg-2">
														<div
															class="clickable"
															(click)="
																fetchPackageInfo(
																	package
																)
															">
															<div
																class="package-list-title-wrapper text-primary"
																[popover]="
																	packageTitle
																"
																[outsideClick]="
																	true
																"
																placement="auto"
																triggers="mouseenter:mouseleave mouseenter:click">
																{{
																	package?.title
																}}
															</div>
															<ng-template
																#packageTitle>
																<div
																	[innerHtml]="
																		package?.title
																	"></div>
															</ng-template>
															<div
																class="package-list-content-wrapper mt-4"
																[popover]="
																	package?.project_type_name +
																	'-' +
																	package?.project_title_name
																"
																placement="auto"
																triggers="mouseenter:mouseleave click">
																{{
																	package?.project_type_name +
																		'-' +
																		package?.project_title_name
																}}
															</div>
															<div
																class="package-list-price-wrapper">
																<div
																	class="d-flex justify-content-between align-items-baseline flex-column">
																	<div
																		class="d-flex align-items-baseline">
																		<h3
																			[ngClass]="
																				package?.discounted_total !=
																					null &&
																				package?.discounted_total !==
																					0
																					? 'package-original-price-wrapper'
																					: ''
																			">
																			${{
																				+package[
																					'total'
																				] ||
																					0
																					| number
																						: '1.0-2'
																			}}
																		</h3>
																		<h3
																			*ngIf="
																				package?.discounted_total !=
																					null &&
																				package?.discounted_total !==
																					0
																			"
																			class="discounted-price-wrapper text-primary">
																			${{
																				+package[
																					'discounted_total'
																				] ||
																					0
																					| number
																						: '1.0-2'
																			}}
																		</h3>
																	</div>
																	<!-- <div
																			class="discount-code-wrapper mx-auto"
																			*ngIf="
																				package?.discounted_total !=
																					null &&
																				package?.discounted_total !==
																					0
																			">
																			<span
																				class="discount-content-wrapper">
																				<img
																					src="../../../../{{
																						discountCouponBgImage
																					}}"
																					alt="" />
																				<p
																					class="font-12">
																					{{
																						package?.discount
																							| number
																								: '1.0'
																					}}%
																					DISCOUNT
																				</p>
																			</span>
																		</div> -->
																	<div
																		class="discount-code-wrapper mx-auto"
																		*ngIf="
																			package?.discounted_total !=
																				null &&
																			package?.discounted_total !==
																				0
																		">
																		<div
																			class="discount-content-container">
																			<img
																				src="../../../../{{
																					discountCouponBgImage
																				}}"
																				alt="" />
																			<div
																				class="discount-content-text-wrapper font-11">
																				<span>
																					{{
																						package?.discount
																							| number
																								: '1.0'
																					}}%
																					DISCOUNT
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>

															<div
																class="package-list-details-wrapper mt-3 mb-2"
																[innerHTML]="
																	package?.details
																"
																[popover]="
																	packageDetail
																"
																placement="auto"
																[outsideClick]="
																	true
																"
																triggers="mouseenter:mouseleave mouseenter:click"></div>
														</div>

														<ng-template
															#packageDetail>
															<div
																[innerHtml]="
																	package?.details
																"></div>
														</ng-template>

														<div
															class="package-select-button">
															<button
																*ngIf="
																	selectedPackage?.id !==
																	package.id
																"
																(click)="
																	onSelectPackage(
																		package
																	)
																"
																class="btn btn-primary mt-2 mb-2 w-100"
																type="submit">
																Select
															</button>
															<button
																*ngIf="
																	selectedPackage?.id ===
																	package?.id
																"
																class="btn btn-warning mt-2 mb-2 w-100"
																type="submit">
																Selected
															</button>
															<span
																class="d-flex align-items-center justify-content-center my-2 clickable"
																[popover]="
																	satisfactionGuaranteedTooltip
																"
																triggers="mouseenter:mouseleave click"
																placement="auto"
																(click)="
																	$event.preventDefault();
																	$event.stopPropagation()
																">
																<img
																	class="mr-2"
																	src="../../../../{{
																		satisfactionGuaranteedImg
																	}}"
																	alt="" />
																<p
																	class="satisfaction-content-wrapper mb-0">
																	Satisfaction
																	Guaranteed!
																</p>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
							<span class="ml-3" *ngIf="isPackageNotFound">
								No package found
							</span>
						</div>
						<div
							class="text-center py-1"
							*ngIf="
								hasMarketplaceAccess &&
								lastLoadedPackages?.data?.length ==
									createProjectPackageListLimit &&
								marketplaceCategory['type'] &&
								lastLoadedPackages?.next_page_url
							">
							<a
								href="javascript:void(0);"
								(click)="onClickLoadMorePackage()"
								class="display-4">
								<span>Load More</span>
								<em
									class="ml-2 text-muted fas fa-chevron-down text-primary"></em>
							</a>
						</div>
					</div>
				</accordion-group>
				<form
					role="form"
					class="questionContainer"
					*ngIf="
						marketplaceCategory['questionForms']['steps'] &&
						(selectedPackage?.id ||
							oProject?.concept_id ==
								projectConcepts?.marketplace)
					"
					[formGroup]="marketplaceCategory['questionForms']['steps']"
					(ngSubmit)="onMarketplaceQuestionsSubmit()"
					id="questionContainerGroup">
					<accordion-group
						#stepgroup5
						*ngIf="
							(selectedPackage?.id ||
								oProject?.concept_id ==
									projectConcepts?.marketplace) &&
							marketplaceQuestions.length > 0
						"
						[isOpen]="true"
						panelClass="card shadow mb-4 marketplace-accordion-wrapper"
						(isOpenChange)="
							onChangeAccordion(
								$event,
								sectionType['project-details']
							)
						">
						<div
							class="mt-2 clickable"
							*ngIf="
								selectedPackage?.id ||
								oProjectsData?.project_package
							">
							<span
								class="font-20 font-w-600 text-primary d-block mb-3">
								{{
									selectedPackage?.title
										? selectedPackage?.title
										: oProjectsData?.project_package?.title
								}}
							</span>
							<span class="display-4">
								{{
									selectedPackage?.project_type_name
										? selectedPackage?.project_type_name
										: oProjectsData?.project_package
												?.project_type_name
								}}
								-
								{{
									selectedPackage?.project_title_name
										? selectedPackage?.project_title_name
										: oProjectsData?.project_package
												?.project_title_name
								}}
							</span>
							<hr class="mb-0" />
							<div
								class="mt-3 mb-2"
								[innerHTML]="
									selectedPackage?.details
										? selectedPackage?.details
										: oProjectsData?.project_package
												?.details
								"></div>
							<div class="text-primary text-bold my-4 font-20">
								Add your details for this project
							</div>
							<hr class="mb-0" />
						</div>

						<div>
							<div
								*ngFor="
									let question of marketplaceQuestions;
									let i = index
								">
								<div
									[ngClass]="{
										'questions-margin':
											!question?.custom ||
											question?.custom_field == 'title',
										'mt-4': i > 0
									}"
									id="step-{{ i + 1 }}"
									[formGroupName]="'step' + (i + 1)">
									<div class="col-xs-12">
										<div
											class="row"
											[ngClass]="{
												'ml-0':
													question?.project_question_type_id ==
													oQuestionTypes?.textarea
											}">
											<div
												class="col-md-12"
												[ngClass]="{
													'madatory-field':
														oSelectedType &&
														oSelectedTitle &&
														marketplaceCategory[
															'questionForms'
														]['steps'].controls[
															'step' + (i + 1)
														]
															.get('title')
															.hasError(
																'required'
															) &&
														!marketplaceCategory[
															'questionForms'
														]['steps'].controls[
															'step' + (i + 1)
														].get('title').valid,

													'p-0':
														question?.project_question_type_id ==
														oQuestionTypes?.textarea
												}">
												<div>
													<span
														class="circle circle-md mr-2 mt-2 float-left"
														[ngClass]="{
															'bg-primary':
																question?.status ==
																'1',
															'bg-danger':
																question.status ==
																'0'
														}"
														*ngIf="
															showWhichStatus &&
															(!question?.custom ||
																(question?.custom &&
																	question?.custom_field ==
																		'title'))
														"></span>
													<h4
														*ngIf="
															!question?.custom
														">
														{{ question.title }}
														<span
															class="text-danger"
															*ngIf="
																question?.field_required
															">
															*
														</span>
														<em
															*ngIf="
																question.question
															"
															class="fa fa-info-circle clickable ml-1 text-primary font-14"
															[popover]="
																questionTooltip
															"
															placement="auto"
															triggers="mouseenter:mouseleave click"></em>
													</h4>
													<h4
														*ngIf="
															question?.custom &&
															question?.custom_field ==
																'title'
														">
														{{ question.title }}
														<span
															class="text-danger"
															*ngIf="
																question?.field_required
															">
															*
														</span>
														<em
															*ngIf="
																question.question
															"
															class="fa fa-info-circle clickable ml-1 text-primary font-14"
															[popover]="
																questionTooltip
															"
															placement="auto"
															triggers="mouseenter:mouseleave click"></em>
													</h4>
													<ng-template
														#questionTooltip>
														<div
															style="
																max-width: 300px;
															">
															{{
																question.question
															}}
														</div>
													</ng-template>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.enum
													"
													[ngClass]="{
														'has-error':
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
													}"
													class="ans-list">
													<div
														class="ans-item form-group">
														<select
															class="form-control"
															formControlName="title">
															<option value="">
																Select
															</option>
															<option
																*ngFor="
																	let select_option of oDefs[
																		'app_list_strings'
																	][
																		question[
																			'field_options'
																		]
																	]
																		| objectToIterable
																"
																[ngValue]="
																	select_option.key
																">
																{{
																	select_option.value
																}}
															</option>
														</select>
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.multienum
													"
													[ngClass]="{
														'has-error':
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
													}">
													<div
														class="form-group form-group"
														*ngIf="
															!question?.custom
														">
														<ngx-select
															[items]="
																aAppListString[
																	question
																		.field_options
																]
															"
															optionValueField="id"
															optionTextField="name"
															placeholder="{{
																question.question_placeholder
															}}"
															formControlName="title"
															[multiple]="
																true
															"></ngx-select>
													</div>
													<div
														class="text-danger"
														*ngIf="
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'minLengthArray'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
														">
														Required Field
													</div>
												</div>
												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.bucket
													"
													class="mt-2 clickable">
													<div class="mb-2">
														<h4>Attach a Bucket</h4>
													</div>
												</div>
												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.bucket
													">
													<a
														class="btn btn-labeled btn-primary mb-2 float-right"
														type="button"
														[routerLink]="
															'/buckets'
														"
														target="_blank">
														<span class="btn-label">
															<i
																class="fas fa-plus"></i>
														</span>
														Add New Bucket
													</a>
													<div class="row row-flush">
														<div
															class="col-md-7 bucket-wrapper">
															<ngx-select
																[items]="
																	aBuckets
																"
																(open)="
																	openSelectBucket()
																"
																placeholder="Select a Bucket"
																optionValueField="id"
																optionTextField="name"
																[multiple]="
																	true
																"
																[keepSelectedItems]="
																	false
																"
																formControlName="title"></ngx-select>
														</div>
													</div>
												</div>
												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.radio
													"
													class="mb-2"
													[ngClass]="{
														'has-error':
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
													}">
													<div
														class="c-radio mb-1"
														*ngFor="
															let radio_option of oDefs[
																'app_list_strings'
															][
																question[
																	'field_options'
																]
															] | objectToIterable
														">
														<label>
															<input
																type="radio"
																formControlName="title"
																value="{{
																	radio_option.key
																}}" />
															<span
																class="fa fa-circle"></span>
															{{
																radio_option?.value
															}}
														</label>
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.checkbox
													"
													class="mb-2">
													<div
														class="checkbox c-checkbox mb-1"
														formArrayName="title"
														*ngFor="
															let item of marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title')
																.controls;
															let j = index
														">
														<div>
															<label>
																<input
																	type="checkbox"
																	formControlName="value" />
																<span
																	class="fa fa-check"></span>
																{{
																	marketplaceCategory[
																		'questionForms'
																	][
																		'steps'
																	].controls[
																		'step' +
																			(i +
																				1)
																	]
																		.get(
																			'title'
																		)
																		.controls[
																			j
																		].get(
																			'text'
																		).value
																}}
															</label>
														</div>
													</div>
													<div
														class="text-danger"
														*ngIf="
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'minLengthCheckbox'
																) &&
															!marketplaceCategory[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
														">
														Required Field
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.text
													">
													<div
														class="form-group"
														[ngClass]="{
															'has-error':
																marketplaceCategory[
																	'questionForms'
																]['steps']
																	.controls[
																	'step' +
																		(i + 1)
																].dirty &&
																marketplaceCategory[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																]
																	.get(
																		'title'
																	)
																	.hasError(
																		'required'
																	) &&
																!marketplaceCategory[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																].get('title')
																	.valid
														}">
														<input
															type="text"
															class="form-control"
															placeholder="{{
																question.question_placeholder
															}}"
															formControlName="title" />
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.textarea
													">
													<div class="row">
														<div
															class="col-md-12 project-brief-inline-editor form-group">
															<div
																formControlName="title"
																[ngxSummernote]="
																	getConfig(
																		question
																	)
																"></div>
														</div>
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.file
													">
													<div
														class="mb-2"
														*ngIf="
															bShowProgressBar
														">
														<progressbar
															[animate]="false"
															[value]="
																progressBarPercentage
															"
															type="success">
															<b>
																{{
																	progressBarPercentage
																}}%
															</b>
														</progressbar>
													</div>
													<div
														class="form-group"
														[ngClass]="{
															'has-error':
																marketplaceCategory[
																	'questionForms'
																]['steps']
																	.controls[
																	'step' +
																		(i + 1)
																].dirty &&
																marketplaceCategory[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																]
																	.get(
																		'title'
																	)
																	.hasError(
																		'required'
																	) &&
																!marketplaceCategory[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																].get('title')
																	.valid
														}">
														<div class="row">
															<div
																class="col-md-12 mb-3">
																<div
																	class="custom-file">
																	<input
																		type="file"
																		class="custom-file-input"
																		id="que_file_step{{
																			i +
																				1
																		}}"
																		(change)="
																			onSingleQueFileSelected(
																				$event,
																				'step' +
																					(i +
																						1)
																			)
																		"
																		multiple="multiple" />
																	<label
																		class="custom-file-label"
																		for="que_file_{{
																			i +
																				1
																		}}">
																		Choose
																		file
																	</label>
																</div>
															</div>
															<div
																class="col-md-12">
																<div
																	ng2FileDrop
																	[ngClass]="{
																		'nv-file-over':
																			oBaseDropZoneOver[
																				'step' +
																					(i +
																						1)
																			]
																	}"
																	(fileOver)="
																		fileOverBase(
																			'step' +
																				(i +
																					1),
																			$event
																		)
																	"
																	(onFileDrop)="
																		onFileDropped(
																			'step' +
																				(i +
																					1),
																			$event
																		)
																	"
																	[uploader]="
																		oUploader[
																			'step' +
																				(i +
																					1)
																		]
																	"
																	class="bg-white border box-placeholder my-drop-zone text-center">
																	DRAG & DROP
																</div>
															</div>
														</div>
														<div
															*ngIf="
																marketplaceCategory[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																].get('title')
																	.controls
																	.length > 0
															">
															<div
																class=""
																formArrayName="title"
																*ngFor="
																	let item of marketplaceCategory[
																		'questionForms'
																	][
																		'steps'
																	].controls[
																		'step' +
																			(i +
																				1)
																	].get(
																		'title'
																	).controls;
																	let j = index
																">
																<div>
																	<button
																		type="button"
																		class="mt-2 btn btn-labeled btn-secondary overflow-wrap-anywhere">
																		<span
																			class="btn-label">
																			<em
																				class="fa fa-file"></em>
																		</span>
																		{{
																			marketplaceCategory[
																				'questionForms'
																			][
																				'steps'
																			].controls[
																				'step' +
																					(i +
																						1)
																			]
																				.get(
																					'title'
																				)
																				.controls[
																					j
																				].get(
																					'file'
																				)
																				?.value
																				?.name
																		}}
																		<span
																			class="btn-label btn-label-right"
																			(click)="
																				unsetQueFile(
																					'step' +
																						(i +
																							1),
																					j
																				)
																			">
																			<em
																				class="fa fa-times"></em>
																		</span>
																	</button>
																</div>
															</div>
														</div>
														<div
															*ngIf="
																oCopyBriefFiles[
																	'step' +
																		(i + 1)
																] &&
																oCopyBriefFiles[
																	'step' +
																		(i + 1)
																].length > 0
															">
															<div
																*ngFor="
																	let oCopyDoc of oCopyBriefFiles[
																		'step' +
																			(i +
																				1)
																	];
																	let j = index
																">
																<button
																	type="button"
																	class="mt-2 btn btn-labeled btn-secondary overflow-wrap-anywhere">
																	<span
																		class="btn-label">
																		<em
																			class="fa fa-file"></em>
																	</span>
																	{{
																		oCopyDoc.filename
																	}}
																	<span
																		class="btn-label btn-label-right"
																		(click)="
																			unsetCopyDoc(
																				'step' +
																					(i +
																						1),
																				j
																			)
																		">
																		<em
																			class="fa fa-times"></em>
																	</span>
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="text-right font-30 font-weight-bold">
							Total:
							<span
								[ngClass]="
									selectedPackage?.discounted_total != null &&
									selectedPackage?.discounted_total !== 0
										? 'package-original-price-wrapper'
										: ''
								">
								${{
									(selectedPackage
										? selectedPackage?.total
										: oProjectsData?.project_package?.total
									) | number: '1.0-0'
								}}
							</span>
							<span
								*ngIf="
									selectedPackage?.discounted_total != null &&
									selectedPackage?.discounted_total !== 0
								"
								class="discounted-price-wrapper text-primary">
								${{
									selectedPackage['discounted_total'] || 0
										| number: '1.0-2'
								}}
							</span>
						</div>
						<div
							class="discount-code-wrapper total-discount-code-wrapper mt-2 mb-3"
							*ngIf="
								selectedPackage?.discounted_total != null &&
								selectedPackage?.discounted_total !== 0
							">
							<div class="total-discount-content-container">
								<img
									src="../../../../{{
										discountCouponBgImage
									}}"
									alt="" />
								<div
									class="total-discount-content-text-wrapper font-11">
									<span>
										{{
											selectedPackage?.discount
												| number: '1.0'
										}}% DISCOUNT
									</span>
								</div>
							</div>
						</div>
						<div
							class="d-flex justify-content-end align-items-baseline"
							*ngIf="
								(!isAdminUser && !isProjectManagerUser) ||
								(isProjectManagerUser && bCopyBrief)
							">
							<button
								class="btn btn-labeled mb-1 float-right fl-btn-grey d-flex align-items-center border-0"
								(click)="saveAsDraft($event)"
								[disabled]="bShowProgressBar || bDisableBtn"
								*ngIf="
									marketplaceQuestions?.length > 0 &&
									!isDraftDisbaled &&
									!isProjectManagerUser &&
									oProject?.project_status_id != 1 &&
									action != 'redo-project' &&
									action != 'start-project'
								">
								<div
									class="btn-label fl-icon-grey save-project-btn-wrapper">
									<i class="far fa-check-circle"></i>
								</div>
								<span class="fl-text-grey">
									Save Project as Draft
								</span>
							</button>
							<button
								class="btn btn-labeled mb-2 float-right fl-btn-grey d-flex align-items-center justify-content-center border-0"
								(click)="saveAsDraft($event)"
								*ngIf="
									marketplaceQuestions?.length > 0 &&
									!isDraftDisbaled &&
									!isProjectManagerUser &&
									oProject?.project_status_id != 1 &&
									action != 'redo-project' &&
									action == 'start-project'
								"
								[disabled]="bShowProgressBar || bDisableBtn">
								<span
									class="btn-label fl-icon-grey save-project-btn-wrapper">
									<i class="far fa-check-circle"></i>
								</span>
								<span class="fl-text-grey">Update Draft</span>
							</button>
							<em
								class="fa fa-info-circle clickable ml-1 pr-2 font-14"
								[popover]="saveAsDraftTooltip"
								placement="auto"
								triggers="mouseenter:mouseleave click"
								*ngIf="
									marketplaceQuestions?.length > 0 &&
									!isDraftDisbaled &&
									!isProjectManagerUser &&
									oProject?.project_status_id != 1 &&
									action != 'redo-project'
								"></em>
							<ng-template #saveAsDraftTooltip>
								By saving it as a draft, you'll be able to edit
								it later in your drafts section, or move it to
								your pending queue to be worked on. Projects
								saved as draft do not start without being moved
								to pending.
							</ng-template>

							<button
								class="btn btn-labeled btn-primary mb-2 float-right d-flex align-items-center next-btn-wrapper border-0"
								type="submit"
								(click)="
									_analyticsService.setOverlayTrackData(
										17,
										108,
										oProjectsData?.project?.project_id,
										2
									)
								"
								[disabled]="
									!marketplaceCategory['questionForms'][
										'steps'
									].valid ||
									bShowProgressBar ||
									bDisableBtn
								"
								*ngIf="marketplaceQuestions?.length > 0">
								<div class="btn-label check-circle-btn-wrapper">
									<i class="far fa-check-circle"></i>
								</div>
								<span>Next</span>
							</button>
						</div>
					</accordion-group>
				</form>
				<form
					role="form"
					class="questionContainer"
					*ngIf="
						category['questionForms']['steps'] && category['title']
					"
					[formGroup]="category['questionForms']['steps']"
					(ngSubmit)="onQuestionSubmit()">
					<accordion-group
						#stepgroup2
						[isOpen]="true"
						panelClass="card shadow mb-4"
						(isOpenChange)="
							onChangeAccordion(
								$event,
								sectionType['invite-team-members']
							)
						">
						<div
							accordion-heading
							class="text-primary text-bold mt-2 clickable">
							<span class="h4">
								3. Do you want to invite or exclude any team
								members?
								<em
									class="fa fa-info-circle clickable ml-1"
									[popover]="teamMemberTooltip"
									placement="auto"
									triggers="mouseenter:mouseleave click"></em>
								<ng-template #teamMemberTooltip>
									When you invite a team member, they'll be
									notified and assigned to your project if
									they're available. You can also exclude a
									team member from this particular project.
								</ng-template>
							</span>
							<span
								*ngIf="!this.accordion.step2.collapsed"
								class="fa fa-angle-down text-primary mr-2 float-right"></span>
							<span
								*ngIf="this.accordion.step2.collapsed"
								class="fa fa-angle-up text-primary mr-2 float-right"></span>
							<hr class="mb-0" />
						</div>
						<div id="collapse02">
							<div>
								<!-- <div class="row" *ngIf="oTmPagination['totalRecords'] > utils.getShowInviteTeamLimit()">
										<div class="col-md-12">
											<h5 *ngIf="teamInviteStatus == 1">
												You've invited your team.
											</h5>
											<h5 *ngIf="teamInviteStatus == 2">
												We're working on expanding your team.
											</h5>
										</div>
										<div class="col-md-4">
											<div class="card card-default">
												<div class="card-header h4 mb-0 text-center">My Team</div>
												<div class="card-body bt text-center">
													<em class="fa fa-users fa-5x"></em>
													<div class="mt-2">
														<button class="btn mr-1"
																[ngClass]="{'btn-outline-primary': teamInviteStatus != 1, 'btn-primary': teamInviteStatus == 1}"
																(click)="onClickInviteTeam()">
															{{ teamInviteStatus == 1 ? 'Invited' : 'Invite' }} All
														</button>
														<button class="btn mr-1"
																[ngClass]="{'btn-outline-danger': teamInviteStatus != 2, 'btn-danger': teamInviteStatus == 2}"
																(click)="onClickExcludeTeam()">
															{{ teamInviteStatus == 2 ? 'Expanding' : 'Expand' }} Team
														</button>
													</div>
												</div>
											</div>
										</div>
									</div> -->
								<div class="row">
									<div
										class="col-12"
										*ngIf="aTeamMembers.length > 0">
										<!-- <div class="p">You have invited {{category['questionForms']['steps'].controls['step1'].value.title.length}} team member
												<span *ngIf="category['questionForms']['steps'].controls['step1'].value.title.length == 0 || category['questionForms']['steps'].controls['step1'].value.title.length > 1">s</span>, so far
											</div>
											<div class="p">
												You have excluded {{ aExcludedTeamMembers.length }} team member
												<span *ngIf="aExcludedTeamMembers.length == 0 || aExcludedTeamMembers.length > 1">s</span>,
												so far.(By excluding creatives or removing them on your end, it may cause delays
												or result in projects not being completed.)
											</div> -->
										<div class="p text-bold mb-3">
											Your team
										</div>
										<!--<div class="row">
												<div class="col-md-12">
													<div class="d-flex align-items-center mb-2">
														<div class="w-50 pr-5">
															As you complete projects team members <b>will join your team</b>
															and you'll be able to <b>invite</b> or <b>exclude</b> them on
															this screen from working on your individual future projects.
														</div>
														<div class="w-50">
															<img src="assets/img/post_project.png" class="img-fluid"
																 style="max-height: 170px;">
														</div>
													</div>
												</div>
											</div>-->
									</div>
									<div
										class="col-md-12"
										*ngIf="aTeamMembers.length == 0">
										<!-- && aSuggestedTeamMembers.length > 0 -->
										<div
											class="d-flex align-items-center mb-2">
											<div class="w-50 pr-5">
												You haven't completed any
												<strong>
													{{
														getProjectCategoryTitle(
															category.type
														)
													}}
												</strong>
												projects yet. As you complete
												<strong>
													{{
														getProjectCategoryTitle(
															category.type
														)
													}}
												</strong>
												projects team members will join
												your team and you'll be able to
												invite or exclude them on this
												screen from working on your
												future projects.
											</div>
											<div class="w-50">
												<img
													src="assets/img/post_project.png"
													class="img-fluid"
													style="max-height: 170px" />
											</div>
										</div>
									</div>
									<div
										class="col-md-4 col-lg-3"
										*ngFor="
											let oTeamMember of aTeamMembers;
											let j = index
										">
										<div class="card border">
											<div class="card-header">
												<span
													class="float-right text-muted mr-2 clickable"
													(click)="
														initNotes(
															oTeamMember,
															initNotesModal
														)
													"
													popover="View, add or edit your notes about {{
														oTeamMember?.name
													}}"
													placement="auto"
													triggers="mouseenter:mouseleave click">
													<em
														class="fa fa-comment-alt fas text-primary"></em>
												</span>
												<span
													class="float-right text-muted mr-2 clickable"
													(click)="
														initStats(
															oTeamMember,
															initStatsModal
														)
													"
													popover="View stats on the work between you and {{
														oTeamMember?.name
													}}"
													placement="auto"
													triggers="mouseenter:mouseleave click">
													<em
														class="fa fa-chart-bar fas text-primary"></em>
												</span>
											</div>
											<div class="card-body text-center">
												<img
													class="mb-2 img-fluid rounded-circle thumb96 img-thumbnail"
													src="{{
														oTeamMember.profile_pic
													}}"
													alt=""
													onError="this.src='../../../assets/img/user-placeholder.png';" />
												<h4>
													{{ oTeamMember.name }}
												</h4>
												<ng-container
													*ngTemplateOutlet="
														showInviteExclude;
														context: {
															oTeamMember:
																oTeamMember,
															showExlude: true
														}
													"></ng-container>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div
										class="col-md-12 text-center text-underline"
										*ngIf="
											oTmPagination['totalRecords'] >
											aTeamMembers.length
										">
										<a
											href="javascript:;"
											class=""
											(click)="onClickLoadMoreTm()">
											See more team members
										</a>
									</div>
								</div>
							</div>
							<div *ngIf="aSuggestedTeamMembers.length > 0">
								<hr class="mb-4" />
								<h4 class="mb-4">Suggested Team Members</h4>
								<div class="row">
									<div
										class="col-md-4 col-lg-3"
										*ngFor="
											let oTeamMember of aSuggestedTeamMembers;
											let j = index
										">
										<div class="card border">
											<div class="card-body text-center">
												<img
													class="mb-2 img-fluid rounded-circle thumb96 img-thumbnail"
													src="{{
														oTeamMember.profile_url
													}}"
													alt=""
													onError="this.src='../../../assets/img/user-placeholder.png';" />
												<!-- <div *ngIf="oTeamMember.invite_exclude == 1">
														<h4>
															{{ oTeamMember.name }} Suggested
														</h4>
														<ng-container *ngTemplateOutlet="showInviteExclude; context:{oTeamMember: oTeamMember}">
														</ng-container>
													</div> -->
												<div>
													<h4>
														{{ oTeamMember.name }}
													</h4>
													<ng-container
														*ngTemplateOutlet="
															showInviteExclude;
															context: {
																oTeamMember:
																	oTeamMember,
																showExlude: false
															}
														"></ng-container>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div
										class="col-md-12 text-center text-underline"
										*ngIf="
											oSuggestedTmPagination[
												'totalRecords'
											] > aSuggestedTeamMembers.length
										">
										<a
											href="javascript:;"
											class=""
											(click)="
												onClickLoadMoreSuggestedTm()
											">
											See more team members
										</a>
									</div>
								</div>
							</div>

							<!-- <div class="pt-2 clearfix">
									<div class="text-right">
										<button class="btn btn-primary" type="button" (click)="oAccordion = 3">Next</button>
									</div>
								</div> -->
						</div>
					</accordion-group>

					<accordion-group
						#stepgroup3
						[isOpen]="true"
						panelClass="card shadow mb-4"
						(isOpenChange)="
							onChangeAccordion($event, sectionType['bucket'])
						">
						<div accordion-heading class="mt-2 clickable">
							<div class="mb-2">
								<span class="h4 text-primary">
									4. Select a brand bucket
									<em
										class="fa fa-info-circle clickable ml-1"
										[popover]="bucketTooltip"
										placement="auto"
										triggers="mouseenter:mouseleave click"></em>
									<ng-template #bucketTooltip>
										A Brand Bucket contains all the relevant
										files for a brand, including your style
										guide, color palette, etc. Choose the
										Bucket to attach to this project. If you
										need to make one, select "Add New
										Bucket" on the right.
									</ng-template>
								</span>
								<span
									*ngIf="!this.accordion.step3.collapsed"
									class="fa fa-angle-down text-primary mr-2 float-right"></span>
								<span
									*ngIf="this.accordion.step3.collapsed"
									class="fa fa-angle-up text-primary mr-2 float-right"></span>
							</div>
							<div class="p">
								A bucket contains the branding material we will
								use in this project. Logo, branding guide, etc.
							</div>
							<hr class="mb-1 mt-4" />
						</div>
						<div id="collapse03">
							<a
								class="btn btn-labeled btn-primary mb-2 float-right"
								type="button"
								[routerLink]="'/buckets'"
								target="_blank">
								<span class="btn-label">
									<i class="fas fa-plus"></i>
								</span>
								Add New Bucket
							</a>

							<div
								class="row row-flush mb-2"
								[formGroupName]="'step2'">
								<div class="col-md-7">
									<ngx-select
										[items]="aBuckets"
										(open)="openSelectBucket()"
										placeholder="Select a Bucket"
										optionValueField="id"
										optionTextField="name"
										[multiple]="true"
										[keepSelectedItems]="false"
										formControlName="title"></ngx-select>
								</div>
							</div>
							<!-- <div class="pt-3 clearfix">
									<div class="text-right">
										<button class="btn btn-primary" type="button" (click)="oAccordion = 4">Next</button>
									</div>
								</div> -->
						</div>
					</accordion-group>

					<accordion-group
						#stepgroup4
						[isOpen]="true"
						panelClass="card shadow mb-4"
						(isOpenChange)="
							onChangeAccordion(
								$event,
								sectionType['project-brief']
							)
						">
						<div accordion-heading class="mt-2 clickable">
							<span class="h4 text-primary">
								5. Project brief
								<em
									class="fa fa-info-circle clickable ml-1"
									[popover]="briefTooltip"
									placement="auto"
									triggers="mouseenter:mouseleave click"></em>
								<ng-template #briefTooltip>
									Here's where you can explain what you need
									done! Team members will see this brief, so
									be sure to provide all relevant details.
								</ng-template>
							</span>
							<span
								*ngIf="!this.accordion.step4.collapsed"
								class="fa fa-angle-down text-primary mr-2 float-right"></span>
							<span
								*ngIf="this.accordion.step4.collapsed"
								class="fa fa-angle-up text-primary mr-2 float-right"></span>
							<hr class="mb-0" />
						</div>
						<div id="collapse04">
							<div
								*ngFor="
									let question of oDefs['questions'][
										category['title']
									];
									let i = index
								">
								<div
									class=""
									[ngClass]="{
										'questions-margin':
											!question?.custom ||
											question?.custom_field == 'title'
									}"
									id="step-{{ i + 1 }}"
									[formGroupName]="'step' + (i + 1)">
									<div class="col-xs-12">
										<div class="row">
											<div
												class="col-md-12"
												[ngClass]="{
													'madatory-field':
														oSelectedType &&
														oSelectedTitle &&
														category[
															'questionForms'
														]['steps'].controls[
															'step' + (i + 1)
														]
															.get('title')
															.hasError(
																'required'
															) &&
														!category[
															'questionForms'
														]['steps'].controls[
															'step' + (i + 1)
														].get('title').valid
												}">
												<div>
													<span
														class="circle circle-md mr-2 mt-2 float-left"
														[ngClass]="{
															'bg-primary':
																question.status ==
																'1',
															'bg-danger':
																question.status ==
																'0'
														}"
														*ngIf="
															showWhichStatus &&
															(!question?.custom ||
																(question?.custom &&
																	question?.custom_field ==
																		'title'))
														"></span>
													<h4
														*ngIf="
															!question?.custom
														">
														{{ question.title }}
														<span
															class="text-danger"
															*ngIf="
																question?.field_required
															">
															*
														</span>
														<em
															*ngIf="
																question.question
															"
															class="fa fa-info-circle clickable ml-1 text-primary font-14"
															[popover]="
																questionTooltip
															"
															placement="auto"
															triggers="mouseenter:mouseleave click"></em>
													</h4>
													<h4
														*ngIf="
															question?.custom &&
															question?.custom_field ==
																'title'
														">
														{{ question.title }}
														<span
															class="text-danger"
															*ngIf="
																question?.field_required
															">
															*
														</span>
														<em
															*ngIf="
																question.question
															"
															class="fa fa-info-circle clickable ml-1 text-primary font-14"
															[popover]="
																questionTooltip
															"
															placement="auto"
															triggers="mouseenter:mouseleave click"></em>
													</h4>
													<ng-template
														#questionTooltip>
														<div
															style="
																max-width: 300px;
															">
															{{
																question.question
															}}
														</div>
													</ng-template>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.enum
													"
													[ngClass]="{
														'has-error':
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															!category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
													}"
													class="ans-list">
													<!-- <div class="ans-item">{{question.question}}</div> -->
													<!-- <div class="ans-item">{{question.description}}</div> -->
													<div
														class="ans-item form-group">
														<select
															class="form-control"
															formControlName="title">
															<option value="">
																Select
															</option>
															<option
																*ngFor="
																	let select_option of oDefs[
																		'app_list_strings'
																	][
																		question[
																			'field_options'
																		]
																	]
																		| objectToIterable
																"
																[ngValue]="
																	select_option.key
																">
																{{
																	select_option.value
																}}
															</option>
														</select>
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.multienum
													"
													[ngClass]="{
														'has-error':
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															!category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
													}">
													<!-- <div>{{question.question}}</div> -->
													<!-- <div>{{question.description}}</div> -->
													<div
														class="form-group form-group"
														*ngIf="
															!question?.custom
														">
														<ngx-select
															[items]="
																aAppListString[
																	question
																		.field_options
																]
															"
															optionValueField="id"
															optionTextField="name"
															placeholder="{{
																question.question_placeholder
															}}"
															formControlName="title"
															[multiple]="
																true
															"></ngx-select>
													</div>
													<div
														class="text-danger"
														*ngIf="
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'minLengthArray'
																) &&
															!category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
														">
														Required Field
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.radio
													"
													class="mb-2"
													[ngClass]="{
														'has-error':
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'required'
																) &&
															!category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
													}">
													<!-- <div>{{question.question}}</div> -->
													<!-- <div>{{question.description}}</div> -->
													<div
														class="c-radio mb-1"
														*ngFor="
															let radio_option of oDefs[
																'app_list_strings'
															][
																question[
																	'field_options'
																]
															] | objectToIterable
														">
														<label>
															<input
																type="radio"
																formControlName="title"
																value="{{
																	radio_option.key
																}}" />
															<span
																class="fa fa-circle"></span>
															{{
																radio_option?.value
															}}
														</label>
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.checkbox
													"
													class="mb-2">
													<!-- <div>{{question.question}}</div> -->
													<!-- <div>{{question.description}}</div> -->
													<div
														class="checkbox c-checkbox mb-1"
														formArrayName="title"
														*ngFor="
															let item of category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title')
																.controls;
															let j = index
														">
														<div
															[formGroupName]="j">
															<label>
																<input
																	type="checkbox"
																	formControlName="value" />
																<span
																	class="fa fa-check"></span>
																{{
																	category[
																		'questionForms'
																	][
																		'steps'
																	].controls[
																		'step' +
																			(i +
																				1)
																	]
																		.get(
																			'title'
																		)
																		.controls[
																			j
																		].get(
																			'text'
																		).value
																}}
															</label>
														</div>
													</div>
													<div
														class="text-danger"
														*ngIf="
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].dirty &&
															category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															]
																.get('title')
																.hasError(
																	'minLengthCheckbox'
																) &&
															!category[
																'questionForms'
															]['steps'].controls[
																'step' + (i + 1)
															].get('title').valid
														">
														Required Field
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.text
													">
													<div
														class="form-group"
														[ngClass]="{
															'has-error':
																category[
																	'questionForms'
																]['steps']
																	.controls[
																	'step' +
																		(i + 1)
																].dirty &&
																category[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																]
																	.get(
																		'title'
																	)
																	.hasError(
																		'required'
																	) &&
																!category[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																].get('title')
																	.valid
														}">
														<!-- <label class="control-label" *ngIf="question.question">({{question.question}})</label> -->
														<!-- <div>{{question.description}}</div> -->
														<input
															type="text"
															class="form-control"
															placeholder="{{
																question.question_placeholder
															}}"
															formControlName="title" />
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.textarea
													">
													<!-- <label class="control-label" *ngIf="question.question">({{question.question}})</label> -->
													<!-- <div>{{question.description}}</div> -->
													<div class="row">
														<div
															class="col-md-12 project-brief-inline-editor form-group">
															<div
																formControlName="title"
																[ngxSummernote]="
																	getConfig(
																		question
																	)
																"></div>
														</div>
													</div>
												</div>

												<div
													*ngIf="
														question.project_question_type_id ==
														oQuestionTypes.file
													">
													<div
														class="mb-2"
														*ngIf="
															bShowProgressBar
														">
														<progressbar
															[animate]="false"
															[value]="
																progressBarPercentage
															"
															type="success">
															<b>
																{{
																	progressBarPercentage
																}}%
															</b>
														</progressbar>
													</div>
													<div
														class="form-group"
														[ngClass]="{
															'has-error':
																category[
																	'questionForms'
																]['steps']
																	.controls[
																	'step' +
																		(i + 1)
																].dirty &&
																category[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																]
																	.get(
																		'title'
																	)
																	.hasError(
																		'required'
																	) &&
																!category[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																].get('title')
																	.valid
														}">
														<!-- <label class="control-label" *ngIf="question.question">({{question.question}})</label> -->
														<!-- <div>{{question.description}}</div> -->
														<div class="row">
															<div
																class="col-md-12 mb-3">
																<div
																	class="custom-file">
																	<input
																		type="file"
																		class="custom-file-input"
																		id="que_file_step{{
																			i +
																				1
																		}}"
																		(change)="
																			onSingleQueFileSelected(
																				$event,
																				'step' +
																					(i +
																						1)
																			)
																		"
																		multiple="multiple" />
																	<label
																		class="custom-file-label"
																		for="que_file_{{
																			i +
																				1
																		}}">
																		Choose
																		file
																	</label>
																</div>
															</div>
															<div
																class="col-md-12">
																<div
																	ng2FileDrop
																	[ngClass]="{
																		'nv-file-over':
																			oBaseDropZoneOver[
																				'step' +
																					(i +
																						1)
																			]
																	}"
																	(fileOver)="
																		fileOverBase(
																			'step' +
																				(i +
																					1),
																			$event
																		)
																	"
																	(onFileDrop)="
																		onFileDropped(
																			'step' +
																				(i +
																					1),
																			$event
																		)
																	"
																	[uploader]="
																		oUploader[
																			'step' +
																				(i +
																					1)
																		]
																	"
																	class="bg-white border box-placeholder my-drop-zone text-center">
																	DRAG & DROP
																</div>
															</div>
														</div>
														<div
															*ngIf="
																category[
																	'questionForms'
																][
																	'steps'
																].controls[
																	'step' +
																		(i + 1)
																].get('title')
																	.controls
																	.length > 0
															">
															<div
																class=""
																formArrayName="title"
																*ngFor="
																	let item of category[
																		'questionForms'
																	][
																		'steps'
																	].controls[
																		'step' +
																			(i +
																				1)
																	].get(
																		'title'
																	).controls;
																	let j = index
																">
																<div
																	[formGroupName]="
																		j
																	">
																	<button
																		type="button"
																		class="mt-2 btn btn-labeled btn-secondary overflow-wrap-anywhere">
																		<span
																			class="btn-label">
																			<em
																				class="fa fa-file"></em>
																		</span>
																		{{
																			category[
																				'questionForms'
																			][
																				'steps'
																			].controls[
																				'step' +
																					(i +
																						1)
																			]
																				.get(
																					'title'
																				)
																				.controls[
																					j
																				].get(
																					'file'
																				)
																				?.value
																				?.name
																		}}
																		<span
																			class="btn-label btn-label-right"
																			(click)="
																				unsetQueFile(
																					'step' +
																						(i +
																							1),
																					j
																				)
																			">
																			<em
																				class="fa fa-times"></em>
																		</span>
																	</button>
																</div>
															</div>
														</div>
														<div
															*ngIf="
																oCopyBriefFiles[
																	'step' +
																		(i + 1)
																] &&
																oCopyBriefFiles[
																	'step' +
																		(i + 1)
																].length > 0
															">
															<div
																*ngFor="
																	let oCopyDoc of oCopyBriefFiles[
																		'step' +
																			(i +
																				1)
																	];
																	let j = index
																">
																<button
																	type="button"
																	class="mt-2 btn btn-labeled btn-secondary overflow-wrap-anywhere">
																	<span
																		class="btn-label">
																		<em
																			class="fa fa-file"></em>
																	</span>
																	{{
																		oCopyDoc.filename
																	}}
																	<span
																		class="btn-label btn-label-right"
																		(click)="
																			unsetCopyDoc(
																				'step' +
																					(i +
																						1),
																				j
																			)
																		">
																		<em
																			class="fa fa-times"></em>
																	</span>
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</accordion-group>

					<div
						class="card shadow card-body mb-4"
						*ngIf="
							category['questionForms']['steps'] && !isAdminUser
						">
						<div class="row row-flush align-items-center">
							<div class="col-12 col-lg-7 col-md-12 mb-3">
								<div
									*ngIf="
										(isOperationalClientUser ||
											isProjectManagerUser) &&
										pendingProjectsCountArr &&
										pendingProjectsCountArr.length > 0 &&
										!isEligibleActive
									">
									<div
										class="p mb-2"
										*ngIf="pendingProjectsCount != 0">
										You currently have
										{{ pendingProjectsCount }} pending
										{{
											pendingProjectsCount == 1
												? 'project'
												: 'projects'
										}}. Please select the order for this
										project to start.
									</div>
									<div
										class="p mb-2"
										*ngIf="pendingProjectsCount == 0">
										You don't have any pending projects.
										This project will start as soon as the
										active slot is available.
									</div>
									<div
										class="d-flex align-items-baseline"
										*ngIf="pendingProjectsCount != 0">
										<label class="text-bold">
											Order: &nbsp;
										</label>
										<select
											class="form-control mr-2 width-70"
											(change)="onChangeSortOrder($event)"
											*ngIf="
												pendingProjectsCountArr.length >
													0 && !isEligibleActive
											">
											<option
												[value]="j + 1"
												*ngFor="
													let project of pendingProjectsCountArr;
													let j = index
												"
												[selected]="
													j ==
													pendingProjectsCountArr.length -
														1
												">
												{{ j + 1 }}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-5 col-md-12">
								<div
									class="d-flex justify-content-end align-items-baseline"
									*ngIf="
										(!isAdminUser &&
											!isProjectManagerUser) ||
										(isProjectManagerUser && bCopyBrief)
									">
									<button
										class="btn btn-labeled mb-2 p-0 float-right fl-btn-grey d-flex align-items-center border-0"
										(click)="saveAsDraft($event)"
										*ngIf="
											oDefs['questions'] &&
											category['title'] &&
											oDefs['questions'][
												category['title']
											]?.length > 0 &&
											!isDraftDisbaled &&
											!isProjectManagerUser &&
											oProject?.project_status_id != 1 &&
											action != 'redo-project'
										"
										[disabled]="
											bShowProgressBar || bDisableBtn
										">
										<div
											class="btn-label fl-icon-grey circle-btn-wrapper">
											<i class="far fa-check-circle"></i>
										</div>
										<span
											*ngIf="action != 'start-project'"
											class="fl-text-grey mx-2">
											Save Project as Draft
										</span>
										<span
											*ngIf="action == 'start-project'"
											class="fl-text-grey mx-2">
											Update Draft
										</span>
									</button>
									<em
										class="fa fa-info-circle clickable ml-1 pr-1 font-14"
										[popover]="saveAsDraftTooltip"
										placement="auto"
										triggers="mouseenter:mouseleave click"
										*ngIf="
											oDefs['questions'] &&
											category['title'] &&
											oDefs['questions'][
												category['title']
											]?.length > 0 &&
											!isDraftDisbaled &&
											!isProjectManagerUser &&
											oProject?.project_status_id != 1 &&
											action != 'redo-project'
										"></em>
									<ng-template #saveAsDraftTooltip>
										By saving it as a draft, you'll be able
										to edit it later in your drafts section,
										or move it to your pending queue to be
										worked on. Projects saved as draft do
										not start without being moved to
										pending.
									</ng-template>

									<button
										class="btn btn-labeled btn-primary mb-2 mr-1 float-right d-flex align-items-center border-0"
										type="submit"
										[disabled]="
											!category['questionForms']['steps']
												.valid || bDisableBtn
										"
										*ngIf="
											oDefs['questions'] &&
											category['title'] &&
											oDefs['questions'][
												category['title']
											]?.length > 0
										">
										<div
											class="btn-label circle-btn-wrapper check-btn-wrapper">
											<i class="far fa-check-circle"></i>
										</div>
										<span>Start Project</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</accordion>
		</div>
	</div>
</div>

<ng-template #designerProfileModal>
	<div class="modal-header" *ngIf="oDesignerUser">
		<h4 class="modal-title text-primary">
			{{ oDesignerUser.name }}'s Profile
		</h4>
		<ng-container *ngIf="activeModalType == 'view_profile'">
			<div
				*ngIf="
					!oDesignerUser.is_suggested ||
					oDesignerUser.invite_exclude == 1
				">
				<ng-container
					*ngTemplateOutlet="
						showInviteExcludeModal;
						context: { oDesignerUser: oDesignerUser }
					"></ng-container>
			</div>
			<div
				*ngIf="
					oDesignerUser.is_suggested &&
					oDesignerUser.invite_exclude != 1
				">
				<ng-container
					*ngTemplateOutlet="
						showInviteCheckboxModal;
						context: { oDesignerUser: oDesignerUser }
					"></ng-container>
			</div>
		</ng-container>

		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="modalDesignerProjectRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body p-0" *ngIf="oDesignerUser">
		<div class="container container-xl p-0">
			<app-designer-profile
				[user_id]="oDesignerUser.id"
				[client_id]="
					isProjectManagerUser && bCopyBrief
						? oProject.created_by
						: ''
				"
				[profilePath]="oDesignerUser.profile_pic"
				[projectTypeId]="category.type"
				[projectType]="selectedProjectType"
				[trigger_id]="'1'"
				[isEligibleForHire]="isEligibleForHire"></app-designer-profile>
		</div>
	</div>
</ng-template>

<ng-template #showInviteCheckbox let-oTeamMember="oTeamMember">
	<label
		class="checkbox c-checkbox mr-1 mb-0"
		(click)="onClickAddTeamMember('step1', oTeamMember.id)"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oTeamMember.id) == -1
		">
		<input type="checkbox" />
		<span class="fa fa-check mr-0"></span>
		{{ oTeamMember.name }}
	</label>
	<label
		class="checkbox c-checkbox mr-1 mb-0"
		(click)="onClickRemoveTeamMember('step1', oTeamMember.id)"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oTeamMember.id) > -1
		">
		<input type="checkbox" checked="checked" />
		<span class="fa fa-check mr-0"></span>
		{{ oTeamMember.name }}
	</label>
</ng-template>

<ng-template
	#showInviteExclude
	let-oTeamMember="oTeamMember"
	let-showExlude="showExlude">
	<div class="disabled-content-wrapper">
		<button
			class="btn btn-primary mr-1 mb-2"
			[ngClass]="
				aExcludedTeamMembers.indexOf(oTeamMember.id) > -1
					? 'disabled'
					: ''
			"
			(click)="
				onClickAddTeamMember(
					'step1',
					oTeamMember.id,
					oTeamMember?.creative_level_id
				)
			"
			*ngIf="
				category['questionForms']['steps'].controls[
					'step1'
				].value.title.indexOf(oTeamMember.id) == -1
			">
			Invite
		</button>
		<button
			class="btn btn-warning mr-1 mb-2"
			(click)="onClickRemoveTeamMember('step1', oTeamMember.id)"
			*ngIf="
				category['questionForms']['steps'].controls[
					'step1'
				].value.title.indexOf(oTeamMember.id) > -1
			">
			Invited
		</button>
		<button
			class="btn btn-primary mr-1 mb-2"
			(click)="
				initDesignerProjectModal(
					oTeamMember,
					designerProfileModal,
					3,
					'view_profile'
				)
			">
			View Profile
		</button>
	</div>
	<div class="mt-2 disabled-content-wrapper" *ngIf="showExlude">
		<a
			class="btn-xs text-underline text-danger"
			href="javascript:"
			(click)="onClickAddExcludeTeamMember(oTeamMember.id)"
			[ngClass]="
				category['questionForms']['steps'].controls[
					'step1'
				].value.title.indexOf(oTeamMember.id) > -1
					? 'disabled text-muted'
					: ''
			"
			*ngIf="aExcludedTeamMembers.indexOf(oTeamMember.id) == -1">
			Exclude
		</a>
		<a
			class="btn-xs text-underline text-danger"
			href="javascript:"
			(click)="onClickRemoveExcludeTeamMember(oTeamMember.id)"
			*ngIf="aExcludedTeamMembers.indexOf(oTeamMember.id) > -1">
			Excluded
		</a>
	</div>
</ng-template>

<ng-template #showInviteCheckboxModal let-oDesignerUser="oDesignerUser">
	<button
		class="btn btn-primary btn-sm ml-3"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oDesignerUser.id) == -1
		"
		(click)="onClickAddTeamMember('step1', oDesignerUser.id)">
		Invite Team Member
	</button>
	<button
		class="btn btn-info btn-sm ml-3"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oDesignerUser.id) > -1
		"
		(click)="onClickRemoveTeamMember('step1', oDesignerUser.id)">
		Uninvite Team Member
	</button>
</ng-template>

<ng-template #showInviteExcludeModal let-oDesignerUser="oDesignerUser">
	<button
		class="btn btn-outline-primary btn-sm ml-3 mr-1"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oDesignerUser.id) == -1
		"
		(click)="onClickAddTeamMember('step1', oDesignerUser.id)">
		Invite Team Member
	</button>
	<button
		class="btn btn-primary btn-sm ml-3 mr-1"
		*ngIf="
			category['questionForms']['steps'].controls[
				'step1'
			].value.title.indexOf(oDesignerUser.id) > -1
		"
		(click)="onClickRemoveTeamMember('step1', oDesignerUser.id)">
		Invited Team Member
	</button>
	<button
		class="btn btn-outline-danger btn-sm"
		(click)="onClickAddExcludeTeamMember(oDesignerUser.id)"
		*ngIf="aExcludedTeamMembers.indexOf(oDesignerUser.id) == -1">
		Exclude Team Member
	</button>
	<button
		class="btn btn-danger btn-sm"
		(click)="onClickRemoveExcludeTeamMember(oDesignerUser.id)"
		*ngIf="aExcludedTeamMembers.indexOf(oDesignerUser.id) > -1">
		Excluded Team Member
	</button>
</ng-template>

<ng-template #hireDesignerModal>
	<div class="modal-header" *ngIf="oDesignerUser">
		<h4 class="modal-title">Hire {{ oDesignerUser.name }}</h4>

		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="modalHireDesignerRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" *ngIf="oDesignerUser">
		<div class="container container-xl">
			<app-hire-designer
				[user_id]="oDesignerUser.id"
				[profilePath]="oDesignerUser.profile_pic"
				[projectTypeId]="category.type"
				[projectType]="selectedProjectType"
				[trigger_id]="'1'"
				(emitCloseModal)="
					modalHireDesignerRef.hide()
				"></app-hire-designer>
		</div>
	</div>
</ng-template>

<ng-template #archiveProjectModal>
	<div class="modal-header">
		<h4 class="modal-title text-primary">{{ oProject.title }}</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="modalArchiveProjectRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="">
			<div class="text-bold">
				Are you sure you want to archive this project?
			</div>
		</div>
	</div>

	<div class="p-4">
		<div class="text-right">
			<a
				href="javascript:"
				class="text-underline mr-2"
				data-dismiss="modal"
				(click)="modalArchiveProjectRef.hide()">
				Cancel
			</a>
			<button
				type="button"
				class="btn btn-primary ml-2"
				data-dismiss="modal"
				(click)="onSubmitArchiveProject()">
				I am sure, archive project
			</button>
		</div>
	</div>
</ng-template>

<ng-template #initNotesModal>
	<app-notes
		[designer]="oDesignerUser"
		(emitCloseModal)="modalNotesRef.hide()"></app-notes>
</ng-template>

<ng-template #initStatsModal>
	<app-stats
		[designer]="oDesignerUser"
		(emitCloseStatsModal)="modalStatsRef.hide()"></app-stats>
</ng-template>

<app-project-payments
	[isPaymentModalOpen]="isPaymentModalOpen"
	[selectedPackage]="selectedPackage"
	[projectsData]="oProjectsData"
	[currentProject]="currentProject"
	(paymentProceed)="onProjectProceedSuccessful()"
	(closePaymentModel)="onClosePaymentModel()"
	*ngIf="isPaymentModalOpen"></app-project-payments>

<ng-template #packageDetailedInfo>
	<app-package-detailed-info
		(onClosePackageModal)="closePackageModal($event)"
		[packageInfo]="packageInfo"
		(onSelectPackage)="onSelectPackage($event)"
		[selectedPackage]="selectedPackage"></app-package-detailed-info>
</ng-template>
