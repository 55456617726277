import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RatingModule } from 'ngx-bootstrap';
import { TeamService } from '../../../services/team/team.service';
import { NewLinePipeModule } from '../../pipes/new-line/new-line.pipe.module';
import { SharedModule } from '../../shared.module';
import { PersonalMessageDetailviewModule } from '../personal-message/detailview/personal-message.detailview.module';
import { HireDesignerComponent } from './hire-designer.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		RatingModule,
		FormsModule,
		NewLinePipeModule,
		SharedModule,
		PersonalMessageDetailviewModule,
	],
	exports: [HireDesignerComponent],
	declarations: [HireDesignerComponent],
	providers: [TeamService],
})
export class HireDesignerModule {}
