export const CATEGORIES = [
  {
    title: "Custom Illustrations",
    subcategories: [
      {
        title: "Animals",
        keywords: ["Pets", "creatures", "wildlife"]
      },
      {
        title: "Blueprint / Floor Plans",
        keywords: ["Architectural blueprints", "layout", "building", "home design", "structural plans"]
      },
      {
        title: "Caricatures",
        keywords: ["Portraits", "personalized", "cartoon", "funny", "drawings"]
      },
      {
        title: "Cartoon Comic Strip",
        keywords: ["webcomic", "graphic storytelling", "sequential art", "newspaper comic strip"]
      },
      {
        title: "Storybook Illustration",
        keywords: ["Nursery rhyme", "picture book", "fairy tale", "children's book"]
      },
      {
        title: "Coloring Books",
        keywords: ["Coloring pages", "mandala", "digital coloring book", "kids"]
      },
      {
        title: "Complex Vector Edit",
        keywords: ["Editing", "professional vector editing", "scalable graphics"]
      },
      {
        title: "Concept Art",
        keywords: ["Conceptual illustration", "character", "game art", "concept design", "environment"]
      },
      {
        title: "Custom Typography",
        keywords: ["Custom font design", "typeface", "custom script lettering"]
      },
      {
        title: "Illustrated Assets for Games",
        keywords: ["Game sprites", "backgrounds", "character designs", "assets"]
      },
      {
        title: "Geometric Art",
        keywords: ["Geometric design", "patterns", "abstract art"]
      },
      {
        title: "Brand Icons",
        keywords: ["Company icons", "brand logo"]
      },
      {
        title: "Illustrated Gifs",
        keywords: ["Animated illustrations", "illustrated animations", "motion designs", "GIF loops", "animated artwork"]
      },
      {
        title: "Illustrated Infographic",
        keywords: ["Infographic illustrations", "statistics", "graphic data"]
      },
      {
        title: "Illustrated Manuals",
        keywords: ["Guides", "visual manuals", "illustrated instructions", "step-by-step"]
      },
      {
        title: "Landscape/Background",
        keywords: ["Scenery", "backdrop", "background illustration"]
      },
      {
        title: "Brand Mascot",
        keywords: ["Character", "brand personality", "idol"]
      },
      {
        title: "Medical Illustrations",
        keywords: ["Scientific illustrations", "healthcare", "anatomy", "medical diagrams"]
      },
      {
        title: "Merch Illustrations",
        keywords: ["Product illustrations", "apparel", "merchandise design"]
      },
      {
        title: "Other Illustrations",
        keywords: ["Miscellaneous illustrations", "random graphics", "design"]
      },
      {
        title: "Vectorizing an Image",
        keywords: ["Convert image to vector", "vectorization", "image tracing", "conversion"]
      },
      {
        title: "Realistic Portraits Illustration",
        keywords: ["Photorealistic", "hyperrealistic portrait art", "lifelike", "detailed drawing"]
      },
      {
        title: "Scene Illustration",
        keywords: ["Illustrative scenes", "digital scene illustration"]
      },
      {
        title: "Trademarked Logo",
        keywords: ["Registered", "official", "branding", "trademark logo"]
      },
      {
        title: "Illustrated Presentation Designs",
        keywords: ["Slides", "PowerPoint", "presentation illustrations"]
      },
      {
        title: "Character Design",
        keywords: ["Character creation", "concept character", "characterisation"]
      },
      {
        title: "T-Shirt Print Design",
        keywords: ["T-shirt designs", "printed artwork", "apparel"]
      },
      {
        title: "Custom Line Art",
        keywords: ["Custom line drawings", "bitmap", "drawing"]
      },
      {
        title: "Product Illustration",
        keywords: ["Product artwork", "mockup", "rendering"]
      },
      {
        title: "Ready for Animation Illustrated Assets",
        keywords: ["Animation-ready illustrations", "ready-to-animate", "asset pack", "pre-made", "Illustration"]
      },
      {
        title: "Avatar Creation",
        keywords: ["Custom avatar", "personalized", "character design"]
      },
      {
        title: "Map Illustration",
        keywords: ["Cartography", "map design", "geographical illustration"]
      },
      {
        title: "Vectorizing an AI-Generated Image",
        keywords: ["AI image vectorization", "vector conversion", "vectorize"]
      },
      {
        title: "Tattoo Art",
        keywords: ["Tattoo designs", "body", "ink art", "etching"]
      },
      {
        title: "Illustrated Album Cover",
        keywords: ["Vinyl cover illustration", "album artwork", "music"]
      },
      {
        title: "Illustrations (Advanced)",
        keywords: ["picture", "drawing", "sketch", "graphic"]
      },
      {
        title: "Illustrations (Simple)",
        keywords: ["picture", "drawing", "sketch", "graphic"]
      }
    ]
  },
  {
    title: "Video Editing",
    subcategories: [
      {
        title: "Other Video",
        keywords: ["film", "recording", "clip", "footage", "reel"]
      },
      {
        title: "Instagram Reels",
        keywords: ["Short-form video", "social media video", "Instagram video", "reels"]
      },
      {
        title: "TikTok",
        keywords: ["Short-form video", "social media video", "TikTok content"]
      },
      {
        title: "Educational and Informative Content",
        keywords: ["Informative content", "explainer", "learning resources", "informational videos", "tutorial"]
      },
      {
        title: "YouTube Shorts",
        keywords: ["Shorts", "short-form video", "clips"]
      },
      {
        title: "Customer Reviews and Testimonials",
        keywords: ["Testimonials", "product reviews", "client feedback"]
      },
      {
        title: "Snippets from Longer Videos",
        keywords: ["Snippets", "video clips", "excerpts", "highlights", "reels"]
      },
      {
        title: "Talking Head",
        keywords: ["Presenter videos", "on-camera presentation", "spokesperson", "vlog videos", "lectures"]
      },
      {
        title: "Tour of a Building or Property",
        keywords: ["Property", "building", "real estate", "virtual tour", "walkthrough"]
      },
      {
        title: "Audio Editing",
        keywords: ["Sound editing", "audio trimming", "podcast editing", "soundtrack editing", "voiceover editing"]
      }
    ]
  },
  {
    title: "Motion Graphics",
    subcategories: [
      {
        title: "Advanced Titling",
        keywords: ["Video effects", "animation", "3D", "motion title graphics"]
      },
      {
        title: "Animated Ad",
        keywords: ["Motion graphic ads", "animated advertisement"]
      },
      {
        title: "Animated Explainer",
        keywords: ["Explainer video animation", "educational animation"]
      },
      {
        title: "Animated Logo",
        keywords: ["Logo animation", "3D"]
      },
      {
        title: "Animated Text",
        keywords: ["Text animation", "3D", "motion graphics", "typography"]
      },
      {
        title: "Graphic Loop",
        keywords: ["Looping", "video loop animation", "animated graphic"]
      },
      {
        title: "Motion Graphics",
        keywords: ["2D", "3D", "animated motion graphics"]
      },
      {
        title: "Product Animation",
        keywords: ["Animated product demos", "3D animation"]
      },
      {
        title: "Template Editing",
        keywords: ["Template", "editable video", "motion graphics editing"]
      },
      {
        title: "Transparent Animated Assets",
        keywords: ["Overlay", "transparent GIFs", "clear background animations", "animated PNGs"]
      },
      {
        title: "UI / UX Animation",
        keywords: ["UX animation", "interface", "microinteractions", "interactive UI"]
      },
      {
        title: "Animated Subtitles",
        keywords: ["Subtitle", "motion graphic subtitles"]
      },
      {
        title: "Video Marketing Assets for Social Media",
        keywords: ["Social media marketing", "reels", "TikTok", "shorts", "branded video assets"]
      },
      {
        title: "Other Video",
        keywords: ["Miscellaneous videos", "general content", "custom"]
      }
    ]
  },
  {
    title: "Graphic Design",
    subcategories: [
      {
        title: "Non-Trademarked Logo",
        keywords: ["Generic design", "trademark-free logo", "open-source", "standard"]
      },
      {
        title: "Ads",
        keywords: ["Digital advertisements", "banner", "online", "social media ads", "campaigns"]
      },
      {
        title: "Apparel",
        keywords: ["Custom apparel", "branded clothing", "clothing design", "t-shirts", "apparel"]
      },
      {
        title: "Basic Photo Editing & Manipulation",
        keywords: ["Retouching", "photo touch-ups", "correction", "manipulation"]
      },
      {
        title: "Blog Graphics",
        keywords: ["Blog images", "photos"]
      },
      {
        title: "Book Covers",
        keywords: ["Custom book covers", "eBook cover design", "book jacket design", "cover art for books"]
      },
      {
        title: "Brochures",
        keywords: ["Tri-fold", "promotional brochures"]
      },
      {
        title: "Cards",
        keywords: ["Greeting card design", "business", "holiday", "event", "personalized cards"]
      },
      {
        title: "Catalogs",
        keywords: ["Product catalogs", "digital", "print", "design"]
      },
      {
        title: "Corporate Identity",
        keywords: ["Brand identity", "corporate branding", "company", "business"]
      },
      {
        title: "Ecommerce Graphics",
        keywords: ["Product images", "online store graphics", "ecommerce visuals"]
      },
      {
        title: "Email Signatures",
        keywords: ["HTML signatures", "footer design", "professional email signatures"]
      },
      {
        title: "Etsy",
        keywords: ["Etsy shop branding", "product photos", "ecommerce"]
      },
      {
        title: "Facebook",
        keywords: ["Social media ads", "marketing", "post graphics"]
      },
      {
        title: "Flyers",
        keywords: ["Event flyers", "handbill", "advertisement", "leaflet", "handout"]
      },
      {
        title: "GIFs",
        keywords: ["Animated GIFs", "clip"]
      },
      {
        title: "Google Display Ads",
        keywords: ["Google", "online display", "banner ad design", "display"]
      },
      {
        title: "Hero Images",
        keywords: ["Banner design", "website hero images", "above-the-fold", "webpage"]
      },
      {
        title: "Icons",
        keywords: ["Mobile app", "SVG icons"]
      },
      {
        title: "Infographics (Advanced)",
        keywords: ["Data visualization", "visual representation"]
      },
      {
        title: "Infographics (Simple)",
        keywords: ["Data visualization", "visual representation"]
      },
      {
        title: "Instagram",
        keywords: ["Social media posts", "content creation for Instagram"]
      },
      {
        title: "Invites",
        keywords: ["Invitation design", "printable", "event", "digital invites"]
      },
      {
        title: "Light Web Design",
        keywords: ["Simple web design", "ecommerce"]
      },
      {
        title: "LinkedIn",
        keywords: ["Social media posts", "ads", "content for LinkedIn"]
      },
      {
        title: "Magazines",
        keywords: ["Digital magazines", "online", "cover design"]
      },
      {
        title: "Mug Graphics",
        keywords: ["Printed", "coffee mugs graphics", "cup"]
      },
      {
        title: "Newsletters",
        keywords: ["Bulletin", "journal", "magazine", "pamphlet", "email newsletters"]
      },
      {
        title: "Other Collateral",
        keywords: ["Promotional materials", "collateral design", "custom marketing"]
      },
      {
        title: "Other Digital Graphics",
        keywords: ["Online", "web graphics", "social media", "digital art"]
      },
      {
        title: "Other Merchandise",
        keywords: ["Promotional items", "products", "merchandise design"]
      },
      {
        title: "Other Social Media Graphics",
        keywords: ["Social media content", "banner ads", "branded graphics", "post"]
      },
      {
        title: "Other Web Sizes",
        keywords: ["Header", "footer graphics", "web ad sizes", "website sizes"]
      },
      {
        title: "Outdoor Graphics / Signages",
        keywords: ["Outdoor", "billboard design", "street signage","banners", "signboard"]
      },
      {
        title: "Packaging Labels",
        keywords: ["Label design", "product labels", "bottle", "food packaging", "sticker"]
      },
      {
        title: "Pinterest",
        keywords: ["Social media posts", "pins", "Pinterest graphics"]
      },
      {
        title: "Podcast Cover",
        keywords: ["Podcast logo", "thumbnail design", "cover"]
      },
      {
        title: "Postcards",
        keywords: ["Postal cards", "mail", "cards"]
      },
      {
        title: "Posters",
        keywords: ["Banner", "billboard", "event posters", "placard"]
      },
      {
        title: "Presentation Graphic Elements",
        keywords: ["Slide design", "PowerPoint", "keynote graphics", "infographic", "presentation icons"]
      },
      {
        title: "Printed Banner",
        keywords: ["Placard", "emblem", "banner design", "vinyl"]
      },
      {
        title: "Sales Sheets",
        keywords: ["Product", "datasheets", "detail sheet", "product brochure", "sales slick"]
      },
      {
        title: "Shirt Graphics",
        keywords: ["Graphic tees", "t-shirt design", "shirt printing", "custom apparel graphics"]
      },
      {
        title: "Slides",
        keywords: ["Presentation", "Google Slides", "PowerPoint", "slide deck"]
      },
      {
        title: "Social Media Collateral",
        keywords: ["Social media posts", "branded content", "branding"]
      },
      {
        title: "Stickers",
        keywords: ["Adhesive", "label", "decal", "vinyl stickers", "die-cut"]
      },
      {
        title: "TikTok",
        keywords: ["Social media graphics", "influencer marketing", "TikTok content"]
      },
      {
        title: "Trade Show Displays",
        keywords: ["Exhibition displays", "expo", "conference", "convention", "exposition"]
      },
      {
        title: "Twitch",
        keywords: ["Emotes", "Twitch branding", "overlays", "streaming content"]
      },
      {
        title: "Website Mockups",
        keywords: ["UI mockup", "web design", "website layout", "prototype", "landing page", "webpage", "web page"]
      },
      {
        title: "X (Formerly Twitter)",
        keywords: ["Social media graphics", "tweets", "Twitter content"]
      },
      {
        title: "YouTube",
        keywords: ["Social media graphics", "thumbnail", "banner", "channel icon"]
      },
      {
        title: "App Design",
        keywords: ["Application", "mobile design", "software design", "app UI/UX", "mobile app"]
      },
      {
        title: "Packaging Design",
        keywords: ["Product packaging", "case design", "cover", "wrapping", "box design"]
      },
      {
        title: "Branding Guide",
        keywords: ["Brand book", "charter", "graphic guidelines", "branding", "guide"]
      },
      {
        title: "Forms",
        keywords: ["Questionnaire", "surveys", "sheets", "applications", "documents"]
      },
      {
        title: "Update an Existing Graphic",
        keywords: ["Edit graphic", "modify image", "update", "alter design"]
      },
      {
        title: "Updates to an Existing Logo",
        keywords: ["Redesign", "logo update", "rebranding", "revamp"]
      },
      {
        title: "Fillable PDF",
        keywords: ["Interactive PDFs", "editable form"]
      },
      {
        title: "Menu",
        keywords: ["Restaurant", "food", "digital menu", "top-level", "navigation bar"]
      },
      {
        title: "Mockup",
        keywords: ["Simulation", "PSD mock-ups", "layout"]
      },
      {
        title: "PowerPoint Presentations",
        keywords: ["PowerPoint", "Google", "corporate presentations", "slide deck"]
      },
      {
        title: "eBook Design",
        keywords: ["Digital", "electronic book design", "iBook", "eBook layout", "EPUB"]
      },
      {
        title: "Template Design",
        keywords: ["Editable template", "guide", "blueprint"]
      },
      {
        title: "Vectorize an Existing Logo",
        keywords: ["Scalable logos", "vectorize", "convert"]
      },
      {
        title: "Workbook Design",
        keywords: ["Digital workbooks", "log graphics", "handbook"]
      }
    ]
  },
  {
    title: "Copywriting",
    subcategories: [
      {
        title: "Blog Post",
        keywords: ["Article", "blogging", "story", "content writing"]
      },
      {
        title: "Taglines/PPC Ads",
        keywords: ["PPC ad copy", "pay-per-click ads", "taglines", "slogan", "marketing"]
      },
      {
        title: "Social Media Content",
        keywords: ["Social media posts", "campaigns", "content strategy", "captions"]
      },
      {
        title: "Transcribed Audio/Video",
        keywords: ["Transcription", "subtitles", "audio", "video", "transcribe"]
      },
      {
        title: "Website Content (incl. Landing pages)",
        keywords: ["SEO content", "landing page", "funnel", "lead capture", "webpage"]
      },
      {
        title: "Product Descriptions",
        keywords: ["Product information", "description"]
      },
      {
        title: "Podcast/Video Summaries",
        keywords: ["Summary", "recap", "highlight", "video synopsis", "transcription"]
      },
      {
        title: "Email Content",
        keywords: ["Marketing", "newsletter", "email blast", "campaigns"]
      },
      {
        title: "Recycled Posts/Content",
        keywords: ["Content repurposing", "repurposed posts", "rewrite"]
      },
      {
        title: "Proofreading/Editing",
        keywords: ["Copy editing", "grammar check", "proofing"]
      },
      {
        title: "Articles",
        keywords: ["Web articles", "blog", "news", "essay"]
      },
      {
        title: "Press Release",
        keywords: ["Media release", "announcement", "handout", "bulletin", "news"]
      },
      {
        title: "Infographic Text Content",
        keywords: ["Chart", "diagram", "graph text", "data for infographics"]
      },
      {
        title: "eBook Content",
        keywords: ["Chapters", "manual", "ghostwriting", "book writing"]
      },
      {
        title: "Business Description",
        keywords: ["About us", "biography", "brand description", "company overview"]
      },
      {
        title: "Narrative Writing",
        keywords: ["Storytelling", "chronology", "narration"]
      },
      {
        title: "Video/Audio Scripts",
        keywords: ["Scriptwriting", "dialogue"]
      },
      {
        title: "Advertising Content",
        keywords: ["Ad copy", "advertising", "promotional content", "campaign", "marketing"]
      },
      {
        title: "Biographic Content",
        keywords: ["Biography", "personal history"]
      },
      {
        title: "Branding Guide",
        keywords: ["Style guide", "branding books"]
      },
      {
        title: "Organizing Content",
        keywords: ["Structuring", "organization", "content arrangement"]
      },
      {
        title: "General Research",
        keywords: ["Research", "data collection", "information gathering", "analysis"]
      },
      {
        title: "Voiceover Work",
        keywords: ["Podcast", "audio", "narration", "voice recording"]
      }
    ]
  },
  {
    title: "Website Development",
    subcategories: [
      {
        title: "PSD to Drag and Drop",
        keywords: ["PSD conversion", "drag-and-drop"]
      },
      {
        title: "PSD to HTML",
        keywords: ["Convert PSD to HTML", "conversion"]
      },
      {
        title: "Sketch to Drag and Drop",
        keywords: ["Sketch conversion", "drag-and-drop"]
      },
      {
        title: "Sketch to HTML",
        keywords: ["Convert Sketch", "HTML conversion"]
      }
    ]
  }
];
