import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { SharedHeaderComponent } from './shared-header.component';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgxSelectModule,
		RouterModule,
		BsDropdownModule,
	],
	exports: [SharedHeaderComponent],
	declarations: [SharedHeaderComponent],
	providers: [],
})
export class SharedHeaderModule {}
