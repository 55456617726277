import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmojiReactionComponent } from './emoji-reaction.component';
import { EmojiReactionWrapperComponent } from './emoji-reaction-wrapper/emoji-reaction-wrapper.component';
import { PopoverModule } from 'ngx-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
	declarations: [EmojiReactionComponent, EmojiReactionWrapperComponent],
	imports: [CommonModule, PopoverModule, TooltipModule],
	exports: [EmojiReactionComponent, EmojiReactionWrapperComponent],
})
export class EmojiReactionModule {}
