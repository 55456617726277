import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable({
	providedIn: 'root'
  })
export class BucketService {
	selectedBucket = new BehaviorSubject<any>([]);
	constructor(private _customHttpService: CustomHttpService) {}

	get(oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.BUCKETS_URL +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	store(oData): Observable<any> {
		return this._customHttpService.post(API_CONSTANTS.BUCKETS_URL, oData);
	}

	show(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.BUCKETS_URL + '/' + id
		);
	}

	update(id, oData): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.BUCKETS_URL + '/' + id,
			oData
		);
	}

	destroy(id): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.BUCKETS_URL + '/' + id
		);
	}
}
