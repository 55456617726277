import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared.module';
import { NgModule } from '@angular/core';
import { ProjectBriefComponent } from './project-brief.component';
import { ProjectChatService } from '@services/project/project-chat.service';

import { CommonModule } from '@angular/common';
import { ProjectBriefService } from '@services/project/project-brief.service';
import { DocumentService } from '@services/document/document.service';
import { ProjectService } from '@services/project/project.service';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { NewLinePipeModule } from '../../pipes/new-line/new-line.pipe.module';
import { FormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { Br2NlModule } from '../../pipes/br2nl/br2nl.pipe.module';
import { InnerHtmlPipeModule } from '../../pipes/innerhtml/innerhtml.pipe.module';
import { FileUploadModule } from 'ng2-file-upload';
import { LinkifyHtmlModule } from '../../pipes/linkify-html/linkify-html.pipe.module';
import { NgxSummernoteModule } from 'ngx-summernote';
import { S3DocumentService } from '@services/document/s3-document.service';
import { NotesService } from '@services/requirement/notes.service';
import { PdfViewerModuleModule } from '../pdf-viewer/pdf-viewer.module';
import { ProjectBucketService } from '@services/project/project-bucket.service';
import { MarketplaceModule } from '../../../routes/marketplace/marketplace.module';
@NgModule({
	imports: [
		CommonModule,
		NgxLinkifyjsModule,
		LinkifyHtmlModule,
		NewLinePipeModule,
		FormsModule,
		NgxSelectModule,
		SharedModule,
		NgxDatatableModule,
		Br2NlModule,
		InnerHtmlPipeModule,
		FileUploadModule,
		NgxSummernoteModule,
		PdfViewerModuleModule,
		MarketplaceModule,
	],
	exports: [ProjectBriefComponent],
	declarations: [ProjectBriefComponent],
	providers: [
		ProjectBriefService,
		DocumentService,
		S3DocumentService,
		ProjectService,
		ProjectChatService,
		NotesService,
		ProjectBucketService,
	],
})
export class ProjectBriefModule {}
