import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { CreateProjectOverlayCloseAction } from '../create-project-overlay.component';
import { Subject } from 'rxjs';
import { LocalStorageService } from '@services/localstorage.service';
import { ClientSubClientService } from '@services/client/client-subclient.service';
import { AnalyticsService } from '@services/analytics/analytics.service';

@Component({
	selector: 'app-create-project-overlay-trigger',
	templateUrl: './create-project-overlay-trigger.component.html',
	styleUrls: ['./create-project-overlay-trigger.component.scss'],
})
export class CreateProjectOverlayTriggerComponent implements OnInit, OnDestroy {
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();
	canSubClientCreateProject: boolean = false;
	currentUser$ = this._localStorageService.getCurrentUser$();
	//Create Project Overlay
	@ViewChild('createProjectButton') createProjectButton: ElementRef<HTMLDivElement>;
	showOverlay: boolean = false;
	showOverlayTrigger: boolean = true;
	hideOverlayRoutes: string[] = ['login', 'projects/create', 'billing', 'subscriptions', 'logout', 'plans', 'password/reset'];
	@ViewChild('createOverlay') createOverlay: ElementRef<HTMLDivElement>;
	componentDestroyed$: Subject<boolean> = new Subject();
	openOverlayFromRoute$: Subject<boolean> = new Subject<boolean>();
	openOverlayFragment: string = 'create-project';
	oldY: number = 0;
	oldX: number = 0;
	dragged: boolean = false;

	get isMinimised(): boolean {
		return this.showOverlay && this.showOverlayTrigger;
	}

	get canCreateProject(): boolean {
		if (this.isOperationalClientUser) {
			return this._localStorageService.isClientUser() || this.canSubClientCreateProject;
		}
		return false;
	}

	constructor(
		private _localStorageService: LocalStorageService,
		private _clientSubClientService: ClientSubClientService,
		private route: Router,
		private _activatedRoute: ActivatedRoute,
		private _analyticsService: AnalyticsService,
	) {
		this.subscribeToRoutes();
	}

	ngOnInit(): void {
		this.subscribeToUserChanges();

		if (this.canCreateProject) {
			this.openOverlayFromRoute$.pipe(debounceTime(500)).subscribe((open: boolean) => {
				if (open) {
					this.openCreateProjectOverlay();
				}
			});
		}
	}

	subscribeToUserChanges() {
		this.currentUser$.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((user: any) => {
				if (user['is_client']) {
					this.isOperationalClientUser = true;
				} else if (user['is_sub_client']) {
					this.getPermissions();
				}
			});
	}

	getPermissions() {
		this._clientSubClientService.getPermissions(this._localStorageService.getParentId(), this._localStorageService.getUserId())
			.subscribe((res) => {
				this.canSubClientCreateProject = this._clientSubClientService.canViewManageOwnProjects(res?.data?.permissions) ||
					this._clientSubClientService.canManageOwnProjects(res?.data?.permissions) ||
					this._clientSubClientService.canManageAllProjects(res?.data?.permissions)
				;
			});
	}

	subscribeToRoutes() {
		for (let route of this.hideOverlayRoutes) {
			if (this.route.url.includes(route)) {
				this.showOverlay = false;
				this.showOverlayTrigger = false;
			}
		}
		this.route.events
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((event) => {
				if (event instanceof NavigationStart) {
					for (let route of this.hideOverlayRoutes) {
						if (event.url.includes(route)) {
							this.showOverlay = false;
							this.showOverlayTrigger = false;
						}
					}
				}

				if (event instanceof NavigationEnd) {
					this.onNavigationEnd(event);
				}

				if (event instanceof NavigationError) {
					this.showOverlay = false;
					this.showOverlayTrigger = false;
					console.log(event.error);
				}
			});
	}

	onNavigationEnd(event: NavigationEnd) {
		let promise = new Promise<string>((res, rej) => {
			for (let route of this.hideOverlayRoutes) {
				if (event.url.includes(route)) {
					rej(route);
					return;
				}
			}
			res('resolved');
		});
		promise.then((res) => {
			this._activatedRoute.fragment
				.pipe(takeUntil(this.componentDestroyed$))
				.subscribe((value) => {
					this.openOverlayFromRoute$.next(value === this.openOverlayFragment);
				});
			this.onCloseCheck(CreateProjectOverlayCloseAction.MINIMISE); //minimise
			this.showOverlayTrigger = true;
		})
			.catch((v) => {
				this.showOverlay = false;
				this.showOverlayTrigger = false;
			});
	}

	onCloseCheck(action: CreateProjectOverlayCloseAction) {
		switch (action) {
			case CreateProjectOverlayCloseAction.CLOSE:
				this.showOverlay = false;
				this.showOverlayTrigger = true;
				this.route.navigate([]);
				document.querySelector('body').style.overflow = 'auto';
				break;
			case CreateProjectOverlayCloseAction.MINIMISE:
				if (this.createOverlay) {
					this.createOverlay.nativeElement.style.visibility = 'hidden';
				}
				this.showOverlayTrigger = true;
				document.querySelector('body').style.overflow = 'auto';
				break;
		}
	}

	openCreateProjectOverlay() {
		this._analyticsService.setRouteData(17, 197);
		this.route.navigate([], { fragment: 'create-project' });
		const body = document.querySelector('body');
		this.scrollToTop();
		setTimeout(() => {
			body.style.overflow = 'hidden';
			if (this.createOverlay) {
				this.createOverlay.nativeElement.style.visibility = 'visible';
			}
			this.showOverlay = true;
			this.showOverlayTrigger = false;
		}, 100);
	}

	scrollToTop() {
		(function smoothscroll() {
			var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			if (currentScroll > 0) {
				window.requestAnimationFrame(smoothscroll);
				window.scrollTo(0, currentScroll - (currentScroll / 8));
			}
		})();
	}

	onTouchStart(ev: TouchEvent) {
		this.oldX = ev.changedTouches[0].pageX;
		this.oldY = ev.changedTouches[0].pageY;
		this.dragged = true;
	}

	onMouseDown(ev: MouseEvent) {
		this.oldY = ev.pageY;
		this.oldX = ev.pageX;
		this.dragged = true;
	}

	onTouchEnd(ev: TouchEvent) {
		if (this.dragged) {
			if (ev.changedTouches[0].pageX < this.oldX) {
				this.moveButton('left');
			} else if (ev.changedTouches[0].pageY > this.oldY) {
				this.moveButton('default');
			} else if (ev.changedTouches[0].pageY < this.oldY) {
				this.moveButton('up');
			} else if (ev.changedTouches[0].pageX > this.oldX) {
				this.moveButton('default');
			}
		}
		this.dragged = false;
	}

	onMouseUp(ev: MouseEvent) {
		if (this.dragged) {
			if (ev.pageY < this.oldY) {
				this.moveButton('up');
			} else if (ev.pageY > this.oldY) {
				this.moveButton('default');
			} else if (ev.pageX < this.oldX) {
				this.moveButton('left');
			} else if (ev.pageX > this.oldX) {
				this.moveButton('default');
			}
		}

		this.dragged = false;
	}

	moveButton(direction: 'up' | 'default' | 'left') {
		switch (direction) {
			case 'default':
				this.createProjectButton.nativeElement.style.transform = 'translate(-100%,-100%)';
				this.createProjectButton.nativeElement.style.left = '100%';
				this.createProjectButton.nativeElement.style.top = '100%';
				break;
			case 'up':
				this.createProjectButton.nativeElement.style.transform = 'translate(-100%,0)';
				this.createProjectButton.nativeElement.style.left = '100%';
				this.createProjectButton.nativeElement.style.top = '0%';
				break;
			case 'left':
				this.createProjectButton.nativeElement.style.transform = 'translate(0,-100%)';
				this.createProjectButton.nativeElement.style.left = '10%';
				this.createProjectButton.nativeElement.style.top = '100%';
				break;
		}
	}

	ngOnDestroy() {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

}
