<div>
	<div class="mb-1">Your unique referral link</div>
	<div class="input-group mb-4">
		<input
			disabled
			[value]="urlShare"
			class="form-control text-muted line-height-16 font-14"
			type="text" />
		<div class="input-group-append clickable" tooltip="Copy to Clipboard">
			<span
				(click)="copyToClipboard()"
				class="input-group-text clickable">
				<img
					class="copy-image social-media-icon clickable"
					src="assets/img/referral-link/clipboard2-check-fill.svg"
					alt="Copy" />
			</span>
		</div>
	</div>
	<hr class="mt-4 mb-4" />
	<div class="mb-3">Share on Social Media</div>
	<div class="d-flex flex-row mb-3">
		<div class="input-group-append mr-4">
			<span>
				<img
					class="social-media-icon clickable"
					(click)="shareOnFacebook()"
					src="assets/img/referral-link/social-media/facebook-icon.svg"
					alt="" />
			</span>
		</div>
		<div class="input-group-append mr-4">
			<span>
				<img
					class="social-media-icon clickable"
					(click)="shareOnLinkedIn()"
					src="assets/img/referral-link/social-media/linkedin-icon.svg"
					alt="" />
			</span>
		</div>
		<div class="input-group-append mr-4">
			<span>
				<img
					class="social-media-icon clickable"
					(click)="shareOnTwitter()"
					src="assets/img/referral-link/social-media/x-twitter.svg"
					alt="" />
			</span>
		</div>
	</div>
</div>
