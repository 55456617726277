import { NgModule } from '@angular/core';
import { DesignerProfileComponent } from './designer-profile.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TeamService } from '../../../services/team/team.service';
import { PopoverModule, RatingModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { NewLinePipeModule } from '../../pipes/new-line/new-line.pipe.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../../shared.module';
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		RatingModule,
		FormsModule,
		NewLinePipeModule,
		TooltipModule,
		PopoverModule,
		SharedModule
	],
	exports: [DesignerProfileComponent],
	declarations: [DesignerProfileComponent],
	providers: [TeamService],
})
export class DesignerProfileModule {}
