<div class="bg-white shadow-lg rounded border">
	<div class="modal-header border-bottom-0 pt-4 pb-2">
		<h3 class="font-weight-light mb-0 text-underline">Start New Project</h3>
		<div class="d-flex justify-content-center align-items-end">
			<div [popover]="'This overlay will be minimized to the bottom right corner and all progress will be saved'"
				 triggers="mouseenter:mouseleave"
				 [style.height.px]="18" type="button" class="clickable d-flex align-items-end px-2"
				 aria-label="Minimise" (click)="closeModal(CreateProjectOverlayCloseAction.MINIMISE)">
				<img alt="minimise" src="assets/img/minimise.svg">
			</div>
			<div [popover]="'This overlay will close and all of the entered information will be lost. To save your progress, minimize this overlay instead.'"
				triggers="mouseenter:mouseleave"
				 type="button" class="clickable px-2 d-flex align-items-end" aria-label="Close"
				 (click)="closeModal(CreateProjectOverlayCloseAction.CLOSE)">
				<img width="16" height="16" alt="close" src="assets/img/close-grey.svg">
			</div>
		</div>
	</div>
	<div class="modal-body">
		<app-projects-editview [isOverlay]="true" [saveDraft]="saveDraft$.asObservable()" (isEdited)="isEdited = $event" (closeOverlay)="closeModal(CreateProjectOverlayCloseAction.CLOSE)"></app-projects-editview>
	</div>
</div>
<ng-template #saveDraftModal>
	<div class="modal-header py-4">
		<h3 class="mb-0 font-weight-light">Save as draft</h3>
		<button
			(click)="saveModalRef.hide()"
			aria-label="Close"
			class="close"
			type="button">
			<img alt="close" src="assets/img/close-light-grey.svg">
		</button>
	</div>
	<div class="modal-body">
		<p>Do you want to save your progress as a draft?</p>
		<div class="d-flex justify-content-end align-items-center mb-4">
			<button (click)="closeSaveDraftModal(false)" class="btn text-danger mr-2">Discard</button>
			<button (click)="closeSaveDraftModal(true)" class="btn btn-purple">Save Draft</button>
		</div>
	</div>
</ng-template>