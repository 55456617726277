<div class="wrapper">
	<div class="container container-lg">
		<div class="row">
			<div class="col-lg-5 offset-lg-1 mt-4">
				<!-- START card-->
				<div class="text-center">
					<a href="https://flocksy.com" target="_blank">
						<img
							alt="Logo"
							class="block-center mb-3 width-50"
							src="assets/img/flocksy-logo.png" />
					</a>
				</div>
				<div class="card card-flat">
					<div class="card-body">
						<h3 class="text-center py-2">Checkout</h3>
						<div *ngIf="oError">
							<alert
								*ngIf="oError?.status"
								dismissible="true"
								type="danger">
								{{ oError.error.message }}
							</alert>
						</div>
						<form
							(submit)="submitForm($event, valForm.value)"
							*ngIf="valForm"
							[formGroup]="valForm"
							class="form-validate mb-3"
							name="registerForm"
							novalidate=""
							role="form">
							<div class="form-group">
								<h4>1. Create your account</h4>
							</div>
							<div class="form-group">
								<label class="text-muted mb-1">Name</label>
								<div class="input-group with-focus">
									<input
										autocomplete="off"
										class="form-control border-right-0"
										formControlName="name"
										name="name"
										placeholder="Name"
										required=""
										type="text" />
									<div class="input-group-append">
										<span
											class="input-group-text text-muted bg-transparent border-left-0">
											<em class="fa fa-user"></em>
										</span>
									</div>
								</div>
								<div
									*ngIf="
										valForm.controls['name'].hasError(
											'required'
										) &&
										(valForm.controls['name'].dirty ||
											valForm.controls['name'].touched)
									"
									class="text-danger">
									Required
								</div>
							</div>
							<div class="form-group">
								<label class="text-muted mb-1">Email</label>
								<div class="input-group with-focus">
									<input
										autocomplete="off"
										class="form-control border-right-0"
										formControlName="email"
										name="email"
										placeholder="Email"
										required=""
										type="email" />
									<div class="input-group-append">
										<span
											class="input-group-text text-muted bg-transparent border-left-0">
											<em class="fa fa-envelope"></em>
										</span>
									</div>
								</div>
								<div
									*ngIf="
										valForm.controls['email'].hasError(
											'required'
										) &&
										(valForm.controls['email'].dirty ||
											valForm.controls['email'].touched)
									"
									class="text-danger">
									Required
								</div>
								<div
									*ngIf="
										valForm.controls['email'].hasError(
											'email'
										) &&
										(valForm.controls['email'].dirty ||
											valForm.controls['email'].touched)
									"
									class="text-danger">
									This field must be a valid email address
								</div>
							</div>
							<div class="form-group">
								<label class="text-muted mb-1">Phone</label>
								<div class="input-group with-focus">
									<input
										autocomplete="off"
										class="form-control border-right-0"
										formControlName="phone"
										name="phone"
										placeholder="Phone"
										type="number" />
									<div class="input-group-append">
										<span
											class="input-group-text text-muted bg-transparent border-left-0">
											<em class="fa fa-phone"></em>
										</span>
									</div>
								</div>
							</div>
							<div class="form-group">
								<label class="text-muted mb-1">Country</label>
								<ngx-select
									[items]="aCountries"
									formControlName="country"
									optionTextField="text"
									optionValueField="id"
									placeholder="Select Country"></ngx-select>
								<div
									*ngIf="
										valForm.controls['country'].hasError(
											'required'
										) &&
										(valForm.controls['country'].dirty ||
											valForm.controls['country'].touched)
									"
									class="text-danger">
									Required
								</div>
							</div>
							<div
								*ngIf="
									valForm.value.country &&
									valForm.value.country == '231'
								"
								class="form-group">
								<label class="text-muted mb-1">State</label>
								<ngx-select
									[items]="aStates"
									formControlName="state"
									optionTextField="text"
									optionValueField="id"
									placeholder="Select State"></ngx-select>
								<div
									*ngIf="
										valForm.controls['state'].hasError(
											'required'
										) &&
										(valForm.controls['state'].dirty ||
											valForm.controls['state'].touched)
									"
									class="text-danger">
									Required
								</div>
							</div>
							<div formGroupName="passwordGroup">
								<div class="form-group">
									<label class="text-muted mb-1">
										Password
									</label>
									<div class="input-group with-focus">
										<input
											[formControl]="
												valForm.get(
													'passwordGroup.password'
												)
											"
											class="form-control border-right-0"
											formControlName="password"
											name="password"
											placeholder="Password"
											type="password" />
										<div class="input-group-append">
											<span
												class="input-group-text text-muted bg-transparent border-left-0">
												<em class="fa fa-lock"></em>
											</span>
										</div>
									</div>
									<div
										*ngIf="
											valForm
												.get('passwordGroup.password')
												.hasError('required') &&
											(valForm.get(
												'passwordGroup.password'
											).dirty ||
												valForm.get(
													'passwordGroup.password'
												).touched)
										"
										class="text-danger">
										Required
									</div>
									<div
										*ngIf="
											valForm
												.get('passwordGroup.password')
												.hasError('minlength') &&
											(valForm.get(
												'passwordGroup.password'
											).dirty ||
												valForm.get(
													'passwordGroup.password'
												).touched)
										"
										class="text-danger">
										Please enter min 8 characters.
									</div>
									<div
										*ngIf="
											valForm
												.get('passwordGroup.password')
												.hasError('maxlength') &&
											(valForm.get(
												'passwordGroup.password'
											).dirty ||
												valForm.get(
													'passwordGroup.password'
												).touched)
										"
										class="text-danger">
										Password can't be greater than 16
										characters.
									</div>
								</div>
								<div class="form-group">
									<label class="text-muted mb-1">
										Password confirmation
									</label>
									<div class="input-group with-focus">
										<input
											[formControl]="
												valForm.get(
													'passwordGroup.confirm_password'
												)
											"
											class="form-control border-right-0"
											formControlName="confirm_password"
											name="confirm_password"
											placeholder="Password confirmation"
											type="password" />
										<div class="input-group-append">
											<span
												class="input-group-text text-muted bg-transparent border-left-0">
												<em class="fa fa-lock"></em>
											</span>
										</div>
									</div>
									<div
										*ngIf="
											valForm
												.get(
													'passwordGroup.confirm_password'
												)
												.hasError('required') &&
											(valForm.get(
												'passwordGroup.confirm_password'
											).dirty ||
												valForm.get(
													'passwordGroup.confirm_password'
												).touched)
										"
										class="text-danger">
										Required
									</div>
									<div
										*ngIf="
											valForm
												.get(
													'passwordGroup.confirm_password'
												)
												.hasError('equalTo')
										"
										class="text-danger">
										Password does Not match
									</div>
								</div>
							</div>
							<div class="form-group">
								<h4>2. Billing Information</h4>
							</div>
							<div class="form-group plan-checkout-container">
								<label>Select Plan</label>
								<accordion
									[closeOthers]="true"
									class="card-group">
									<accordion-group
										(isOpenChange)="
											onChangeAccordion($event, oPlan)
										"
										*ngFor="let oPlan of aPlans"
										[isOpen]="oAccordion == oPlan.plan_id">
										<div
											accordion-heading
											class="clickable">
											<label class="c-radio">
												<input
													[value]="oPlan.plan_id"
													formControlName="plan_id"
													type="radio" />
												<span
													class="fa fa-circle"></span>
											</label>
											<span
												*ngIf="
													!isMonthFree &&
													oPlan.billing_period == 1
												">
												<span class="mr-2">
													{{ oPlan.plan_title }}
												</span>
												<sup>$</sup>
												<span class="text-md">
													{{ oPlan.plan_cost }}
												</span>
												<span>/mo</span>
											</span>
											<span
												*ngIf="
													isMonthFree &&
													oPlan.billing_period == 1 &&
													oPlan.coupon
												">
												<span class="mr-2">
													{{ oPlan.plan_title }}
												</span>
												<sup>$</sup>
												<span class="text-md">
													{{
														oPlan.plan_cost *
															oPlan.coupon
																.quantity
													}}
												</span>
												for
												{{ oPlan.coupon.quantity + 1 }}
												Months
											</span>
											<span
												*ngIf="
													oPlan.billing_period != 1
												">
												<span class="mr-2">
													{{ oPlan.plan_title }}
												</span>
												<sup>$</sup>
												<span class="text-md">
													{{ oPlan.plan_cost }}
												</span>
												for
												{{ oPlan.billing_period }}
												months
											</span>
										</div>
										<div class="card-body">
											<p
												*ngIf="
													oPlan.limited_unlimited_projects
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												Unlimited Projects
											</p>
											<p
												*ngIf="
													oPlan.limited_unlimited_revisions
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												Unlimited Revisions
											</p>
											<p
												*ngIf="
													oPlan.limited_unlimited_projects ==
													0
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												Limited Projects({{
													oPlan.limited_projects_count
												}})
											</p>
											<p
												*ngIf="
													oPlan.limited_unlimited_revisions ==
													0
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												Limited Revisions({{
													oPlan.limited_revisions_count
												}})
											</p>
											<p
												tooltip="We are one of the few services that can tell you exactly when you can expect your project to be completed. The bulk of our design and copy projects have results within 24 hours during the work week.">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												24-Hour Turnaround
												<em
													class="fa fa-info-circle"></em>
											</p>
											<p>
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												Same Day Response
											</p>
											<p
												*ngIf="
													oPlan.limited_unlimited_stock_photography
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												Stock Photos Included
											</p>
											<p
												*ngIf="
													oPlan.limited_unlimited_stock_photography ==
													0
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												Limited Stock Photography({{
													oPlan.limited_stock_photography_count
												}})
											</p>
											<p>
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												USA Based Project Manager
											</p>
											<p
												*ngIf="
													oPlan.plan_id == 50 ||
													oPlan.plan_id == 43
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												Animated Gifs
											</p>
											<p *ngIf="oPlan.plan_id == 43">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												Agency Support
											</p>
											<p
												*ngIf="
													oPlan.money_back_guarantee !=
													0
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												{{
													oPlan.money_back_guarantee
												}}-Day Money Back Guarantee
											</p>
											<p
												*ngIf="
													oPlan.no_contract_cancel_anytime
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												No contract. Cancel anytime
											</p>
											<p
												*ngIf="oPlan.access_subclients"
												[tooltip]="subUserTemplate">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												{{ oPlan.access_subclients }}
												Users
												<em
													class="fa fa-info-circle"></em>
												<ng-template #subUserTemplate>
													<div>
														Collaborate quickly and
														efficiently by adding up
														to
														{{
															oPlan.access_subclients
														}}
														additional users or
														clients to your account.
														Give them permissions
														that allows them to
														access projects, files
														and more.
													</div>
												</ng-template>
											</p>
											<p [tooltip]="projectCountTemplate">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												{{
													oPlan.plan_active_project_cap
												}}
												Active
												{{
													oPlan.plan_active_project_cap >
													1
														? 'Projects'
														: 'Project'
												}}
												at a Time
												<em
													class="fa fa-info-circle"></em>
												<ng-template
													#projectCountTemplate>
													<div>
														Add as many projects as
														you want to your queue
														and we will work through
														them
														{{
															oPlan.plan_active_project_cap
														}}
														at a time.
													</div>
												</ng-template>
											</p>
											<p
												tooltip="Use our brand buckets to upload your or your clients branding material and marketing assets, and then easily attach it to any project you wish with one click.">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												<span *ngIf="!oPlan.buckets">
													Unlimited
												</span>
												<span *ngIf="oPlan.buckets">
													{{ oPlan.buckets }}
												</span>
												Brand "Buckets"
												<em
													class="fa fa-info-circle"></em>
											</p>
											<p
												*ngFor="
													let oType of oPlan.project_types
												">
												<em
													class="fa fa-check fa-fw text-green mr-2"></em>
												{{ oType.title }} Team
											</p>
											<!--<p>
                                                <em class="fa fa-check fa-fw text-green mr-2"></em>
                                                Projects completed in under {{oPlan.turnaround_day * 24}} hours
                                            </p>
                                            <p>
                                                <em class="fa fa-check fa-fw text-green mr-2"></em>
                                                Revisions completed in under {{oPlan.revisions_day * 24}} hours
                                            </p>
                                            <p *ngIf="oPlan.custom_email_templates">
                                                <em class="fa fa-check fa-fw text-green mr-2"></em>
                                                Custom Email Templates
                                            </p>
                                            <p *ngIf="oPlan.unlimited_custom_templates">
                                                <em class="fa fa-check fa-fw text-green mr-2"></em>
                                                Unlimited Custom Templates
                                            </p>-->
										</div>
									</accordion-group>
								</accordion>
							</div>

							<div class="form-group">
								<label>Credit or Debit Card</label>
								<div id="stripe-card-element"></div>
								<div
									*ngIf="stripeError"
									class="alert alert-danger mt-2">
									{{ stripeError.message }}
								</div>
							</div>

							<div
								*ngIf="valForm.value.plan_id"
								class="form-group">
								<h4>3. Total</h4>
							</div>
							<div
								*ngIf="valForm.value.plan_id"
								class="form-group">
								<div class="row">
									<div class="col-md-12">
										<div class="table-responsive">
											<table class="table table-bordered">
												<tr *ngIf="oSelectedPlan">
													<td *ngIf="isMonthFree">
														{{
															oSelectedPlan.plan_title
														}}
														for
														{{
															oSelectedPlan.coupon
																.quantity + 1
														}}
														Months
													</td>
													<td
														*ngIf="
															!isMonthFree &&
															oSelectedPlan.billing_period ==
																1
														">
														{{
															oSelectedPlan.plan_title
														}}
													</td>
													<td
														*ngIf="
															!isMonthFree &&
															oSelectedPlan.billing_period !=
																1
														">
														{{
															oSelectedPlan.plan_title
														}}
														for
														{{
															oSelectedPlan.billing_period
														}}
														Months
													</td>
													<td
														class="text-right"
														*ngIf="!isMonthFree">
														${{
															oSelectedPlan.plan_cost
																| number
																	: '1.2-2'
														}}
													</td>
													<td
														class="text-right"
														*ngIf="isMonthFree">
														${{
															oSelectedPlan.plan_cost *
																oSelectedPlan
																	.coupon
																	.quantity
																| number
																	: '1.2-2'
														}}
													</td>
												</tr>
												<tr
													*ngIf="
														oCoupon &&
														oCoupon['applied'] ==
															true &&
														oSelectedPlan &&
														oSelectedPlan['type'] &&
														!(
															oSelectedPlan[
																'type'
															] == 2 &&
															oSelectedPlan[
																'discount'
															] == 100
														)
													">
													<td>
														Coupon Applied:
														<span
															*ngIf="
																oSelectedPlan[
																	'type'
																] == 1
															">
															${{
																oSelectedPlan[
																	'discount'
																]
															}}
														</span>
														<span
															*ngIf="
																oSelectedPlan[
																	'type'
																] == 2
															">
															{{
																oSelectedPlan[
																	'discount'
																]
															}}%
														</span>
													</td>
													<td class="text-right">
														${{
															getCouponAmount()
																| number
																	: '1.2-2'
														}}
													</td>
												</tr>
												<tr
													*ngIf="
														oCoupon &&
														oCoupon['applied'] ==
															true &&
														oCoupon['data'] &&
														oCoupon['data'][
															'type'
														] &&
														valForm.value.state &&
														valForm.value.state ==
															'3927'
													">
													<td class="text-bold">
														SubTotal
													</td>
													<td class="text-right">
														${{
															getPlanCost() -
																getCouponAmount()
																| number
																	: '1.2-2'
														}}
													</td>
												</tr>
												<tr
													*ngIf="
														valForm.value.state &&
														valForm.value.state ==
															'3927'
													">
													<td>
														Connecticut Sales Tax:
														6.5%
													</td>
													<td class="text-right">
														${{
															getStateTaxAmount()
																| number
																	: '1.2-2'
														}}
													</td>
												</tr>
												<tr>
													<td class="text-bold">
														Total
													</td>
													<td class="text-right">
														${{
															getPayableAmount()
																| number
																	: '1.2-2'
														}}
													</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>

							<div *ngFor="let data of oCoupon.data">
								<div
									*ngIf="
										data.plan_id == oSelectedPlan.plan_id
									">
									<div
										*ngIf="
											oCoupon['applied'] == true &&
											data.type == 2 &&
											data.coupon
										">
										<div
											*ngIf="
												!isMonthFree &&
												data.coupon.duration ==
													'repeating'
											"
											class="alert alert-success mt-2">
											You get
											{{ data.coupon.percent_off }}%
											discount for
											{{ data.coupon.duration_in_months }}
											Months
										</div>
										<div
											*ngIf="
												isBillingPeriodNotMonthly &&
												data.coupon.duration ==
													'once' &&
												oSelectedPlan.billing_period ==
													3
											"
											class="alert alert-success mt-2">
											{{ data.coupon.percent_off }}% off
											when you pay upfront for six months
										</div>
										<div
											*ngIf="
												isBillingPeriodNotMonthly &&
												data.coupon.duration ==
													'once' &&
												oSelectedPlan.billing_period ==
													6
											"
											class="alert alert-success mt-2">
											{{ data.coupon.percent_off }}% off
											when you pay upfront for six months
										</div>
										<div
											*ngIf="
												isBillingPeriodNotMonthly &&
												data.coupon.duration ==
													'forever' &&
												oSelectedPlan.billing_period ==
													12
											"
											class="alert alert-success mt-2">
											{{ data.coupon.percent_off }}% off
											when you pay upfront for twelve
											months
										</div>
									</div>
								</div>
							</div>
							<div
								*ngIf="
									isMonthFree &&
									coupon_code == PLAN_COUPONS.Buy1Get1
								"
								class="alert alert-success mt-2">
								Buy one month and your second month is free
							</div>
							<div
								*ngIf="
									isMonthFree &&
									coupon_code == PLAN_COUPONS.Buy2Get1
								"
								class="alert alert-success mt-2">
								Buy two months and your third month is free
							</div>

							<div
								*ngIf="
									valForm.value.plan_id &&
									oSelectedPlan &&
									!isMonthFree &&
									oSelectedPlan.billing_period == 1
								"
								class="form-group">
								<small>
									Renewed monthly at ${{
										oSelectedPlan.plan_cost +
											getStateTaxAmount()
											| number: '1.2-2'
									}}. Cancel anytime.
								</small>
							</div>
							<div
								*ngIf="
									valForm.value.plan_id &&
									oSelectedPlan &&
									oSelectedPlan.billing_period != 1
								"
								class="form-group">
								<small>
									Renew Every
									{{ oSelectedPlan.billing_period }} Month at
									${{
										getPlanCost() + getStateTaxAmount()
											| number: '1.2-2'
									}}. Cancel Anytime.
								</small>
							</div>
							<div
								*ngIf="
									valForm.value.plan_id &&
									oSelectedPlan &&
									isMonthFree
								"
								class="form-group">
								<small>
									Renewed monthly for
									{{
										oSelectedPlan.plan_cost +
											getStateTaxAmount() | currency
									}}
									on {{ renewal_date }}. Cancel anytime.
								</small>
							</div>

							<div class="clearfix">
								<div class="checkbox c-checkbox float-left mt0">
									<label>
										<input
											formControlName="agree_terms"
											name="agree_terms"
											required=""
											type="checkbox" />
										<span class="fa fa-check"></span>
										I accept the
										<a
											href="https://flocksy.com/terms-of-service"
											target="_blank">
											Terms and Conditions
										</a>
									</label>
								</div>
							</div>
							<span
								*ngIf="
									valForm.controls['agree_terms'].hasError(
										'required'
									) &&
									(valForm.controls['agree_terms'].dirty ||
										valForm.controls['agree_terms'].touched)
								"
								class="text-danger">
								Please accept the terms and conditions
							</span>
							<button
								[disabled]="!valForm.valid || bDisableButton"
								class="btn btn-block btn-primary mt-3"
								type="submit">
								Finish and Pay
							</button>

							<div *ngIf="serverMessage" class="mt-3">
								<div *ngIf="serverStatusCode == 200">
									<div class="alert alert-success">
										{{ serverMessage }}
									</div>
								</div>

								<div *ngIf="serverStatusCode != 200">
									<div
										*ngIf="serverStatusCode == 409"
										class="alert alert-danger">
										{{ serverMessage }} Please log into it
										<a [routerLink]="['/login']">here</a>
									</div>
									<div
										*ngIf="serverStatusCode != 409"
										class="alert alert-danger">
										{{ serverMessage }}
									</div>
								</div>
							</div>
						</form>
						<!-- <div class="alert alert-danger text-center"></div> -->
						<p class="pt-3 text-center">Have an account?</p>
						<a
							[routerLink]="'/login'"
							class="btn btn-block btn-secondary">
							Login
						</a>
					</div>
				</div>
				<!-- END card-->
				<div class="p-3 text-center">
					<span>&copy;</span>
					<span>{{ _settingsService.getAppSetting('year') }}</span>
					<span class="mx-2">-</span>
					<span>{{ _settingsService.getAppSetting('name') }}</span>
					<br />
					<span>
						{{ _settingsService.getAppSetting('description') }}
					</span>
				</div>
			</div>
			<div class="col-lg-5 mt-4">
				<div class="card card-flat">
					<div class="card-header text-center">
						<img
							alt="img"
							class="img-fluid"
							src="assets/img/stamp_14_day.jpg"
							style="max-width: 150px" />
					</div>
					<div class="card-body">
						<h3 class="text-center">Our Customers Love Us!</h3>
						<div class="col-lg-12">
							<div class="card card-default">
								<div class="card-body text-center">
									<img
										alt="Contact"
										class="mb-2 img-fluid rounded-circle thumb64"
										src="https://flocksy.com/wp-content/uploads/2019/01/2.png" />
									<h4 class="mb-0">Florian Laplantif</h4>
									<h5>Mercantilo.com</h5>
									<p>
										Flocksy is that missing component for my
										business that I wish I'd always had.
										Dealing with freelancers on sites like
										fiverrr or upwork is time consuming and
										very hit or miss. With Flocksy, I get
										one point of contact for all my work and
										they are quick to respond! Their work is
										high quality and on-point. Overall it is
										much cheaper and much better to use
										Flocksy than anything else I've tried so
										far. Love the service and will keep
										testing the limits of it.
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="card card-default mb-0">
								<div class="card-body text-center">
									<img
										alt="Contact"
										class="mb-2 img-fluid rounded-circle thumb64"
										src="https://flocksy.com/wp-content/uploads/2018/11/3.png" />
									<h4 class="mb-0">David Cristello</h4>
									<h5>Jetpackworkflow.com</h5>
									<p>
										Responsive team, great service.
										<br />
										The quality of design for the price is
										also impressive
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
