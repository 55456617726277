<form class="email-form" [formGroup]="emailForm" (ngSubmit)="submitForm()">
	<div class="mb-4">
		<div class="mb-1">
			Who do you want to tell about Flocksy?
			<span class="text-muted">
				(enter up to 30 email addresses separated by commas)
			</span>
			<span class="text-asterisk">*</span>
		</div>
		<div
			id="tagsContainer"
			[ngClass]="{
				'emails-invalid':
					(!this.emailForm.get('emails').value.length &&
						isEmailsTouched) ||
					(this.emailForm.get('email').enabled &&
						!this.emailForm.get('email').valid),
				'emails-wrapper': this.emailsFocused
			}"
			class="emails-wrapper container-fluid d-flex flex-row border rounded pt-0 pb-1 pl-2 pr-2 align-items-center flex-wrap">
			<div
				*ngFor="
					let email of emailForm.get('emails').value;
					let index = index
				"
				class="tag mt-1">
				{{ email }}
				<span class="remove" (click)="removeTag(index)">
					<img
						src="assets/img/referral-link/x-circle-fill.svg"
						alt="x-circle-fill" />
				</span>
			</div>
			<div
				*ngIf="emailForm.get('emails').value.length < 30"
				class="flex-grow-1 mt-1">
				<input
					(blur)="onInputBlur()"
					(focus)="onInputFocus()"
					(keydown)="onKeydown($event)"
					type="email"
					id="emailInput"
					autocomplete="off"
					[spellcheck]="false"
					formControlName="email" />
			</div>
		</div>
	</div>
	<div class="mb-4">
		<div class="mb-1">Subject</div>
		<div class="input-group with-focus mr-3 input-text-color">
			<input
				(change)="onSubjectInputChange()"
				autocomplete="off"
				class="form-control font-12"
				formControlName="subject"
				type="text" />
		</div>
	</div>
	<div class="mb-4">
		<div class="mb-1">Include a message</div>
		<div class="input-group with-focus mr-3 input-text-color">
			<textarea
				(change)="onMessageTextareaChange()"
				textareaAutoHeight
				style="overflow: hidden"
				autocomplete="off"
				class="form-control font-12"
				rows="1"
				formControlName="message"
				type="email"></textarea>
		</div>
	</div>
	<div class="emal-send-buttons-container d-flex justify-content-between">
		<div class="">
			<button
				type="button"
				id="loom-record-btn"
				[disabled]="isRecording"
				[ngClass]="{
					'invalid-button': isRecording
				}"
				(click)="_analyticsService.setOverlayTrackData(16, 120);"
				class="btn btn-primary purple-btn">
				Record Video Message
			</button>
			<div *ngIf="!videoUrl" class="position-absolute mt-2 text-muted">
				Record a video message to send along
			</div>
			<div *ngIf="videoUrl" class="position-absolute mt-2 text-muted">
				<p>
					Video recorded!
					<a [href]="videoUrl" target="_blank">Watch the video</a>
				</p>
			</div>
		</div>
		<div>
			<button
				type="submit"
				[disabled]="
					isRecording ||
					!emailForm.valid ||
					emailForm.get('email').value ||
					!emailForm.get('emails').value.length
				"
				[ngClass]="{
					'invalid-button':
						isRecording ||
						!emailForm.valid ||
						!emailForm.get('emails').value.length ||
						emailForm.get('email').value
				}"
				class="btn btn-primary">
				Send Message
			</button>
		</div>
	</div>
</form>
<div *ngIf="embedUrl">
	<div class="d-inline-block">
		<iframe
			width="120"
			height="100"
			frameborder="0"
			allowfullscreen=""
			[src]="embedUrl"></iframe>
	</div>
	<span (click)="removeVideo()" class="ml-2 trash-icon-pos clickable">
		<i
			class="far fa-trash-alt fa-lg"
			style="color: red"
			aria-hidden="true"></i>
	</span>
</div>
