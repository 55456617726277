import {
	Component,
	Input,
	OnInit,
	OnDestroy,
	TemplateRef,
	Output,
	EventEmitter,
	ElementRef,
	ViewChild,
	AfterViewChecked,
} from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotesService } from '../../../../../../services/client/creative/notes/notes.service';
import { LocalStorageService } from '../../../../../../services/localstorage.service';
import { ReplaySubject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy {
	@Input() designer;
	clientId = this._localStorageService.getUserId();
	modalNotesRef: BsModalRef;
	notes = [];
	creativeNotesForm: FormGroup;

	creativeNotesPage = 1;
	creativeNotes = [];
	creativeNotesPagination = {
		totalRecords: 0,
		currentPage: 1,
		itemsPerPage: 0,
	};

	isEditNote: boolean = false;
	selectedNote = {};
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	deleteNotesRef: BsModalRef;
	@Output() emitCloseModal = new EventEmitter();
	@ViewChild('scrollBottom') private scrollBottom: ElementRef;
	showNotes: boolean = false;
	tooltipText =
		'These notes are only seen by you and wont be visible to other users in the account';
	isSkeletonLoaded = true;

	constructor(
		public _formBuilder: FormBuilder,
		public _bsModalService: BsModalService,
		private _noteService: NotesService,
		public _localStorageService: LocalStorageService,
		private _toasterService: ToasterService
	) {}

	ngOnInit(): void {
		this.initCreativetNotesForm();
		this.retrieveCreativeNotes();
	}

	retrieveCreativeNotes() {
		return this._noteService
			.index(this.clientId, this.designer.id, this.creativeNotesPage, {})
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe((data) => {
				this.creativeNotes = data.data.data;
				this.creativeNotesPagination['totalRecords'] = data.data.total;
				this.creativeNotesPagination['itemsPerPage'] =
					data.data.per_page;
				this.showNotes = true;
				this.isSkeletonLoaded = false;
			});
	}

	onCreativeNotesPageChanged(event: any): void {
		this.creativeNotesPage = event.page;
		this.retrieveCreativeNotes();
	}

	submit($ev) {
		$ev.preventDefault();
		for (let c in this.creativeNotesForm.controls) {
			this.creativeNotesForm.controls[c].markAsTouched();
		}

		if (!this.creativeNotesForm.valid) {
			return false;
		}

		if (!this.isEditNote) {
			this.storeCreativeNotes();
		} else {
			this.updateCreativeNote();
		}
	}

	initCreativetNotesForm() {
		this.creativeNotesForm = this._formBuilder.group({
			note: [null, Validators.required],
		});
	}

	editCreativeNote(note) {
		this.isEditNote = true;
		console.log(note);
		this.selectedNote = note;
		this.creativeNotesForm.patchValue(note);
	}

	clearEditNote() {
		this.isEditNote = false;
		this.selectedNote = {};
		this.creativeNotesForm.reset();
	}

	storeCreativeNotes() {
		this._noteService
			.store(
				this.clientId,
				this.designer.id,
				this.creativeNotesForm.value
			)
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe((data) => {
				this._toasterService.pop('success', data.message);
				this.creativeNotesForm.reset();
				this.retrieveCreativeNotes();
			});
	}

	updateCreativeNote() {
		this._noteService
			.update(this.selectedNote['id'], this.creativeNotesForm.value)
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe(
				(data) => {
					console.log(data);
					this.isEditNote = false;
					this.creativeNotesForm.reset();
					this._toasterService.pop('success', data.message);
					this.retrieveCreativeNotes();
				},
				(err) => {
					this.isEditNote = false;
				}
			);
	}

	initDeleteNote(note, template: TemplateRef<any>) {
		this.selectedNote = note;
		this.deleteNotesRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-lg modal-full' }
			)
		);
	}

	deleteCreativeNote(noteId) {
		this._noteService
			.destroy(noteId)
			.pipe(
				takeUntil(this.destroyed$),
				catchError((err) => {
					return throwError(err);
				})
			)
			.subscribe(
				(data) => {
					this.deleteNotesRef.hide();
					this.retrieveCreativeNotes();
					this._toasterService.pop('success', data.message);
				},
				(err) => {}
			);
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
