import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomHttpService } from '../../../../app/services/custom.http.service';
import { API_CONSTANTS } from '../../../../app/common/constant/ApiConstants';

@Injectable()
export class ReferralService {
	shareMethod$ = new BehaviorSubject<string>('social-media');
	payPalEmail$ = new BehaviorSubject<string>(null);
	isEmailSent$ = new BehaviorSubject<boolean>(false);
	referralLink$ = new BehaviorSubject<string>(null);

	constructor(private _customHttpService: CustomHttpService) {}

	setMethod(method: string) {
		this.shareMethod$.next(method);
	}

	setPayPalEmail(email: string) {
		this.payPalEmail$.next(email);
	}
	setIsEmailSent(isSent: boolean) {
		this.isEmailSent$.next(isSent);
	}
	setReferralLink(referralLink: string) {
		this.referralLink$.next(referralLink);
	}

	sendEmails(email: Email, user_id: number): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL +
				`/users/${user_id}/referrals/email`,
			email
		);
	}
	getTrackingStats(user_id: number) {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + `/users/${user_id}/referrals/stats`
		);
	}
}

interface Email {
	to_addresses: [];
	subject: string;
	body: string;
	video_link: string;
}
