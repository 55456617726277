import { NgxSelectModule } from 'ngx-select-ex';
import { AddNewCardComponent } from './cards/create/create.component';
import { CardsListComponent } from './cards/cards/cards.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { PaymentsComponent } from './payments.component';

@NgModule({
	imports: [SharedModule, NgxSelectModule],
	declarations: [PaymentsComponent, CardsListComponent, AddNewCardComponent],
	exports: [PaymentsComponent],
	providers: [],
})
export class PaymentsModule {}
