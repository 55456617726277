<ngx-skeleton-loader
	[class]="class"
	[count]="count"
	*ngIf="!appearance"
	[theme]="theme"></ngx-skeleton-loader>
<ngx-skeleton-loader
	[class]="class"
	[count]="count"
	*ngIf="appearance"
	[theme]="theme"
	[appearance]="'circle'"></ngx-skeleton-loader>
