import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ApiService {
	constructor(private _customHttpService: CustomHttpService) {}

	getAuthSeamless(): Observable<any> {
		return this._customHttpService.get(API_CONSTANTS.AUTH_SEAMLESS);
	}

	getAuthUser(oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.AUTH_USER +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	logout(): Observable<any> {
		return this._customHttpService.post(API_CONSTANTS.LOGOUT, '');
	}

	isValidCoupon(coupon_code, plan_id, page?): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.COUPON_URL +
				'/' +
				coupon_code +
				'?plan_id=' +
				plan_id +
				'&page=' +
				page
		);
	}

	existsReferralCode(code): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/referral-code/' + code
		);
	}

	getClientUsers(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/clients'
		);
	}

	getDesignerUsers(status?): Observable<any> {
		if (status) {
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL + '/designers?status=' + status
			);
		}
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/designers'
		);
	}

	getCountryList(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/get/countries'
		);
	}

	getStateList(country_id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/states?country_id=' +
				country_id
		);
	}

	getPlanSeamless(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PLANS_URL + '/seamless'
		);
	}

	storeTracker(oData): Observable<any> {
		return this._customHttpService.post(API_CONSTANTS.TRACKERS_URL, oData);
	}

	getGoogleCode(): Observable<any> {
		return this._customHttpService.get(API_CONSTANTS.API_ENDPOINT_URL + '/social-logins/google/redirect');
	}

	getMicrosoftCode(): Observable<any> {
		return this._customHttpService.get(API_CONSTANTS.API_ENDPOINT_URL + '/social-logins/microsoft/redirect');
	}
}
