import { Component, HostBinding, OnInit } from '@angular/core';

import { PageTitleService } from '@services/page.title.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { SettingsService } from '@core/settings/settings.service';
import { ToasterConfig } from 'angular2-toaster';
import { FreshchatService } from '@services/analytics/freshchat.service';
import { TrackingAnalyticsService } from '@services/tracking.analytics.service';
import { LocalStorageService } from '@services/localstorage.service';
import { WhiteLabelService } from '@services/white-label/white-label.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	loaded = false;
	@HostBinding('class.layout-fixed') get isFixed() {
		return this.settings.getLayoutSetting('isFixed');
	}

	@HostBinding('class.aside-collapsed') get isCollapsed() {
		return this.settings.getLayoutSetting('isCollapsed');
	}

	@HostBinding('class.layout-boxed') get isBoxed() {
		return this.settings.getLayoutSetting('isBoxed');
	}

	@HostBinding('class.layout-fs') get useFullLayout() {
		return this.settings.getLayoutSetting('useFullLayout');
	}

	@HostBinding('class.hidden-footer') get hiddenFooter() {
		return this.settings.getLayoutSetting('hiddenFooter');
	}

	@HostBinding('class.layout-h') get horizontal() {
		return this.settings.getLayoutSetting('horizontal');
	}

	@HostBinding('class.aside-float') get isFloat() {
		return this.settings.getLayoutSetting('isFloat');
	}

	@HostBinding('class.offsidebar-open') get offsidebarOpen() {
		return this.settings.getLayoutSetting('offsidebarOpen');
	}

	@HostBinding('class.aside-toggled') get asideToggled() {
		return this.settings.getLayoutSetting('asideToggled');
	}

	@HostBinding('class.aside-collapsed-text') get isCollapsedText() {
		return this.settings.getLayoutSetting('isCollapsedText');
	}

	public _toasterConfig: ToasterConfig = new ToasterConfig({
		positionClass: 'toast-bottom-right',
	});
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();


	constructor(
		public settings: SettingsService,
		public _pageTitleService: PageTitleService,
		private _analyticsService: AnalyticsService,
		private _freshchatService: FreshchatService,
		public _trackingAnalyticsService: TrackingAnalyticsService,
		public _localStorageService: LocalStorageService,
		private _whiteLabelService: WhiteLabelService,
	) {
		this._analyticsService.initAnalytics();
	}

	ngOnInit() {
		setTimeout(()=>{this.loaded = true},1500)
		// for client background color
		const body = document.getElementsByTagName('body');
		if (body && this.isOperationalClientUser) {
			body[0].style.backgroundColor = 'white ';
		}
		this._pageTitleService.init();
		this._trackingAnalyticsService.init();
		document.addEventListener('click', (e) => {
			const target = e.target as HTMLElement;
			/*if (target.tagName === 'A') e.preventDefault();*/
		});
		if(!this._whiteLabelService.isWhiteLabelledUser()) {
			this._freshchatService.init();
		}
	}
}
