import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class PlanService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PLANS_URL +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	store(oData): Observable<any> {
		return this._customHttpService.post(API_CONSTANTS.PLANS_URL, oData);
	}

	update(id, oData): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.PLANS_URL + '/' + id,
			oData
		);
	}

	show(id): Observable<any> {
		return this._customHttpService.get(API_CONSTANTS.PLANS_URL + '/' + id);
	}

	getActivePlans(): Observable<any> {
		return this._customHttpService.get(API_CONSTANTS.PLAN_URL);
	}

	makePayment(oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/make-payment',
			oData
		);
	}

	makeStripePayment(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PLANS_URL + '/' + id + '/payments/stripe',
			oData
		);
	}

	updateBillingCard(oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.API_ENDPOINT_URL + '/billing-card-update',
			oData
		);
	}

	updateCustomerCard(id, oData): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.USERS_URL + '/' + id + '/stripe/customer-cards',
			oData
		);
	}

	retrieveActivePlans(oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		if (oParams) {
			return this._customHttpService.get(
				API_CONSTANTS.PLANS_URL + '/active?' + params.toString()
			);
		}
		return this._customHttpService.get(API_CONSTANTS.PLANS_URL + '/active');
	}

	seamless(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PLANS_URL + '/seamless'
		);
	}

	getStripeProducts(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/stripe/products'
		);
	}

	getStripeListOfPrices(stripe_product_id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/stripe/products/' +
				stripe_product_id
		);
	}

	getProductIdFromStripePrice(stripe_price_id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/stripe/prices/' + stripe_price_id
		);
	}
}
