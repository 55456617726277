import { Injectable } from '@angular/core';
import { LocalStorageService } from '../localstorage.service';
import { environment } from '@env/environment';

declare const window: Window &
	typeof globalThis & {
	fcWidget: any
}

@Injectable()
export class FreshchatService {
	constructor(
		private _localStorageService: LocalStorageService,
		) {}

	init() {
		this.initiateCall();
	}

	initFreshChat() {
		window.fcWidget.init({
			token: environment.freshChatConfig.TOKEN,
			host: environment.freshChatConfig.HOST,
			config: {
				headerProperty: {
					direction: 'ltr', //will move widget to left side of the screen
				},
			},
			firstName: this._localStorageService?.getUserFullName(),
			email: this._localStorageService?.getUserEmail(),
			externalId: this._localStorageService?.getUserId()
		});
	}

	initialize(i, t) {
		var e;
		i.getElementById(t)
			? this.initFreshChat()
			: (((e = i.createElement('script')).id = t),
				(e.async = !0),
				(e.src = 'https://wchat.freshchat.com/js/widget.js'),
				(e.onload = this.initFreshChat),
				i.head.appendChild(e));
	}

	initiateCall() {
		this.initialize(document, 'freshchat-js-sdk');
	}
}
