import { Injectable, OnInit } from '@angular/core';
import { CustomHttpService } from './custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '@common/constant/ApiConstants';
import { environment } from '@env/environment';
import { ExternalScriptService } from '@services/common/external-script.service';
import { GenericHttpResponse } from '@services/interface';

declare var grecaptcha: any;

export enum LoginMethod {
	PASSWORD = 'PASSWORD',
	CODE = "CODE"
}

export interface  CheckEmailResponse{
	exists: boolean,
	method?: LoginMethod
}

export interface AuthData {
	grant_type: 'password' | 'verification-code',
	username: string,
	password?: string,
	client_id: string,
	client_secret: string,
	guid?: any,
	referral_source?: any,
}

@Injectable()
export class LoginService implements OnInit{
	constructor(private _customHttpService: CustomHttpService,
				public _externalScriptService: ExternalScriptService,
				) {}

	ngOnInit() {
	}

	reloadGoogleRecaptchaScript() {
		return new Promise((resolve, reject) => {
			this._externalScriptService
				.loadScript(
					'https://www.google.com/recaptcha/api.js?render=' +
					environment.reCaptchaSiteKey,
					'flocksy_recaptcha'
				)
				.then((data) => {resolve()})
				.catch((error) => console.log(error));
		})
	}

	oAuthToken(oData: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.OAUTH_TOKEN_URL,
			oData
		);
	}

	loginGuid(guid: string, oData: string): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.OAUTH_TOKEN_URL + '?guid=' + guid,
			oData
		);
	}

	loginWithPasswordCode(oData: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.OAUTH_PASSWORD_CODE_URL,
			oData
		);
	}

	generateGoogleRecaptchaToken(callback: Function, page:string){
		grecaptcha?.ready(function () {
			grecaptcha
				.execute(environment.reCaptchaSiteKey, {
					action: page,
				})
				.then(function (token) {
					callback(token);
				});
		});
	}

	sendVerificationCode(data: any) {
		return this._customHttpService.post(API_CONSTANTS.OAUTH_USER_VERIFICATION_CODE, data);
	}

	checkEmail(data: any): Observable<any> {
		return this._customHttpService.post<GenericHttpResponse<CheckEmailResponse>>(API_CONSTANTS.OAUTH_USER_CHECK_EMAIL, data);
	}
}
