import { Component, OnInit } from '@angular/core';
import { FlocksyModalFooterData, FlocksyModalHeaderData } from '@shared/module/flocksy-modal/interface';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-legacy-details-overlay',
  templateUrl: './legacy-details-overlay.component.html',
  styleUrls: ['./legacy-details-overlay.component.scss']
})
export class LegacyDetailsOverlayComponent implements OnInit {
  modalHeaderData: FlocksyModalHeaderData = {
    title: "You’re Getting Legacy Pricing"
  };
  modalFooterData: FlocksyModalFooterData = {
    footerActions: [
      {
        btnText: "Close",
        btnClass: "btn btn-purple",
        btnAction: () => {
          this._bsModalRef.hide();
        },
      },
    ],
    wrapperClass: "border-0"
  }

  constructor(
      public _bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
  }

}
