<div class="wrapper">
	<div class="m-auto text-center">
		<!-- START card-->
		<div class="text-center mb-4">
			<div class="text-lg mb-3">404</div>
			<p class="lead m0">We couldn't find this page.</p>
			<p>The page you are looking for does not exists.</p>
		</div>
		<div>
			Please
			<a class="text-muted" href="javascript:window.history.back()">
				return
			</a>
			to the previous page or visit
			<a *ngIf="!isWhiteLabelledUser" class="text-muted" href="/">app.flocksy.com</a>
		</div>
		<div *ngIf="!isWhiteLabelledUser" class="p-3 text-center">
			<span>&copy;</span>
			<span>{{ settings.getAppSetting('year') }}</span>
			<span class="mx-2">-</span>
			<span>{{ settings.getAppSetting('name') }}</span>
			<br />
			<span>{{ settings.getAppSetting('description') }}</span>
		</div>
	</div>
</div>
