import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ProjectBucketService {
	constructor(private _customHttpService: CustomHttpService) {}

	getSharedBuckets(projectId): Observable<any> {
		const params = new URLSearchParams();
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				projectId +
				'/buckets' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	storeSharedBuckets(projectId, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + projectId + '/buckets',
			oData
		);
	}

	unshareSharedBucket(project_id, bucket_ids): Observable<any> {
		const params = new URLSearchParams();
		for (const id of bucket_ids) {
			params.set('shared_bucket_ids[]', id);
		}
		return this._customHttpService.delete(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				project_id +
				'/buckets?' +
				params.toString()
		);
	}

	getFiles(id: number): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/account/data?project_id=' + id
		);
	}
}
