<div class="wrapper">
	<div class="container container-md">
		<div class="row">
			<div class="col-lg-6">
				<div class="mt-4">
					<!-- START card-->
					<div class="text-center">
						<a href="https://flocksy.com" target="_blank">
							<img
								alt="Logo"
								class="block-center mb-3 width-50"
								src="assets/img/flocksy-logo.png" />
						</a>
					</div>
					<div *ngIf="!bSignupSuccess" class="card card-flat">
						<div class="card-body">
							<div *ngIf="oError">
								<alert
									*ngIf="oError?.status"
									dismissible="true"
									type="danger">
									{{ oError.error.message }}
								</alert>
							</div>
							<form
								(submit)="submitForm($event, valForm.value)"
								[formGroup]="valForm"
								class="form-validate mb-3"
								name="registerForm"
								novalidate=""
								role="form">
								<div class="form-group">
									<label class="text-muted mb-1">Name</label>
									<div class="input-group with-focus">
										<input
											autocomplete="off"
											class="form-control border-right-0"
											formControlName="name"
											name="name"
											placeholder="Name"
											required=""
											type="text" />
										<div class="input-group-append">
											<span
												class="input-group-text text-muted bg-transparent border-left-0">
												<em class="fa fa-user"></em>
											</span>
										</div>
									</div>
									<div
										*ngIf="
											valForm.controls['name'].hasError(
												'required'
											) &&
											(valForm.controls['name'].dirty ||
												valForm.controls['name']
													.touched)
										"
										class="text-danger">
										Required
									</div>
									<div
										*ngIf="
											(valForm.controls['name'].hasError(
												'url'
											) ||
												valForm.controls[
													'name'
												].hasError('email')) &&
											(valForm.controls['name'].dirty ||
												valForm.controls['name']
													.touched)
										"
										class="text-danger">
										Please enter valid name
									</div>
								</div>
								<div class="form-group">
									<label class="text-muted mb-1">
										Business Name
									</label>
									<div class="input-group with-focus">
										<input
											autocomplete="off"
											class="form-control border-right-0"
											formControlName="business_name"
											name="business_name"
											placeholder="Business Name"
											type="text" />
										<div class="input-group-append">
											<span
												class="input-group-text text-muted bg-transparent border-left-0">
												<em class="fa fa-building"></em>
											</span>
										</div>
									</div>
								</div>
								<div class="form-group">
									<label class="text-muted mb-1">Email</label>
									<div class="input-group with-focus">
										<input
											autocomplete="off"
											class="form-control border-right-0"
											formControlName="email"
											name="email"
											placeholder="Email"
											required=""
											type="email" />
										<div class="input-group-append">
											<span
												class="input-group-text text-muted bg-transparent border-left-0">
												<em class="fa fa-envelope"></em>
											</span>
										</div>
									</div>
									<div
										*ngIf="
											valForm.controls['email'].hasError(
												'required'
											) &&
											(valForm.controls['email'].dirty ||
												valForm.controls['email']
													.touched)
										"
										class="text-danger">
										Required
									</div>
									<div
										*ngIf="
											valForm.controls['email'].hasError(
												'email'
											) &&
											(valForm.controls['email'].dirty ||
												valForm.controls['email']
													.touched)
										"
										class="text-danger">
										This field must be a valid email address
									</div>
								</div>
								<div class="form-group">
									<label class="text-muted mb-1">Phone</label>
									<div class="input-group with-focus">
										<input
											autocomplete="off"
											class="form-control border-right-0"
											formControlName="phone"
											name="phone"
											placeholder="Phone"
											type="number" />
										<div class="input-group-append">
											<span
												class="input-group-text text-muted bg-transparent border-left-0">
												<em class="fa fa-phone"></em>
											</span>
										</div>
									</div>
								</div>
								<div class="form-group">
									<label class="text-muted mb-1">
										How did you hear of us?
									</label>
									<div class="input-group with-focus">
										<textarea
											class="form-control border-right-0"
											formControlName="lead_source"
											name="lead_source"
											placeholder="How did you hear of us?"></textarea>
										<div class="input-group-append">
											<span
												class="input-group-text text-muted bg-transparent border-left-0">
												<em class="fa fa-user"></em>
											</span>
										</div>
									</div>
								</div>
								<div formGroupName="passwordGroup">
									<div class="form-group">
										<label class="text-muted mb-1">
											Password
										</label>
										<div class="input-group with-focus">
											<input
												[formControl]="
													valForm.get(
														'passwordGroup.password'
													)
												"
												class="form-control border-right-0"
												formControlName="password"
												name="password"
												placeholder="Password"
												type="password" />
											<div class="input-group-append">
												<span
													class="input-group-text text-muted bg-transparent border-left-0">
													<em class="fa fa-lock"></em>
												</span>
											</div>
										</div>
										<div
											*ngIf="
												valForm
													.get(
														'passwordGroup.password'
													)
													.hasError('required') &&
												(valForm.get(
													'passwordGroup.password'
												).dirty ||
													valForm.get(
														'passwordGroup.password'
													).touched)
											"
											class="text-danger">
											Required
										</div>
										<div
											*ngIf="
												valForm
													.get(
														'passwordGroup.password'
													)
													.hasError('minlength') &&
												(valForm.get(
													'passwordGroup.password'
												).dirty ||
													valForm.get(
														'passwordGroup.password'
													).touched)
											"
											class="text-danger">
											Please enter min 8 characters.
										</div>
										<div
											*ngIf="
												valForm
													.get(
														'passwordGroup.password'
													)
													.hasError('maxlength') &&
												(valForm.get(
													'passwordGroup.password'
												).dirty ||
													valForm.get(
														'passwordGroup.password'
													).touched)
											"
											class="text-danger">
											Password can't be greater than 16
											characters.
										</div>
									</div>
									<div class="form-group">
										<label class="text-muted mb-1">
											Password confirmation
										</label>
										<div class="input-group with-focus">
											<input
												[formControl]="
													valForm.get(
														'passwordGroup.confirm_password'
													)
												"
												class="form-control border-right-0"
												formControlName="confirm_password"
												name="confirm_password"
												placeholder="Password confirmation"
												type="password" />
											<div class="input-group-append">
												<span
													class="input-group-text text-muted bg-transparent border-left-0">
													<em class="fa fa-lock"></em>
												</span>
											</div>
										</div>
										<div
											*ngIf="
												valForm
													.get(
														'passwordGroup.confirm_password'
													)
													.hasError('required') &&
												(valForm.get(
													'passwordGroup.confirm_password'
												).dirty ||
													valForm.get(
														'passwordGroup.confirm_password'
													).touched)
											"
											class="text-danger">
											Required
										</div>
										<div
											*ngIf="
												valForm
													.get(
														'passwordGroup.confirm_password'
													)
													.hasError('equalTo')
											"
											class="text-danger">
											Your passwords do not match
										</div>
									</div>
								</div>
								<div class="clearfix">
									<div
										class="checkbox c-checkbox float-left mt0">
										<label>
											<input
												formControlName="agree_terms"
												name="agree_terms"
												required=""
												type="checkbox" />
											<span class="fa fa-check"></span>
											I accept the
											<a
												href="https://flocksy.com/terms-of-service"
												target="_blank">
												Terms and Conditions
											</a>
										</label>
									</div>
								</div>
								<span
									*ngIf="
										valForm.controls[
											'agree_terms'
										].hasError('required') &&
										(valForm.controls['agree_terms']
											.dirty ||
											valForm.controls['agree_terms']
												.touched)
									"
									class="text-danger">
									Please accept terms
								</span>
								<button
									[disabled]="!valForm.valid"
									class="btn btn-block btn-primary mt-3"
									type="submit">
									Create an Account
								</button>
							</form>
							<!-- <div class="alert alert-danger text-center"></div> -->
							<p class="pt-3 text-center">Have an account?</p>
							<a
								[routerLink]="'/login'"
								class="btn btn-block btn-secondary">
								Login
							</a>
						</div>
					</div>
					<div *ngIf="bSignupSuccess" class="card card-flat">
						<div class="card-body text-center text-success">
							<h3 class="mb-3 text-bold">
								Thanks for applying for a demo. Please check
								your email.
							</h3>
						</div>
					</div>
					<!-- END card-->
					<div class="p-3 text-center">
						<span>&copy;</span>
						<span>
							{{ _settingsService.getAppSetting('year') }}
						</span>
						<span class="mx-2">-</span>
						<span>
							{{ _settingsService.getAppSetting('name') }}
						</span>
						<br />
						<span>
							{{ _settingsService.getAppSetting('description') }}
						</span>
					</div>
				</div>
			</div>
			<div class="col-lg-6 my-4">
				<div class="card card-flat">
					<div class="card-header text-center">
						<img
							alt="img"
							class="img-fluid"
							src="assets/img/stamp_14_day.jpg"
							style="max-width: 150px" />
					</div>
					<div class="card-body">
						<h3 class="text-center">Our Customers Love Us!</h3>
						<div class="col-lg-12">
							<div class="card card-default">
								<div class="card-body text-center">
									<img
										alt="Zach Hayden"
										class="mb-2 img-fluid rounded-circle thumb64"
										src="https://flocksy.com/wp-content/uploads/2019/04/1-1.jpeg" />
									<h4 class="mb-0">Zach Hayden</h4>
									<h5>thehaydendigital.com</h5>
									<p>
										Flocksy has been incredible for us! They
										have consistently delivered amazing work
										– sometimes I’m surprised by how much
										they can actually tackle in 24 hours.
									</p>
									<p>
										When there has been rework required, my
										project manager is awesome at getting it
										done super quick. I’ve tried other
										“unlimited graphic” services before, but
										Flocksy, hands down, is the best.
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="card card-default mb-0">
								<div class="card-body text-center">
									<img
										alt="Tyler Maclaren"
										class="mb-2 img-fluid rounded-circle thumb64"
										src="https://flocksy.com/wp-content/uploads/2019/06/Tyler.jpg" />
									<h4 class="mb-0">Tyler Maclaren</h4>
									<h5>Brandis Hire</h5>
									<p>
										“completed quickly and professionally.”
										<br />
										Flocksy has been great helping us with
										our rebranding. Although we are in a
										completely different timezone, we still
										manage to communicate well together and
										our projects get completed quickly and
										professionally. Recommended!
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
