import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	Renderer2,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@services/localstorage.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { SettingsService } from '@core/settings/settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { environment } from '@env/environment';
import { DocumentService } from '@services/document/document.service';
import { ToasterService } from 'angular2-toaster';
import { ReferralService } from '../services/referral.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WhiteLabelledUser } from '@services/interface';
import { WhiteLabelService } from '@services/white-label/white-label.service';

declare var window;

@Component({
	selector: 'app-client-header',
	templateUrl: './client-header.component.html',
	styleUrls: ['./client-header.component.scss'],
})
export class ClientHeaderComponent implements OnInit, OnDestroy {
	@Input() onlyLogo = false;
	modalFreeMonthReferralRef: BsModalRef;
	isFeedbackeryEnabled = FlocksyUtil.isFeedbackeryEnabled;
	isFeedbackeryInitialized = FlocksyUtil.isFeedbackeryInitialized;
	urlShare;
	shareMethod: string;
	isEmailSent: boolean;
	private unsubscribe$ = new Subject<void>();
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();

	isWhiteLabeledUser = this._whiteLabelService.isWhiteLabelledUser();
	whiteLabelledUser:WhiteLabelledUser;
	defaultLogo = 'assets/img/custom-domain/custom_domain_default_logo.png';

	@ViewChild('feedbackButton') set fbDesktop(content: ElementRef) {
		if (content && !this.isFeedbackeryInitialized) {
			const feedbackId = environment.feedbackeryIds.dashboardClient;
			this._renderer2.setAttribute(
				content.nativeElement,
				'data-feedbackery',
				feedbackId
			);
			if (this._router.url != '/dashboard') {
				window.feedbackery.init();
				this.isFeedbackeryInitialized = true;
			}
		}
	}
	constructor(
		public _localStorageService: LocalStorageService,
		public _router: Router,
		public settings: SettingsService,
		public _analyticsService: AnalyticsService,
		public _bsModalService: BsModalService,
		public _renderer2: Renderer2,
		public _documentService: DocumentService,
		public _toasterService: ToasterService,
		public _whiteLabelService: WhiteLabelService,
		private _referralService: ReferralService
	) {
		this.urlShare =
			environment.landingSiteURL +
			'/?r=' +
			this._localStorageService.getUserId();
	}

	ngOnInit(): void {
		if(this.isWhiteLabeledUser) {
			this._whiteLabelService.getWhiteLabelledUser$()
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe((res) => {
					this.whiteLabelledUser = res
				})
		}
		combineLatest([
			this._referralService.shareMethod$,
			this._referralService.isEmailSent$
		])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(([method, isEmailSent]) => {
				this.shareMethod = method;
				this.isEmailSent = isEmailSent;
			});
	}

	redirectUser() {
		if (this._localStorageService.isUserLoggedIn()) {
			this._router.navigate(['/dashboard']);
		} else {
			window.open(environment.externalSiteUrl, '_self');
		}
	}

	initFreeMonthReferralModal(template: TemplateRef<any>) {
		this.modalFreeMonthReferralRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{
					modalClass: 'modal-dialog',
					class: 'modal-flocksy-lg modal-flocksy-md modal-flocksy-sm',
				}
			)
		);
		this._analyticsService.setOverlayTrackData(16, 42);
	}

	copyToClipboard() {
		this._documentService.copyToClipboard(this.urlShare);
		this._toasterService.pop('success', 'Referral URL has been copied');
		this._analyticsService.setOverlayTrackData(16, 117);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
