<ng-template #demoSignupPlanModal>
	<div class="modal-header">
		<h4 class="modal-title">Upgrade Plan</h4>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="modalDemoSignupPlanRef.hide()">
			<em class="fa fa-times" style="font-size: 16px"></em>
		</button>
	</div>
	<div class="modal-body">
		<div
			class="container container-md"
			*ngIf="_localStorageService.isClientUser()">
			<h4>Hi {{ _localStorageService.getUserFullName() }},</h4>
			<p>
				You’re currently on our
				<b>Starter Plan!</b>
				This allows you to queue up pending projects, add users, access
				the template library, create buckets and more!
			</p>
			<p>
				IMPORTANT: Your Projects are
				<b>not</b>
				started on this plan. You need to upgrade to have a team
				assigned to your account.
			</p>
			<p>
				You can upgrade
				<a (click)="goToPlanPage()" href="javascript:">here</a>
				and have the team start working on your projects. The upgrade
				comes with our 14 Day Money Back Guarantee so you risk nothing
				by seeing if we can form a creative team that fits your needs.
			</p>
			<p>
				If you have any questions let us know by emailing
				<a href="mailto:support@flocksy.com">support@flocksy.com</a>
				or chatting with your Project Manager.
			</p>
			<p>
				Cheers,
				<br />
				Team Flocksy
			</p>
		</div>
		<div
			class="container container-md"
			*ngIf="_localStorageService.isSubClientUser()">
			The admin account user is the only one that upgrade this account.
			Please contact
			<u>{{ _localStorageService.getParentName() }}</u>
			to request they upgrade it.
		</div>
	</div>
</ng-template>
