import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class OnboardingDesignService {
	constructor(
		private _customHttpService: CustomHttpService,
		public _router: Router
	) {}

	getForTypes(id?): Observable<any> {
		if (id) {
			return this._customHttpService.get(
				API_CONSTANTS.ONBOARDING_URL + '/designs/project-types?id=' + id
			);
		} else {
			return this._customHttpService.get(
				API_CONSTANTS.ONBOARDING_URL + '/designs/project-types'
			);
		}
	}

	index(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.ONBOARDING_URL + '/designs'
		);
	}

	store(oData: object): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.ONBOARDING_URL + '/designs',
			oData
		);
	}

	show(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.ONBOARDING_URL + '/designs/' + id
		);
	}

	update(id, oData): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.ONBOARDING_URL + '/designs/' + id,
			oData
		);
	}

	activate(id): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.ONBOARDING_URL + '/designs/' + id + '/activate',
			{}
		);
	}

	deactivate(id): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.ONBOARDING_URL + '/designs/' + id + '/deactivate',
			{}
		);
	}

	destroy(id): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.ONBOARDING_URL + '/designs/' + id
		);
	}

	stepValidate(userId, stepNo, oParams?: object): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		if (oParams) {
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL +
					'/' +
					userId +
					'/onboarding/steps/' +
					stepNo +
					'/validate?' +
					params.toString()
			);
		} else {
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL +
					'/' +
					userId +
					'/onboarding/steps/' +
					stepNo +
					'/validate'
			);
		}
	}

	getDesingData(userId, oParams?: object): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		if (oParams) {
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL +
					'/' +
					userId +
					'/onboarding/designs?' +
					params.toString()
			);
		} else {
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL + '/' + userId + '/onboarding/designs'
			);
		}
	}

	getDesingReviewData(userId, oParams?: object): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		if (oParams) {
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL +
					'/' +
					userId +
					'/onboarding/designs/review?' +
					params.toString()
			);
		} else {
			return this._customHttpService.get(
				API_CONSTANTS.USERS_URL +
					'/' +
					userId +
					'/onboarding/designs/review'
			);
		}
	}

	storeDesingReviewData(userId, oPost?): Observable<any> {
		if (!oPost) {
			oPost = {};
		}
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL +
				'/' +
				userId +
				'/onboarding/designs/review',
			oPost
		);
	}

	storeDesingData(userId: any, oData: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/' + userId + '/onboarding/designs',
			oData
		);
	}

	onboardingRedirect(pageId) {
		switch (pageId) {
			case 1: {
				this._router.navigate(['/onboarding/preferences']);
				break;
			}
			case 2: {
				this._router.navigate(['/onboarding/designs']);
				break;
			}
			case 3: {
				this._router.navigate(['/onboarding/review']);
				break;
			}
			case 4: {
				this._router.navigate(['/onboarding/tests']);
				break;
			}
			case 5: {
				this._router.navigate(['/onboarding']);
				break;
			}
			default: {
				this._router.navigate(['/dashboard']);
				break;
			}
		}
	}

	editOnboardingRedirect(pageId) {
		switch (pageId) {
			case 1: {
				this._router.navigate(['/creatives/onboarding/preferences']);
				break;
			}
			case 2: {
				this._router.navigate(['/creatives/onboarding/designs']);
				break;
			}
			case 3: {
				this._router.navigate(['/creatives/onboarding/review']);
				break;
			}
			case 4: {
				this._router.navigate(['/creatives/onboarding/tests/edit']);
				break;
			}
			default: {
				this._router.navigate(['/dashboard']);
				break;
			}
		}
	}
}
