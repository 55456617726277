import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MessageSharedService {
	isUnread: BehaviorSubject<any> = new BehaviorSubject<any>(false);
	isUnread$ = this.isUnread.asObservable();
	changeInUser: BehaviorSubject<any> = new BehaviorSubject<any>(0);
	changeInUser$ = this.changeInUser.asObservable();
	uploadAssets: BehaviorSubject<any> = new BehaviorSubject<any>(false);
	uploadAssets$ = this.uploadAssets.asObservable();
	insertEmoji: Subject<any> = new Subject<boolean>();
	projectDetailUploadAssets: BehaviorSubject<any> = new BehaviorSubject<any>(false);
	projectDetailUploadAssets$ = this.projectDetailUploadAssets.asObservable();
	constructor() {}
}
