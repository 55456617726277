<div class="wrapper">
	<app-header class="topnavbar-wrapper" [onlyLogo]="true"></app-header>
	<div class="main-wrapper">
		<h1 class="login_title">Sign Up</h1>
		<div class="row">
			<div class="col-lg-6 col-md-6">
				<div class="mt-4">
					<!-- START card-->
					<!-- <div class="text-center">
                        <a href="https://flocksy.com" target="_blank">
                            <img alt="Logo" class="block-center mb-3 width-50" src="assets/img/flocksy-logo.png"/>
                        </a>
                    </div> -->
					<div class="card-main">
						<div class="card-body pt-0" *ngIf="valForm">
							<div *ngIf="oError">
								<alert
									*ngIf="oError?.status"
									dismissible="true"
									type="danger">
									{{ oError.error.message }}
								</alert>
							</div>
							<form
								(submit)="submitForm($event, valForm.value)"
								[formGroup]="valForm"
								class="form-validat form-main mb-3"
								name="registerForm"
								novalidate=""
								role="form">
								<div class="form-group">
									<label class="mb-1">Name</label>
									<span class="text-danger pl-1">*</span>
									<div class="input-group with-focus">
										<input
											autocomplete="off"
											class="form-control border-right-0"
											formControlName="name"
											name="name"
											placeholder="Name"
											required=""
											type="text" />
										<div
											class="input-group-append border-left">
											<span
												class="input-group-text text-muted bg-white">
												<em
													class="fas fa-user-tie label-icons"></em>
											</span>
										</div>
									</div>
									<div
										*ngIf="submitted &&
											valForm.controls['name'].hasError(
												'required'
											)
										"
										class="text-danger">
										Required
									</div>
									<div
										*ngIf="
											(valForm.controls['name'].hasError(
												'url'
											) ||
												valForm.controls[
													'name'
												].hasError('email')) &&
											(valForm.controls['name'].dirty ||
												valForm.controls['name']
													.touched)
										"
										class="text-danger">
										Please enter valid name
									</div>
								</div>
								<div class="form-group">
									<label class="mb-1">Business Name</label>
									<div class="input-group with-focus">
										<input
											autocomplete="off"
											class="form-control border-right-0"
											formControlName="business_name"
											name="business_name"
											placeholder="Business Name"
											type="text" />
										<div
											class="input-group-append border-left">
											<span
												class="input-group-text text-muted bg-white">
												<em
													class="fa fa-building label-icons"></em>
											</span>
										</div>
									</div>
								</div>
								<div class="form-group">
									<label class="mb-1">Email</label>
									<span class="text-danger pl-1">*</span>
									<div class="input-group with-focus">
										<input
											autocomplete="off"
											class="form-control border-right-0"
											formControlName="email"
											name="email"
											placeholder="Email"
											required=""
											type="email" />
										<div
											class="input-group-append border-left">
											<span
												class="input-group-text text-muted bg-white">
												<em
													class="fa fa-envelope label-icons"></em>
											</span>
										</div>
									</div>
									<div
										*ngIf="submitted &&
											valForm.controls['email'].hasError(
												'required'
											) 
										"
										class="text-danger">
										Required
									</div>
									<div
										*ngIf="submitted &&
											valForm.controls['email'].hasError(
												'email'
											) &&
											(valForm.controls['email'].dirty)
										"
										class="text-danger">
										This field must be a valid email address
									</div>
								</div>
								<div class="form-group">
									<label class="mb-1">Phone</label>
									<div class="input-group with-focus">
										<input
											autocomplete="off"
											class="form-control border-right-0"
											formControlName="phone"
											name="phone"
											placeholder="Phone"
											type="number" />
										<div
											class="input-group-append border-left">
											<span
												class="input-group-text text-muted bg-white">
												<em
													class="fa fa-phone label-icons"></em>
											</span>
										</div>
									</div>
								</div>
								<div class="form-group">
									<label class="mb-1">
										How did you hear of us?
									</label>
									<div class="input-group with-focus">
										<input
											class="form-control border-right-0"
											formControlName="lead_source"
											name="lead_source"
											placeholder="How did you hear of us?" />
										<div
											class="input-group-append border-left">
											<span
												class="input-group-text text-muted bg-white">
												<em
													class="fas fa-comment-dots label-icons"></em>
											</span>
										</div>
									</div>
								</div>
								<div formGroupName="passwordGroup">
									<div class="form-group">
										<label class="mb-1">Password</label>
										<span class="text-danger pl-1">*</span>
										<div class="input-group with-focus">
											<input
												[formControl]="
													valForm.get(
														'passwordGroup.password'
													)
												"
												class="form-control border-right-0"
												formControlName="password"
												name="password"
												placeholder="Password"
												type="password" />
											<div
												class="input-group-append border-left">
												<span
													class="input-group-text text-muted bg-white">
													<em
														class="fa fa-key label-icons"></em>
												</span>
											</div>
										</div>
										<div
											*ngIf="submitted &&
												valForm
													.get(
														'passwordGroup.password'
													)
													.hasError('required')
											"
											class="text-danger">
											Required
										</div>
										<div
											*ngIf="submitted &&
												valForm
													.get(
														'passwordGroup.password'
													)
													.hasError('minlength') &&
												(valForm.get(
													'passwordGroup.password'
												).dirty)
											"
											class="text-danger">
											Please enter min 8 characters.
										</div>
										<div
											*ngIf="submitted &&
												valForm
													.get(
														'passwordGroup.password'
													)
													.hasError('maxlength') &&
												(valForm.get(
													'passwordGroup.password'
												).dirty)
											"
											class="text-danger">
											Password can't be greater than 16
											characters.
										</div>
									</div>
									<div class="form-group">
										<label class="mb-1">
											Password confirmation
										</label>
										<span class="text-danger pl-1">*</span>
										<div class="input-group with-focus">
											<input
												[formControl]="
													valForm.get(
														'passwordGroup.confirm_password'
													)
												"
												class="form-control border-right-0"
												formControlName="confirm_password"
												name="confirm_password"
												placeholder="Password confirmation"
												type="password" />
											<div
												class="input-group-append border-left">
												<span
													class="input-group-text text-muted bg-white">
													<em
														class="fa fa-key label-icons"></em>
												</span>
											</div>
										</div>
										<div
											*ngIf="submitted &&
												valForm
													.get(
														'passwordGroup.confirm_password'
													)
													.hasError('required')
											"
											class="text-danger">
											Required
										</div>
										<div
											*ngIf="submitted &&
												valForm
													.get(
														'passwordGroup.confirm_password'
													)
													.hasError('equalTo')
											"
											class="text-danger">
											Your passwords do not match
										</div>
									</div>
								</div>
								<div class="d-flex flex-column pt-3">
									<div class="d-flex flex-column pt-3">
										<div
											class="checkbox c-checkbox float-left mt0 clearfix">
											<label class="text-dark">
												<input
													formControlName="agree_terms"
													name="agree_terms"
													required=""
													type="checkbox" />
												<span
													class="fa fa-check"></span>
												I accept the
												<a
													href="https://flocksy.com/terms-of-service"
													class="underline"
													target="_blank">
													Terms and Conditions
												</a>
											</label>
										</div>
									</div>
									<span
										*ngIf="submitted &&
											valForm.controls[
												'agree_terms'
											].hasError('required')
										"
										class="text-danger">
										Please accept terms
									</span>
								</div>
								<button
									class="btn btn-labeled btn-account mt-3 mb-2 d-flex justify-content-between align-items-center"
									type="submit">
									<span class="btn-label">
										<i class="far fa-check-circle"></i>
									</span>
									Create an Account
								</button>
								<div
									*ngIf="
										isMonthFree &&
										PLAN_COUPONS.b1g1Coupons.includes(
											coupon_code
										)
									"
									class="alert alert-success my-2 p-2">
									Buy one month and your second month is free
								</div>
								<div
									*ngIf="
										isMonthFree &&
										PLAN_COUPONS.b2g1Coupons.includes(
											coupon_code
										)
									"
									class="alert alert-success my-2 p-2">
									Buy two months and your third month is free
								</div>
								<div
									*ngIf="
										oCoupon['data'][0] &&
										oCoupon['data'][0].type == 2 &&
										oCoupon['data'][0].billing_period != 1
									"
									class="alert alert-success my-2 p-2">
									<span
										*ngIf="
											oCoupon['data'][0].billing_period ==
											3
										">
										{{
											oCoupon.data[0].coupon.percent_off
										}}% off when you pay upfront for three
										months
									</span>
									<span
										*ngIf="
											oCoupon['data'][0].billing_period ==
											6
										">
										{{
											oCoupon.data[0].coupon.percent_off
										}}% off when you pay upfront for six
										months
									</span>
									<span
										*ngIf="
											oCoupon['data'][0].billing_period ==
											12
										">
										{{
											oCoupon.data[0].coupon.percent_off
										}}% off when you pay upfront for twelve
										months
									</span>
								</div>
							</form>
							<!-- <div class="alert alert-danger text-center"></div> -->
							<div
								class="d-flex justify-content-between align-items-center flex-wrap">
								<div
									class="d-flex flex-column align-items-center fullWidthMobile">
									<div
										class="d-flex justify-content-center align-items-center">
										<span class="pr-2">
											<i class="fas fa-user-tie"></i>
										</span>
										<p class="account-text text-center">
											Already have an account?
										</p>
									</div>
									<a
										(click)="
											_analyticsService.setRouteData(1, 3)
										"
										[routerLink]="'/login'"
										class="d-flex justify-content-center underline link-text">
										Login
									</a>
								</div>

								<p class="fullWidthMobile text-center">Or</p>

								<div
									class="d-flex flex-column align-items-center fullWidthMobile">
									<div
										class="d-flex justify-content-center align-items-center">
										<span class="pr-2">
											<i class="far fa-user-circle"></i>
										</span>
										<p class="pt-2 text-center">
											Want to work with us?
										</p>
									</div>
									<a
										href="https://creatives.flocksy.com/"
										target="_blank"
										class="clearfix d-flex justify-content-center underline link-text">
										Apply to be a creative
									</a>
								</div>
							</div>
						</div>
					</div>
					<!-- END card-->
				</div>
			</div>
			<div class="col-lg-6 my-4 col-md-6 text-contents">
				<div class="d-flex justify-content-between text-area flex-wrap">
					<div *ngIf="selectedCard">
						<p class="fullWidthMobile p-1">
							<strong>
								{{ cards[selectedCard - 1]?.detailedFeedback }}
							</strong>
						</p>
					</div>
				</div>
				<div class="d-flex">
					<div
						class="cards-desktop w-100 d-flex mt-4"
						#widgetsContent>
						<div *ngFor="let card of cards_main" class="card-main">
							<div
								class="card-view d-flex mb-2"
								[ngClass]="{
									'card-view-selected':
										card.id === selectedCard,
									'card-view-unselected':
										card.id !== selectedCard
								}"
								(click)="updateCardId(card.id)">
								<div
									class="d-flex"
									[ngClass]="
										card.id !== 3
											? 'w-100'
											: 'review-image-wrapper'
									">
									<img
										src="{{ card.image }}"
										class="review-image-content" />
									<div
										class="align-self-center pl-2 title-wrapper">
										<h4 class="title mb-1">
											{{ card.title }}
										</h4>
										<span class="sub-title">
											{{ card.subtitle }}
										</span>
									</div>
								</div>
								<div
									class="font-22 ml-3 align-self-center text-white font-weight-bold">
									{{ card.feedback }}
								</div>
							</div>
						</div>
					</div>
					<div class="cards-mobile d-flex mt-4 w-100" #widgetsContent>
						<div class="card-main">
							<div
								class="card-view d-flex mb-2 card-view-selected">
								<div class="d-flex flex-row flex-wrap">
									<div class="d-flex mb-2">
										<img
											src="{{ cards[card_index].image }}"
											class="review-image-content" />
										<div class="pl-2">
											<h4 class="title mb-1">
												{{ cards[card_index].title }}
											</h4>
											<span class="sub-title">
												{{ cards[card_index].subtitle }}
											</span>
										</div>
									</div>
									<div class="f-11 text-white ml-1">
										{{ cards[card_index].feedback }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex justify-content-end">
					<div
						class="d-flex justify-content-end cursor-pointer button_desktop cards-desktop">
						<span
							class="pr-4 clickable card-button"
							[ngClass]="{
								'inactive-card-button': selectedCard === 1
							}"
							(click)="backButtonCardId()">
							<span
								[ngClass]="{
									'inactive-card-button-dot':
										selectedCard === 1
								}"
								class="circle cursor-pointer"></span>
							Back
						</span>
						<span
							class="clickable card-button"
							[ngClass]="{
								'inactive-card-button': selectedCard === 3
							}"
							(click)="nextButtonCardId()">
							Next
							<span
								[ngClass]="{
									'inactive-card-button-dot':
										selectedCard === 3
								}"
								class="circle cursor-pointer"></span>
						</span>
					</div>
					<div
						class="d-flex justify-content-end cursor-pointer cards-mobile">
						<span
							class="pr-4 clickable card-button"
							[ngClass]="{
								'inactive-card-button': card_index === 0
							}"
							(click)="backButtonCardIdMobile()">
							<span
								[ngClass]="{
									'inactive-card-button-dot': card_index === 0
								}"
								class="circle cursor-pointer"></span>
							Back
						</span>
						<span
							class="clickable card-button"
							[ngClass]="{
								'inactive-card-button': card_index === 2
							}"
							(click)="nextButtonCardIdMobile()">
							Next
							<span
								[ngClass]="{
									'inactive-card-button-dot': card_index === 2
								}"
								class="circle cursor-pointer"></span>
						</span>
					</div>
				</div>
				<div class="pt-3 d-flex flex-column">
					<img
						src="../../../../assets/img/Footer Illustration.svg"
						class="svg-signup" />
				</div>
			</div>
		</div>
	</div>
</div>

<div class="p-3 footer">
	<span>&copy;</span>
	<span>{{ _settingsService.getAppSetting('year') }}</span>
	<span class="mx-2">-</span>
	<span>{{ _settingsService.getAppSetting('name') }}</span>
</div>
