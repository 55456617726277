import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '@services/login.service';
import { LocalStorageService } from '@services/localstorage.service';
import { ApiService } from '@services/common/api.service';
import { CommonService } from '@services/common/common.service';
import { environment } from '@env/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { PackagesService } from '@services/packages/packages.service';
import { ConfigService } from '@services/user/config.service';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { WhiteLabelService } from '@services/white-label/white-label.service';
import { ReplaySubject } from 'rxjs';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	valForm: FormGroup;
	oError: object = {
		status: '',
	};
	guid: string;
	packageInfo;
	packageId: string;
	@HostListener('window:resize', ['$event'])
	getScreenSize(event?) {
		this.isMobileScreen = window.innerWidth < 480;
	}
	isMobileScreen = window.innerWidth < 480 ? true : false;
	loggedInUserInfo;
	loginPageTriggerId =
		FlocksyUtil.authenticationTriggers()['marketplace-login'];
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	marketPlaceTriggers = FlocksyUtil.marketPlaceConfigTriggerIds();
	appTitle: string;

	constructor(
		public _settingsService: SettingsService,
		public _formBuilder: FormBuilder,
		public _commonService: CommonService,
		public _loginService: LoginService,
		private _activatedRoute: ActivatedRoute,
		public _localStorageService: LocalStorageService,
		public _apiService: ApiService,
		public _analyticsService: AnalyticsService,
		public _toasterService: ToasterService,
		public _packageService: PackagesService,
		private _configService: ConfigService,
		private _router: Router,
		private _whiteLabelService: WhiteLabelService,
	) {
		this.initForm();

		if (this._activatedRoute.snapshot.paramMap.get('guid')) {
			this.guid = this._activatedRoute.snapshot.paramMap.get('guid');
			this.loginViaGuid();
		}
		if (this._activatedRoute.snapshot.queryParamMap.get('packageId')) {
			this.packageId =
				this._activatedRoute.snapshot.queryParamMap.get('packageId');
			this.getPackageDetails();
		}
	}
	
	ngOnInit() {
		if(this._whiteLabelService.isWhiteLabelledUser()) {
			this._router.navigate(['/dashboard']);
			return;
		}
		if (this._localStorageService.isUserLoggedIn()) {
			this.getAuthUser();
		}
	}

	initForm() {
		this.valForm = this._formBuilder.group({
			email: [
				null,
				Validators.compose([
					Validators.required,
					FlocksyValidator.email,
				]),
			],
			password: [null, Validators.required],
		});
	}

	getAuthUser() {
		this._apiService.getAuthUser({ tracker_flagged: 1 }).subscribe(
			(data) => {
				this.loggedInUserInfo = data.data;
				if (!this.loggedInUserInfo.is_sub_client) {
					this.storeMarketplaceConfig();
				} else {
					this._commonService.subClientLoggedInInfo.next({
						isSubClientUser: true,
						client_name: this.loggedInUserInfo.parent_name,
					});
				}
				this._localStorageService.setCurrentUser(data.data);
				this._toasterService.pop('success', `Welcome back to ${this.appTitle}!`);
				this.redirectUser();
			},
			(err) => {}
		);
	}

	submitForm($ev, value: any) {
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			return false;
		}
		this.valForm.disable();
		var oAuthData = {
			grant_type: 'password',
			username: this.valForm.value.email,
			client_id: environment.ApiClientId,
			client_secret: environment.ApiClientSecret,
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: null,
		};

		Object.assign(this.valForm.value, oAuthData);
		this.valForm.value['trigger_id'] = this.loginPageTriggerId;
		this.valForm.value['package_id'] = this.packageId;
		this._loginService.oAuthToken(this.valForm.value).subscribe(
			(data) => {
				this._localStorageService.setAccessToken(data.access_token);
				this._localStorageService.setUserLoggedIn(true);
				this.getAuthUser();
			},
			(err) => {
				if ('error' in err.error) {
					if (err.error['error'] == 'invalid_credentials')
						err.error['message'] =
							'Sorry, that information wasn’t correct. ' +
							'Please check what you entered and try again.';
				}
				this._toasterService.pop('error', err.error.message);
				this.valForm.enable();
				this.oError = err;
			}
		);
	}

	loginViaGuid() {
		if (this._localStorageService.isUserLoggedIn()) {
			this._toasterService.pop(
				'error',
				"You're already logged in!",
				'Please refresh or open a new browser.'
			);
			return false;
		}

		let auth_data = {
			grant_type: 'password',
			username: 'test@test.com',
			password: 'test@123',
			client_id: environment.ApiClientId,
			client_secret: environment.ApiClientSecret,
			guid: this.guid,
			referral_source: this._localStorageService.getReferralCode()
				? atob(this._localStorageService.getReferralCode())
				: null,
		};

		this._loginService
			.loginGuid(this.guid, JSON.stringify(auth_data))
			.subscribe(
				(data) => {
					this._localStorageService.setAccessToken(data.access_token);
					this._localStorageService.setUserLoggedIn(true);
					this.getAuthUser();
				},
				(err) => {}
			);
	}

	getPackageDetails() {
		this._packageService.getPackageDetails(this.packageId).subscribe(
			(res) => {
				this.packageInfo = res.data;
				if (this._localStorageService.isUserLoggedIn()) {
					this.redirectUser();
				}
			},
			(err) => {
				if (err.status == 422) {
					this._router.navigate(['/marketplace-login'], {
						queryParams: {},
					});
					this.packageId = null;
				}
			}
		);
	}

	storeMarketplaceConfig() {
		let reqParam = {};
		reqParam['identifier'] = FlocksyUtil.marketplaceIdentifier;
		reqParam['value'] = 1;
		reqParam['trigger_id'] = this.marketPlaceTriggers['marketplace-login'];
		this._configService
			.store(this.loggedInUserInfo.id, reqParam)
			.subscribe((res) => {
				this.loggedInUserInfo['has_marketplace_access'] = true;
				this._localStorageService.setCurrentUser(this.loggedInUserInfo);
			});
	}

	redirectUser() {
		this._commonService.redirectUser(this.packageId, this.packageInfo);
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
