import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'objectToIterable',
})
export class ObjectToIterablePipe implements PipeTransform {
	transform(dictionary: object): any {
		let aObject = [];
		for (var key in dictionary) {
			if (dictionary.hasOwnProperty(key)) {
				aObject.push({ key: key, value: dictionary[key] });
			}
		}
		return aObject;
	}
}
