import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { WhiteLabelService } from '@services/white-label/white-label.service';

@Component({
	selector: 'app-error404',
	templateUrl: './error404.component.html',
	styleUrls: ['./error404.component.scss'],
})
export class Error404Component implements OnInit {
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();

	constructor(
		public settings: SettingsService,
		public _whiteLabelService: WhiteLabelService
	) {}

	ngOnInit() {}
}
