import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class DashboardService {
	constructor(private _customHttpService: CustomHttpService) {}

	getActiveProjectsCount(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/active/count'
		);
	}

	getDraftProjectsCount(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/draft/count'
		);
	}

	getPendingProjectsCount(params?): Observable<any> {
		const aParams = new URLSearchParams();
		for (const key in params) {
			aParams.set(key, params[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/pending/count' +
				(aParams.toString() ? '?' + aParams.toString() : '')
		);
	}

	getCompletedProjectsCount(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/completed/count'
		);
	}

	getProjectBidsCount(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/bids/count'
		);
	}

	getActivePlan(id: any, params?: any): Observable<any> {
		const aParams = new URLSearchParams();
		for (const key in params) {
			aParams.set(key, params[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.CLIENTS_URL +
				'/' +
				id +
				'/plans/active' +
				(aParams.toString() ? '?' + aParams.toString() : '')
		);
	}

	getProjectActivity(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/activities'
		);
	}

	getDesignersStats(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/designers/stats'
		);
	}

	getDesignerProfileForClient(client_user_id, user_id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.CLIENTS_URL +
				'/' +
				client_user_id +
				'/designers/' +
				user_id +
				'/profile'
		);
	}

	getProjectsByMonth(project_status_id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL +
				'/projects/month' +
				'?project_status_id=' +
				project_status_id
		);
	}

	getProjectsPostedByMonth(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/projects/posted/month'
		);
	}

	getProjectsByTitleByMonth(month?: string): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL +
				'/projects/title/month' +
				'?month=' +
				month
		);
	}

	getProjectChatsByMonth(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/projects/chats/month'
		);
	}

	getBucketsByMonth(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/buckets/month'
		);
	}

	getBucketAssetsByMonth(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/bucket_assets/month'
		);
	}

	getProjectsInvitedByMonth(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/projects/invited/month'
		);
	}

	getProjectBidsAssignedByMonth(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/projects/bids/assigned/month'
		);
	}

	getProjectInvitesByWeek(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/projects/invited/week'
		);
	}

	getLateProjectsByWeek(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/projects/late/week'
		);
	}

	getSignupByMonth(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/signup/month'
		);
	}

	getTransactionsCountByMonth(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/transacions/count/month'
		);
	}

	getTransactionsByMonth(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/transacions/month'
		);
	}

	getGeoCountries(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/users/country'
		);
	}

	getGeoStates(country_code?: string): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL +
				'/users/state' +
				'?country_code=' +
				country_code
		);
	}

	storeNPSSurvey(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/' + id + '/nps-survey',
			oData
		);
	}

	getNPSSurvey(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/nps-survey'
		);
	}

	storeSocialShare(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/' + id + '/social-share',
			oData
		);
	}

	storeAgreement(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.USERS_URL + '/' + id + '/agreement',
			oData
		);
	}

	getAgreement(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/agreement'
		);
	}

	getApprovedProjectBids(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/projects/types/bids/approved/month'
		);
	}

	getAdditionalPayments(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL +
				'/projects/types/additional-payments/month'
		);
	}

	getProjectAdditionalPayments(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL +
				'/projects/types/additional-payments/month'
		);
	}

	getCreativesAvgTurnaroundTimes(oFilter: object): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL +
				'/users/creatives/projects/types/turnaround-times/month?' +
				params.toString()
		);
	}

	getCreativesAssignedByCountry(oFilter: object): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL +
				'/users/creatives/projects/assigned/countries/month?' +
				params.toString()
		);
	}

	getOnboardingTestApprovedByCountry(oFilter: object): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL +
				'/users/creatives/onboarding/tests/approved/countries/month?' +
				params.toString()
		);
	}

	getUncreditedTransactions(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL +
				'/projects/transactions/outgoing/uncredited'
		);
	}

	getCreativesAudits(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.REPORTS_URL + '/users/creatives/levels/audits'
		);
	}

	getMarketingInfo(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/dashboard'
		);
	}

	getLateProjects(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/current?filter[late-timer]=1'
		);
	}
}
