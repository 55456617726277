import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TimerSharedService {
	triggerdTimer: BehaviorSubject<any> = new BehaviorSubject<any>(false);
	triggerdTimer$ = this.triggerdTimer.asObservable();
	isIntroJs: BehaviorSubject<any> = new BehaviorSubject<any>(false);
	isIntroJs$ = this.isIntroJs.asObservable();
	showTimerLimit = new EventEmitter<boolean>(false);
	isDetailScreen = false;
	projectId = '';
	projectName = '';
	timerLimit = new BehaviorSubject<any>(3);
	timerLimit$ = this.timerLimit.asObservable();
	stopHours = 3;
	datePickerConfig = {
		isAnimated: true,
		dateInputFormat: 'MMM DD, YYYY',
		showWeekNumbers: false,
	};

	constructor() {}
}
