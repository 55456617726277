import { Component, Input } from '@angular/core';
import { FlocksyUtil } from '../../../../common/FlocksyUtil';

@Component({
	selector: 'app-details-info',
	templateUrl: './package-details.component.html',
	styleUrls: ['./package-details.component.scss'],
})
export class PackageDetailsComponent {
	@Input() packageInfo: any;
	packageBGImage = FlocksyUtil.packageBGImage;
	discountCouponBgImage = FlocksyUtil.discountBgImage;
	satisfactionGuaranteedImg = FlocksyUtil.satisfactionGuaranteedImg;
	satisfactionGuaranteedTooltip = FlocksyUtil.satisfactionGuaranteedTooltip;

	constructor() {}
}
