import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ProjectUserService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/users'
		);
	}

	get(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/project/get/clients_designers?project_id=' +
				id
		);
	}
}
