import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class CreativeLevelService {
	constructor(private _customHttpService: CustomHttpService) {}

	show(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/level'
		);
	}

	showCriteria(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.USERS_URL + '/' + id + '/levels/criteria'
		);
	}

	index(id): Observable<any> {
		return this._customHttpService.get(
			`${API_CONSTANTS.ADMIN_USERS_URL}/${id}`
		);
	}

	store(id, oData): Observable<any> {
		return this._customHttpService.put(
			`${API_CONSTANTS.ADMIN_USERS_URL}/${id}`,
			oData
		);
	}
}
