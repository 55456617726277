import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user/user.service';
import { takeUntil } from 'rxjs/operators';
import { ReferralService } from '../../services/referral.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-stats-tracking',
	templateUrl: './stats-tracking.component.html',
	styleUrls: ['./stats-tracking.component.scss'],
})
export class StatsTrackingComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();
	userData: any;
	stats: any;

	constructor(
		public _userService: UserService,
		public _referralService: ReferralService
	) {}

	ngOnInit(): void {
		this._userService.userData$
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((data) => {
				this.userData = data;
				this._referralService
					.getTrackingStats(this.userData.id)
					.pipe(takeUntil(this.unsubscribe$))
					.subscribe((data) => {
						this.stats = data;
					});
			});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
