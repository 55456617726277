import {
	Component,
	Input,
	OnInit,
	TemplateRef,
} from '@angular/core';

import { UserblockService } from './userblock.service';
import { LocalStorageService } from '@services/localstorage.service';
import { CommonService } from '@services/common/common.service';
import { Subscription } from 'rxjs';
import { CreativeLevelService } from '@services/user/creative-level.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { SidebarService } from '@services/layout/siderbar.service';
import { UserService } from '@services/user/user.service';
import { ToasterService } from 'angular2-toaster';
import { FormControl } from '@angular/forms';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { PlansService } from '@services/user/plans/plans.service';
import { Router } from '@angular/router';
import { LegacyDetailsOverlayComponent } from './legacy-details-overlay/legacy-details-overlay.component';
import { WhiteLabelService } from '@services/white-label/white-label.service';
@Component({
	selector: 'app-userblock',
	templateUrl: './userblock.component.html',
	styleUrls: ['./userblock.component.scss'],
})
export class UserblockComponent implements OnInit {
	profileImageUrl: string;

	profilePictureChanged: Subscription;
	loginBeanNameChanged: Subscription;
	creative_level_id: number;
	modalCreativeLevelRef: BsModalRef;
	aLevels = [];
	unassigned_chats;
	selectedOption = 3;
	customText: string;
	aData;
	sAvailable = false;
	sOffline = false;
	sCustom = false;
	current_availability_config_id;
	availability_custom_messages;
	message_id;
	status_updated = false;
	creativeLevelId;
	custom = new FormControl('');
	availibilityText = 'Select your availability';
	isDesignerUser = this._localStorageService.isDesignerUser();
	isSubClientUser = this._localStorageService.isSubClientUser();
	legacyPlanInfo: any = FlocksyUtil.legacyPlanInfo;
	piggyIconSvg = FlocksyUtil.piggyIconSvg;
	isAdminUser = this._localStorageService.isAdminUser();
	isProjectManagerUser = this._localStorageService.isProjectManagerUser();
	isProjectManagerAsAM = this._localStorageService.isProjectManagerAsAM();
	planPeriodType = FlocksyUtil.planPeriodType;
	isClientUser = this._localStorageService.isClientUser();
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();
	isPlatinumLead = this._localStorageService.isPlatinumLead();
	isPlatinumLevel = this._localStorageService.isPlatinumLevel();
	model_data = [
		{
			id: 1,
			value: 'Available',
		},
		{
			id: 0,
			value: 'Offline',
		},
	];
	oActivePlans = [];
	isSubscriptionInfoShown = true;
	aStatus = [];
	@Input() currentUrl = '';
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();
	profileUpdated: Subscription;

	constructor(
		public _localStorageService: LocalStorageService,
		public userblockService: UserblockService,
		public creativeLevelService: CreativeLevelService,
		public _bsModalService: BsModalService,
		public _analyticsService: AnalyticsService,
		public _sidebarService: SidebarService,
		public _userService: UserService,
		public _toasterService: ToasterService,
		public _commonService: CommonService,
		private readonly _plansService: PlansService,
		private router: Router,
		private _whiteLabelService: WhiteLabelService,
	) {
		this.profileImageUrl = this._localStorageService.getProfileImageURL();
	}

	ngOnInit() {
		this.initSubscription();
		if (this._localStorageService.isDesignerUser()) {
			this.retrieveSidebarData();
			this.getAvailability();
		}
		if (this.isSubClientUser || this.isClientUser) {
			this.retrieveActivePlan();
		}

		this.userblockService.updatedStatus$.subscribe((res) => {
			if (res == true) {
				this.getAvailability();
			}
		});
		this.currentUrl = this.router.url;
	}

	initSubscription() {
		this.profilePictureChanged =
			this._commonService.profilePictureChanged.subscribe((data) => {
				this.profileImageUrl =
					this._localStorageService.getProfileImageURL();
			});

		this.loginBeanNameChanged =
			this._commonService.loginBeanNameChanged.subscribe((data) => {});
		
		this.profileUpdated =
			this._commonService.profileUpdated.subscribe((data) => {
				this.getAvailability();
			});
	}

	userBlockIsVisible() {
		return this.userblockService.getVisibility();
	}

	retrieveSidebarData() {
		this._sidebarService.index().subscribe((data) => {
			this.creative_level_id = data.data['creative_level_id'];
			this.creativeLevelId = FlocksyUtil.creativeLevels();

			this._commonService.unassignedChatsUpdated.emit({
				unassigned_chats:
					data.data['total_unassigned_unread_chats_count'],
			});
		});
	}
	getAvailability() {
		const param = 'include=availability_custom_message';
		this._userService.getCreativeAvailibity(param).subscribe((data) => {
			this.aData = data.data;

			this.aStatus = [
				...this.model_data,
				...this.aData.availability_custom_messages,
			];
			if (this.status_updated == true) {
				this.updateCustomStatus(this.aStatus[2].id);
				this.status_updated = false;
			}
			if (this.aData.accept_project == 2) {
				this.message_id = this.aData.current_availability_config_id;
				const filteredData =
					this.aData.availability_custom_messages.filter((obj) => {
						return obj.id === this.message_id;
					});
				this.availibilityText = filteredData[0].value;
			}
			if (this.aData.accept_project == 1) {
				this.availibilityText = 'Available';
				this.message_id = this.aData.accept_project;
			}
			if (this.aData.accept_project == 0) {
				this.availibilityText = 'Offline';
				this.message_id = this.aData.accept_project;
			}
		});
	}
	changeAvailibility(id) {
		if (!this.aData?.timezone_id) {
			delete this.aData?.timezone_id;
		}
		
		this.message_id = id;
		if (id == 0) {
			this.aData.accept_project = 0;
			const oData = this.aData;
			this.updateUserStatus(this.aData);
		} else if (id == 1) {
			this.aData.accept_project = 1;
			const oData = this.aData;
			this.updateUserStatus(oData);
		} else if (id > 1) {
			this.aData.accept_project = 2;
			this.updateCustomStatus(id);
		}
	}
	updateCustomStatus(id) {
		const oData = {
			accept_project: 2,
			availability_user_config_id: id,
			name: this._localStorageService.getUserFullName(),
		};
	
		if (this.aData?.timezone_id) {
			oData['timezone_id'] = this.aData.timezone_id;
		}
	
		this._userService
			.updateCustomMessage(this._localStorageService.getUserId(), oData)
			.subscribe((res) => {
				if (res) {
					this._toasterService.pop('success', 'Your status has been updated!');
					this.getAvailability();
				} else {
					this._toasterService.pop('error', 'Error while creating custom message');
				}
			});
	}	

	createCustomMessage(title) {
		this._userService
			.createCustomMessage(this._localStorageService.getUserId(), {
				title,
			})
			.subscribe((res) => {
				if (res) {
					this.custom.reset();
					this.aStatus = [];
					this.status_updated = true;
					this.getAvailability();
				} else {
					this._toasterService.pop(
						'error',
						'error while creating custom message'
					);
				}
			});
	}

	updateUserStatus(oData) {
		this._userService
			.updateUserAvailibilty(this._localStorageService.getUserId(), oData)
			.subscribe((res) => {
				this._toasterService.pop(
					'success',
					'Your status has been updated!'
				);
				if (oData.accept_project == 1) {
					this.sAvailable = true;
				}
				if (oData.accept_project == 0) {
					this.sOffline == true;
				}
				this.getAvailability();
			});
	}

	showLegacyInformation() {
		this._bsModalService.show(LegacyDetailsOverlayComponent, {
			class: "modal-xl"
		})
	}

	retrieveCreativeLevelCriteria() {
		this.creativeLevelService
			.showCriteria(this._localStorageService.getUserId())
			.subscribe((data) => {
				this.aLevels = data.data;
			});
	}

	initCreativeLevelModal(template: TemplateRef<any>) {
		this.modalCreativeLevelRef = this._bsModalService.show(
			template,
			Object.assign(
				{},
				{},
				{ modalClass: 'modal-dialog', class: 'modal-xl modal-full' }
			)
		);
		this.retrieveCreativeLevelCriteria();
		this._analyticsService.setOverlayTrackData(16, 45);
	}

	retrieveActivePlan() {
		this._plansService
			.index(this._localStorageService.getUserId())
			.subscribe(
				(data) => {
					this.oActivePlans = data;
					this.isSubscriptionInfoShown =
						this._commonService.getSubscriptionBtnInfo(data);
					this._localStorageService.setItem(
						'currentPlanPeriodType',
						this.oActivePlans[0]?.plan?.billing_period == 1
							? this.planPeriodType.monthlyPlan
							: this.oActivePlans[0]?.plan?.billing_period == 3
							? this.planPeriodType.quarterlyPlan
							: this.planPeriodType.yearlyPlan
					);
				},
				(err) => {}
			);
	}
}
