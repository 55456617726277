import { Directive, ElementRef, HostListener, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[textareaAutoHeight]'
})
export class TextareaAutoHeightDirective implements AfterViewChecked {

  constructor(private el: ElementRef) { }

  ngAfterViewChecked(): void {
    this.adjustHeight();
  }

  @HostListener('input') onInput() {
    this.adjustHeight();
  }

  private adjustHeight(): void {
    const textarea = this.el.nativeElement as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
}
