import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { DocumentService } from '@services/document/document.service';
import { ReferralService } from '../../services/referral.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '@services/user/user.service';
import { environment } from '@env/environment';
import { AnalyticsService } from '@services/analytics/analytics.service';

@Component({
	selector: 'app-share-social-media',
	templateUrl: './share-social-media.component.html',
	styleUrls: ['./share-social-media.component.scss'],
})
export class ShareSocialMediaComponent implements OnInit, OnDestroy {
	urlShare = '';
	text: string =
		'Just got some awesome creative work done by ' +
		'Flocksy - quick, easy, and affordable! If ' +
		'you need top-notch content, use my link for ' +
		'$100 off your first month!';

	linkedInText: string =
		"Hi everyone! I've been partnering with Flocksy for some " +
		'fantastic creative work. They deliver high-quality graphic design, ' +
		'video editing, custom illustrations, copywriting and ' +
		'more quickly and at a great flat rate. If you want to ' +
		'enhance your content affordably and easily, use this link to get $100 off ' +
		'your first month of Flocksy services! ';

	private unsubscribe$ = new Subject<void>();

	constructor(
		public _documentService: DocumentService,
		public _toasterService: ToasterService,
		public _referralService: ReferralService,
		public _userService: UserService,
		public _analyticsService: AnalyticsService,
	) {}

	ngOnInit(): void {
		this._userService.userData$
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((userData) => {
				this.urlShare = `${environment.landingSiteURL}/?r=${userData?.id}`;
				this._referralService.setReferralLink(this.urlShare);
			});
	}

	copyToClipboard() {
		this._documentService.copyToClipboard(this.urlShare);
		this._toasterService.pop('success', 'Referral URL has been copied');
		this._analyticsService.setOverlayTrackData(16, 117);
	}

	shareOnFacebook(): void {
		const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
			this.urlShare + '&utm_source=facebook'
		)}`;
		window.open(shareUrl, '_blank');
	}

	shareOnTwitter(): void {
		const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
			this.urlShare + '&utm_source=twitter'
		)}&text=${encodeURIComponent(
			this.text
		)}&hashtags=recommendation,marketing`;
		window.open(shareUrl, '_blank');
		this._analyticsService.setOverlayTrackData(16, 119);
	}

	shareOnLinkedIn(): void {
		const encodedText = encodeURIComponent(
			this.linkedInText + this.urlShare + '&utm_source=linkedin'
		);
		const shareUrl = `https://www.linkedin.com/feed/?shareActive=true&text=${encodedText}`;
		window.open(shareUrl, '_blank');
		this._analyticsService.setOverlayTrackData(16, 118);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
