import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';
import { CustomHttpService } from '../custom.http.service';

@Injectable({
	providedIn: 'root',
})
export class NotesService {
	constructor(private _customHttpService: CustomHttpService) {}

	store(userId, oData): Observable<any> {
		return this._customHttpService.post(
			`${API_CONSTANTS.USERS_URL}/${userId}/requirement-notes`,
			oData
		);
	}

	index(userId, page?, oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set('filter[' + key + ']', oParams[key]);
		}

		return this._customHttpService.get(
			`${
				API_CONSTANTS.USERS_URL
			}/${userId}/requirement-notes?page=${page}${
				params.toString() ? '&' + params.toString() : ''
			}`
		);
	}

	destroy(userId, id?): Observable<any> {
		return this._customHttpService.delete(
			`${API_CONSTANTS.USERS_URL}/${userId}/requirement-notes/${id}`
		);
	}
}
