<div class="card-wrapper">
	<div class="mt-4 mx-2">
		<div class="m-3">
			<h4
				class="m-0 font-13 font-weight-semi-bold"
				*ngIf="cards.length && !isViaSignUpPage">
				Please select one of the cards you previously added to make this
				payment:
			</h4>
			<h4
				class="m-0 font-13 font-weight-semi-bold"
				*ngIf="!cards.length && !isViaSignUpPage">
				You have no added cards attached to your account. In order to
				proceed, please add a valid card.
			</h4>
		</div>
		<div class="pt-0 mx-3 mt-4">
			<div class="row" *ngIf="cards.length > 0 && !isViaSignUpPage">
				<div class="col-lg-3 col-sm-6 mb-4" *ngFor="let card of cards">
					<div class="card b min-width-225 mb-0">
						<div class="card-header pb-0">
							<a class="text-primary">
								<img
									*ngIf="card?.cc_id != selectedCardId"
									[src]="
										'../../../../../../' + shieldFillUncheck
									"
									alt="" />
								<img
									*ngIf="card?.cc_id == selectedCardId"
									[src]="
										'../../../../../../' + shieldFillCheck
									"
									alt="" />
							</a>
							<a
								class="float-right clickable"
								*ngIf="card?.default_source"
								[popover]="defaultCardTooltip"
								triggers="mouseenter:mouseleave click"
								container="body">
								<em
									class="fa fa-info-circle light-grey-icon"></em>
							</a>
							<a
								class="float-right clickable"
								*ngIf="
									!card?.default_source && card?.backup_source
								"
								[popover]="backupCardTooltip"
								triggers="mouseenter:mouseleave click"
								container="body">
								<em
									class="fa fa-info-circle light-grey-icon"></em>
							</a>
							<a
								class="float-right clickable"
								*ngIf="
									!card?.default_source &&
									!card?.backup_source
								"
								[popover]="anotherCardTooltip"
								triggers="mouseenter:mouseleave click"
								container="body">
								<em
									class="fa fa-info-circle light-grey-icon"></em>
							</a>
						</div>
						<div class="card-body text-center pt-0">
							<div
								class="my-3 card-brand-parent"
								*ngIf="
									card?.brand &&
									cardBrandNames.includes(card?.brand)
								">
								<div class="card-brand-img-parent">
									<img
										class="bg-transparent max-width-86"
										src="{{
											cardBrands[card?.brand]['img']
										}}"
										alt="card?.brand" />
								</div>
							</div>

							<div
								class="rounded-circle thumb86 mb-2 card-brand-parent"
								*ngIf="
									!card?.brand ||
									!cardBrandNames.includes(card?.brand)
								">
								<div class="card-brand-img-parent">
									<img
										class="bg-transparent"
										src="{{
											cardBrands['default']['img']
										}}" />
								</div>
							</div>
							<p class="f-16 mb-3" *ngIf="card?.default_source">
								Default Card
							</p>
							<p class="f-16 mb-3" *ngIf="card?.backup_source">
								Backup Card
							</p>
							<p
								class="f-16 mb-3"
								*ngIf="
									!card?.default_source &&
									!card?.backup_source
								">
								Additional Card
							</p>
							<div>
								<a
									class="btn btn-secondary text-center text-muted w-100 pt-2">
									<span class="pr-2">
										**** {{ card?.last4 }}
									</span>
									<span class="text-center">|</span>
									<span class="pl-2">
										{{ card?.exp_month }}/{{
											card?.exp_year
										}}
									</span>
								</a>
							</div>
							<div
								class="d-flex justify-content-center mt-3 check-box-wrapper c-radio card-dropdwn">
								<!-- <input
									type="radio"
									[(ngModel)]="defaultCardId"
									[value]="card?.cc_id"
									(change)="onCardChange(defaultCardId)" />
								<span class="ml-2 font-13">
									Select this card for payment
								</span> -->
								<label class="font-12">
									<input
										type="radio"
										[(ngModel)]="defaultCardId"
										[value]="card?.cc_id"
										(change)="
											onCardChange(defaultCardId)
										" />
									<span class="fa fa-circle"></span>
									Select this card for payment
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- add plan -->
				<div
					class="col-lg-3 col-sm-6 mb-4"
					*ngIf="showCardsList && isCardsLoaded">
					<div
						class="card b min-width-225 add-card-section h-100 mb-0"
						(click)="onAddCard()">
						<div
							class="card-body text-center pt-0 d-flex align-items-center justify-content-center">
							<div class="">
								<img
									class="mb-3 img-fluid rounded-circle thumb66"
									[src]="'../../../../../' + addSubUserImg"
									alt=""
									onError="this.src='../../../assets/img/user-placeholder.png';" />
								<p class="mb-1">Add Card</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #defaultCardTooltip>
	<div style="width: 15rem">
		Your default card will be used to process all payments for your account.
	</div>
</ng-template>
<ng-template #backupCardTooltip>
	<div style="width: 15rem">
		Your backup card will be charged if we're unable to process a payment
		using your default card.
	</div>
</ng-template>
<ng-template #anotherCardTooltip>
	<div style="width: 15rem">
		Saved cards can be updated to be either a default or backup payment
		method. We will not charge these cards unless you set them as your
		default or backup payment method.
	</div>
</ng-template>
