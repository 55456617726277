import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';
import { CustomHttpService } from '../custom.http.service';

@Injectable()
export class SidebarService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL + '/sidebar'
		);
	}
}
