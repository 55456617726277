import { Component, OnInit } from '@angular/core';
import { ApiService } from '@services/common/api.service';
import { WhiteLabelService } from '@services/white-label/white-label.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-white-label-inactive',
  templateUrl: './white-label-inactive.component.html',
  styleUrls: ['./white-label-inactive.component.scss']
})
export class WhiteLabelInactiveComponent implements OnInit {

  constructor(
      public _apiService: ApiService,
      public _whiteLabelService: WhiteLabelService,
      public router: Router,
  ) {
  }

  ngOnInit(): void {
    if(!this._whiteLabelService.isWhiteLabelledUser() || this._whiteLabelService.getWhiteLabelledUser()?.is_active) {
      this.router.navigate(['/login']);
    }
  }

}
