import { Component, Input, OnInit } from '@angular/core';
import { FlocksyUtil } from '../../../../common/FlocksyUtil';

@Component({
	selector: 'app-true-icon',
	templateUrl: './true-icon.component.html',
	styleUrls: ['./true-icon.component.scss'],
})
export class TrueIconComponent implements OnInit {
	@Input() type = 'normal';
	iconType = FlocksyUtil.iconType;
	constructor() {}

	ngOnInit(): void {}
}
