<svg
	width="14"
	height="12"
	viewBox="0 0 14 12"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		d="M6.24897 0.417433L4.54019 3.52017L0.717021 4.01933C0.0314143 4.10838 -0.243352 4.86532 0.253844 5.29886L3.01982 7.71263L2.36562 11.1224C2.24786 11.7387 2.97272 12.2004 3.57982 11.9121L7 10.3021L10.4202 11.9121C11.0273 12.198 11.7521 11.7387 11.6344 11.1224L10.9802 7.71263L13.7462 5.29886C14.2434 4.86532 13.9686 4.10838 13.283 4.01933L9.45981 3.52017L7.75103 0.417433C7.44486 -0.135624 6.55776 -0.142654 6.24897 0.417433Z"
		fill="#16CC8E" />
</svg>
