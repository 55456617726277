import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../../../../services/user/user.service';
import { takeUntil } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { ReferralService } from '../../services/referral.service';
import { FlocksyValidator } from '../../../../common/validation/flocksy-validator';
import { Router } from '@angular/router';

@Component({
	selector: 'app-paypal-email',
	templateUrl: './paypal-email.component.html',
	styleUrls: ['./paypal-email.component.scss'],
})
export class PaypalEmailComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();
	payPalEmail: FormControl = new FormControl(
		null,
		Validators.compose([FlocksyValidator.email])
	);
	isEmailFulfilled = false;
	userData;

	constructor(
		public _userService: UserService,
		private _referralService: ReferralService,
		private router: Router
	) {}

	ngOnInit(): void {
		this._userService.userData$
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((data) => {
				this.userData = data;
				this.payPalEmail.setValue(this.userData?.payment_email);
				if (this.payPalEmail.value) {
					this.payPalEmail.disable();
				} else {
					this.payPalEmail.enable();
				}
			});
		this.isEmailFulfilled = !!this.payPalEmail.value;
	}

	setNewPaypalEmail(event: any) {
		event.preventDefault();

		if (!this.payPalEmail.valid) {
			return;
		}
		const newUserData = {
			...this.userData,
			payment_email: this.payPalEmail.value,
		};
		this._userService
			.updateUserData(newUserData)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((res) => {
				this._referralService.setPayPalEmail(res.payment_email);
				this.isEmailFulfilled = !!this.payPalEmail.value;
			});
	}

	navigateAndRefresh(url: string): void {
		this.router.navigate([url]).then(() => {
			window.location.reload();
		});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
