import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/common/api.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
	referral;

	constructor(
		private _activatedRoute: ActivatedRoute,
		public _apiService: ApiService
	) {}

	ngOnInit() {
		this.checkReferralCodeViaQueryString();
	}

	checkReferralCodeViaQueryString() {
		this._activatedRoute.queryParams.subscribe((params) => {
			if ('r' in params) {
				this.referral = params['r'];
				this.storeTracker();
				this.redirectToFlocksy();
			} else {
				this.redirectToFlocksy();
			}
		});
	}

	storeTracker() {
		this._apiService
			.storeTracker({ page_id: 6, referral: this.referral })
			.subscribe(
				(data) => {},
				(err) => {}
			);
	}

	redirectToFlocksy() {
		if (this.referral) {
			window.location.href =
				environment.landingSiteURL + '/?r=' + this.referral;
		} else {
			window.location.href = environment.landingSiteURL;
		}
	}
}
