import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TeamService } from '@services/team/team.service';
import { LocalStorageService } from '@services/localstorage.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { BsModalRef } from 'ngx-bootstrap';
import { WhiteLabelService } from '@services/white-label/white-label.service';

@Component({
	selector: 'app-designer-profile',
	templateUrl: './designer-profile.component.html',
	styleUrls: ['./designer-profile.component.scss'],
})
export class DesignerProfileComponent implements OnInit {
	@Input() user_id: number;
	@Input() profilePath: string;
	@Input() projectTypeId;
	@Input() projectType;
	@Input() trigger_id;
	@Input() isEligibleForHire = false;
	@Input() client_id;
	aDesignerProjects = [];
	oDesignerForClient: object = {};
	modalHireDesignerRef: BsModalRef;
	isOperationalClientUser =
		this._localStorageService.isOperationalClientUser();
	tab: string;
	showOverlayContent = false;
	oTeam: any = {
		aPm: [],
	};
	teamsWorkedOnData: any = {};
	uniqueSkills = [];
	recentHistory = true;
	isWhiteLabelledUser = this._whiteLabelService.isWhiteLabelledUser();
	isSkeletonLoading = true;

	constructor(
		public _teamService: TeamService,
		public _analyticsService: AnalyticsService,
		public _localStorageService: LocalStorageService,
		public _whiteLabelService: WhiteLabelService
	) {
		this.retrieveTeam();
	}

	ngOnInit() {
		if (!this.client_id) {
			this.client_id = this._localStorageService.getUserId();
		}
		this.init();
	}

	init() {
		this.retrieveProjectsByClient();
		this.retrieveDesignerProfileForClient();
		this.tab = 'history';
	}

	retrieveTeam() {
		this.getTeam().subscribe(
			(data) => {
				this.oTeam['aPm'] = data.data.project_manager;
			},
			(err) => {}
		);
	}

	getTeam() {
		return this._teamService.get();
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('user_id' in changes) {
			if (!changes['user_id'].firstChange) {
				this.init();
			}
		}
	}

	retrieveProjectsByClient() {
		this._teamService
			.getDesignerProjectsForClient(this.client_id, this.user_id)
			.subscribe((data) => {
				this.aDesignerProjects = data.data;
				console.log(this.aDesignerProjects);
				
			});
	}

	retrieveDesignerProfileForClient() {
		this.getDesignerProfileForClient().subscribe((data) => {
			this.oDesignerForClient = data.data;

			this.teamsWorkedOnData = Array.from(
				this.oDesignerForClient['recent_projects_by_other_clients']
					.reduce(
						(entryMap, e) =>
							entryMap.set(e.feedback_by_id, {
								...(entryMap.get(e.feedback_by_id) || {}),
								...e,
							}),
						new Map()
					)
					.values()
			);

			console.log(this.oDesignerForClient[
				'recent_projects_by_other_clients'
			])
			

			if (!this.profilePath) {
				if ('profile' in this.oDesignerForClient) {
					this.profilePath =
						this.oDesignerForClient['profile']['profile_path'];
				}
			}
			this.showOverlayContent = true;
			this.isSkeletonLoading = false;
			const uniqueProjectTypeNames = {};

			for (let project of this.oDesignerForClient['projects_completed_by_title']) {
				uniqueProjectTypeNames[project.project_type_name] = true; // Store project_type_name as a key
			}

			this.uniqueSkills = Object.keys(uniqueProjectTypeNames);
		});
	}

	getDesignerProfileForClient() {
		return this._teamService.getDesignerProfileForClient(
			this.client_id,
			this.user_id,
			this.prepareFilters()
		);
	}

	prepareFilters() {
		return {
			project_type_id: this.projectTypeId,
		};
	}

	getStarsArray(rating: number): any[] {
		return new Array(rating);
	}

	getEmptyStarsArray(rating: number): any[] {
		let remainingStars = 5 - rating;
		return new Array(remainingStars);
	}

	getFirstNameApostrophe(fullName: string): string {
		const firstName = fullName.split(' ')[0];
		return firstName.endsWith('s') ? `${firstName}'` : `${firstName}'s`;
	}

}
