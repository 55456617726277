import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable()
export class ProjectBriefService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		if (oParams == '&message_center=1') {
			return this._customHttpService.get(
				API_CONSTANTS.API_ENDPOINT_URL +
					'/get/projects/' +
					id +
					'?project_brief=1&message_center=1'
			);
		} else {
			return this._customHttpService.get(
				API_CONSTANTS.API_ENDPOINT_URL +
					'/get/projects/' +
					id +
					'?project_brief=1' +
					  '&include[is_edit_brief_allowed]=1'+
					(params.toString() ? '&' + params.toString() : '')

			);
		}
	}

	updateQuestionAnswers(
		project_question_answer_id: number,
		oData: any
	): Observable<any> {
		return this._customHttpService.put(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/project_question_answers/' +
				project_question_answer_id +
				'/update',
			oData
		);
	}

	getLatestDesignsAndProjects(id, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/users/creatives/' +
				id +
				'/projects/files?' +
				(params.toString() ? '&' + params.toString() : '')
		);
	}
	getBrief(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.API_ENDPOINT_URL +
				'/get/projects' +
				id +
				'?project_brief=1&message_center=1'
		);
	}

	getProjectAttachmentsToken(projectId) {
		return this._customHttpService.post(
			`${API_CONSTANTS.PROJECTS_URL}/${projectId}/attachments/download`,
			''
		);
	}

	downloadProjectAttachments(projectId) {
		return this.getProjectAttachmentsToken(projectId).subscribe(
			(data) => {
				if (data) {
					const url = `${API_CONSTANTS.PROJECTS_URL}/${projectId}/attachments/download?token=${data.data.token}`;
					window.location.href = url;
					return false;
				}
			},
			(err) => {}
		);
	}
}
