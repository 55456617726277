<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
	<!-- START navbar header-->
	<a
		(click)="_analyticsService.setRouteData(16, 34); redirectUser()"
		class="navbar-brand text-white client-logo-wrapper">
		<div class="client-logo logo-class clickable">
			<img
				class="block-center"
				width="45px"
				[src]="isWhiteLabeledUser ? (whiteLabelledUser?.logo_document?.full_path ? whiteLabelledUser?.logo_document?.full_path: defaultLogo) :'assets/img/flocksy-chat-icon.png'"
				alt="Logo" />
		</div>
	</a>
	<!-- END navbar header-->
	<!-- START Left navbar-->
	<ul class="navbar-nav mr-auto flex-row">
		<li class="nav-item">
			<!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
			<a
				(click)="
					settings.toggleLayoutSetting('asideToggled');
					$event.stopPropagation()
				"
				[ngClass]="onlyLogo === true ? 'd-none' : 'd-block'"
				class="nav-link sidebar-toggle d-md-none text-dark">
				<em class="fas fa-bars"></em>
			</a>
		</li>
	</ul>
	<!-- END Left navbar-->
	<!-- START Right Navbar-->
	<ul *ngIf="!isWhiteLabeledUser"
		class="navbar-nav flex-row"
		[ngStyle]="{ visibility: onlyLogo === true ? 'hidden' : 'show' }">
		<!-- START Alert menu-->
		<li class="nav-item d-flex align-items-center">
			<a
				href="javascript:void(0);"
				class="refer-button"
				type="button"
				(click)="initFreeMonthReferralModal(freeMonthReferralModal)">
				<!-- *ngIf="isClientUser" -->
				<!-- <span class="btn-label">
					<i class="fa fa-gift"></i>
				</span> -->
				<img src="assets/img/referral-link/refer-button.svg" alt="refer">
			</a>
		</li>
		<li
			class="nav-item feedback d-flex align-items-center ml-2"
			*ngIf="isFeedbackeryEnabled">
			<a
				#feedbackButton
				id="header-feedback-btn"
				class="nav-link text-dark text-lg"
				href="javascript:">
				<!-- <span class="d-none d-sm-block">
					<em class="fas fa-bullhorn mr-1"></em>
					Give Feedback
				</span>
				<span class="d-block d-sm-none">
					<em class="fas fa-bullhorn mr-1"></em>
				</span> -->
				<span class="">
					<em class="fas fa-bullhorn mr-1 font-18"></em>
				</span>
			</a>
		</li>
		<!-- END Alert menu-->
	</ul>
	<!-- END Right Navbar-->
	<!-- <div
		class="mobile-view w-100"
		*ngIf="
			(isPlatinumLevel || isProjectManagerUser || isProjectManagerAsAM) &&
			mobile &&
			!isLoginPage
		">
		<app-earning-timer [desktop_view]="false"></app-earning-timer>
	</div> -->
</nav>

<!-- END Top Navbar-->
<ng-template #freeMonthReferralModal>
	<div class="modal-header border-0 px-4 py-0">
		<div class="row mx-0 justify-content-between w-100 align-items-center padding-top-2">
			<div *ngIf="!isEmailSent" class="font-24 line-height-27">
				Tell people about Flocksy and earn cash!
			</div>
			<div *ngIf="isEmailSent" class="font-24 line-height-27">
				Success!
			</div>
		</div>
		<div class="pt-4 ml-2">
			<button
				(click)="modalFreeMonthReferralRef.hide()"
				aria-label="Close"
				class="close"
				type="button">
				<img src="assets/img/close-light.svg" alt="">
			</button>
		</div>
	</div>
	<div class="modal-body px-4 padding-bottom-4 pt-0">
		<hr class="mt-4 mb-4">
		<div *ngIf="!isEmailSent">
			<div class="mb-5">
				<app-paypal-email></app-paypal-email>
			</div>
			<div>
				<app-share-tabs></app-share-tabs>
			</div>
			<hr class="mb-4 mt-0">
			<div>
				<app-stats-tracking></app-stats-tracking>
			</div>
		</div>
		<div *ngIf="isEmailSent">
			<app-email-success></app-email-success>
		</div>
	</div>
</ng-template>

<ng-template #reportConcernModal>
	<app-report-concern
		(emitCloseModal)="modalReportConcernRef.hide()"></app-report-concern>
</ng-template>

<ng-template #seeAllNotificationsModal>
	<app-notifications-detailview
		(emitCloseModal)="
			modalSeeAllNotificationsRef.hide()
		"></app-notifications-detailview>
</ng-template>
