import {
	Component,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	AfterViewInit,
	ChangeDetectorRef,
} from '@angular/core';
import { CarouselComponent } from 'ngx-bootstrap/carousel';

@Component({
	selector: 'app-custom-carousel',
	templateUrl: './custom-carousel.component.html',
	styleUrls: ['./custom-carousel.component.scss'],
})
export class CustomCarouselComponent implements AfterViewInit {
	@Input() itemsPerSlide: number = 1;
	@Input() showIndicators: boolean = true;
	@Input() noWrap: boolean = false;
	@Input() interval: number = 5000;
	@Input() aiImageList: any[] = [];
	@Input() activeSlide: number = 0;

	// Function inputs as event emitters
	@Output() onDeleteAiImg = new EventEmitter<number>();
	@Output() openAIImgModal = new EventEmitter<any>();
	@Output() onSelectFile = new EventEmitter<number>();
	@Output() unSelectImg = new EventEmitter<number>();

	// Additional inputs
	@Input() aiImgModal: any; // Adjust type as necessary
	@Input() searchIcon: string = '';
	@Input() whitePlusIcon: string = '';
	@Input() whiteDeleteIcon: string = '';

	@Output() slideChanged = new EventEmitter<number>();
	@ViewChild('carousel') carousel: CarouselComponent;

	constructor(private cdr: ChangeDetectorRef) {}

	ngAfterViewInit() {
		this.carousel.selectSlide(this.activeSlide);
		this.cdr.detectChanges();
	}

	// Emit onSelectFile
	handleSelectFile(index: number) {
		this.onSelectFile.emit(index);
	}

	// Emit unSelectImg
	handleUnSelectImg(index: number) {
		this.unSelectImg.emit(index);
	}

	// Emit onDeleteAiImg
	handleDeleteAiImg(index: number) {
		this.onDeleteAiImg.emit(index);
	}

	// Emit openAIImgModal
	handleOpenAIImgModal(modal: any) {
		this.openAIImgModal.emit(modal);
	}
}
