import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { AuthGuard } from '../guards/auth-guard.service';
import { routes } from './routes';
import { MarketplaceModule } from './marketplace/marketplace.module';

@NgModule({
	imports: [
		SharedModule,
		RouterModule.forRoot(routes),
		PagesModule,
		MarketplaceModule,
	],
	declarations: [],
	exports: [RouterModule],
	providers: [AuthGuard],
})
export class RoutesModule {}
