<div class="skeleton-loder-wrapper" *ngIf="!isSkeletonShown">
	<div class="mb-4">
		<app-skeleton-loader
			[height]="'40px'"
			[width]="'100%'"></app-skeleton-loader>
	</div>

	<!-- Content -->
	<div class="mb-4">
		<div class="row">
			<div class="col-lg-1 d-flex align-items-center">
				<app-skeleton-loader
					[height]="'50px'"
					[width]="'50px'"
					[appearance]="'circle'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-1 d-flex align-items-center">
				<app-skeleton-loader
					[height]="'50px'"
					[width]="'50px'"
					[appearance]="'circle'"></app-skeleton-loader>
			</div>
		</div>
	</div>
	<div class="mb-4">
		<app-skeleton-loader
			[height]="'40px'"
			[width]="'100%'"></app-skeleton-loader>
	</div>
	<div class="mb-4">
		<app-skeleton-loader
			[height]="'70px'"
			[width]="'100%'"></app-skeleton-loader>
	</div>
	<div class="d-flex mb-4 align-items-center">
		<app-skeleton-loader
			[height]="'50px'"
			[width]="'50px'"
			[appearance]="'circle'"></app-skeleton-loader>
		<div class="ml-3 w-100">
			<app-skeleton-loader
				[height]="'100px'"
				[width]="'100%'"></app-skeleton-loader>
		</div>
	</div>
	<div class="d-flex mb-4 align-items-center">
		<app-skeleton-loader
			[height]="'50px'"
			[width]="'50px'"
			[appearance]="'circle'"></app-skeleton-loader>
		<div class="ml-3 w-100">
			<app-skeleton-loader
				[height]="'100px'"
				[width]="'100%'"></app-skeleton-loader>
		</div>
	</div>
	<div class="d-flex mb-4 align-items-center">
		<app-skeleton-loader
			[height]="'50px'"
			[width]="'50px'"
			[appearance]="'circle'"></app-skeleton-loader>
		<div class="ml-3 w-100">
			<app-skeleton-loader
				[height]="'100px'"
				[width]="'100%'"></app-skeleton-loader>
		</div>
	</div>
	<div class="d-flex w-100 mb-4">
		<div class="w-100">
			<app-skeleton-loader
				[height]="'40px'"
				[width]="'100%'"></app-skeleton-loader>
		</div>
		<div class="ml-3 w-100">
			<app-skeleton-loader
				[height]="'40px'"
				[width]="'200px'"></app-skeleton-loader>
		</div>
	</div>

	<div class="mb-4">
		<app-skeleton-loader
			[height]="'120px'"
			[width]="'100%'"></app-skeleton-loader>
	</div>

	<div class="d-flex mb-4">
		<div class="mr-3">
			<app-skeleton-loader
				[height]="'180px'"
				[width]="'180px'"></app-skeleton-loader>
		</div>
		<div class="mr-3">
			<app-skeleton-loader
				[height]="'180px'"
				[width]="'180px'"></app-skeleton-loader>
		</div>
		<div class="mr-3">
			<app-skeleton-loader
				[height]="'180px'"
				[width]="'180px'"></app-skeleton-loader>
		</div>
		<div class="mr-3">
			<app-skeleton-loader
				[height]="'180px'"
				[width]="'180px'"></app-skeleton-loader>
		</div>
	</div>
	<div class="mb-4">
		<div class="row">
			<div class="col-lg-1 d-flex align-items-center">
				<app-skeleton-loader
					[height]="'50px'"
					[width]="'50px'"
					[appearance]="'circle'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-1 d-flex align-items-center">
				<app-skeleton-loader
					[height]="'50px'"
					[width]="'50px'"
					[appearance]="'circle'"></app-skeleton-loader>
			</div>
		</div>
	</div>
	<div class="mb-4">
		<div class="row">
			<div class="col-lg-1 d-flex align-items-center">
				<app-skeleton-loader
					[height]="'50px'"
					[width]="'50px'"
					[appearance]="'circle'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-2">
				<app-skeleton-loader
					[height]="'200px'"
					[width]="'100%'"></app-skeleton-loader>
			</div>
			<div class="col-lg-1 d-flex align-items-center">
				<app-skeleton-loader
					[height]="'50px'"
					[width]="'50px'"
					[appearance]="'circle'"></app-skeleton-loader>
			</div>
		</div>
	</div>
	<div class="row mb-4">
		<div class="col-md-6 col-sm-12">
			<app-skeleton-loader
				[height]="'50px'"
				[width]="'300px'"></app-skeleton-loader>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
		</div>
		<div class="col-md-6 col-sm-12">
			<app-skeleton-loader
				[height]="'50px'"
				[width]="'300px'"></app-skeleton-loader>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
			<div class="d-flex">
				<app-skeleton-loader
					class="mr-3"
					[height]="'30px'"
					[width]="'30px'"></app-skeleton-loader>
				<app-skeleton-loader
					[height]="'30px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
		</div>
	</div>
</div>

<div class="mb-4" *ngIf="isSkeletonShown">
	<div class="row">
		<div class="col-lg-1 d-flex align-items-center">
			<app-skeleton-loader
				[height]="'50px'"
				[width]="'50px'"
				[appearance]="'circle'"></app-skeleton-loader>
		</div>
		<div class="col-lg-2">
			<app-skeleton-loader
				[height]="'150px'"
				[width]="'100%'"></app-skeleton-loader>
		</div>
		<div class="col-lg-2">
			<app-skeleton-loader
				[height]="'150px'"
				[width]="'100%'"></app-skeleton-loader>
		</div>
		<div class="col-lg-2">
			<app-skeleton-loader
				[height]="'150px'"
				[width]="'100%'"></app-skeleton-loader>
		</div>
		<div class="col-lg-2">
			<app-skeleton-loader
				[height]="'150px'"
				[width]="'100%'"></app-skeleton-loader>
		</div>
		<div class="col-lg-2">
			<app-skeleton-loader
				[height]="'150px'"
				[width]="'100%'"></app-skeleton-loader>
		</div>
		<div class="col-lg-1 d-flex align-items-center">
			<app-skeleton-loader
				[height]="'50px'"
				[width]="'50px'"
				[appearance]="'circle'"></app-skeleton-loader>
		</div>
	</div>
</div>
