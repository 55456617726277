import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { BsModalService } from 'ngx-bootstrap';
import { UserService } from '../../../services/user/user.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { LocalStorageService } from '../../../services/localstorage.service';

@Component({
	selector: 'app-report-concern',
	templateUrl: './report-concern.component.html',
})
export class ReportConcernComponent implements OnInit {
	@Output() emitCloseModal = new EventEmitter();
	aDisabledBtn = false;
	reportConcernForm: FormGroup;

	constructor(
		public _toasterService: ToasterService,
		public _bsModalService: BsModalService,
		public _analyticsService: AnalyticsService,
		public _formBuilder: FormBuilder,
		public _userService: UserService,
		public _localStorageService: LocalStorageService
	) {}

	ngOnInit() {
		this.initForm();
	}

	initForm() {
		this.reportConcernForm = this._formBuilder.group({
			message: [null, Validators.required],
		});
	}

	closeModal() {}

	submitReportConcernForm($ev) {
		$ev.preventDefault();
		for (let c in this.reportConcernForm.controls) {
			this.reportConcernForm.controls[c].markAsTouched();
		}
		if (!this.reportConcernForm.valid) {
			return false;
		}

		this.aDisabledBtn = true;
		this._userService
			.creativeReportConcern(
				this._localStorageService.getUserId(),
				this.reportConcernForm.value
			)
			.subscribe(
				(res) => {
					this._toasterService.pop(
						'success',
						'Your concern was reported successfully'
					);
					this.aDisabledBtn = true;
					this.emitCloseModal.emit();
				},
				(err) => {
					this.aDisabledBtn = true;
				}
			);
	}
}
