import { Injectable } from '@angular/core';
import { CustomHttpService } from './../custom.http.service';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable({
	providedIn: 'root'
  })
export class ProjectAssignedUserService {
	constructor(private _customHttpService: CustomHttpService) {}

	index(id, data?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in data) {
			params.set(key, data[key]);
		}
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				id +
				'/assigned-users' +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	indexUnassigned(id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/assigned-users/unassigned'
		);
	}

	store(id, oData): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PROJECTS_URL + '/' + id + '/assigned-users',
			oData
		);
	}

	destroy(project_id, id): Observable<any> {
		return this._customHttpService.delete(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				project_id +
				'/assigned-users/' +
				id
		);
	}

	assigned(id, user_id): Observable<any> {
		return this._customHttpService.get(
			API_CONSTANTS.PROJECTS_URL +
				'/' +
				id +
				'/assigned-users/' +
				user_id +
				'/assigned'
		);
	}
}
