import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../../common/constant/ApiConstants';
import { CustomHttpService } from '../../custom.http.service';

@Injectable()
export class UserNoteService {
	constructor(private readonly _customHttpService: CustomHttpService) {}

	index(id, oParams): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${id}/notes${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	store(clientId, oData: any): Observable<any> {
		return this._customHttpService.post(
			`${API_CONSTANTS.USERS_URL}/${clientId}/notes`,
			oData
		);
	}

	update(noteId, oData) {
		return this._customHttpService.put(
			`${API_CONSTANTS.USERS_URL}/notes/${noteId}`,
			oData
		);
	}

	delete(noteId, oParams?): Observable<any> {
		let params = new URLSearchParams();
		for (let key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.delete(
			`${API_CONSTANTS.USERS_URL}/notes/${noteId}${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}
}
