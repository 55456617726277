<div *ngIf="!isSkeletonLoading">
	<div class="modal-header border-0 pt-4 px-4 pb-2">
		<p class="modal-header-text">
			View stats on the work between you and {{ designer?.name }}
		</p>
		<ng-template #designerTooltip>
			View stats on the work between you and {{ designer?.name }}
		</ng-template>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="emitCloseStatsModal.emit()">
			<img src="../../../../../assets/img/team-close-btn.svg" alt="">
		</button>
	</div>
	<div class="modal-body px-4 pb-5 pt-0" *ngIf="stats?.project_types_worked.length > 0">
		<hr class="mt-0 mb-4">
		<span class="project-categories-text">
			Project categories
		</span>

		<div class="row mt-4">
			<div
				class="col-lg-3 col-md-6 mb-4"
				*ngFor="let type of stats?.project_types_worked">
				<div class="categories-block d-flex flex-column px-3 py-4">
					
					<img
						class="mb-3 categories-img-width"
						[src]="
							'../../../../../../' +
							(teamsProjectTypes[
							type?.external_slug
							]?.path ||
							teamsProjectTypes[
									'other'
								]?.path)
						"
						alt="" />
					<div class="categories-text">
						{{type?.title}}
					</div>
				</div>
			</div>
		</div>

		<hr class="my-4"/>

		<div>
			<div class="project-categories-text mb-4">
				Number of projects
			</div>
			<div>
				<img src="../../../../../../../assets/img/checked-teams.svg" alt="" class="mr-2">
				<span>{{ stats?.projects_worked }}</span>
			</div>
		</div>

		<hr class="my-4"/>

		<div *ngIf="stats?.projects_worked <= 0" class="mt-3">
			<span>
				You haven't worked with this creative yet. Once you work on
				projects with them, you'll be able to see your shared history
				with them here.
			</span>
		</div>

		<div class="project-categories-text mb-4">
			Last three projects
		</div>

		<div>
			<div class="projects-block d-flex flex-row justify-content-between mb-3" *ngFor="let project of stats?.projects_completed">

				<div class="d-flex flex-row mx-0 justify-content-between padding-top-bottom-main-block tab-flex-column col-11 mobile-flex-unset">
					<div class="d-flex flex-row mx-0 review-project-block tab-flex-column">
						<div class="d-flex flex-column justify-content-center review-width">
							<div class="font-14 grey-text mb-1 overflow-hidden-text clickable word-break-all review-text-height">
								<span
								*ngIf="project?.feedback_user"
									[tooltip]="statsFeedback"
									container="body">
									{{project?.feedback_user?.feedback}}
								</span>
								<span
									*ngIf="!project?.feedback_user">
									No feedback for this project
								</span>
								<ng-template #statsFeedback>
									<div>
										{{project?.feedback_user?.feedback}}
									</div>
								</ng-template>
							</div>
							<div class="row mx-0" *ngIf="project?.feedback_user">
								<div *ngFor="let star of getStarsArray(project?.feedback_user?.rating)" class="mr-1">
									<img src="../../../../assets/img/star-solid-my-team.svg" alt="" class="star-dimensions">
								</div>
								<div *ngFor="let emptyStar of getEmptyStarsArray(project?.feedback_user?.rating)" class="mr-1">
									<img src="../../../../assets/img/star-hollow-my-team.svg" alt="" class="star-dimensions">
								</div>
							</div>
							<div class="row mx-0" *ngIf="!project?.feedback_user">
								<div *ngFor="let i of [].constructor(5); let index = index" class="mr-1">
								  <img src="../../../../assets/img/star-hollow-my-team.svg" alt="" class="star-dimensions">
								</div>
							</div>
						</div>
						<div class="d-flex flex-column justify-content-between project-detail-width">
							<div class="font-18 dark-grey-text project-details-field-margin-bottom">
								<a [routerLink]="['/projects', project?.project_id]"
									target="_blank"
									class="dark-grey-text no-underline"
									>
									<span
									class="clickable short-file-name"
									>
									{{ project?.title }}
									<img src="../../../../assets/img/link-redirect.svg" alt="" class="ml-2">
									</span>
								</a>
							</div>
							<ng-template #projTitle>
								{{ project?.title }}
							</ng-template>
							<div class="font-14 grey-text project-details-field-margin-bottom">
								{{project?.project_title_name}}
							</div>
							<div class="font-12 dark-grey-text clickable">
								<span 
								*ngIf="project?.audits[0]?.created_at"
								>{{
									_localStorageService.formatDate(
										project?.audits[0]?.created_at,
										'MMMM DD, YYYY'
									)
								}}</span>
							</div>
							<ng-template #fullReviewDate>
								{{
									_localStorageService.formatDate(
										project?.audits[0]?.created_at,
										'MMMM DD, YYYY'
									)
								}}
							</ng-template>
						</div>
					</div>
					<div class="d-flex flex-row creative-width">
						<div class="mr-2">
							<img [src]="designer?.profile_pic" 
							alt="userProfilePicture"
							onError="this.src='../../../../assets/img/user-placeholder.png';" 
							class="creative-profile clickable"
							>
						</div>
						<div class="d-flex flex-column">
							<div class="font-14 light-grey-text mb-2">
								{{getFirstNameApostrophe(designer?.name)}} response:
							</div>
							<div class="font-14 grey-text overflow-hidden-text word-break-all"
								[tooltip]="statsComment"
								container="body">
								{{ project?.feedback_comment_by_creative }}
								<ng-template #statsComment>
									<div>
										{{project?.feedback_user?.feedback}}
									</div>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<hr class="my-4"/>

		<div
			class="mb-4 project-categories-text"
			*ngIf="stats?.average_rating?.avg_rating">
			Average rating
		</div>
		<div>
			<img src="../../../../../../../assets/img/rating-big-star-teams.svg" alt="" class="mr-2">
			<span>{{ stats?.average_rating?.avg_rating }}</span>
		</div>
		
	</div>

	<div
		class="modal-body"
		*ngIf="stats?.project_types_worked.length <= 0 && statsShow">
		<div class="mt-3">
			<span>
				You haven't worked with this creative yet. Once you work on
				projects with them, you'll be able to see your shared history
				with them here.
			</span>
		</div>
	</div>
</div>

<div *ngIf="isSkeletonLoading">
	<div class="modal-header border-0 pt-4 px-4 pb-2">
		<p class="modal-header-text">
			View stats on the work between you and {{ designer?.name }}
		</p>
		<button
			type="button"
			class="close"
			aria-label="Close"
			(click)="emitCloseStatsModal.emit()">
			<img src="../../../../../assets/img/team-close-btn.svg" alt="">
		</button>
	</div>
	<div class="modal-body px-4 pb-5 pt-0">
		<hr class="mt-0 mb-4">
		<span class="project-categories-text">
			<app-skeleton-loader
				[height]="'18px'"
				[width]="'200px'"></app-skeleton-loader>
		</span>

		<div class="row mt-4">
			<div
				class="col-lg-3 col-md-6 mb-4"
				*ngFor="let type of [].constructor(3)">
				<div class="categories-block d-flex flex-column px-3 py-4">
					
					<app-skeleton-loader
						[height]="'33px'"
						[width]="'32px'"></app-skeleton-loader>
					<div class="categories-text">
						<app-skeleton-loader
							[height]="'17px'"
							[width]="'100px'"></app-skeleton-loader>
					</div>
				</div>
			</div>
		</div>

		<hr class="my-4"/>

		<div>
			<div class="project-categories-text mb-4">
				<app-skeleton-loader
					[height]="'18px'"
					[width]="'200px'"></app-skeleton-loader>
			</div>
			<div>
				<span class="mr-2">
					<app-skeleton-loader
					[height]="'37px'"
					[width]="'40px'"></app-skeleton-loader>
				</span>
				<span><app-skeleton-loader
					[height]="'14px'"
					[width]="'7px'"></app-skeleton-loader></span>
			</div>
		</div>

		<hr class="mt-2 mb-4"/>

		<div class="project-categories-text mb-4">
			<app-skeleton-loader
				[height]="'18px'"
				[width]="'200px'"></app-skeleton-loader>
		</div>

		<div>
			<div class="projects-block d-flex flex-row justify-content-between mb-3" *ngFor="let project of [].constructor(3)">

				<div class="d-flex flex-row mx-0 justify-content-between padding-top-bottom-main-block tab-flex-column col-11 mobile-flex-unset">
					<div class="d-flex flex-row mx-0 review-project-block tab-flex-column">
						<div class="d-flex flex-column justify-content-center review-width">
							<div class="font-14 grey-text mb-1 overflow-hidden-text clickable word-break-all review-text-height">
								<span>
									<app-skeleton-loader
										[height]="'15px'"
										[width]="'44px'"></app-skeleton-loader>
								</span>
							</div>
							<div class="row mx-0">
								<div>
									<app-skeleton-loader
										[height]="'21.4px'"
										[width]="'228px'"></app-skeleton-loader>
								</div>
							</div>
						</div>
						<div class="d-flex flex-column justify-content-between project-detail-width">
							<div class="font-18 dark-grey-text project-details-field-margin-bottom">
								<span
									class="dark-grey-text no-underline"
									>
									<span
									class="clickable short-file-name"
									>
									<app-skeleton-loader
										[height]="'21.5px'"
										[width]="'114px'"></app-skeleton-loader>
									</span>
								</span>
							</div>
							<div class="font-14 grey-text project-details-field-margin-bottom">
								<app-skeleton-loader
								[height]="'17px'"
								[width]="'50px'"></app-skeleton-loader>
							</div>
							<div class="font-12 dark-grey-text clickable">
								<span 
								><app-skeleton-loader
								[height]="'17px'"
								[width]="'50px'"></app-skeleton-loader></span>
							</div>
						</div>
					</div>
					<div class="d-flex flex-row creative-width">
						<div class="mr-2">
							<app-skeleton-loader
								[appearance]="'circle'"
								[height]="'40px'"
								[width]="'40px'"></app-skeleton-loader>
						</div>
						<div class="d-flex flex-column">
							<div class="font-14 light-grey-text mb-2">
								<app-skeleton-loader
								[height]="'17px'"
								[width]="'117px'"></app-skeleton-loader>
							</div>
							<div class="font-14 grey-text overflow-hidden-text word-break-all">
								<app-skeleton-loader
								[height]="'17px'"
								[width]="'117px'"></app-skeleton-loader>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<hr class="my-4"/>

		<div
			class="mb-4 project-categories-text">
			<app-skeleton-loader
				[height]="'18px'"
				[width]="'200px'"></app-skeleton-loader>
		</div>
		<div>
			<span class="mr-2">
				<app-skeleton-loader
					[height]="'37px'"
					[width]="'40px'"></app-skeleton-loader>
			</span>
			<span>
				<app-skeleton-loader
				[height]="'14px'"
				[width]="'27px'"></app-skeleton-loader>
			</span>
		</div>
		
	</div>
</div>
