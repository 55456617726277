import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { AppSignupComponent } from './pages/app-signup/app-signup.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PasswordResetTokenComponent } from './pages/password-reset-token/password-reset-token.component';
import { InviteSignupComponent } from './pages/invite-signup/invite-signup.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { ANALYTICS_PAGES } from '@common/constant/AnalyticsPageIds';
import { WhiteLabelInactiveComponent } from './pages/white-label-inactive/white-label-inactive.component';

export const routes = [
	{
		path: '',
		component: LayoutComponent,
		children: [
			{
				path: '',
				redirectTo: 'account-activity',
				pathMatch: 'full',
			},
			{
				path: 'admin',
				loadChildren: () =>
					import('./admin/admin.module').then((m) => m.AdminModule),
			},
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[16].placements[30] },
			},
			{
				path: 'projects',
				loadChildren: () =>
					import('./projects/projects.module').then(
						(m) => m.ProjectsModule
					),
			},
			{
				path: 'buckets',
				loadChildren: () =>
					import('./account-files/buckets/buckets.module').then(
						(m) => m.BucketsModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[22].placements[64] },
			},
			{
				path: 'project-files',
				loadChildren: () =>
					import('./account-files/deliverables/deliverables.module').then(
						(m) => m.DeliverablesModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[22].placements[64] },
			},
			{
				path: 'teams',
				loadChildren: () =>
					import('./teams/teams.module').then((m) => m.TeamsModule),
				data: { page_placement_id: ANALYTICS_PAGES[24].placements[69] },
			},
			{
				path: 'profile',
				loadChildren: () =>
					import('./profile/profile.module').then(
						(m) => m.ProfileModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[28].placements[78] },
			},
			{
				path: 'payment-settings',
				loadChildren: () =>
					import('./payment-settings/payment-settings.module').then(
						(m) => m.PaymentSettingsModule
					)
			},
			{
				path: 'change-password',
				loadChildren: () =>
					import('./change-password/change-password.module').then(
						(m) => m.ChangePasswordModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[29].placements[81] },
			},
			{
				path: 'billing-info',
				loadChildren: () =>
					import('./billing-info/billing-info.module').then(
						(m) => m.BillingInfoModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[30].placements[84] },
			},
			{
				path: 'finance',
				loadChildren: () =>
					import('./finance/finance.module').then(
						(m) => m.FinanceModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[31].placements[87] },
			},
			{
				path: 'billing',
				loadChildren: () =>
					import('./billing/billing.module').then(
						(m) => m.BillingModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[31].placements[87] },
			},
			{
				path: 'emails',
				loadChildren: () =>
					import('./emails/emails.module').then(
						(m) => m.EmailsModule
					),
				data: {
					page_placement_id: ANALYTICS_PAGES[40].placements[108],
				},
			},
			{
				path: 'bids',
				loadChildren: () =>
					import('./bids/bids.module').then((m) => m.BidsModule),
				data: { page_placement_id: ANALYTICS_PAGES[33].placements[91] },
			},
			{
				path: 'template-library',
				loadChildren: () =>
					import(
						'./template-libraries/template-libraries.module'
					).then((m) => m.TemplateLibrariesModule),
				data: { page_placement_id: ANALYTICS_PAGES[27].placements[76] },
			},
			{
				path: 'users',
				loadChildren: () =>
					import('./users/users.module').then((m) => m.UsersModule),
			},
			{
				path: 'messages',
				loadChildren: () =>
					import('./messages/messages.module').then(
						(m) => m.MessagesModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[26].placements[74] },
			},
			{
				path: 'payment',
				loadChildren: () =>
					import('./time-tracker/time-tracker.module').then(
						(m) => m.TimeTrackerModule
					),
			},
			{
				path: 'clients',
				loadChildren: () =>
					import('./clients/clients.module').then(
						(m) => m.ClientsModule
					),
				data: {
					page_placement_id: ANALYTICS_PAGES[39].placements[106],
				},
			},
			{
				path: 'showcases',
				loadChildren: () =>
					import('./showcases/showcases.module').then(
						(m) => m.ShowcasesModule
					),
				data: { page_placement_id: ANALYTICS_PAGES[35].placements[97] },
			},
			{
				path: 'creatives',
				loadChildren: () =>
					import('./creatives/creatives.module').then(
						(m) => m.CreativesModule
					),
			},
			{
				path: 'resources',
				loadChildren: () =>
					import('./resources/resources.module').then(
						(m) => m.ResourcesModule
					),
			},
			{
				path: 'feedback',
				loadChildren: () =>
					import('./feedback/feedback.module').then(
						(m) => m.FeedbackModule
					),
			},
			{
				path: 'notifications',
				loadChildren: () =>
					import('./notifications/notifications.module').then(
						(m) => m.NotificationsModule
					),
			},
			{
				path: 'finance-and-billing',
				loadChildren: () =>
					import(
						'./finance-and-billing/finance-and-billing.module'
					).then((m) => m.FinanceAndBillingModule),
			},
			{
				path: 'finance-and-billing',
				loadChildren: () =>
					import(
						'./finance-and-billing/finance-and-billing.module'
					).then((m) => m.FinanceAndBillingModule),
			},
			{
				path: 'packages',
				loadChildren: () =>
					import('./packages/packages.module').then(
						(m) => m.PackagesModule
					),
			},
			{
				path: 'subscriptions',
				loadChildren: () =>
					import('./subscriptions/subscriptions.module').then(
						(m) => m.SubscriptionModule
					),
			},
			{
				path: 'account-activity',
				loadChildren: () =>
					import('./activity-feed-page/activity-feed-page.module').then((m) => m.ActivityFeedPageModule),
				data: { page_placement_id: ANALYTICS_PAGES[82].placements[198] },
			},
			{
				path: 'white-label',
				loadChildren: () => import('./white-label/white-label.module').then(m => m.WhiteLabelModule),
			}
		],
	},
	{
		path: 'oauth',
		loadChildren: () =>
			import('./oauth/oauth.module').then((m) => m.OauthModule),
	},
	{
		path: 'share',
		loadChildren: () =>
			import('./shares/shares.module').then((m) => m.SharesModule),
		data: { page_placement_id: ANALYTICS_PAGES[11].placements[18] },
	},
	{
		path: 'profiles',
		loadChildren: () =>
			import('./profiles/profiles.module').then((m) => m.ProfilesModule),
		data: { page_placement_id: ANALYTICS_PAGES[12].placements[19] },
	},
	{
		path: 'onboarding',
		loadChildren: () =>
			import('./onboarding/onboarding.module').then(
				(m) => m.OnboardingModule
			),
		data: { page_placement_id: ANALYTICS_PAGES[13].placements[20] },
	},
	{
		path: 'plans',
		loadChildren: () =>
			import('./plans/plans.module').then((m) => m.PlansModule),
		data: { page_placement_id: ANALYTICS_PAGES[14].placements[24] },
	},
	{
		path: 'logout',
		loadChildren: () =>
			import('./logout/logout.module').then((m) => m.LogoutModule),
		data: { page_placement_id: ANALYTICS_PAGES[3].placements[8] },
	},


	// Not lazy-loaded routes
	{ path: 'login', component: LoginComponent },
	{ path: 'checkout', component: CheckoutComponent },
	{ path: 'login/:guid', component: LoginComponent },
	{ path: 'signup', component: SignupComponent },
	{ path: 'app-signup', component: AppSignupComponent },
	{ path: 'password/reset', component: ResetPasswordComponent },
	{ path: 'inactive', component: WhiteLabelInactiveComponent },
	{ path: 'invite-signup/:token', component: InviteSignupComponent },
	{ path: 'password/reset/:token', component: PasswordResetTokenComponent },
	{ path: '404', component: Error404Component },
	{ path: '500', component: Error500Component },
	// Not found
	{ path: '**', redirectTo: '404' },
];
