import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../../common/constant/ApiConstants';
import { CustomHttpService } from '../../custom.http.service';

@Injectable()
export class ProjectNotesService {
	constructor(private readonly _customHttpService: CustomHttpService) {}

	index(oParams): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.PROJECTS_URL}/notes${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	delete(noteId, oParams?) {
		const params = new URLSearchParams();
		for (const key in oParams) {
			if (oParams[key]) {
				params.set(key, oParams[key]);
			}
		}
		return this._customHttpService.delete(
			`${API_CONSTANTS.PROJECTS_URL}/notes/${noteId}${
				params.toString() ? '?' + params.toString() : ''
			}`
		);
	}

	update(noteId, oData): Observable<any> {
		return this._customHttpService.put(
			`${API_CONSTANTS.PROJECTS_URL}/notes/${noteId}`,
			oData
		);
	}

	store(projectId, oData): Observable<any> {
		return this._customHttpService.post(
			`${API_CONSTANTS.PROJECTS_URL}/${projectId}/notes`,
			oData
		);
	}
}
