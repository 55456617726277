import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomHttpService } from '../../custom.http.service';
import { API_CONSTANTS } from '../../../common/constant/ApiConstants';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionsService {
	constructor(private readonly _customHttpService: CustomHttpService) {}

	store(userID, data) {
		return this._customHttpService.post(
			`${API_CONSTANTS.USERS_URL}/${userID}/subscriptions`,
			data
		);
	}

	update(userId, data) {
		return this._customHttpService.put(
			`${API_CONSTANTS.USERS_URL}/${userId}/subscriptions`,
			data
		);
	}

	index(userId, oFilter, isUpgrade?) {
		let params = new URLSearchParams();
		for (let key in oFilter) {
			params.set('filter[' + key + ']', oFilter[key]);
		}
		if (isUpgrade) {
			params.set('page', 'upgrades');
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.USERS_URL}/${userId}/subscriptions` +
				(params.toString() ? '?' + params.toString() : '')
		);
	}

	planUpgradeSupport(data: any): Observable<any> {
		return this._customHttpService.post(
			API_CONSTANTS.PLANS_URL + '/upgrade/support',
			data
		);
	}

	retrieveActivePlans(oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}
		if (oParams) {
			return this._customHttpService.get(
				API_CONSTANTS.PLANS_URL + '/active?' + params.toString()
			);
		}
		return this._customHttpService.get(API_CONSTANTS.PLANS_URL + '/active');
	}
}
