import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { NgxSortableModule } from 'ngx-sortable';
import { AppModalPopupModule } from '@shared/module/app-modal-popup/app-modal-popup.module';

import { AppComponent } from './app.component';

import { CoreModule } from '@core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from '@shared/shared.module';
import { RoutesModule } from './routes/routes.module';

import { PageTitleService } from '@services/page.title.service';
import { LocalStorageService } from '@services/localstorage.service';
import { CustomHttpService } from '@services/custom.http.service';
import { ApiService } from '@services/common/api.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { OnboardingDesignService } from '@services/onboarding/onboarding-design.service';
import { FreshchatService } from '@services/analytics/freshchat.service';
import { TrackingAnalyticsService } from '@services/tracking.analytics.service';
import { SocketIoService } from '@services/socket.io.service';
import { BsDropdownModule } from 'ngx-bootstrap';
import { environment } from '@env/environment';
import { WhiteLabelService } from '@services/white-label/white-label.service';
import { S3DocumentService } from '@services/document/s3-document.service';
import { CreateProjectOverlayModule } from '@shared/module/create-project-overlay/create-project-overlay.module';

//import { ToasterNotificationService } from './common/notifications/toaster.notification.service';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		HttpClientModule,
		BrowserAnimationsModule, // required for ng2-tag-input
		CoreModule,
		LayoutModule,
		SharedModule.forRoot(),
		RoutesModule,
		LoadingBarHttpClientModule,
		BsDropdownModule,
		LoadingBarRouterModule,
		NgxLinkifyjsModule.forRoot({
			enableHash: false,
			enableMention: false,
		}),
		ToasterModule.forRoot(),
		NgxSortableModule,
		AppModalPopupModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		CreateProjectOverlayModule,
	],
	providers: [
		Title,
		PageTitleService,
		LocalStorageService,
		CustomHttpService,
		ApiService,
		ToasterService,
		AnalyticsService,
		OnboardingDesignService,
		FreshchatService,
		TrackingAnalyticsService,
		SocketIoService,
		S3DocumentService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(
		private _whiteLabelService: WhiteLabelService,
	) {
		if(environment.baseURL !== location.origin) {
			this._whiteLabelService.setIsWhiteLabelledUser(true);
			this._whiteLabelService.init();
		}
		else {
			this._whiteLabelService.setIsWhiteLabelledUser(false);
			this._whiteLabelService.showLoader();
			this._whiteLabelService.setAppTitle("Flocksy");
			this._whiteLabelService.setFlocksyFavicon();
		}
	}

}
