<div class="paypal-email-wrapper container-fluid border rounded p-3">
	<div class="paypal-title-text mb-1">
		Get $100 cash via PayPal for every month that someone you refer has a
		active Flocksy subscription
	</div>
	<div>
		You cannot sign up with your own link to earn commissions or discounts
	</div>
	<hr class="mt-4 margin-bottom-2" />

	<div class="mb-1">
		Your PayPal email
		<span class="text-asterisk">*</span>
	</div>

	<div>
		<form (submit)="setNewPaypalEmail($event)">
			<div class="d-flex flex-row align-items-center">
				<div class="input-group with-focus mr-3">
					<input
						autocomplete="off"
						class="form-control"
						placeholder="Your PayPal email"
						[formControl]="payPalEmail"
						type="email" />
				</div>
				<div *ngIf="!isEmailFulfilled">
					<button
						[disabled]="!payPalEmail.value || !payPalEmail.valid"
						class="btn btn-primary purple-btn" type="submit"
						[ngClass]="{'invalid-button': !payPalEmail.value || !payPalEmail.valid}"
					>
						Add
					</button>
				</div>
				<div *ngIf="isEmailFulfilled" class="ml-3">
					<span>
						<img
							(click)="navigateAndRefresh('/profile')"
							class="edit-icon"
							src="assets/img/referral-link/edit-green-icon.svg"
							alt="" />
					</span>
				</div>
			</div>
		</form>
	</div>
</div>
