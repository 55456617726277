import { Injectable } from '@angular/core';
import { CustomHttpService } from '../custom.http.service';
import { API_CONSTANTS } from '../../common/constant/ApiConstants';

@Injectable({
	providedIn: 'root',
})
export class PackagesService {
	constructor(private _customHttpService: CustomHttpService) {}

	store(body) {
		return this._customHttpService.post(API_CONSTANTS.PACKAGES_URL, body);
	}

	update(id, body) {
		return this._customHttpService.put(
			`${API_CONSTANTS.PACKAGES_URL}/${id}`,
			body
		);
	}

	deletePackage(id) {
		return this._customHttpService.delete(
			API_CONSTANTS.PACKAGES_URL + '/' + id
		);
	}

	index(page, oParams?) {
		let params = new URLSearchParams();
		for (let key in oParams) {
			if (key === 'sort') {
				params.set('sort', oParams[key]);
			} else {
				params.set('filter[' + key + ']', oParams[key]);
			}
		}
		return this._customHttpService.get(
			`${API_CONSTANTS.PACKAGES_URL}?page=${page}${
				params.toString() ? '&' + params.toString() : ''
			}`
		);
	}

	show(id) {
		return this._customHttpService.get(
			API_CONSTANTS.PACKAGES_URL + '/' + id
		);
	}

	getPackageDetails(id) {
		return this._customHttpService.get(
			`${API_CONSTANTS.PACKAGES_URL}/${id}/details`
		);
	}
}
