import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LocalStorageService } from '@services/localstorage.service';
import { ToasterService } from 'angular2-toaster';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '@services/common/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@services/common/common.service';
import { UserService } from '@services/user/user.service';

@Component({
	selector: 'app-modal-popup',
	templateUrl: './app-modal-popup.component.html',
})
export class AppModalPopupComponent implements OnInit {
	modalNewSystemRef: BsModalRef;
	@ViewChild('updateLocationInfoModal', { static: true })
	updateLocationInfoModal: TemplateRef<any>;
	valForm: FormGroup;
	aCountries = [];
	aStates = [];

	constructor(
		public _localStorageService: LocalStorageService,
		public _bsModalService: BsModalService,
		public _apiService: ApiService,
		public _commonService: CommonService,
		public _formBuilder: FormBuilder,
		public _userService: UserService,
		public _toasterService: ToasterService
	) {}

	ngOnInit() {
		this.initSubscribe();
	}

	initSubscribe() {
		this._commonService.actionTriggered.subscribe((data) => {
			if (data) {
				if (data['form'] == 'updated_location_info') {
					if (this._localStorageService.isDesignerUser()) {
						this.initUpdateLocationInfoModal();
					}
				}
			}
		});
	}

	initUpdateLocationInfoModal() {
		this.valForm = this._formBuilder.group({
			name: [
				this._localStorageService.getUserFullName(),
				Validators.required,
			],
			country: ['', Validators.required],
			state: ['', Validators.required],
		});
		this.retrieveUser();
		this.getCountryList();

		this.modalNewSystemRef = this._bsModalService.show(
			this.updateLocationInfoModal,
			Object.assign({
				keyboard: false,
				backdrop: 'static',
				modalClass: 'modal-dialog',
				class: 'modal-lg modal-full',
			})
		);
	}

	retrieveUser() {
		this._userService.getProfileInformation().subscribe(
			(data) => {
				if (data.data['country']) {
					data.data['country'] = parseInt(data.data['country']);
				}
				this.valForm.patchValue(data.data);
				if (this.valForm.value['country']) {
					this.getStateList(this.valForm.value['country']);
				}
			},
			(err) => {}
		);
	}

	getCountryList() {
		this._apiService.getCountryList().subscribe(
			(data) => {
				this.aCountries = data.data;
			},
			(err) => {}
		);
	}

	onChangeCountry(event) {
		if (this.valForm.value['country']) {
			this.getStateList(this.valForm.value['country']);
		} else {
			this.aStates = [];
		}
	}

	getStateList(country_id) {
		if (!country_id) {
			return;
		}

		this._apiService.getStateList(country_id).subscribe(
			(data) => {
				this.aStates = data.data;
			},
			(err) => {}
		);
	}

	submitForm($ev) {
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			return false;
		}

		this.updateUserData(this.valForm.value);
	}

	updateUserData(oData) {
		this._userService.updateUserData(oData).subscribe(
			(data) => {
				this._toasterService.pop('success', data.message);
				this.modalNewSystemRef.hide();
			},
			(err) => {
				this._toasterService.pop('error', err.error.message);
			}
		);
	}
}
