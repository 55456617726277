import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';

declare var gtag;

@Injectable({
	providedIn: 'root',
})
export class TrackingAnalyticsService {
	constructor(public _router: Router) {}

	init() {
		if (!this.isEnabled()) {
			return;
		}

		this._router.events.subscribe((event) => {
			if (typeof gtag === 'function') {
				if (event instanceof NavigationEnd) {
					gtag('config', environment.analytics.google.id, {
						page_path: event.urlAfterRedirects,
					});
				}
			}
		});
	}

	isEnabled() {
		return environment.analytics.enabled;
	}

	trackGoogleConversion() {
		if (!this.isEnabled()) {
			return;
		}

		if (typeof gtag === 'function') {
			console.log('conversion');
			gtag('event', 'conversion', {
				send_to: environment.analytics.google.conversionId,
			});
		}
	}
}
