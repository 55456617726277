import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EarningTimerComponent } from './earning-timer.component';
import { FlocksyTimerModule } from '../flocksy-timer/flocksy-timer.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ANALYTICS_PAGES } from '../../../common/constant/AnalyticsPageIds';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProjectService } from '../../../services/project/project.service';
@NgModule({
	declarations: [EarningTimerComponent],
	imports: [
		CommonModule,
		FlocksyTimerModule,
		FormsModule,
		ReactiveFormsModule,
		TooltipModule.forRoot(),
	],
	exports: [EarningTimerComponent],
	providers: [ProjectService],
})
export class EarningTimerModule {}
