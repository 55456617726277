import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex';

import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { DemoSignupComponent } from './demo-signup/demo-signup.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';

import { LoginService } from '@services/login.service';
import { SignupService } from '@services/signup/signup.service';
import { CouponService } from '@services/coupon/coupon.service';
import { PlanService } from '@services/plan/plan.service';
import { AppSignupComponent } from './app-signup/app-signup.component';
import { InviteSignupComponent } from './invite-signup/invite-signup.component';
import { PasswordResetTokenComponent } from './password-reset-token/password-reset-token.component';
import { ANALYTICS_PAGES } from '@common/constant/AnalyticsPageIds';
import { ExternalScriptService } from '@services/common/external-script.service';
import { LandingComponent } from './landing/landing.component';
import { LayoutModule } from '../../layout/layout.module';
import { LoginSuccessGoogleComponent } from './login/login-success-google/login-success-google.component';
import { LoginSuccessMicrosoftComponent } from './login/login-success-microsoft/login-success-microsoft.component';
import { WhiteLabelInactiveComponent } from './white-label-inactive/white-label-inactive.component';

/* Use this routes definition in case you want to make them lazy-loaded */
const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		data: {
			title: 'Login',
			page_placement_id: ANALYTICS_PAGES[1].placements[1],
		},
	},
	{
		path: 'login/:guid',
		component: LoginComponent,
		data: {
			title: 'Login',
			page_placement_id: ANALYTICS_PAGES[5].placements[10],
		},
	},
	{
		path: 'demo-signup',
		component: DemoSignupComponent,
		data: { title: 'Demo Signup' },
	},
	{
		path: 'signup',
		component: SignupComponent,
		data: {
			title: 'Signup',
			page_placement_id: ANALYTICS_PAGES[2].placements[6],
		},
	},
	{
		path: 'checkout',
		component: CheckoutComponent,
		data: {
			title: 'Checkout',
			page_placement_id: ANALYTICS_PAGES[4].placements[9],
		},
	},
	{
		path: 'app-signup',
		component: AppSignupComponent,
		data: {
			title: 'Designer/Coder Signup',
			page_placement_id: ANALYTICS_PAGES[6].placements[11],
		},
	},
	{
		path: 'password/reset',
		component: ResetPasswordComponent,
		data: {
			title: 'Reset Password',
			page_placement_id: ANALYTICS_PAGES[7].placements[12],
		},
	},
	{
		path: 'password/reset/:token',
		component: PasswordResetTokenComponent,
		data: {
			title: 'Create a New Password',
			page_placement_id: ANALYTICS_PAGES[8].placements[14],
		},
	},
	{
		path: 'invite-signup/:token',
		component: InviteSignupComponent,
		data: {
			title: 'Invite Signup',
			page_placement_id: ANALYTICS_PAGES[9].placements[15],
		},
	},
	{
		path: '404',
		component: Error404Component,
		data: {
			title: '404 Not Found',
			page_placement_id: ANALYTICS_PAGES[10].placements[16],
		},
	},
	{
		path: '500',
		component: Error500Component,
		data: { title: '500 Server Error' },
	},
	{
		path: 'landing',
		component: LandingComponent,
		data: { title: 'Flocksy' },
	},
	{
		path: 'social-logins/google/redirect',
		component: LoginSuccessGoogleComponent,
		data: {
			title: 'social-login-google'
		},
	},
	{
		path: 'social-logins/microsoft/redirect',
		component: LoginSuccessMicrosoftComponent,
		data: {
			title: 'social-login-microsoft'
		},
	},
];

@NgModule({
	imports: [
		SharedModule,
		RouterModule.forChild(routes),
		NgxSelectModule,
		LayoutModule,
	],
	declarations: [
		LoginComponent,
		SignupComponent,
		DemoSignupComponent,
		CheckoutComponent,
		ResetPasswordComponent,
		Error404Component,
		Error500Component,
		AppSignupComponent,
		PasswordResetTokenComponent,
		InviteSignupComponent,
		LandingComponent,
		WhiteLabelInactiveComponent,
	],
	exports: [
		RouterModule,
		LoginComponent,
		SignupComponent,
		DemoSignupComponent,
		CheckoutComponent,
		AppSignupComponent,
		ResetPasswordComponent,
		Error404Component,
		Error500Component,
		InviteSignupComponent,
		LandingComponent,
	],
	providers: [
		LoginService,
		SignupService,
		CouponService,
		PlanService,
		ExternalScriptService,
	],
})
export class PagesModule {}
