import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReferralService } from '../../services/referral.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { UserService } from '@services/user/user.service';
import { FlocksyValidator } from '@common/validation/flocksy-validator';
import { environment } from '@env/environment';
import { isSupported, setup } from '@loomhq/record-sdk';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnalyticsService } from '@services/analytics/analytics.service';

@Component({
	selector: 'app-share-email',
	templateUrl: './share-email.component.html',
	styleUrls: ['./share-email.component.scss'],
})
export class ShareEmailComponent implements OnInit, OnDestroy {
	emailForm: FormGroup;
	private unsubscribe$ = new Subject<void>();
	loomSDK: any;
	videoUrl: string = '';
	embedUrl: SafeResourceUrl;
	referralLink: string;
	isRecording: boolean = false;
	isRefLinkAttached: boolean;
	emailInput: string = '';
	isEmailsTouched: boolean = false;
	emailsFocused: boolean;
	userData: any;

	constructor(
		private _formBuilder: FormBuilder,
		public _referralService: ReferralService,
		public _userService: UserService,
		public sanitizer: DomSanitizer,
		public _analyticsService: AnalyticsService,
	) {}

	ngOnInit() {
		this.subscribeToCombinedObservables();
		this.initForm();
		this.subscribeToEmailFormObservables();
		this.loomInit();
		this.setReferralLink();
	}

	subscribeToCombinedObservables() {
		combineLatest([
			this._userService.isRefLinkAttached$,
			this._referralService.referralLink$,
			this._userService.userData$,
			this._userService.emailVideoEmbedUrl$,
		])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(([isAttached, referralLink, userData, embedUrl]) => {
				this.isRefLinkAttached = isAttached;
				this.referralLink = referralLink;
				this.userData = userData;
				this.embedUrl = embedUrl;
			});
	}

	subscribeToEmailFormObservables() {
		combineLatest([
			this._userService.emailSubject$,
			this._userService.emailsList$,
			this._userService.emailMessage$,
		])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(([subject, emails, message]) => {
				this.emailForm.get('subject').setValue(subject);
				this.emailForm.get('emails').setValue(emails);

				const newMessage =
					message +
					(!this.isRefLinkAttached ? this.referralLink : '');
				this.emailForm.get('message').setValue(newMessage);

				this.isRefLinkAttached = true;
				this._userService.setRefLinkIsAttached(true);
			});
	}

	setReferralLink() {
		if (this.userData) {
			const referralLink = `${environment.landingSiteURL}/?r=${this.userData.id}`;
			this._referralService.setReferralLink(referralLink);
		}
	}

	initForm() {
		this.emailForm = this._formBuilder.group({
			email: ['', [FlocksyValidator.email]],
			emails: [[], Validators.required],
			subject: ['', Validators.required],
			message: ['', Validators.required],
			videoLink: [''],
		});
		this.emailForm.get('email').valueChanges.subscribe(() => {
			if (this.checkEmailExists()) {
				this.emailForm.get('email').setErrors({ incorrect: true });
			}
		});
	}

	submitForm() {
		const addresses = this.emailForm.get('emails').value;
		const subject = this.emailForm.get('subject').value;
		const message = this.emailForm.get('message').value;
		const videoLink = this.videoUrl;
		this._analyticsService.setOverlayTrackData(16, 121);

		this._referralService
			.sendEmails(
				{
					to_addresses: addresses,
					subject: subject,
					body: message,
					video_link: videoLink,
				},
				this.userData.id
			)
			.subscribe((res) => {
				this._userService.setEmailsList(addresses);
				this._userService.setEmailMessage(message);
				this._userService.setEmailVideoLink(this.embedUrl);
				this._referralService.setIsEmailSent(true);
			});
	}

	adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
		textarea.style.height = 'auto';
		textarea.style.height = `${textarea.scrollHeight}px`;
	}

	// --------For Multi Emails ----------------------------

	addTags() {
		const newEmailList = [
			...this.emailForm.get('emails').value,
			this.emailForm.get('email').value,
		];
		this._userService.setEmailsList(newEmailList);
		this.emailForm.get('email').setValue('');
	}

	removeTag(index: number) {
		this.emailForm.get('emails').value.splice(index, 1);
	}

	onInputBlur() {
		this.emailsFocused = false;
		this.isEmailsTouched = true;
		if (
			!this.emailForm.get('email').valid ||
			!this.emailForm.get('email').value
		)
			return;
		this.addTags();
	}
	onInputFocus() {
		this.emailsFocused = true;
	}

	onKeydown(event: KeyboardEvent) {
		this.isEmailsTouched = true;
		if (event.key === 'Enter' || event.key === ',') {
			event.preventDefault();
			if (
				!this.emailForm.get('email').valid ||
				!this.emailForm.get('email').value.length
			)
				return;
			this.addTags();
		}
	}
	checkEmailExists() {
		const isExist = this.emailForm
			.get('emails')
			.value.filter((val) => val === this.emailForm.get('email').value);
		return !!isExist.length;
	}

	//--------------------------------------------------

	async loomInit() {
		const { supported, error } = await isSupported();
		if (!supported) {
			return;
		}
		const button = document.getElementById('loom-record-btn');
		if (!button) {
			return;
		}

		const { configureButton } = await setup({
			publicAppId: environment.loom.publicAppId,
		});

		const sdkButton = configureButton({ element: button });

		sdkButton.on('cancel', () => {
			this.isRecording = false;
		});

		sdkButton.on('recording-start', () => {
			this.isRecording = true;
		});

		sdkButton.on('recording-complete', (video) => {
			this.isRecording = false;
		});

		sdkButton.on('insert-click', async (video) => {
			this.videoUrl = video.sharedUrl;
			const embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
				video.embedUrl
			);
			this._userService.setEmailVideoEmbedUrl(embedUrl);
			const newVal =
				this.emailForm.get('message').value + '\n\n' + this.videoUrl;
			this.emailForm.get('message').patchValue(newVal);
			this.emailForm.get('message').updateValueAndValidity();
			this._userService.setEmailMessage(
				this.emailForm.get('message').value
			);
		});
	}

	removeVideo() {
		this.removeLinkFromMessage();
		this.videoUrl = '';
		this.embedUrl = null;
		this.emailForm.get('emails').updateValueAndValidity();
	}
	removeLinkFromMessage() {
		let previousMessage = this.emailForm.get('message').value;
		let substringToRemove = this.videoUrl;
		let result = previousMessage.replace(substringToRemove, '');
		this._userService.setEmailMessage(result);
	}
	onSubjectInputChange() {
		this._userService.setEmailSubject(this.emailForm.get('subject').value);
	}
	onMessageTextareaChange() {
		this._userService.setEmailMessage(this.emailForm.get('message').value);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
