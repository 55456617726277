import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { BucketService } from '../../../../services/bucket/bucket.service';
import { LocalStorageService } from '../../../../services/localstorage.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { S3DocumentService } from '../../../../services/document/s3-document.service';
import * as moment from 'moment';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { ToasterService } from 'angular2-toaster';
import { FlocksyUtil } from '../../../../common/FlocksyUtil';
import { FileUploader } from 'ng2-file-upload';
import { BucketAssetsService } from '../../../../services/bucket/bucket-assets.service';
import { DocumentService } from '../../../../services/document/document.service';

@Component({
	selector: 'app-bucket-detail',
	templateUrl: './bucket-detail.component.html',
	styleUrls: ['./bucket-detail.component.scss'],
})
export class BucketDetailComponent implements OnInit {
	aBuckets = [];
	@Output() selectedBucketDetail = new EventEmitter();
	@Input() bCopyBrief = false;
	@Input() oProject: any;
	@Input() isBucketShown: any;
	@Input() selectedBucket: any;
	isAdminUser = this._localStorageService.isAdminUser();
	isClientUser = this._localStorageService.isClientUser();
	isProjectManagerUser = this._localStorageService.isProjectManagerUser();
	modalAddBucket: any;
	valForm: FormGroup;
	closeIcon = FlocksyUtil.closeIcon;
	oBaseDropZoneOver;
	bShowProgressBar = false;
	isValidationShown = false;
	oFileAnalyticsId;
	oFileProgress = {};
	oUploader = {};
	oFiles = [];
	oFileAnalyticsInterval;
	MAX_ANALYTICS_ATTEMPT = FlocksyUtil.getMaxFileAnalyticsAttempt();
	FILE_ANALYTICS_INTERVAL = FlocksyUtil.getFileAnalyticsInterval();
	disableAddBucketBtn = false;
	name;
	description;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateItemsPerSlide(event.target.innerWidth);
	}
	itemsPerSlide = 3;
	buckets = [];
	modalViewBucket: BsModalRef;
	editBucketMode = false;
	oSelectedBucket: any = {};
	oAssetsPagination = {
		totalRecords: 0,
		currentPage: 1,
		itemsPerPage: 0,
	};
	aBucketAssets = [];
	isSubClientUser = this._localStorageService.isSubClientUser();
	modalEditBucket: BsModalRef;
	valEditForm: FormGroup;
	modalDeleteBucket: BsModalRef;
	disableDeleteBucketBtn = false;
	oPagination = {
		totalRecords: 0,
		currentPage: 1,
		itemsPerPage: 0,
	};
	disableEditBucketBtn = false;
	progressBarPercentage = 0;
	modalEditBucketAsset: BsModalRef;
	modalDeleteBucketAsset: BsModalRef;
	oSelectedBucketAsset;
	valBucketAssetEditForm: FormGroup;
	disableEditBucketAssetBtn = false;
	currentSlideIndex;
	nextPageLoading = false;
	loadedBucketList = [];
	firstDataLoaded = false;
	searchableText;
	originalBuckets = [];
	searchInProgress = false;
	notBucketFound = false;
	dataLoaded = false;
	isSkeletonLoading = true;
	createNewBtnClicked = false;
	editBucketBtnClicked = false;
	updateAssetBtnClicked = false;
	newBucketAdded = false;
	enabledBuckets = 0;
	selectedDisabledBucket;
	@ViewChild('disabledBucketModal', { static: true })
	disabledBucketModal: TemplateRef<any>;
	disabledBucketModalRef: BsModalRef;
	bucketDeleteClicked = false;
	activeBuckets = [];

	constructor(
		private _bucketService: BucketService,
		public _localStorageService: LocalStorageService,
		public _bsModalService: BsModalService,
		public _formBuilder: FormBuilder,
		public _s3DocumentService: S3DocumentService,
		public _analyticsService: AnalyticsService,
		public _toasterService: ToasterService,
		public _bucketAssetsService: BucketAssetsService,
		public _documentService: DocumentService,
		private cdr: ChangeDetectorRef
	) {
		this.getBuckets();
		this.updateItemsPerSlide(window.innerWidth);
	}

	ngOnInit(): void {
		this._bucketService.selectedBucket.subscribe((res) => {
			this.buckets = res;
		});
		this.oUploader['file'] = new FileUploader({ url: '' });
	}

	retrieveBuckets(params) {
		return this._bucketService.get(params);
	}

	getBuckets() {
		this.isSkeletonLoading = true;
		const data = {
			per_page: 0,
		};
		this.nextPageLoading = true;
		this.retrieveBuckets(data).subscribe(
			(data) => {
				this.aBuckets = [];
				this.originalBuckets = [];
				this.activeBuckets = [];

				let objectsArray = [];
				objectsArray = Object.entries(data.data)
				.filter(([key, value]) => typeof value === 'object' && !Array.isArray(value) && key !== 'active_buckets_per_plan')
				.map(([key, value]) => value);

				if (Array.isArray(data.data.active_buckets_per_plan)) {
					this.activeBuckets = data.data.active_buckets_per_plan;
				}

				if (this.isClientUser || this.isSubClientUser) {
					objectsArray.forEach((item) => {
						if (this.activeBuckets.includes(item.id)) {
						  this.aBuckets.push(item);
						  this.originalBuckets.push(item);
						}
					});
				} else {
					this.aBuckets = objectsArray;
				}

				this.enabledBuckets = data.data.user_plan_buckets;
				this.nextPageLoading = false;
				this.dataLoaded = true;
				this.isSkeletonLoading = false;
				if (this.newBucketAdded) {
					const firstBucket = this.aBuckets[0];
					if (firstBucket) {
						this.selectBucket(firstBucket);
						this.newBucketAdded = false;
					}
				}
			},
			(err) => {}
		);
	}

	addFile(element) {
		element.click();
	}

	openSelectBucket() {
		const data = {
			per_page: 0,
		};
		this.retrieveBuckets(data).subscribe(
			(data) => {
				if (data.data.length > this.aBuckets.length) {
					this.aBuckets = this.filterBuckets(data.data);
				}
			},
			(err) => {}
		);
	}

	filterBuckets(aData) {
		const aBuckets = [];
		for (const oData of aData) {
			aBuckets.push({
				id: oData.id,
				name: oData.name,
			});
		}
		return aBuckets;
	}

	selectBucket(bucket) {
		const index = this.buckets.indexOf(bucket.id);
		if (index === -1) {
		  this.buckets.push(bucket.id);
		} else {
		  this.buckets.splice(index, 1);
		}
		this.selectedBucket = this.buckets;
		this.selectedBucketDetail.emit(this.selectedBucket);
	}
	  

	initAddBucketModal(template: TemplateRef<any>) {
		this.isValidationShown = false;
		this.modalAddBucket = this._bsModalService.show(
			template,
			Object.assign({ class: 'modal-lg modal-full' })
		);
		this.initForm();
	}

	initForm() {
		this.valForm = this._formBuilder.group({
			name: ['', Validators.required],
			description: [''],
		});
		if (this.name && this.description) {
			this.valForm.patchValue({
				name: this.name,
				description: this.description,
			});
		}
	}

	onFileSelected(event) {
		for (const oFile of event.target.files) {
			let index: any = this.oFiles.findIndex((res) => {
				return res.name == oFile.name;
			});
			if (index == -1) {
				this.oFiles.push(oFile);
			}
		}
	}

	fileOverBase(e: any): void {
		this.oBaseDropZoneOver = e;
	}

	onFileDropped(event: any): void {
		for (const oFile of event) {
			let index: any = this.oFiles.findIndex((res) => {
				return res.name == oFile.name;
			});
			if (index == -1) {
				this.oFiles.push(oFile);
			}
		}
	}

	submitAddBucketForm($ev) {
		this.disableAddBucketBtn = true;
		this.isValidationShown = true;
		$ev.preventDefault();
		for (let c in this.valForm.controls) {
			this.valForm.controls[c].setValue(
				this.valForm.controls[c].value.trim()
			);
			this.valForm.controls[c].markAsTouched();
		}

		if (!this.valForm.valid) {
			this.disableAddBucketBtn = false;
			return false;
		}

		if (this.oFiles.length > 0) {
			this.saveDocumentFilesS3(
				this.valForm.value.name,
				this.valForm.value.description
			);
		} else {
			this.storeBucket();
		}
	}

	saveDocumentFilesS3(name, description, type?) {
		if (type != 'updateBucket') {
			this.name = name;
			this.description = description;
		}
		
		this.oFiles = this._s3DocumentService.generateFileHash(this.oFiles);

		// analytics
		let startTime = moment();
		this._analyticsService
			.storeDocumentTrackData(3, 22, 0, this.oFiles)
			.subscribe((res) => {
				this.oFileAnalyticsId = res.data.request_id;
			});

		this.oFileProgress = {};
		let uploadFilePromises = this._s3DocumentService.uploadFiles(
			this.oFiles,
			3,
			function (evt) {
				let percent = Math.round((evt.loaded * 100) / evt.total);
				this.oFileProgress[evt.key] = percent;
				let progressPercentage = 0;
				for (const key in this.oFileProgress) {
					progressPercentage =
						progressPercentage + this.oFileProgress[key];
				}
				this.bShowProgressBar = true;
				this.progressBarPercentage = Math.floor(
					progressPercentage / this.oFiles.length
				);
			}.bind(this)
		);

		Promise.all(uploadFilePromises).then(
			async (res) => {
				// analytics
				this.updateFileAnalytics(startTime, 2);

				// document files
				let postFiles = [];
				for (let i = 0; i < this.oFiles.length; i++) {
					const file = this.oFiles[i];
					postFiles.push({
						filename: file.name,
						file_hash: file.file_hash,
					});
				}
				let storeFilesData = {
					create_separate_document: 1,
					document_category_id: 3,
					files: postFiles,
				};
				this._s3DocumentService
					.storeDocumentFiles(storeFilesData)
					.subscribe((res) => {
						this.bShowProgressBar = false;
						let document_ids = res.data;
						this.oFiles = [];
						if (this.editBucketMode) {
							let reloadAsset = true;
							this.updateBucket(document_ids, reloadAsset);
						} else {
							this.storeBucket(document_ids);
						}
					});
			},
			(err) => {
				this.bShowProgressBar = false;
				this._toasterService.pop(
					'error',
					'There was an error uploading your files'
				);
				this.updateFileAnalytics(startTime, 3, err);
			}
		);
	}

	updateFileAnalytics(startTime, status, err?) {
		const duration = moment().diff(startTime, 'seconds');
		let i = 0;
		this.oFileAnalyticsInterval = setInterval(() => {
			if (this.oFileAnalyticsId) {
				this._analyticsService
					.updateDocumentsTrackData(this.oFileAnalyticsId, {
						time_duration: duration,
						status: status,
						failed_reason: err,
					})
					.subscribe();
				clearInterval(this.oFileAnalyticsInterval);
			}
			++i;
			if (i == this.MAX_ANALYTICS_ATTEMPT) {
				clearInterval(this.oFileAnalyticsInterval);
			}
		}, this.FILE_ANALYTICS_INTERVAL);
	}

	storeBucket(document_ids?) {
		if (document_ids) {
			Object.assign(this.valForm.value, { document_ids: document_ids });
		}
		this._bucketService.store(this.valForm.value).subscribe((data) => {
			this.valForm.reset();
			this._toasterService.pop('success', 'Your bucket has been added!');
			this.modalAddBucket.hide();
			this.newBucketAdded = true;
			this.getBuckets();
			this.oFiles = [];
			this.disableAddBucketBtn = false;
			this.name = '';
			this.description = '';
			this.isValidationShown = false;
		},
		err => {
			this.disableAddBucketBtn = false;
			this.isValidationShown = false;
		});
	}

	private updateItemsPerSlide(windowWidth: number) {
		if (windowWidth >= 1400 && windowWidth <= 1920) {
			this.itemsPerSlide = 5;
		} else if (windowWidth >= 1250 && windowWidth < 1400) {
			this.itemsPerSlide = 3;
		} else if (windowWidth >= 950 && windowWidth < 1250) {
			this.itemsPerSlide = 2;
		} else if (windowWidth >= 678 && windowWidth < 950) {
			this.itemsPerSlide = 2;
		} else if (windowWidth < 678) {
			this.itemsPerSlide = 1;
		}
	}

	initViewBucketModal(oBucket: object, template: TemplateRef<any>) {
		this.editBucketMode = true;
		this.oFiles = [];
		this.oSelectedBucket = oBucket;
		this.initEditForm();
		this.valEditForm.patchValue(oBucket);
		this.retrieveBucketAssets();
		this.modalViewBucket = this._bsModalService.show(
			template,
			Object.assign({ class: 'modal-xl modal-full' })
		);
	}

	hideViewBucketModal() {
		this.modalViewBucket.hide();
	}

	retrieveBucketAssets() {
		this._bucketAssetsService
			.index(this.oSelectedBucket.id, this.oAssetsPagination.currentPage)
			.subscribe((data) => {
				this.aBucketAssets = data.data.map(asset => {
					return {
						...asset,
						formattedDate: this._localStorageService.formatDate(
							asset.created_at,
							'MMMM DD, YYYY h:mm A'
						)
					};
				});
				this.oAssetsPagination.totalRecords = data.total;
				this.oAssetsPagination.itemsPerPage = data.per_page;
			});
	}	

	initEditBucketModal(oBucket: object, template: TemplateRef<any>) {
		this.editBucketMode = true;
		this.oFiles = [];
		this.oSelectedBucket = oBucket;
		this.initEditForm();
		this.valEditForm.patchValue(oBucket);
		this.retrieveBucketAssets();
		this.modalEditBucket = this._bsModalService.show(
			template,
			Object.assign({ class: 'modal-xl modal-full' })
		);
		this.valEditForm.patchValue(oBucket);
		this.oSelectedBucket = oBucket;
		this._analyticsService.setOverlayTrackData(
			22,
			27,
			this.oSelectedBucket['id'],
			3
		);
	}

	initEditForm() {
        this.valEditForm = this._formBuilder.group({
            name: ['', [Validators.required, this.whiteSpaceValidator]],
            description: [''],
        });
    }

	initDeleteBucketModal(oBucket: object, template: TemplateRef<any>) {
		this.modalDeleteBucket = this._bsModalService.show(
			template,
			Object.assign({ class: 'modal-lg modal-full' })
		);
		this.oSelectedBucket = oBucket;
		this._analyticsService.setOverlayTrackData(
			22,
			28,
			this.oSelectedBucket['id'],
			3
		);
	}

	deleteBucket(oData) {
		this.disableDeleteBucketBtn = true;
		this._bucketService.destroy(oData['id']).subscribe(
			(data) => {
				this.modalDeleteBucket.hide();
				this._toasterService.pop(
					'success',
					'This bucket has been deleted.'
				);

				this.getBuckets();
				this.disableDeleteBucketBtn = false;
			},
			(err) => {
				this.disableDeleteBucketBtn = false;
			}
		);
	}

	onPageChanged(event: any): void {
		this.oPagination['currentPage'] = event.page;
		const params = {
			per_page: 0,
		};
		this.retrieveBuckets(params);
	}

	onBucketAssetsPageChanged(event: any): void {
		this.oAssetsPagination['currentPage'] = event.page;
		this.retrieveBucketAssets();
	}

	whiteSpaceValidator(control: FormControl): ValidationErrors | null {
        return (control.value || '').trim().length ? null : { 'whitespace': true };
    }

	submitEditBucketForm($ev) {
        $ev.preventDefault();
        this.editBucketBtnClicked = true;

        for (let c in this.valEditForm.controls) {
            let control = this.valEditForm.controls[c];
            control.markAsTouched();
        }

        if (!this.valEditForm.valid) {
            return false;
        }

        if (this.oFiles.length > 0) {
            this.saveDocumentFilesS3(
                this.valEditForm.value.name,
                this.valEditForm.value.description,
                'updateBucket'
            );
            this._toasterService.pop(
                'success',
                "You've added this asset to your bucket! Way to go."
            );
        } else {
            this.updateBucket();
        }
    }

	updateBucket(document_ids?, reloadAsset?) {
		if (document_ids) {
			Object.assign(this.valEditForm.value, {
				document_ids: document_ids,
			});
		}
		this.disableEditBucketBtn = true;
		this._bucketService
			.update(this.oSelectedBucket['id'], this.valEditForm.value)
			.subscribe(
				(data) => {
					if (this.modalEditBucket) this.modalEditBucket.hide();
					this._toasterService.pop(
						'success',
						'Your bucket has been updated!'
					);
					this.getBuckets();
					if (reloadAsset) {
						this.retrieveBucketAssets();
					}
					this.disableEditBucketBtn = false;
					this.editBucketBtnClicked = false;
				},
				(err) => {
					this.disableEditBucketBtn = false;
					this.editBucketBtnClicked = false;
				}
			);
	}

	removeFile(index) {
		if (index !== -1) {
			this.oFiles.splice(index, 1);
		}
	}

	initEditBucketAssetModal(oBucketAsset: object, template: TemplateRef<any>) {
		this.modalEditBucketAsset = this._bsModalService.show(
			template,
			Object.assign({ class: 'modal-lg modal-full edit-bucket-asset' })
		);
		this.initBucketAssetEditForm();
		this.valBucketAssetEditForm.patchValue(oBucketAsset);
		this.oSelectedBucketAsset = oBucketAsset;
		this._analyticsService.setOverlayTrackData(
			22,
			27,
			this.oSelectedBucket['id'],
			3
		);
		document.getElementsByClassName(
			'edit-bucket-asset'
		)[0].parentElement.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
	}

	initDeleteBucketAssetModal(
		oBucketAsset: object,
		template: TemplateRef<any>
	) {
		this.modalDeleteBucketAsset = this._bsModalService.show(
			template,
			Object.assign({ class: 'delete-bucket-asset' })
		);
		this.oSelectedBucketAsset = oBucketAsset;
		this._analyticsService.setOverlayTrackData(
			23,
			30,
			this.oSelectedBucketAsset['id'],
			3
		);
		document.getElementsByClassName(
			'delete-bucket-asset'
		)[0].parentElement.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
	}

	initBucketAssetEditForm() {
		this.valBucketAssetEditForm = this._formBuilder.group({
			description: ['', [Validators.required, this.whiteSpaceValidator]],
		});
	}

	deleteBucketAsset(oData) {
		this._bucketAssetsService
			.destroy(
				this.oSelectedBucket['id'],
				this.oSelectedBucketAsset['id']
			)
			.subscribe((data) => {
				this.modalDeleteBucketAsset.hide();
				this.retrieveBucketAssets();
				this.getBuckets();
			});
		this._toasterService.pop(
			'success',
			'This bucket asset has been deleted.'
		);
	}

	submitEditBucketAssetForm($ev) {
        $ev.preventDefault();
        this.updateAssetBtnClicked = true;

        for (let c in this.valBucketAssetEditForm.controls) {
            const control = this.valBucketAssetEditForm.controls[c];
            control.markAsTouched();
        }

        if (!this.valBucketAssetEditForm.valid) {
            return false;
        }

        this._toasterService.pop(
            'success',
            'Your bucket asset has been updated!'
        );
        this.updateBucketAsset();
    }

	updateBucketAsset() {
		this.disableEditBucketAssetBtn = true;
		this._bucketAssetsService
			.update(
				this.oSelectedBucket.id,
				this.oSelectedBucketAsset.id,
				this.valBucketAssetEditForm.value
			)
			.subscribe(
				(data) => {
					this.modalEditBucketAsset.hide();
					this.getBuckets();
					this.disableEditBucketAssetBtn = false;
					this.updateAssetBtnClicked = false;
				},
				(err) => {
					this.disableEditBucketAssetBtn = false;
					this.updateAssetBtnClicked = false;
				}
			);
	}

	filterData() {
		setTimeout(() => {
			this.searchInProgress = true;
			this.notBucketFound = false;
			const searchTerm = this.searchableText.toLowerCase();
			this.aBuckets = [];
			if (searchTerm !== '') {
				setTimeout(() => {
					let filteredBuckets = [];
					this.aBuckets = [];
					filteredBuckets = this.originalBuckets.filter(bucket =>
						bucket.name.toLowerCase().includes(searchTerm)
					);
					this.aBuckets = filteredBuckets;
					if (this.aBuckets.length == 0) {
						this.notBucketFound = true;
					} else {
						this.notBucketFound = false;
					}
					this.searchInProgress = false;
				});
			} else {
				setTimeout(() => {
					this.aBuckets = this.originalBuckets;
					this.notBucketFound = false;
					this.searchInProgress = false;
				});
			}
			this.cdr.detectChanges();
		});
	}
	
}
