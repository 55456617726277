import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANTS } from '../../../../common/constant/ApiConstants';
import { CustomHttpService } from '../../../custom.http.service';

@Injectable({
	providedIn: 'root',
})
export class NotesService {
	constructor(private _customHttpService: CustomHttpService) {}

	store(clientId, creativeId, oData): Observable<any> {
		return this._customHttpService.post(
			`${API_CONSTANTS.USERS_URL}/clients/${clientId}/creatives/${creativeId}/notes`,
			oData
		);
	}

	index(clientId, creativeId, page?, oParams?): Observable<any> {
		const params = new URLSearchParams();
		for (const key in oParams) {
			params.set(key, oParams[key]);
		}

		return this._customHttpService.get(
			`${
				API_CONSTANTS.USERS_URL
			}/clients/${clientId}/creatives/${creativeId}/notes?page=${page}${
				params.toString() ? '&' + params.toString() : ''
			}`
		);
	}

	destroy(id?): Observable<any> {
		return this._customHttpService.delete(
			`${API_CONSTANTS.USERS_URL}/client-creative-notes/${id}`
		);
	}

	update(id, oData): Observable<any> {
		return this._customHttpService.put(
			`${API_CONSTANTS.USERS_URL}/client-creative-notes/${id}`,
			oData
		);
	}
}
